import React from 'react'
import { formatNumber, parseNumber } from 'libphonenumber-js'
import { Button } from './Button'
import { BtnHref } from './Links'
import { CopyToClipboard } from './CopyToClipboard'

export default class CallButton extends React.PureComponent {
    state = {
        called: false,
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.phoneNumber !== this.props.phoneNumber) {
            this.setState({ called: false })
        }
    }

    call() {
        const { called } = this.state

        if(!called) {
            this.props.onCall(this.props.item)
            this.setState({ called: true })
        }
    }

    render() {
        const {
            phoneNumber,
            modifiers,
            disabled,
            geoCode,
            label,
            icon,
        } = this.props

        const { called } = this.state
        let displayNumber = null
        let linkNumber = '#'
        let parsedPhoneNumber = null

        // We're using 'uk' as geoCode, but `libphonenumber-js` uses 'GB'
        let phoneGeoCode = geoCode === 'uk' ? 'GB' : geoCode.toUpperCase()

        try {
            parsedPhoneNumber = parseNumber(phoneNumber, phoneGeoCode)

            if (parsedPhoneNumber.country === phoneGeoCode) {
                displayNumber = formatNumber(parsedPhoneNumber, 'National')
            } else {
                displayNumber = formatNumber(parsedPhoneNumber, 'International')
            }

            linkNumber = 'tel:' + formatNumber(parsedPhoneNumber, 'International')

            if (!parsedPhoneNumber.phone) {
                throw Error('No valid phone number parsed from ' + phoneNumber)
            }

            return called
                ? <BtnHref modifiers={modifiers} disabled={disabled} href={linkNumber}>
                    {icon} &nbsp; <CopyToClipboard label={displayNumber}/>
                </BtnHref>
                : <Button modifiers={modifiers} disabled={disabled} onClick={() => this.call()}>
                    {icon} &nbsp; {label}
                </Button>
        } catch (error) {
            return called
                ? <Button modifiers={modifiers} disabled={disabled}>
                    {icon} &nbsp; <CopyToClipboard label={phoneNumber}/>
                </Button>
                : <Button modifiers={modifiers} disabled={disabled} onClick={() => this.call()}>
                    {icon} &nbsp; {label}
                </Button>
        }
    }
}
