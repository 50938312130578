import { createSelector } from 'reselect'

const state = state => state

export const industriesSelector = createSelector(
    state,
    state => {
        return Object.keys(state.entities.industries).map(id => state.entities.industries[id])
    }
)
