import React from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import FormattedDate from '../utils/FormattedDate'
import { getProjects } from '../../actions/projects'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'

@connect(
    state => ({
        partnerId: state.entities.partnerUsers[state.auth.partnerUserId].partnerId,
        projectId: state.pages.projects.projectIds[0],
    }),
    {
        getProjects,
        push,
    }
)

@withTranslation('', { wait: true })
export default class TransactionItem extends React.Component {

    // Redirect to project page related to leadId id
    getProjectId(e, leadId) {
        e.preventDefault()
        this.props.getProjects({ partnerId: this.props.partnerId, leadId })
             .then(res => this.props.push(`/projects/${res.result[0]}`))
    }

    render() {
        const { transaction, t } = this.props

        return (
            <tr>
                <td className="table-mobile-cell">
                    <table className="definition-list">
                        <tr>
                            <td>{ t('transactions.date') }</td>
                            <td><FormattedDate date={transaction.createdAt} format="datetime" /></td>
                        </tr>
                        <tr>
                            <td>{ t('transactions.coupon') }</td>
                            <td>{transaction.couponId}</td>
                        </tr>
                        <tr>
                            <td>{ t('transactions.punches') }</td>
                            <td className={'amount ' + (transaction.punches > 0 ? 'success' : 'danger')}>{transaction.punches > 0 ? '+' : ''}{transaction.punches}</td>
                        </tr>
                        <tr>
                            <td>{ t('transactions.type') }</td>
                            <td>
                                {t('transactions.type.' + transaction.type)}
                                { transaction.lead &&
                                <span>: <Link to="" onClick={e => this.getProjectId(e, transaction.lead.id)}>#{transaction.lead.id}</Link>
                                    <br />
                                    <small>{transaction.lead.title}</small>
                                </span> }
                            </td>
                        </tr>
                    </table>
                </td>
                <td><FormattedDate date={transaction.createdAt} format="datetime" /></td>
                <td>{transaction.couponId}</td>
                <td className={'amount ' + (transaction.punches > 0 ? 'success' : 'danger')}>{transaction.punches > 0 ? '+' : ''}{transaction.punches}</td>
                <td>{t('transactions.type.' + transaction.type)}{ transaction.lead &&
                    <span>:
                        <Link to="" onClick={e => this.getProjectId(e, transaction.lead.id)}>#{transaction.lead.id}</Link>
                        <br />
                        <small>{transaction.lead.title}</small>
                    </span> }
                </td>
            </tr>
        )
    }
}
