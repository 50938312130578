import { useState } from 'react'
import { Card, Content } from '../cards/index'
import { Button } from '../elements/ButtonNew'
import { Text } from '../elements/text/Text'
import { Modal, Col, Row } from 'react-bootstrap'
import { cancelSubscription, resumeSubscription } from '../../actions/cashier'
import { useDispatch } from 'react-redux'
import LoadingAnimation from '../utils/LoadingAnimation'
import { useTranslation } from 'react-i18next'
import { toastr } from 'react-redux-toastr'

export default function SubscriptionCard({
    subscription,
    onSubscriptionUpdated,
}){
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    let priceText = subscription.product.priceExclVat + ' ' + subscription.product.currency.toUpperCase();
    if(subscription.product.vatRate > 0){
        priceText += ' ' + t('stripe_subscription_page.subscription.ex_vat');
    }
    priceText += '/' + t('stripe_subscription_page.subscription.period.' + subscription.product.paymentFrequency);

    async function confirmCancelSub(){
        try {
            setShowCancelModal(false);
            setLoading(true);
            await dispatch(cancelSubscription(subscription));
            await onSubscriptionUpdated();
        } catch(err) {
            toastr.error(t('stripe_subscription_page.subscription.update_error.title'), t('stripe_subscription_page.subscription.update_error.description'));
        } finally {
            setLoading(false);
            toastr.confirm(t('stripe_subscription_page.subscription.create_success'));
        }
    }

    async function resumeSub(){
        try {
            setLoading(true);
            await dispatch(resumeSubscription(subscription));
            await onSubscriptionUpdated();
        } catch(err) {
            toastr.error(t('stripe_subscription_page.subscription.update_error.title'), t('stripe_subscription_page.subscription.update_error.description'));
        } finally {
            setLoading(false);
            toastr.confirm(t('stripe_subscription_page.subscription.create_success'));
        }
    }

    return (
        <div>
            <Card>
                <Content>
                    <Row>
                        <Col sm={8}>
                            <Text>
                                {subscription.product.name}
                            </Text>
                            <br />
                            <Text modifiers={['small']}>
                                {subscription.product.description}
                            </Text>
                        </Col>
                        <Col sm={4} style={{ textAlign: 'right' }}>
                            <Text>
                                {priceText}
                            </Text>
                            <Text modifiers={['small']}>
                                {subscription.endsAt ? t('stripe_subscription_page.subscription.ends') + ': ' + (new Date(subscription.endsAt).toLocaleDateString()) : t('stripe_subscription_page.subscription.autorenews')}
                            </Text>
                        </Col>
                    </Row>

                    <br />

                    {subscription.endsAt ?
                        <Button onClick={ () => resumeSub() } modifiers={[ 'action', 'bold', 'small' ]} disabled={loading}>
                            <span>
                                {t('stripe_subscription_page.subscription.resume')}
                            </span>
                        </Button>
                        :
                        <Button onClick={ () => setShowCancelModal(true)} modifiers={[ 'danger', 'bold', 'small' ]} disabled={loading}>
                            <span>
                                {t('stripe_subscription_page.subscription.cancel')}
                            </span>
                        </Button>
                    }
                    {loading && (
                        <LoadingAnimation />
                    )}
                </Content>
            </Card>

            <Modal show={showCancelModal}
                animation={true}
                onHide={() => setShowCancelModal(false)}>
                <Modal.Header closeButton>
                    <Text>
                        {t('stripe_subscription_page.subscription.cancel_modal.title')}
                    </Text>
                </Modal.Header>
                <Modal.Body>
                    {t('stripe_subscription_page.subscription.cancel_modal.description')}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => confirmCancelSub()} modifiers={[ 'danger', 'bold', 'small' ]}>
                        <span>
                            {t('stripe_subscription_page.subscription.cancel_modal.confirm')}
                        </span>
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
