import React from 'react'
import { toastr } from 'react-redux-toastr'
import Box from '../blocks/Box'
import { Flex } from '../elements/Flex'
import { Headline, Text } from '../elements/text/Text'
import { Button } from '../elements/ButtonNew'
import { connect } from 'react-redux'
import { hideModal } from '../../actions/modal'
import { withTranslation, Trans } from 'react-i18next'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import { sendPurchaseLimitReached } from '../../actions/partners'

const CenteredModal = styled(Modal)`
    .modal-dialog {
        width: 520px;
        margin-top: 300px !important;
    }
`

@connect(
    (state) => ({
        partner: state.entities.partners[state.modal.partnerId],
    }),
    {
        hideModal,
        sendPurchaseLimitReached,
    }
)
@withTranslation('', { wait: true })
export default class MarketplacePurchaseLimitReachedModal extends React.Component {
    state = {
        messageSent: false,
    }

    contact = () => {
        const { partner } = this.props
        try {
            this.props.sendPurchaseLimitReached(partner.id)
        } catch (err) {
            toastr.error('Error!', err.error)
        } finally {
            this.setState({ messageSent: true })
        }
    }

    close = () => {
        this.props.hideModal()
    }

    renderContentBody = () => {
        const { messageSent } = this.state

        if (messageSent) {
            return this.renderInfoAboutMessageSent()
        }

        return this.renderInfoAboutLimitReached()
    }

    renderInfoAboutMessageSent = () => {
        const { t } = this.props

        return <React.Fragment>
            <Box.Header modifiers={[ 'pX_2', 'pT_2', 'pB_1' ]}>
                <Headline modifiers={[ 'tiny' ]}>{t('marketplace.limit_reached_modal.headline_message_sent')}</Headline>
            </Box.Header>
            <Box.Line />
            <Box.Content modifiers={[ 'p_2' ]}>
                <Text>
                    {t('marketplace.limit_reached_modal.contact_message_sent')}
                </Text>
            </Box.Content>
            <Box.Line />
            <Flex modifiers={[ 'justifyEnd', 'p_2' ]}>
                <Button onClick={this.close} modifiers={[ 'action', 'bottomCenter', 'regular', 'bold' ]}>{t('marketplace.limit_reached_modal.close')}</Button>
            </Flex>
        </React.Fragment>
    }

    renderInfoAboutLimitReached = () => {
        const { partner, t } = this.props

        return <React.Fragment>
            <Box.Header modifiers={[ 'pX_2', 'pT_2', 'pB_1' ]}>
                <Headline modifiers={[ 'tiny' ]}>{t('marketplace.limit_reached_modal.headline')}</Headline>
            </Box.Header>
            <Box.Line />
            <Box.Content modifiers={[ 'p_2' ]}>
                <Text>
                    <Trans
                        i18nKey="marketplace.limit_reached_modal.limit_text"
                        limit={partner.leadMarketplaceRecentPurchaseCountMax}
                    />
                </Text>
            </Box.Content>
            <Box.Content modifiers={[ 'p_2' ]}>
                <Text>
                    {t('marketplace.limit_reached_modal.contact_text')}
                </Text>
            </Box.Content>
            <Box.Line />
            <Flex modifiers={[ 'justifyEnd', 'p_2' ]}>
                <Button onClick={this.contact} modifiers={[ 'action', 'bottomCenter', 'regular', 'bold' ]}>{t('marketplace.limit_reached_modal.contact_confirm')}</Button>
            </Flex>
        </React.Fragment>
    }

    render = () => {
        return <CenteredModal
            show={true}
            onHide={this.props.hideModal}
            className="new"
        >
            <Box>
                {this.renderContentBody()}
            </Box>
        </CenteredModal>
    }
}
