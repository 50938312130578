import { keys } from 'lodash'

/**
 * Replace filter string to array
 * @param {string} filterProps - Sting of filter cases.
 * @return {array} Array of property
 */
export const splitFilterString = filterProps => Array.isArray(filterProps)
    ? filterProps.filter(val => val !== '')
    : (filterProps || '').split(',').filter(val => val !== '')

/**
 *
 * Replace empty props from body request
 * @param {object} props - Object with parameters
 * @return {object} Object without empty property
 */
export const removeEmptyProperties = props => {
    const criteria = {
        ...props,
    }

    delete criteria.visibleColumns
    delete criteria.direction
    delete criteria.column
    delete criteria.sidebarName

    keys(criteria)
        .forEach(key => {
            switch (criteria[key]) {
                case '':
                case null:
                case undefined:
                case {}:
                    delete criteria[key]
                    break
                default:
                    if (Array.isArray(criteria[key]) && !criteria[key].length)
                        delete criteria[key]
            }
        })

    return criteria
}

/**
 *
 * Calculate distance between 2 geo points
 * @param {number} lat1 - Object with parameters
 * @param {number} lon1 - Object with parameters
 * @param {number} lat2 - Object with parameters
 * @param {number} lon2 - Object with parameters
 * @param {string} geoCode - Object with parameters
 * @return {number} Distance in units based on geo code
 */
export const calculateDistance = (lat1, lon1, lat2, lon2, geoCode) => {
    if ((lat1 === lat2) && (lon1 === lon2)) {
        return 0
    } else {
        const radLat1 = Math.PI * lat1 / 180
        const radLat2 = Math.PI * lat2 / 180
        const theta = lon1 - lon2
        const radTheta = Math.PI * theta / 180
        let dist = Math.sin(radLat1) * Math.sin(radLat2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta)
        if (dist > 1) {
            dist = 1
        }
        dist = Math.acos(dist)
        dist = dist * 180 / Math.PI
        dist = dist * 60 * 1.1515
        return geoCode === 'us' ? dist : dist * 1.609344
    }
}
