import { CALL_API } from '../middleware/api'
import * as schemas from '../schemas'
import { PartnerCouponResource } from 'ageras-api-client/src/api'

export const GET_COUPONS = 'coupons/GET_COUPONS'
export const GET_COUPONS_SUCCESS = 'coupons/GET_COUPONS_SUCCESS'
export const GET_COUPONS_FAIL = 'coupons/GET_COUPONS_FAIL'

export const getCoupons = partnerId => ({
    [CALL_API]: {
        types: [ GET_COUPONS, GET_COUPONS_SUCCESS, GET_COUPONS_FAIL ],
        endpoint: `/partners/${partnerId}/coupons`,
        schema: schemas.coupons,
        options: {
            body: {
                limit: 1000,
                showEmpty: true,
                type: PartnerCouponResource.TypeEnum.MarketplaceCoupon
            },
        },
    },
})
