import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Page } from '../../../../components/layout/Page'
import { Card, Header, Content } from '../../../../components/cards/index'
import { renderField, renderTextarea } from '../../../../components/utils/forms'
import { Col, Row } from 'react-bootstrap'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { getPartner } from '../../../../actions/partners'
import { currentClientSelector } from '../../../../selectors/auth'
import { StarRatingInput } from '../../../../components/utils/StarRatingInput'
import { createRating } from '../../../../actions/ratings'
import { push } from 'connected-react-router'
import { toastr } from 'react-redux-toastr'

const validate = values => {
    const errors = {}
    if (!values.name)
        errors.name = 'Required'
    if (!values.title)
        errors.title = 'Required'
    if (!values.email)
        errors.email = 'Required'
    if (!values.comment)
        errors.comment = 'Required'
    return errors
}

@connect(
    (state, props) => ({
        client: currentClientSelector(state),
        partner: state.entities.partners[props.match.params.partnerId],
        initialValues: {
            name: currentClientSelector(state).firstName + ' ' + currentClientSelector(state).lastName,
            email: currentClientSelector(state).email,
            phone: currentClientSelector(state).phone,
        },
    }),
    {
        getPartner,
        createRating,
        push,
    }
)
@withTranslation('', { wait: true })
@reduxForm({
    form: 'clientReviewForm',
    destroyOnUnmount: true,
    enableReinitialize: true,
    validate,
})
export class CreateReviewPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            budget: 0,
            schedule: 0,
            expertise: 0,
            contact: 0,
            quality: 0,
        }
    }

    componentWillMount() {
        this.props.getPartner(this.props.match.params.partnerId)
    }

    checkStar(type, index) {
        this.setState({ [type]: index + 1 })
    }

    submitRatingsFrom(data) {
        const { leadId, partnerId } = this.props.match.params

        if (this.overallRating) {
            this.props.createRating({
                ...data,
                partner: { id: partnerId },
                score: this.state,
                leadId,
            }).then(res => {
                toastr.success(this.props.t('review.success_message'))
                this.props.push(`/case/${leadId}/${partnerId}`)
            })
        } else {
            throw new SubmissionError({
                _error: this.props.t('review.submit_rate_error', { name: this.props.partner.companyName }),
            })
        }
    }

    render() {
        const { error, t, handleSubmit, partner } = this.props
        const satedRate = Object.values(this.state).filter(el => el)
        this.overallRating = Math.ceil(satedRate.reduce((prev, cur, index) => (prev + cur), 0) / satedRate.length) || 0
        if (!partner) return null
        return <Page>
            <Card>
                <Header>
                    {t('review.header', { name: partner.companyName })}
                </Header>
                <Content>
                    <p className="intro-text">
                        {t('review.intro')}
                    </p>
                    <form onSubmit={handleSubmit(this.submitRatingsFrom.bind(this))}>
                        <Row>
                            <Col sm={6}>
                                <div className="form-group">
                                    <strong>{t('review.name')}</strong>
                                    <Field name="name"
                                           component={renderField}/>
                                </div>
                                <div className="form-group">
                                    <strong>{t('review.email_label')}</strong>
                                    <Field name="email"
                                           required
                                           component={renderField}
                                           placeholder={t('review.email_description')} />
                                </div>
                                <div className="form-group">
                                    <strong>{t('review.email_label')}</strong>
                                    <Field name="phone"
                                           required
                                           component={renderField}
                                           placeholder={t('review.email_description')} />
                                </div>
                                <div className="form-group">
                                    <strong>{t('review.experience_label')}</strong>
                                    <Field name="title"
                                           required
                                           component={renderField}
                                           placeholder={t('review.experience_description')} />
                                </div>
                                <div className="form-group">
                                    <strong>{t('review.provide_label')}</strong>
                                    <Field name="comment"
                                           height="380px"
                                           required
                                           component={renderTextarea}
                                           placeholder={t('review.provide_description')} />
                                </div>
                            </Col>
                            <Col sm={6}>
                                <strong>{t('review.rate.rate')}</strong>
                                <table className="table-review-rating">
                                    <tr>
                                        <td>{t('review.rate.quality')}</td>
                                        <td><StarRatingInput filled={ this.state.quality} total={5} size={26} event={this.checkStar.bind(this, 'quality')}/></td>
                                    </tr>
                                    <tr>
                                        <td>{t('review.rate.replay_ability')}</td>
                                        <td><StarRatingInput filled={ this.state.contact} total={5} size={26} event={this.checkStar.bind(this, 'contact')}/></td>
                                    </tr>
                                    <tr>
                                        <td>{t('review.rate.expertise_ability')}</td>
                                        <td><StarRatingInput filled={ this.state.expertise} total={5} size={26} event={this.checkStar.bind(this, 'expertise')}/></td>
                                    </tr>
                                    <tr>
                                        <td>{t('review.rate.schedule_ability')}</td>
                                        <td><StarRatingInput filled={ this.state.schedule} total={5} size={26} event={this.checkStar.bind(this, 'schedule')}/></td>
                                    </tr>
                                    <tr>
                                        <td>{t('review.rate.budget_ability')}</td>
                                        <td><StarRatingInput filled={ this.state.budget} total={5} size={26} event={this.checkStar.bind(this, 'budget')}/></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ textAlign: 'center' }}>
                                            <p><strong>{t('review.overall_rating')}</strong></p>
                                            <StarRatingInput filled={ this.overallRating } total={5} size={42} />
                                            <br />
                                            {error && <div className="has-error"><strong className="help-block">{error}</strong></div>}
                                        </td>
                                    </tr>
                                </table>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={4}>
                                <button className="btn btn-action btn-lg btn-block" type="submit">
                                    {t('review.submit_rate')}
                                </button>
                            </Col>
                        </Row>
                        <hr />
                        <div className="alert alert-info">
                            {t('review.privacy_disclaimer')}
                        </div>
                    </form>
                </Content>
            </Card>
        </Page>
    }
}
