import { useQueryClient } from '@tanstack/react-query'
import { useAgerasApiMutation } from 'ageras-api-client/src'
import { useAuth } from 'ageras-api-client/src/auth'
import { KEY_PARTNER } from 'ageras-api-client/src/queryKeys'
import useModal  from 'components/src/modals/useModal'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import {
    LeadResource,
    PartnerResource,
    LeadActionResource,
    LeadsApiFetchParamCreator,
} from 'ageras-api-client/src/api'
import LeadMarketplaceCard from 'components/src/Marketplace/LeadCard'
import { Briefcase, User } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import config from '../../../config'
import usePartnerPrePaidBalance from '../../../utils/usePartnerPrePaidBalance'
import DetailPrompt from './DetailPrompt'
import UnlockPrompt from './UnlockPrompt'
import PrePaymentModal from '../../modals/PrePaymentModal'
import { getPartner } from '../../../actions/partners'
import { toastr } from 'react-redux-toastr'

interface LeadCardProps {
    lead: LeadResource
    partner: PartnerResource
    isIndividualType: boolean
    hasMarketplacePlusLeadAccess: boolean
    hasMarketplaceWithoutFeesAccess: boolean
}

const CONSIDER_LEAD_NEW_HOURS = 8

const isNewLead = (lead: LeadResource): boolean => {
    const date = new Date()
    date.setHours(date.getHours() - CONSIDER_LEAD_NEW_HOURS)
    return new Date(String(lead.validatedAt)) > date
}

const isLastChance = (lead: LeadResource): boolean => Number(lead.quotesInfo?.max) - Number(lead.quotesInfo?.count) === 1

function LeadCard({ lead, partner, isIndividualType, hasMarketplacePlusLeadAccess, hasMarketplaceWithoutFeesAccess }: LeadCardProps) {
    const { show } = useModal()
    const { balance } = usePartnerPrePaidBalance()
    const { token, partnerUser } = useAuth()
    const history = useHistory()
    const { t } = useTranslation('translation')
    const queryClient = useQueryClient()
    const dispatch = useDispatch()

    const isPremiumLead = lead.marketplace?.matchesMarketPremiumAccessCriteria
    const isBasicQuoteLimitReached = !hasMarketplacePlusLeadAccess && partner.marketplace?.currentQuotesLimit == partner.marketplace?.currentQuotesCount
    const isLockedForBasicUsers = isBasicQuoteLimitReached || (isPremiumLead && (
        !!lead.marketplace?.basicAccessDeadline && new Date(lead.marketplace?.basicAccessDeadline) > new Date()
    ))
    const isBusinessLead = !isIndividualType
    const minBalanceToQuote = config.minimumMarketplacePrePaymentAmount[partner.geo?.code! as keyof typeof config.minimumMarketplacePrePaymentAmount]
    const mutation = useAgerasApiMutation<LeadActionResource>(
        leadAction => LeadsApiFetchParamCreator().leadsActionsCreate(String(lead.id), leadAction)
    )

    const onContinue = useCallback(async () => {
        if (hasMarketplaceWithoutFeesAccess || balance >= minBalanceToQuote) {
            const action: LeadActionResource = {
                partnerId: partnerUser?.partnerId,
                action: LeadActionResource.ActionEnum.BuyFromLeadMarketplace,
                customFee: lead.quotesInfo?.quoteFee?.quoteFee,
            }

            try {
                await mutation.mutateAsync(action)
                await Promise.all([
                    queryClient.invalidateQueries([ KEY_PARTNER + '/' + partner.id ]),
                    dispatch(getPartner(partner.id))
                ])

                history.push('/quote/' + lead.id)
            } catch (res) {
                if (res instanceof Error) {
                    toastr.error(t('marketplace.preferences.error_title'), res.message)
                }
            }
        } else {
            show(args => <PrePaymentModal {...args} token={token!} partner={partner} geoCode={partner.geo?.code!} currency={partner.preferences?.currency!} partnerId={Number(partner.id)}/>)()
            toastr.error(t('marketplace.balance.low_balance_title'), t('marketplace.balance.low_balance_description', { amount: minBalanceToQuote + partner.preferences?.currency?.symbol! }))
        }
    }, [ mutation, history, lead, balance, partner, token, show, t ])

    const onUpgrade = useCallback(() => {
        history.push('/marketplace/subscription?type=lead_marketplace_plus') // update identifier
    }, [ history ])

    const revenueAmount = lead.attributes ? Object.values(lead.attributes).find(attr => attr.identifier === 'annual_revenue')?.selected?.[0]?.title : 0

    const newLeadLabel = isNewLead(lead) ? t('marketplace.new_label') : undefined
    const lastChanceLabel = !newLeadLabel && isLastChance(lead) ? t('marketplace.last_chance_label') : undefined

    return (
        <LeadMarketplaceCard
            icon={isBusinessLead ? <Briefcase /> : <User />}
            revenueAmount={revenueAmount as unknown as number}
            revenueCurrency={partner.preferences?.currency?.code!}
            title={lead.title!}
            cityName={lead.geo?.cityName!}
            zipCode={lead.geo?.zipCode!}
            customerType={lead.customerInfo?.customerType?.identifier!}
            premiumAccessLabel={isPremiumLead && !hasMarketplacePlusLeadAccess ? t('marketplace.premium_access_label') : undefined}
            newLeadLabel={newLeadLabel}
            lastChanceLabel={lastChanceLabel}
            incomeRevenueLabelString={isBusinessLead ? t('marketplace.lead.revenue') : t('marketplace.lead.income')}
            fillingYearLabelString={t('marketplace.lead.filling_year')}
            actionLabelUnlockString={t('marketplace.lead.unlock')}
            actionLabelContinueString={t('marketplace.lead.continue')}
            unlockAction={(!hasMarketplacePlusLeadAccess && isLockedForBasicUsers) ? show(props => (
                <UnlockPrompt
                    {...props}
                    onUpgrade={onUpgrade}
                />
            )) : undefined}
            continueAction={(hasMarketplacePlusLeadAccess || !isLockedForBasicUsers) ? show(props => (
                <DetailPrompt
                    {...props}
                    lead={lead}
                    isIndividualType={isIndividualType}
                    revenueCurrency={partner.preferences?.currency?.code!}
                    premiumAccessLabel={isPremiumLead && !hasMarketplacePlusLeadAccess ? t('marketplace.premium_access_label') : undefined}
                    newLeadLabel={newLeadLabel}
                    lastChanceLabel={lastChanceLabel}
                    onContinue={onContinue}
                />
            )) : undefined}
        />
    )
}

export default LeadCard
