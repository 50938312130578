import React from 'react'
import styled from 'styled-components'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { TEXT_COLOR_MODIFIERS, TEXT_ALIGN_MODIFIERS } from '../../elements/modifiers/textModifiers'
import { MODIFIER_MARGIN_CONFIG, MODIFIER_PADDING_CONFIG } from '../../elements/modifiers/spaceModifiers'
import { colors, fonts } from '../../../theme/variables_new'

export const FormGroup = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)};
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)};
`

export const FormControl = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 6px;
    border: 1px solid ${colors.grey};

    font-size: ${fonts.size.s};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

    &:focus-within {
        border-color: ${colors.blue};
    }

    ${({ error }) => error ? `border-color: ${colors.red};` : ''}

    ${({ disabled }) => disabled ? `
        background-color: ${colors.lightGrey};
        cursor: not-allowed;
        opacity: 0.6;
    ` : ''}

    & > * {
        flex-basis: 0;
    }
`

export const FormInput = styled.input`
    padding: 10px 12px;
    background-color: inherit;
    outline: none;
    border: none;
    flex-grow: 3;

    &:not(:last-child) {
        padding-right: 0;
    }

    &:not(:first-child) {
        padding-left: 0;
    }

    &:disabled {
        cursor: not-allowed;
    }
`

export const FormPaymentInput = styled.div`
    padding: 10px 12px;
    background-color: inherit;
    outline: none;
    border: none;
    flex-grow: 3;

    &:not(:last-child) {
        padding-right: 0;
    }

    &:not(:first-child) {
        padding-left: 0;
    }
`

export const Select = styled.select`
    width: 100%;
    padding: 10px 12px;
    background-color: inherit;
    outline: none;
    border: none;
    flex-grow: 3;

    &:not(:last-child) {
        padding-right: 0;
    }

    &:not(:first-child) {
        padding-left: 0;
    }
`

export const Addon = styled.div`
    padding-left: 12px;
    padding-right: 12px;
    flex-grow: 1;
    background-color: transparent;
`

export const Label = styled.label`
    font-size: 12px;
    text-transform: uppercase;
    color: ${colors.fontLight};
`

export const Error = styled.div`
    color: ${colors.red};
    font-size: 14px;
`
