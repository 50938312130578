import styled from 'styled-components'
import Confetti from './Confetti'
import H1 from './H1'
import H2 from './H2'
import Splash from './Splash'
import SubHeader from './SubHeader'
import SeparatedSection from './SeparatedSection'
import Icon from './Icon'

const LandingPage = styled.div`
     background-color: #fff;
     margin-top: -30px;
     padding-top: 30px;
`

LandingPage.Confetti = Confetti
LandingPage.H1 = H1
LandingPage.H2 = H2
LandingPage.Splash = Splash
LandingPage.SeparatedSection = SeparatedSection
LandingPage.SubHeader = SubHeader
LandingPage.Icon = Icon

export default LandingPage
