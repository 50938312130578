import styled from 'styled-components'
import { cardLabelColor } from '../../../theme/variables'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { MODIFIER_MARGIN_CONFIG } from '../../elements/modifiers/spaceModifiers'

export default styled.div`
    color: ${cardLabelColor};
    font-size: 16px;
    line-height: 1.5em;
    margin-bottom: 16px;
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
`
