import styled from 'styled-components'
import { colors } from '../../../theme/variables_new'
import { applyResponsiveStyleModifiers, applyStyleModifiers } from 'styled-components-modifiers'
import { MODIFIER_MARGIN_CONFIG, MODIFIER_PADDING_CONFIG } from '../../elements/modifiers/spaceModifiers'
import { Flex } from '../../elements/Flex'
import { Modal } from 'react-bootstrap'

export const Page = styled.div`
    width: 100%;
    color: ${colors.darkBlue}
    ${applyResponsiveStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_PADDING_CONFIG)}
`

export const CircleNumber = styled.div`
    display: inline-block;
    position: relative;
    width: ${({ small }) => small ? 30 : 40}px;
    height: ${({ small }) => small ? 30 : 40}px;
    border-radius: 50%;
    background-color: ${({ active }) => active ? colors.blue : colors.grey};
    ${({ actionable }) => actionable && 'cursor: pointer;'}

    &::before {
        content: '${({ number }) => number}';
        font-weight: bold;
        font-size: ${({ small }) => small ? 16 : 20}px;
        position: absolute;
        top: 53%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: ${colors.white};
    }

    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)}
`

export const DetailsCard = styled(Flex)`
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0px;
    opacity: 0;
    overflow: hidden;
    transition: all .3s ease-in-out;
    background-color: ${colors.white};

    ${({ active }) => active &&
    '   height: 100%;\n' +
    '   opacity: 1;'}
`

export const ChevronButton = styled.span`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
    color: ${({ disabled }) => disabled ? colors.darkBlue + '40' : colors.darkBlue};
    ${({ disabled }) => disabled && '\n' +
    '   cursor: not-allowed;\n' +
    '   & > svg {\n' +
    '       pointer-events: none;\n' +
    '}'}
`

export const CenteredList = styled.ul`
    list-style-type: none;
    padding-left: 0;
    text-align: center;
`

export const CenteredModal = styled(Modal)`
    .modal-dialog {
        width: 520px;
        margin-top: 300px !important;
    }
`

export const LeadCardLogo = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 160px;
    height: auto;
    transform: translate(-50%, -50%);
    opacity: .1;
`

export const Tooltip = styled.div`
    cursor: help;
    position: relative;

    &:before,
    &:after {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        -webkit-font-smoothing: antialiased;
        margin: 0 auto;
        opacity: 0;
        visibility: hidden;
        z-index: 9;
        transition: 0.3s ease;
    }

    &:before {
        content: "";
        height: 0;
        width: 0;
        top: calc(100% - 9px);
        border-style: solid;
        border-width: 10px;
        border-color: transparent transparent #333 transparent;
    }

    &:after {
        content: attr(data-tooltip);
        white-space: nowrap;
        background: #333;
        padding: 0.5rem 1rem 0.5rem 1rem;
        font-size: 0.9rem;
        box-sizing: border-box;
        top: calc(100% + 11px);
        color: #fff;
        border-radius: 2px;
        text-shadow: 0px 1px 1px #000;
        z-index: 9;
    }

    &:hover:before,
    &:hover:after {
        opacity: 1;
        visibility: visible;
        transition: 0.4s ease-in-out 0.3s;
    }
`
