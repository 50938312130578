import React from 'react'
import { withTranslation } from 'react-i18next'

@withTranslation('', { wait: true })
export default class InvoiceLineItem extends React.PureComponent {
    render() {
        const { lineItem, t } = this.props

        return (
            <div>{ lineItem.itemName }</div>
        )
    }
}
