import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const GET_SECTORS = 'sectors/GET_SECTORS'
export const GET_SECTORS_SUCCESS = 'sectors/GET_SECTORS_SUCCESS'
export const GET_SECTORS_FAIL = 'sectors/GET_SECTORS_FAIL'

export const GET_PARTNER_SECTORS = 'sectors/GET_PARTNER_SECTORS'
export const GET_PARTNER_SECTORS_SUCCESS = 'sectors/GET_PARTNER_SECTORS_SUCCESS'
export const GET_PARTNER_SECTORS_FAIL = 'sectors/GET_PARTNER_SECTORS_FAIL'

export const UPDATE_PARTNER_SECTORS = 'sectors/UPDATE_PARTNER_SECTORS'
export const UPDATE_PARTNER_SECTORS_SUCCESS = 'sectors/UPDATE_PARTNER_SECTORS_SUCCESS'
export const UPDATE_PARTNER_SECTORS_FAIL = 'sectors/UPDATE_PARTNER_SECTORS_FAIL'

export const getSectors = criteria => ({
    [CALL_API]: {
        types: [ GET_SECTORS, GET_SECTORS_SUCCESS, GET_SECTORS_FAIL ],
        endpoint: '/sectors',
        schema: schemas.sectors,
        options: {
            body: criteria,
        },
    },
})

export const getPartnerSectors = partnerId => ({
    [CALL_API]: {
        types: [ GET_PARTNER_SECTORS, GET_PARTNER_SECTORS_SUCCESS, GET_PARTNER_SECTORS_FAIL ],
        endpoint: `/partners/${partnerId}/sectors`,
        schema: schemas.sectors,
        options: {
            body: {
                limit: 1000,
            },
        },
    },
    partnerId,
})

export const updatePartnerSectors = (partnerId, sectorIds) => ({
    [CALL_API]: {
        types: [ UPDATE_PARTNER_SECTORS, UPDATE_PARTNER_SECTORS_SUCCESS, UPDATE_PARTNER_SECTORS_FAIL ],
        endpoint: `/partners/${partnerId}/sectors`,
        options: {
            method: 'put',
            body: {
                sectorIds,
            },
        },
    },
})

