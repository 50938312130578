import React from 'react'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { Flex } from '../Flex'
import { ChevronButton, CircleNumber } from './styles'
import { Text } from '../text/Text'

export default class PaginationControl extends React.Component {

    renderPageNumbers = () => {
        const { total, current, offset = 2 } = this.props
        const pageIndexArray = []

        let origin = 1
        let top = total

        switch (true) {
            case current <= 3:
                origin = 1
                top = total > 5 ? 5 : total
                break
            case current > 3 && current < total - 2:
                origin = current - 2
                top = current + 2
                break
            case current >= total - 2:
                origin = total > 5 ? total - 4 : 1
                top = total
                break
        }

        for (let i = origin; i <= top; i++) {
            pageIndexArray.push(i)
        }

        if (top !== total) {
            if (total - top > 1) {
                pageIndexArray.push('...')
            }
            pageIndexArray.push(total)
        }

        if (origin !== 1) {
            if (origin > 2) {
                pageIndexArray.unshift('...')
            }
            pageIndexArray.unshift(1)
        }

        if (total === 0) {
            pageIndexArray.push(1)
        }

        return pageIndexArray.map(pageIndex => {
            if (pageIndex === '...') {
                return <Text modifiers={[ 'mX_1', 'bold' ]}>{pageIndex}</Text>
            }

            return <CircleNumber
                modifiers={[ 'mX_1' ]}
                small
                actionable
                number={pageIndex}
                active={pageIndex === current}
                onClick={() => this.props.goTo(pageIndex)}
            />
        })
    }

    render() {
        const { current, total } = this.props

        return <Flex modifiers={[ 'alignCenter', 'justifyCenter' ]}>
            <ChevronButton disabled={current === 1 || !current}>
                <ChevronLeft size={24} onClick={this.props.goDown}/>
            </ChevronButton>
            {this.renderPageNumbers()}
            <ChevronButton disabled={(current === total) || !total}>
                <ChevronRight size={24} onClick={this.props.goUp} />
            </ChevronButton>
        </Flex>
    }
}
