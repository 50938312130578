import React from 'react'
import styled from 'styled-components'
import Amount from '../../Amount'
import Button from '../../Button'
import Text from '../../texts/Text'
import Card from '../../Card'
import Flex from '../../layout/Flex'
import Grid from '../../layout/Grid'
import { ChevronsRight } from 'react-feather'
import { LeadCardHeader, LeadCardHeaderProps } from '../LeadCard'

export interface Product {
    id: number
    product: {
        name: string
    }
    quantity: number
    description?: string
}

export interface LeadDetailProps extends LeadCardHeaderProps {
    revenueAmount: number
    revenueCurrency: string
    caseTitle: string
    continueAction?: () => void
    description?: string
    fillingYear?: string
    products?: Product[]
    fillingYearLabelString: string
    actionLabelContinueString: string
    productLabelString: string
    caseLabelString: string
    descriptionLabelString: string
    incomeRevenueLabelString: string
    disabled: boolean
}

const Ul = styled.ul({
    margin: 0,
    padding: 0,
    paddingLeft: 18
})

function LeadDetail({
    title,
    zipCode,
    cityName,
    customerType,
    revenueAmount,
    revenueCurrency,
    continueAction,
    products,
    description,
    fillingYear,
    caseTitle,
    hide,
    incomeRevenueLabelString,
    fillingYearLabelString,
    actionLabelContinueString,
    productLabelString,
    caseLabelString,
    descriptionLabelString,
    premiumAccessLabel,
    icon,
    newLeadLabel,
    lastChanceLabel,
    disabled,
}: LeadDetailProps) {

    return <>
        <LeadCardHeader
            title={title}
            zipCode={zipCode}
            cityName={cityName}
            customerType={customerType}
            premiumAccessLabel={premiumAccessLabel}
            newLeadLabel={newLeadLabel}
            lastChanceLabel={lastChanceLabel}
            hide={hide}
            icon={icon}
        />
        <Card.Line />
        <Card.Content px={3} pt={3}>
            <Grid gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))" gridGap={3}>
                <Card.Content>
                    <Flex flexDirection="column" mb={3}>
                        <Text size="medium" color="impact" bold mb={1}>
                            {incomeRevenueLabelString}
                        </Text>
                        <Text size="small">
                            <Amount number={revenueAmount} currency={revenueCurrency} maximumFractionDigits={0} />
                        </Text>
                    </Flex>
                    {fillingYear && <Flex flexDirection="column" mb={3}>
                        <Text size="medium" color="impact" bold mb={1}>
                            {fillingYearLabelString}
                            {':'}
                        </Text>
                        <Text size="small">{fillingYear}</Text>
                    </Flex>}
                    {products && Boolean(products?.length) && <Flex flexDirection="column" mb={3}>
                        <Text size="medium" color="impact" bold mb={1}>{productLabelString}</Text>
                        <Ul>
                            {products.map(product => <li key={product.id}>
                                <Flex flexDirection="column" mt={1}>
                                    <Text size="small">
                                        {product.product.name}
                                        {' '}
                                        {product.quantity ? ` x ${product.quantity}` : ''}
                                    </Text>
                                    {product.description && <Ul>
                                        <li><Text size="small">{product.description}</Text></li>
                                    </Ul>}
                                </Flex>
                            </li>)}
                        </Ul>
                    </Flex>}
                </Card.Content>
                <Card.Content>
                    <Flex flexDirection="column" mb={3}>
                        <Text size="medium" color="impact" bold mb={1}>{caseLabelString}</Text>
                        <Text size="small">{caseTitle}</Text>
                    </Flex>
                    {description && <Flex flexDirection="column" mb={3}>
                        <Text size="medium" color="impact" bold mb={1}>{descriptionLabelString}</Text>
                        <Text size="small">{description}</Text>
                    </Flex>}
                </Card.Content>
            </Grid>
        </Card.Content>
        <Card.Line />
        <Flex mt={3} px={3} justifyContent="center" flexDirection="column">
            <Button variant="primary" grow disabled={disabled} onClick={continueAction}>
                {actionLabelContinueString}
                <Flex as={ChevronsRight} ml={2}/>
            </Button>
        </Flex>
    </>
}

export default LeadDetail
