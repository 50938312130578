export const SHOW_MODAL = 'modal/show'
export const HIDE_MODAL = 'modal/hide'
export const DISABLE_MODAL = 'modal/disable'
export const UN_DISABLE_MODAL = 'modal/un_disable'

export const showModal = modalProps => ({
    type: SHOW_MODAL,
    modalProps,
})

export const disableModal = () => ({
    type: DISABLE_MODAL,
})

export const unDisableModal = () => ({
    type: UN_DISABLE_MODAL,
})

export const hideModal = () => ({
    type: HIDE_MODAL,
})
