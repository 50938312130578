import { useAgerasApiMutation } from 'ageras-api-client/src'
import { useAuth } from 'ageras-api-client/src/auth'
import { KEY_COUPONS } from 'ageras-api-client/src/queryKeys'
import Flex from 'components/src/layout/Flex'
import React, { useCallback, useState } from 'react'
import Modal from 'components/src/modals/Modal'
import { ModalProps } from 'components/src/modals'
import Prompt from 'components/src/Marketplace/Prompt'
import Card from 'components/src/Card'
import Button from 'components/src/Button'
import Text from 'components/src/texts/Text'
import Heading from 'components/src/texts/Heading'
import { FormControl } from 'components/src/inputs/FormControl'
import { Input } from 'components/src/inputs/Input'
import { ChevronsRight } from 'react-feather'
import {
    CurrencyResource,
    PartnerResource,
    PrepaymentResource,
    PrepaymentsApiFetchParamCreator,
} from 'ageras-api-client/src/api'
import { TFunction, useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useQueryClient } from '@tanstack/react-query'
import { SchemaOf } from 'yup'
import * as yup from 'yup'
import config from '../../config'
import { PARTNER_PREPAYMENT_PAID, sendSegmentData } from '../../utils/segment'

interface FormValues {
    amount: number,
}

interface PrePaymentModalProps extends ModalProps {
    token: string
    currency: CurrencyResource
    partnerId: number
    geoCode: string
    partner: PartnerResource
}

function PrePaymentModal(props: PrePaymentModalProps) {
    const [ submitting, setSubmitting ] = useState<boolean>(false)
    const { t } = useTranslation('translation')
    const queryClient = useQueryClient()
    const minAmount = config.minimumMarketplacePrePaymentAmount[props.geoCode as keyof typeof config.minimumMarketplacePrePaymentAmount]

    const initialValues = {
        amount: minAmount,
    }

    const validationSchema = useCallback((t: TFunction): (SchemaOf<FormValues>) => yup.object().shape({
        amount: yup.number().min(minAmount, t('marketplace.prepayments.errors.amount_too_low')).required(t('marketplace.prepayments.errors.invalid_amount')),
    }), [ minAmount ])

    const mutation = useAgerasApiMutation<PrepaymentResource>(
        (actionData: PrepaymentResource) => PrepaymentsApiFetchParamCreator().prepaymentsCreate(actionData),
        {
            meta: {
                authorization: 'Bearer ' + props.token
            },
            onSuccess: (response) => {
                props.onHide()
                queryClient.invalidateQueries([ KEY_COUPONS ])
                sendSegmentData(props.partner, PARTNER_PREPAYMENT_PAID, {
                    prepaymentAmount: response.amount?.amount,
                })
            }
        }
    )

    const onSubmit = useCallback(async (values: FormValues) => {
        try {
            setSubmitting(true)
            const prepayment: PrepaymentResource = {
                partnerId: props.partnerId,
                amount: {
                    amount: values.amount
                }
            }
            await mutation.mutateAsync(prepayment)
        } catch (e) {
        } finally {
            setSubmitting(false)
        }
    }, [ mutation, props.partnerId ])

    const formik = useFormik<FormValues>({
        initialValues,
        onSubmit,
        validateOnChange: false,
        validationSchema: validationSchema(t),
    })

    return <Modal {...props} size="extraSmall">
        <Prompt hide={props.onHide}>
            <form onSubmit={formik.handleSubmit}>
                <Card.Content px={3} pb={3} pt={2} width="100%">
                    <Heading size="medium" mb={3}>{t('marketplace.prepayments.add_funds_modal_title')}</Heading>
                    <FormControl error={formik.errors.amount} label={t('marketplace.prepayments.minimum_amount_label')}>
                        <Input type="number" name="amount" icon={<span>{props.currency.symbol || props.currency.code}</span>} value={String(formik.values.amount)} onChange={formik.handleChange} min={0} step={'1'}/>
                    </FormControl>
                    <Text mt={2} size="small">{t('marketplace.prepayments.add_funds_modal_fine_print')}</Text>
                </Card.Content>
                <Card.Content px={3} pb={3} pt={2} width="100%">
                    <Flex as={Button} variant="primary" disabled={submitting} type="submit">
                        {t('marketplace.prepayments.action_add_button_text')}
                        <Flex as={ChevronsRight} ml={2}/>
                    </Flex>
                </Card.Content>
            </form>
        </Prompt>
    </Modal>
}

export default PrePaymentModal
