import React from 'react'
import styled from 'styled-components'

import InputOverride from './InputOverride'
import Input from './Input'
import Label from './Label'

export const Radio = styled.div`
    margin: 0.5rem;
`
const RadioInput = styled.div`
  display: inline-block;
`

const input = props =>
    <RadioInput>
        <Input {...props}/>
        <InputOverride/>
    </RadioInput>

Radio.Input = input
Radio.Label = Label
