import { CALL_API } from '../middleware/api'
import * as schemas from '../schemas'

export const GET_PARTNER_ASSIGNEES = 'partnerAssignees/GET_PARTNER_ASSIGNEES'
export const GET_PARTNER_ASSIGNEES_SUCCESS = 'partnerAssignees/GET_PARTNER_ASSIGNEES_SUCCESS'
export const GET_PARTNER_ASSIGNEES_FAIL = 'partnerAssignees/GET_PARTNER_ASSIGNEES_FAIL'

export const getPartnerAssignees = partnerId => ({
    [CALL_API]: {
        types: [
            GET_PARTNER_ASSIGNEES,
            GET_PARTNER_ASSIGNEES_SUCCESS,
            GET_PARTNER_ASSIGNEES_FAIL,
        ],
        endpoint: `/partners/${partnerId}/assignees`,
        schema: schemas.partnerAssignees,
        options: {
            body: {
                limit: 500,
            },
        },
    },
})
