import React from 'react'
import FormattedAmount from '../utils/FormattedAmount'
import { StarRatingInput } from '../utils/StarRatingInput'
import styled from 'styled-components'
import { Flex } from '../elements/Flex'
import Portrait from '../elements/Portrait'
import { Card } from '../blocks/Card'
import { media } from '../utils/styledMedia'
import PartnerLogoContent from '../../components/elements/PartnerLogoContent'
import CheckMarkCircle from '../elements/CheckMarkCircle'
import { cardSeparatorColor, primaryColor } from '../../theme/variables'
import { Button } from '../elements/ButtonNew'
import { useTranslation } from 'react-i18next'

const PartnerInfoContent = styled(Card.Content)`
    display: flex;
    align-items: center;
    ${media.max.landscapeMobile`
          padding-right: 15px;
    `}
    .mdi-chevron-right {
      font-size: 28px;
    }
    ${media.min.landscapeMobile`
      height: 190px;
      .mdi-chevron-right {
        display: none;
      }
      ${Card.Label} {
        font-size: 14px;
      }
  `}
`

const CheckMarkBadge = styled(CheckMarkCircle).attrs({
    modifiers: [ 'primary' ],
    size: 14,
})`
    position: absolute;
    right: 0px;
    top: -15px;
`

const Banner = styled.div`
  text-transform: uppercase;
  color: #65768c;
  font-weight: 700;
  font-size: 12px;
  border-top: 1px solid ${cardSeparatorColor};
  border-bottom: 8px solid ${primaryColor};
  text-align: center;
  padding: 10px;
`

const PartnerActionContent = styled(Card.Content)`
  ${Card.Label} {
    font-size: 14px;
  }
`

const CompanyName = styled(Card.Label)`
  word-break: break-all;
  font-size: 14px;
`

const StarRating = styled(StarRatingInput)`
  position: absolute;
  right: 30px;
`

export const QuoteBox = ({
   partner,
   quote,
   lead,
   handleOpenQuoteClick,
   creditSystemIsLoading
}) => {

    const { t } = useTranslation()

    const totalAmount = lead.customerInfo.showPricesIncludingVat ? quote.totalAmountInclVat : quote.totalAmountExclVat

    const amountText = lead.customerInfo.showPricesIncludingVat ? 'total_amount_including_vat' : 'total_amount_excluding_vat'

    const companyName = (quote.partner.companyName.length) > 28
        ? quote.partner.companyName.slice(0, 25) + '...'
        : quote.partner.companyName

    const userName = partner.partnerUser.name

    const partnerUserName = (userName && userName.length > 65) ? userName.slice(0, 60) + '...' : userName

    const isExclusivePartner = (partner.features || []).some(el => el === 'client_portal_badge')

    return quote && partner && partner.partnerUser && <>
        <Card modifiers={[ 'mB_1' ]} style={{ cursor: 'pointer'}} onClick={() => handleOpenQuoteClick(partner.id)}>
            {isExclusivePartner && <CheckMarkBadge />}
            <PartnerInfoContent>
                <Flex>
                    <div>
                        <Portrait
                            square
                            modifiers={[ 'mR_2' ]}
                            name={userName || companyName}
                            image={partner.partnerUser.profilePicture}
                            size={60}
                        />
                    </div>
                    <div>
                        <CompanyName>{companyName}</CompanyName>
                        <Card.Title><b>{partnerUserName}</b></Card.Title>
                        <Card.Label>{quote.partner.city}</Card.Label>
                        {partner.rating && Boolean(partner.rating.stars) && <StarRating filled={partner.rating.stars} total={5} />}
                    </div>
                </Flex>
                <b className="mdi mdi-chevron-right" />
            </PartnerInfoContent>
            <Card.Line />
            {quote.progress === 'draft' &&
            <PartnerActionContent>
                <p style={{ textAlign: 'center', fontStyle: 'italic' }}>{t('quotes.quote.awaiting_offer')}</p>
            </PartnerActionContent>
            }
            {quote.progress !== 'draft' &&
            <PartnerActionContent>
                {totalAmount.amount > 0 && <Flex modifiers={[ 'justifySpaceBetween']} style={{ flexDirection: 'column' }}>
                    <Card.Label>{t(`quotes.quote.${amountText}`)}</Card.Label>
                    <Card.Title>
                        <b>
                            <FormattedAmount
                                currency={totalAmount.currency}
                                amount={totalAmount.amount}
                                decimals={2}
                            />
                        </b>
                    </Card.Title>
                </Flex>}
                {totalAmount.amount === 0 &&
                    <Flex>
                        <Card.Text>
                            {t('quotes.quote.quote_description_without_price')}
                        </Card.Text>
                    </Flex>
                }
                {!lead.customerInfo.showPricesIncludingVat && totalAmount.amount > 0 &&
                <Card.Meta modifiers={[ 'mT_0' ]}>
                    {t('quotes.quote.total_amount_including_vat')} &nbsp;
                    <FormattedAmount
                        currency={quote.totalAmountInclVat.currency}
                        amount={quote.totalAmountInclVat.amount}
                        decimals={2}
                    />
                </Card.Meta>}
                {partner.id && <Button
                    className="hidden-xs"
                    modifiers={[ creditSystemIsLoading ? 'disabled' : isExclusivePartner ? 'primary' : 'action', 'p_2', 'fullWidth', 'mT_2', 'btnBlock' ]}
                    disabled={creditSystemIsLoading}
                >
                    {totalAmount.amount === 0 ? t('quotes.quote.read_more') : t('quotes.quote.see_offer')}
                </Button>}
            </PartnerActionContent>
            }
            {isExclusivePartner && <Banner>— {t('quotes.quote.strategic_partner')} —</Banner>}
        </Card>
        {partner.logo && <Card modifiers={[ 'mB_1' ]}>
            <PartnerLogoContent>
                <img src={partner.logo} alt="partner logo" />
            </PartnerLogoContent>
        </Card>}
        {partner.attributes && Object.values(partner.attributes).map(el =>
            el && el.identifier === 'interview_quote' && <Card>
                <Card.Content>
                    <Card.Intro modifiers={[ 'm_0' ]}>
                        <i>&quot;{el.selected[0].title}&quot;</i>
                    </Card.Intro>
                </Card.Content>
            </Card>
        )}
        {partner.features && partner.features.includes('show_association_logo') && <Card>
            <Card.Content className="text-center">
                <Card.Text ><b>{partner.companyName} {t('quotes.quote.is_member_of')}</b></Card.Text>
                <br />
                <img src={partner.association.imageLogo} alt="association logo"  />
            </Card.Content>
        </Card>}
    </>
}
