import styled from 'styled-components'
import { cardBorderColor, cardPadding } from '../../../theme/variables'
import { MODIFIER_MARGIN_CONFIG, MODIFIER_PADDING_CONFIG } from '../../elements/modifiers/spaceModifiers'
import { applyStyleModifiers } from 'styled-components-modifiers'

export default styled.div`
    padding: ${props => props.padding || cardPadding};
    border-bottom: 1px solid ${cardBorderColor};
    overflow: auto;
    font-size: 16px;
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)}
`
