import React from 'react'
import { withTranslation } from 'react-i18next'
import LoadingAnimation from '../utils/LoadingAnimation'
import ConversationMessage from './messages/ConversationMessage'
import NoteMessage from './messages/NoteMessage'

@withTranslation('', { wait: true })
export default class MessageFeed extends React.Component {
    render() {
        const { t, messages, customer, isLoading } = this.props

        if(isLoading) {
            return <LoadingAnimation/>
        }

        return (
            <div ref="feed" className="feed">
                {(messages.length === 0 && !isLoading) &&
                    <div className="no-notes">
                        <img src="/images/icon-messages.svg" alt=""/>
                        <p>
                            {t('projects.no_messages', { name: customer.firstName + ' ' + customer.lastName })}
                        </p>
                    </div>
                }
                { messages.map((message, i) => {
                    if(message.participant) {
                        return <ConversationMessage message={message} key={i} />
                    }
                    if(message.partnerUser) {
                        return <NoteMessage message={message} key={i} />
                    }
                    return null
                }) }
            </div>
        )
    }
}
