import { useAgerasApi } from 'ageras-api-client/src'
import {
    PartnerCouponResource,
    PartnerCouponResult,
    PartnersApiFetchParamCreator,
} from 'ageras-api-client/src/api'
import { useAuth } from 'ageras-api-client/src/auth'
import { KEY_COUPONS } from 'ageras-api-client/src/queryKeys'
import { useMemo } from 'react'

export const COIN_DIVIDE_NUMBER = 100

function usePartnerPrePaidBalance() {
    const auth = useAuth()

    const { data: prePaidBalance, isLoading, error } = useAgerasApi<PartnerCouponResult>(PartnersApiFetchParamCreator().partnersCouponsIndex(
        String(auth.partnerUser?.partnerId),
        undefined,
        undefined,
        undefined,
        undefined,
        String(PartnerCouponResource.TypeEnum.MarketplaceCoupon),
    ), {
        queryKey: [ KEY_COUPONS ]
    })

    const countPrePaidBalance = useMemo((): number => {
        return prePaidBalance?.data?.reduce((balance: number, current: PartnerCouponResource) => {
            return current.punchesLeft ? balance + current.punchesLeft : balance
        }, 0) ?? 0
    }, [ prePaidBalance ])

    return { balance: countPrePaidBalance / COIN_DIVIDE_NUMBER, isLoading, error }
}

export default usePartnerPrePaidBalance
