import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { currentClientSelector } from '../../../selectors/auth'
import { Page } from '../../../components/layout/Page'
import Checkout from '../../../components/checkout/Checkout'

@connect(
    (state, props) => ({
        checkoutId: props.match.params.checkoutId,
        client: currentClientSelector(state),
    }),
)
@withTranslation('', { wait: true })
export default class CheckoutPage extends React.Component {
    render() {
        const {
            checkoutId,
            client,
            match,
        } = this.props

        return (
            <Page>
                <Checkout
                    checkoutId={checkoutId}
                    buyer={client}
                    action={match.params.action}
                    paymentMethod={match.params.paymentMethod}
                />
            </Page>
        )
    }
}
