import { applyResponsiveStyleModifiers, applyStyleModifiers } from 'styled-components-modifiers'
import styled from 'styled-components'
import { colors } from '../../theme/variables_new'

const MODIFIER_TAB_CONFIG = {
    active: () => `
        color: ${colors.blue};
        border-bottom: 4px solid ${colors.blue};
        padding-bottom: 8px;
    `,
    maxWidth: () => `
        flex-basis: 0;
        flex-grow: 1;
    `,
    positionRelative: () => `
        position: relative;
    `,
}

export default styled.div`
    display: inline-block;
    cursor: pointer;
    padding: 24px 24px 12px;
    ${({ disabled }) => disabled ? `
        color: ${colors.grey};
        cursor: not-allowed;
    ` : ''};
    ${({ sticky }) => sticky ? `
        position: sticky;
        right: 0;
        background: white;
    ` : ''};
    ${applyStyleModifiers(MODIFIER_TAB_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_TAB_CONFIG)}
`
