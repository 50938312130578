import React from 'react'
import Box from '../blocks/Box'
import { Flex } from '../elements/Flex'
import { Headline, Text } from '../elements/text/Text'
import { Button } from '../elements/Button'
import { connect } from 'react-redux'
import { hideModal } from '../../actions/modal'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'

const CenteredModal = styled(Modal)`
    .modal-dialog {
        width: 520px;
        margin-top: 300px !important;
    }
`

@connect(
    (state) => ({
        partner: state.entities.partners[state.modal.partnerId],
    }),
    {
        hideModal,
    }
)
@withTranslation('', { wait: true })
export default class ConfirmModal extends React.Component {

    close = () => {
        this.props.hideModal()
    }
    confirm = () => {
        const { callback, callbackParams } = this.props.modalProps
        this.props.hideModal()
        callback(callbackParams)
    }

    renderContentBody = () => {
        const { title, body, cancelText, confirmText } = this.props.modalProps

        return <React.Fragment>
            <Box.Header modifiers={[ 'pX_2', 'pT_2', 'pB_1' ]}>
                <Headline modifiers={[ 'tiny' ]}>{title}</Headline>
            </Box.Header>
            <Box.Line />
            <Box.Content modifiers={[ 'p_2' ]}>
                <Text>
                    {body}
                </Text>
            </Box.Content>
            <Box.Line />
            <Flex modifiers={[ 'justifyEnd', 'p_2' ]}>
                <Button onClick={this.close} modifiers={[ 'secondary', 'bottomCenter', 'regular', 'mX_2' ]}>{cancelText}</Button>
                <Button onClick={this.confirm} modifiers={[ 'action', 'bottomCenter', 'regular' ]}>{confirmText}</Button>
            </Flex>
        </React.Fragment>
    }

    render = () => {
        return <CenteredModal
            show={true}
            onHide={this.props.hideModal}
            className="new"
        >
            <Box>
                {this.renderContentBody()}
            </Box>
        </CenteredModal>
    }
}
