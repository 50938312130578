import React from 'react'
import styled from 'styled-components'
import Input from './_Input'
import Bevel from './_Bevel'
import Knob from './_Knob'

const InputLabel = styled.label`
    align-items: center;
    display: flex;
    flex-shrink: 0;
    font-weight: normal;
    margin: 7px 0;
`

const SwitchCheckboxInput = props =>
    <InputLabel style={ props.labelMargins }>
        <Input
            {...props}
            {...props.input}
            {...props.inputProps}
        />
        <Bevel>
            <Knob/>
        </Bevel>
    </InputLabel>


export default SwitchCheckboxInput
