import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'

@withTranslation('', { wait: true })
export default class CardCheckBox extends Component {

    static defaultProps = {
        checked: false,
        disabled: false,
    }

    static propTypes = {
        onClick: PropTypes.func,
        checked: PropTypes.bool,
        text: PropTypes.string,
        value: PropTypes.any.isRequired,
    }

    clicked() {
        if (typeof this.props.onClick === 'function' && !this.props.disabled) {
            this.props.onClick(this.props.value, !this.props.checked)
        }
    }

    render() {
        const { text, checked, disabled, t } = this.props

        const falseClass = this.props.softFalse ? 'soft-false' : 'hard-false'
        const tooltipMessage = checked ? t('subscription.cards.deactivate') : t('subscription.cards.activate')

        return (
            <OverlayTrigger placement="left" overlay={<Tooltip id={'tooltip-' + checked}>{tooltipMessage}</Tooltip>}>
                <div className="card-checkbox" onClick={this.clicked.bind(this)}>
                    <div className={`checkbox ${falseClass} ${checked ? 'checked' : ''} ${disabled ? 'disabled' : ''}`} />
                    <div className="info">
                        {text}
                    </div>
                </div>
            </OverlayTrigger>
        )
    }
}
