import Card from 'components/src/Card'
import Text from 'components/src/texts/Text'
import Heading from 'components/src/texts/Heading'
import Button from 'components/src/Button'
import Modal  from 'components/src/modals/Modal'
import Prompt  from 'components/src/Marketplace/Prompt'
import { ModalProps } from 'components/src/modals'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

interface UnlockModalProps extends ModalProps {
    onUpgrade: () => void
}

function UnlockModal({ onHide, zIndex, onUpgrade }: UnlockModalProps) {
    const { t } = useTranslation('translation')

    const handleClick = useCallback(() => {
        onUpgrade()
        onHide()
    }, [ onHide, onUpgrade ])

    return <Modal onHide={onHide} zIndex={zIndex} size="extraSmall">
        <Prompt hide={onHide}>
            <Card.Content px={3} pb={3} pt={2} width="100%">
                <Heading textAlign="center" size="medium" mb={3}>{t('marketplace.unlock_prompt.title')}</Heading>
                <Text mb={3} textAlign="center">{t('marketplace.unlock_prompt.text')}</Text>
                <Button variant="secondary" onClick={handleClick}>{t('marketplace.unlock_prompt.action_text')}</Button>
            </Card.Content>
        </Prompt>
    </Modal>
}

export default UnlockModal
