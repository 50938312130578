import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const GET_PRODUCTS = 'products/GET_PRODUCTS'
export const GET_PRODUCTS_SUCCESS = 'products/GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_FAIL = 'products/GET_PRODUCTS_FAIL'

export const GET_PARTNER_QUOTE_PRODUCT_PRICES = 'products/GET_PARTNER_QUOTE_PRODUCT_PRICES'
export const GET_PARTNER_QUOTE_PRODUCT_PRICES_SUCCESS = 'products/GET_PARTNER_QUOTE_PRODUCT_PRICES_SUCCESS'
export const GET_PARTNER_QUOTE_PRODUCT_PRICES_FAIL = 'products/GET_PARTNER_QUOTE_PRODUCT_PRICES_FAIL'

export const CREATE_PARTNER_QUOTE_PRODUCT_PRICE = 'products/CREATE_PARTNER_QUOTE_PRODUCT_PRICE'
export const CREATE_PARTNER_QUOTE_PRODUCT_PRICE_SUCCESS = 'products/CREATE_PARTNER_QUOTE_PRODUCT_PRICE_SUCCESS'
export const CREATE_PARTNER_QUOTE_PRODUCT_PRICE_FAIL = 'products/CREATE_PARTNER_QUOTE_PRODUCT_PRICE_FAIL'

export const DELETE_PARTNER_QUOTE_PRODUCT_PRICE = 'products/DELETE_PARTNER_QUOTE_PRODUCT_PRICE'
export const DELETE_PARTNER_QUOTE_PRODUCT_PRICE_SUCCESS = 'products/DELETE_PARTNER_QUOTE_PRODUCT_PRICE_SUCCESS'
export const DELETE_PARTNER_QUOTE_PRODUCT_PRICE_FAIL = 'products/DELETE_PARTNER_QUOTE_PRODUCT_PRICE_FAIL'

export const GET_PARTNER_REVENUE_SEGMENTS = 'products/GET_PARTNER_REVENUE_SEGMENTS'
export const GET_PARTNER_REVENUE_SEGMENTS_SUCCESS = 'products/GET_PARTNER_REVENUE_SEGMENTS_SUCCESS'
export const GET_PARTNER_REVENUE_SEGMENTS_FAIL = 'products/GET_PARTNER_REVENUE_SEGMENTS_FAIL'

export const CREATE_PARTNER_REVENUE_SEGMENT = 'products/CREATE_PARTNER_REVENUE_SEGMENT'
export const CREATE_PARTNER_REVENUE_SEGMENT_SUCCESS = 'products/CREATE_PARTNER_REVENUE_SEGMENT_SUCCESS'
export const CREATE_PARTNER_REVENUE_SEGMENT_FAIL = 'products/CREATE_PARTNER_REVENUE_SEGMENT_FAIL'

export const COPY_PARTNER_REVENUE_SEGMENT = 'products/COPY_PARTNER_REVENUE_SEGMENT'
export const COPY_PARTNER_REVENUE_SEGMENT_SUCCESS = 'products/COPY_PARTNER_REVENUE_SEGMENT_SUCCESS'
export const COPY_PARTNER_REVENUE_SEGMENT_FAIL = 'products/COPY_PARTNER_REVENUE_SEGMENT_FAIL'

export const DELETE_PARTNER_REVENUE_SEGMENT = 'products/DELETE_PARTNER_REVENUE_SEGMENT'
export const DELETE_PARTNER_REVENUE_SEGMENT_SUCCESS = 'products/DELETE_PARTNER_REVENUE_SEGMENT_SUCCESS'
export const DELETE_PARTNER_REVENUE_SEGMENT_FAIL = 'products/DELETE_PARTNER_REVENUE_SEGMENT_FAIL'

export const GET_PARTNER_QUOTE_PRODUCT_COMMENTS = 'products/GET_PARTNER_QUOTE_PRODUCT_COMMENTS'
export const GET_PARTNER_QUOTE_PRODUCT_COMMENTS_SUCCESS = 'products/GET_PARTNER_QUOTE_PRODUCT_COMMENTS_SUCCESS'
export const GET_PARTNER_QUOTE_PRODUCT_COMMENTS_FAIL = 'products/GET_PARTNER_QUOTE_PRODUCT_COMMENTS_FAIL'

export const CREATE_PARTNER_QUOTE_PRODUCT_COMMENT = 'products/CREATE_PARTNER_QUOTE_PRODUCT_COMMENT'
export const CREATE_PARTNER_QUOTE_PRODUCT_COMMENT_SUCCESS = 'products/CREATE_PARTNER_QUOTE_PRODUCT_COMMENT_SUCCESS'
export const CREATE_PARTNER_QUOTE_PRODUCT_COMMENT_FAIL = 'products/CREATE_PARTNER_QUOTE_PRODUCT_COMMENT_FAIL'

export const DELETE_PARTNER_QUOTE_PRODUCT_COMMENT = 'products/DELETE_PARTNER_QUOTE_PRODUCT_COMMENT'
export const DELETE_PARTNER_QUOTE_PRODUCT_COMMENT_SUCCESS = 'products/DELETE_PARTNER_QUOTE_PRODUCT_COMMENT_SUCCESS'
export const DELETE_PARTNER_QUOTE_PRODUCT_COMMENT_FAIL = 'products/DELETE_PARTNER_QUOTE_PRODUCT_COMMENT_FAIL'

export const getProducts = criteria => ({
    [CALL_API]: {
        types: [ GET_PRODUCTS, GET_PRODUCTS_SUCCESS, GET_PRODUCTS_FAIL ],
        endpoint: '/products',
        schema: schemas.products,
        options: {
            body: {
                limit: 500,
                ...criteria,
            },
        },
    },
})

export const getPartnerQuoteProductPrices = (partnerId, criteria) => ({
    [CALL_API]: {
        types: [ GET_PARTNER_QUOTE_PRODUCT_PRICES, GET_PARTNER_QUOTE_PRODUCT_PRICES_SUCCESS, GET_PARTNER_QUOTE_PRODUCT_PRICES_FAIL ],
        endpoint: `/partners/${partnerId}/quoteproductprices`,
        schema: schemas.partnerQuoteProductPrices,
        options: {
            body: {
                limit: 200,
                ...criteria,
            },
        },
    },
})

export const createPartnerQuoteProductPrice = (partnerId, body) => ({
    [CALL_API]: {
        types: [ CREATE_PARTNER_QUOTE_PRODUCT_PRICE, CREATE_PARTNER_QUOTE_PRODUCT_PRICE_SUCCESS, CREATE_PARTNER_QUOTE_PRODUCT_PRICE_FAIL ],
        endpoint: `/partners/${partnerId}/quoteproductprices`,
        schema: schemas.partnerQuoteProductPrice,
        options: {
            method: 'post',
            body,
        },
    },
})

export const deletePartnerQuoteProductPrice = (partnerId, quotePriceId) => ({
    [CALL_API]: {
        types: [ DELETE_PARTNER_QUOTE_PRODUCT_PRICE, DELETE_PARTNER_QUOTE_PRODUCT_PRICE_SUCCESS, DELETE_PARTNER_QUOTE_PRODUCT_PRICE_FAIL ],
        endpoint: `/partners/${partnerId}/quoteproductprices/${quotePriceId}`,
        options: {
            method: 'delete',
        },
    },
    quotePriceId,
})

export const getPartnerRevenueSegments = (partnerId, criteria) => ({
    [CALL_API]: {
        types: [ GET_PARTNER_REVENUE_SEGMENTS, GET_PARTNER_REVENUE_SEGMENTS_SUCCESS, GET_PARTNER_REVENUE_SEGMENTS_FAIL ],
        endpoint: `/partners/${partnerId}/revenuesegments`,
        schema: schemas.partnerRevenueSegments,
        options: {
            body: {
                limit: 50,
                ...criteria,
            },
        },
    },
})

export const createPartnerRevenueSegment = (partnerId, body) => ({
    [CALL_API]: {
        types: [ CREATE_PARTNER_REVENUE_SEGMENT, CREATE_PARTNER_REVENUE_SEGMENT_SUCCESS, CREATE_PARTNER_REVENUE_SEGMENT_FAIL ],
        endpoint: `/partners/${partnerId}/revenuesegments`,
        schema: schemas.partnerRevenueSegment,
        options: {
            method: 'post',
            body,
        },
    },
})

export const deletePartnerRevenueSegment = (partnerId, segmentId) => ({
    [CALL_API]: {
        types: [ DELETE_PARTNER_REVENUE_SEGMENT, DELETE_PARTNER_REVENUE_SEGMENT_SUCCESS, DELETE_PARTNER_REVENUE_SEGMENT_FAIL ],
        endpoint: `/partners/${partnerId}/revenuesegments/${segmentId}`,
        options: {
            method: 'delete',
        },
    },
    segmentId,
})

export const copyPartnerRevenueSegment = (partnerId, revenueSegmentId, leadTypeIds, withPrices, copyType, forceCopy) => ({
    [CALL_API]: {
        types: [ COPY_PARTNER_REVENUE_SEGMENT, COPY_PARTNER_REVENUE_SEGMENT_SUCCESS, COPY_PARTNER_REVENUE_SEGMENT_FAIL ],
        endpoint: `/partners/${partnerId}/revenuesegments/actions`,
        schema: schemas.partnerRevenueSegment,
        options: {
            method: 'post',
            body: {
                action: 'copy',
                revenue_segment_id: revenueSegmentId,
                lead_type_ids: leadTypeIds,
                with_prices: withPrices,
                copy_type: copyType,
                force_copy: forceCopy,
            },
        },
    },
    forceCopy,
})

export const getPartnerQuoteProductComments = (partnerId, criteria) => ({
    [CALL_API]: {
        types: [ GET_PARTNER_QUOTE_PRODUCT_COMMENTS, GET_PARTNER_QUOTE_PRODUCT_COMMENTS_SUCCESS, GET_PARTNER_QUOTE_PRODUCT_COMMENTS_FAIL ],
        endpoint: `/partners/${partnerId}/quoteproductcomments`,
        schema: schemas.partnerQuoteProductComments,
        options: {
            body: {
                limit: 50,
                ...criteria,
            },
        },
    },
})

export const createPartnerQuoteProductComment = (partnerId, body) => ({
    [CALL_API]: {
        types: [ CREATE_PARTNER_QUOTE_PRODUCT_COMMENT, CREATE_PARTNER_QUOTE_PRODUCT_COMMENT_SUCCESS, CREATE_PARTNER_QUOTE_PRODUCT_COMMENT_FAIL ],
        endpoint: `/partners/${partnerId}/quoteproductcomments`,
        schema: schemas.partnerQuoteProductComment,
        options: {
            method: 'post',
            body,
        },
    },
})

export const deletePartnerQuoteProductComment = (partnerId, quoteCommentId) => ({
    [CALL_API]: {
        types: [ DELETE_PARTNER_QUOTE_PRODUCT_COMMENT, DELETE_PARTNER_QUOTE_PRODUCT_COMMENT_SUCCESS, DELETE_PARTNER_QUOTE_PRODUCT_COMMENT_FAIL ],
        endpoint: `/partners/${partnerId}/quoteproductcomments/${quoteCommentId}`,
        options: {
            method: 'delete',
        },
    },
    quoteCommentId,
})
