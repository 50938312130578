import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RefundRequestFormReason from './RefundRequestFormReason'
import RefundRequestFormInvoice from './RefundRequestFormInvoice'
import RefundRequestFormBrowseFile from './RefundRequestFormBrowseFile'
import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import RefundRequestFormTerms from './RefundRequestFormTerms'

@connect(
    state => ({}),
    { replace }
)
export default class RefundRequestForm extends Component {

    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)
        this.nextPage = this.nextPage.bind(this)
        this.previousPage = this.previousPage.bind(this)
        this.state = {
            page: 1,
            data: {},
        }
    }

    nextPage(data) {
        this.setState({ page: this.state.page + 1, data: { ...this.state.data, ...data } })
    }

    previousPage() {
        this.setState({ page: this.state.page - 1 })
        if (this.state.page - 1 === 0) {
            this.props.replace('/account/refunds')
        }
    }

    render() {
        const { page, data } = this.state

        return (
            <div>
                {page === 1 && <RefundRequestFormTerms previousPage={this.previousPage} onSubmit={this.nextPage}/>}
                {page === 2 && <RefundRequestFormInvoice previousPage={this.previousPage} onSubmit={this.nextPage} />}
                {page === 3 && <RefundRequestFormReason previousPage={this.previousPage} invoiceId={data.invoice.id} onSubmit={this.nextPage} />}
                {page === 4 && <RefundRequestFormBrowseFile previousPage={this.previousPage} onSubmit={this.props.onSubmit} />}
            </div>
        )
    }
}
