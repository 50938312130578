import React from 'react'
import nl2br from './Nl2br'
import { withTranslation } from 'react-i18next'

@withTranslation('', { wait: true })
export default class ReadMore extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            expanded: false,
        }
    }
    render() {
        const { children, t, className, characters = 500 } = this.props
        const { expanded } = this.state

        const truncate = children.length > characters
        const text = expanded ?
            children :
            (truncate ? children.substring(0, characters) + '...' : children)

        return (
            <div className={className}>
                {nl2br(text)}

                {!expanded && truncate && <a className="readmore-link" onClick={() => this.setState({ expanded: true })}>{t('actions.read_more')}</a>}
            </div>
        )
    }
}
