import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Field, reduxForm } from 'redux-form'
import LoadingAnimation from '../utils/LoadingAnimation'
import { connect } from 'react-redux'
import { currentPartnerSelector } from '../../selectors/auth'

@withTranslation('', { wait: true })
@reduxForm({
    form: 'partnerUser',
    fields: [
        'email',
        'firstName',
        'lastName',
        'password',
        'phone',
        'mobile',
        'wantsEmails',
        'wantsTextMessages',
    ],
})
@connect(
    state => ({
        partner: currentPartnerSelector(state)
    })
)
export default class PartnerUserForm extends Component {
    render() {
        const {
            t,
            handleSubmit,
            submitting,
            editing,
            partner,
            fields: {
                email,
                firstName,
                lastName,
                password,
                phone,
                mobile,
                wantsEmails,
                wantsTextMessages,
                wantsDailyEmail,
            },
        } = this.props

        if (submitting) {
            return <LoadingAnimation />
        }

        return (
            <form onSubmit={handleSubmit}>
                <p className="intro-text">
                    {t('partner_user.form.introduction')}
                </p>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>{t('partner_user.form.name')}</label>
                            <Field className="form-control"
                                   name="firstName"
                                   component="input"
                                   type="text"
                                   {...firstName}
                                   placeholder={t('partner_user.form.name')} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>{t('partner_user.form.last_name')}</label>
                            <Field className="form-control"
                                   name="lastName"
                                   component="input"
                                   type="text"
                                   {...lastName}
                                   placeholder={t('partner_user.form.last_name')} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>{t('partner_user.form.email')}</label>
                            <Field className="form-control"
                                   name="email"
                                   component="input"
                                   type="email"
                                   {...email}
                                   placeholder={t('partner_user.form.email')} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>{t('partner_user.form.password')}</label>
                            <Field className="form-control"
                                   name="password"
                                   component="input"
                                   type="password"
                                   {...password}
                                   placeholder={t('partner_user.form.password')} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>{t('partner_user.form.telephone')}</label>
                            <Field className="form-control"
                                   name="phone"
                                   component="input"
                                   type="text"
                                   {...phone}
                                   placeholder={t('partner_user.form.telephone')} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>{t('partner_user.form.mobile')}</label>
                            <Field className="form-control"
                                   name="mobile"
                                   component="input"
                                   type="text"
                                   {...mobile}
                                   placeholder={t('partner_user.form.mobile')} />                                  </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <div className="checkbox-rounded">
                                <Field className="form-control"
                                       name="wantsEmails"
                                       component="input"
                                       {...wantsEmails}
                                       type="checkbox"
                                />
                                <label>{t('partner_user.form.wants_emails')}</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <div className="checkbox-rounded">
                                <Field className="form-control"
                                       name="wantsTextMessages"
                                       component="input"
                                       {...wantsTextMessages}
                                       type="checkbox"
                                />
                                <label>{t('partner_user.form.wants_sms')}</label>
                            </div>
                        </div>
                    </div>
                    {partner.hasSubscription && <div className="col-md-6">
                        <div className="form-group">
                            <div className="checkbox-rounded">
                                <Field className="form-control"
                                       name="wantsDailyEmail"
                                       component="input"
                                       {...wantsDailyEmail}
                                       type="checkbox"
                                />
                                <label>{t('partner_user.form.wants_daily_email')}</label>
                            </div>
                        </div>
                    </div>}
                </div>
                <hr />
                <button className="btn btn-action btn-lg btn-block" type="submit">
                    {!editing ? t('partner_user.save') : t('partner_user.save_edit')}
                </button>
            </form>
        )
    }
}
