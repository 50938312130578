import { CALL_API } from '../middleware/api'
import config from '../config'

export const SAVE_STRIPE_CARD = 'STRIP/SAVE_STRIPE_CARD'

export const GET_STRIPE_API_KEY = 'stripe/GET_STRIPE_API_KEY'
export const GET_STRIPE_API_KEY_SUCCESS = 'stripe/GET_STRIPE_API_KEY_SUCCESS'
export const GET_STRIPE_API_KEY_FAIL = 'stripe/GET_STRIPE_API_KEY_FAIL'

export const CREATE_STRIPE_SETUP_INTENT = 'stripe/CREATE_STRIPE_SETUP_INTENT'
export const CREATE_STRIPE_SETUP_INTENT_SUCCESS = 'stripe/CREATE_STRIPE_SETUP_INTENT_SUCCESS'
export const CREATE_STRIPE_SETUP_INTENT_FAIL = 'stripe/CREATE_STRIPE_SETUP_INTENT_FAIL'

export const SAVE_STRIPE_PAYMENT_METHOD = 'stripe/SAVE_STRIPE_PAYMENT_METHOD'
export const SAVE_STRIPE_PAYMENT_METHOD_SUCCESS = 'stripe/SAVE_STRIPE_PAYMENT_METHOD_SUCCESS'
export const SAVE_STRIPE_PAYMENT_METHOD_FAIL = 'stripe/SAVE_STRIPE_PAYMENT_METHOD_FAIL'

export const saveStripeCard = stripeCard => ({
    type: SAVE_STRIPE_CARD,
    stripeCard,
})

export const getStripeApiKey = geoCode => ({
    [CALL_API]: {
        types: [ GET_STRIPE_API_KEY, GET_STRIPE_API_KEY_SUCCESS, GET_STRIPE_API_KEY_FAIL ],
        endpoint: `${config.agerasServices}/payments/stripe`,
        options: {
            body: {
                geoCode,
            },
        },
    },
})

export const createStripeSetupIntent = (partner) => ({
    [CALL_API]: {
        types: [ CREATE_STRIPE_SETUP_INTENT, CREATE_STRIPE_SETUP_INTENT_SUCCESS, CREATE_STRIPE_SETUP_INTENT_FAIL ],
        endpoint: `${config.agerasServices}/stripe/create-setup-intent`,
        options: {
            method: 'post',
            body: {
                partnerId: partner.id,
            },
        },
        suppressLoader: true,
    },
})

export const saveStripePaymentMethod = (partner, stripeSetupIntentId) => ({
    [CALL_API]: {
        types: [ SAVE_STRIPE_PAYMENT_METHOD, SAVE_STRIPE_PAYMENT_METHOD_SUCCESS, SAVE_STRIPE_PAYMENT_METHOD_FAIL ],
        endpoint: `${config.agerasServices}/stripe/confirm-setup-intent`,
        options: {
            method: 'post',
            body: {
                partnerId: partner.id,
                setupIntentId: stripeSetupIntentId,
            },
        },
        suppressLoader: true,
    },
})
