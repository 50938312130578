import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { getRenderedTerm } from '../../actions/terms'
import { renderedTermsByIdSelector } from '../../selectors/terms'
import { Page } from '../../components/layout/Page'
import {
    Card,
    Header,
    Content,
} from '../../components/cards/index'
import LoadingAnimation from '../../components/utils/LoadingAnimation'
import ReactMarkdown from 'react-markdown'
import queryString from 'query-string'

@connect(
    (state, props) => ({
        terms: renderedTermsByIdSelector(state)(props.match.params.termsId),
        /* The output from queryString.parse seems to be weird :/ */
        termsData: JSON.parse(JSON.stringify(queryString.parse(props.location.search))),
    }),
    {
        getRenderedTerm,
    }
)
@withTranslation('', { wait: true })
export default class TermsPage extends React.Component {
    componentDidMount() {
        this.props.getRenderedTerm(
            this.props.match.params.termsId,
            this.props.termsData
        )
    }

    render() {
        const {
            terms,
        } = this.props

        if(!terms) {
            return <LoadingAnimation />
        }

        return (
            <Page>
                <Card>
                    <Header>
                        {terms.headline}
                    </Header>
                    <Content>
                        <ReactMarkdown>
                            {terms.body}
                        </ReactMarkdown>
                    </Content>
                </Card>
            </Page>
        )
    }
}
