import React from 'react'
import { Card } from '../../../../../components/blocks/Card/index'
import { Flex } from '../../../../../components/elements/Flex'
import styled from 'styled-components'
import FormattedAmount from '../../../../../components/utils/FormattedAmount'
import { primaryColor } from '../../../../../theme/variables'
import { Col, Row } from 'react-bootstrap'

const POINTS = [
    { title: 'Fremtidens digitale bogføring', icon: 'cellphone-android' },
    { title: 'Spar tid og penge', icon: 'timer' },
    { title: 'Hurtig og korrekt bogføring', icon: 'briefcase-check' },
    { title: 'Fuldt overblik over din forretning', icon: 'finance' },
    { title: 'Let overgang fra nuværende regnskapsprogram', icon: 'transfer' },
]

const Icon = styled.i`
  color: ${primaryColor};
  font-size: 28px;
  margin-right: 25px;
`

const FormattedAmountText = styled.div`
  font-weight: 500;
  font-size: 22px;
  color: ${primaryColor}
`

export const MenetoPriceInfoCard = ({ quote, t }) =>
    <Card>
        <Card.Content modifiers={[ 'pY_5', 'pX_6' ]}>
            <Card.Heading modifiers={[ 'primaryColor', 'mB_2' ]}>{t('quotes.quote.quote_title')}</Card.Heading>
            <Row>
                <Col sm={6}>
                    <Card.Text>
                        Alt i en bogføringsløsning til en fast månedlig pris
                    </Card.Text>
                </Col>
                <Col sm={6} className="text-right">
                    <Card.Text modifiers={[ 'primaryColor' ]}>
                        <FormattedAmountText>
                            <FormattedAmount
                                amount={quote.totalAmountExclVat.amount / 12}
                                currency={quote.totalAmountExclVat.currency}
                                decimals={2}
                            />/måned
                        </FormattedAmountText>
                    </Card.Text>
                    <Card.Meta>
                        Oprettelse 0,00 Kr <br />
                        Ingen bindingsperiode <br />
                        Min. pris 1 måned &nbsp;
                        <FormattedAmount
                            amount={quote.totalAmountExclVat.amount / 12}
                            currency={quote.totalAmountExclVat.currency}
                            decimals={2}
                        />
                    </Card.Meta>
                </Col>
            </Row>
            {POINTS.map(el =>
                <Card.ListItem block modifiers={[ 'pX_3', 'pY_2', 'mT_1' ]}>
                    <Flex modifiers={[ 'alignCenter' ]}>
                        <Icon className={`mdi mdi-${el.icon}`} />
                        <span>{el.title}</span>
                    </Flex>
                </Card.ListItem>
            )}
        </Card.Content>
    </Card>
