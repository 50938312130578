import { useAgerasApi } from 'ageras-api-client/src'
import { MeetingsApiFetchParamCreator } from 'ageras-api-client/src/api'
import FormattedDate from '../../utils/FormattedDate'
import React from 'react'
import { Flex } from '../../elements/Flex'
import { Text } from '../../elements/text/Text'
import { useTranslation } from 'react-i18next'
import { timezoneFromGeoCode, timezoneStringFromGeoCode } from '../../../utils/timezones'

export default function MeetingRequests({ partner, leadId }) {
    const { t } = useTranslation()
    const { data: meetings, isLoading } = useAgerasApi(MeetingsApiFetchParamCreator().meetingsIndex(
        Number(leadId),
        Number(partner.id)
    ))

    const timezone = timezoneFromGeoCode(partner.geo.code);
    const timezoneString = timezoneStringFromGeoCode(partner.geo.code);

    return (
        <Flex modifiers={[ 'directionColumn' ]}>
            <Flex>
                <Text modifiers={[ 'bold', 'mB_1' ]}>
                    {t('quotes.meeting_title')}
                </Text>
            </Flex>
            {!isLoading && meetings?.data.map(meeting => meeting?.leadPartnerMeetingRequestTimeslots.map(
                timeslot =>
                <Flex key={timeslot.id} style={{gap: '20px'}} modifiers={[ 'fullWidth', 'justifyStart', 'alignCenter' ]}>
                    <Flex modifiers={[ 'justifyStart', 'alignCenter' ]} style={{gap: '5px'}}>
                        <i  className="mdi mdi-clock-outline" />
                        <FormattedDate date={timeslot.from} format={'date'} timezone={timezone} />
                    </Flex>
                    <div>
                        <FormattedDate date={timeslot.from} format={'time'} timezone={timezone} />
                    </div>
                    <div>
                        {'-'}
                    </div>
                    <div>
                        <FormattedDate date={timeslot.to} format={'time'} timezone={timezone} />
                        { timezoneString ? ` (${timezoneString})` : '' }
                    </div>
                </Flex>
            ))}
        </Flex>
    )
}
