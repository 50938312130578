import config from '../config'
import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const GET_TERMS = 'terms/GET_TERMS'
export const GET_TERMS_SUCCESS = 'terms/GET_TERMS_SUCCESS'
export const GET_TERMS_FAIL = 'terms/GET_TERMS_FAIL'

export const GET_RENDERED_TERMS = 'terms/GET_RENDERED_TERMS'
export const GET_RENDERED_TERMS_SUCCESS = 'terms/GET_RENDERED_TERMS_SUCCESS'
export const GET_RENDERED_TERMS_FAIL = 'terms/GET_RENDERED_TERMS_FAIL'

export const ACCEPT_TERMS = 'terms/ACCEPT_TERMS'
export const ACCEPT_TERMS_SUCCESS = 'terms/ACCEPT_TERMS_SUCCESS'
export const ACCEPT_TERMS_FAIL = 'terms/ACCEPT_TERMS_FAIL'

export const GET_ACCEPTED_TERMS = 'terms/GET_ACCEPTED_TERMS'
export const GET_ACCEPTED_TERMS_SUCCESS = 'terms/GET_ACCEPTED_TERMS_SUCCESS'
export const GET_ACCEPTED_TERMS_FAIL = 'terms/GET_ACCEPTED_TERMS_FAIL'

export const getTerms = criteria => dispatch => dispatch({
    [CALL_API]: {
        types: [ GET_TERMS, GET_TERMS_SUCCESS, GET_TERMS_FAIL ],
        endpoint: '/terms',
        schema: schemas.termss,
        options: {
            body: {
                ...criteria,
                limit: 1000,
            },
        },
    },
})

export const getTerm = termId => ({
    [CALL_API]: {
        types: [ GET_RENDERED_TERMS, GET_RENDERED_TERMS_SUCCESS, GET_RENDERED_TERMS_FAIL ],
        endpoint: `/terms/${termId}`,
        schema: schemas.terms,
    },
})

export const getRenderedTerm = (termId, data) => ({
    [CALL_API]: {
        types: [ GET_RENDERED_TERMS, GET_RENDERED_TERMS_SUCCESS, GET_RENDERED_TERMS_FAIL ],
        endpoint: `${config.agerasServices}/templaterenderer/terms/${termId}`,
        schema: schemas.renderedTerms,
        options: {
            method: 'post',
            body: {
                data,
            },
        },
        suppressLoader: true,
    },
})

export const acceptTerms = termsId => ({
    [CALL_API]: {
        types: [ ACCEPT_TERMS, ACCEPT_TERMS_SUCCESS, ACCEPT_TERMS_FAIL ],
        endpoint: `/terms/${termsId}/actions`,
        schema: schemas.terms,
        options: {
            method: 'post',
            body: {
                action: 'accept',
            },
        },
        suppressLoader: true,
    },
})

export const getAcceptedTerms = (acceptorId, termsId) => ({
    [CALL_API]: {
        types: [ GET_ACCEPTED_TERMS, GET_ACCEPTED_TERMS_SUCCESS, GET_ACCEPTED_TERMS_FAIL ],
        endpoint: '/terms/accepted',
        schema: schemas.termss,
        options: {
            body: {
                acceptorId,
                termsId,
                acceptorType: 'partner',
                limit: 1000,
            },
        },
    },
})
