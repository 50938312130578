import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { RefundList } from '../finance/refunds/RefundList'
import * as refunds from '../../actions/refunds'
import { currentPartnerSelector } from '../../selectors/auth'
import PageTitle from '../utils/PageTitle'
import { getInvoicesRefundRequestList } from '../../actions/invoices'

@connect(
    state => ({
        refunds: state.pages.invoiceRefunds.refunds
            .map(id => state.entities.invoiceRefundRequests[id]),
        isLoading: state.pages.invoiceRefunds.isLoading,
        pagination: state.pages.invoiceRefunds.pagination,
        partner: currentPartnerSelector(state),
    }),
    {
        getInvoicesRefundRequestList,
    }
)
@withTranslation('', { wait: true })

export default class RefundsListPage extends Component {

    componentDidMount() {
        this.props.getInvoicesRefundRequestList(this.props.partner.id)
    }

    loadMore(page) {
        this.props.getInvoicesRefundRequestList(this.props.partner.id, page, true)
    }

    render() {
        const {
            isLoading,
            pagination: {
                pages,
                page,
            },
            refunds,
            t,
        } = this.props
        let hasMore = isLoading ? false : page < pages

        return (
            <div className="card">
                <PageTitle identifier="refunds.title" />
                <h2>{ t('refunds.title') }</h2>
                <RefundList
                    loadMore={page => this.loadMore(page)}
                    hasMore={hasMore}
                    refunds={refunds}
                    t={t}
                />
            </div>
        )
    }
}
