import styled from 'styled-components'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { MODIFIER_MARGIN_CONFIG } from './modifiers/spaceModifiers'
import { BACKGROUND_COLOR_MODIFIERS } from './modifiers/backgroundColorModifiers'

export default styled.div.attrs({ className: 'mdi mdi-check' })`
  display: inline-block;
  border-radius: 100%;
  text-align: center;
  line-height: 1;
  color: #fff;
  padding: ${props => props.size || 'initial'}px;
  ${applyStyleModifiers(BACKGROUND_COLOR_MODIFIERS)}
  ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
`
