import React from 'react'
import FormattedDate from '../../utils/FormattedDate'
import FormattedAmount from '../../utils/FormattedAmount'
import { Label } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { generateApiUrl } from '../../../utils/urls'
import { Link } from 'react-router-dom'

@withTranslation('', { wait: true })
export default class InvoiceItem extends React.Component {
    render() {
        const { invoice, t } = this.props

        let paymentStatusColor

        switch (invoice.status) {
            case 'credited':
            case 'authorized':
            case 'partially_credited':
            case 'payment_in_progress':
                paymentStatusColor = 'info'
                break
            case 'overdue':
            case 'disputed':
            case 'unpaid ':
            case 'failed':
                paymentStatusColor = 'danger'
                break
            case 'paid':
                paymentStatusColor = 'success'
                break
        }

        let downloadUrl = generateApiUrl('invoices/' + invoice.id + '.pdf')

        const totalIncludingVat = invoice.amounts.totalIncludingVat

        return (
            <tr>
                <td className="table-mobile-cell">
                    <table className="definition-list">
                        <tbody>
                            <tr>
                                <td>{ t('invoices.number') }:</td>
                                <td><Link to={ '/invoices/' + invoice.id }>{t(`invoices.type.${invoice.type}`)} #{invoice.invoiceNumber}</Link></td>
                            </tr>
                            <tr>
                                <td>{ t('invoices.date') }:</td>
                                <td><FormattedDate date={invoice.createdAt} format="date" /></td>
                            </tr>
                            <tr>
                                <td>{ t('invoices.amount') }:</td>
                                <td><FormattedAmount amount={totalIncludingVat.amount} currency={totalIncludingVat.currency} /></td>
                            </tr>
                            <tr>
                                <td>{ t('invoices.due_date') }:</td>
                                <td><FormattedDate date={invoice.dueAt} format="date" /></td>
                            </tr>
                            <tr>
                                <td>{ t('invoices.status') }:</td>
                                <td><Label bsStyle={paymentStatusColor}>
                                    {invoice.type === 'invoice' ? t('invoices.' + invoice.status) : t(`invoices.type.${invoice.type}`)}
                                </Label></td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                <td><Link to={ '/invoices/' + invoice.id }>{t(`invoices.type.${invoice.type}`)} #{invoice.invoiceNumber}</Link></td>
                <td><FormattedDate date={invoice.createdAt} format="date" /></td>
                <td className="amount"><FormattedAmount amount={totalIncludingVat.amount} currency={totalIncludingVat.currency} /></td>
                <td><FormattedDate date={invoice.dueAt} format="date" /></td>
                <td>
                    <Label bsStyle={paymentStatusColor}>
                        {invoice.type === 'invoice' ? t('invoices.' + invoice.status) : t(`invoices.type.${invoice.type}`)}
                    </Label>
                </td>
            </tr>
        )
    }
}
