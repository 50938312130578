import {
    GET_LEAD, GET_LEAD_SUCCESS, GET_LEAD_FAIL,
} from '../../actions/leads'

const initialState = {
    isLoading: false,
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    case GET_LEAD:
        return {
            ...state,
            isLoading: true,
        }
    case GET_LEAD_SUCCESS:
    case GET_LEAD_FAIL:
        return {
            ...state,
            isLoading: false,
        }
    default:
        return state
    }
}
