import {
    UN_DISABLE_MODAL,
    DISABLE_MODAL,
    HIDE_MODAL,
    SHOW_MODAL,
} from '../actions/modal'

export default function reducer(state = {}, action = {}) {
    switch (action.type) {
        case SHOW_MODAL:
            return {
                ...action.modalProps,
            }
        case DISABLE_MODAL:
            return {
                ...state,
                disabled: true,
            }
        case HIDE_MODAL:
            return {}
        case UN_DISABLE_MODAL:
            return {
                ...state,
                disabled: false,
            }
        default:
            return state
    }
}
