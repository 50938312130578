import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import ExpandButton from '../utils/ExpandButton'
import AwardForm from '../cv/AwardForm'
import { Button } from '../elements/Button'
import { Flex } from '../elements/Flex'

@withTranslation('', { wait: true })
export default class AwardItem extends Component {

    constructor(props) {
        super(props)

        this.state = {
            editing: false,
        }
    }

    clickEdit = () => {
        this.setState({
            editing: true,
        })
    }

    onCloseEdit = () => {
        this.setState({
            editing: false,
        })
    }

    onSubmitUpdate = data => {
        this.setState({
            editing: false,
        })
        data.id = this.props.award.id
        this.props.onSubmitEdit(data)
    }

    deleteAward = () => {
        this.setState({
            editing: false,
        })

        this.props.onDeleteAward(this.props.award.id)
    }

    render() {
        const {
            award, t,
        } = this.props
        const { editing } = this.state

        return (
            <div className="item">
                <Flex modifiers={[ 'justifySpaceBetween', 'alignCenter' ]}>
                    <div>
                        <h3>{award.awardName}</h3>
                        <small>{award.issuer}</small><br />
                        <span>{award.description}</span>
                        <br/>
                        <p className="meta">
                            {moment(award.rewardDate).format('MMMM YYYY')}
                        </p>
                    </div>
                    {!editing && <Button
                        modifiers={[ 'secondary' ]} onClick={this.clickEdit}>{t('cv.education.edit')}</Button>
                    }
                </Flex>
                {editing && <ExpandButton
                    expanded={true}
                    onClose={this.onCloseEdit}
                    className="success"
                    content={
                        <div>
                            <AwardForm handleSubmit={this.onSubmitUpdate} initialValues={{
                                ...award,
                                fromDateYear: moment(award.rewardDate).format('YYYY'),
                                fromDateMonth: moment(award.rewardDate).format('MM'),
                            }}/>
                            <button style={{ marginTop: 20 }} className="btn btn-lg btn-danger btn-block" onClick={this.deleteAward}>{t('cv.award.delete')}</button>
                        </div>
                    }
                    header={t('cv.award.edit')}
                />
                }
            </div>
        )
    }
}
