import React from 'react'
import { FormGroup } from 'react-bootstrap'
import { Error } from '../Error'
import MetaLabel from '../../elements/text/MetaLabel'
import FormControl from '../../elements/FormControl'

export const SelectInputGroup = ({
    className,
    options = [],
    input,
    label,
    meta,
    ...props
}) =>
    <FormGroup controlId={input.name} bsClass={'form-group ' + className || ''}>
        {label && <MetaLabel>{label}</MetaLabel>}
        <FormControl
            onChange={event => {
                const selectedValue = options.find(el => el.id == event.target.value)
                input.onChange(selectedValue)
            }}
            componentClass="select"
            defaultValue={input.value.id || ''}
            {...props}
        >
            <option value="" disabled hidden>Select..</option>
            {options.map(option =>
                <option
                    key={option.id}
                    value={option.id}
                >
                    {option.title}
                </option>
            )}
        </FormControl>
        <Error meta={meta} />
    </FormGroup>
