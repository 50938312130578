import React from 'react'
import {
    FormGroup,
    HelpBlock,
} from 'react-bootstrap'
import { Checkbox, Flex } from '../../cards'

const CheckboxInput = ({
   children,
   input: {
       value,
       onChange,
       name,
       ...inputProps
   },
   meta: { touched, error, warning },
}) =>
    <FormGroup className={touched && error && 'has-error'}>
        <Flex>
            <Checkbox
                name={name}
                id={name}
                onChange={onChange}
                value={value}
                checked={value}
                inputProps={inputProps}
            /> &nbsp;
            <span>{children}</span>
        </Flex>

        {touched &&
        ((error &&
            <HelpBlock>
                {error}
            </HelpBlock>) ||
            (warning &&
                <HelpBlock>
                    {warning}
                </HelpBlock>))}
    </FormGroup>

export default CheckboxInput
