import styled from 'styled-components'
import { backgroundColor, cardBorderColor } from '../../../theme/variables'

export default styled.div`
    position: relative;
    display: inline-block;
    height: 25px;
    width: 47px;
    background: ${backgroundColor};
    border: 1px solid ${cardBorderColor};
    border-radius: 28px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .07);
    cursor: pointer;
    transition: background-color .2s ease;
    margin-right: 10px;
`
