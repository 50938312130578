import SubscriptionCard from './SubscriptionCard'
import CreateSubscriptionCard from './CreateSubscriptionCard'
import LoadingAnimation from '../utils/LoadingAnimation'
import { useAgerasApi } from 'ageras-api-client/src'
import { CashierproductsApiFetchParamCreator, PartnersApiFetchParamCreator } from 'ageras-api-client/src/api'
import { isArray, isObject } from 'lodash'

export default function SubscriptionWidget({
    partner
}) {
    const { data: subscriptions, isLoading: loadingSubscriptions, refetch: refetchSubscriptions } = useAgerasApi(PartnersApiFetchParamCreator().partnersCashiersubscriptionsIndex(partner.id, true));
    const { data: paymentMethod, isLoading: loadingPaymentMethod } = useAgerasApi(PartnersApiFetchParamCreator().partnersCashierpaymentmethodsGet(partner.id));
    const { data: cashierproducts, isLoading: loadingProducts } = useAgerasApi(CashierproductsApiFetchParamCreator().cashierproductsIndex(partner.geo.code));

    const isLoading = loadingSubscriptions || loadingPaymentMethod || loadingProducts;
    const hasSubscription = !isLoading && Boolean(subscriptions?.data?.length);
    const hasPaymentMethod = paymentMethod && isObject(paymentMethod) && !isArray(paymentMethod);
    const hasProducts = Boolean(cashierproducts?.data?.length);
    const canCreateSubscription = !isLoading && !hasSubscription && hasProducts;

    return(
        <div>
            {isLoading && (
                <LoadingAnimation />
            )}
            {hasSubscription && subscriptions.data.map(subscription =>
                <SubscriptionCard key={subscription.id} subscription={subscription} onSubscriptionUpdated={refetchSubscriptions} />
            )}
            {canCreateSubscription && cashierproducts.data.map(cashierproduct =>
                <CreateSubscriptionCard key={cashierproduct.id} partner={partner} product={cashierproduct} fillPayment={!hasPaymentMethod}  />
            )}
        </div>
    )
}
