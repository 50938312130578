import styled from 'styled-components'
import { media } from '../../cards'
import { BACKGROUND_COLOR_MODIFIERS } from '../../elements/modifiers/backgroundColorModifiers'
import { primaryColor } from '../../../theme/variables'
import { applyStyleModifiers } from 'styled-components-modifiers'

const H1_MODIFIERS = {
    primary: () => `
        font-size: 52px;
        color: ${primaryColor};
        font-weight: 300;
        line-height: 1.2;
    `
}

export default styled.div`
    font-size: 75px;
    ${media.mobile`font-size: 32px`};
    text-align: center;
    ${applyStyleModifiers(H1_MODIFIERS)}

`
