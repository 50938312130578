import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Card } from '../blocks/Card'
import ReduxForm from '../forms/ReduxForm'
import { Field } from 'redux-form'
import { TextInput } from '../forms/inputs/TextInput'
import { Button } from '../elements/Button'
import { createImpressums, getPartner } from '../../actions/partners'
import { currentPartnerSelector } from '../../selectors/auth'
import { Flex } from '../elements/Flex'
import nl2br from '../utils/Nl2br'

@connect(
    state => ({
        partner: currentPartnerSelector(state),
    }),
    {
        createImpressums,
        getPartner,
    }
)
@withTranslation('', { wait: true })
export default class ImpressumPage extends React.Component {
    saveImpression = async data => {
        await this.props.createImpressums(this.props.partner.id, data)
        this.props.getPartner(this.props.partner.id)
    }

    render() {
        const { t, partner } = this.props
        return (
            <Card>
                <Card.Content>
                    <Card.Intro>{nl2br(t('impressum.impressum_description'))}</Card.Intro>
                    <ReduxForm onSubmit={this.saveImpression} form="partner" initialValues={partner.impressum}>
                        <Field name="text" component={TextInput} required/>
                        <Flex modifiers={[ 'justifyEnd' ]}>
                            <Button
                                type="submit"
                                modifiers={[ 'action' ]}
                            >
                                {t('impressum.btn_action')}
                            </Button>
                        </Flex>
                    </ReduxForm>
                </Card.Content>
            </Card>
        )
    }
}
