import React from 'react'
import { Card } from '../blocks/Card'
import { Flex } from '../elements/Flex'
import { connect } from 'react-redux'
import { getListOfReservedQuotes } from '../../actions/quotes'
import { withTranslation } from 'react-i18next'
import { reservedProjectsQuotesSelector } from '../../selectors/quotes'
import FormattedDate from '../utils/FormattedDate'
import { BtnLink } from '../elements/Links'
import moment from 'moment'

@connect(
    state => ({
        reservedQuotes: reservedProjectsQuotesSelector(state),
    }),
    {
        getListOfReservedQuotes,
    },
)
@withTranslation('', { wait: true })
export default class ClientsAwaitingQuotesCard extends React.PureComponent {
    componentDidMount() {
        this.props.getListOfReservedQuotes(this.props.partner.id)
    }

    render() {
        const {
            t,
            partner,
            reservedQuotes,
        } = this.props

        if (!partner.hasMarketingPackage || !reservedQuotes.length)
            return null

        const marketingSubscription = partner.subscription.find(sub => sub.packageType === 'Ageras Marketing Package')
        const reservedQuotesLB = marketingSubscription && reservedQuotes.filter(quote => moment(quote.createdAt).isAfter(marketingSubscription.createdAt))

        if (!reservedQuotesLB || !reservedQuotesLB.length) return null

        return (
            <Card>
                <Card.Header>{t('projects.quotes.awaiting_quotes_card_title')}</Card.Header>
                <Card.Content>
                    <Flex>
                        { reservedQuotesLB.map(quote =>
                            <Card modifiers={[ 'm_0', 'mR_2' ]}>
                                <Card.Content padding="15px">
                                    <p><b><i>{t('projects.quotes.lead_id')}  #{quote.leadId}</i></b></p>
                                    <p><i>
                                        {t('projects.quotes.waiting_since')} &nbsp;
                                        <FormattedDate date={quote.createdAt}/>
                                    </i></p>
                                    <BtnLink
                                        to={`/quote/${quote.leadId}`}
                                        modifiers={[ 'action', 'fullWidth', 'btnBlock' ]}
                                    >
                                        {t('projects.quotes.create_quote')}
                                    </BtnLink>
                                </Card.Content>
                            </Card>
                        )}
                    </Flex>
              </Card.Content>
            </Card>
        )
    }
}
