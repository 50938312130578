import { fadedTextColor } from '../../../theme/variables'
import styled from 'styled-components'
import { applyStyleModifiers } from 'styled-components-modifiers'

const MODIFIER_TEXT_CONFIG = {
    main: () => `
        font-weight: 500;
    `,
    secondary: () => `
        color: ${fadedTextColor};
        font-size:16px;
    `,
}

export default styled.div`
    ${applyStyleModifiers(MODIFIER_TEXT_CONFIG)}
`
