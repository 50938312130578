const initialState = {
    isLoading: false,
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    default:
        return state
    }
}
