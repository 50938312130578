import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Field, reduxForm } from 'redux-form'
import LoadingAnimation from '../utils/LoadingAnimation'

@withTranslation('', { wait: true })
@reduxForm({
    form: 'descriptionCv',
    fields: [ 'description' ],
})
export default class DescriptionForm extends Component {

    render() {
        const {
            t,
            handleSubmit,
            submitting,
            fields: {
                description,
            },
        } = this.props

        if(submitting) {
            return <LoadingAnimation />
        }

        return (
            <form onSubmit={handleSubmit}>
                <div className="form-group" >
                    <Field className="form-control"
                           rows="5"
                           component="textarea"
                           name="description"
                           placeholder={t('cv.description.placeholder')}
                           {...description}
                    />
                </div>
                <button className="btn btn-action btn-lg btn-block" type="submit">
                    {t('cv.description.save')}
                </button>
            </form>
        )
    }
}
