import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as auth from '../../actions/auth'
import { queryParametersSelector } from '../../selectors/routing'
import PasswordReminderForm from './PasswordReminderForm'
import PasswordResetForm from './PasswordResetForm'

@connect(
    state => ({
        queryParameters: queryParametersSelector(state),
    }),
    dispatch => ({
        sendPasswordReminder: bindActionCreators(auth.sendPasswordReminder, dispatch),
        resetPassword: bindActionCreators(auth.resetPassword, dispatch),
    })
)
export default class PasswordRecoveryCard extends React.Component {

    render() {
        const {
            queryParameters,
        } = this.props

        const initialValues = {
            email: queryParameters.email,
            token: queryParameters.token,
        }

        return (
            <div className="card login-card">
                { queryParameters.token && <PasswordResetForm initialValues={initialValues} /> || <PasswordReminderForm /> }
            </div>
        )
    }
}
