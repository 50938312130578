import React from 'react'
import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import queryString from 'query-string'
import SortIcon from 'react-feather/dist/icons/navigation-2'
import {
    hasLeadAccessSelector, hasMarketplaceLeadAccessSelector, hasMarketplaceSignUpAccessSelector
} from '../../../selectors/access'
import { Text, Span, Headline } from '../../elements/text/Text'
import { Container, Col, Row } from '../../layout/layout'
import { bkpInt } from '../../../theme/variables_new'
import WindowSizeListener from 'react-window-size-listener'
import { Page } from './styles'
import { getAuthPermissions } from '../../../actions/auth'
import { Flex } from '../../elements/Flex'
import { currentPartnerSelector } from '../../../selectors/auth'
import LeadCard from './LeadCard'
import PaginationControl from '../../elements/PaginationControl'
import { getLeads } from '../../../actions/leads'
import DropdownElement from '../../elements/Dropdown'
import { TextButton } from '../../elements/ButtonNew'
import { removeEmptyProperties, splitFilterString } from '../../utils'
import { showModal } from '../../../actions/modal'
import Box from '../../blocks/Box'
import moment from 'moment'
import LoadingAnimation from '../../utils/LoadingAnimation'
import { getLeadTypes } from '../../../actions/segmentation'

@connect(
    (state, { location: { search } }) => ({
        hasMarketplaceSignUpAccess: hasMarketplaceSignUpAccessSelector(state),
        hasMarketplaceLeadAccess: hasMarketplaceLeadAccessSelector(state),
        hasLeadAccess: hasLeadAccessSelector(state),
        partner: currentPartnerSelector(state),
        leads: state.entities.leads,
        isLoadingLeads: state.pages.leads.leads.isLoading,
        leadTypes: state.entities.leadTypes,
        revenueSegments: state.entities.revenueSegments,
        pagination: state.pages.leads.leads.pagination,
        criteria: {
            sort: search && queryString.parse(search)['sort'] || 'validated_at',
            industryId: search && queryString.parse(search)['industryId'] ? splitFilterString(queryString.parse(search)['industryId']) : '', // change to revenue segments
        },
    }),
    {
        getAuthPermissions,
        getLeadTypes,
        showModal,
        getLeads,
        replace,
    },
)
@withTranslation('', { wait: true })
export default class MarketplacePage extends React.Component {
    state = {
        width: 0,
        leadIds: [],
    }

    async componentDidMount() {
        window.scrollTo(0, 0)

        await this.props.getAuthPermissions()

        if (!this.props.marketplaceEnabledCountries.includes(this.props.partner.geo.code)) {
            return
        }

        this.props.getLeadTypes(this.props.partner.geo.code)
        this.retrieveData(this.props.criteria, this.props.pagination.page || 1)
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify({ ...prevProps.criteria }) !== JSON.stringify({ ...this.props.criteria })) {
            this.retrieveData(this.props.criteria)
        }
    }

    sorts = [
        {
            name: 'validated_at',
            displayName: this.props.t('marketplace.controls.sort_options.expires_at_asc'),
        },
        {
            name: '-validated_at',
            displayName: this.props.t('marketplace.controls.sort_options.expires_at_desc'),
        },
        {
            name: 'lead_quote_fee',
            displayName: this.props.t('marketplace.controls.sort_options.price_asc'),
        },
        {
            name: '-lead_quote_fee',
            displayName: this.props.t('marketplace.controls.sort_options.price_desc'),
        },
        {
            name: 'distance',
            displayName: this.props.t('marketplace.controls.sort_options.distance_asc'),
            hidden: !this.props.partner.geo.point.lat || !this.props.partner.geo.point.lon,
        },
        {
            name: '-distance',
            displayName: this.props.t('marketplace.controls.sort_options.distance_desc'),
            hidden: !this.props.partner.geo.point.lat || !this.props.partner.geo.point.lon,
        },
    ]

    validationDeadlineDays = {
        us: 5,
        dk: 5,
        no: 5,
        se: 5,
        nl: 5,
        de: 5,
    }

    retrieveData = async (listCriteria, page) => {
        const { partner } = this.props

        const criteria = removeEmptyProperties({
            ...listCriteria,
        })
        this.props.replace({
            pathname: window.location.pathname,
            search: queryString.stringify(criteria),
        })
        const leadResponse = await this.props.getLeads({
            ...criteria,
            geoCode: partner.geo.code,
            status: 'validated',
            industryId: partner.industry.id,
            geoPoint: partner.geo.point.lat + ',' + partner.geo.point.lon,
            segmentedForLeadMarketplacePartnerId: partner.id,
            hasLeadQuoteFee: '1',
            isQuotesLead: '1',
            limit: 12,
            page: page || 1,
            validatedAtGte: moment().subtract(this.validationDeadlineDays[partner.geo.code], 'days').startOf('day').toISOString(),
            hasMaxQuotes: '0',
            hasAcceptedQuote: '0',
            noQuoteForPartnerId: this.props.partner.id,
        })

        this.setState({ leadIds: leadResponse.result })
    }

    buyLead = async leadId => {
        const { partner } = this.props
        this.props.showModal({
            leadId,
            partnerId: partner.id,
            name: 'unlockMarketplaceLead',
        })
    }

    unlockLead = leadId => {
        const { hasMarketplaceLeadAccess, hasMarketplaceSignUpAccess } = this.props

        if (!hasMarketplaceLeadAccess && hasMarketplaceSignUpAccess) {
            return this.props.replace({ pathname: '/marketplace/subscription' })
        }

        if (this.isPurchaseLimitReached()) {
            return this.showPurchaseLimitReachedModal()
        }

        return this.buyLead(leadId)
    }

    isPurchaseLimitReached = () => {
        const { partner } = this.props

        if (partner.leadMarketplaceRecentPurchaseCount >= partner.leadMarketplaceRecentPurchaseCountMax) {
            return true
        }

        return false
    }

    showPurchaseLimitReachedModal = async leadId => {
        const { partner } = this.props
        this.props.showModal({
            leadId,
            partnerId: partner.id,
            name: 'showMarketplacePurchaseLimitReached',
        })
    }

    pageUp = () => {
        const { page } = this.props.pagination
        this.goToPage(page + 1)
    }

    pageDown = () => {
        const { page } = this.props.pagination
        this.goToPage(page - 1)
    }

    goToPage = page => {
        const { pagination } = this.props
        if (page !== pagination.page) {
            this.retrieveData(this.props.criteria, page)
        }
    }

    onFilterChange = filters => {
        const { criteria } = this.props
        const parsed = removeEmptyProperties({
            ...criteria,
            ...filters,
        })
        this.props.replace({
            pathname: window.location.pathname,
            search: queryString.stringify({ ...parsed }),
        })
    }

    onSort = sort => {
        this.onFilterChange({ sort })
    }

    updateWindowWidth = width => this.setState({ width: width })

    getWindowWidthString = width => {
        if (width <= bkpInt.small) return 'small'
        if (width <= bkpInt.medium) return 'medium'
        if (width <= bkpInt.large) return 'desktopSmall'
        if (width <= bkpInt.extraLarge) return 'desktopMedium'
        return 'desktopLarge'
    }

    render() {
        const { isLoadingLeads, leads, pagination, partner, t } = this.props

        return (
            <Page responsiveModifiers={{
                medium: [ 'pB_6', 'mB_6' ],
                desktopSmall: [ 'pB_6', 'mB_6' ],
                desktopMedium: [ 'pB_6', 'mB_6' ],
                desktopLarge: [ 'pB_6', 'mB_6' ],
            }} size={this.getWindowWidthString(this.state.width)} className="new">
                <Container modifiers={[ 'widthLg' ]}>
                    <Row>
                        <Col>
                            <Headline responsiveModifiers={{
                                small: [ 'spacingSmallest', 'small' ],
                                medium: [ 'spacingSmallest', 'small' ],
                                desktopSmall: [ 'spacingSmallest', 'medium' ],
                                desktopMedium: [ 'spacingMedium', 'medium' ],
                                desktopLarge: [ 'spacingLarge', 'mediumLarge' ],
                            }} size={this.getWindowWidthString(this.state.width)} modifiers={[ 'center', 'medium' ]}>{t('marketplace.title')}</Headline>
                        </Col>
                    </Row>
                </Container>
                <Container modifiers={[ 'widthExtraLg' ]}>
                    <Flex modifiers={[ 'justifyEnd', 'alignCenter' ]}>
                        <DropdownElement
                            options={this.sorts}
                            onSelect={this.onSort}
                            selected={this.props.criteria}
                            name="sort"
                        >
                            <TextButton modifiers={[ 'primary', 'mL_1' ]}>
                                <Flex modifiers={[ 'alignCenter' ]}>
                                    <Text modifiers={[ 'mR_1', 'bold' ]}>{t('marketplace.controls.sort')}</Text>
                                    <SortIcon size={22} />
                                </Flex>
                            </TextButton>
                        </DropdownElement>
                    </Flex>
                    <Row>
                        {isLoadingLeads && <Col spanLg={6} startLg={4}>
                            <Box modifiers={[ 'p_4', 'mY_6' ]}>
                                <Flex modifiers={[ 'justifyCenter', 'alignCenter' ]}>
                                    <LoadingAnimation />
                                </Flex>
                            </Box>
                        </Col>}
                        {!isLoadingLeads && !Boolean(this.state.leadIds.length) && <Col spanLg={6} startLg={4}>
                            <Box modifiers={[ 'p_4', 'mY_6' ]}>
                                <Text modifiers={[ 'center' ]}>{t('marketplace.no_leads')}</Text>
                            </Box>
                        </Col>}
                        {!isLoadingLeads && Boolean(this.state.leadIds.length) && this.state.leadIds.map(id => leads[id]).map(lead => <Col spanMd={4} spanLg={3}>
                            <LeadCard
                                partner={this.props.partner}
                                lead={lead}
                                buyLead={this.unlockLead}
                                validationDeadlineDays={this.validationDeadlineDays[partner.geo.code]}
                                t={t}
                            />
                        </Col>)}
                    </Row>
                    <Row modifiers={[ 'mT_3' ]}>
                        <Col spanMd={8} spanLg={8} startLg={3}>
                            <PaginationControl
                                total={pagination.pages}
                                current={pagination.page}
                                goUp={this.pageUp}
                                goDown={this.pageDown}
                                goTo={this.goToPage}
                            />
                        </Col>
                    </Row>
                </Container>
                <WindowSizeListener onResize={size => this.updateWindowWidth(size.windowWidth)} />
            </Page>
        )
    }
}
