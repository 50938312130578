import { useAgerasApi } from 'ageras-api-client/src'
import {
    PartnersApiFetchParamCreator, PartnerSubscriptionResult, PartnerSubscriptionTypeResource,
} from 'ageras-api-client/src/api'
import Loader from 'components/src/Loader'
import PackageCard from 'components/src/Marketplace/PackageCard'
import React, { useCallback } from 'react'
import Flex from 'components/src/layout/Flex'
import Page from 'components/src/layout/Page'
import Heading from 'components/src/texts/Heading'
import Button from 'components/src/Button'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import usePartner from '../../../../utils/usePartner'

const LoaderWrapper = styled(Flex)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

interface MarketplacePackagesPageProps {
    marketplaceEnabledCountries: string[]
}

function MarketplacePackagesPage({ marketplaceEnabledCountries }: MarketplacePackagesPageProps) {
    const { partner, isLoading } = usePartner()
    const { t } = useTranslation()
    const history = useHistory()

    if (partner?.geo?.code && !marketplaceEnabledCountries.includes(partner?.geo?.code)) {
        window.location.href = '/'
    }

    const { data: subscriptions, isLoading: subscriptionTypesLoading } = useAgerasApi<PartnerSubscriptionResult>(
        PartnersApiFetchParamCreator().partnersSubscriptiontypesIndex(
            partner?.geo?.code,
            false,
            'lead_marketplace',
        ),
    )

    const handlePackageChange = useCallback((subscription: PartnerSubscriptionTypeResource) => {
        return () => history.push('/marketplace/subscription?type=' + subscription.identifier)
    }, [ history ])

    const translationIdentifierSuffix = (subscription: PartnerSubscriptionTypeResource) => subscription.identifier?.split(/[_]+/).pop()

    return <Page variant="grey">
        {isLoading && subscriptionTypesLoading && <LoaderWrapper>
            <Loader />
        </LoaderWrapper>}
        {!isLoading && !subscriptionTypesLoading &&
            <Flex px={6} pt={6} pb={5} maxWidth={1100} mx="auto" flexDirection="column">
                <Flex mb={4} justifyContent={'center'}>
                    <Heading size="large">{t('marketplace.packages.heading')}</Heading>
                </Flex>
                <Flex justifyContent={'space-between'}>
                    {subscriptions && subscriptions.data?.map((subscription: PartnerSubscriptionTypeResource) => (
                        <Flex flexDirection='column' key={subscription.identifier}>
                            <PackageCard
                                key={subscription.id}
                                imageSrc="/images/ageras-logo.svg"
                                title={t(`marketplace.sign_up.package_${translationIdentifierSuffix(subscription)}.title`)}
                                packageName={subscription.name!}
                                subscriptionText={t(`marketplace.sign_up.package_${translationIdentifierSuffix(subscription)}.subscription_text`)}
                                subscriptionFee={subscription.monthlyPrice?.amount!}
                                currency={partner?.preferences?.currency?.code!}
                                description={t(`marketplace.sign_up.package_${translationIdentifierSuffix(subscription)}.description`)}
                                helpText={t(`marketplace.sign_up.package_${translationIdentifierSuffix(subscription)}.help_text`)}
                            />
                            <Button mt={4} variant="primary" onClick={handlePackageChange(subscription)}>{t('marketplace.packages.button')}</Button>
                        </Flex>))}
                </Flex>
            </Flex>}
    </Page>
}

export default MarketplacePackagesPage
