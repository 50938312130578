import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const GET_RATINGS = 'ratings/GET_RATINGS'
export const GET_RATINGS_SUCCESS = 'ratings/GET_RATINGS_SUCCESS'
export const GET_RATINGS_FAIL = 'ratings/GET_RATINGS_FAIL'

export const UPDATE_RATING = 'ratings/UPDATE_RATING'
export const UPDATE_RATING_SUCCESS = 'ratings/UPDATE_RATING_SUCCESS'
export const UPDATE_RATING_FAIL = 'ratings/UPDATE_RATING_FAIL'

export const CREATE_RATING = 'actions/CREATE_RATING'
export const CREATE_RATING_SUCCESS = 'actions/CREATE_RATING_SUCCESS'
export const CREATE_RATING_FAIL = 'actions/CREATE_RATING_FAIL'

export const getRatings = (partnerId, page = 1, limit) => dispatch => dispatch({
    [CALL_API]: {
        types: [ GET_RATINGS, GET_RATINGS_SUCCESS, GET_RATINGS_FAIL ],
        endpoint: '/ratings',
        schema: schemas.ratings,
        options: {
            body: {
                partnerId,
                limit,
                page,
                sort: '-created_at',
            },
        },
    },
})

export const updateRating = (ratingId, data) => dispatch => dispatch({
    [CALL_API]: {
        types: [ UPDATE_RATING, UPDATE_RATING_SUCCESS, UPDATE_RATING_FAIL ],
        endpoint: `/ratings/${ratingId}`,
        schema: schemas.rating,
        options: {
            method: 'put',
            body: data,
        },
    },
})

export const createRating = body => ({
    [CALL_API]: {
        types: [ CREATE_RATING, CREATE_RATING_SUCCESS, CREATE_RATING_FAIL ],
        endpoint: '/ratings',
        schema: schemas.projectConversation,
        options: {
            method: 'post',
            body,
        },
    },
})
