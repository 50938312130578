import React, { ReactNode } from 'react'
import Amount from '../../Amount'
import Button from '../../Button'
import Heading from '../../texts/Heading'
import Text from '../../texts/Text'
import Card from '../../Card'
import Badge from '../../Badge'
import Flex, { Spacer } from '../../layout/Flex'
import { MapPin, DollarSign, ChevronsRight, Unlock, Calendar, Award, X, Clock, Bell } from 'react-feather'

export interface LeadCardHeaderProps {
    title: string
    zipCode: string
    cityName: string
    customerType: string
    premiumAccessLabel?: string
    newLeadLabel?: string
    lastChanceLabel?: string
    icon: ReactNode
    hide?: () => void
}

export function LeadCardHeader({
    title,
    customerType,
    zipCode,
    cityName,
    hide,
    icon,
    premiumAccessLabel,
    newLeadLabel,
    lastChanceLabel,
}: LeadCardHeaderProps) {
    return (
        <Card.Content>
            <Flex justifyContent="space-between" alignItems="center" px={3} mb={2}>
                <Text color="impact">
                    {icon}
                </Text>
                <Flex alignItems="center" flexWrap={'wrap'} alignContent="flex-start" justifyContent="flex-end" ml="auto" mr={0}>
                    {premiumAccessLabel && <Badge m={1} mr={0} variant="success" size="small">
                        <Flex as={Award} mr={1} size={12}/>
                        {premiumAccessLabel}
                    </Badge>}
                    {newLeadLabel && <Badge m={1} mr={0} variant="hint" size="small">
                        <Flex as={Bell} mr={1} size={12}/>
                        {newLeadLabel}
                    </Badge>}
                    {lastChanceLabel && <Badge m={1} mr={0} variant="warning" size="small">
                        <Flex as={Clock} mr={1} size={12}/>
                        {lastChanceLabel}
                    </Badge>}
                    {Boolean(hide) && <Button variant="neutral" onClick={hide} size="small" ml={3} mt={-3} mr={-2}>
                        <X size={18}/>
                    </Button>}
                </Flex>
            </Flex>
            <Flex px={3}>
                <Heading size="medium" mb={3} color="impact">{title}</Heading>
            </Flex>
            <Flex alignItems="start" px={3} mb={1}>
                <Text color="impact"><Award size={16}/></Text>
                <Text size="small" ml={2}>{customerType}</Text>
            </Flex>
            <Flex alignItems="start" px={3} mb={2}>
                <Text color="impact"><MapPin size={16}/></Text>
                <Text ml={2} size="small">
                    <span>{zipCode}</span>
                    <span>
                        {', '}
                        {cityName}
                    </span>
                </Text>
            </Flex>
        </Card.Content>
    )
}

export interface LeadCardProps extends LeadCardHeaderProps {
    revenueAmount: number
    revenueCurrency: string
    fillingYear?: string
    unlockAction?: () => void
    continueAction?: () => void
    incomeRevenueLabelString: string
    fillingYearLabelString: string
    actionLabelUnlockString: string
    actionLabelUnlockedString?: string
    actionLabelContinueString: string
}

function LeadCard({
    title,
    zipCode,
    cityName,
    customerType,
    revenueAmount,
    revenueCurrency,
    unlockAction,
    continueAction,
    fillingYear,
    premiumAccessLabel,
    incomeRevenueLabelString,
    fillingYearLabelString,
    actionLabelUnlockString,
    actionLabelContinueString,
    icon,
    newLeadLabel,
    lastChanceLabel
}: LeadCardProps) {
    return <Card py={3}>
        <LeadCardHeader
            title={title}
            zipCode={zipCode}
            cityName={cityName}
            customerType={customerType}
            premiumAccessLabel={premiumAccessLabel}
            newLeadLabel={newLeadLabel}
            lastChanceLabel={lastChanceLabel}
            icon={icon}
        />
        <Card.Line />
        <Card.Content mb={2}>
            <Flex alignItems="center" mt={2} px={3}>
                <Text color="impact"><DollarSign size={16}/></Text>
                <Text color="impact" ml={2} size="small" bold>
                    {incomeRevenueLabelString}
                    {':'}
                </Text>
                <Text ml={1} size="small">
                    <Amount number={revenueAmount} currency={revenueCurrency} maximumFractionDigits={0}/>
                </Text>
            </Flex>
            {fillingYear && <Flex alignItems="center" mt={2} px={3}>
                <Text color="impact"><Calendar size={16}/></Text>
                <Text color="impact" ml={2} size="small" bold>
                    {fillingYearLabelString}
                    {':'}
                </Text>
                <Text ml={1} size="small">
                    {fillingYear}
                </Text>
            </Flex>}
        </Card.Content>
        <Spacer />
        <Card.Line />
        <Flex px={3} mt={3} flexDirection="row-reverse">
            {unlockAction && <Flex as={Button} onClick={unlockAction} flexGrow={1} flexBasis={0} alignItems="center" variant="secondary" mr={1} maxWidth={160}>
                <Flex as={Unlock} mr={2} size={14}/>
                <Text verticalAlign="baseline">{actionLabelUnlockString}</Text>
            </Flex>}
            {continueAction && <Flex as={Button} onClick={continueAction} flexGrow={1} flexBasis={0} alignItems="center" variant="primary" maxWidth={160}>
                {actionLabelContinueString}
                <Flex as={ChevronsRight} ml={2}/>
            </Flex>}
        </Flex>
    </Card>
}

export default LeadCard
