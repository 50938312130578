import styled from 'styled-components'
import { applyStyleModifiers, applyResponsiveStyleModifiers } from 'styled-components-modifiers'
import {TEXT_COLOR_MODIFIERS, TEXT_ALIGN_MODIFIERS, TEXT_DECORATION_MODIFIERS} from '../modifiers/textModifiers'
import { MODIFIER_MARGIN_CONFIG, MODIFIER_PADDING_CONFIG } from '../modifiers/spaceModifiers'
import BaseText from './BaseText'
import { colors, fonts } from '../../../theme/variables_new'

const BODYTEXT_MODIFIERS = {
    regular: () => `
        font-size: ${fonts.size.s};
        line-height: ${fonts.lineHeight['28']};
    `,
    medium: () => `
        font-size: 14px;
        line-height: 26px;
    `,
    small: () => `
        font-size: ${fonts.size.xs};
        line-height: ${fonts.lineHeight['24']};
    `,
    tiny: () => `
        font-size: ${fonts.size.xxs};
        line-height: ${fonts.lineHeight['24']};
    `,
    tinyLow: () => `
        font-size: ${fonts.size.xxs};
        line-height: ${fonts.lineHeight['18']};
    `,
    smallHigh: () => `
        font-size: ${fonts.size.xs};
        line-height: ${fonts.lineHeight['24']};
    `,
    bold: () => `
        font-weight: ${fonts.weight.bold};
    `,
    semibold: () => `
        font-weight: ${fonts.weight.semibold};
    `,
    accent: () => `
        color: ${colors.blue};
    `,
    lightGrey: () => `
        color: ${colors.fontLight};
    `,
    inline: () => `
        display: inline;
    `,
    uppercase: () => `
        text-transform: uppercase;
    `,
}

export const Text = styled(BaseText)`
    font-weight: ${fonts.weight.normal};
    ${applyStyleModifiers(BODYTEXT_MODIFIERS)};
    ${applyStyleModifiers(TEXT_COLOR_MODIFIERS)};
    ${applyStyleModifiers(TEXT_ALIGN_MODIFIERS)};
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)};
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)};
    ${applyStyleModifiers(TEXT_DECORATION_MODIFIERS)};
    ${applyResponsiveStyleModifiers(BODYTEXT_MODIFIERS)};
    ${applyResponsiveStyleModifiers(TEXT_COLOR_MODIFIERS)};
    ${applyResponsiveStyleModifiers(TEXT_ALIGN_MODIFIERS)};
    ${applyResponsiveStyleModifiers(MODIFIER_MARGIN_CONFIG)};
    ${applyResponsiveStyleModifiers(MODIFIER_PADDING_CONFIG)};
    ${applyResponsiveStyleModifiers(TEXT_DECORATION_MODIFIERS)};
`

export const Span = styled.span`
    ${applyStyleModifiers(TEXT_COLOR_MODIFIERS)};
    ${applyStyleModifiers(BODYTEXT_MODIFIERS)};
    ${applyResponsiveStyleModifiers(BODYTEXT_MODIFIERS)};
    ${applyResponsiveStyleModifiers(TEXT_COLOR_MODIFIERS)};
`

const HEADLINE_MODIFIERS = {
    large: () => `
        font-size: ${fonts.size.xxl};
        line-height: ${fonts.lineHeight['50']}
    `,
    mediumLarge: () => `
        font-size: 36px;
        line-height: 44px;
    `,
    medium: () => `
        font-size: ${fonts.size.xl};
        line-height: ${fonts.lineHeight['40']}
    `,
    tiny: () => `
        font-size: 20px;
        line-height: 30px;
    `,
    small: () => `
        font-size: 26px;
        line-height: 34px;
    `,
    quoteMark: () => `
        position: relative;
        &::before {
            content: "“";
            display: inline-block;
            font-size: 40px;
            color: ${colors.blue};
            position: absolute;
            left: -23px;
            top: -20px;
            transform: rotate(-180deg);
        }
    `,

    spacingSmallest: () => `
        margin-top: 58px;
        margin-bottom: 58px;
    `,
    spacingSmall: () => `
        margin-top: 58px;
        margin-bottom: 58px;
    `,
    spacingMedium: () => `
        margin-top: 64px;
        margin-bottom: 64px;
    `,
    spacingLarge: () => `
        margin-top: 112px;
        margin-bottom: 112px;
    `,
}

export const Headline = styled(BaseText)`
    font-weight: ${fonts.weight.bold};
    ${applyStyleModifiers(TEXT_COLOR_MODIFIERS)};
    ${applyStyleModifiers(TEXT_ALIGN_MODIFIERS)};
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)};
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)};
    ${applyStyleModifiers(HEADLINE_MODIFIERS)};
    ${applyResponsiveStyleModifiers(TEXT_COLOR_MODIFIERS)};
    ${applyResponsiveStyleModifiers(TEXT_ALIGN_MODIFIERS)};
    ${applyResponsiveStyleModifiers(MODIFIER_MARGIN_CONFIG)};
    ${applyResponsiveStyleModifiers(MODIFIER_PADDING_CONFIG)};
    ${applyResponsiveStyleModifiers(HEADLINE_MODIFIERS)};
`

const IMPACT_TEXT_MODIFIERS = {
    large: () => `
        font-size: ${fonts.size.l};
        line-height: ${fonts.lineHeight['40']}
    `,
    medium: () => `
        font-size: 18px;
        line-height: 26px;
    `,
    small: () => `
        font-size: 18px;
        line-height: ${fonts.lineHeight['24']}
    `,
    tiny: () => `
        font-size: ${fonts.size.xs};
        line-height: ${fonts.lineHeight['24']}
    `,
}

export const Impact = styled(BaseText)`
    font-weight: ${fonts.weight.bold};
    font-size: ${fonts.size.m};
    line-height: ${fonts.lineHeight['28']};
    ${applyStyleModifiers(IMPACT_TEXT_MODIFIERS)};
    ${applyStyleModifiers(TEXT_COLOR_MODIFIERS)};
    ${applyStyleModifiers(TEXT_ALIGN_MODIFIERS)};
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)};
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)};
    ${applyResponsiveStyleModifiers(IMPACT_TEXT_MODIFIERS)};
    ${applyResponsiveStyleModifiers(TEXT_COLOR_MODIFIERS)};
    ${applyResponsiveStyleModifiers(TEXT_ALIGN_MODIFIERS)};
    ${applyResponsiveStyleModifiers(MODIFIER_PADDING_CONFIG)};
    ${applyResponsiveStyleModifiers(MODIFIER_PADDING_CONFIG)};
`

const LABEL_MODIFIERS = {
    space: () => `
        margin: 6px 4px;
    `,
    typeLabel: () => `
        font-size: ${fonts.size.xs};
        font-weight: bold;
        line-height: ${fonts.lineHeight['20']}
        padding: 10px 12px;
        width: fit-content;
        color: ${colors.white};
        background-color: ${colors.lightBlue};
        border-radius: 6px;
        text-transform: capitalize;
    `,
}

export const BlockLabel = styled.div`
    display: inline-block;
    border-radius: 4px;
    padding: 0px 16px;
    text-align: center;
    text-transform: uppercase;
    color: ${colors.darkBlue};
    background-color: ${colors.lightGrey};
    ${applyStyleModifiers(TEXT_COLOR_MODIFIERS)};
    ${applyStyleModifiers(TEXT_ALIGN_MODIFIERS)};
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)};
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)};
    ${applyStyleModifiers(LABEL_MODIFIERS)};
    ${applyStyleModifiers(BODYTEXT_MODIFIERS)};
    ${applyResponsiveStyleModifiers(TEXT_COLOR_MODIFIERS)};
    ${applyResponsiveStyleModifiers(TEXT_ALIGN_MODIFIERS)};
    ${applyResponsiveStyleModifiers(MODIFIER_PADDING_CONFIG)};
    ${applyResponsiveStyleModifiers(MODIFIER_PADDING_CONFIG)};
    ${applyResponsiveStyleModifiers(LABEL_MODIFIERS)};
    ${applyResponsiveStyleModifiers(BODYTEXT_MODIFIERS)};
`
