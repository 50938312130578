import React from 'react'
import { Trans, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import InvoiceList from '../finance/invoices/InvoiceList'
import UnpaidInvoiceList from '../finance/invoices/UnpaidInvoiceList'
import * as invoices from '../../actions/invoices'
import { currentPartnerSelector } from '../../selectors/auth'
import LoadingAnimation from '../utils/LoadingAnimation'
import { currentPageSelector } from '../../selectors/routing'
import PageTitle from '../utils/PageTitle'
import { Card } from '../blocks/Card'
import styled from 'styled-components'

const Intro = styled(Card.Intro)`
  font-style: italic;
  line-height: 1.8em;
`

@connect(
    state => ({
        invoices: state.pages.invoices.items.map(id => state.entities.invoices[id]),
        pages: state.pages.invoices.pagination.pages,
        total: state.pages.invoices.pagination.total,
        isLoading: state.pages.invoices.isLoading,
        page: currentPageSelector(state),
        partner: currentPartnerSelector(state),
    }),
    {
        getInvoices: invoices.getInvoices,
    }
)
@withTranslation('', { wait: true })
export default class InvoiceListPage extends React.Component {
    componentDidMount() {
        this.refreshInvoices(this.props)
    }

    componentWillReceiveProps(newProps) {
        if(this.props.page !== newProps.page) {
            this.refreshInvoices(newProps)
        }
    }

    refreshInvoices(props) {
        const { partner, getInvoices, page } = props
        if(partner) {
            getInvoices({ partnerId: partner.id }, page)
        }
    }

    render() {
        const {
            isLoading,
            invoices,
            t,
        } = this.props

        const unpaidInvoices = invoices.filter(invoice => [ 'unpaid', 'overdue' ].includes(invoice.status))

        return (
            <Card>
                {isLoading && <Card.Content>
                    <LoadingAnimation />
                </Card.Content>}
                {!isLoading && <Card.Content>
                    {Boolean(unpaidInvoices.length) && <div>
                        <PageTitle identifier="invoices.title"/>
                        <Card.Title>{t('invoices.title_unpaid')}</Card.Title>
                        <UnpaidInvoiceList invoices={unpaidInvoices}/>
                        <Intro>
                            <Trans
                                i18nKey="invoices.unpaid_info"
                                br={<br/>}
                            />
                        </Intro>
                    </div>}
                    <div>
                        <Card.Title>{t('invoices.title')}</Card.Title>
                        <InvoiceList invoices={invoices}/>
                    </div>
                </Card.Content>}
            </Card>
        )
    }
}
