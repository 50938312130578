import React from 'react'
import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import { currentPartnerSelector } from '../../selectors/auth'
import SubscriptionPlanPage from './SubscriptionPlanPage'
import SubscriptionBillingPage from './SubscriptionBillingPage'
import { ContainerFluid } from '../cards'
import SidebarPageTemplate from '../../containers/layout/SidebarPageTemplate'

@connect(
    state => ({
        partner: currentPartnerSelector(state),
    }),
    {
        replace,
    }
)
@withTranslation('', { wait: true })
export default class SubscriptionPage extends React.Component {
    componentDidMount() {
        const { match, t } = this.props
        this.navLinks = [
            {
                pathname: `${match.url}/plan`,
                relatedComponent: <SubscriptionPlanPage />,
                mainText: t('navigation.subscription_plan'),
                secondaryText: t('navigation.subscription_plan_description'),
            },
            {
                pathname: `${match.url}/billing`,
                relatedComponent: <SubscriptionBillingPage />,
                mainText: t('navigation.subscription_billing'),
                secondaryText: t('navigation.subscription_billing_description'),
            },
        ]
    }

    render() {
        const { location: { hash } } = this.props

        return <ContainerFluid>
            {/* Sidebar and pages component*/}
            <SidebarPageTemplate
                navLinks={this.navLinks}
                hash={hash}
            />
        </ContainerFluid>
    }
}
