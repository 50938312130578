import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { PageHeader } from '../../components/layout/PageHeader'
import { Flex } from '../../components/elements/Flex'
import OverflowText from '../../components/elements/OverflowText'
import { Col, Dropdown, MenuItem, Button, Row } from 'react-bootstrap'



export default function ClientPageHeader(props) {

    const {
        t,
        user,
        logout,
        history,
        pageTitle,
        location,
        hasNewMessage,
    } = props

    let menuItems = [
        { title: t('navigation.cases'), pathname: '/cases' },
        { title: t('navigation.messages'), pathname: '/messages' },

        // TODO: need find out how make `account` to be related to `account/invoices`
        // { title: t('navigation.account'), pathname: '/account/invoice' },
        // { title: t('navigation.services'), pathname: '/services' },
    ]

    const [ menuOpen, setMenuOpen ] = useState(false)

    return <>
        <div id="page-header" className="client-header">
            <div className="top">
                <div className="container-fluid">
                    <Row>
                        <Col xs={6}>
                            <Link to="/">
                                <img className="logo" src="/images/ageras-logo.svg" alt="ageras_logo" width={'200px'}/>
                            </Link>
                        </Col>
                        <Col xs={6} className="visible-xs visible-sm">
                            <Button className={ `btn-link pull-right ${menuOpen ? 'ap-remove' : 'ap-burger'}`} onClick={() => setMenuOpen(menuOpen => !menuOpen)} />
                        </Col>
                        <Col xs={6} className="hidden-xs hidden-sm">
                            <Dropdown
                                className={'pull-right'}
                                onClick={() => setMenuOpen(menuOpen => !menuOpen)}
                                open={menuOpen}
                                id={'logout'}
                            >
                                <Dropdown.Toggle id="logout" noCaret={true} className={'btn btn-link'}>
                                    <span className={'ap-user'}/>
                                    &nbsp;
                                    <span>{user && (user.firstName + ' ' + user.lastName)}</span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <MenuItem onClick={logout}>
                                        {t('user.log_out')}
                                    </MenuItem>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </div>
            </div>

            <PageHeader
                closeMenu={() => setMenuOpen(false)}
                mobileMenuOpen={menuOpen}
                hasNewMessage={hasNewMessage}
                menuItems={menuItems}
                logout={logout}
                t={t}
            />

        </div>
        {location.pathname !== '/messages' &&
        <div className={`mobile-page-name sticky-top visible-xs visible-sm ${pageTitle.pageId === 'quoteDetailPage' ? 'text-right' : 'text-center'}`}>
            <Flex
                responsiveModifiers={{
                    spaceBetween: [ 'justifySpaceBetween' ],
                    center: [ 'justifyCenter' ],
                }}
                size={pageTitle.pageId === 'quoteDetailPage' ? 'spaceBetween' : 'center'}
            >
                {pageTitle.pageId === 'quoteDetailPage' &&
                    <Flex onClick={() => history.goBack()} className="text-active mdi mdi-chevron-left">
                        {t('actions.overview_message')}
                    </Flex>
                }
                <OverflowText modifiers={[ 'mL_1' ]}>{pageTitle.title}</OverflowText>
            </Flex>
        </div>}
        </>
}
