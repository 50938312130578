import React from 'react'
import { SquareEmptyStar, SquareStar } from '../cards/index'

export class StarRatingInput extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hoveredFilled: false }
    }

    handleHover(fill) {
        this.setState({
            hoveredFilled: fill,
        })
    }
    render() {
        const { filled = 0, total = 0, event, size, className } = this.props
        const filledTotal = this.state.hoveredFilled || filled
        let starColor
        switch (filledTotal) {
            case 1:
                starColor = '#e22027'
                break
            case 2:
                starColor = '#f47324'
                break
            case 3:
                starColor = '#f8cc18'
                break
            case 4:
                starColor = '#73b143'
                break
            case 5:
                starColor = '#0ecc71'
                break
        }
        let result = []
        for (let i = 0; i < filledTotal; i++) {
            result.push(<SquareStar
                size={size}
                key={i}
                onClick={event && event.bind(null, i)}
                onMouseOver={event && this.handleHover.bind(this, i + 1)}
                starColor={starColor}
                cursor={event && 'pointer'}>
                <span className="ap-star" />
            </SquareStar>)
        }
        for (let i = filledTotal; i < total; i++) {
            result.push(<SquareEmptyStar
                size={size}
                key={i}
                onClick={event && event.bind(null, i)}
                onMouseOver={event && this.handleHover.bind(this, i + 1)}
                cursor={event && 'pointer'}>
                <span className="ap-star" />
            </SquareEmptyStar>)
        }

        return <div className={className} onMouseLeave={ event && this.handleHover.bind(this, 0)}>{result}</div>
    }
}
