import {
    LOGIN, LOGIN_SUCCESS, LOGIN_FAIL,
    LOGIN_USING_TOKEN_SUCCESS,
    LOGOUT_SUCCESS,
    GET_TOKEN_INFO_SUCCESS,
    REFRESH, REFRESH_SUCCESS, REFRESH_FAIL,
    START_REFRESH_INTERVAL_SUCCESS,
    STOP_REFRESH_INTERVAL_SUCCESS,
    GET_AUTH_PERMISSIONS_SUCCESS,
    GET_CURRENT_USER_SUCCESS,
    RESET_TIMEOUT, RESET_TIMEOUT_SUCCESS,
    AUTHENTICATE_WITH_SIGNUP_CONFIRM_TOKEN,
    AUTHENTICATE_WITH_SIGNUP_CONFIRM_TOKEN_SUCCESS,
    AUTHENTICATE_WITH_SIGNUP_CONFIRM_TOKEN_FAIL,
    FINALIZE_SIGNUP, FINALIZE_SIGNUP_SUCCESS, FINALIZE_SIGNUP_FAIL,
} from '../actions/auth'
import { camelize } from 'humps'
import storage from 'local-storage-fallback'

const initialState = {
    token: storage.getItem('refresh_token'),
    loggingIn: false,
    loginFailed: false,
    refreshingToken: storage.getItem('auth.refreshing_token'),
    refreshIntervalId: null,
    user: null,
    partnerUserId: storage.getItem('auth.partner_user_id'),
    clientId: storage.getItem('auth.client_id'),
    employeeId: null,
    userType: null,
    permissions: [],
    email: '',
    geoCode: '',
    authenticating: false,
    authenticatingFailed: false,
    finalizingSignup: false,
    finalizingSignupSuccess: false,
    finalizingSignupFailed: false,
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    case GET_CURRENT_USER_SUCCESS:
        return {
            ...state,
            partnerUserId: action.response.result,
        }
    case GET_AUTH_PERMISSIONS_SUCCESS:
        return {
            ...state,
            permissions: action.response.result,
        }
    case LOGIN:
        storage.setItem('auth.last_token_refresh', new Date())
        return {
            ...state,
            loggingIn: true,
            loginFailed: false,
        }
    case LOGIN_USING_TOKEN_SUCCESS:
        storage.setItem('auth.partner_user_id', action.userId)
        storage.setItem('refresh_token', action.token)
        return {
            ...state,
            token: action.token,
            partnerUserId: action.userId,
        }
    case GET_TOKEN_INFO_SUCCESS:
        storage.setItem('refresh_token', action.tokenInfo.token)
        return {
            ...state,
            token: action.tokenInfo.token,
            [camelize(action.tokenInfo.user_type) + 'Id']: action.tokenInfo.user_id,
            userType: action.tokenInfo.user_type,
        }
    case LOGIN_SUCCESS:
        return {
            ...state,
            loggingIn: false,
        }
    case LOGIN_FAIL:
        return {
            ...state,
            loggingIn: false,
            loginFailed: true,
        }
    case LOGOUT_SUCCESS:
        const projectsFilter = storage.getItem('projectsFilter')
        const i18nextLng = storage.getItem('i18nextLng')
        storage.clear()
        storage.setItem('projectsFilter', projectsFilter)
        storage.setItem('i18nextLng', i18nextLng)
        return {
            ...state,
            token: null,
        }
    case RESET_TIMEOUT:
        if (state.loadingTimeoutId)
            clearTimeout(state.loadingTimeoutId)
        return {
            ...state,
            loadingTimedOut: false,
            loadingTimeoutId: action.loadingTimeoutId,
        }
    case RESET_TIMEOUT_SUCCESS:
        return {
            ...state,
            loadingTimedOut: true,
            loadingTimeoutId: undefined,
        }
    case REFRESH:
        storage.setItem('auth.refreshing_token', true)
        return {
            ...state,
            refreshingToken: true,
        }
    case REFRESH_SUCCESS:
        storage.removeItem('auth.refreshing_token')
        storage.setItem('refresh_token', action.token)
        storage.setItem('auth.last_token_refresh', new Date())
        return {
            ...state,
            refreshingToken: false,
            token: action.token,
        }
    case REFRESH_FAIL:
        return {
            ...state,
            refreshingToken: false,
        }
    case START_REFRESH_INTERVAL_SUCCESS:
        return {
            ...state,
            refreshIntervalId: action.intervalId,
        }
    case STOP_REFRESH_INTERVAL_SUCCESS:
        clearInterval(state.refreshIntervalId)
        return {
            ...state,
            refreshIntervalId: null,
        }
    case AUTHENTICATE_WITH_SIGNUP_CONFIRM_TOKEN:
        storage.removeItem('refresh_token')

        return {
            ...state,
            token: null,
            authenticating: true,
            authenticatingFailed: false,
        }
    case AUTHENTICATE_WITH_SIGNUP_CONFIRM_TOKEN_SUCCESS:
        const response = action.response

        const token = response.token
        const userId = response.userId
        const email = response.email
        const { partnerSignUp } = response
        const geoCode = partnerSignUp.geoCode

        storage.setItem('refresh_token', token)

        return {
            ...state,
            user: partnerSignUp,
            authenticating: false,
            authenticatingFailed: false,
            token,
            userId,
            email,
            geoCode,
        }
    case AUTHENTICATE_WITH_SIGNUP_CONFIRM_TOKEN_FAIL:
        return {
            ...state,
            authenticating: false,
            authenticatingFailed: true,
        }
    case FINALIZE_SIGNUP:
        return {
            ...state,
            finalizingSignup: true,
            finalizingSignupSuccess: false,
            finalizingSignupFailed: false,
        }
    case FINALIZE_SIGNUP_SUCCESS:
        return {
            ...state,
            finalizingSignup: false,
            finalizingSignupSuccess: true,
            finalizingSignupFailed: false,
        }
    case FINALIZE_SIGNUP_FAIL:
        return {
            ...state,
            finalizingSignup: false,
            finalizingSignupSuccess: false,
            finalizingSignupFailed: true,
        }
    default:
        return state
    }
}
