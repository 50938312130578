import React from 'react'
import { camelize } from 'humps'
import { Triangle } from '../../components/cards/index'
import moment from 'moment'
import { backgroundColor, primaryColor } from '../../theme/variables'
import nl2br from '../../components/utils/Nl2br'
import ReadMore from '../../components/utils/ReadMore'
import FileItem from '../../components/projects/messages/FileItem'
import { SeparatorLine } from '../../components/cards'
import Portrait from '../../components/elements/Portrait'
import { Flex } from '../../components/elements/Flex'

export const NoteMessage = ({
    disableReadMore,
    characters,
    message,
}) =>
    <div className="note-message">
        <div className="message-text">
            {disableReadMore ?
                <div>{nl2br(message.text)}</div> :
                <ReadMore characters={characters}>
                    {message.text}
                </ReadMore>
            }
            {message.attachments && message.attachments.length > 0 &&
                <div className="attachments">
                    <SeparatorLine lineColor="#ccc" margin="10px auto"/>
                    {message.attachments.map(f => <FileItem file={f} />)}
                </div>
            }
        </div>
    </div>

export const MessageBlock = ({
    disableReadMore,
    characters,
    message,
    float,
    t,
}) =>
    <div className={'message-container clearfix ' + (float === 'right' ? 'sender-message' : 'receiver-message')}>
        <Flex className="message-block" modifiers={[ 'alignCenter', 'mR_1' ]}>
            <div>
                <Portrait
                    modifiers={[ 'mT_2', float === 'right' ? 'mL_1' : 'mR_1' ]}
                    name={message.participant.name}
                    image={message.participant.picture}
                    size={40}
                />
            </div>
            <Triangle
                color={float === 'right' ? primaryColor : backgroundColor}
                style={{ marginTop: '20px' }}
                direction={float}
            />
            <div>
                { message.participant.name &&
                    <div className="message-info">
                        {message.hideInfo || <strong>{float === 'right' ? t('conversations.you') : message.participant.name}</strong>}
                        {message.createdAt && <span> &middot; {moment(message.createdAt).format('LLL') }</span>}
                    </div>
                }
                <NoteMessage
                    float={float}
                    message={message}
                    disableReadMore={disableReadMore}
                    characters={characters}
                />
            </div>
        </Flex>
    </div>

export default class MessagesContainer extends React.Component {
    componentDidMount() {
        if(this.lastElement) {
            this.lastElement.scrollIntoView()
        }
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.conversationId !== nextProps.conversationId) {
            this.lastElement.scrollIntoView()
        }
    }

    render() {
        const {
            characters,
            scrollIntoView,
            messages,
            sender,
            t,
        } = this.props

        return <div>
            {messages.map(message => {
                const float = message.participant[camelize(message.participant.type) + 'Id'] === sender.id ? 'right' : 'left'
                return <MessageBlock
                    key={message.id}
                    float={float}
                    message={message}
                    characters={characters}
                    t={t}
                />
            })}
            {scrollIntoView && <div style={{ float: 'left', clear: 'both' }} ref={el => this.lastElement = el } />}
        </div>
    }
}
