import moment from 'moment'

import { GET_NOTIFICATIONS_SUCCESS } from '../actions/notifications'

const initialState = {
    ids: [],
    lastFetch: null,
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    case GET_NOTIFICATIONS_SUCCESS:
        const ids = action.response.result.concat(state.ids)

        return {
            ...state,
            ids,
            lastFetch: moment().toISOString(),
        }
    default:
        return state
    }
}
