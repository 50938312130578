import { Card, Content } from '../cards/index'
import { Button } from '../elements/ButtonNew'
import { Text } from '../elements/text/Text'
import { useState } from 'react'
import PaymentMethodModal from './PaymentMethodModal'
import { useTranslation } from 'react-i18next'


export default function PaymentMethodCard ({
    partner,
    paymentMethod,
}){
    const [showModal, setShowModal] = useState(false);
    const { t } = useTranslation();

    return (
        <div>
            <Card>
                {paymentMethod ?
                    <Content>
                        <Text>
                            {t('stripe_subscription_page.payment_method.card')}
                        </Text>
                        <Text>{paymentMethod.pmType}</Text>
                        <Text>{'•••• •••• •••• ' + paymentMethod.pmLastFour}</Text>
                        <br />
                        <Button onClick={ () => setShowModal(true) } modifiers={[ 'primary', 'bold', 'small' ]}>
                            <span>
                                {t('stripe_subscription_page.payment_method.update')}
                            </span>
                        </Button>
                    </Content>
                    :
                    <Content>
                        <Text>
                            {t('stripe_subscription_page.payment_method.none')}
                        </Text>
                        <br />
                        <Button onClick={ () => setShowModal(true) } modifiers={[ 'action', 'bold', 'small' ]}>
                            <span>
                                {t('stripe_subscription_page.payment_method.add')}
                            </span>
                        </Button>
                    </Content>
                }
            </Card>

            <PaymentMethodModal partner={partner} show={showModal} toggle={(show) => {setShowModal(show)}}/>
        </div>
    )
}
