import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { bindActionCreators } from 'redux'
import { hide } from '../../actions/loader'

const permissionDenied = 'Permission Denied'

@connect(
    state => ({
        state: state.loader.state,
        status: state.loader.status,
        message: state.loader.message,
    }),
    dispatch => ({
        hideLoading: bindActionCreators(hide, dispatch),
    })
)

@withTranslation('', { wait: true })
export default class LoadingBarWrapper extends React.Component {

    render() {
        const {
            state, status, t, message,
        } = this.props

        if (state === 'show_status') {
            setTimeout(function() {
                this.props.hideLoading()
            }.bind(this), (status === 'failed' ? 7500 : 1700))
        }

        var bar = {
            bottom: 0,
        }
        var progress = {}

        if (state === 'loading') {
            progress = {
                width: '70%',
            }
        } else if (state === 'show_status') {
            progress = {
                width: '100%',
            }
        } else {
            progress = {
                width: 0,
            }
            bar = {
                display: 'none',
            }
        }

        var statusText = ''

        if(status === 'success') {
            statusText = t('loading.success')
        } else if(status === 'failed') {
            statusText = message === permissionDenied ? t('loading.permission_denied') : t('loading.error')
        }

        return (
            <div className={'loading-bar loading-bar-' + status} style={bar}>
                <div className="progress" style={progress} />

                {state === 'loading' ?
                    <span>{t('loading.saving_changes')}</span> :
                    <span>{statusText}</span>
                }
            </div>
        )
    }
}
