import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Route, Switch } from 'react-router'
import { replace } from 'connected-react-router'
import { ContextProvider as AuthProvider } from 'ageras-api-client/src/auth'
import * as auth from '../actions/auth'
import { withTranslation } from 'react-i18next'
import LogIn from './pages/LogIn'
import PartnerPageHeader from '../containers/layout/PartnerPageHeader'
import { PageFooter } from './layout/PageFooter'
import LoadingAnimation from './utils/LoadingAnimation'
import { isLoadingPartnersGlobalSelector } from '../selectors'
import LoadingBarWrapper from './layout/LoadingBarWrapper'
import PushNotificationBar from './layout/PushNotificationBar'
import IndexPage from './pages/IndexPage'
import QuotePage from '../containers/partners/pages/QuotePage'
import ProjectListPage from './pages/ProjectListPage'
import ProjectPage from './pages/project/ProjectPage'
import AccountPage from './pages/AccountPage'
import SegmentationIntroPage from './pages/SegmentationIntroPage'
import MarketingPage from './pages/MarketingPage'
import UserEditPage from './pages/UserEditPage'
import SubscriptionPage from './pages/SubscriptionPage'
import DemoPage from './pages/DemoPage'
import SubscriptionPaymentOptionsPage from './pages/SubscriptionPaymentOptionsPage'
import SubscriptionConfirmationPage from './pages/SubscriptionConfirmationPage'
import CheckoutPage from '../containers/partners/pages/CheckoutPage'
import QuoteIntroductionPage from './pages/QuoteIntroductionPage'
import SubscriptionInfoPage from './pages/SubscriptionInfoPage'
import TermsPage from '../containers/common/TermsPage'
import { currentPartnerSelector, currentPartnerUserSelector, isFinalizingSignupSelector } from '../selectors/auth'
import { StickyMobileNavigation } from '../containers/layout/StickyMobileNavigation'
import { getUnreadParticipantMessages } from '../actions/conversations'
import config from '../config'
import { acceptedTermsSelector } from '../selectors/terms'
import { isLoadingInvoicesSelector, unpaidInvoicesTillFirsOfJuneSelector } from '../selectors/invoices'
import BootstrapModal from './modals'
import InvoiceDetailPage from './pages/InvoiceDetailPage'
import InvoicePaymentConfirmationPage from './pages/InvoicePaymentConfirmationPage'
import PartnerContractsPage from './pages/PartnerContractsPage'
import ContractOfferPage from './pages/ContractOfferPage'
import AccessibilityPrompt from './prompts/AccessibilityPrompt'
import MarketplacePage from './pages/MarketplacePage/index'
import Marketplace from './pages/Marketplace/index'
import MarketplaceSignupPage from './pages/MarketplacePage/SignupPage/index'
import SignupConfirmPage from './pages/SignupConfirmPage'
import MarketplacePreferencesPage from './pages/MarketplacePage/PreferencesPage'
import { useAgerasApi } from "ageras-api-client/src";
import { AuthApiFetchParamCreator } from "ageras-api-client/src/api";
import DebtorModal from './modals/DebtorModal'
import UserFlow from './pages/UserFlow'
import MarketplacePackagesPage from './pages/MarketplacePage/PackagesPage'
import { segmentPage } from '../utils/segment'
import {
    hasMarketplaceBasicLeadAccessSelector,
    hasMarketplaceLeadAccessSelector,
    hasMarketplacePlusLeadAccessSelector,
    hasMarketplaceWithoutFeesAccessSelector
} from '../selectors/access'

function SyncAuth({ setAuth, token }) {
    useAgerasApi(
        AuthApiFetchParamCreator().authTokenGet(),
        {
            onSuccess: (data) => {
                setAuth(data)
            },
            meta: {
                authorization: `Bearer ${token}`,
            },
        },
    )

    return null
}

@connect(
    state => ({
        loggedIn: Boolean(state.auth.token),
        token: state.auth.token,
        isFinalizingSignup: isFinalizingSignupSelector(state),
        isLoading: isLoadingPartnersGlobalSelector(state),
        timedOut: state.auth.loadingTimedOut,
        partner: currentPartnerSelector(state),
        partnerUser: currentPartnerUserSelector(state),
        partnerContracts: state.entities.partnerContracts,
        acceptedTerms: acceptedTermsSelector(state),
        loadingAcceptedTerms: state.pages.acceptTermsPage.isLoadingTerms,
        unpaidInvoices: unpaidInvoicesTillFirsOfJuneSelector(state),
        isLoadingInvoices: isLoadingInvoicesSelector(state),
        hasMarketplaceLeadAccess: hasMarketplaceLeadAccessSelector(state),
        hasMarketplaceBasicLeadAccess: hasMarketplaceBasicLeadAccessSelector(state),
        hasMarketplacePlusLeadAccess: hasMarketplacePlusLeadAccessSelector(state),
        hasMarketplaceWithoutFeesAccess: hasMarketplaceWithoutFeesAccessSelector(state),
    }),
    {
        loadPartnerAuth: auth.loadPartnerAuth,
        getUnreadParticipantMessages,
        resetApp: auth.resetApp,
        replace,
    }
)
@withTranslation('', { wait: true })
class PartnerApp extends React.PureComponent {
    state = {
        auth: {},
    }

    componentWillMount() {
        if (this.props.isFinalizingSignup === true) {
            return
        }

        this.props.loadPartnerAuth()
    }

    componentWillReceiveProps(newProps) {
        if(
            ((newProps.location.pathname !== this.props.location.pathname) && newProps.partnerUser) ||
            (!this.props.partnerUser && newProps.partnerUser)
        ) {
            this.setAuth({
                ...this.state.auth,
                partnerUser: newProps.partnerUser,
                token: newProps.token,
            })
            newProps.getUnreadParticipantMessages({
                unreadByParticipantId: newProps.partnerUser.id,
            })
        }
        if (this.props.token !== newProps.token) {
            this.setAuth({ ...this.state.auth, token: newProps.token })
        }
        if (this.props.permissions !== newProps.permissions) {
            this.setAuth({ ...this.state.auth, permissions: newProps.permissions })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            segmentPage()
        }
    }

    setAuth = (auth) => {
        this.setState({ auth })
    }

    marketplaceEnabledCountries = []

    resetApp() {
        this.props.resetApp()
    }

    render() {
        const {
            loadingAcceptedTerms,
            isLoadingInvoices,
            loggedIn,
            isLoading,
            timedOut,
            partner,
            partnerContracts,
            location,
            history,
            t,
            isFinalizingSignup,
            hasMarketplaceLeadAccess,
            hasMarketplaceBasicLeadAccess,
            hasMarketplacePlusLeadAccess,
            hasMarketplaceWithoutFeesAccess
        } = this.props

        if (isFinalizingSignup) {
            return <Fragment>
                <div className="signup signup__page">
                    <div className="col-lg-4 col-md-8 col-sm-12">
                        <Route path="/signup/:signupToken/confirm" render={props => <SignupConfirmPage { ...props} />}/>
                    </div>
                </div>
            </Fragment>
        }

        if (!loggedIn) {
            return <Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3">
                            <Route component={LogIn}/>
                        </div>
                    </div>
                </div>
            </Fragment>
        }

        const hasActiveContract = () => Boolean(Object.values(partnerContracts).find(contract => contract.currentPartnerContractPeriod))

        if (isLoading) {
            return <div className="whitefill">
                <LoadingAnimation size={75} type="logo" />
                {timedOut && <div className="alert alert-warning timeout-alert">
                    {t('login.loading_timeout_message')}
                    <br /><br />
                    <a className="btn btn-large btn-primary" onClick={this.resetApp.bind(this)}>{t('login.loading_timeout_button')}</a>
                </div>}
            </div>
        }

        if (config.showRequiredTermsPage[partner.geo.code][partner.industry.identifier] && partner.hasSubscription && (loadingAcceptedTerms || isLoadingInvoices)) {
            return null
        }

        const mobileNavigationTabs = [
            {
                icon: 'mdi mdi-account-box-outline',
                link: '/projects',
                name: t('navigation.projects'),
                active: location.pathname.indexOf('/projects') === 0,
            },
        ]

        return (
            <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
                {partner.geo.code === 'us' &&
                <AccessibilityPrompt />
                }
                <AuthProvider value={this.state.auth}>
                    <PartnerPageHeader
                        hasNewMessage={false}
                        history={history}
                        marketplaceEnabledCountries={this.marketplaceEnabledCountries}
                    />
                    <Switch>
                        <Route exact path="/" render={props => <IndexPage {...props} marketplaceEnabledCountries={this.marketplaceEnabledCountries}/>}/>
                        <Route path="/quote/:leadId" component={QuotePage}/>
                        <Route exact path="/projects" component={ProjectListPage}/>
                        <Route path="/projects/:projectId" component={ProjectPage}/>
                        <Route path="/marketing" component={MarketingPage}/>
                        <Route exact path="/invoices/:invoiceId" component={InvoiceDetailPage} />
                        <Route exact path="/invoices/:invoiceId/:action" component={InvoiceDetailPage} />
                        <Route exact path="/invoices/:invoiceId/:paymentMethod/:action" component={InvoiceDetailPage} />
                        <Route exact path="/invoice-payment-confirmation/:invoiceId" component={InvoicePaymentConfirmationPage} />
                        <Route path="/account" component={AccountPage}/>
                        <Route path="/subscription" component={SubscriptionPage}/>
                        <Route path="/segmentation" render={props => {
                            if(partner.geo.code === 'de')
                                return <Redirect to="/" />
                            return <SegmentationIntroPage {...props}/>
                        }}/>
                        <Route path="/me" component={UserEditPage}/>
                        {partner.state === 'demo' && <Route path="/demo" component={DemoPage}/>}
                        <Route path="/content/new-model" component={QuoteIntroductionPage}/>
                        {/*<Route exact path="/subscription-upgrade" component={SubscriptionUpgradePage}/>*/}
                        <Route path="/subscription-confirmation" component={SubscriptionConfirmationPage}/>
                        <Route path="/subscription-upgrade/:subscriptionPackageId" component={SubscriptionPaymentOptionsPage}/>
                        <Route exact path="/checkout/:checkoutId" component={CheckoutPage}/>
                        <Route exact path="/checkout/:checkoutId/:paymentMethod/:action" component={CheckoutPage} />
                        <Route exact path="/subscription-info" component={SubscriptionInfoPage} />
                        <Route exact path="/terms/:termsId" component={TermsPage} />
                        <Route exact path="/partner-contract" component={PartnerContractsPage} />
                        {!hasActiveContract() && <Route exact path="/partner-offers" component={ContractOfferPage} />}
                        <Route exact path="/marketplace" render={props => {
                            if (!hasMarketplaceBasicLeadAccess) {
                                return <MarketplacePage {...props} marketplaceEnabledCountries={this.marketplaceEnabledCountries}/>
                            }
                            return <Marketplace
                                {...props}
                                marketplaceEnabledCountries={this.marketplaceEnabledCountries}
                                hasMarketplaceLeadAccess={hasMarketplaceLeadAccess}
                                hasMarketplacePlusLeadAccess={hasMarketplacePlusLeadAccess}
                                hasMarketplaceWithoutFeesAccess={hasMarketplaceWithoutFeesAccess}
                            />
                        }} />
                        <Route path="/marketplace/signup" render={props => <MarketplaceSignupPage {...props} marketplaceEnabledCountries={this.marketplaceEnabledCountries} />}/>
                        <Route path='/marketplace/preferences' render={props => <MarketplacePreferencesPage {...props} marketplaceEnabledCountries={this.marketplaceEnabledCountries}/> } />
                        <Route path="/marketplace/packages" render={props => <MarketplacePackagesPage {...props} marketplaceEnabledCountries={this.marketplaceEnabledCountries} />}/>
                        <Route path="/marketplace/subscription" render={props => <MarketplaceSignupPage {...props} marketplaceEnabledCountries={this.marketplaceEnabledCountries} />}/>
                    </Switch>
                    <DebtorModal />
                    <UserFlow />
                </AuthProvider>
                <PageFooter
                    t={t}
                    user={partner}
                    userType="partner"
                />

                <StickyMobileNavigation
                    hasNewMessage={false}
                    mobileNavigationTabs={mobileNavigationTabs}
                    location={location}
                />

                <LoadingBarWrapper/>
                <BootstrapModal/>
                <PushNotificationBar/>
                <SyncAuth setAuth={this.setAuth} token={this.state.auth.token} />
            </div>
        )
    }
}

export default PartnerApp
