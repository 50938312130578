import {
    GET_RATINGS, GET_RATINGS_SUCCESS, GET_RATINGS_FAIL,
} from '../../actions/ratings'

const initialState = {
    ratingIds: new Set([]),
    isLoading: true,
    currentPage: 1,
    pages: null,
    pagination: {},
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_RATINGS:
            return {
                ...state,
                isLoading: !action.append,
                isLoadingMore: action.append,
            }
        case GET_RATINGS_SUCCESS:
            // let items = action.append && state.items ? state.items.concat(action.ratings) : action.ratings
            return {
                ...state,
                ratingIds: new Set(action.response.result),
                pagination: action.response.pagination,
                isLoading: false,
                isLoadingMore: false,
            }
        case GET_RATINGS_FAIL:
            return {
                ...state,
                isLoading: false,
                isLoadingMore: false,
            }
        default:
            return state
    }
}
