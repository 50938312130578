import { useState } from "react";
import { createStripeSetupIntent } from "../../actions/cashier";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch } from "react-redux";

export default function usePrepareStripeElements(partner){
    const [stripePromise, setStripePromise] = useState(null);
    const [stripeClientSecret, setStripeClientSecret] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState(null);

    const dispatch = useDispatch();

    async function prepareCardElements() {
        setIsLoading(true);
        const setupIntentResponse = await dispatch(createStripeSetupIntent(partner));
        setStripePromise(loadStripe(setupIntentResponse.publicKey));
        setStripeClientSecret(setupIntentResponse.clientSecret);
        setOptions({
            clientSecret: setupIntentResponse.clientSecret,
            appearance: {
                theme: 'stripe'
            }
        });
        setIsLoading(false);
    };

    return { isLoading, stripePromise, stripeClientSecret, options, prepareCardElements };
}
