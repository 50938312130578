import React, { memo } from 'react'

const Header = memo(props =>
    <thead>
        <tr>
            {props.children}
        </tr>
    </thead>
)

Header.displayName = 'Header'

export default Header
