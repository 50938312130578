import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { currentPartnerSelector } from '../../../selectors/auth'
import { hasCommunicationAccessSelector } from '../../../selectors/access'
import {
    addNoteToProject,
    getProject,
    getProjectEvents,
    getProjectNotes,
    getProjectStatuses,
} from '../../../actions/projects'
import LoadingAnimation from '../../utils/LoadingAnimation'
import ProjectDetailPage from './ProjectDetailPage'
import { getQuote } from '../../../actions/quotes'
import { quoteByProject } from '../../../selectors/quotes'
import { getDeliverables } from '../../../actions/deliverables'
import SidebarPageTemplate from '../../../containers/layout/SidebarPageTemplate'
import PageTitle from '../../utils/PageTitle'
import { Content, SeparatorLine } from '../../cards/index'
import styled from 'styled-components'
import { PrimaryLink } from '../../elements/Links'
import { getLead } from '../../../actions/leads'
import { leadByProjectDatafieldsMatchSelector } from '../../../selectors/lead'
import { ProjectNotesPage } from './ProjectNotesPage'
import { ContainerFluid } from '../../cards'
import { Card } from '../../blocks/Card'
import { getDataFields } from '../../../actions/datafields'
import CustomerChoiceDetailPage from './CustomerChoiceDetailPage'
import { isProjectClientCustomerChoiceSelector } from '../../../selectors/projects'

const NavigationInfoComponent = ({ className, customer, customerNaming, t }) => (
    <div className={className + ' hidden-xs'} >
        <Card.Content modifiers={[ 'pY_2' ]}>
            <PrimaryLink to="/projects" modifiers={[ 'linkBlock' ]}>
                <strong>
                    <i className="mdi mdi-chevron-left" />&nbsp;
                    {t('projects.all_clients')}
                </strong>
            </PrimaryLink>
        </Card.Content>
        <Card.Line/>
        <Card.Content>
            <Card.MarkCircle modifiers={[ 'primary', 'mB_1' ]} className="mdi mdi-factory mdi-24px"/>
            <br />
            {customerNaming}
            <br/>
            {customer.geo.zipCode && <span>{ customer.geo.zipCode }</span>}
            {customer.geo.cityName && <span> { customer.geo.cityName }</span>}
        </Card.Content>
        <SeparatorLine />
    </div>)

const NavigationInfo = styled(NavigationInfoComponent)`
  text-align: center;

  ${SeparatorLine} {
      margin: 0;
  }
  ${PrimaryLink} {
    text-align: left;
}
  ${Content} {
    &> div:first-child {
        margin: auto;
    }
}
`

@connect(
    (state, props) => ({
        partner: currentPartnerSelector(state),
        quote: quoteByProject(state)(state.entities.projects[props.match.params.projectId]),
        isProjectClientCustomerChoice: isProjectClientCustomerChoiceSelector(state, props.match.params.projectId),
        lead: leadByProjectDatafieldsMatchSelector(state, props.match.params.projectId),
        deliverables: state.pages.projectDetail
            .deliverables
            .map(id => state.entities.deliverables[id]),
        project: state.entities.projects[props.match.params.projectId],
        hasCommunicationAccess: hasCommunicationAccessSelector(state),
        notes: state.pages.projectDetail
            .notes
            .map(id => state.entities.projectNotes[id]),
    }),
    {
        getDataFields,
        getProject,
        getProjectEvents,
        getQuote,
        getDeliverables,
        getProjectNotes,
        addNoteToProject,
        getProjectStatuses,
        getLead,
    }
)
@withTranslation('', { wait: true })
export default class ProjectPage extends React.Component {
    constructor() {
        super()
        this.state = {
            showInfoCard: false,
        }
    }

    componentDidMount() {
        this.freshProjectFromProps(this.props)
        this.props.getProjectStatuses()
        this.props.getDataFields({ geoCode: this.props.partner.geo.code })
        window.scrollTo(0, 0)
    }

    componentWillReceiveProps(newProps) {
        if(this.props.match.params.projectId !== newProps.match.params.projectId) {
            this.freshProjectFromProps(newProps)
            window.scrollTo(0, 0)
        }
    }

    freshProjectFromProps(props) {
        const {
            getProject,
            getProjectNotes,
            getQuote,
            match,
        } = props
        getProject(match.params.projectId).then(res => {
            const project = res.entities.projects[res.result]
            if(project.quote) {
                getQuote(project.lead.id, project.quote)
                    .then(a => {
                        if (!([ 'won', 'lost' ].includes(a.entities.quotes[a.result].progress))) {
                            this.setState({ showInfoCard: true })
                        }
                        getDeliverables({
                            partnerId: project.partner.id,
                            leadId: project.lead.id,
                        })
                    })
            }
            if(project.lead) {
                this.props.getLead(this.props.project.lead.id)
            }
        })
        getProjectNotes(match.params.projectId)
    }
    handleNoteSubmit(data, reset) {
        this.props.addNoteToProject(this.props.project.id, data.content)

        // Reset form
        reset()
    }
    render() {
        const {
            t,
            project,
            partner,
            quote,
            deliverables,
            lead,
            match,
            notes,
            location: { hash },
        } = this.props

        const projectId = match.params.projectId

        if (!project || !lead || !lead.id) {
            return <LoadingAnimation />
        }

        const customer = project.customers[0]
        const customerNaming = customer.companyName || (customer.firstName && customer.lastName ?
            customer.firstName + ' ' + customer.lastName : customer.firstName ?
                customer.firstName : customer.lastName ?
                    customer.lastName : project.title)
        const navLinks = [
            this.props.isProjectClientCustomerChoice ? {
                pathname: `/projects/${projectId}`,
                relatedComponent: <CustomerChoiceDetailPage
                    lead={lead}
                    partner={partner} />,
                mainText: t('projects.navigation.details.title'),
                secondaryText: t('projects.navigation.details.description'),
            } : {
                pathname: `/projects/${projectId}`,
                relatedComponent: <ProjectDetailPage
                    project={project}
                    lead={lead}
                    quote={quote}
                    deliverables={deliverables} />,
                mainText: t('projects.navigation.details.title'),
                secondaryText: t('projects.navigation.details.description'),
            },
            {
                pathname: `/projects/${projectId}/project-notes`,
                relatedComponent: <ProjectNotesPage
                    t={t}
                    customer={customer}
                    handleNoteSubmit={this.handleNoteSubmit.bind(this)}
                    notes={notes}/>,
                mainText: t('projects.navigation.notes.title'),
                secondaryText: t('projects.navigation.notes.description'),
            },
        ]

        return <ContainerFluid>
            <PageTitle
                title={customerNaming}
                backLinkName={t('projects.all_clients')}
            />
            {/* Sidebar and pages component*/}
            <SidebarPageTemplate
                navLinks={navLinks}
                hash={hash}
                navigationInfo={
                    <NavigationInfo customer={customer} customerNaming={customerNaming} t={t}/>
                }
            />
        </ContainerFluid>
    }
}
