import React from 'react'
import styled from 'styled-components'
import Input from './_Input'
import InputOverride from './_InputOverride'
import Label from './_Label'

const RadioInput = styled.div`
  display: inline-block;
`

export const RadioBox = props =>
    <RadioInput>
        <Input {...props}/>
        <InputOverride htmlFor={props.id}/>
    </RadioInput>

RadioBox.Label = Label
