import React from 'react'
import { Card } from '../../../../../components/blocks/Card'
import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { PrimaryBlankLink } from '../../../../../components/elements/Links'
import { Button } from '../../../../../components/elements/ButtonNew'

const SHOW_DATA_FIELDS = [
    'partner_user_languages',
    'establishment_year',
    'employees_count',
    'customers_count',
    'availability',
]

const PartnerInfo = styled.div`
  margin-bottom: 35px;
`

export const AboutPartnerCard = ({
    partnerUser = {},
    partner,
    t,
    handleRevealPhoneNumberClick,
    isPhoneNumbersRevealed,
    creditSystemIsLoading,
}) =>
    <Card>
        <Card.Content modifiers={[ 'pY_5', 'pX_6' ]}>
            <Card.Heading modifiers={[ 'primaryColor', 'mB_5' ]}>
                {t('quotes.quote.about_quote_title', { name: partner.companyName })}
            </Card.Heading>
            <Row>
                <Col md={6}>
                    {partner.geo.address && <PartnerInfo>
                        <Card.Label modifiers={[ 'strong' ]}>{t('partner.address')}</Card.Label>
                        <div>
                            {partner.geo.address} — {partner.geo.zipCode }
                            <br/>
                            {partner.geo.cityName}
                        </div>
                    </PartnerInfo>}
                    {!isPhoneNumbersRevealed && <Button
                        className="hidden-xs"
                        modifiers={[ creditSystemIsLoading ? 'disabled' : 'action', 'mB_3', 'p_2', 'btnBlock', 'maxWidth' ]}
                        disabled={creditSystemIsLoading}
                        onClick={handleRevealPhoneNumberClick}
                    >
                        {t('partner.reveal_phone_number')}
                    </Button>}
                    {isPhoneNumbersRevealed && partnerUser.phone && <PartnerInfo>
                        <Card.Label modifiers={[ 'strong' ]}>{t('partner.phone')}</Card.Label>
                        <div>{partnerUser.phone}</div>
                    </PartnerInfo>}
                    {isPhoneNumbersRevealed && partnerUser.mobile && <PartnerInfo>
                        <Card.Label modifiers={[ 'strong' ]}>{t('partner.mobile_phone')}</Card.Label>
                        <div>{partnerUser.mobile}</div>
                    </PartnerInfo>}
                    {partner.website && <PartnerInfo>
                        <Card.Label modifiers={[ 'strong' ]}>{t('partner.web_site')}</Card.Label>
                        <PrimaryBlankLink href={'http://' + partner.website}>{partner.website}</PrimaryBlankLink>
                    </PartnerInfo>}
                </Col>
                <Col md={6}>
                    {Object.values(partner.attributes).map(attribute =>
                        attribute && SHOW_DATA_FIELDS.includes(attribute.identifier) &&
                        <PartnerInfo>
                            <Card.Label modifiers={[ 'strong' ]}>{attribute.label}</Card.Label>
                            <div>{attribute.selected.map(el => <span>{el.title} </span>)}</div>
                        </PartnerInfo>
                    )}
                </Col>
            </Row>
        </Card.Content>
    </Card>
