import React, { useCallback, useContext, useState } from 'react'
import { DropdownContext } from 'components/src/Dropdown'
import Card from 'components/src/Card'
import Text from 'components/src/texts/Text'
import Flex from 'components/src/layout/Flex'
import Range from 'components/src/inputs/Range'
import MultiSelect from 'components/src/inputs/MultiSelect'
import Button from 'components/src/Button'
import Link from 'components/src/Link'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { FilterProps } from './index'
import {
    IndustryGroupResource,
    IndustryResource,
    LeadTypeGroupResource,
    LeadTypeResource
} from 'ageras-api-client/src/api'

interface SelectItemResource {
    id: number
    title: string
    groupId?: number
}

interface LeadListFiltersProps {
    onFiltersSave: (filters: {}) => void
    rangeMax?: number
    industries?: SelectItemResource[]
    types?: SelectItemResource[]
    industryGroups?: SelectItemResource[]
    typeGroups?: SelectItemResource[]
    initialFilters?: FilterProps
    showPreferencesNotification: boolean
}

export const getInitialIndustryGroups = (industries: string[] | undefined, industryGroups: SelectItemResource[] | undefined) => {
    const selectedGroups: number[] = []
    if (!industries || !industryGroups) {
        return selectedGroups
    }

    industryGroups.forEach((group: IndustryGroupResource) => {
        const groupIndustries: string[] | undefined = group.industries?.map((industry: IndustryResource) => String(industry.id))
        if (groupIndustries && groupIndustries.every(v => industries?.includes(v))) {
            selectedGroups.push(Number(group.id))
        }
    })

    return selectedGroups
}

export const getInitialTypeGroups = (types: string[] | undefined, typeGroups: SelectItemResource[] | undefined) => {
    const selectedTypes: number[] = []
    if (!types || !typeGroups) {
        return selectedTypes
    }

    typeGroups.forEach((group: LeadTypeGroupResource) => {
        const groupTypes: string[] | undefined = group.types?.map((type: LeadTypeResource) => String(type.id))
        if (groupTypes && groupTypes.every(v => types?.includes(v))) {
            selectedTypes.push(Number(group.id))
        }
    })

    return selectedTypes
}

function LeadListFilters({ onFiltersSave, types, industries, industryGroups, typeGroups, rangeMax = 200, initialFilters, showPreferencesNotification }: LeadListFiltersProps) {
    const [ distance, setDistance ] = useState<number>(rangeMax)
    const [ selectedIndustryGroups, setSelectedIndustryGroups ] = useState<number[]>(getInitialIndustryGroups(initialFilters?.industries, industryGroups))
    const [ selectedTypeGroups, setSelectedTypeGroups ] = useState<number[]>(getInitialTypeGroups(initialFilters?.types, typeGroups))
    const { toggleDropdown } = useContext(DropdownContext)
    const { t } = useTranslation('translation')
    const history = useHistory()

    const industryItemToLabel = useCallback(item => industryGroups?.find(group => group.id === item)?.title!, [ industryGroups ])
    const typeItemToLabel = useCallback(item => typeGroups?.find(group => group.id === item)?.title!, [ typeGroups ])

    const handleRangeChange = useCallback((newValue) => {
        setDistance(newValue)
    }, [])

    const handleIndustriesChange = useCallback(industries => {
        setSelectedIndustryGroups(industries)
    }, [])

    const handleTypesChange = useCallback(types => {
        setSelectedTypeGroups(types)
    }, [])

    const handleSave = useCallback(() => {
        const filterIndustries: string[] = []
        if (selectedIndustryGroups.length) {
            industries?.forEach(industry => {
                if (selectedIndustryGroups.includes(industry.groupId!)) {
                    filterIndustries.push(String(industry.id))
                }
            })
        }
        const filterTypes: string[] = []
        if (selectedTypeGroups.length) {
            types?.forEach(type => {
                if (selectedTypeGroups.includes(type.groupId!)) {
                    filterTypes.push(String(type.id))
                }
            })
        }
        const filters = {
            distance,
            industries: filterIndustries,
            types: filterTypes,
        }
        onFiltersSave(filters)
        if (toggleDropdown) {
            toggleDropdown()
        }
    }, [ distance, industries, onFiltersSave, selectedIndustryGroups, selectedTypeGroups, toggleDropdown, types ])

    const pushToPreferencesPage = useCallback(() => {
        history.push('/account/partner-preferences')
    }, [ history ])

    return <Card width="max-content" overflow="initial">
        <Card.Content px={3} pt={3}>
            {!showPreferencesNotification && <Text>{t('marketplace.controls.label_filter_set_preferences')}</Text>}
            {showPreferencesNotification && <Text><Link onClick={pushToPreferencesPage}>{t('marketplace.controls.label_filter_no_preferences')}</Link></Text>}
        </Card.Content>
        <Card.Content px={3} mt={3}>
            <Text size="small" mb={2}>{t('marketplace.controls.filter_options.distance')}</Text>
            <Flex flexDirection="column" mx={1}>
                <Range max={rangeMax} min={50} step={50} value={distance} onChange={handleRangeChange} width="100%" />
            </Flex>
        </Card.Content>
        <Card.Content px={3} mt={3}>
            <Text size="small">{t('marketplace.controls.filter_options.types')}</Text>
            {typeGroups && (
                <MultiSelect
                    selected={selectedTypeGroups}
                    onChange={handleTypesChange}
                    items={typeGroups.map(group => group.id)}
                    placeholder={t('marketplace.controls.filter_options.selected')}
                    itemToLabel={typeItemToLabel}
                />
            )}
        </Card.Content>
        <Card.Content px={3} mt={3}>
            <Text size="small">{t('marketplace.controls.filter_options.services')}</Text>
            {industryGroups && (
                <MultiSelect
                    selected={selectedIndustryGroups}
                    onChange={handleIndustriesChange}
                    items={industryGroups.map(group => group.id)}
                    placeholder={t('marketplace.controls.filter_options.selected')}
                    itemToLabel={industryItemToLabel}
                />
            )}
        </Card.Content>
        <Card.Content px={3} pb={3} mt={3}>
            <Button variant="secondary" size="small" onClick={handleSave}>{t('marketplace.controls.save_options_action')}</Button>
        </Card.Content>
    </Card>
}

export default LeadListFilters
