import React from 'react'
import ReduxToastr from 'react-redux-toastr'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Route, Switch } from 'react-router'
import { withTranslation } from 'react-i18next'
import { loadClientAuth, logout, resetApp } from '../actions/auth'
import ClientPageHeader from '../containers/layout/ClientPageHeader'
import LoadingBarWrapper from './layout/LoadingBarWrapper'
import LeadListPage from '../containers/clients/pages/cases/LeadListPage'
import LeadPage from '../containers/clients/pages/cases/LeadPage'
import LoadingAnimation from './utils/LoadingAnimation'
import { isLoadingClientsGlobalSelector } from '../selectors'
import CheckoutPage from '../containers/clients/pages/CheckoutPage'
import TermsPage from '../containers/common/TermsPage'
import { PageFooter } from './layout/PageFooter'
import { currentClientSelector } from '../selectors/auth'
import ChatWidget from './utils/ChatWidget'
import { CreateReviewPage } from '../containers/clients/pages/cases/CreateReviewPage'
import ClientMessagesPage from '../containers/clients/pages/ClientMessagesPage'
import { getUnreadParticipantMessages } from '../actions/conversations'
import { StickyMobileNavigation } from '../containers/layout/StickyMobileNavigation'
import { ClientPostponeMessagePage } from '../containers/clients/pages/ClientPostponeMessagePage'
import { RejectionFeedbackPage } from '../containers/clients/pages/RejectionFeedbackPage'
import { QuoteDetailPage } from '../containers/clients/pages/cases/QuoteDetailPage'
import AccessibilityPrompt from './prompts/AccessibilityPrompt'
import { segmentPage } from '../utils/segment'
import config from '../config'

@connect(
    state => ({
        loggedIn: Boolean(state.auth.token),
        isLoading: isLoadingClientsGlobalSelector(state),
        timedOut: state.auth.loadingTimedOut,
        client: currentClientSelector(state),
        leads: Object.values(state.entities.leads),
        user: currentClientSelector(state),
        location: state.router.location,
        routerPathname: state.router.location.pathname,
        pageTitle: state.pageTitle,
        hasNewMessage: state.pages.conversationsMessages.unreadMessagesIds
            .map(id => state.entities.conversationMessages[id]).length,
    }),
    {
        loadClientAuth,
        getUnreadParticipantMessages,
        resetApp,
        logout,
    }
)
@withTranslation('', { wait: true })
class ClientApp extends React.Component {

    constructor(props) {
        super(props)
        const currentUrlParams = new URLSearchParams(window.location.search);
        const queryString = currentUrlParams.toString();
        const redirectUrl = queryString ? `${config.newClientPortalUrl}?${queryString}` : config.newClientPortalUrl;
        window.location.replace(redirectUrl)
    }
    
    componentDidMount() {
        this.props.loadClientAuth()
    }

    componentWillReceiveProps(newProps) {
        if(
            newProps.location.pathname !== this.props.location.pathname ||
            !this.props.client && newProps.client
        ) {
            newProps.getUnreadParticipantMessages({
                unreadByParticipantId: newProps.client.id,
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            segmentPage()
        }
    }

    resetApp() {
        this.props.resetApp()
    }

    shouldShowFooter() {
        return ![
            '/messages',
        ].includes(this.props.routerPathname)
    }

    render() {
        const {
            loggedIn,
            isLoading,
            pageTitle,
            timedOut,
            logout,
            client,
            leads,
            user,
            location,
            hasNewMessage,
            history,
            t,
        } = this.props

        const mobileNavigationTabs = [
            {
                icon: 'mdi mdi-shape-outline',
                link: '/cases',
                name: t('navigation.cases'),
                active: location.pathname.indexOf('/cases') === 0,
            },
            {
                icon: 'mdi mdi-message-text-outline',
                link: '/messages',
                name: t('navigation.messages'),
                active: location.pathname.indexOf('/messages') === 0,
            },
        ]

        if(!loggedIn) {
            return <div className="whitefill">
                <LoadingAnimation size={75} type="logo" />
            </div>
        }

        if (isLoading) {
            return <div className="whitefill">
                <LoadingAnimation size={75} type="logo" />
                {timedOut && <div className="alert alert-warning timeout-alert">
                    {t('login.loading_timeout_message')}
                    <br /><br />
                    <a className="btn btn-large btn-primary" onClick={this.resetApp.bind(this)}>{t('login.loading_timeout_button')}</a>
                </div>}
                <ReduxToastr />
            </div>
        }

        return (
            <div>
                {client.geo.code === 'us' &&
                <AccessibilityPrompt />
                }
                <ClientPageHeader
                    t={t}
                    user={user}
                    logout={logout}
                    pageTitle={pageTitle}
                    location={location}
                    hasNewMessage={false}
                    history={history}
                />
                <Switch>
                    <Route exact path="/" render={() => (
                        <Redirect to="/cases" />
                    )}/>
                    <Route exact path="/cases" component={LeadListPage} />
                    <Route exact path="/cases/:leadId" component={LeadPage} />
                    <Route exact path="/cases/:leadId/:partnerId" component={QuoteDetailPage} />
                    <Route exact path="/checkout/:checkoutId" component={CheckoutPage}/>
                    <Route exact path="/checkout/:checkoutId/:paymentMethod/:action" component={CheckoutPage} />
                    <Route exact path="/cases/:leadId/:partnerId/review" component={CreateReviewPage} />
                    <Route exact path="/terms/:termsId" component={TermsPage} />
                    <Route exact path="/messages" component={ClientMessagesPage} />
                    <Route exact path="/cases-postponed" component={ClientPostponeMessagePage} />
                    <Route exact path="/rejection-feedback/:leadId" component={RejectionFeedbackPage} />

                    {/*<Route path="/account" component={AccountComponent} />*/}
                </Switch>

                {this.shouldShowFooter() &&
                    <PageFooter
                        userType="client"
                        user={client}
                        t={t}
                    />
                }
                <StickyMobileNavigation
                    hasNewMessage={false}
                    mobileNavigationTabs={mobileNavigationTabs}
                />
                <LoadingBarWrapper/>
                <ReduxToastr />
                <ChatWidget
                    geoCode={client.geo.code}
                    user={client}
                />
            </div>
        )
    }
}

export default ClientApp
