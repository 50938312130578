import React from 'react'
import { primaryColor } from '../../../../../theme/variables'
import { Card } from '../../../../../components/blocks/Card'
import styled from 'styled-components'
import { Flex } from '../../../../../components/elements/Flex'
import Portrait from '../../../../../components/elements/Portrait'

const PrimaryCardContent = styled(Card.Content)`
  background-color: ${primaryColor};
  color: #fff;
  i {
    line-height: 1.5;
  }
`

export const InterviewQuoteCard = ({
    partnerUser = {},
    partner,
}) =>
    Object.values(partner.attributes).map(attribute => attribute && attribute.identifier === 'interview_quote' &&
        <Card>
            <PrimaryCardContent modifiers={[ 'pY_5', 'pX_6' ]}>
                <i>"{attribute.selected[0].title}"</i>
                <Flex modifiers={[ 'mT_5' ]}>
                    <Portrait
                        modifiers={[ 'mR_2' ]}
                        name={partnerUser.name}
                        image={partnerUser.profilePicture}
                        size={45}
                    />
                    <div>
                        <div><strong>{partnerUser.name}</strong></div>
                        <div>{partner.companyName}</div>
                    </div>
                </Flex>
            </PrimaryCardContent>
        </Card>
    )
