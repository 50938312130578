
import { CALL_API } from '../middleware/api'

export const CREATE_REQUEST_MEETING_ACTION = 'meetings/CREATE_REQUEST_MEETING_ACTION'
export const CREATE_REQUEST_MEETING_ACTION_SUCCESS = 'meetings/CREATE_REQUEST_MEETING_ACTION_SUCCESS'
export const CREATE_REQUEST_MEETING_ACTION_FAIL = 'meetings/CREATE_REQUEST_MEETING_ACTION_FAIL'

export const createRequestMeetingAction = (partnerId, leadId, dates, action) => ({
    [CALL_API]: {
        types: [ CREATE_REQUEST_MEETING_ACTION, CREATE_REQUEST_MEETING_ACTION_SUCCESS, CREATE_REQUEST_MEETING_ACTION_FAIL ],
        endpoint: `/meetings/actions`,
        options: {
            method: 'post',
            body: {
                partner_id: partnerId,
                lead_id: leadId,
                dates,
                action,
            },
        },
    },
})
