import React from 'react'
import { SeparatorLine } from '../cards/index'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { fadedTextColor } from '../../theme/variables'
import FormattedAmount from '../utils/FormattedAmount'
import groupBy from 'lodash.groupby'
import mapValues from 'lodash.mapvalues'

const types = {
    yearly: 1,
    monthly: 12,
    quarterly: 4,
}

export const QuotePriceDetail = ({ linkUrl, t, quote, close, includeVat }) => {
    let items = quote.items.map(el => ({
        ...el,
        productCustomId: el.title + el.type + el.leadProductId,
    }))

    let separatedItems = mapValues(
        groupBy(items, 'type'),
        valueGroupedItemsByType =>
            mapValues(
                groupBy(valueGroupedItemsByType, 'productCustomId'),
                valueGroupedByUniqueItemsId =>
                    valueGroupedByUniqueItemsId.reduce((accumulator, current) => (
                        {
                            title: current.title,
                            amount: accumulator.amount + (includeVat ? current.amountInclVat : current.amountExclVat).amount,
                            currency: (includeVat ? current.amountInclVat : current.amountExclVat).currency,
                            index: current.type === 'unit' ? (current.quantity || 1) : types[current.type],
                        }),
                        { amount: 0, index: 0, currency: '' }
                    )
            )
        )

    const listOf = Object.values(mapValues(separatedItems, (valueGroupedItemsByType, keyTypeName) => [
        <tr>
            <td colSpan="3" style={{ paddingTop: '10px' }}>
                <b>{t(`quotes.items.types.${keyTypeName}`)}</b>
            </td>
        </tr>,
        Object.values(mapValues(valueGroupedItemsByType, item =>
            <tr>
                <td className="text-left">{item.title}:</td>
                <td>{item.index} ×</td>
                <td className="text-right">
                    <FormattedAmount
                        amount={item.amount}
                        decimals={2}
                        currency={item.currency}
                    />
                </td>
            </tr>
        )),
    ]))

    return <div className="quote-detail-price">
        <button
            type="button"
            className="close"
            onClick={() => close()}
        >
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
        </button>
        <p style={{ marginTop: 0 }}>
            <span className="text-bold">{t('quotes.quote.price_details_header')}</span>
        </p>
        <p className="intro-text-color">{t('quotes.quote.price_details_description')}</p>
        <table style={{
            width: '100%',
            color: fadedTextColor,
        }}>
            <colgroup>
                <col width="auto" />
                <col width="50px" />
                <col width="auto" />
            </colgroup>
            {listOf}
        </table>
        <SeparatorLine width="100%" margin="15px auto" />
        <Row>
            <Col md={6} className="text-left">{t('quotes.quote.table_info.total_price')}: </Col>
            <Col md={6} className="text-right">
                <FormattedAmount
                    amount={(includeVat ? quote.totalAmountInclVat : quote.totalAmountExclVat).amount}
                    currency={(includeVat ? quote.totalAmountInclVat : quote.totalAmountExclVat).currency}
                    decimals={2}
                />
            </Col>
        </Row>
        {linkUrl && <div>
            <SeparatorLine width="100%" margin="15px auto" />
            <p style={{ marginTop: 0, textDecoration: 'underline' }}
               className="text-center text-active">
                <Link to={linkUrl}>{t('quotes.quote.price_details_see_more')}</Link>
            </p>
        </div>}
    </div>
}
