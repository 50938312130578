import React from 'react'
import { TextareaGroup } from '../../forms/inputs/TextareaGroup'
import { Field } from 'redux-form'
import { Card } from '../../blocks/Card'

export const CompanyInterviewQuoteContent = ({ fields, t }) => {
    if(!fields) return null

    const dataFields = fields.getAll()

    return <div>
        {fields.map((member, index) => dataFields[index].identifier === 'interview_quote' && [
            <Card.Line />,
            <Card.Content>
                <Card.Title>{t('company_profile.personal_quote.title')}</Card.Title>
                <Card.Intro>{t('company_profile.personal_quote.intro')}</Card.Intro>
                <Field
                    label={dataFields[index].label}
                    name={`${member}.selected[0].title`}
                    component={TextareaGroup}
                />
            </Card.Content>,
        ])}
    </div>
}
