import { cardBorderColor } from '../../../theme/variables'
import styled from 'styled-components'
import MarkCircle from './_MarkCircle'
import ListItem from './_ListItem'
import Heading from './_Heading'
import Content from './_Content'
import Header from './_Header'
import Label from './_Label'
import Intro from './_Intro'
import Title from './_Title'
import Text from './_Text'
import Line from './_Line'
import Meta from './_Meta'

import { applyResponsiveStyleModifiers, applyStyleModifiers } from 'styled-components-modifiers'
import { MODIFIER_MARGIN_CONFIG } from '../../elements/modifiers/spaceModifiers'

export const Card = styled.div`
    background-color: #fff;
    border: 1px solid ${cardBorderColor};
    border-radius: ${props => props.borderRadius || '3px'};
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.09);
    margin: ${props => props.margin || '0 0 20px 0'};
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_MARGIN_CONFIG)}
`
Card.MarkCircle = MarkCircle
Card.ListItem = ListItem
Card.Content = Content
Card.Heading = Heading
Card.Header = Header
Card.Title = Title
Card.Label = Label
Card.Intro = Intro
Card.Text = Text
Card.Line = Line
Card.Meta = Meta
