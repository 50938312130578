import React from 'react'
import { withTranslation } from 'react-i18next'
import PageTitle from '../utils/PageTitle'
import { Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import { connect } from 'react-redux'
import { getAuthPermissions } from '../../actions/auth'

@connect(
    (state, props) => ({}),
    {
        getAuthPermissions,
    }
)
@withTranslation('', { wait: true })
export default class SubscriptionConfirmationPage extends React.Component {
    componentDidMount() {
        this.props.getAuthPermissions()
    }

    render() {
        const {
            t,
        } = this.props
        return <div className="container-fluid" style={{ marginTop: '30px' }}>
            <PageTitle identifier="subscription.confirmation.page_title" />
            <div className="page-splash"
                 style={{ maxWidth: '760px', marginTop: '55px' }}>
                <div className="icon-container"
                     style={{ width: '75px', margin: 'auto' }}>
                    <img src="/images/icons/baloons.svg" />
                </div>
                <h1 className="intro-heading">
                    <ReactMarkdown>
                        {t('subscription.confirmation.title')}
                    </ReactMarkdown>
                </h1>
                <div className="intro-text" style={{ margin: ' 0 90px 30px 90px' }}>
                    {t('subscription.confirmation.sub_title')}
                </div>
                <br />
                <Link to="" className="btn btn-block btn-lg btn-action"
                      style={{ maxWidth: '400px', margin: 'auto' }} >
                    {t('subscription.confirmation.button_text')}
                </Link>
            </div>
        </div>
    }
}
