import { createSelector } from 'reselect'

export const renderedTermsByIdSelector = createSelector(
    state => state.entities.renderedTermss,
    terms => termId => Object.values(terms).find(t => t.termId === parseInt(termId))
)

export const acceptedTermsSelector = createSelector(
    state => state.pages.acceptTermsPage.termsId,
    state => state.entities.termss,
    (termsId, termss) => termsId.map(id => termss[id])
)
