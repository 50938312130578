import { currentPartnerSelector, currentPartnerUserSelector } from '../selectors/auth'
import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'
import * as coupons from './coupons'
import config from '../config'
import { fetchJSON } from '../fetch'

export const GET_LEADS = 'leads/GET_LEADS'
export const GET_LEADS_SUCCESS = 'leads/GET_LEADS_SUCCESS'
export const GET_LEADS_FAIL = 'leads/GET_LEADS_FAIL'

export const GET_DEMO_LEADS = 'leads/GET_DEMO_LEADS'
export const GET_DEMO_LEADS_SUCCESS = 'leads/GET_DEMO_LEADS_SUCCESS'
export const GET_DEMO_LEADS_FAIL = 'leads/GET_DEMO_LEADS_FAIL'

export const GET_RECENT_LEADS = 'leads/GET_RECENT_LEADS'
export const GET_RECENT_LEADS_SUCCESS = 'leads/GET_RECENT_LEADS_SUCCESS'
export const GET_RECENT_LEADS_FAIL = 'leads/GET_RECENT_LEADS_FAIL'

export const GET_ALL_EXCLUSIVE_LEADS = 'leads/GET_ALL_EXCLUSIVE_LEADS'
export const GET_ALL_EXCLUSIVE_LEADS_SUCCESS = 'leads/GET_ALL_EXCLUSIVE_LEADS_SUCCESS'
export const GET_ALL_EXCLUSIVE_LEADS_FAIL = 'leads/GET_ALL_EXCLUSIVE_LEADS_FAIL'

export const GET_BOUGHT_LEADS = 'leads/GET_BOUGHT_LEADS'
export const GET_BOUGHT_LEADS_SUCCESS = 'leads/GET_BOUGHT_LEADS_SUCCESS'
export const GET_BOUGHT_LEADS_FAIL = 'leads/GET_BOUGHT_LEADS_FAIL'

export const GET_LEAD = 'leads/GET_LEAD'
export const GET_LEAD_SUCCESS = 'leads/GET_LEAD_SUCCESS'
export const GET_LEAD_FAIL = 'leads/GET_LEAD_FAIL'

export const BUY_LEAD = 'leads/BUY_LEAD'
export const BUY_LEAD_SUCCESS = 'leads/BUY_LEAD_SUCCESS'
export const BUY_LEAD_FAIL = 'leads/BUY_LEAD_FAIL'

export const GO_TO_PAGE = 'leads/GO_TO_PAGE'
export const CLEAR_LIST = 'leads/CLEAR_LIST'

export const LEAD_ACTION_RECORD_REJECTION = 'leads/LEAD_ACTION_RECORD_REJECTION'
export const LEAD_ACTION_RECORD_REJECTION_SUCCESS = 'leads/LEAD_ACTION_RECORD_REJECTION_SUCCESS'
export const LEAD_ACTION_RECORD_REJECTION_FAIL = 'leads/LEAD_ACTION_RECORD_REJECTION_FAIL'

export const LEAD_ACTION_RECORD_BUY_FROM_MARKETPLACE = 'leads/LEAD_ACTION_RECORD_BUY_FROM_MARKETPLACE'
export const LEAD_ACTION_RECORD_BUY_FROM_MARKETPLACE_SUCCESS = 'leads/LEAD_ACTION_RECORD_BUY_FROM_MARKETPLACE_SUCCESS'
export const LEAD_ACTION_RECORD_BUY_FROM_MARKETPLACE_FAIL = 'leads/LEAD_ACTION_RECORD_BUY_FROM_MARKETPLACE_FAIL'

export const GET_LEAD_ALLOCATION_SUGGESTIONS = 'leads/GET_LEAD_ALLOCATION_SUGGESTIONS'
export const GET_LEAD_ALLOCATION_SUGGESTIONS_SUCCESS = 'leads/GET_LEAD_ALLOCATION_SUGGESTIONS_SUCCESS'
export const GET_LEAD_ALLOCATION_SUGGESTIONS_FAIL = 'leads/GET_LEAD_ALLOCATION_SUGGESTIONS_FAIL'

export const ACCEPT_LEAD_ALLOCATION_SUGGESTIONS = 'leads/ACCEPT_LEAD_ALLOCATION_SUGGESTIONS'
export const ACCEPT_LEAD_ALLOCATION_SUGGESTIONS_SUCCESS = 'leads/ACCEPT_LEAD_ALLOCATION_SUGGESTIONS_SUCCESS'
export const ACCEPT_LEAD_ALLOCATION_SUGGESTIONS_FAIL = 'leads/ACCEPT_LEAD_ALLOCATION_SUGGESTIONS_FAIL'

export const getLead = (leadId, criteria) => ({
    [CALL_API]: {
        types: [ GET_LEAD, GET_LEAD_SUCCESS, GET_LEAD_FAIL ],
        endpoint: `/leads/${leadId}`,
        schema: schemas.lead,
        options: {
            body: criteria,
        },
    },
})

export const buyLead = (leadId, partnerId, punchPrice, meetingBooking = false, meetingPrice = null) => (dispatch, getState) => {
    const body = {
        partner_id: partnerId,
        punch_price: punchPrice,
        meeting_booking: meetingBooking,
        method: 'weblogin',
    }

    if (meetingBooking === true && meetingPrice !== null) {
        body.meeting = {
            meeting_price: meetingPrice,
            partner_user_id: currentPartnerUserSelector(getState()).id,
        }
    }

    return dispatch({
        [CALL_API]: {
            types: [ BUY_LEAD, BUY_LEAD_SUCCESS, BUY_LEAD_FAIL ],
            endpoint: `/leads/${leadId}/offers`,
            schema: schemas.leadOffer,
            options: {
                method: 'post',
                body: body,
            },
        },
    }).then(() => {
        dispatch(getLead(leadId))
        dispatch(coupons.getCoupons(partnerId))
    }
)
}

export const getLeads = (criteria, types = [
    GET_LEADS,
    GET_LEADS_SUCCESS,
    GET_LEADS_FAIL,
]) => ({
    [CALL_API]: {
        types,
        endpoint: '/leads',
        schema: schemas.leads,
        options: {
            body: {
                ...criteria,
                limit: criteria.limit || 100,
            },
        },
    },
})

export const getBoughtLeads = (page = 1, criteria = {}) => (dispatch, getState) => dispatch(getLeads({
    ...criteria,
    page,
    partnerId: currentPartnerSelector(getState()).id,
    sort: '-created_at',
    hasMaxQuotes: '0',
    hasAcceptedQuote: '0',
    hasRejectedQuote: '0',
    leadCategory: [ 'basic' ],
}, [ GET_BOUGHT_LEADS, GET_BOUGHT_LEADS_SUCCESS, GET_BOUGHT_LEADS_FAIL ]))

export const getRecentLeads = (businessUnitId, page = 1) => (dispatch, getState) => dispatch(getLeads({
    page,
    sort: '-validated_at',
    isQuotesLead: true,
    status: 'validated',
    hasMaxQuotes: '0',
    hasAcceptedQuote: '0',
    hasRejectedQuote: '0',
    segmentationCriteria: 'all',
    segmentedForPartnerBusinessUnitId: businessUnitId,
    noQuoteForPartnerId: currentPartnerSelector(getState()).id,
    geoCode: currentPartnerSelector(getState()).geo.code,
    leadCategory: [ 'basic' ],
}, [ GET_RECENT_LEADS, GET_RECENT_LEADS_SUCCESS, GET_RECENT_LEADS_FAIL ]))

export const getDemoLeadsForPartner = (partnerId, segmentIds, geoRegionIds, page = 1) => ({
    [CALL_API]: {
        types: [ GET_DEMO_LEADS, GET_DEMO_LEADS_SUCCESS, GET_DEMO_LEADS_FAIL ],
        endpoint: `/leads/demopartners/${partnerId}`,
        schema: schemas.leads,
        options: {
            body: {
                segmentedForRevenueSegmentId: segmentIds,
                geoRegionId: geoRegionIds,
                page,
            },
        },
    },
})

export const getAllExclusiveLeads = (page = 1) => (dispatch, getState) => dispatch(getLeads({
    page,
    sort: '-validated_at',
    isQuotesLead: true,
    status: 'validated',
    hasMaxQuotes: '0',
    hasAcceptedQuote: '0',
    hasRejectedQuote: '0',
    noQuoteForPartnerId: currentPartnerSelector(getState()).id,
    geoCode: currentPartnerSelector(getState()).geo.code,
    leadCategory: [ 'exclusive' ],
}, [ GET_ALL_EXCLUSIVE_LEADS, GET_ALL_EXCLUSIVE_LEADS_SUCCESS, GET_ALL_EXCLUSIVE_LEADS_FAIL ]))

export const clearList = () => dispatch => dispatch({
    type: CLEAR_LIST,
})

export const goToPage = page => dispatch => dispatch({
    type: GO_TO_PAGE,
    page,
})

export const leadActionRecordRejection = (leadId, rejectionReasons) => ({
    [CALL_API]: {
        types: [ LEAD_ACTION_RECORD_REJECTION, LEAD_ACTION_RECORD_REJECTION_SUCCESS, LEAD_ACTION_RECORD_REJECTION_FAIL ],
        endpoint: `/leads/${leadId}/actions`,
        schema: schemas.lead,
        options: {
            method: 'post',
            body: {
                action: 'record_rejection_reasons',
                rejectionReasons,
            },
        },
    },
})

export const leadActionRecordBuyFromMarketplace = (leadId, partnerId, customFee) => ({
    [CALL_API]: {
        types: [ LEAD_ACTION_RECORD_REJECTION, LEAD_ACTION_RECORD_REJECTION_SUCCESS, LEAD_ACTION_RECORD_REJECTION_FAIL ],
        endpoint: `/leads/${leadId}/actions`,
        schema: schemas.lead,
        options: {
            method: 'post',
            body: {
                action: 'buy_from_lead_marketplace',
                partnerId,
                customFee,
            },
        },
    },
})

export const getNotAcceptedLeadAllocationSuggestions = (clientId, leadId) => {
    return getLeadAllocationSuggestions(clientId, leadId, false)
}

export const getAcceptedLeadAllocationSuggestions = (clientId, leadId) => {
    return getLeadAllocationSuggestions(clientId, leadId, true)
}

export const getLeadAllocationSuggestions = (clientId, leadId, isAccepted = false) => ({
    [CALL_API]: {
        types: [
            GET_LEAD_ALLOCATION_SUGGESTIONS,
            GET_LEAD_ALLOCATION_SUGGESTIONS_SUCCESS,
            GET_LEAD_ALLOCATION_SUGGESTIONS_FAIL,
        ],
        endpoint: `/clients/${clientId}/allocationsuggestions`,
        options: {
            body: {
                leadId,
                isAccepted,
            },
        },
    },
})

export const acceptLeadAllocationSuggestions = (clientId, suggestionIds) => ({
    [CALL_API]: {
        types: [
            ACCEPT_LEAD_ALLOCATION_SUGGESTIONS,
            ACCEPT_LEAD_ALLOCATION_SUGGESTIONS_SUCCESS,
            ACCEPT_LEAD_ALLOCATION_SUGGESTIONS_FAIL,
        ],
        endpoint: `/clients/${clientId}/allocationsuggestions/actions`,
        options: {
            method: 'post',
            body: {
                action: 'accept_lead_partner_allocation_suggestions',
                allocationSuggestionIds: suggestionIds,
            },
        },
    },
})
