import React from 'react'

export const ModalLayout = ({ showModal, closeModal, children }) => (
    showModal && <div className="blocking-modal">
        <div className="body">
            <div className="text-right"><span onClick={() => closeModal() } className="ap-remove"/></div>
            {children}
        </div>
    </div>
)
