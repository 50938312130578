import React from 'react'
import { Card } from '../../blocks/Card'
import SwitchCheckBoxGroup from '../../forms/inputs/SwitchCheckBoxGroup'
import { Field } from 'redux-form'

export const CompanyServicesContent = ({ t, fields }) => {
    if(!fields) return null

    const dataFields = fields.getAll()

    return <div className="partner-company-services">
        {fields.map((member, index) => dataFields[index].identifier === 'partner_industry' && [
            <Card.Line />,
            <Card.Content>
                <Card.Title>{t('company_profile.services.title')}</Card.Title>
                <Card.Intro>{t('company_profile.services.intro')}</Card.Intro>
                <Field
                    name={`${member}.selected`}
                    component={SwitchCheckBoxGroup}
                    options={dataFields[index].values}
                />
            </Card.Content>,
        ])}
    </div>
}
