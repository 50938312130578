import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import SocialMediaForm from '../cv/SocialMediaForm'
import ExpandButton from '../utils/ExpandButton'
import { Button } from '../elements/Button'
import { Flex } from '../elements/Flex.js'

@withTranslation('', { wait: true })
export default class SocialMediaItem extends Component {

    constructor(props) {
        super(props)

        this.state = {
            editing: false,
        }
    }

    clickEdit = () => {
        this.setState({
            editing: true,
        })
    }

    onCloseEdit = () => {
        this.setState({
            editing: false,
        })
    }

    onSubmitUpdate = data => {
        this.setState({
            editing: false,
        })

        this.props.onSubmitEdit(data)
    }

    deleteSocialMedia = key => {
        this.setState({
            editing: false,
        })
        const data = {
            type: key,
            url: '',
        }
        this.props.onSubmitEdit(data)
    }

    render() {
        const {
            ob,
            obKey, t,
        } = this.props

        const { editing } = this.state

        return (
            <div className="item">
                {!(obKey == 'id') && !(ob[obKey] == '') && !editing &&
                <Flex modifiers={[ 'justifySpaceBetween', 'alignCenter' ]}>
                    <p className="text-capitalize">{obKey}<br/>
                        <a href={ob[obKey]} target="_blank">{t('cv.social_media.visit_your')} {obKey}</a>
                    </p>
                    <Button modifiers={[ 'secondary' ]} onClick={this.clickEdit}>{t('cv.social_media.edit')}</Button>
                </Flex>
                }
                {editing && <ExpandButton
                    expanded={true}
                    onClose={this.onCloseEdit}
                    className="success"
                    content={
                        <div>
                            <SocialMediaForm onSubmit={this.onSubmitUpdate} selectedKey={obKey} initialValues={{
                                type: obKey,
                                url: ob[obKey],
                            }}/>
                            <Button modifiers={[ 'danger', 'btnBlock', 'fullWidth', 'mT_1' ]} onClick={() => this.deleteSocialMedia(obKey)}>{t('cv.social_media.delete')}</Button>
                        </div>
                    }
                    header={t('cv.social_media.edit')}
                />
                }
            </div>
        )
    }
}

