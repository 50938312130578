import React, { Component } from 'react'
import { Flex } from './Flex'
import TabDropdown from './TabDropdown'
import Tab from './TabNew'
import { Text } from './text/Text'
import Icon from './TabDropdown/icon'

const Tabs = ({
    tabs,
    changeTab,
    closeDropdown,
    selectedTab,
    selectedChild,
    toggleDropdown,
    openedDropdownId,
    maxWidth,
    flexWrap,
}) => {
    const handleTabClick = tab => {
        if (tab.disabled) {
            return
        }

        if (tab.static) {
            toggleDropdown(tab.tabId)

            return
        }

        changeTab(tab.tabId)
    }

    return (
        <Flex style={{ overflow: 'visible' }} modifiers={[ 'alignStretch', 'overflowX', flexWrap ? 'flexWrap' : '' ]}>
            {tabs.map(el => el.hidden ||
                <Tab
                    key={`tab_${el.tabId}`}
                    responsiveModifiers={{ active: [ 'active' ] }}
                    size={selectedTab === el.tabId ? 'active' : ''}
                    onClick={() => handleTabClick(el)}
                    disabled={el.disabled}
                    modifiers={[ maxWidth ? 'maxWidth' : '', 'positionRelative' ]}
                    sticky={el.sticky}
                >
                    <Flex>
                        <Flex modifiers={[ 'directionColumn' ]}>
                            <Text modifiers={[ 'bold', 'small', 'center' ]}>
                                {el.tabName}
                            </Text>
                            <Text modifiers={[ 'bold', 'tiny', 'center' ]}>{el.tabDetail}</Text>
                            {el.tabExtra && <Text modifiers={[ 'bold', 'tinyLow', 'center' ]}>{el.tabExtra}</Text>}
                            {selectedChild?.parentId === el.tabId && <Text modifiers={[ 'bold', 'tinyLow', 'center' ]}>
                                {selectedChild.tabName}
                            </Text>}
                        </Flex>
                        {el.static && <Icon style={{ marginLeft: '4px', position: 'absolute', right: 0 }} className="mdi mdi-chevron-down mdi-18px" open={openedDropdownId === el.tabId} />}
                    </Flex>
                    {el.children &&
                        <TabDropdown
                            tabId={el.tabId}
                            options={el.children}
                            onClick={option => changeTab(option.tabId)}
                            open={openedDropdownId === el.tabId}
                            openedTabId={openedDropdownId}
                            close={closeDropdown}
                        />
                    }
                </Tab>
            )}
        </Flex>
    )
}

class TabsContainer extends Component {
    state = {
        openedDropdownId: null,
    }

    handleToggleDropdown = tabId => {
        this.setState({
            openedDropdownId: this.state.openedDropdownId === tabId
                ? null
                : tabId,
        })
    }

    handleCloseDropdown = () => {
        this.setState({
            openedDropdownId: null,
        })
    }

    render() {
        const { openedDropdownId } = this.state

        return <Tabs {...this.props}
            openedDropdownId={openedDropdownId}
            toggleDropdown={this.handleToggleDropdown}
            closeDropdown={this.handleCloseDropdown}
        />
    }
}
export default TabsContainer
