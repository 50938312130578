import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class GlobalWarnings extends Component {
    render() {
        if(!this.props.verification) {
            return null
        }

        const { t } = this.props
        const {
            entries,
        } = this.props.verification

        let message = null

        if(entries.find(e => e.identifier.indexOf('legal_entity') > -1)) {
            message =<Link to="/account/kyc">
                <strong>{t('global_warnings.important')}</strong>&nbsp;&mdash;
                {t('global_warnings.legal_entity_info_missing')}
            </Link>
        } else if(entries.find(e => e.identifier.indexOf('bank_account') > -1)) {
            console.log("found entry", entries.find(e => e.identifier.indexOf('bank_account')))
            message = <Link to="/account/payout-info">
                <strong>{t('global_warnings.important')}</strong>&nbsp;&mdash;
                {t('global_warnings.bank_account_info_missing')}
            </Link>
        }

        if(!message) {
            return null
        }

        return (
            <div className="global-alerts">
                <div className="global-alert global-alert-warning">
                    {message}
                </div>
            </div>
        )
    }
}
