
let portal

if (process.env.REACT_APP_PROJECT === 'partners') {
    portal = 'partners'
}

if (process.env.REACT_APP_PROJECT === 'clients') {
    portal = 'clients'
}

export const portalPicker = () => {
    if (portal) {
        return portal
    }
    throw new Error(`Unknown project`)
}

export default portalPicker
