import styled from 'styled-components'
import NavLink from './NavLink'
import Text from './Text'
import { cardBorderColor } from '../../../theme/variables'
import Chevron from './Chevron'

export const SidebarNav = styled.ul`
    min-width: 242px;
    box-shadow: 0 5px 10px 6px rgba(0, 0, 0, 0.02);
    border: 1px solid ${cardBorderColor};
    background-color: #fff;
    border-radius: 3px;
    padding: 0;
    margin: 0;
`

SidebarNav.NavLink = NavLink
SidebarNav.Text = Text
SidebarNav.Chevron = Chevron
