import React from 'react'
import { ButtonToolbar, Dropdown, MenuItem } from 'react-bootstrap'
import { Badge } from '../cards/index'
import { Radio } from '../blocks/radio'
import { TriangleBadge } from '../blocks/triangleBadge'
import { Flex } from '../cards'
import { cardBorderColor, inputBackgroundColor, secondaryColorTwo } from '../../theme/variables'
import styled from 'styled-components'

class CustomToggle extends React.Component {
    handleClick(e) {
        e.preventDefault()
        this.props.onClick(e)
    }

    render() {
        return <a href="" onClick={e => this.handleClick(e)}>
            {this.props.children}
        </a>
    }
}

export class ProjectStatusChooser extends React.PureComponent {
    render() {
        const {
            selectedStatus,
            projectStatuses,
            project,
            className,
            t,
            ...props
        } = this.props

        return <ButtonToolbar bsClass={className}>
            <form>
                <Dropdown id={t('projects.statuses.' + selectedStatus)} onClick={e => e.stopPropagation()}>
                    <CustomToggle bsRole="toggle">
                        <TriangleBadge text={t('projects.statuses.' + selectedStatus)} icon="mdi mdi-chevron-down" />
                    </CustomToggle>
                    <Dropdown.Menu bsRole="menu">
                        {projectStatuses.map((status, i) =>
                            <MenuItem key={status.name + i} eventKey={status.name} onSelect={status => props.changeProjectStatus(project, status)}>
                                <Radio>
                                    <Flex modifiers={['alignCenter']}>
                                        <Radio.Input
                                            defaultChecked={status.name === selectedStatus}
                                            name="projectStatus"
                                            id={'projectStatusRadio-' + i}
                                        />
                                        <TriangleBadge text={t('projects.statuses.' + status.name)} />
                                    </Flex>
                                </Radio>
                            </MenuItem>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </form>
            <div className="mdi mdi-chevron-down hidden" />
        </ButtonToolbar>
    }
}
export const ProjectStatusSelectBox = styled(ProjectStatusChooser)`
    padding: 8px;
    border-radius: 3px;
    border: 1px solid ${cardBorderColor};
    background: ${inputBackgroundColor};
    position: relative;
    a:focus {
        outline: none;
    }
    .dropdown-menu {
      margin-top: 8px;
      right: -9px;
      left: auto;
    }
    i.mdi-chevron-down {
      display: none;
    }

    div.mdi-chevron-down {
      display: inline-block!important;
      position: absolute;
      right: 10px;
      top: 5px;
      font-size: 20px;
      color: ${secondaryColorTwo}; 
    } 
`
