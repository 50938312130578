import {
    GET_TRANSACTIONS, GET_TRANSACTIONS_SUCCESS, GET_TRANSACTIONS_FAIL,
} from '../../actions/transactions'

const initialState = {
    items: [],
    isLoading: false,
    pagination: {
        page: 1,
    },
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    case GET_TRANSACTIONS:
        return {
            ...state,
            isLoading: true,
        }
    case GET_TRANSACTIONS_SUCCESS:
        return {
            ...state,
            items: action.response.result,
            pagination: action.response.pagination,
            isLoading: false,
        }
    case GET_TRANSACTIONS_FAIL:
        return {
            ...state,
            isLoading: false,
        }
    default:
        return state
    }
}
