import {
    PaymentElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js'
import { useCallback, useState } from 'react'
import Button from 'components/src/Button'

interface StripePaymentMethodSetupProps {
    returnUrl: string
    t: any
}

export function StripePaymentMethodSetup(props: StripePaymentMethodSetupProps) {
    const [ savingPaymentMethod, setSavingPaymentMethod ] = useState(false)
    const stripe = useStripe()
    const elements = useElements()

    const onSubmit = useCallback(async (event) => {
        event.preventDefault()

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return
        }

        await stripe.confirmSetup({
            elements,
            confirmParams: {
                // eslint-disable-next-line camelcase
                return_url: props.returnUrl,
            },
        })
    }, [ elements, props.returnUrl, stripe ])

    return (
        <form onSubmit={onSubmit}>
            <PaymentElement/>
            <Button
                type="submit"
                variant="primary"
                disabled={savingPaymentMethod}
            >
                {props.t('payment_page.add_payment_button')}
            </Button>
        </form>
    )
}
