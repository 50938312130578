import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'
import { fetchJSON } from '../fetch'
import config from '../config'
import { handleError } from '../actions/errors'

export const GET_LEAD_TYPES = 'segmentation/GET_LEAD_TYPES'
export const GET_LEAD_TYPES_SUCCESS = 'segmentation/GET_LEAD_TYPES_SUCCESS'
export const GET_LEAD_TYPES_FAIL = 'segmentation/GET_LEAD_TYPES_FAIL'

export const GET_PARTNER_LEAD_TYPES = 'partner/GET_LEAD_TYPES'
export const GET_PARTNER_LEAD_TYPES_SUCCESS = 'partner/GET_LEAD_TYPES_SUCCESS'
export const GET_PARTNER_LEAD_TYPES_FAIL = 'partner/GET_LEAD_TYPES_FAIL'

export const GET_INDUSTRIES = 'industries/GET_INDUSTRIES'
export const GET_INDUSTRIES_SUCCESS = 'industries/GET_INDUSTRIES_SUCCESS'
export const GET_INDUSTRIES_FAIL = 'industries/GET_INDUSTRIES_FAIL'

export const GET_PARTNER_INDUSTRIES = 'partner/GET_INDUSTRIES'
export const GET_PARTNER_INDUSTRIES_SUCCESS = 'partner/GET_INDUSTRIES_SUCCESS'
export const GET_PARTNER_INDUSTRIES_FAIL = 'partner/GET_INDUSTRIES_FAIL'

export const ADD_INDUSTRY_TO_PARTNER = 'partners/ADD_INDUSTRY_TO_PARTNER'
export const ADD_INDUSTRY_TO_PARTNER_SUCCESS = 'partners/ADD_INDUSTRY_TO_PARTNER_SUCCESS'
export const ADD_INDUSTRY_TO_PARTNER_FAIL = 'partners/ADD_INDUSTRY_TO_PARTNER_FAIL'

export const REMOVE_INDUSTRY_FROM_PARTNER = 'partners/REMOVE_INDUSTRY_FROM_PARTNER'
export const REMOVE_INDUSTRY_FROM_PARTNER_SUCCESS = 'partners/REMOVE_INDUSTRY_FROM_PARTNER_SUCCESS'
export const REMOVE_INDUSTRY_FROM_PARTNER_FAIL = 'partners/REMOVE_INDUSTRY_FROM_PARTNER_FAIL'

export const ADD_LEAD_TYPE_TO_PARTNER = 'partners/ADD_LEAD_TYPE_TO_PARTNER'
export const ADD_LEAD_TYPE_TO_PARTNER_SUCCESS = 'partners/ADD_LEAD_TYPE_TO_PARTNER_SUCCESS'
export const ADD_LEAD_TYPE_TO_PARTNER_FAIL = 'partners/ADD_LEAD_TYPE_TO_PARTNER_FAIL'

export const REMOVE_LEAD_TYPE_FROM_PARTNER = 'partners/REMOVE_LEAD_TYPE_FROM_PARTNER'
export const REMOVE_LEAD_TYPE_FROM_PARTNER_SUCCESS = 'partners/REMOVE_LEAD_TYPE_FROM_PARTNER_SUCCESS'
export const REMOVE_LEAD_TYPE_FROM_PARTNER_FAIL = 'partners/REMOVE_LEAD_TYPE_FROM_PARTNER_FAIL'

export const addIndustryToPartner = (partnerId, industryId) => dispatch => dispatch({
    [CALL_API]: {
        types: [ ADD_INDUSTRY_TO_PARTNER, ADD_INDUSTRY_TO_PARTNER_SUCCESS, ADD_INDUSTRY_TO_PARTNER_FAIL ],
        endpoint: `/partners/${partnerId}/industries`,
        schema: schemas.industry,
        options: {
            method: 'post',
            body: {
                id: industryId,
            },
        },
    },
})

export const removeIndustryFromPartner = (partnerId, industryId) => dispatch => dispatch({
    [CALL_API]: {
        types: [ REMOVE_INDUSTRY_FROM_PARTNER, REMOVE_INDUSTRY_FROM_PARTNER_SUCCESS, REMOVE_INDUSTRY_FROM_PARTNER_FAIL ],
        endpoint: `/partners/${partnerId}/industries/${industryId}`,
        options: {
            method: 'delete',
        },
    },
    industryId: industryId,
})

// export function addLeadTypeToPartner(partnerId, leadTypeId) {
//     return (dispatch) => {
//         dispatch({ type: ADD_LEAD_TYPE_TO_PARTNER })
//
//         fetchJSON(`${config.serviceUrl}/partners/${partnerId}/types`, {
//             method: 'post',
//             body: {
//                 id: leadTypeId
//             }
//         })
//             .then(response => {
//                 if (response) {
//                     return dispatch({ type: ADD_LEAD_TYPE_TO_PARTNER_SUCCESS, leadTypeId: leadTypeId })
//                 }
//
//                 dispatch({ type: ADD_LEAD_TYPE_TO_PARTNER_FAIL })})
//             .catch(e => handleError(dispatch, e))
//     }
// }

export const addLeadTypeToPartner = (partnerId, leadTypeId) => dispatch => dispatch({
    [CALL_API]: {
        types: [ ADD_LEAD_TYPE_TO_PARTNER, ADD_LEAD_TYPE_TO_PARTNER_SUCCESS, ADD_LEAD_TYPE_TO_PARTNER_FAIL ],
        endpoint: `/partners/${partnerId}/lead_types`,
        schema: schemas.leadType,
        options: {
            method: 'post',
            body: {
                id: leadTypeId,
            },
        },
    },
})

// export function removeLeadTypeFromPartner(partnerId, leadTypeId) {
//     return (dispatch) => {
//         dispatch({ type: REMOVE_LEAD_TYPE_FROM_PARTNER })
//
//         fetch(`${config.serviceUrl}/partners/${partnerId}/types/${leadTypeId}`, {
//             method: 'delete'
//         })
//             .then(response => {
//                 if (response) {
//                     return dispatch({ type: REMOVE_LEAD_TYPE_FROM_PARTNER_SUCCESS, leadTypeId: leadTypeId })
//                 }
//
//                 dispatch({ type: REMOVE_LEAD_TYPE_FROM_PARTNER_FAIL })})
//             .catch(e => handleError(dispatch, e))
//     }
// }

export const removeLeadTypeFromPartner = (partnerId, leadTypeId) => dispatch => dispatch({
    [CALL_API]: {
        types: [ REMOVE_LEAD_TYPE_FROM_PARTNER, REMOVE_LEAD_TYPE_FROM_PARTNER_SUCCESS, REMOVE_LEAD_TYPE_FROM_PARTNER_FAIL ],
        endpoint: `/partners/${partnerId}/lead_types/${leadTypeId}`,
        options: {
            method: 'delete',
        },
    },
    leadTypeId: leadTypeId,
})

export const getLeadTypes = geoCode => dispatch => dispatch({
    [CALL_API]: {
        types: [ GET_LEAD_TYPES, GET_LEAD_TYPES_SUCCESS, GET_LEAD_TYPES_FAIL ],
        endpoint: '/leads/types',
        schema: schemas.leadTypes,
        options: {
            body: {
                geo_code: geoCode,
            },
        },
    },
})

export const getPartnerLeadTypes = partner => dispatch => dispatch({
    [CALL_API]: {
        types: [ GET_PARTNER_LEAD_TYPES, GET_PARTNER_LEAD_TYPES_SUCCESS, GET_PARTNER_LEAD_TYPES_FAIL ],
        endpoint: `/partners/${partner.id}/lead_types`,
        schema: schemas.leadTypes,
        options: {
            body: { limit: 100 },
        },
    },
})

export const getPartnerIndustries = partner => dispatch => dispatch({
    [CALL_API]: {
        types: [ GET_PARTNER_INDUSTRIES, GET_PARTNER_INDUSTRIES_SUCCESS, GET_PARTNER_INDUSTRIES_FAIL ],
        endpoint: `/partners/${partner.id}/industries`,
        schema: schemas.industries,
        options: {
            body: {
                limit: 100,
            },
        },
    },
})

export const getIndustries = criteria => dispatch => dispatch({
    [CALL_API]: {
        types: [ GET_INDUSTRIES, GET_INDUSTRIES_SUCCESS, GET_INDUSTRIES_FAIL ],
        endpoint: '/industries',
        schema: schemas.industries,
        options: {
            body: {
                ...criteria,
                limit: 100,
            },
        },
    },
})
