import {
    GET_STRIPE_API_KEY_SUCCESS,
    SAVE_STRIPE_CARD,
} from '../actions/stripe'
const initialState = {}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    case SAVE_STRIPE_CARD:
        return {
            ...state,
            ... action.stripeCard,
        }
    case GET_STRIPE_API_KEY_SUCCESS:
        return {
            ...state,
            ... action.response,
        }
    default:
        return state
    }
}
