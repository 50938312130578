import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { currentClientSelector } from '../../../selectors/auth'
import PageTitle from '../../../components/utils/PageTitle'
import { addMessageToConversation } from '../../../actions/projects'
import {
    readConversationMessages,
    getConversations,
    getConversation,
    getUnreadParticipantMessages,
} from '../../../actions/conversations'
import { ConversationMessenger } from '../../layout/ConversationMessenger'

@connect(
    state => ({
        client: currentClientSelector(state),
        conversations: state.pages.projectDetail.conversationIds
            .map(id => state.entities.projectConversations[id]),
        conversationId: state.router.location.hash.slice(1),
        messages: state.entities.conversationMessages,
        pagination: state.pages.projectDetail.pagination,
        unreadMessagesIds: state.pages.conversationsMessages.unreadMessagesIds,
        isLoadingConversations: state.pages.projectDetail.isLoadingConversations,
        selectedConversation: state.entities.projectConversations[state.router.location.hash.slice(1)],
    }),
    {
        getConversation,
        getUnreadParticipantMessages,
        addMessageToConversation,
        readConversationMessages,
        getConversations,
    }
)

@withTranslation('', { wait: true })
export default class ClientMessagesPage extends React.Component {
    componentDidMount() {
        this.loadMore(1)
    }

    handleNoteSubmit(data, reset) {
        const {
            addMessageToConversation,
            getConversation,
            selectedConversation,
        } = this.props
        const files = data.file ? [ data.file ] : undefined
        addMessageToConversation(selectedConversation.id, data.content, files)
            .then(() => {
                reset()
                getConversation(selectedConversation.id)
            })
    }

    loadMore(page) {
        this.props.getConversations({
            clientId: this.props.client.id,
            sort: 'latest_activity',
            page,
        }, true)
    }

    readConversationMessages(conversationId) {
        this.props.readConversationMessages(conversationId)
            .then(res => {
                this.props.getUnreadParticipantMessages({
                    unreadByParticipantId: this.props.client.id,
                })
            })
    }

    render() {
        const {
            client,
            unreadMessagesIds,
            selectedConversation,
            isLoadingConversations,
            conversations,
            messages,
            pagination: {
                pages,
                page,
            },
            t,
        } = this.props
        let hasMore = isLoadingConversations ? false : page < pages

        return (
            <div className="client-message-page container-fluid">
                <PageTitle identifier="pages.titles.messages" />
                <ConversationMessenger
                    handleNoteSubmit={this.handleNoteSubmit.bind(this)}
                    markAsReadConversation={this.readConversationMessages.bind(this)}
                    selectedConversation={selectedConversation}
                    unreadMessagesIds={unreadMessagesIds}
                    isLoadingConversations={isLoadingConversations}
                    conversations={conversations}
                    messages={messages}
                    sender={{ id: client.id, type: 'client' }}
                    conversationNaming="partner"
                    loadMore={this.loadMore.bind(this)}
                    hasMore={hasMore}
                    t={t}
                />
            </div>
        )
    }
}
