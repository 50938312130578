import styled from 'styled-components'
import { breakpoints, colors } from '../../theme/variables_new'
import { applyStyleModifiers, applyResponsiveStyleModifiers } from 'styled-components-modifiers'
import { MODIFIER_MARGIN_CONFIG, MODIFIER_PADDING_CONFIG } from '../../components/elements/modifiers/spaceModifiers'

const MODIFIER_CONTAINER_CONFIG = {
    widthFull: () => `
        max-width: 100%;
    `,
    widthExtraLg: () => `
        max-width: 1600px;
    `,
    widthLg: () => `
        max-width: 1170px;
    `,
    widthMd: () => `
        max-width: 942px;
    `,
    widthSm: () => `
        max-width: 830px;
    `,
}

export const Container = styled.div`
    position: relative;
    max-width: 900px;
    padding: 0 28px;
    margin: auto;


    @media (min-width: ${breakpoints.large}) {
        padding: 0 24px;
    }

    ${applyStyleModifiers(MODIFIER_CONTAINER_CONFIG)};
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)};
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)};
    ${applyResponsiveStyleModifiers(MODIFIER_CONTAINER_CONFIG)};
    ${applyResponsiveStyleModifiers(MODIFIER_MARGIN_CONFIG)};
    ${applyResponsiveStyleModifiers(MODIFIER_PADDING_CONFIG)};
`

export const Row = styled.div`
    display: grid;
    grid-column-gap: 12px;
    grid-template-columns: repeat(4, 1fr);

    @media (min-width: ${breakpoints.small}) {
        grid-column-gap: 16px;
        grid-template-columns: repeat(8, 1fr);
    }

    ${({ offers }) => offers ? `
    @media (min-width: ${breakpoints.small}) and (max-width: ${breakpoints.desktopMedium}) {
        grid-template-columns: repeat(6, 1fr);
    }
    ` : ''}

    @media (min-width: ${breakpoints.medium}) {
        grid-template-columns: repeat(12, 1fr);
    }
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)};
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)};
    ${applyResponsiveStyleModifiers(MODIFIER_MARGIN_CONFIG)};
    ${applyResponsiveStyleModifiers(MODIFIER_PADDING_CONFIG)};
`

export const Col = styled.div`
    ${({ startSm }) => startSm ? `grid-column-start: ${startSm};` : ''}
    ${({ spanSm }) => spanSm ? `grid-column-end: span ${spanSm};` : 'grid-column-end: span 4;'}
    ${({ centerSm }) => centerSm && 'align-self: center;'}

    @media (min-width: ${breakpoints.small}) {
        ${({ startMd }) => startMd ? `grid-column-start: ${startMd};` : ''}
        ${({ spanMd }) => spanMd ? `grid-column-end: span ${spanMd};` : 'grid-column-end: span 8;'}
        ${({ centerMd }) => centerMd && 'align-self: center;'}
    }

    @media (min-width: ${breakpoints.medium}) {
        ${({ startLg }) => startLg ? `grid-column-start: ${startLg};` : ''}
        ${({ spanLg }) => spanLg ? `grid-column-end: span ${spanLg};` : 'grid-column-end: span 12;'}
        ${({ centerLg }) => centerLg && 'align-self: center;'}
    }
`

const MODIFIER_FIXED_CONFIG = {
    bottom: () => `
        bottom: 0;
    `,
    top: () => `
        top: 0;
    `,
    static: () => `
        position: static;
    `,
    noBackground: () => `
        background-color: transparent;
    `,
    greyBackground: () => `
        background-color: ${colors.backgroundGrey};
    `,
    whiteBackground: () => `
        background-color: ${colors.white};
    `,
}

export const Section = styled.section`
    position: relative;
    ${applyStyleModifiers(MODIFIER_FIXED_CONFIG)};
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)};
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)};
    ${applyResponsiveStyleModifiers(MODIFIER_FIXED_CONFIG)};
    ${applyResponsiveStyleModifiers(MODIFIER_MARGIN_CONFIG)};
    ${applyResponsiveStyleModifiers(MODIFIER_PADDING_CONFIG)};
`

export const Fixed = styled.section`
    position: fixed;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: ${colors.white};
    ${applyStyleModifiers(MODIFIER_FIXED_CONFIG)};
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)};
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)};
    ${applyResponsiveStyleModifiers(MODIFIER_FIXED_CONFIG)};
    ${applyResponsiveStyleModifiers(MODIFIER_MARGIN_CONFIG)};
    ${applyResponsiveStyleModifiers(MODIFIER_PADDING_CONFIG)};
`
