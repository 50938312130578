import React from 'react'

export default class ExpandButton extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            expanded: this.props.expanded || false,
        }
    }

    toggleExpand() {
        if (this.props.disabled) return
        this.setState({ expanded: !this.state.expanded })
    }

    render() {
        const { content, header, onClose, disabled } = this.props

        const className = this.props.className + ' expand-button'
        const expandedClassName = (this.props.expandedClassName ? this.props.expandedClassName : this.props.className) + ' expand-button expanded'

        if (this.state.expanded) {
            return <div className={expandedClassName}>
                <div className="header">
                    <a className="icon" onClick={() => {
                        this.toggleExpand()
                        if(onClose) {
                            onClose()
                        }
                    }}>&times;</a>
                    {header}
                </div>
                <div className="content">
                    {content}
                </div>
            </div>
        }

        return (
            <a className={className} onClick={this.toggleExpand.bind(this)} disabled={disabled}>
                <span className="icon mdi mdi-plus" />
                {header}
            </a>
        )
    }
}
