import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { replace } from 'connected-react-router'
import LandingPage from '../blocks/LandingPage'
import { Card } from '../blocks/Card'
import { Separator } from '../cards'
import { currentPartnerSelector } from '../../selectors/auth'
import { getPartner } from '../../actions/partners'
import { getPartnerContractOfferGroups } from '../../actions/partnercontractoffergroups'
import LoadingAnimation from '../../components/utils/LoadingAnimation'
import config from '../../config'
import FormattedAmount from '../../components/utils/FormattedAmount'
import Nl2br from '../../components/utils/Nl2br'
import { Text, Span, Headline, Impact, BlockLabel } from '../elements/text/Text'
import Box from '../blocks/Box'
import { Container, Col, Row, Fixed } from '../layout/layout'
import { bkpInt, breakpoints, colors } from '../../theme/variables_new'
import List from '../elements/List'
import moment from 'moment'
import { Icon, Image } from '../layout/images'
import { applyResponsiveStyleModifiers } from 'styled-components-modifiers'
import { MODIFIER_MARGIN_CONFIG, MODIFIER_PADDING_CONFIG } from '../elements/modifiers/spaceModifiers'
import WindowSizeListener from 'react-window-size-listener'
import { Control } from './DemoPage'
import { Button } from '../elements/ButtonNew'

// define page
const Page = styled.div`
    width: 100%;
    color: ${colors.darkBlue}
    ${applyResponsiveStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_PADDING_CONFIG)}
`

export const OFFER_BOX_MODIFIERS = {
    impact: () => `
        position: relative;
        overflow: hidden;
        padding-top: 64px;
        border: 2px solid ${colors.green};
        box-shadow: 0px 9.29px 40px rgba(41, 211, 128, 0.1);
    `,
    base: () => `
        @media (min-width: ${breakpoints.small}) {
            margin-top: 24px;
        }
    `,
}

const Banner = styled.div`
    position: absolute;
    width: 240px;
    top: 25px;
    left: -69px;
    padding: 8px 12px;
    font-weight: bold;
    transform: rotate(-40deg);
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
    color: ${colors.white};
    background-color: ${colors.green}
`

const Offer = styled(Box)`
    text-align: center;
    padding: 40px 16px 60px;
    @media (max-width: ${breakpoints.small}) {
        margin-bottom: 12px;
    }
    ${applyResponsiveStyleModifiers(OFFER_BOX_MODIFIERS)};
`

export const OfferBox = ({
    type,
    offer,
    currency,
    t,
    size,
}) => {
    const totalLeads = offer.offerItems.reduce((acc, cur) => {
        return { leadQuota: acc.leadQuota + cur.leadQuota }
    })

    return <Offer responsiveModifiers={{
        basic: [ 'impact' ],
        lite: [ 'base' ],
        premium: [ 'base' ],
    }} size={type}>
        {type === 'basic' && <Banner>{t('partner_contract_offers.offer.recommended')}</Banner>}
        <Headline modifiers={[ 'mB_3' ]} responsiveModifiers={{
            small: [ 'small' ],
            medium: [ 'tiny' ],
            desktopSmall: [ 'medium' ],
            desktopMedium: [ 'medium' ],
            desktopLarge: [ 'medium' ],
        }} size={size}>
            <Span responsiveModifiers={{
                basic: [ 'green' ],
                premium: [ 'blue' ],
            }} size={type}>
                {t(`partner_contract_offers.offer.type.${type}`)}
            </Span>
        </Headline>
        <Text modifiers={[ 'regular', 'mB_2', 'lightGrey' ]}>{t('partner_contract_offers.offer.package_specs')}</Text>
        <Headline responsiveModifiers={{
            small: [ 'medium' ],
            medium: [ 'medium' ],
            desktopSmall: [ 'large' ],
            desktopMedium: [ 'large' ],
            desktopLarge: [ 'large' ],
        }} size={size}>
            <Span responsiveModifiers={{
                basic: [ 'green' ],
                premium: [ 'blue' ],
            }} size={type}>
                <FormattedAmount
                    amount={offer.priceExclVat.amount}
                    currency={offer.priceExclVat.currency}
                    roundTo="0"
                />
            </Span>
        </Headline>
        <Headline modifiers={[ 'mB_3' ]} responsiveModifiers={{
            small: [ 'medium' ],
            medium: [ 'medium' ],
            desktopSmall: [ 'large' ],
            desktopMedium: [ 'large' ],
            desktopLarge: [ 'large' ],
        }} size={size}>
            <Span responsiveModifiers={{
                basic: [ 'green' ],
                premium: [ 'blue' ],
            }} size={type}>
                {t('partner_contract_offers.offer.units', {count: totalLeads.leadQuota})}
            </Span>
        </Headline>
        <Text modifiers={[ 'regular', 'mB_2', 'lightGrey' ]}>{t('partner_contract_offers.offer.leads_per_month')}</Text>
        <div style={{ minHeight: '140px' }}>
            {offer.offerItems.map(item => <Impact key={'lead_item_' + item.id} responsiveModifiers={{
                basic: [ 'green' ],
                premium: [ 'blue' ],
            }} size={type} modifiers={[ 'medium' ]}>{item.leadQuota + ' ' + item.revenueSegment.displayName}</Impact>)}
        </div>
    </Offer>
}

@connect(
    state => ({
        partner: currentPartnerSelector(state),
        partnerContracts: state.entities.partnerContracts,
        partnerContractOfferGroups: state.entities.partnerContractOfferGroups,
        isLoading: state.pages.partnerContractOfferGroups.isLoading,
    }),
    {
        getPartner,
        getPartnerContractOfferGroups,
        replace,
    }
)

@withTranslation('', { wait: true })
export default class ContractOfferPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            width: 0,
        }
    }

    componentDidMount() {
        this.props.getPartnerContractOfferGroups(this.props.partner.id, {})
    }

    updateWindowWidth = width => this.setState({ width: width })

    continue = () => {
        this.props.replace({ pathname: '/partner-contract' })
    }

    returnWindowWidthString = width => {
        if (width <= bkpInt.small) return 'small'
        if (width <= bkpInt.medium) return 'medium'
        if (width <= bkpInt.large) return 'desktopSmall'
        if (width <= bkpInt.extraLarge) return 'desktopMedium'
        return 'desktopLarge'
    }

    render() {
        const { isLoading, partnerContractOfferGroups, partner, partnerContracts, t } = this.props

        if (isLoading) {
            return <LoadingAnimation />
        }

        const offerGroup = Object.values(partnerContractOfferGroups)[0]
        if (!offerGroup) {
            return <LoadingAnimation />
        }

        const partnerHasContracts = Object.values(partnerContracts).length > 0

        const liteOffer = offerGroup.offers.find(offer => offer.sort === 1)
        const preferredOffer = offerGroup.offers.find(offer => offer.sort === 2)
        const premiumOffer = offerGroup.offers.find(offer => offer.sort === 3)

        return <Page responsiveModifiers={{
            small: [ 'pB_3', 'mB_3' ],
            medium: [ 'pB_6', 'mB_6' ],
            desktopSmall: [ 'pB_6', 'mB_6' ],
            desktopMedium: [ 'pB_6', 'mB_6' ],
            desktopLarge: [ 'pB_6', 'mB_6' ],
        }} size={this.returnWindowWidthString(this.state.width)} className="new">
            <Container modifiers={[ 'widthLg' ]}>
                <Row>
                    <Col>
                        <Headline responsiveModifiers={{
                            small: [ 'spacingSmallest', 'small' ],
                            medium: [ 'spacingSmallest', 'small' ],
                            desktopSmall: [ 'spacingSmallest', 'medium' ],
                            desktopMedium: [ 'spacingMedium', 'medium' ],
                            desktopLarge: [ 'spacingMedium', 'mediumLarge' ],
                        }} size={this.returnWindowWidthString(this.state.width)} modifiers={[ 'center' ]}>{t('partner_contract_offers.title')}</Headline>
                    </Col>
                </Row>
                <Row offers>
                    <Col spanMd={2} spanLg={4}>
                        <OfferBox currency={config.currency[partner.geo.code]} offer={liteOffer} type="lite" t={t} size={this.returnWindowWidthString(this.state.width)}/>
                    </Col>
                    <Col spanMd={2} spanLg={4}>
                        <OfferBox currency={config.currency[partner.geo.code]} offer={preferredOffer} type="basic" t={t} size={this.returnWindowWidthString(this.state.width)}/>
                    </Col>
                    <Col spanMd={2} spanLg={4}>
                        <OfferBox currency={config.currency[partner.geo.code]} offer={premiumOffer} type="premium" t={t} size={this.returnWindowWidthString(this.state.width)}/>
                    </Col>
                </Row>
            </Container>
            {this.state.width > bkpInt.medium && partnerHasContracts && <Control right>
                <Button onClick={this.continue} modifiers={[ 'primary', 'large', 'bottomLeft', 'bold' ]}>{t('demo_flow.control.continue')}</Button>
            </Control>}
            <WindowSizeListener onResize={size => this.updateWindowWidth(size.windowWidth)} />
        </Page>
    }
}
