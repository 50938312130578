import { createSelector } from 'reselect'

export const permissionsSelector = createSelector(
    state => state,
    state => state.auth.permissions
)

export const hasMeetingBookingAccessSelector = createSelector(
    permissionsSelector,
    permissions => permissions.includes('meeting_booking_buy')
)

export const hasCVAccessSelector = createSelector(
    permissionsSelector,
    permissions => permissions.includes('partner_user_cv_create')
)

export const hasWidgetsAccessSelector = createSelector(
    permissionsSelector,
    permissions => permissions.includes('widget_show')
)

export const hasFeatureRatingAccessSelector = createSelector(
    permissionsSelector,
    permissions => permissions.includes('rating_feature')
)

export const hasReplyRatingAccessSelector = createSelector(
    permissionsSelector,
    permissions => permissions.includes('rating_reply_create')
)

export const hasFullRatingDetailAccessSelector = createSelector(
    permissionsSelector,
    permissions => permissions.includes('rating_show_full_details')
)

export const hasSubscriptionFeaturesAccessSelector = createSelector(
    permissionsSelector,
    permissions => permissions.includes('see_subscription_features')
)

export const hasRefundAccessSelector = createSelector(
    permissionsSelector,
    permissions => permissions.includes('create_refund_request')
)

export const hasLeadAccessSelector = createSelector(
    permissionsSelector,
    permissions => (
        permissions.includes('leads_list_same_geo') ||
        permissions.includes('leads_list')
    )
)

export const hasCreateLeadOfferAccessSelector = createSelector(
    permissionsSelector,
    permissions => permissions.includes('leads_offers_create')
)

export const hasListPartnerCouponsAccessSelector = createSelector(
    permissionsSelector,
    permissions => (
        permissions.includes('partners_coupons_list') ||
        permissions.includes('partners_coupons_list_own')
    )
)

export const hasListPartnerActionFeesAccessSelector = createSelector(
    permissionsSelector,
    permissions => (
        permissions.includes('partner_action_fees_list') ||
        permissions.includes('partner_action_fees_list_own')
    )
)

export const hasQuoteCreationAccessAccessSelector = createSelector(
    permissionsSelector,
    permissions => permissions.includes('leads_quotes_create')
)

export const hasMenuAccessSelector = createSelector(
    state => state,
    state => {
        return !state.router.location.pathname.startsWith('/onboarding/')
    }
)

export const hasCommunicationAccessSelector = createSelector(
    permissionsSelector,
    permissions => permissions.includes('communication_access')
)

export const hasQuotePaymentAccessSelector = createSelector(
    permissionsSelector,
    permissions => (
        permissions.includes('clients_quote_payment_access')
    )
)

export const hasNotesCreateAccessSelector = createSelector(
    permissionsSelector,
    permissions => permissions.includes('partner_notes_create')
)

export const hasContractSigningAccessSelector = createSelector(
    permissionsSelector,
    permissions => permissions.includes('partner_contracts_sign')
)

export const hasMarketplaceSignUpAccessSelector = createSelector(
    permissionsSelector,
    permissions => permissions.includes('partner_subscribe_to_lead_marketplace')
)

export const hasMarketplaceLeadAccessSelector = createSelector(
    permissionsSelector,
    permissions => permissions.includes('partner_access_to_lead_marketplace')
)

export const hasMarketplaceBasicLeadAccessSelector = createSelector(
    permissionsSelector,
    permissions => permissions.includes('partner_access_to_marketplace_basic_leads')
)

export const hasMarketplacePlusLeadAccessSelector = createSelector(
    permissionsSelector,
    permissions => permissions.includes('partner_access_to_marketplace_plus_leads')
)

export const hasMarketplaceWithoutFeesAccessSelector = createSelector(
    permissionsSelector,
    permissions => permissions.includes('partner_access_to_marketplace_without_fees')
)
