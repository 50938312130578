import { createSelector } from 'reselect'
import { currentClientSelector } from './auth'
import moment from 'moment/moment'

const conversationsSelector = state => state.entities.projectConversations

const conversationIds = state => state.pages.projectDetail.conversationIds

export const conversationByParticipantsSelector = createSelector(
    conversationsSelector,
    conversations => participants => (
        Object.values(conversations).find(c => (
            participants.every(p => (
                c.participants.some(cp => {
                    return p.type === cp.type &&
                        parseInt(p.id) === cp[`${cp.type}Id`]
                })
            ))
        ))
    )
)

export const conversationSelector = (state, project = { lead: {}, partner: {} }) => conversationByParticipantsSelector(state)([
    { type: 'lead', id: project.lead && project.lead.id },
    { type: 'partner', id: project.partner && project.partner.id },
])

export const clientConversationSelector = (state, leadId, partnerId) => conversationByParticipantsSelector(state)([
    { type: 'lead', id: leadId },
    { type: 'partner', id: partnerId },
    { type: 'client', id: currentClientSelector(state).id },
])

export const clientConversationMessagesSelector = (state, leadId, partnerId) => createSelector(
    state => clientConversationSelector(state, leadId, partnerId),
    state => state.entities.conversationMessages,
    (conversation, messages) => (conversation || { messages: [] }).messages
        .map(id => messages[id])
        .sort((cur, next) => moment(cur.updatedAt) - moment(next.updatedAt))
)(state)

export const existNewMessageSelector = (state, leadId, partnerId) => createSelector(
    state => clientConversationSelector(state, leadId, partnerId),
    state => state.pages.conversationsMessages.unreadMessagesIds,
    state => state.entities.conversationMessages,
    (clientConversation, unreadMessagesIds, messages) => unreadMessagesIds.map(id => messages[id])
        .find(message => clientConversation && message.conversationId === clientConversation.id)
)(state)

export const partnerConversationSelector = createSelector(
    conversationIds, conversationsSelector,
    (conversationIds, projectConversations) => conversationIds.map(id => projectConversations[id])
)
