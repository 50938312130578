import React from 'react'
import UnpaidInvoiceItem from './UnpaidInvoiceItem'
import FormattedAmount from '../../utils/FormattedAmount'
import { withTranslation } from 'react-i18next'

@withTranslation('', { wait: true })
export default class UnpaidInvoiceList extends React.Component {
    renderSumUpLines() {
        var totalVat = this.props.invoices.reduce(function(vatSum, invoice) {
            if([ 'unpaid', 'overdue' ].includes(invoice.status)) {
                return vatSum + invoice.amounts.vat.amount
            }
            return vatSum
        }, 0)

        var totalInclVat = this.props.invoices.reduce(function(totalSum, invoice) {
            if([ 'unpaid', 'overdue' ].includes(invoice.status)) {
                return totalSum + invoice.amounts.totalIncludingVat.amount
            }
            return totalSum
        }, 0)

        return [
            <tr key={1}>
                <td colSpan="4"><p></p></td>
                <td>{ this.props.t('invoices.vat') }</td>
                <td>
                    <FormattedAmount amount={totalVat} currency={this.props.invoices[0].amounts.totalIncludingVat.currency} />
                </td>
            </tr>,
            <tr key={2}>
                <td colSpan="4"><p></p></td>
                <td>{ this.props.t('invoices.total_inc_vat') }</td>
                <td>
                    <FormattedAmount amount={totalInclVat} currency={this.props.invoices[0].amounts.totalIncludingVat.currency} />
                </td>
            </tr>,
        ]
    }

    render() {
        const {
            invoices, t,
        } = this.props

        return <table className="datatable responsive compact">
            <thead>
                <tr>
                    <th className="table-mobile-cell" />
                    <th/>
                    <th>{ t('invoices.number') }</th>
                    <th>{ t('invoices.date') }</th>
                    <th>{ t('invoices.due_date') }</th>
                    <th>{ t('invoices.invoice_line') }</th>
                    <th>{ t('invoices.amount') }</th>
                </tr>
            </thead>
            <tbody>
                {invoices.map(invoice => {
                    if([ 'unpaid', 'overdue' ].includes(invoice.status)) {
                        return <UnpaidInvoiceItem key={invoice.id} invoice={invoice} t={t} />
                    }
                }
                )}
                {!invoices.length && <tr>
                    <td colSpan="10">
                        { t('invoices.no_invoices') }
                    </td>
                </tr>}
                {invoices.length && this.renderSumUpLines()}
            </tbody>
        </table>
    }
}
