import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import ExpertiseForm from '../cv/ExpertiseForm'
import ExpandButton from '../utils/ExpandButton'
import { Button } from '../elements/Button'
import { Flex } from '../elements/Flex'

@withTranslation('', { wait: true })
export default class ExpertiseItem extends Component {

    constructor(props) {
        super(props)

        this.state = {
            editing: false,
        }
    }

    clickEdit = () => {
        this.setState({
            editing: true,
        })
    }

    onCloseEdit = () => {
        this.setState({
            editing: false,
        })
    }

    onSubmitUpdate = data => {
        this.setState({
            editing: false,
        })
        data.id = this.props.exp.id
        this.props.onSubmitEdit(data)
    }

    deleteExpertise = () => {
        this.setState({
            editing: false,
        })

        this.props.onDeleteExpertise(this.props.exp.id)
    }

    render() {
        const {
            exp, t,
        } = this.props

        const { editing } = this.state

        return (
            <div className="item">
                <Flex modifiers={[ 'justifySpaceBetween', 'alignCenter' ]}>
                    <p>{exp.expertiseName}</p>
                    {!editing && <Button
                        modifiers={[ 'secondary' ]} onClick={this.clickEdit}>{t('cv.expertise.edit')}</Button>
                    }
                </Flex>
                {editing && <ExpandButton
                    expanded={true}
                    onClose={this.onCloseEdit}
                    className="success"
                    content={
                        <div>
                            <ExpertiseForm handleSubmit={this.onSubmitUpdate} initialValues={exp}/>
                            <button style={{ marginTop: 20 }} className="btn btn-lg btn-danger btn-block" onClick={this.deleteExpertise}>{t('cv.expertise.delete')}</button>
                        </div>
                    }
                    header={t('cv.experience.edit')}
                />
                }
            </div>
        )
    }
}

