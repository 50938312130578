import React, { Component } from 'react'
import { reduxForm } from 'redux-form'

@reduxForm()
export default class ReduxForm extends Component {
    render() {
        const { handleSubmit, children} = this.props

        return (
            <form onSubmit={handleSubmit}>
                {children}
            </form>
        )
    }
}
