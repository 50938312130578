import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const GET_PARTNER_CONTRACT_OFFER_GROUPS = 'partner/GET_PARTNER_CONTRACTS'
export const GET_PARTNER_CONTRACT_OFFER_GROUPS_SUCCESS = 'partner/GET_PARTNER_CONTRACTS_SUCCESS'
export const GET_PARTNER_CONTRACT_OFFER_GROUPS_FAIL = 'partner/GET_PARTNER_CONTRACTS_FAIL'

export const getPartnerContractOfferGroups = (partnerId, criteria, append = false) => ({
    [CALL_API]: {
        types: [ GET_PARTNER_CONTRACT_OFFER_GROUPS, GET_PARTNER_CONTRACT_OFFER_GROUPS_SUCCESS, GET_PARTNER_CONTRACT_OFFER_GROUPS_FAIL ],
        endpoint: `/partners/${partnerId}/contractoffergroups`,
        schema: schemas.partnerContractOfferGroups,
        options: {
            body: criteria,
        },
    },
    append,
})
