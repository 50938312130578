import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import creditCardType, { getTypeInfo, types as CardType } from 'credit-card-type'
import * as billingActions from '../../actions/billing'
import * as subscriptionActions from '../../actions/subscriptions'
import { queryParametersSelector } from '../../selectors/routing'
import LoadingAnimation from '../utils/LoadingAnimation'
import EpayPaymentWindow from '../subscription/EpayPaymentWindow'
import { currentPartnerSelector, currentPartnerUserSelector } from '../../selectors/auth'
import PageTitle from '../utils/PageTitle'
import CardCheckBox from '../subscription/CardCheckBox'

@connect(
    (state, props) => ({
        cards: Object.values(state.entities.paymentCards),
        isLoading: state.pages.billing.isLoading,
        paymentStarted: state.pages.billing.paymentStarted,
        partner: currentPartnerSelector(state),
        partnerUser: currentPartnerUserSelector(state),
        epayResponse: queryParametersSelector(state),
    }),
    dispatch => ({
        getCards: bindActionCreators(billingActions.getCards, dispatch),
        addCard: bindActionCreators(billingActions.addEpayCard, dispatch),
        startPayment: bindActionCreators(billingActions.startPayment, dispatch),
        redirectToBillingPage: bindActionCreators(billingActions.redirectToBillingPage, dispatch),
        updateSubscriptions: bindActionCreators(subscriptionActions.updatePartnerSubscriptions, dispatch),
    })
)
@withTranslation('', { wait: true })
export default class SubscriptionBillingPage extends React.Component {

    componentDidMount() {
        const { getCards, partner } = this.props
        getCards(partner)
        this.checkQueryAndAddCard()
    }

    handleCardActivationClick(cardId, checked) {
        this.props.updateSubscriptions(this.props.partner.id, cardId)
            .then(action => {
                this.props.getCards(this.props.partner)
            })
    }

    checkQueryAndAddCard() {
        const {
            epayResponse,
            partnerUser,
            redirectToBillingPage,
        } = this.props

        if(!epayResponse.hash) {
            return
        }

        this.props.addCard(epayResponse, partnerUser)
            .then(() => redirectToBillingPage())
    }

    render() {
        const {
            t,
            cards,
            isLoading,
            paymentStarted,
            startPayment,
        } = this.props

        if (isLoading) {
            return <LoadingAnimation />
        }

        return (
            <div className="card billing-card">
                <PageTitle identifier="subscription.billing.header" />
                <h2>{t('subscription.billing.header')}</h2>
                <p className="intro-text">{t('subscription.billing.intro')}</p>

                {cards.length ?
                    <table className="datatable responsive">
                        <tbody>
                            {cards.map(card => {
                                const cardType = creditCardType(card.cardMask.replace(new RegExp('X', 'g'), '0'))[0]
                                return <tr>
                                    <td className="table-mobile-cell">
                                        <table className="definition-list">
                                            <tbody>
                                                <tr>
                                                    <td><span className={'paymentcard large ' + (cardType ? cardType.type : 'unknown')}/></td>
                                                    <td>
                                                        {cardType && cardType.niceType}<br/>
                                                        <span>{card.cardMask}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td><span className={'paymentcard large ' + (cardType ? cardType.type : 'unknown')}/>
                                    </td>
                                    <td>{cardType && cardType.niceType}</td>
                                    <td><span>{card.cardMask}</span></td>
                                    <td>
                                        <CardCheckBox
                                        onClick={this.handleCardActivationClick.bind(this)}
                                        value={card.id}
                                        disabled={card.isBeingUsed}
                                        checked={card.isBeingUsed}
                                        />
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table> :
                    <div>{t('subscription.no_cards')}</div>
                }
                <hr />
                { paymentStarted && <EpayPaymentWindow width={700} height={400} placeholderId="billing-page-epay-window" /> ||
                <button className="btn btn-action btn-block btn-lg" onClick={startPayment}>{t('subscription.add_card.button')}</button>
                }
            </div>
        )
    }
}
