import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Field, reduxForm } from 'redux-form'
import LoadingAnimation from '../utils/LoadingAnimation'
import Select from 'react-select'
import monthOptions from '../utils/monthOptions'
import { dangerColor } from '../../theme/variables'

const FIELD_AWARD = 'awardName'
const FIELD_ISSUER = 'issuer'
const FIELD_FROM_YEAR = 'fromDateYear'
const FIELD_FROM_MONTH = 'fromDateMonth'
const FIELD_DESCRIPTION = 'description'

@withTranslation('', { wait: true })
@reduxForm({
    form: 'educationCv',
    fields: [
        'awardName',
        'issuer',
        'fromDateYear',
        'fromDateMonth',
        'description',
    ],
})

export default class AwardForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                [FIELD_AWARD]: this.props.initialValues.awardName || '',
                [FIELD_ISSUER]: this.props.initialValues.issuer || '',
                [FIELD_FROM_MONTH]: this.props.initialValues.fromDateMonth || '01',
                [FIELD_FROM_YEAR]: this.props.initialValues.fromDateYear || '',
                [FIELD_DESCRIPTION]: this.props.initialValues.description || '01',
            },
            errors: [],
        }
    }

    handleInput = evt => {
        const value = evt.target.value
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [evt.target.name]: value,
            },
        }))
    }

    handleSelect = (evt, name) => {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [name]: evt,
            },
        }))
    }

    validateForm = event => {
        event.preventDefault()
        this.setState({
            errors: [],
        }, () => {
            const { data } = this.state
            const errors = []

            if (data[FIELD_AWARD] === '') {
                errors.push(FIELD_AWARD)
            }

            if (data[FIELD_ISSUER] === '') {
                errors.push(FIELD_ISSUER)
            }

            if (data[FIELD_FROM_YEAR] === '' || isNaN(data[FIELD_FROM_YEAR])) {
                errors.push(FIELD_FROM_YEAR)
            }

            this.setState({
                errors: [
                    ...this.state.errors,
                    ...errors,
                ],
            }, () => {
                if (this.state.errors.length === 0) this.props.handleSubmit(data)
            })
        })
    }

    render() {
        const {
            t,
            submitting,
        } = this.props

        const { awardName, issuer, fromDateMonth, fromDateYear, description } = this.state.data
        const { errors } = this.state

        if (submitting) {
            return <LoadingAnimation />
        }

        return (
            <form onSubmit={this.validateForm}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <Field
                                onChange={evt => this.handleInput(evt)}
                                className="form-control"
                                name="awardName"
                                component="input"
                                placeholder={t('cv.award.placeholders.awardName')}
                                {...awardName}
                                style={{ borderColor: errors.includes(FIELD_AWARD) ? dangerColor : '' }}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <Field
                                onChange={evt => this.handleInput(evt)}
                                className="form-control"
                                name="issuer"
                                component="input"
                                placeholder={t('cv.award.placeholders.awardIssuer')}
                                {...issuer}
                                style={{ borderColor: errors.includes(FIELD_ISSUER) ? dangerColor : '' }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <Field name="fromDateMonth" component={props => {
                                return <Select
                                    value={fromDateMonth}
                                    onChange={evt => this.handleSelect(evt, FIELD_FROM_MONTH)}
                                    options={monthOptions(t)}
                                    clearable={false}
                                    simpleValue
                                />
                            }} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <Field
                                onChange={evt => this.handleInput(evt)}
                                className="form-control"
                                name="fromDateYear"
                                component="input"
                                type="text"
                                placeholder={t('cv.award.placeholders.fromDate.fromYear')}
                                {...fromDateYear}
                                style={{ borderColor: errors.includes(FIELD_FROM_YEAR) ? dangerColor : '' }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <Field className="form-control"
                                   onChange={evt => this.handleInput(evt)}
                                   rows="5"
                                   component="textarea"
                                   placeholder={t('cv.award.placeholders.experienceDescription')}
                                   name="description"
                                   {...description}
                            />
                        </div>
                    </div>
                </div>
                <button className="btn btn-action btn-lg btn-block" type="submit">
                    {t('cv.award.save')}
                </button>
            </form>
        )
    }
}
