import React from 'react'
import { toastr } from 'react-redux-toastr'
import Box from '../../blocks/Box'
import { Flex } from '../../elements/Flex'
import { CenteredModal } from './styles'
import { Headline, Text } from '../../elements/text/Text'
import { Button } from '../../elements/ButtonNew'
import { connect } from 'react-redux'
import { hideModal } from '../../../actions/modal'
import { withTranslation } from 'react-i18next'
import { copyPartnerRevenueSegment } from '../../../actions/products'

@connect(
    null,
    {
        hideModal,
        copyPartnerRevenueSegment,
    }
)
@withTranslation('', { wait: true })
export default class ForceCopyRevenueSegmentModal extends React.Component {
    state = {
        disabled: false,
    }

    confirmCopy = async () => {
        const { modalProps, t } = this.props
        try {
            this.setState({ 'disabled': true })
            await this.props.copyPartnerRevenueSegment(
                modalProps.partnerId,
                modalProps.segmentId,
                modalProps.selectedSegmentsIds,
                modalProps.isCopyWithPrices,
                modalProps.isCopyType,
                true,
            )
            await modalProps.refreshView()
        } catch (err) {
            toastr.error(t('quotes.revenue_segments.errors.error_header'), t('quotes.revenue_segments.errors.copying'))
        } finally {
            this.props.hideModal()
        }
    }

    render() {
        const { disabled } = this.state
        const { t } = this.props
        return <CenteredModal
            show={true}
            onHide={this.props.hideModal}
            className="new"
        >
            <Box>
                <Box.Header modifiers={[ 'pX_2', 'pT_2', 'pB_1' ]}>
                    <Headline modifiers={[ 'tiny' ]}>{t('quotes.revenue_segments.confirm_modal.headline')}</Headline>
                </Box.Header>
                <Box.Line />
                <Box.Content modifiers={[ 'p_2' ]}>
                    <Text>
                        {t('quotes.revenue_segments.confirm_modal.confirm_text')}
                    </Text>
                </Box.Content>
                <Box.Line />
                <Flex modifiers={[ 'justifyEnd', 'p_2' ]}>
                    <Button
                        onClick={this.confirmCopy}
                        modifiers={[ 'action', 'bottomLeft', 'regular', 'bold', disabled ? 'disabled' : '' ]}
                    >
                        {t('quotes.revenue_segments.confirm_modal.confirm')}
                    </Button>
                </Flex>
            </Box>
        </CenteredModal>
    }
}
