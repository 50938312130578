import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Field, reduxForm } from 'redux-form'
import LoadingAnimation from '../utils/LoadingAnimation'
import { dangerColor } from '../../theme/variables'

const FIELD_EXPERTISE = 'expertiseName'

@withTranslation('', { wait: true })
@reduxForm({
    form: 'expertiseCv',
    fields: [ 'expertiseName' ],
})
export default class ExpertiseForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                [FIELD_EXPERTISE]: this.props.initialValues.expertiseName || '',
            },
            errors: [],
        }
    }

    handleInput = evt => {
        const value = evt.target.value
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [evt.target.name]: value,
            },
        }))
    }

    validateForm = event => {
        event.preventDefault()
        this.setState({
            errors: [],
        }, () => {
            const { data } = this.state
            const errors = []

            if (data[FIELD_EXPERTISE] === '') {
                errors.push(FIELD_EXPERTISE)
            }

            this.setState({
                errors: [
                    ...this.state.errors,
                    ...errors,
                ],
            }, () => {
                if (this.state.errors.length === 0) this.props.handleSubmit(data)
            })
        })
    }

    render() {
        const {
            t,
            submitting,
        } = this.props

        const { expertiseName } = this.state.data
        const { errors } = this.state

        if(submitting) {
            return <LoadingAnimation />
        }

        return (
            <form onSubmit={this.validateForm}>
                <div className="form-group">
                    <Field className="form-control"
                           rows="5"
                           component="textarea"
                           onChange={evt => this.handleInput(evt)}
                           placeholder={t('cv.expertise.placeholders.description')}
                           name="expertiseName"
                           {...expertiseName}
                           style={{ borderColor: errors.includes(FIELD_EXPERTISE) ? dangerColor : '' }}
                    />
                </div>
                <button className="btn btn-action btn-lg btn-block" type="submit">
                    {t('cv.expertise.save')}
                </button>
            </form>
        )
    }
}
