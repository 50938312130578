import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import {
    getProjects,
    getProjectStatuses,
} from '../../actions/projects'
import { currentPartnerSelector } from '../../selectors/auth'
import { queryParametersSelector } from '../../selectors/routing'
import LoadingAnimation from '../utils/LoadingAnimation'
import PageTitle from '../utils/PageTitle'
import { getQuotesProgresses, markQuoteAccepted } from '../../actions/quotes'
import { ProgressLabel, Checkbox, ContainerFluid } from '../cards/index'
import { Row } from 'react-bootstrap'
import { TriangleBadge } from '../blocks/triangleBadge/index'
import { Button } from '../elements/Button'
import { Separator } from '../cards'
import { projectsSelector, projectStatusesSelector } from '../../selectors/projects'
import { ProjectListTable } from './ProjectListTable'
import { Card } from '../blocks/Card'
import { Flex } from '../elements/Flex'
import OverflowText from '../elements/OverflowText'
import { toastr } from 'react-redux-toastr'
import ClientsAwaitingQuotesCard from '../projects/ClientsAwaitingQuotesCard'
import { getPartnerAssignees } from '../../actions/partnerassignees'

@connect(
    state => ({
        partner: currentPartnerSelector(state),
        query: queryParametersSelector(state),
        isLoading: state.pages.projects.isLoading,
        projects: projectsSelector(state),
        page: state.pages.projects.pagination.page,
        pages: state.pages.projects.pagination.pages,
        quoteProgresses: state.quoteProgresses,
        projectStatuses: projectStatusesSelector(state),
        partnerAssignees: state.entities.partnerAssignees,
    }),
    {
        getQuotesProgresses,
        getProjectStatuses,
        markQuoteAccepted,
        getPartnerAssignees,
        getProjects,
        replace,
    },
)
@withTranslation('', { wait: true })
export default class ProjectListPage extends React.PureComponent {
    state = {
        toggleFilters: false,
        assigneeIds: [],
    }

    async componentDidMount() {
        const res = await this.props.getPartnerAssignees(this.props.partner.id)
        await this.setState({ assigneeIds: res.result })
        this.props.getQuotesProgresses()
        this.props.getProjectStatuses()
        this.search(this.props)
    }

    componentWillReceiveProps(newProps) {
        const { query } = this.props
        if (query !== newProps.query) {
            clearTimeout(this.searchTimeout)
            this.searchTimeout = setTimeout(() => {
                this.search(newProps)
            }, 800)
        }
    }

    handleQueryChange(e) {
        this.replace({
            ...this.props.query,
            query: e.target.value,
        })
    }

    replace(query) {
        this.props.replace({
            search: '?' + Object.keys(query).map(key => `${key}=${query[key]}`).join('&'),
        })
    }

    search(props) {
        const criteria = this.prepareCriteria(props)
        props.getProjects(criteria)
    }

    loadNextPage() {
        const criteria = this.prepareCriteria(this.props)

        this.props.getProjects({
            ...criteria,
            page: this.props.page + 1,
        })
    }

    prepareCriteria(props) {
        const { query, partner } = props

        const criteria = {
            partnerId: partner.id,
            isArchived: '0',
            isLeadValidated: 1,
            ...query,
        }
        return criteria
    }

    handleFilterChange(filterName, e) {
        const criteria = {
            progress: this.props.query.progress,
            status: this.props.query.status,
            progressArray: this.props.query.progress ? this.props.query.progress.split(',') : [],
            statusArray: this.props.query.status ? this.props.query.status.split(',') : [],
        }
        const checked = e.target.checked
        const name = e.target.value

        if (checked) {
            criteria[filterName + 'Array'].push(name)
        } else {
            const index = criteria[filterName + 'Array'].indexOf(name)
            if (index >= 0) criteria[filterName + 'Array'].splice(index, 1)
        }

        this.replace({
            ...this.props.query,
            progress: criteria.progressArray.join(','),
            status: criteria.statusArray.join(','),

        })
    }

    isChecked(name, filter) {
        const progress = this.props.query[filter]
        if (!progress) return false

        return progress.split(',').indexOf(name) >= 0
    }

    saveFilter = () => {
        localStorage.setItem('projectsFilter', this.props.location.search)
        toastr.success(this.props.t('projects.title'), this.props.t('projects.filter_saved_message'))
    }

    get assigneeInfoData() {
        const {
            partnerAssignees,
            partner,
            t,
        } = this.props

        const geoCode = partner.geo.code

        const assignee = this.state.assigneeIds
            .map(id => partnerAssignees[id])
            .find(assignee => assignee.assigneeRole === 'client_consultant')

        if (assignee && geoCode !== 'de') {
            return {
                image: assignee.assigneeInfo.image,
                name: assignee.assigneeInfo.name,
                mail: assignee.assigneeInfo.email,
                phone: assignee.assigneeInfo.phone,
            }
        }

        if (partner.employee && geoCode !== 'de') {
            return {
                image: partner.employee.profilePicture,
                name: partner.employee.name,
                mail: partner.employee.email,
                phone: partner.employee.phoneWork,
            }
        }

        return {
            image: '/images/ageras-squares.png',
            name: t('base.company_prefix'),
            mail: t('base.country.mail'),
            phone: t('base.country.phone'),
        }
    }

    render() {
        const {
            projectStatuses,
            quoteProgresses,
            isLoading,
            projects,
            compact,
            partner,
            pages,
            query,
            page = 1,
            t,
        } = this.props

        let filterButtonText = ''

        if (compact) {
            filterButtonText = ''
        } else if (this.state.toggleFilter) {
            filterButtonText = t('projects.filters_hide')
        } else {
            filterButtonText = t('projects.filters_show')
        }

        const hasFilters = (query.query || query.progress)

        return <ContainerFluid modifiers={[ 'fullWidth' ]}>
            <Separator/>
            <ClientsAwaitingQuotesCard partner={partner} />
            <Card>
                <div className="project-finder">
                    <PageTitle identifier="navigation.projects" backLinkName={t('leads.title')}/>
                    <div className="header">
                        <button className="btn btn-primary filter-btn" onClick={() => this.setState({ toggleFilter: !this.state.toggleFilter })}>
                            <span className="ap-filter" /> <span className="hidden-xs hidden-sm">{filterButtonText}</span>
                        </button>
                        <button className="btn btn-primary filter-btn" onClick={() => this.replace({})} >
                            {t('projects.reset_filter')}
                        </button>
                        <div className="search-input-container">
                            <span className="search-icon ap-magnify" />
                            <input
                                onChange={this.handleQueryChange.bind(this)}
                                value={this.props.query.query}
                                className="form-control"
                                type="text"
                            />
                        </div>
                    </div>
                    <div className={'filters ' + (this.state.toggleFilter ? '' : 'hidden')}>
                        {/*Desktop filter content*/}
                        <Flex modifiers={[ 'justifySpaceBetween', 'alignCenter' ]}>
                            <div>
                                <Row className="hidden-xs">
                                    <div className="status-filter">
                                        <strong>{t('projects.quotes_progress')}</strong>
                                    </div>
                                    {quoteProgresses.map((progress, i) =>
                                        <div key={i} className="status-filter">
                                            <Checkbox
                                                onChange={this.handleFilterChange.bind(this, 'progress')}
                                                checked={this.isChecked(progress, 'progress')}
                                                value={progress}
                                                type="checkbox"
                                                id={'quoteProgresses-' + progress}
                                            />
                                            <ProgressLabel progress={progress} icon={true} />
                                            <div className="text">{t('quotes.progress.' + progress)}</div>
                                            {/*<span className="text intro-text-color"> ({progressCounter[progress].length})</span>*/}
                                        </div>
                                    )}
                                </Row>
                                <Row className="hidden-xs">
                                    <div className="status-filter">
                                        <strong>{t('projects.project_statuses')}</strong>
                                    </div>
                                    {projectStatuses.map((status, i) =>
                                        <div key={i} className="status-filter">
                                            <Flex modifiers={[ 'alignCenter' ]}>
                                                <Checkbox
                                                    onChange={this.handleFilterChange.bind(this, 'status')}
                                                    checked={this.isChecked(status.name, 'status')}
                                                    value={status.name}
                                                    type="checkbox"
                                                    id={'projectStatuses-' + status.name}
                                                />
                                                <TriangleBadge text={t('projects.statuses.' + status.name)} />
                                            </Flex>
                                        </div>
                                    )}
                                </Row>
                            </div>
                            <div>
                                <Button
                                    onClick={this.saveFilter}
                                    modifiers={[ 'primary', 'mR_2' ]}
                                >
                                    {t('projects.save_filter')}
                                </Button>
                            </div>
                        </Flex>

                        {/*Mobile filter content*/}
                        <table className="mobile-status-table visible-xs">
                            <colgroup>
                                <col width="40px"/>
                                <col width="80%"/>
                            </colgroup>
                            <tbody>
                                {quoteProgresses.map((progress, i) =>
                                    <tr key={i}>
                                        <td>
                                            <Checkbox
                                                onChange={this.handleFilterChange.bind(this, 'progress')}
                                                checked={this.isChecked(progress, 'progress')}
                                                value={progress}
                                                type="checkbox"
                                                id={progress}
                                            />
                                        </td>
                                        <td>
                                            <Flex modifiers={[ 'alignCenter' ]}>
                                                <ProgressLabel progress={progress} icon={true}/>
                                                <OverflowText width="75%">{t('quotes.progress.' + progress)}</OverflowText>
                                            </Flex>
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <td/>
                                    <td> <Card.Line /></td>
                                </tr>
                                {projectStatuses.map((status, i) =>
                                    <tr key={i}>
                                        <td>
                                            <Checkbox
                                                onChange={this.handleFilterChange.bind(this, 'status')}
                                                checked={this.isChecked(status.name, 'status')}
                                                value={status.name}
                                                type="checkbox"
                                                id={status}
                                            />
                                        </td>
                                        <td>
                                            <TriangleBadge text={t('projects.statuses.' + status.name)}/>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    {/*Project data table */}
                    <div className="content">
                        {isLoading &&
                        <div className={'loading-overlay ' + (projects.length === 0 ? 'empty-list' : '')}>
                            <LoadingAnimation />
                        </div>}

                        {(projects.length === 0 && !isLoading) && <div className="empty-list">
                            <img src="/images/icons/widgets.svg" />
                            {hasFilters ? <div>
                                <h2>{t('projects.empty_list.no_matches.header')}</h2>
                                <p className="intro-text">{t('projects.empty_list.no_matches.intro')}</p>
                            </div> : <div>
                                <h2>{t('projects.empty_list.no_projects.header')}</h2>
                                <p className="intro-text">{t('projects.empty_list.no_projects.intro')}</p>
                            </div>}

                        </div>}

                        <ProjectListTable />

                        {
                            !isLoading &&
                            page < pages &&
                                <p className="text-center">
                                    <Button
                                        modifiers={[ 'primary' ]}
                                        onClick={() => this.loadNextPage()}
                                    >
                                        {t('projects.load_more')}
                                    </Button>
                                </p>
                        }
                    </div>
                </div>
            </Card>
        </ContainerFluid>
    }
}
