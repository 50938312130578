import React from 'react'
import { Card } from '../../../../../components/blocks/Card'
import styled from 'styled-components'
import { Flex } from '../../../../../components/elements/Flex'

const FormatText = styled.div`
  line-height: 1.53;
  color: #5f6974
`

const ImgContainer = styled.div`
  width: 800px;
  margin-${({ left }) => left ? 'left' : 'right'}: 50px;
`

export const TellowWorkInfoCard = () =>
    <Card>
        <Card.Content modifiers={[ 'pY_5', 'pX_6' ]}>
            <Card.Heading modifiers={[ 'cyanColor', 'mB_5' ]}>
                Zo werkt Tellow Compleet
            </Card.Heading>
            <Flex modifiers={[ 'mB_3' ]}>
                <ImgContainer>
                    <img src="/images/tellow-partner/iphone-w-tellow-1.png" />
                </ImgContainer>
                <div>
                    <h2>
                        <strong>1. Altijd en overal boekhoudkundig advies</strong>
                    </h2>
                    <FormatText>
                        Tellow compleet is de volledige boekhouder die alles voor je doet. Wij nemen je hele administratie uit handen. Dus ook je persoonlijke inkomstenbelasting aangifte (IB) én die van je fiscale partner
                    </FormatText>
                </div>
            </Flex>
            <Flex modifiers={[ 'mB_3' ]}>
                <div>
                    <h2>
                        <strong>2. Je bonnen automatisch verwerkt</strong>
                    </h2>
                    <FormatText>
                        Bonnetje? Maak een foto, scan hem in en wij doen de rest. Zo worden al je bonnen automatisch onder de juiste kostenpost geboekt.
                    </FormatText>
                </div>
                <ImgContainer left>
                    <img src="/images/tellow-partner/iphone-w-tellow-2.png" />
                </ImgContainer>
            </Flex>
            <Flex>
                <ImgContainer>
                    <img src="/images/tellow-partner/iphone-w-tellow-3.png" />
                </ImgContainer>
                <div>
                    <h2>
                        <strong>3. Maak en verzend offertes en facturen</strong>
                    </h2>
                    <FormatText>
                        Maak professionele offertes inclusief je eigen logo en bedrijfskleuren. Offerte geaccepteerd? Zet de offerte dan gemakkelijk om in een factuur en ontvang je geld.
                    </FormatText>
                </div>
            </Flex>
        </Card.Content>
    </Card>
