import { Content, LineCard } from '../../cards'
import { Card } from '../../blocks/Card'
import { Col, Row } from 'react-bootstrap'
import { TextareaGroup } from '../../forms/inputs/TextareaGroup'
import nl2br from '../../utils/Nl2br'
import React from 'react'
import { actionColor, secondaryColor } from '../../../theme/variables'
import styled from 'styled-components'
import connect from 'react-redux/es/connect/connect'
import { getLeadCertifications } from '../../../actions/certifications'
import { createSelector } from 'reselect'
import { withTranslation } from 'react-i18next'
import { certificationSelector } from '../../../selectors/lead'

const ProductLine = styled(Row)`
  margin-bottom: 10px;
`

const TagGroup = styled.div`
  margin-bottom: 30px;
`

@connect(
    state => ({
        certifications: certificationSelector(state),
    }),
    {
        getLeadCertifications,
    }
)
@withTranslation('', { wait: true })
export class QuoteDescriptionLineCard extends React.PureComponent {
    componentDidMount() {
        this.props.getLeadCertifications(this.props.lead.id)
    }

    render() {
        const {
            certifications,
            disabled,
            input,
            lead,
            meta,
            t,
        } = this.props

        return <LineCard borderColor={input.value ? actionColor : secondaryColor}>
            <Card.Header>
                <Card.MarkCircle size="40" modifiers={[ 'mR_2', input.value ? 'action' : 'secondary' ]}>1</Card.MarkCircle>
                <strong>{t('quotes.creation.description.label')}</strong>
            </Card.Header>
            <Content>
                <Row>
                    <Col sm={6}>
                        <Card.Intro modifiers={[ 'mT_0' ]}>{t('quotes.creation.description.intro')}</Card.Intro>
                        <TextareaGroup
                            placeholder={t('quotes.creation.description.placeholder')}
                            disabled={disabled}
                            height={400}
                            input={input}
                            meta={meta}
                        />
                    </Col>
                    <Col sm={6}>
                        <Card>
                            <Card.Content>
                                <Row>
                                    <Col sm={2}>
                                        <Card.MarkCircle
                                            modifiers={[ 'primary' ]}
                                            className="mdi mdi-text-subject mdi-24px"
                                            size="45"
                                        />
                                    </Col>
                                    <Col sm={10}>
                                        <strong>{lead.title}</strong></Col>
                                </Row>
                                <Card.Intro>
                                    <Card.Text modifiers={[ 'primaryColor' ]}>
                                        {t('quotes.creation.description.case_highlight')}
                                    </Card.Text>
                                    <Card.Line modifiers={[ 'mB_2', 'mT_1' ]} />
                                    <div><strong>#{lead.id}</strong></div>
                                    <TagGroup>
                                        {lead.customerInfo && lead.customerInfo.customerType &&
                                        <ProductLine key="primaryIndustry">
                                            <Col sm={6}>{t('leads.lead_info.type')}</Col>
                                            <Col sm={6}>{lead.customerInfo.customerType.identifier}</Col>
                                        </ProductLine>}
                                        {lead.primarySector?.displayName &&
                                            <ProductLine key="primarySector">
                                                <Col sm={6}>{t('leads.lead_info.sector')}</Col>
                                                <Col sm={6}>{lead.primarySector.displayName}</Col>
                                            </ProductLine>}
                                        {lead.attributes.primary &&
                                        lead.attributes.primary.map(attribute => <ProductLine key={attribute.label}>
                                            <Col sm={6}>{attribute.label}</Col>
                                            <Col sm={6}>{attribute.selected.map(el => el.title)}</Col>
                                        </ProductLine>)}
                                    </TagGroup>
                                    {Object.keys(lead.attributes).map(tag =>
                                        [ 'basics', 'business_value', 'bookkeeping', 'requirement', 'preference', 'industry' ].includes(tag) &&
                                        <TagGroup>
                                            <Card.Text modifiers={[ 'primaryColor' ]}>{t(`leads.lead_info.${tag}`)}</Card.Text>
                                            <Card.Line modifiers={[ 'mY_1' ]} />
                                            {lead.attributes[tag].map(attribute => <ProductLine key={attribute.label}>
                                                <Col sm={6}>{attribute.label}</Col>
                                                <Col sm={6}>{attribute.selected.map(el => el.title)}</Col>
                                            </ProductLine>)}
                                        </TagGroup>)
                                    }
                                    {Boolean(certifications.length) &&
                                    <TagGroup>
                                        <Card.Text modifiers={[ 'primaryColor' ]}>
                                            {t('leads.lead_info.certifications')}
                                        </Card.Text>
                                        <Card.Line modifiers={[ 'mY_1' ]} />
                                        {certifications.map(el => <ProductLine key={el.id + 'certifications'}>
                                            <Col sm={6}><i className="mdi mdi-certificate"/> {el.title}</Col>
                                        </ProductLine>)}
                                    </TagGroup>}
                                    {lead.description && <TagGroup>
                                        <Card.Text modifiers={[ 'primaryColor' ]}>
                                            {t('quotes.creation.description.case_description')}
                                        </Card.Text>
                                        <Card.Line modifiers={[ 'mY_1' ]} />
                                        {nl2br(lead.description)}
                                    </TagGroup>}
                                </Card.Intro>
                            </Card.Content>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </LineCard>
    }
}
