import { ClientResource, PartnerResource } from 'ageras-api-client/src/api'

export const PARTNER_LOG_IN = 'Partner log in(FE)'
export const PARTNER_SIGN_UP = 'Partner user created(FE)'
export const PARTNER_SUBSCRIPTION_STARTED = 'Subscription started(FE)'
export const PARTNER_PREPAYMENT_PAID = 'Prepayment Paid(FE)'
export const PARTNER_QUOTE_CREATED = 'Quote created(FE)'
export const PARTNER_PUBLISH_QUOTE_WITHOUT_PRICE = 'Partner publish quote without price(FE)'
export const PARTNER_PUBLISH_QUOTE_WITH_PRICE = 'Partner publish quote with price(FE)'
export const PARTNER_REJECTED_QUOTE = 'Partner rejected client quote(FE)'
export const PARTNER_ACCEPTED_MEETING = 'Partner accepted client meeting(FE)'

export const CLIENT_LOG_IN = 'Client log in(FE)'
export const CLIENT_OPENED_QUOTE = 'Client opened quote(FE)'
export const CLIENT_VIEWED_PARTNERS_PHONE_NUMBER = 'Client Viewed Partners Number(FE)'
export const CLIENT_SENT_PARTNER_MESSAGE = 'Client sent partner message(FE)'
export const CLIENT_REQUESTED_MEETING = 'Client requested meeting(FE)'
export const CLIENT_ACCEPTED_QUOTE = 'Client accepted quote(FE)'

interface PartnerCustomParamsInterface {
    prepaymentAmount?: number
    subscription?: string,
    leadId?: number,
    revenueSize?: number,
    quoteAmount?: number
}

interface ClientCustomParamsInterface {
    leadId?: number
    partnerId?: number
}

type SegmentCustomParamsInterface = PartnerCustomParamsInterface & ClientCustomParamsInterface

declare var analytics: any

const isPartner = (entity: PartnerResource | ClientResource): entity is PartnerResource => {
    return (entity as PartnerResource).hasActiveContract !== undefined
}

function retrieveDefaultFields(entity: PartnerResource | ClientResource) {

    if (isPartner(entity)) {
        return {
            type: 'partner',
            partnerId: entity.id,
            name: entity.companyName,
            geoCode: entity.geo?.code,
            companyName: entity.companyName,
            industryName: entity.industry?.name,
            industryIdentifier: entity.industry?.identifier,
            subscription: entity.subscription?.[0]?.packageType,
            hasSubscription: entity.hasSubscription,
            hasActiveContract: entity.hasActiveContract,
            createdAt: entity.createdAt,
        }
    } else {
        return {
            type: 'client',
            clientId: entity.id,
            name: entity.firstName + ' ' + entity.lastName,
            email: entity.email,
            phoneNumber: entity.phone,
            geoCode: entity.geo?.code
        }
    }
}

export function identifySegment(entity: PartnerResource | ClientResource) {
    try {
        const anonymousId = analytics.user().anonymousId()
        const defaultFields = retrieveDefaultFields(entity)
        const identifyString = isPartner(entity) ? 'partner-' + entity.id : 'client-' + entity.id

        analytics.identify(identifyString, { ...defaultFields }, {
            anonymousId: anonymousId,
        })
    } catch (e) {
        console.error(e)
    }
}

export function sendSegmentData(entity: PartnerResource | ClientResource, event: string, customParams?: SegmentCustomParamsInterface) {
    try {
        const defaultFields = retrieveDefaultFields(entity)
        analytics.track(event, { ...defaultFields, ...customParams })
    } catch (e) {
        console.error(e)
    }
}

export function segmentPage() {
    try {
        analytics.page()
    } catch (e) {
        console.error(e)
    }
}
