import { useAuth } from 'ageras-api-client/src/auth'
import React from 'react'
import userflow from 'userflow.js'
import usePartner from '../../utils/usePartner'
import config from '../../config'
function UserFlow() {
    const { partnerUser } = useAuth()
    const { partner } = usePartner()
    const token: string = String(config.userflowToken)

    userflow.init(token)
    userflow.identify(String(partnerUser?.partnerId), {
        name: partnerUser?.firstName + ' ' + partnerUser?.lastName,
        email: partnerUser?.email,
        // eslint-disable-next-line camelcase
        signed_up_at: partnerUser?.createdAt,
        // eslint-disable-next-line camelcase
        locale_code: partner?.geo?.code,
        // eslint-disable-next-line camelcase
        has_active_contract: String(partner?.hasActiveContract)
    })
    return <></>
}

export default UserFlow
