import React from 'react'
import { Field, FieldArray } from 'redux-form'
import { Col, Row } from 'react-bootstrap'
import { SelectInputGroup } from '../../forms/inputs/SelectInputGroup'
import { FieldGroup } from '../../forms/inputs/FieldGroup'
import { Card } from '../../blocks/Card'

const dynamicCompanyInfoFields = ({ fields, t }) => {
    if (!fields) return null

    const dataFields = fields.getAll()

    return fields.map((member, index) => {
        switch (dataFields[index].identifier) {
            case 'customers_count':
            case 'employees_count':
            case 'availability':
                return (
                    <Col md={6}>
                        <Field
                            label={t(`company_profile.company_info.${dataFields[index].identifier}`)}
                            name={`${member}.selected[0]`}
                            component={SelectInputGroup}
                            options={dataFields[index].values}
                        />
                    </Col>
                )
            case 'hourly_rate':
            case 'establishment_year':
                return (
                    <Col md={6}>
                        <Field
                            label={t(`company_profile.company_info.${dataFields[index].identifier}`)}
                            name={`${member}.selected[0].title`}
                            type="number"
                            component={FieldGroup}
                        />
                    </Col>
                )
            default:
                return null
        }
    })
}

export const CompanyInformationContent = ({ t, zipCodeOnChange }) =>
    <Card.Content>
        <Card.Title>{t('company_profile.company_info.title')}</Card.Title>
        <Card.Intro>{t('company_profile.company_info.intro')}</Card.Intro>
        <Row>
            <FieldArray
                name="attributes"
                component={dynamicCompanyInfoFields}
                t={t}
            />
            <Col md={6}>
                <Field
                    label={t('company_profile.company_info.website')}
                    name="website"
                    component={FieldGroup}
                />
            </Col>
            <Col md={6}>
                <Field
                    label={t('company_profile.company_info.address')}
                    name="geo.address"
                    component={FieldGroup}
                />
            </Col>
            <Col md={6}>
                <Field
                    label={t('company_profile.company_info.postal_code')}
                    name="geo.zipCode"
                    component={FieldGroup}
                    onChange={zipCodeOnChange}
                />
            </Col>
            <Col md={6}>
                <Field
                    label={t('company_profile.company_info.city')}
                    name="geo.cityName"
                    component={FieldGroup}
                    readOnly
                />
            </Col>
            <Col md={6}>
                <Field
                    label={t('company_profile.company_info.phone')}
                    name="phone"
                    component={FieldGroup}
                />
            </Col>
        </Row>
    </Card.Content>
