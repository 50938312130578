import { CREATE_PUNCHABLE_ACTION, CREATE_PUNCHABLE_ACTION_SUCCESS, CREATE_PUNCHABLE_ACTION_FAIL } from '../../actions/creditsystem'

const initialState = {
    isLoading: false,
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CREATE_PUNCHABLE_ACTION:
            return {
                ...state,
                isLoading: true,
            }
        case CREATE_PUNCHABLE_ACTION_SUCCESS:
        case CREATE_PUNCHABLE_ACTION_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state
    }
}
