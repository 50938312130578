import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation, Trans } from 'react-i18next'
import { currentPartnerSelector } from '../../selectors/auth'
import { punchCountSelector } from '../../selectors/coupons'
import CouponList from '../coupons/CouponList'
import LoadingAnimation from '../utils/LoadingAnimation'
import PageTitle from '../utils/PageTitle'
import { COIN_DIVIDE_NUMBER } from '../../utils/usePartnerPrePaidBalance'
import Amount from 'components/src/Amount'

@connect(
    state => ({
        coupons: Object.values(state.entities.coupons),
        partner: currentPartnerSelector(state),
        isLoading: state.pages.coupons.isLoading,
        punchCount: punchCountSelector(state),
    })
)

@withTranslation('', { wait: true })
export default class CouponListPage extends Component {
    render() {
        const {
            isLoading,
            coupons,
            punchCount,
            t,
            partner,
        } = this.props

        const count = <Amount number={punchCount / COIN_DIVIDE_NUMBER} currency={partner.preferences?.currency?.code} maximumFractionDigits={0} />

        return (
            <div className="card">
                <PageTitle identifier="coupons.title" />
                <h2>{t('coupons.title')}</h2>

                <p className="intro-text">
                    {t('coupons.intro')}
                </p>
                <div className="row">
                    {isLoading ? <LoadingAnimation /> : <div>
                        {coupons.length ? <div>
                            <strong>
                                <Trans
                                    i18nKey="coupons.punches_left_explanation"
                                    components={{ count: count }}
                                />
                            </strong>
                            <br /><br />
                            <CouponList coupons={coupons} partner={partner} />
                        </div> : <div>{t('coupons.no_coupons')}</div>}
                    </div>}
                </div>
            </div>
        )
    }
}
