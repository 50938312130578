import { CALL_API } from '../middleware/api'
import * as schemas from '../schemas'

export const GET_GEO_REGIONS = 'locations/GET_GEO_REGIONS'
export const GET_GEO_REGIONS_SUCCESS = 'locations/GET_GEO_REGIONS_SUCCESS'
export const GET_GEO_REGIONS_FAIL = 'locations/GET_GEO_REGIONS_FAIL'

export const getGeoRegions = criteria => ({
    [CALL_API]: {
        types: [ GET_GEO_REGIONS, GET_GEO_REGIONS_SUCCESS, GET_GEO_REGIONS_FAIL ],
        endpoint: '/locations/segmentationgeoregions',
        schema: schemas.geoRegions,
        options: {
            body: {
                ...criteria,
                limit: 100,
            },
        },
    },
})
