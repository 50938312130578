import { PartnerResource } from 'ageras-api-client/src/api'
import { useAuth } from 'ageras-api-client/src/auth'
import Amount from 'components/src/Amount'
import useModal from 'components/src/modals/useModal'
import React from 'react'
import Text from 'components/src/texts/Text'
import Button from 'components/src/Button'
import { useTranslation } from 'react-i18next'
import usePartnerPrePaidBalance from '../../../utils/usePartnerPrePaidBalance'
import PrePaymentModal from '../../modals/PrePaymentModal'
import Grid from 'components/src/layout/Grid'

interface MarketplaceBalanceProps {
    partner: PartnerResource,
    hasMarketplaceWithoutFeesAccess: boolean
}

function BalanceInfo({ partner, hasMarketplaceWithoutFeesAccess }: MarketplaceBalanceProps) {
    const { t } = useTranslation()
    const auth = useAuth()
    const { show } = useModal()
    const { balance, isLoading } = usePartnerPrePaidBalance()

    if (!partner.marketplace?.currentQuotesLimit || partner.marketplace?.currentQuotesCount === undefined) {
        return null
    }

    return <Grid gridTemplateColumns="auto auto" gridGap="0.5rem" alignItems="center" justifyItems="end" >
        <Text>
            {t('marketplace.balance.quotes_left')}
            {' '}
            <Text color="accent" size="medium" bold={true}>
                {partner.marketplace?.currentQuotesLimit - partner.marketplace?.currentQuotesCount}
                /
                {partner.marketplace?.currentQuotesLimit}
            </Text>
        </Text>

        {/* placeholder for the first grid row */}
        <span>&nbsp;</span>

        {!hasMarketplaceWithoutFeesAccess && <Text>
            {t('marketplace.balance.pre_paid')}
            {' '}
            <Text color="accent" size="medium" bold={true}>{!isLoading && <Amount number={balance} currency={partner.preferences?.currency?.code} maximumFractionDigits={0} />}</Text>
        </Text>}

        {!hasMarketplaceWithoutFeesAccess && <Button ml="1rem" variant="primary" size="small" onClick={show(args => <PrePaymentModal {...args} token={auth.token!} partner={partner} geoCode={partner.geo?.code!} currency={partner.preferences?.currency!} partnerId={Number(partner.id)} />)}>
            <Text size="small">{t('marketplace.balance.add_balance')}</Text>
        </Button>}
    </Grid>
}

export default BalanceInfo
