import {
    GET_PARTNER_PROFILE_DATA_FIELDS,
    GET_PARTNER_PROFILE_DATA_FIELDS_FAIL,
    GET_PARTNER_PROFILE_DATA_FIELDS_SUCCESS
} from '../../actions/datafields'

const initialState = {
    dataFieldsIds: [],
    isLoading: false,
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_PARTNER_PROFILE_DATA_FIELDS:
            return {
                ...state,
                isLoading: true,
            }
        case GET_PARTNER_PROFILE_DATA_FIELDS_SUCCESS:
            return {
                ...state,
                dataFieldsIds: action.response.result,
                isLoading: false,
            }
        case GET_PARTNER_PROFILE_DATA_FIELDS_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state
    }
}
