import {
    GET_TERMS, GET_TERMS_SUCCESS, GET_TERMS_FAIL,
} from '../../actions/terms'

import {
    COMPLETE_CHECKOUT, COMPLETE_CHECKOUT_SUCCESS, COMPLETE_CHECKOUT_FAIL,
} from '../../actions/checkouts'

const initialState = {
    terms: [],
    isLoadingTerms: false,
    isCompletingCheckout: false,
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    case GET_TERMS:
        return {
            ...state,
            isLoadingTerms: true,
        }
    case GET_TERMS_SUCCESS:
        return {
            ...state,
            terms: action.response.result,
            isLoadingTerms: false,
        }
    case GET_TERMS_FAIL:
        return {
            ...state,
            isLoadingTerms: false,
        }
    case COMPLETE_CHECKOUT:
        return {
            ...state,
            isCompletingCheckout: true,
        }
    case COMPLETE_CHECKOUT_SUCCESS:
        return {
            ...state,
            isCompletingCheckout: false,
        }
    case COMPLETE_CHECKOUT_FAIL:
        return {
            ...state,
            isCompletingCheckout: false,
        }
    default:
        return state
    }
}
