import React from 'react'
import {
    Content, ConversationParticipantContext,
    ConversationScrollableList,
    DotIndicator,
    MobileNoteSubmitWrapper,
    OverflowText,
    PlainCard,
    ScrollableContent,
    Separator,
    SeparatorLine,
} from '../../components/cards'
import { Col, Media, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Portrait from '../../components/projects/Portrait'
import EmptyCard from './EmptyCardTemplate'
import NoteSubmitForm from './NoteSubmitForm'
import {
    selectedListItemColor,
    selectedBorder,
} from '../../theme/variables'
import moment from 'moment/moment'
import MessagesContainer from './MessagesContainer'
import { Trans } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroller'
import LoadingAnimation from '../../components/utils/LoadingAnimation'
import styled from 'styled-components'
import { PrimaryBlankLink } from '../../components/elements/Links'

const ConversationContainer = styled(Row)`
    margin: 0!important;
    > div {
        padding: 0;
    }
    .empty-card {
        margin-top: 30px;
    }
`

const NoConversation = ({ t }) =>
    <div>
        <p className="intro-text-color">
            <Trans
                i18nKey="empty_list.go_to_cases"
                link={<Link to="/cases" className="text-active_link">{t('messages.cases')}</Link>}
            />
        </p>
        <Link to="/cases" className="btn btn-primary btn-lg">{t('messages.go_to_cases')}</Link>
    </div>

export class ConversationMessenger extends React.Component {
    render () {
        const {
            markAsReadConversation,
            selectedConversation,
            conversationNaming,
            unreadMessagesIds,
            handleNoteSubmit,
            conversations,
            showTextInput,
            messages,
            loadMore,
            hasMore,
            sender,
            t,
        } = this.props

        const selectedConversationParticipant = selectedConversation ? selectedConversation.participants.find(el => el.type === conversationNaming) : {}
        const conversationMessages = selectedConversation ?
            selectedConversation.messages
                .map(id => messages[id])
                .sort((cur, next) => moment(cur.updatedAt) - moment(next.updatedAt)) :
            []
        const leadParticipant = selectedConversation ? selectedConversation.participants.find(el => el.type === 'lead') : {}
        const clientParticipant = selectedConversation ? selectedConversation.participants.find(el => el.type === 'client') : {}
        const partnerParticipant = selectedConversation ? selectedConversation.participants.find(el => el.type === 'partner') : {}

        return (
            <div>
                <div
                    className={`mobile-page-name message-header sticky-top visible-xs visible-sm ${selectedConversation ? 'text-right' : 'text-center'}`}
                >
                    {selectedConversation &&
                    <Link
                        to={'/messages'}
                        className="text-active pull-left"
                    >
                        <i className="mdi mdi-chevron-left" /> &nbsp;
                        {t('actions.overview_message')}
                    </Link>
                    }
                    { selectedConversationParticipant.name ?
                        <OverflowText>{selectedConversationParticipant.name}</OverflowText> : t('pages.titles.messages')
                    }
                </div>
                <PlainCard useMobile>
                    {conversations.length
                        ? <ConversationContainer>
                            <Col md={4} className={selectedConversation ? 'hidden-xs hidden-sm' : ''}>
                                <ConversationScrollableList
                                    padding="10px"
                                    height="640px"
                                    mobileHeight="auto"
                                    mobileMarginBottom="50px"
                                >
                                    <InfiniteScroll
                                        pageStart={1}
                                        loadMore={loadMore}
                                        hasMore={hasMore}
                                        loader={<LoadingAnimation size={25} />}
                                        useWindow={false}
                                    >
                                        { conversations
                                            .map((conversation, i) => {
                                                const lastMessage = messages[conversation.messages[0]] || {}
                                                let existNewMessage = unreadMessagesIds.includes(lastMessage.id)
                                                const responcedParticipent = conversation.participants.find(el => el.type === conversationNaming)

                                                return <Link
                                                    key={i + '_conversation_' + conversation.id}
                                                    to={'/messages#' + conversation.id}
                                                    onClick={() => existNewMessage && markAsReadConversation(conversation.id)}
                                                >
                                                    <Media
                                                        style={{
                                                            background: selectedConversation && conversation.id === Number(selectedConversation.id) ?
                                                                selectedListItemColor : 'none',
                                                            borderRadius: selectedBorder,
                                                        }}
                                                    >
                                                        <Media.Left align="middle">
                                                            <Content padding="20px 10px 0 20px">
                                                                <DotIndicator
                                                                    showBadge={existNewMessage}
                                                                    top={'0px'}
                                                                    right={'-4px'}
                                                                    padding={'7px'}
                                                                >
                                                                    <Portrait
                                                                        name={responcedParticipent.name}
                                                                        hash={responcedParticipent.name}
                                                                        image={responcedParticipent.picture}
                                                                        size={40}
                                                                    />
                                                                </DotIndicator>
                                                                <span>&nbsp;</span>
                                                            </Content>
                                                        </Media.Left>
                                                        <Media.Body className="credit-info">
                                                            <Content padding="20px 20px 20px 10px">
                                                                <OverflowText style={{ fontWeight: 500 }} width="13em">{responcedParticipent.name}</OverflowText>
                                                                <OverflowText width="13em">{lastMessage && lastMessage.text}</OverflowText>
                                                            </Content>
                                                        </Media.Body>
                                                    </Media>
                                                </Link>
                                            })
                                        }
                                    </InfiniteScroll>
                                </ConversationScrollableList>
                            </Col>
                            <Col md={8} className={selectedConversation ? '' : 'hidden-xs hidden-sm'}>
                                <EmptyCard
                                    isEmpty={selectedConversation}
                                    icon={'../../../images/icons/empty-state-messages.svg'}
                                    description={t('empty_card.empty_message_state')}
                                >
                                    <ScrollableContent
                                        height="480px"
                                        marginBottom="0"
                                        mobileHeight="auto"
                                        mobileMarginBottom="190px"
                                        padding="0"
                                    >
                                        <Content padding="20px">
                                            {conversationNaming === 'client'
                                                ? <ConversationParticipantContext>
                                                    <Portrait
                                                        name={clientParticipant.name}
                                                        hash={clientParticipant.name}
                                                        image={clientParticipant.picture}
                                                        size={40}
                                                    />
                                                    <div><strong>{clientParticipant.name}</strong></div>
                                                    <div>{clientParticipant.zipCode} {clientParticipant.city}</div>
                                                    { selectedConversation && selectedConversation.projectId &&
                                                    <PrimaryBlankLink href={`/projects/${selectedConversation.projectId}`}>
                                                        {t('messages.view_project')}
                                                    </PrimaryBlankLink>}
                                                </ConversationParticipantContext>
                                                : <ConversationParticipantContext>
                                                    <Portrait
                                                        name={partnerParticipant.name}
                                                        hash={partnerParticipant.name}
                                                        image={partnerParticipant.picture}
                                                        size={40}
                                                    />
                                                    <div><strong>{partnerParticipant.name}</strong></div>
                                                    <div>{partnerParticipant.zipCode} {partnerParticipant.city}</div>
                                                    <PrimaryBlankLink href={`/cases/${leadParticipant.leadId}/${partnerParticipant.partnerId}`}>
                                                        {t('messages.view_offer')}
                                                    </PrimaryBlankLink>
                                                </ConversationParticipantContext>
                                            }
                                        </Content>
                                        <SeparatorLine />
                                        <Content padding="20px">
                                            <MessagesContainer
                                                ref={el => {
                                                    this.messagesEnd = el
                                                }}
                                                messages={conversationMessages}
                                                conversationId={selectedConversation && selectedConversation.id}
                                                scrollIntoView={true}
                                                sender={sender}
                                                characters={200}
                                                t={t}
                                            />
                                        </Content>
                                    </ScrollableContent>
                                </EmptyCard>
                                <Separator />
                                <Content padding="20px">
                                    {(selectedConversation || showTextInput) &&
                                    <MobileNoteSubmitWrapper>
                                        <NoteSubmitForm
                                            placeholder={t('message_form.placeholder') + (selectedConversationParticipant.name || '')}
                                            buttonText={<i className="mdi mdi-send" />}
                                            supportFiles={false}
                                            onSubmit={handleNoteSubmit}
                                            onChange={() => showTextInput || this.messagesEnd.lastElement.scrollIntoView()}
                                        />
                                    </MobileNoteSubmitWrapper>
                                    }
                                </Content>
                            </Col>
                        </ConversationContainer>
                        : <ConversationContainer>
                            <Col md={4}>
                                <ConversationScrollableList
                                    padding="10px"
                                    height="640px"
                                    mobileHeight="auto"
                                    mobileMarginBottom="50px"
                                >
                                    <Content padding="20px">
                                        <EmptyCard
                                            isEmpty={false}
                                            icon={'../../../images/icons/empty-state-messages.svg'}
                                            description={t('empty_card.empty_conversations_state')}
                                        />
                                    </Content>
                                </ConversationScrollableList>
                            </Col>
                            <Col md={8}>
                                <ScrollableContent
                                    height="480px"
                                    marginBottom="0"
                                    mobileHeight="auto"
                                    mobileMarginBottom="190px"
                                    padding="10px"
                                >
                                    <Content padding="20px">
                                        <EmptyCard
                                            isEmpty={false}
                                            icon={'../../../images/icons/empty-state-messages.svg'}
                                            description={t('empty_card.no_messages')}
                                        />
                                    </Content>
                                </ScrollableContent>
                            </Col>
                        </ConversationContainer>
                    }
                </PlainCard>
            </div>
        )
    }
}
