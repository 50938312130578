import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getInvoices } from '../../actions/invoices'
import { currentPartnerSelector } from '../../selectors/auth'
import { ContainerFluid } from '../cards'
import LoadingAnimation from '../utils/LoadingAnimation'
import FormattedAmount from '../utils/FormattedAmount'
import InvoiceLineItem from '../finance/invoices/InvoiceLineItem'
import { getStripeApiKey } from '../../actions/stripe'
import { generateApiUrl } from '../../utils/urls'
import { Label } from 'react-bootstrap'
import { push } from 'connected-react-router'
import {
    createPaymentIntent,
    confirmPaymentIntent,
    validatePaymentIntent,
} from '../../actions/billing'
import Box from '../blocks/Box'
import { Headline, Text } from '../elements/text/Text'
import { Button } from "../elements/ButtonNew";

@connect(
    (state, props) => ({
        invoice: state.entities.invoices[props.match.params.invoiceId],
        partner: currentPartnerSelector(state),
        stripe: state.stripe,
        isCompletingPayment: state.pages.billing.isCompletingPayment,
    }),
    {
        getInvoices,
        getStripeApiKey,
        createPaymentIntent,
        confirmPaymentIntent,
        validatePaymentIntent,
        push,
    }
)
@withTranslation('', { wait: true })
export default class InvoiceDetailPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            error: null,
            selectedPaymentMethod: null,
        }
    }

    componentDidMount() {
        const { partner } = this.props
        this.props.getInvoices({ partnerId: partner.id, limit: 1, invoiceId: this.props.match.params.invoiceId })

        this.props.getStripeApiKey(partner.geo.code)
            .then(res => {
                if(this.props.match.params.action === 'return') {
                    this.handleSourcePaymentRedirect()
                } else if (this.props.match.params.action === 'payment_intent_action_required') {
                    this.handlePaymentIntentReturn()
                }
            })
    }

    handleSourcePaymentRedirect() {
        const q = new URLSearchParams(window.location.search)
        const stripe = window.Stripe(this.props.stripe.applicationKey)

        stripe.retrieveSource({
            client_secret: q.get('client_secret'),
            id: q.get('source'),
        }).then(result => {
            if(result.source) {
                const status = q.get('redirect_status')

                if (status === 'succeeded') {
                    this.props.push('/invoice-payment-confirmation/' + this.props.match.params.invoiceId)
                } else {
                    this.setState({ error: true })
                }
            }
        })
    }

    handlePaymentIntentReturn() {
        const q = new URLSearchParams(window.location.search)
        this.props.validatePaymentIntent(q.get('payment_intent_id'), this.props.match.params.invoiceId)
            .then(response => {
                this.handlePaymentIntentAction(response.result)
            })
    }

    handleServerResponse(response) {
        if (response.result.status === 'error') {
            this.setState({
                error: response.result.errorCode,
            })
        } else if (response.result.requiresAction) {
            // Use Stripe.js to handle required card action
            this.handlePaymentIntentAction(response.result)
        } else {
            this.props.push('/invoice-payment-confirmation/' + this.props.match.params.invoiceId)
        }
    }

    handlePaymentIntentAction(result) {
        const stripe = window.Stripe(this.props.stripe.applicationKey)
        stripe.handleCardAction(
            result.clientSecret
        ).then(result => {
            if (result.error) {
                this.setState({
                    error: result.error.code,
                })
            } else {
                this.props.confirmPaymentIntent(result.paymentIntent.id, this.props.invoice.id)
                    .then(response => {
                        this.handleServerResponse(response)
                    })
            }
        })
    }

    createSourceAndRedirect = (sourceId, paymentMethod, additionalData = {}) => {
        const {
            invoice,
            partner,
            t,
        } = this.props
        const stripe = window.Stripe(this.props.stripe.applicationKey)

        const amount = parseInt(Math.round(invoice.amounts.totalIncludingVat.amount * 100))
        const currency = invoice.amounts.totalIncludingVat.currency
        let statementDescriptor = t(`invoices.type.${invoice.type}`).concat(' #', invoice.invoiceNumber)

        const sourceData = {
            type: sourceId,
            amount: amount,
            currency: currency,
            redirect: {
                return_url: window.location + '/' + paymentMethod.identifier + '/return',
            },
            owner: {
                name: partner.companyName,
            },
            statement_descriptor: statementDescriptor,
            metadata: {
                invoiceId: invoice.id,
                partnerId: partner.id,
                geoCode: partner.geo.code,
            },
            ...additionalData,
        }

        stripe.createSource(sourceData).then(result => {
            if(result.source) {
                if(result.source.redirect) {
                    window.location = result.source.redirect.url
                } else {
                    if (result.source.status === 'chargeable') {
                        this.props.push('/invoice-payment-confirmation/' + this.props.match.params.invoiceId)
                    } else {
                        this.setState({ error: true })
                    }
                }
            } else {
                this.setState({
                    error: result.error.code,
                })
            }
        })
    }

    completePayment = paymentMethod => {
        this.props.createPaymentIntent(paymentMethod.id, this.props.invoice.id)
            .then(result => {
                this.handleServerResponse(result)
            })
    }

    render() {
        const {
            invoice,
            stripe,
            t,
            isCompletingPayment,
        } = this.props

        const {
            error,
        } = this.state

        const redirectedBack = this.props.match.params.action === 'return' && !error

        const paymentIntentNeedsAction = this.props.match.params.action === 'payment_intent_action_required' && !error

        if (!invoice || !stripe.solutions || redirectedBack) {
            return <LoadingAnimation />
        }

        const totalIncludingVat = invoice.amounts.totalIncludingVat

        let paymentStatus

        switch (invoice.status) {
        case 'credited':
        case 'partially_credited':
        case 'payment_in_progress':
            paymentStatus = 'info'
            break
        case 'overdue':
        case 'disputed':
        case 'unpaid ':
        case 'failed':
            paymentStatus = 'danger'
            break
        case 'authorized':
        case 'paid':
            paymentStatus = 'success'
            break
        }

        let downloadUrl = generateApiUrl('invoices/' + invoice.id + '.pdf')

        let solutions = stripe.solutions
        const paymentMethods = solutions.map(identifier => {
            if (identifier === 'card_stripe')
                return {
                    identifier: 'card_stripe',
                    apiKey: stripe.applicationKey,
                }
            return { identifier }
        })

        return (
            <ContainerFluid>
                {error && <div className="alert alert-danger">
                    {error.length ? t('payment_page.payment_error.' + error) : t('payment_page.payment_error_message')}
                </div>}
                {paymentIntentNeedsAction && <div className="alert alert-info">
                    {t('payment_page.payment_needs_confirmation')}
                </div>}
                {isCompletingPayment && <LoadingAnimation />}
                <Box modifiers={[ 'p_3', 'mB_3' ]}>
                    <Box.Content>
                        <Headline modifiers={[ 'tiny', 'mB_2' ]}>
                            <Label bsStyle={paymentStatus}>
                                {invoice.type === 'invoice' ? t('invoices.' + invoice.status) : t(`invoices.type.${invoice.type}`)}
                            </Label>
                            &nbsp;<strong>{t(`invoices.type.${invoice.type}`)} #{invoice.invoiceNumber}</strong>
                        </Headline>
                        { invoice.invoiceLines.map(lineItem =>
                            <InvoiceLineItem key={lineItem.id} lineItem={lineItem} />
                        )}
                        <Text>
                            <strong>Total: <FormattedAmount amount={totalIncludingVat.amount} currency={totalIncludingVat.currency} /></strong>
                        </Text>
                        <a href={downloadUrl} target="_blank" rel="noreferrer">
                            <Button
                                to={downloadUrl}
                                modifiers={[ 'btnBlock', 'primary', 'p_2', 'mT_3' ]}
                            >
                                {t('invoices.download')}
                            </Button>
                        </a>
                    </Box.Content>
                </Box>
                { [ 'unpaid', 'overdue', 'failed' ].includes(invoice.status) && <Box modifiers={[ 'p_3' ]}>
                    <Box.Content>
                        <Headline modifiers={[ 'small', 'mB_2' ]}>{t('payment_page.intro')}</Headline>
                        <Text><em>{t('payment_page.security_disclaimer')}</em></Text>
                    </Box.Content>
                </Box>}
            </ContainerFluid>
        )
    }
}
