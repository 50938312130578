import React from 'react'
import TabDropdownComponent from './styles'
import { Text } from '../text/Text'

class TabDropdown extends React.Component {
    handleItemClick = (event, option) => {
        const { onClick, close } = this.props

        event.stopPropagation()

        onClick(option)
        close()
    }

    render() {
        const { options, open } = this.props

        return <TabDropdownComponent open={open}>
            {Boolean(options.length) && options.map(option =>
                <TabDropdownComponent.Item
                    key={`item-${option.tabId}`}
                    onClick={event => this.handleItemClick(event, option)}
                >
                    <Text modifiers={[ 'small' ]}>
                        { option.tabName }
                    </Text>
                </TabDropdownComponent.Item>)}
        </TabDropdownComponent>
    }
}

export default TabDropdown
