import React from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

@withTranslation('', { wait: true })
export default class FileItem extends React.Component {
    render() {
        const {
            file,
            t,
        } = this.props

        return (
            <div>
                <Link to={decodeURIComponent(file.url || '#')} target="_blank">{file.name}</Link>
                <ul className="meta">
                    <li>{ Math.round(file.size / 1000) } KB</li>
                </ul>
            </div>
        )
    }
}
