import React from 'react'
import Message from './Message'

export default class NoteMessage extends React.Component {

    render() {
        const {
            message: {
                createdAt,
                partnerUser: {
                    name,
                    profilePicture,
                    email,
                },
                content,
            },
        } = this.props

        return (
            <div className="note-message">
                <Message
                    message={{
                        type: 'note',
                        author: {
                            name,
                            picture: profilePicture,
                            email,
                        },
                        createdAt,
                        content,
                    }}
                />
            </div>
        )
    }
}
