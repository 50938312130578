import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { DotIndicator } from '../cards'

export const PageHeader = ({
    hasNewMessage,
    mobileMenuOpen,
    closeMenu,
    menuItems,
    logout,
    t,
}) =>
    <div>
        <div id="desktop-menu" className="hidden-xs hidden-sm">
            <div className="container-fluid">
                <ul>
                    {menuItems.map((item, i) => {
                        if (item.hidden && item.hidden === true) {
                            return null
                        }
                        if(item.url) {
                            return <li key={i}><a href={item.url}>{item.title}</a></li>
                        }
                        return (
                            <li key={i}>
                                <NavLink
                                    to={item.pathname}
                                    activeClassName="active"
                                >
                                    <DotIndicator showBadge={[ '/messages' ].includes(item.pathname) ? hasNewMessage : ''}>
                                        {item.title}
                                    </DotIndicator>
                                </NavLink>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
        <div id="mobile-menu" className={mobileMenuOpen ? 'open' : ''}>
            <div className="dropdown">
                <ul>
                    {menuItems.map((item, i) => {
                        if (item.hidden && item.hidden === true) {
                            return null
                        }
                        return (
                            <li key={i} onClick={ () => closeMenu()}>
                                <Link to={{ pathname: item.pathname }} className={item.active}>{item.title}</Link>
                            </li>
                        )
                    })}
                    <li onClick={ () => closeMenu()}>
                        <Link to={{ pathname: '/me' }}>{ t('user.profile_edit.header') }</Link>
                    </li>
                    <li onClick={ () => closeMenu()}>
                        <a href="#" onClick={() => logout()}>
                            { t('user.log_out') }
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
