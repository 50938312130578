import { GET_PARTNER_CONTRACTS, GET_PARTNER_CONTRACTS_FAIL, GET_PARTNER_CONTRACTS_SUCCESS } from '../../actions/partnercontracts'
import { GET_TERMS, GET_TERMS_SUCCESS, GET_TERMS_FAIL } from '../../actions/terms'

const initialState = {
    isLoading: false,
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_PARTNER_CONTRACTS:
        case GET_TERMS:
            return {
                ...state,
                isLoading: true,
            }
        case GET_PARTNER_CONTRACTS_SUCCESS:
        case GET_PARTNER_CONTRACTS_FAIL:
        case GET_TERMS_SUCCESS:
        case GET_TERMS_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state
    }
}
