import {
    GET_INVOICES, GET_INVOICES_SUCCESS, GET_INVOICES_FAIL, GET_DUE_NOT_PAID_INVOICES, GET_DUE_NOT_PAID_INVOICES_SUCCESS,
    GET_DUE_NOT_PAID_INVOICES_FAIL
} from '../../actions/invoices'

const initialState = {
    items: [],
    notPaidInvoicesId: [],
    isLoading: true,
    isLoadingNotPaidInvoices: true,
    pagination: {},
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_INVOICES:
            return {
                ...state,
                isLoading: true,
            }
        case GET_INVOICES_SUCCESS:
            return {
                ...state,
                items: action.response.result,
                pagination: action.response.pagination,
                isLoading: false,
            }
        case GET_INVOICES_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        case GET_DUE_NOT_PAID_INVOICES:
            return {
                ...state,
                isLoadingNotPaidInvoices: true,
            }
        case GET_DUE_NOT_PAID_INVOICES_SUCCESS:
            return {
                ...state,
                notPaidInvoicesId: action.response.result,
                isLoadingNotPaidInvoices: false,
            }
        case GET_DUE_NOT_PAID_INVOICES_FAIL:
            return {
                ...state,
                isLoadingNotPaidInvoices: false,
            }
        default:
            return state
    }
}
