import { actionColor, cyanColor, dangerColor, primaryColor, secondaryColor } from '../../../theme/variables'

export const TEXT_COLOR_MODIFIERS = {
    regularColor: () => `
        color: #000
    `,
    primaryColor: () => `
        color: ${primaryColor} 
    `,
    secondaryColor: () => `
        color: ${secondaryColor} 
    `,
    actionColor: () => `
        color: ${actionColor} 
    `,
    dangerColor: () => `
        color: ${dangerColor} 
    `,
    cyanColor: () => `
        color: ${cyanColor}
    `,
}
