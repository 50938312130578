import React from 'react'
import styled from 'styled-components'
import { FormGroup, Label } from './FormControl'
import { Flex } from '../../elements/Flex'
import { Error } from './FormControl'
import { colors } from '../../../theme/variables_new'

const Checkbox = styled.div`
    position: relative;
    width: 20px;
    height: 20px;
    border: 2px solid ${colors.grey};
    border-radius: 3px;
    svg {
        position: absolute;
        top: -2px;
        left: -2px;
        path {
            fill: none;
            stroke: ${colors.lightBlue};
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-dasharray: 71px;
            stroke-dashoffset: 71px;
            transition: all 0.6s ease;
        }
        polyline {
            fill: none;
            stroke: white;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-dasharray: 18px;
            stroke-dashoffset: 18px;
            transition: all 0.3s ease;
        }
    }
    span {
        position: absolute;
        left: 20px;
        top: -2px;
        font-weight: normal;
    }
`

const CheckboxLabel = styled.label`
    cursor: pointer;
    display: initial;
    margin: 0;

    &:hover ${Checkbox} svg path {
        stroke-dashoffset: 0;
    }
`

const CheckboxInputField = styled.input.attrs({ type: 'checkbox' })`
    position: absolute;
    z-index: -1;
    width: 0;
    height: 0;
    opacity: 0;

    &:checked + ${Checkbox} {
        border-color: ${colors.lightBlue};
        svg {
            path {
                fill: ${colors.lightBlue};
            }
            polyline {
                stroke-dashoffset: 0;
            }
        }
    }
`

export const CheckboxBox = ({ onChange, checked, value, id, label, inputProps }) => (
    <CheckboxLabel htmlFor={id}>
        <CheckboxInputField name={id} id={id} onChange={onChange} checked={checked} value={value} {...inputProps}/>
        <Checkbox>
            <svg width="20px" height="20px" viewBox="0 0 20 20">
                <path d="M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z"/>
                <polyline points="4 11 8 15 16 6"/>
            </svg>
            <span style={{ whiteSpace: 'nowrap' }}>
                {label}
            </span>
        </Checkbox>
    </CheckboxLabel>
)

const CheckboxInput = ({
    label,
    children,
    input: {
        value,
        onChange,
        name,
        ...inputProps
    },
    meta: { touched, error, warning },
}) =>
    <FormGroup>
        {label && <Label>{label}</Label>}
        <Flex>
            <CheckboxBox
                name={name}
                id={name}
                onChange={onChange}
                value={value}
                checked={value}
                inputProps={inputProps}
            /> &nbsp;
            <span>{children}</span>
        </Flex>

        {touched &&
        ((error && <Error>
            {error}
        </Error>
        ) || (warning && <Error>
            {warning}
        </Error>))}
    </FormGroup>

export default CheckboxInput
