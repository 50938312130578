import React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { MapPin } from 'react-feather'
import { replace } from 'connected-react-router'
import { toastr } from 'react-redux-toastr'
import { withTranslation } from 'react-i18next'
import Box from '../blocks/Box'
import { Flex } from '../elements/Flex'
import { Headline, Span, Text } from '../elements/text/Text'
import { Button, TextButton } from '../elements/ButtonNew'
import { hideModal } from '../../actions/modal'
import { cancelSubscription } from '../../actions/subscriptions'
import styled from 'styled-components'

const CenteredModal = styled(Modal)`
    .modal-dialog {
        width: 520px;
        margin-top: 300px !important;
    }
`

@connect(
    (state) => ({
        partner: state.entities.partners[state.modal.partnerId],
        subscription: state.entities.subscriptions[state.modal.subscriptionId],
    }),
    {
        hideModal,
        cancelSubscription,
        replace,
    }
)
@withTranslation('', { wait: true })
export default class CancelMarketplaceSubscriptionModal extends React.Component {
    confirmCancel = async () => {
        const { subscription, partner } = this.props
        try {
            await this.props.cancelSubscription(partner.id, subscription.id)
        } catch (err) {
            toastr.error('Error!', err.error)
        } finally {
            this.props.hideModal()
        }
    }

    render() {
        const { t } = this.props

        return <CenteredModal
            show={true}
            onHide={this.props.hideModal}
            className="new"
        >
            <Box>
                <Box.Header modifiers={[ 'pX_2', 'pT_2', 'pB_1' ]}>
                    <Headline modifiers={[ 'tiny' ]}>{t('marketplace.cancellation.modal_headline')}</Headline>
                </Box.Header>
                <Box.Line />
                <Box.Content modifiers={[ 'p_2' ]}>
                    <Text>{t('marketplace.cancellation.modal_body')}</Text>
                    <Text modifiers={[ 'small', 'mT_1' ]}>{t('marketplace.cancellation.modal_sub')}</Text>
                </Box.Content>
                <Box.Line />
                <Flex modifiers={[ 'justifyEnd', 'p_2' ]}>
                    <Button onClick={this.confirmCancel} modifiers={[ 'danger', 'bottomLeft', 'small', 'bold' ]}>{t('marketplace.cancellation.btn_confirm')}</Button>
                </Flex>
            </Box>
        </CenteredModal>
    }
}
