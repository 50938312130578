import React from 'react'

const newlineRegex = /(\r\n|\n\r|\r|\n)/g

export default function nl2br(str) {
    if (typeof str === 'number') {
        return str
    } else if (typeof str !== 'string') {
        return ''
    }

    return str.trim().split(newlineRegex).map(function(line, index) {
        if (line.match(newlineRegex)) {
            return React.createElement('br', { key: index })
        }
        return line
    })
}
