import styled from 'styled-components'
import { Badge } from '../../cards'

export const BadgeLabel = styled(Badge)`
    padding: 2px 10px 2px 15px;
    border-radius: 0 4px 4px 0;
    max-width: 100%;
    i {
        padding-left: 10px;
    }
`
