import React from 'react'
import { connect } from 'react-redux'
import i18n from 'i18next'
import { Trans, withTranslation } from 'react-i18next'
import { reduxForm, Field, FieldArray } from 'redux-form'
import {
    getVerifications,
    saveVerifications,
    saveBankAccount,
} from '../../actions/partners'
import { currentPartnerSelector } from '../../selectors/auth'
import moment from 'moment'
import LoadingAnimation from '../utils/LoadingAnimation'
import countries from 'i18n-iso-countries'
import { flatten } from 'flat'
import { getRenderedTerm, acceptTerms } from '../../actions/terms'
import { getVerificationSelector, getVerificationTermsSelector } from '../../selectors/verification'
import { renderField, CheckboxGroup } from '../utils/forms'

const adaptFileEventToValue = delegate =>
    e => delegate(e.target.files[0])

const FileInput = ({
    label,
    input: {
        value,
        onChange,
        onBlur,
        name,
        ...inputProps
    },
    meta: { touched, error, warning },
    ...props
}) => {
    if(value && value[0] !== 'd') {
        const reader = new FileReader()
        reader.onload = upload => {
            onChange(upload.target.result)
        }
        reader.readAsDataURL(value)
    }

    return (
        <div className={'form-group' + (touched && error ? ' has-error' : '')}>
            {label && <label className="control-label">
                {label}
            </label>}
            <input
                onChange={adaptFileEventToValue(onChange)}
                onBlur={adaptFileEventToValue(onBlur)}
                type="file"
                name={name}
                {...inputProps}
                {...props}
            />
            {touched &&
            ((error &&
                <span className="help-block">
                    {error}
                </span>) ||
                (warning &&
                    <span className="help-block">
                        {warning}
                    </span>))}
        </div>
    )
}

const renderAdditionalOwners = ({ fields, meta: { error, submitFailed }, t }) =>
    <div>
        {fields.length > 0 && <table className="datatable form-compact">
            <thead>
                <tr>
                    <th>{t('verifications.additional_owners.name')}</th>
                    <th>{t('verifications.additional_owners.date_of_birth.header')}</th>
                    <th style={{ width: '30%' }}>{t('verifications.additional_owners.address.header')}</th>
                    <th />
                </tr>
            </thead>
            {fields.map((member, index) =>
                <tbody key={index}>
                    <tr>
                        <td>
                            <div className="row">
                                <div className="col-md-12">
                                    <Field
                                        name={`${member}.first_name`}
                                        type="text"
                                        component={renderField}
                                        placeholder={t('verifications.additional_owners.first_name')}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <Field
                                        name={`${member}.last_name`}
                                        type="text"
                                        component={renderField}
                                        placeholder={t('verifications.additional_owners.last_name')}
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="row">
                                <div className="col-md-12">
                                    <Field name={`${member}.dob.day`} component={renderField}>
                                        <option>{t('verifications.additional_owners.date_of_birth.day')}</option>
                                        {[ ...Array(31).keys() ].map(i => <option key={i} value={ i + 1 }>{ i + 1 }</option>)}
                                    </Field>
                                </div>
                                <div className="col-md-12">
                                    <Field name={`${member}.dob.month`} component={renderField}>
                                        <option>{t('verifications.additional_owners.date_of_birth.month')}</option>
                                        {moment.months().map((month, index) => <option key={index} value={ index + 1 }>{ month }</option>)}
                                    </Field>
                                </div>
                                <div className="col-md-12">
                                    <Field name={`${member}.dob.year`} component={renderField}>
                                        <option>{t('verifications.additional_owners.date_of_birth.year')}</option>
                                        {[ ...Array(120).keys() ].map(i => <option key={i} value={ 2017 - i }>{ 2017 - i }</option>)}
                                    </Field>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="row">
                                <div className="col-md-12">
                                    <Field placeholder={t('verifications.additional_owners.address.address')} name={`${member}.address.line1`} component={renderField} />
                                </div>
                                <div className="col-md-4">
                                    <Field placeholder={t('verifications.additional_owners.address.postal_code')} name={`${member}.address.postal_code`} component={renderField} />
                                </div>
                                <div className="col-md-8">
                                    <Field placeholder={t('verifications.additional_owners.address.city')} name={`${member}.address.city`} component={renderField} />
                                </div>
                                <div className="col-md-12">
                                    <Field name={`${member}.address.country`} component={renderField}>
                                        <option />
                                        {Object.keys(countries.getNames(i18n.language)).map((value, index) => {
                                            return <option key={index} value={value}>{countries.getNames(i18n.language)[value]}</option>
                                        })}
                                    </Field>
                                </div>
                            </div>
                        </td>
                        <td rowSpan={2}>
                            <button
                                type="button"
                                className="btn btn-sm btn-default"
                                onClick={() => fields.remove(index)}
                            >&times;</button>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            <Field label={t('verifications.legal_entity.legal_representative.document.label')} name={`${member}.verification.document`} type="file" component={FileInput} />
                        </td>
                    </tr>
                </tbody>
            )}
        </table>}

        <button className="btn btn-default" type="button" onClick={() => fields.push({})}>
            {t('verifications.additional_owners.add')}
        </button>
        {submitFailed &&
        error &&
        <span>
            {error}
        </span>}

    </div>

@withTranslation('', { wait: true })
@reduxForm({
    form: 'KYCUpdatePartnerForm',
})
@connect(
    (state, props) => ({
        partner: currentPartnerSelector(state),
        verification: getVerificationSelector(state),
        terms: getVerificationTermsSelector(state),
    }),
    {
        getVerifications,
        saveVerifications,
        saveBankAccount,
        getRenderedTerm,
        acceptTerms,
    }
)
export default class KYCPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            date: '',
            acceptTerms: {},
            loadingAdditional: false,
            error: null,
        }
    }

    componentWillMount() {
        this.reloadVerifications()
    }

    reloadVerifications() {
        this.props.getVerifications(this.props.partner.id).then(res => {
            this.props.verification.entries.forEach(el => {
                if(el.identifier === 'ageras.tos.accept') {
                    this.props.getRenderedTerm(el.metadata.termsId, {
                        partnerId: this.props.partner.id,
                    })
                    this.setState({ acceptTerms: {
                        [`term_${el.metadata.termsId}`]: false,
                    } })
                }
            })

            this.setState({ loadingAdditional: false })
        })
    }

    submitForm(data) {
        const {
            partner,
            verification,
        } = this.props
        data.legal_entity = data.legal_entity || {}

        if(verification.entries.find(v => v.identifier.indexOf('legal_entity.additional_owners') > -1)) {
            data.legal_entity.additional_owners = []
        }

        this.saveVerifications(partner.id, data)
    }

    saveVerifications(partnerId, data) {
        if(data.bank_account) {
            this.props.saveBankAccount(partnerId, data.bank_account)

            delete data.bank_account
        }

        // Find out which terms to mark as accepted
        if(data.terms) {
            data.terms.map(id => this.props.acceptTerms(id))
            delete data.terms
        }

         // Flatten array and change keys (.NUMBER. to .[NUMBER].)
        data = Object.assign({},
            ...Object.entries(flatten(data))
                .map(([ key, value ]) => ({
                    [key.replace(/\.(\d+)\./g, '.[$1].')]: value,
                }))
        )

        this.props.saveVerifications(partnerId, flatten(data)).then(res => {
            this.setState({ loadingAdditional: true, error: null })
            this.reloadVerifications()
            this.props.reset()
        }).catch(error => {
            this.setState({ error: error.error })
        })
    }

    render() {
        const { handleSubmit, verification, submitting, terms, t } = this.props
        const { loadingAdditional, error } = this.state
        const inputFields = []
        const legalRepresentativeFields = []
        const required = value => (value ? undefined : t('verifications.validation.required'))
        const validateAdditionalOwners = value => {
            return undefined
        }

        if(loadingAdditional) {
            return <div className="mini-landing verifications card">
                <LoadingAnimation />
                <h1>{t('verifications.loading_more.title')}</h1>
                <p>{t('verifications.loading_more.intro')}</p>
            </div>
        }

        if(!verification) {
            return <LoadingAnimation />
        }

        const extraInputFields = []
        let remainingVerifications = verification.entries

        if(!remainingVerifications.length) {
            return <div className="card">
                <h2>{t('verifications.title')}</h2>
                <div className="mini-landing verifications">
                    <span className="icon"><img src="/images/icons/checkmark.svg" /></span>
                    <h1>{t('verifications.completed.title')}</h1>
                    <p>{t('verifications.completed.intro')}</p>
                </div>
            </div>
        }

        // Legal entity representative?
        if(remainingVerifications.find(v => v.identifier.indexOf('legal_entity.first_name') > -1)) {
            legalRepresentativeFields.push(<div className="form-group">
                <div className="row">
                    <div className="col-md-6">
                        <Field label={t('verifications.legal_entity.legal_representative.first_name')} name="legal_entity.first_name" component={renderField} validate={[ required ]} />
                    </div>
                    <div className="col-md-6">
                        <Field label={t('verifications.legal_entity.legal_representative.last_name')} name="legal_entity.last_name" component={renderField} validate={[ required ]} />
                    </div>
                </div>
            </div>)

            remainingVerifications = remainingVerifications.filter(v => v.identifier.indexOf('legal_entity.first_name') === -1 && v.identifier.indexOf('legal_entity.last_name'))
        }

        // DOB field?
        if(remainingVerifications.find(v => v.identifier.indexOf('legal_entity.dob.') > -1)) {
            legalRepresentativeFields.push(<div className="form-group">
                <label>
                    {t('verifications.legal_entity.legal_representative.date_of_birth.label')}
                </label>
                <div className="row">
                    <div className="col-md-2">
                        <Field name="legal_entity.dob.day" component={renderField} validate={[ required ]}>
                            <option>{t('verifications.legal_entity.legal_representative.date_of_birth.day')}</option>
                            {[ ...Array(31).keys() ].map(i => <option key={i} value={ i + 1 }>{ i + 1 }</option>)}
                        </Field>
                    </div>
                    <div className="col-md-2">
                        <Field name="legal_entity.dob.month" component={renderField} validate={[ required ]}>
                            <option>{t('verifications.legal_entity.legal_representative.date_of_birth.month')}</option>
                            {moment.months().map((month, index) => <option key={index} value={ index + 1 }>{ month }</option>)}
                        </Field>
                    </div>
                    <div className="col-md-2">
                        <Field name="legal_entity.dob.year" component={renderField} validate={[ required ]}>
                            <option>{t('verifications.legal_entity.legal_representative.date_of_birth.year')}</option>
                            {[ ...Array(120).keys() ].map(i => <option key={i} value={ 2017 - i }>{ 2017 - i }</option>)}
                        </Field>
                    </div>
                </div>
            </div>)

            remainingVerifications = remainingVerifications.filter(v => v.identifier.indexOf('legal_entity.dob.') === -1)
        }

        // Legal representative personal address
        if(remainingVerifications.find(v => v.identifier.indexOf('legal_entity.personal_address.') > -1)) {
            legalRepresentativeFields.push(<div className="form-group">
                <p className="intro-text">
                    {t('verifications.legal_entity.legal_representative.personal_address.header')}
                </p>Får

                <div className="row">
                    <div className="col-md-7">
                        <Field label={t('verifications.legal_entity.legal_representative.personal_address.address_label')} name="legal_entity.personal_address.line1" component={renderField} validate={[ required ]} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <Field label={t('verifications.legal_entity.legal_representative.personal_address.postal_code_label')} name="legal_entity.personal_address.postal_code" component={renderField} validate={[ required ]} />
                    </div>
                    <div className="col-md-4">
                        <Field label={t('verifications.legal_entity.legal_representative.personal_address.city_label')} name="legal_entity.personal_address.city" component={renderField} validate={[ required ]} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-7">
                        <Field label={t('verifications.legal_entity.legal_representative.personal_address.country_label')} name={'legal_entity.personal_address.country'} component={renderField}>
                            <option />
                            {Object.keys(countries.getNames(i18n.language)).map((value, index) => {
                                return <option key={index} value={value}>{countries.getNames(i18n.language)[value]}</option>
                            })}
                        </Field>
                    </div>
                </div>

            </div>)

            remainingVerifications = remainingVerifications.filter(v => v.identifier.indexOf('legal_entity.personal_address.') === -1)
        }

        // Legal entity business tax id?
        if(remainingVerifications.find(v => v.identifier.indexOf('legal_entity.business_tax_id') > -1)) {
            extraInputFields.push(<div className="form-group">
                <div className="row">
                    <div className="col-md-12">
                        <Field label={t('verifications.legal_entity.business_tax_id')} name="legal_entity.business_tax_id" component={renderField} validate={[ required ]} />
                    </div>
                </div>
            </div>)

            remainingVerifications = remainingVerifications.filter(v => v.identifier.indexOf('legal_entity.business_tax_id') === -1)
        }

        // Legal entity personal id?
        if(remainingVerifications.find(v => v.identifier.indexOf('legal_entity.personal_id_number') > -1)) {
            legalRepresentativeFields.push(<div className="form-group">
                <div className="row">
                    <div className="col-md-12">
                        <Field label={t('verifications.legal_entity.personal_id_number')} name="legal_entity.personal_id_number" component={renderField} validate={[ required ]} />
                    </div>
                </div>
            </div>)

            remainingVerifications = remainingVerifications.filter(v => v.identifier.indexOf('legal_entity.personal_id_number') === -1)
        }

        // Legal entity last 4 digits of social security number?
        if(remainingVerifications.find(v => v.identifier.indexOf('legal_entity.ssn_last_4') > -1)) {
            legalRepresentativeFields.push(<div className="form-group">
                <div className="row">
                    <div className="col-md-12">
                        <Field label={t('verifications.legal_entity.ssn_last_4')} name="legal_entity.ssn_last_4" component={renderField} validate={[ required ]} />
                    </div>
                </div>
            </div>)

            remainingVerifications = remainingVerifications.filter(v => v.identifier.indexOf('legal_entity.ssn_last_4') === -1)
        }

        // Legal business address
        if(remainingVerifications.find(v => v.identifier.indexOf('legal_entity.address.') > -1)) {
            extraInputFields.push(<div className="form-group">
                <p className="intro-text">
                    {t('verifications.legal_entity.address.header')}
                </p>

                <div className="row">
                    <div className="col-md-7">
                        <Field label={t('verifications.legal_entity.address.address_label')} name="legal_entity.address.line1" component={renderField} validate={[ required ]} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <Field label={t('verifications.legal_entity.address.postal_code_label')} name="legal_entity.address.postal_code" component={renderField} validate={[ required ]} />
                    </div>
                    <div className="col-md-4">
                        <Field label={t('verifications.legal_entity.address.city_label')} name="legal_entity.address.city" component={renderField} validate={[ required ]} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-7">
                        <Field label={t('verifications.legal_entity.address.country_label')} name={'legal_entity.address.country'} component={renderField} validate={[ required ]}>
                            <option />
                            {Object.keys(countries.getNames(i18n.language)).map((value, index) => {
                                return <option key={index} value={value}>{countries.getNames(i18n.language)[value]}</option>
                            })}
                        </Field>
                    </div>
                </div>

            </div>)

            remainingVerifications = remainingVerifications.filter(v => v.identifier.indexOf('legal_entity.address.') === -1)
        }

        // Legal document
        if(remainingVerifications.find(v => v.identifier.indexOf('legal_entity.verification.document') > -1)) {
            legalRepresentativeFields.push(<div className="form-group">
                <p className="intro-text">{t('verifications.legal_entity.legal_representative.document.intro')}</p>
                <Field label={t('verifications.legal_entity.legal_representative.document.label')} name="legal_entity.verification.document" type="file" component={FileInput} validate={[ required ]} />
            </div>)

            remainingVerifications = remainingVerifications.filter(v => v.identifier.indexOf('legal_entity.verification.document') === -1)
        }

        // Additional owners?
        let additionalOwner = <div />
        if(remainingVerifications.find(v => v.identifier.indexOf('legal_entity.additional_owners') > -1)) {
            additionalOwner = <div>
                <h3>{t('verifications.additional_owners.title')}</h3>
                <p className="intro-text" style={{ marginTop: 0 }}>
                    {t('verifications.additional_owners.intro')}
                </p>
                <FieldArray t={t} validate={[ validateAdditionalOwners ]} name="legal_entity.additional_owners" component={renderAdditionalOwners} />
                <hr />
            </div>

            remainingVerifications = remainingVerifications.filter(v => v.identifier.indexOf('legal_entity.additional_owners') === -1)
        }

        // Bank account
        let bankInfo = null
        if(remainingVerifications.find(v => v.identifier.indexOf('bank_account') > -1)) {
            bankInfo = <div>
                <h3>{t('payout_info.destination.title')}</h3>
                <p className="intro-text">
                    {t('payout_info.destination.intro')}
                </p>

                <div className="form-group">
                    <Field
                        className="form-control"
                        name="bank_account.account_number"
                        component={renderField}
                        validate={[ required ]}
                        label={t('payout_info.destination.iban_label')}
                        placeholder={t('payout_info.destination.iban_placeholder')}
                    />

                    <Field
                        className="form-control"
                        name="bank_account.currency"
                        component={renderField}
                        validate={[ required ]}
                        label={t('payout_info.destination.currency_label')}
                    >
                        <option />
                        <option value="EUR">EUR</option>
                        <option value="DKK">DKK</option>
                        <option value="SEK">SEK</option>
                        <option value="NOK">NOK</option>
                        <option value="GBP">GBP</option>
                    </Field>

                    <Field
                        className="form-control"
                        name="bank_account.country"
                        component={renderField}
                        validate={[ required ]}
                        label={t('payout_info.destination.country_label')}
                    >
                        <option />
                        {Object.keys(countries.getNames(i18n.language)).map((value, index) => {
                            return <option key={index} value={value}>{countries.getNames(i18n.language)[value]}</option>
                        })}
                    </Field>
                </div>
                <hr />
            </div>

            remainingVerifications = remainingVerifications.filter(v => v.identifier.indexOf('bank_account') === -1)
        }

        // Remove accept_terms and bank_account
        remainingVerifications = remainingVerifications.filter(v => (v.type !== 'accept_terms'))

        return (
            <div className="card">
                <h2>{t('verifications.title')}</h2>
                <div className="alert alert-warning">
                    <strong>{t('verifications.kyc_header')}</strong><br />
                    {t('verifications.kyc_intro')}
                </div>
                <hr />
                {error && <div>
                    <div className="alert alert-danger">
                        <strong>{t('verifications.error_title')}</strong><br />
                        {error}
                    </div>
                </div>}
                <form onSubmit={handleSubmit(this.submitForm.bind(this))}>
                    <div className="form-group">
                        {inputFields}
                    </div>

                    {legalRepresentativeFields.length > 0 && <div>
                        <h3>{t('verifications.legal_entity.legal_representative.title')}</h3>
                        <p className="intro-text" style={{ marginTop: 0 }}>
                            {t('verifications.legal_entity.legal_representative.intro')}
                        </p>
                        {legalRepresentativeFields}
                        <hr />
                    </div>}

                    {additionalOwner}
                    {bankInfo}
                    {extraInputFields}
                    {terms.length > 0 && <p>
                        <Field
                            name="terms"
                            component={CheckboxGroup}
                            options={terms.map(term => {
                                return {
                                    value: term.id,
                                    label: <Trans i18nKey="checkout.terms.accept_label" link={<a href={`/terms/${term.term_id}`} target="_blank">{term.headline}</a>} />,
                                }
                            })}
                            validate={[ required ]}
                        />
                    </p>}

                    <button disabled={submitting} type="submit" className="btn btn-action btn-primary btn-lg btn-block" >
                        {t('verifications.submit_data')}
                    </button>
                </form>
            </div>
        )
    }
}
