import React from 'react'
import SegmentationPage from './SegmentationPage'
import CertificationPage from './CertificationPage'

import { ContainerFluid } from '../cards'
import SidebarPageTemplate from '../../containers/layout/SidebarPageTemplate'
import { withTranslation } from 'react-i18next'

@withTranslation('', { wait: true })
export default class SegmentationIntroPage extends React.Component {
    render() {
        const {
            location: { hash },
            match,
            t,
        } = this.props

        const navLinks = [
            {
                pathname: `${match.url}/base`,
                relatedComponent: <SegmentationPage />,
                mainText: t('navigation.segmentation'),
                secondaryText: t('navigation.segmentation_description'),
            },
            {
                pathname: `${match.url}/certifications`,
                relatedComponent: <CertificationPage />,
                mainText: t('navigation.certifications'),
                secondaryText: t('navigation.certifications_description'),
            },
        ]

        return <ContainerFluid>
            <SidebarPageTemplate
                navLinks={navLinks}
                hash={hash}
            />
        </ContainerFluid>
    }
}
