import { combineReducers } from 'redux'
import coupons from './coupons'
import leads from './leads'
import certifications from './certifications'
import invoices from './invoices'
import partnerUsers from './partnerUsers'
import ratings from './ratings'
import transactions from './transactions'
import segmentation from './segmentation'
import demoLanding from './demoLanding'
import billing from './billing'
import checkout from './checkout'
import leadDetail from './leadDetail'
import subscriptionUpgrade from './subscriptionUpgrade'
import subscriptionInfo from './subscriptionInfo'
import subscriptionPaymentOptions from './subscriptionPaymentOptions'
import cv from './cv'
import refunds from './refunds'
import projects from './projectList'
import projectDetail from './projectDetail'
import locations from './locations'
import payoutInfo from './payoutInfo'
import leadPartner from './leadPartner'
import deliverables from './deliverables'
import quote from './quote'
import quoteDetail from './quoteDetail'
import conversationsMessages from './conversationsMessages'
import invoiceRefunds from './invoiceRefunds'
import acceptTermsPage from './acceptTermsPage'
import partnerProfile from './partnerProfile'
import partnerContracts from './partnerContracts'
import partnerContractOfferGroups from './partnerContractOfferGroups'
import creditSystem from './creditSystem'
import meetings from './meetings'

const pagesReducer = combineReducers({
    coupons,
    leads,
    certifications,
    invoices,
    partnerUsers,
    ratings,
    transactions,
    segmentation,
    checkout,
    billing,
    leadDetail,
    subscriptionUpgrade,
    subscriptionInfo,
    subscriptionPaymentOptions,
    demoLanding,
    cv,
    refunds,
    projects,
    projectDetail,
    locations,
    payoutInfo,
    quote,
    quoteDetail,
    leadPartner,
    deliverables,
    conversationsMessages,
    invoiceRefunds,
    acceptTermsPage,
    partnerProfile,
    partnerContracts,
    partnerContractOfferGroups,
    creditSystem,
    meetings,
})

export default pagesReducer
