import { colors } from '../../../theme/variables_new'

export const TEXT_COLOR_MODIFIERS = {
    primary: () => `
        color: ${colors.darkBlue}
    `,
    white: () => `
        color: ${colors.white}
    `,
    grey: () => `
        color: ${colors.grey}
    `,
    green: () => `
        color: ${colors.green}
    `,
    blue: () => `
        color: ${colors.blue}
    `,
    opacityLight: () => `
        opacity: ${colors.opacity.light}
    `,
    opacityMedium: () => `
        opacity: ${colors.opacity.medium}
    `,
    opacityHigh: () => `
        opacity: ${colors.opacity.high}
    `,
    opacityFull: () => `
        opacity: ${colors.opacity.full}
    `,
}

export const TEXT_ALIGN_MODIFIERS = {
    center: () => `
        text-align: center;
    `,
    left: () => `
        text-align: left;
    `,
    right: () => `
        text-align: right;
    `,
}

export const TEXT_DECORATION_MODIFIERS = {
    lineStrike: () => `
        text-decoration: line-through;
    `,
}
