import React from 'react'
import { Card } from '../../../../../components/blocks/Card/index'
import { ScrollableContent, Separator } from '../../../../../components/cards/index'
import MessagesContainer from '../../../../layout/MessagesContainer'
import EmptyCard from '../../../../layout/EmptyCardTemplate'
import NoteSubmitForm from '../../../../layout/NoteSubmitForm'
import styled from 'styled-components'

const ScrollContent = styled.div`
    overflow-y: auto;
    max-height: 550px;
`

export const ChatCard = ({
    readConversationMessages,
    conversationMessages,
    handleNoteSubmit,
    partnerUserName,
    companyName,
    clientId,
    t,
}) =>
    <Card>
        <Card.Content onClick={readConversationMessages}>
            <Card.Heading modifiers={[ 'primaryColor', 'mX_3', 'mB_5', 'mT_3' ]}>
                {t('quotes.quote.chat_with', { name: partnerUserName })}
            </Card.Heading>
            <EmptyCard
                isEmpty={conversationMessages.length}
                icon={'../../../images/icons/empty-state-messages.svg'}
                description={t('empty_card.empty_message_state', { name: companyName })}
            >
                <ScrollContent>
                    <MessagesContainer
                        messages={conversationMessages}
                        sender={{ id: clientId, type: 'client' }}
                        t={t}
                    />
                </ScrollContent>
            </EmptyCard>
            <Separator/>
            <NoteSubmitForm
                placeholder={t('message_form.placeholder', { name: companyName })}
                buttonText={t('message_form.send_message')}
                supportFiles={false}
                onSubmit={handleNoteSubmit}
            />
        </Card.Content>
    </Card>
