import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import {
    queryParametersSelector,
    currentPageSelector,
} from '../../selectors/routing'

@connect(
    state => ({
        page: currentPageSelector(state),
        pathname: state.router.location.pathname,
        query: queryParametersSelector(state),
    }),
)
@withTranslation('', { wait: true })
export default class GlobalPagination extends React.Component {
    render() {
        const {
            page,
            pages,
            pathname,
            query,
            t,
        } = this.props

        return (
            <div>
                { pages > 1 && <span>{ t('pagination.page_of', { page, pages }) }<br /></span> }
                { page > 1 && <LinkContainer to={{ pathname, query: { ...query, page: page - 1 } }}>
                    <Button className="btn btn-secondary">{ t('pagination.previous') }</Button>
                </LinkContainer> }
                { pages > page && <LinkContainer to={{ pathname, query: { ...query, page: page + 1 } }}>
                    <Button className="btn btn-secondary">{ t('pagination.next') }</Button>
                </LinkContainer> }
            </div>
        )
    }
}
