import { bkpInt, colors } from '../../theme/variables_new'
import styled from 'styled-components'
import { applyResponsiveStyleModifiers, applyStyleModifiers } from 'styled-components-modifiers'
import { Icon } from '../layout/images'
import { withTranslation } from 'react-i18next'
import React from 'react'
import Box from '../blocks/Box'
import { Flex } from './Flex'
import { BlockLabel, Text } from './text/Text'
import List from './List'
import { largeNumberAbbreviator } from '../../utils/formatters'

const MODIFIER_TAB_CONFIG = {
    active: () => `
        border-bottom: 4px solid ${colors.blue};
        padding-bottom: 20px;
    `,
    disabled: () => `
        cursor: initial;
    `,
}

const SegmentationTab = styled.div`
    padding: 0 12px 24px;
    cursor: pointer;
    flex: 1;
    text-align: center;
    ${applyStyleModifiers(MODIFIER_TAB_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_TAB_CONFIG)}
`

const segmentationTabs = [
    {
        name: 'company_type',
        arrayTitle: 'clientTypes',
        stateObjectName: 'leadTypes',
        fieldName: 'title',
        isExpandable: true,
        icon: <Icon style={{ marginBottom: '32px' }} modifiers={[ 'small' ]}>
            <svg width="51" height="40" viewBox="0 0 51 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0)">
                    <path d="M42.9658 17.7128H36.0615V19.3085H42.9658V17.7128Z" fill="#16BEFF"/>
                    <path d="M42.9658 24.8936H36.0615V26.4894H42.9658V24.8936Z" fill="#16BEFF"/>
                    <path d="M42.9658 31.5425H36.0615V33.1383H42.9658V31.5425Z" fill="#16BEFF"/>
                    <path d="M48.2772 40H30.751V10.6383C30.751 9.84041 31.3883 9.14893 32.238 9.14893H46.7901C47.5868 9.14893 48.2772 9.78722 48.2772 10.6383V40ZM32.3443 38.4042H46.6839V10.7447H32.3443V38.4042Z" fill="#16BEFF"/>
                    <path d="M45.6211 10.7447H33.459V7.23404C33.459 6.64894 33.937 6.2234 34.4681 6.2234H44.612C45.1962 6.2234 45.6211 6.70213 45.6211 7.23404V10.7447ZM35.0523 9.14894H44.0278V7.76596H35.0523V9.14894Z" fill="#16BEFF"/>
                    <path d="M32.3435 40H2.81445V5.79787C2.81445 4.62766 3.77043 3.7234 4.88573 3.7234H30.2722C31.4406 3.7234 32.3435 4.68085 32.3435 5.79787V40ZM4.40774 38.4043H30.7502V5.79787C30.7502 5.53191 30.5377 5.31915 30.2722 5.31915H4.88573C4.62018 5.31915 4.40774 5.53191 4.40774 5.79787V38.4043Z" fill="#0A7AE0"/>
                    <path d="M15.5615 10.8511H8.65723V12.4468H15.5615V10.8511Z" fill="#0A7AE0"/>
                    <path d="M15.5615 17.9787H8.65723V19.5745H15.5615V17.9787Z" fill="#0A7AE0"/>
                    <path d="M15.5615 24.6277H8.65723V26.2234H15.5615V24.6277Z" fill="#0A7AE0"/>
                    <path d="M15.5615 31.8085H8.65723V33.4043H15.5615V31.8085Z" fill="#0A7AE0"/>
                    <path d="M26.5547 10.8511H19.6504V12.4468H26.5547V10.8511Z" fill="#0A7AE0"/>
                    <path d="M26.5547 17.9787H19.6504V19.5745H26.5547V17.9787Z" fill="#0A7AE0"/>
                    <path d="M26.5547 24.6277H19.6504V26.2234H26.5547V24.6277Z" fill="#0A7AE0"/>
                    <path d="M26.5547 31.8085H19.6504V33.4043H26.5547V31.8085Z" fill="#0A7AE0"/>
                    <path d="M27.3516 5.26596H7.86035V1.11702C7.86035 0.478723 8.39145 0 8.97566 0H26.1832C26.8205 0 27.2985 0.531915 27.2985 1.11702V5.26596H27.3516ZM9.45364 3.67021H25.7583V1.59574H9.45364V3.67021Z" fill="#0A7AE0"/>
                    <path d="M50.6667 38.4043H0V40H50.6667V38.4043Z" fill="#0A7AE0"/>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="50.6667" height="40" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </Icon>,
    },
    {
        name: 'size',
        isExpandable: false,
        icon: <Icon style={{ marginBottom: '32px' }} modifiers={[ 'small' ]}>
            <svg width="46" height="40" viewBox="0 0 46 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0)">
                    <path d="M43.8307 23.7955C42.4502 22.3843 40.5274 21.5571 38.5552 21.5084C38.4073 21.5084 38.3087 21.5571 38.1608 21.6057C38.0622 21.6544 37.9636 21.7517 37.865 21.8491C37.4213 22.4817 36.8296 22.9683 36.1394 23.3089C35.4491 23.6495 34.7096 23.8442 33.9207 23.8442C33.1319 23.8442 32.3923 23.6982 31.7021 23.4062C31.0118 23.1143 30.3709 22.6276 29.8779 22.0437C29.6313 21.7517 29.4341 21.5084 28.9904 21.5571C27.7085 21.6544 26.4266 22.0437 25.342 22.725L25.2927 22.6763C24.9968 23.1143 24.6024 23.5036 24.1094 23.7955C25.0954 25.0121 25.6871 26.5206 25.6871 28.1264V36.7882C25.9829 36.6909 26.328 36.6422 26.7225 36.6422C26.9197 36.6422 27.1169 36.6422 27.3141 36.6909V36.5936H45.1619C45.3591 36.5936 45.6056 36.4963 45.7535 36.3503C45.9014 36.2043 46.0001 36.0096 46.0001 35.7663V29.051C46.0001 27.1045 45.2112 25.2067 43.8307 23.7955ZM44.3237 34.9391H27.3141V28.0778C27.3141 26.6666 26.969 25.2554 26.2787 24.0388C27.0183 23.5522 27.8564 23.2602 28.7439 23.1629C29.3848 23.8928 30.1737 24.4768 31.0118 24.8661C31.8993 25.2554 32.8361 25.45 33.8221 25.45C34.8082 25.45 35.745 25.2554 36.6324 24.8174C37.5199 24.4281 38.3087 23.8442 38.9004 23.1143C40.3795 23.2602 41.76 23.9415 42.7953 25.0121C43.7814 26.0826 44.373 27.5425 44.373 29.0023V34.9391H44.3237Z" fill="#16BEFF"/>
                    <path d="M27.0674 14.1118V11.1435C27.0674 9.34299 27.8069 7.63983 29.0888 6.32596C30.3707 5.06075 32.1456 4.33083 33.9698 4.33083C35.7941 4.33083 37.5197 5.06075 38.8509 6.32596C40.1328 7.59117 40.8723 9.34299 40.8723 11.1435V14.1118C40.8723 15.9123 40.1328 17.6155 38.8509 18.9294C37.569 20.1946 35.7941 20.9245 33.9698 20.9245C32.1456 20.9245 30.42 20.1946 29.0888 18.9294C27.7576 17.6155 27.0674 15.9123 27.0674 14.1118ZM39.1467 14.1118V11.1435C39.1467 9.78095 38.6044 8.46708 37.6183 7.49384C36.6322 6.52061 35.301 5.98533 33.9205 5.98533C32.5401 5.98533 31.2089 6.52061 30.2228 7.49384C29.2367 8.46708 28.6944 9.78095 28.6944 11.1435V14.1118C28.6944 15.4744 29.2367 16.7882 30.2228 17.7615C31.2089 18.7347 32.5401 19.27 33.9205 19.27C35.301 19.27 36.6322 18.7347 37.6183 17.7615C38.6044 16.7882 39.1467 15.4744 39.1467 14.1118Z" fill="#16BEFF"/>
                    <path d="M44.1754 20.8271H41.3158C40.6748 20.8271 40.1325 20.3405 40.0339 19.7079L39.1957 14.8417L40.5762 14.5984L39.6395 13.2846C35.0049 12.9926 32.5891 11.0948 31.3565 9.53761C29.976 7.78578 29.7788 6.03396 29.7295 5.9853L31.2086 5.83931L30.469 5.93663L31.2086 5.83931C31.2579 6.08262 31.9974 11.5327 40.0339 11.8734H40.4283L45.2107 18.832C45.5066 19.2213 45.5066 19.7566 45.26 20.1459C45.0628 20.5838 44.6684 20.8271 44.1754 20.8271ZM41.4144 19.3673H43.8302L40.6748 14.7444L41.4144 19.3673Z" fill="#16BEFF"/>
                    <path d="M27.9057 13.3331L27.8564 11.8733C31.0119 11.776 31.357 9.48885 31.4063 9.24554L32.8854 9.39153C32.6882 10.7054 31.5049 13.2358 27.9057 13.3331Z" fill="#16BEFF"/>
                    <path d="M21.3973 10.9974V7.59108C21.3973 5.54729 20.5591 3.64948 19.1293 2.18962C17.6502 0.778429 15.7274 -0.000160217 13.6566 -0.000160217C11.5859 -0.000160217 9.66307 0.778429 8.18397 2.23828C6.75417 3.64948 5.91602 5.59595 5.91602 7.59108V10.9974C5.91602 13.0412 6.75417 14.939 8.18397 16.3989C9.61376 17.8101 11.5859 18.6373 13.6566 18.6373C15.7274 18.6373 17.6502 17.8587 19.1293 16.3989C20.5591 14.9877 21.3973 13.0412 21.3973 10.9974ZM7.54303 10.9974V7.59108C7.54303 5.98524 8.18397 4.47673 9.31794 3.3575C10.4519 2.23828 12.0296 1.60568 13.6073 1.60568C15.2343 1.60568 16.7627 2.23828 17.8967 3.3575C19.0307 4.47673 19.6716 5.98524 19.6716 7.59108V10.9974C19.6716 12.6032 19.0307 14.1118 17.8967 15.231C16.7627 16.3502 15.2836 17.0315 13.6566 17.0315C12.0296 17.0315 10.5012 16.3989 9.36725 15.2796C8.23327 14.1118 7.59233 12.6032 7.54303 10.9974Z" fill="#0A7AE0"/>
                    <path d="M27.314 34.939V28.0777C27.314 26.6665 26.9689 25.2553 26.2787 24.0388C26.2787 23.9901 25.687 23.0656 25.3419 22.6763C24.6024 21.7517 23.6656 21.0218 22.6302 20.4865C21.5949 19.9512 20.4116 19.6592 19.2283 19.5619C18.7846 19.5619 18.5874 19.8052 18.3408 20.0972C17.7985 20.7785 17.059 21.3137 16.2208 21.703C15.3826 22.0923 14.4952 22.2383 13.6077 22.2383C12.7203 22.2383 11.8328 21.995 11.0439 21.6057C10.2551 21.2164 9.56484 20.6325 9.02251 19.9026C8.9239 19.8052 8.82529 19.7079 8.72669 19.6592C8.62808 19.6106 8.48017 19.5619 8.33226 19.5619C6.11361 19.6106 3.94427 20.5352 2.41586 22.141C2.21865 22.3356 2.07074 22.4816 1.92283 22.6763C1.82422 22.7736 1.33119 23.4062 0.986066 24.0388C0.345123 25.304 0 26.6665 0 28.0777V34.939V36.5449V39.124C0 39.3186 0.0986066 39.5619 0.246517 39.7079C0.394427 39.8539 0.59164 39.9512 0.838156 39.9512H26.5252C26.7224 39.9512 26.9689 39.8539 27.1168 39.7079C27.2647 39.5619 27.3633 39.3673 27.3633 39.124V36.5449L27.314 34.939ZM25.687 38.394H1.62701V28.0777C1.62701 26.3746 2.26795 24.6714 3.45123 23.4062C4.63451 22.141 6.26152 21.3624 7.98714 21.2164C8.67738 22.0437 9.56484 22.7249 10.5509 23.1629C11.537 23.6008 12.6216 23.8442 13.7063 23.8442C14.791 23.8442 15.8757 23.6008 16.8617 23.1629C17.8478 22.7249 18.7353 22.0437 19.4255 21.2164C21.1018 21.4111 22.6795 22.2383 23.8135 23.4549C24.9475 24.7201 25.5884 26.3259 25.5884 28.0291V38.394H25.687Z" fill="#0A7AE0"/>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="46" height="40" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </Icon>,
    },
    {
        name: 'work_type',
        arrayTitle: 'industries',
        stateObjectName: 'industries',
        fieldName: 'name',
        isExpandable: true,
        icon: <Icon style={{ marginBottom: '32px' }} modifiers={[ 'small' ]}>
            <svg width="51" height="40" viewBox="0 0 51 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M42.3821 18.7075H40.6054V15.1096C40.6054 14.7987 40.3389 14.5322 40.028 14.5322H36.2969V12.7555H40.028C41.3161 12.7555 42.3821 13.8215 42.3821 15.1096V18.7075Z" fill="#0A7AE0"/>
                <path d="M7.77965 35.453H6.00293V15.0652C6.00293 13.7771 7.06896 12.7111 8.35708 12.7111H11.2887V14.4878H8.35708C8.04615 14.4878 7.77965 14.7543 7.77965 15.0652V35.453Z" fill="#0A7AE0"/>
                <path d="M12.1777 25.4589H10.401V10.0903H6.66992V2.89462C6.71434 2.13951 7.33619 0.718141 9.29058 0.496052C9.91243 0.407216 10.4899 0.584888 10.9785 0.984649C12.0445 1.87301 12.1777 3.56089 12.2222 3.73856V3.78297V25.4589H12.1777ZM8.44664 8.3136H10.4454V3.87181C10.3566 3.51647 10.1789 2.67253 9.82359 2.3616C9.73476 2.31718 9.64592 2.27277 9.46825 2.27277C8.62431 2.3616 8.44664 2.8502 8.44664 3.02787V8.3136Z" fill="#16BEFF"/>
                <path d="M37.1845 18.7074H35.4078V3.56088C35.4078 3.51646 35.3634 3.20554 35.008 2.67252C34.7859 2.40601 33.942 2.22834 33.2757 2.27276H33.2313H9.37891V0.496045H33.1869C33.6311 0.451627 35.5855 0.451627 36.4294 1.65091C37.1401 2.6281 37.1845 3.33879 37.1845 3.6053V18.7074Z" fill="#16BEFF"/>
                <path d="M39.8943 38.0292H8.49089C7.69137 38.0292 6.89185 37.6294 6.44767 36.9632C6.00349 36.2969 5.87023 35.4529 6.13674 34.6978L6.18116 34.6534L13.6434 17.819H49L42.2485 36.3857C41.8931 37.3629 40.9604 38.0292 39.8943 38.0292ZM7.82462 35.3197C7.73579 35.5418 7.7802 35.7639 7.91346 35.9415C8.04671 36.1192 8.2688 36.2525 8.49089 36.2525H39.8943C40.2053 36.2525 40.4718 36.0748 40.5606 35.7639L46.4238 19.5958H14.7982L7.82462 35.3197Z" fill="#0A7AE0"/>
            </svg>
        </Icon>,
    },
    {
        name: 'area',
        arrayTitle: 'geoRegions',
        stateObjectName: 'geoRegions',
        fieldName: 'regionName',
        isExpandable: true,
        icon: <Icon style={{ marginBottom: '32px' }} modifiers={[ 'small' ]}>
            <svg width="40" height="40" viewBox="0 0 40 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M35.6814 41.1847C35.9079 41.1902 36.12 41.0741 36.2374 40.8803C36.3367 40.7178 36.3387 40.5139 36.2425 40.3496L35.6814 41.1847ZM35.6814 41.1847H4.78075M35.6814 41.1847H4.78075M4.78075 41.1847C4.55446 41.1901 4.34257 41.0739 4.22532 40.8803M4.78075 41.1847L4.22532 40.8803M4.22532 40.8803C4.12597 40.7178 4.12402 40.5139 4.22014 40.3496L4.22532 40.8803ZM8.31225 32.4693C7.63005 32.4649 6.99862 32.8293 6.66105 33.4222L6.66067 33.4228L3.12915 39.7404C3.1291 39.7405 3.12905 39.7405 3.129 39.7406C2.81708 40.2918 2.82425 40.9679 3.14782 41.5123C3.48777 42.0902 4.11108 42.4416 4.78136 42.4335H35.6808C36.3509 42.4414 36.974 42.09 37.3138 41.5123C37.6373 40.9678 37.6445 40.2919 37.3326 39.7407C37.3325 39.7406 37.3324 39.7405 37.3324 39.7404L33.8003 33.4228L33.7999 33.4222L32.1502 32.5693V32.4693H32.1499H28.0567H27.9567V32.5693V33.718V33.818H28.0567L32.1502 33.818L32.1521 33.818C32.3456 33.8142 32.5261 33.9149 32.6246 34.0814C32.6248 34.0817 32.625 34.082 32.6252 34.0823L36.1552 40.3984L36.1562 40.4001C36.2338 40.5326 36.2322 40.6971 36.152 40.8281L36.1518 40.8285C36.0531 40.9917 35.8745 41.0894 35.6838 41.0848V41.0847H35.6814H4.78075V41.0847L4.77837 41.0848C4.58792 41.0893 4.40956 40.9915 4.31086 40.8285L4.31064 40.8281C4.23051 40.6971 4.22893 40.5326 4.30646 40.4001L4.30743 40.3984L7.83804 34.0814C7.93636 33.915 8.11669 33.8143 8.30995 33.818L8.30995 33.818H8.31186H12.4053H12.5053V33.718V32.5693V32.4693H12.4053H8.31225ZM8.31225 32.4693C8.31236 32.4693 8.31247 32.4693 8.31258 32.4693L8.31193 32.5693V32.4693H8.31225Z" fill="#0A7AE0" stroke="#0A7AE0" strokeWidth="0.2"/>
                <path d="M31.393 16.7272C31.393 18.1234 30.7248 20.0857 29.6026 22.3418C28.4971 24.5644 27.014 26.9512 25.5176 29.1563C24.0232 31.3581 22.5272 33.3619 21.4039 34.8161C20.9402 35.4163 20.5406 35.9222 20.2315 36.3089C19.9224 35.9222 19.5228 35.4163 19.0591 34.8161C17.9357 33.3619 16.4397 31.3581 14.9454 29.1563C13.4489 26.9512 11.9659 24.5644 10.8604 22.3418C9.73817 20.0857 9.06992 18.1234 9.06992 16.7272C9.06992 10.7712 14.0431 5.9 20.2315 5.9C26.4198 5.9 31.393 10.7712 31.393 16.7272Z" stroke="#16BEFF" strokeWidth="1.8"/>
                <circle cx="20.2317" cy="16.2" r="4.26924" stroke="#16BEFF" strokeWidth="1.8"/>
            </svg>
        </Icon>,
    },
    {
        name: 'industries',
        arrayTitle: 'sectors',
        stateObjectName: 'sectors',
        fieldName: 'displayName',
        isExpandable: true,
        displayTotal: true,
        icon: <Icon style={{ marginBottom: '32px' }} modifiers={[ 'small' ]}>
            <svg width="43" height="40" viewBox="0 0 43 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M31.2439 18.5366H21V14.1463L11.2439 18.5366V14.1463L1 18.5366V39.0244H31.2439" stroke="#0A7AE0" strokeWidth="1.8"/>
                <path d="M8.72812 30.2439C8.72812 29.6633 9.19877 29.1927 9.77934 29.1927H21.4867C22.0672 29.1927 22.5379 29.6633 22.5379 30.2439V39.1H8.72812V30.2439Z" stroke="#0A7AE0" strokeWidth="1.8"/>
                <path d="M30.2379 39.1L32.1014 0.9H40.1431L42.0065 39.1H30.2379Z" stroke="#16BEFF" strokeWidth="1.8" strokeLinejoin="round"/>
                <line x1="31.2441" y1="7.88049" x2="41.0002" y2="7.88049" stroke="#16BEFF" strokeWidth="1.8"/>
            </svg>
        </Icon>,
    },
]

@withTranslation('', { wait: true })
export default class BusinessUnitDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeSegmentationTab: 0,
        }
    }

    setActiveSegmentationTab = tabId => {
        this.setState({ activeSegmentationTab: tabId })
    }

    getSegmentationArray = index => {
        const { businessUnit } = this.props
        if (index === 0) {
            return businessUnit.clientTypes
        }
        if (index === 2) {
            return businessUnit.industries
        }
        if (index === 3) {
            return businessUnit.geoRegions
        }
        if (index === 4) {
            return businessUnit.sectors
        }
    }

    determineListRowCount = (length, columnCount, marginalRowCount) => length / columnCount + marginalRowCount

    returnSegmentationArrayTitle = (segmentationStateObject, businessUnitArray, fieldName, displayTotal = false) => {
        const { t } = this.props
        if (businessUnitArray.length === 0) {
            return t('partner_contracts.counts.none')
        }
        if (Object.values(segmentationStateObject).length !== 0 && Object.values(segmentationStateObject).length === businessUnitArray.length) {
            return t('partner_contracts.counts.all')
        }
        if (businessUnitArray.length > 1) {
            if (displayTotal) {
                return businessUnitArray.length
            }
            return t('partner_contracts.counts.multiple')
        }
        return businessUnitArray[0][fieldName]
    }

    returnFormattedRevenueRange = () => {
        const { t } = this.props
        const minimum = this.props.businessUnit.revenues.minimumRevenue
        const maximum = this.props.businessUnit.revenues.maximumRevenue

        if (minimum === 0 && maximum >= 2000000000) {
            return t('partner_contracts.counts.all')
        }
        return `${largeNumberAbbreviator(minimum)}-${largeNumberAbbreviator(maximum)}`
    }

    render() {
        const { activeSegmentationTab } = this.state
        const { width, businessUnit, industries, geoRegions, leadTypes, sectors, t } = this.props

        if (width > bkpInt.medium) {
            return <Box.Content>
                <Flex style={{ padding: '53px 38px 0' }}>
                    {segmentationTabs.map((tab, i) => <SegmentationTab
                        key={'tab_' + tab.name}
                        onClick={tab.isExpandable ? () => this.setActiveSegmentationTab(i) : null}
                        responsiveModifiers={{ active: [ 'active' ] }}
                        modifiers={[ !tab.isExpandable && 'disabled' ]}
                        size={activeSegmentationTab === i ? 'active' : ''}
                    >
                        {tab.icon}
                        <Text modifiers={[ 'smallHigh', 'opacityHigh' ]}>{t(`partner_contracts.segmentation_descriptions.${tab.name}`)}</Text>
                        <Text
                            modifiers={[ 'small', 'bold' ]}
                            responsiveModifiers={{ active: [ 'accent' ] }}
                            size={activeSegmentationTab === i ? 'active' : ''}
                        >
                            {tab.isExpandable ? this.returnSegmentationArrayTitle(this.props[tab.stateObjectName], businessUnit[tab.arrayTitle], tab.fieldName) : this.returnFormattedRevenueRange()}
                        </Text>
                    </SegmentationTab>)}
                </Flex>
                <Box.Line />
                <Box.Content style={{ padding: '32px 58px 36px' }}>
                    <List rows={this.determineListRowCount(this.getSegmentationArray(activeSegmentationTab).length, 4, 3)}>
                        {this.getSegmentationArray(activeSegmentationTab).map((el, i) => <List.Item key={`li_${i}`}><Text modifiers={[ 'smallHigh', 'opacityHigh' ]}>{el.name || el.regionName || el.displayName || el.title || el}</Text></List.Item>)}
                    </List>
                </Box.Content>
            </Box.Content>
        }

        return <Box.Content style={{ margin: '30px 23px' }}>
            <Box.Content>
                <Text modifiers={[ 'tiny', 'lightGrey', 'uppercase' ]}>{t('partner_contracts.segmentation_descriptions.company_type')}</Text>
                {businessUnit.clientTypes.length > 0 ? businessUnit.clientTypes.map((el, i) => <BlockLabel key={'label_' + i} modifiers={[ 'space', 'tiny' ]}>{el.title}</BlockLabel>) : <BlockLabel modifiers={[ 'space', 'tiny' ]}>{t('partner_contracts.counts.none')}</BlockLabel>}
            </Box.Content>
            <Box.Content>
                <Text modifiers={[ 'tiny', 'lightGrey', 'mT_1', 'uppercase' ]}>{t('partner_contracts.segmentation_descriptions.size')}</Text>
                <BlockLabel modifiers={[ 'space', 'tiny' ]}>{this.returnFormattedRevenueRange()}</BlockLabel>
            </Box.Content>
            <Box.Content>
                <Text modifiers={[ 'tiny', 'lightGrey', 'mT_1', 'uppercase' ]}>{t('partner_contracts.segmentation_descriptions.work_type')}</Text>
                {businessUnit.industries.length > 0 ? businessUnit.industries.map((el, i) => <BlockLabel key={'label_' + i} modifiers={[ 'space', 'tiny' ]}>{el.name}</BlockLabel>) : <BlockLabel modifiers={[ 'space', 'tiny' ]}>{t('partner_contracts.counts.none')}</BlockLabel>}
            </Box.Content>
            <Box.Content>
                <Text modifiers={[ 'tiny', 'lightGrey', 'mT_1', 'uppercase' ]}>{t('partner_contracts.segmentation_descriptions.area')}</Text>
                {businessUnit.geoRegions.length > 0 ? businessUnit.geoRegions.map((el, i) => <BlockLabel key={'label_' + i} modifiers={[ 'space', 'tiny' ]}>{el.regionName}</BlockLabel>) : <BlockLabel modifiers={[ 'space', 'tiny' ]}>{t('partner_contracts.counts.none')}</BlockLabel>}
            </Box.Content>
            <Box.Content>
                <Text modifiers={[ 'tiny', 'lightGrey', 'mT_1', 'uppercase' ]}>{t('partner_contracts.segmentation_descriptions.industries')}</Text>
                {businessUnit.sectors.length > 0 ? businessUnit.sectors.map((el, i) => <BlockLabel key={'label_' + i} modifiers={[ 'space', 'tiny' ]}>{el.displayName}</BlockLabel>) : <BlockLabel modifiers={[ 'space', 'tiny' ]}>{t('partner_contracts.counts.none')}</BlockLabel>}
            </Box.Content>
        </Box.Content>
    }
}
