import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Field, reduxForm } from 'redux-form'
import * as certifications from '../../actions/certifications'
import { currentPartnerSelector } from '../../selectors/auth'
import ActionCheckBox from '../utils/ActionCheckBox'
import { primaryIndustrySelector } from '../../selectors/industries'
import PageTitle from '../utils/PageTitle'
import { Card } from '../blocks/Card'

@connect(
    state => ({
        partner: currentPartnerSelector(state),
        certifications: Object.values(state.entities.certifications),
        partnerCertificationIds: state.pages.certifications.partnerCertificationIds,
    }),
    dispatch => ({
        getCertifications: bindActionCreators(certifications.getCertifications, dispatch),
        getPartnerCertifications: bindActionCreators(certifications.getPartnerCertifications, dispatch),
        addPartnerCertification: bindActionCreators(certifications.addPartnerCertification, dispatch),
        removePartnerCertification: bindActionCreators(certifications.removePartnerCertification, dispatch),
    })
)
@withTranslation('', { wait: true })
@reduxForm({
    form: 'partner',
})
export default class CertificationPage extends React.Component {
    componentDidMount() {
        const partner = this.props.partner

        this.props.getCertifications({ geo_code: partner.geo.code, industry_id: partner.industry.id })
        this.props.getPartnerCertifications(this.props.partner.id)
    }

    handleCertificationClick(value, checked) {
        const partnerId = this.props.partner.id

        if (checked) {
            return this.props.addPartnerCertification(partnerId, value)
        }

        this.props.removePartnerCertification(partnerId, value)
    }

    render() {
        const {
            t,
            certifications,
            partnerCertificationIds,
        } = this.props

        return (
            <Card>
                <Card.Content>
                    <PageTitle identifier="certifications.header" />
                    <Card.Title>{t('certifications.header')}</Card.Title>
                    <Card.Intro>{t('certifications.intro')}</Card.Intro>

                    <Card.Line modifiers={[ 'mY_3', 'cardWidth' ]} />

                    <div className="certifications row">
                        {certifications.map(certification => {
                            return (
                                <div key={certification.id} className="col-sm-6">
                                    <ActionCheckBox onClick={this.handleCertificationClick.bind(this)} value={certification.id} text={certification.title} checked={partnerCertificationIds.has(certification.id)}/>
                                </div>
                            )
                        })}
                    </div>
                </Card.Content>
            </Card>
        )
    }
}
