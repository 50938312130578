import React from 'react'
import { toastr } from 'react-redux-toastr'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
const Text = styled.span`
  cursor: copy;
`

@withTranslation('', { wait: true })
export class CopyToClipboard extends React.PureComponent {
    copyToClipboard = e => {
        let textField = document.createElement('textarea')
        const text = e.target.textContent
        textField.innerText = text
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        toastr.info(this.props.t('toaster_message.copied') + ' ' + text)
        textField.remove()
    }

    render() {
        return <Text onClick={this.copyToClipboard}>{this.props.label}</Text>
    }
}

