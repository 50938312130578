import { LeadResource } from 'ageras-api-client/src/api'
import Card from 'components/src/Card'
import LeadDetail, { Product } from 'components/src/Marketplace/LeadDetail'
import Prompt from 'components/src/Marketplace/Prompt'
import { ModalProps } from 'components/src/modals'
import Modal from 'components/src/modals/Modal'
import React, { useCallback, useState } from 'react'
import { Briefcase, User } from 'react-feather'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'


const ScrollableCardContent = styled(Card.Content) `
  overflow: scroll;
  width: 100%;
`

interface DetailPromptProps extends ModalProps {
    lead: LeadResource
    premiumAccessLabel?: string
    newLeadLabel?: string
    lastChanceLabel?: string
    onContinue: () => void
    revenueCurrency: string
    isIndividualType: boolean
}

function DetailPrompt({
    onHide,
    zIndex,
    lead,
    premiumAccessLabel,
    newLeadLabel,
    lastChanceLabel,
    onContinue,
    revenueCurrency,
    isIndividualType,
}: DetailPromptProps) {
    const { t } = useTranslation('translation')
    const [ submitting, setSubmitting ] = useState<boolean>(false)

    const handleContinue = useCallback(async () => {
        try {
            setSubmitting(true)
            await onContinue()
            setSubmitting(false)
            onHide()
        } catch (e) {
            setSubmitting(false)
        }
    }, [ onContinue, onHide ])

    const revenueAmount = lead.attributes ? Object.values(lead.attributes).find(attr => attr.identifier === 'annual_revenue')?.selected?.[0]?.title : 0

    const isBusinessLead = !isIndividualType

    return <Modal onHide={onHide} zIndex={zIndex} size="extraSmall">
        <Prompt>
            <ScrollableCardContent py={3}>
                <LeadDetail
                    icon={isBusinessLead ? <Briefcase /> : <User />}
                    revenueAmount={revenueAmount as unknown as number}
                    disabled={submitting}
                    revenueCurrency={revenueCurrency}
                    title={lead.title!}
                    cityName={lead.geo?.cityName!}
                    zipCode={lead.geo?.zipCode!}
                    customerType={lead.customerInfo?.customerType?.identifier!}
                    caseTitle={lead.title!}
                    hide={onHide}
                    description={lead.description}
                    products={lead.products?.map(product => product as Product)}
                    continueAction={handleContinue}
                    premiumAccessLabel={premiumAccessLabel}
                    newLeadLabel={newLeadLabel}
                    lastChanceLabel={lastChanceLabel}
                    incomeRevenueLabelString={isBusinessLead ? t('marketplace.lead.revenue') : t('marketplace.lead.income')}
                    fillingYearLabelString={t('marketplace.lead.filling_year')}
                    actionLabelContinueString={t('marketplace.lead.continue')}
                    descriptionLabelString={t('marketplace.lead.description')}
                    caseLabelString={t('marketplace.lead.case')}
                    productLabelString={t('marketplace.lead.products')}
                />
            </ScrollableCardContent>
        </Prompt>
    </Modal>
}

export default DetailPrompt
