import * as actions from '../../actions/segmentation'
import { GET_PARTNER_SECTORS, GET_PARTNER_SECTORS_FAIL, GET_PARTNER_SECTORS_SUCCESS } from '../../actions/sectors'
import {
    GET_PARTNER_CERTIFICATIONS,
    GET_PARTNER_CERTIFICATIONS_FAIL,
    GET_PARTNER_CERTIFICATIONS_SUCCESS,
} from '../../actions/certifications'

const initialState = {
    leadTypeIds: new Set([]),
    industryIds: new Set([]),
    partnerSectorIds: new Set([]),
    partnerCertificationIds: new Set([]),
    isLoadingLeadTypes: false,
    isLoadingIndustries: false,
    isLoadingPartnerSectors: false,
    isLoadingPartnerCertification: false,
    current_page: 1,
    pages: null,
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case actions.GET_LEAD_TYPES:
            return {
                ...state,
                isLoadingLeadTypes: true,
            }
        case actions.GET_LEAD_TYPES_SUCCESS:
            return {
                ...state,
                isLoadingLeadTypes: false,
            }
        case actions.GET_LEAD_TYPES_FAIL:
            return {
                ...state,
                isLoadingLeadTypes: false,
            }
        case actions.GET_PARTNER_LEAD_TYPES:
            return {
                ...state,
                isLoadingLeadTypes: true,
            }
        case actions.GET_PARTNER_LEAD_TYPES_SUCCESS:
            return {
                ...state,
                leadTypeIds: new Set(action.response.result),
                isLoadingLeadTypes: false,
            }
        case actions.GET_PARTNER_LEAD_TYPES_FAIL:
            return {
                ...state,
                isLoadingLeadTypes: false,
            }
        case actions.GET_INDUSTRIES:
            return {
                ...state,
                isLoadingIndustries: true,
            }
        case actions.GET_INDUSTRIES_SUCCESS:
            return {
                ...state,
                isLoadingIndustries: false,
            }
        case actions.GET_INDUSTRIES_FAIL:
            return {
                ...state,
                isLoadingIndustries: false,
            }
        case actions.GET_PARTNER_INDUSTRIES:
            return {
                ...state,
                isLoadingIndustries: true,
            }
        case actions.GET_PARTNER_INDUSTRIES_SUCCESS:
            return {
                ...state,
                industryIds: new Set(action.response.result),
                isLoadingIndustries: false,
            }
        case actions.GET_PARTNER_INDUSTRIES_FAIL:
            return {
                ...state,
                isLoadingIndustries: false,
            }
        case actions.ADD_INDUSTRY_TO_PARTNER_SUCCESS:
            return {
                ...state,
                industryIds: new Set([
                    ...state.industryIds,
                    action.response.result,
                ]),
            }
        case actions.REMOVE_INDUSTRY_FROM_PARTNER_SUCCESS:
            const industrySet = new Set(state.industryIds)
            industrySet.delete(action.industryId)
            return {
                ...state,
                industryIds: industrySet,
            }
        case actions.ADD_LEAD_TYPE_TO_PARTNER_SUCCESS:
            return {
                ...state,
                leadTypeIds: new Set([
                    ...state.leadTypeIds,
                    action.response.result,
                ]),
            }
        case actions.REMOVE_LEAD_TYPE_FROM_PARTNER_SUCCESS:
            const leadTypeSet = new Set(state.leadTypeIds)
            leadTypeSet.delete(action.leadTypeId)
            return {
                ...state,
                leadTypeIds: leadTypeSet,
            }
        case GET_PARTNER_SECTORS:
            return {
                ...state,
                isLoadingPartnerSectors: true,
            }
        case GET_PARTNER_SECTORS_SUCCESS:
            return {
                ...state,
                partnerSectorIds: new Set(action.response.result),
                isLoadingPartnerSectors: false,
            }
        case GET_PARTNER_SECTORS_FAIL:
            return {
                ...state,
                isLoadingPartnerSectors: false,
            }
        case GET_PARTNER_CERTIFICATIONS:
            return {
                ...state,
                isLoadingPartnerCertification: true,
            }
        case GET_PARTNER_CERTIFICATIONS_SUCCESS:
            return {
                ...state,
                partnerCertificationIds: new Set(action.response.result),
                isLoadingPartnerCertification: false,
            }
        case GET_PARTNER_CERTIFICATIONS_FAIL:
            return {
                ...state,
                isLoadingPartnerCertification: false,
            }
        default:
            return state
    }
}
