import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import CurrentUser from '../auth/CurrentUser'
import { punchCountSelector } from '../../selectors/coupons'
import { currentPartnerSelector, currentPartnerUserSelector } from '../../selectors/auth'
import {
    hasMenuAccessSelector,
    hasCommunicationAccessSelector,
    hasMarketplaceSignUpAccessSelector,
    hasMarketplaceLeadAccessSelector,
    hasMarketplaceWithoutFeesAccessSelector
} from '../../selectors/access'
import NotificationDropdown from '../../components/layout/NotificationDropdown'
import GlobalWarnings from './GlobalWarnings'
import { Container } from '../../components/layout/layout'
import { Flex } from '../../components/elements/Flex'
import { Impact, Text } from '../../components/elements/text/Text'
import { logout } from '../../actions/auth'
import { replace } from 'connected-react-router'
import styled from 'styled-components'
import { bkpInt, breakpoints, colors } from '../../theme/variables_new'
import { Image } from '../../components/layout/images'
import WindowSizeListener from 'react-window-size-listener'
import { getProjects } from '../../actions/projects'
import BalanceWidget from '../../components/pages/Marketplace/BalanceWidget'

// Topbar
const TopBar = styled.div`
    background-color: ${colors.darkBlue};
    color: ${colors.white};
    height: 90px;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: ${breakpoints.small}) {
        padding-left: 18px;
        padding-right: 12px;
    }
`

// Info bar
const InformationBar = styled.div`
    height: 55px;
    width: 100%;
    background: ${colors.lightBlue};
    text-align: center;
    line-height: 55px;
    color: ${colors.white};
    text-overflow: ellipsis;
    overflow: hidden;
`

//Navigation
const Navigation = styled.div`
    display: flex;
    align-items; center;
`

const Header = styled.div`
    position: relative;
    background-color: ${colors.white};
`

const MobileProgressBar = styled.ul`
    max-width: 200px;
    margin: 0 auto;
    padding: 0 18px;
    height: 2em;
    font-size: 10px; /* change font size only to scale*/
    display: flex;
`

const MobileBarItem = styled.li`
    height: 100%;
    list-style: none;
    position: relative;
    margin: 0;
    padding: 0;

    &:not(:last-of-type) {
        flex-grow: 1;
    }

    &:last-of-type {
        width: 2em;
    }

    &:last-of-type span {
        display: none;
    }

    &:not(:last-of-type) span {
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background: ${colors.blue};
        height: .4em;
        z-index: 1;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        background: ${colors.blue};
        width: 2em;
        height: 2em;
        border-radius: 2em;
        z-index: 2;
    }

    ${({ active }) => active && `
        &:before {
            background: ${colors.blue};
        }

        & > span,
        & ~ li span,
        & ~ li:before {
            background: ${colors.grey} !important;
        }
    `}
`

const DesktopBarItem = styled.div`
    position: relative;
    padding-left: 2.8em;
    font-weight: bold;
    padding-top: 6px;
    padding-bottom: 6px;
    color: ${colors.fontLight};
    font-weight: 500

    &:not(:last-of-type) {
        padding-right: 47px;
        margin-right: 40px;
    }

    &:before {
        font-weight: bold
        content: '${props => props.index}';
        text-align: center;
        display: inline-block;
        position: absolute;
        line-height: 33px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 2em;
        height: 2em;
        border-radius: 2em;

        background-color: ${colors.lightGrey};
        color: ${colors.grey};
    }

    ${({ active }) => active && `
        color: ${colors.blue};

        &:before {
            background: ${colors.blue};
            color: ${colors.white};
        }
    `}
`

const Arrow = styled.div`
    display: inline-block;
    position: absolute;
    right: 0;
    top: 50%;
    width: 7px;
    color: ${colors.grey};
    transform: translateY(-50%);
`

const MenuTab = styled.div`
    display: inline-block;
    cursor: pointer;
    padding: 24px;

    ${({ active }) => active && `
        color: ${colors.blue};
        border-bottom: 4px solid ${colors.blue};
        padding-bottom: 20px;
    `}
`

export const PageHeader = ({
    menuItems,
    size,
    demoMode,
    location,
}) => {
    const filteredMenuItems = menuItems.filter(item => !item.hidden)
    const activeTab = filteredMenuItems.find(item => item.pathname.startsWith(location.pathname.slice(0, location.pathname.indexOf('/', 2))))

    if (demoMode) {
        return <Header>
            <Container modifiers={[ 'widthFull' ]} style={{ paddingTop: '22px', paddingBottom: '22px' }}>
                {size === 'small' && <div>
                    <Impact modifiers={[ 'small', 'blue', 'center', 'mB_2' ]}>{activeTab && activeTab.title}</Impact>
                    <MobileProgressBar>
                        {filteredMenuItems.map(item => <MobileBarItem active={Boolean(activeTab && activeTab.pathname === item.pathname)}><span /></MobileBarItem>)}
                    </MobileProgressBar>
                </div>}
                {size !== 'small' && <Flex modifiers={[ 'justifyCenter', 'alignCenter' ]}>
                    {filteredMenuItems.map((item, index) => <DesktopBarItem key={index} active={Boolean(activeTab && activeTab.pathname === item.pathname)} index={index + 1} url={'images/arrow.svg'}>
                        <Link to={item.pathname + (typeof item.search === 'string' ? item.search : '')}>{item.title}</Link>
                        {index + 1 !== filteredMenuItems.length && <Arrow>
                            <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.15" d="M0.27583 11.1122C0.080995 11.3383 -0.0173211 11.6337 0.00250926 11.9333C0.0223396 12.233 0.158692 12.5124 0.381572 12.71C0.604451 12.9077 0.895599 13.0074 1.19097 12.9873C1.48633 12.9672 1.76173 12.8289 1.95656 12.6028L6.65371 7.12614C6.87663 6.87518 7 6.54932 7 6.21149C7 5.87366 6.87663 5.54781 6.65371 5.29684L1.98995 0.328363C1.78141 0.118049 1.4993 0 1.20524 0C0.911185 0 0.629076 0.118049 0.420529 0.328363C0.316203 0.433337 0.233397 0.558228 0.176888 0.695831C0.120379 0.833435 0.091285 0.981027 0.091285 1.1301C0.091285 1.27916 0.120379 1.42676 0.176888 1.56436C0.233397 1.70196 0.316203 1.82685 0.420529 1.93183L4.34966 6.12116C4.39427 6.17267 4.41888 6.2389 4.41888 6.30748C4.41888 6.37605 4.39427 6.44228 4.34966 6.49379L0.27583 11.1122Z" fill="#042D52"/>
                            </svg>
                        </Arrow>}
                    </DesktopBarItem>)}
                </Flex>}
            </Container>
        </Header>
    }

    return <Header>
        <Flex modifiers={[ 'alignCenter', 'overflowX', 'mX_2' ]}>
            {filteredMenuItems.map((item, index) => <MenuTab key={index} active={Boolean(activeTab && activeTab.pathname === item.pathname)}>
                <Link to={item.pathname + (typeof item.search === 'string' ? item.search : '')}>
                    <Text modifiers={[ 'bold', 'small' ]}>{item.title}</Text>
                </Link>
            </MenuTab>)}
        </Flex>
    </Header>
}

export const exclusiveLeadsListHiddenCountries = [ 'us', 'de' ]

@connect(
    state => ({
        location: state.router.location,
        punchesLeft: punchCountSelector(state),
        showMenu: hasMenuAccessSelector(state),
        partner: currentPartnerSelector(state),
        partnerUser: currentPartnerUserSelector(state),
        pageTitle: state.pageTitle,
        authType: state.auth.userType,
        loadingCoupons: state.pages.coupons.isLoading,
        hasCommunicationAccess: hasCommunicationAccessSelector(state),
        verification: Object.keys(state.entities.verifications).length > 0 ? Object.entries(state.entities.verifications)[0][1] : null,
        partnerContracts: state.entities.partnerContracts,
        partnerContractOfferGroups: state.entities.partnerContractOfferGroups,
        hasMarketplaceSignupAccess: hasMarketplaceSignUpAccessSelector(state),
        hasMarketplaceAccess: hasMarketplaceSignUpAccessSelector(state) || hasMarketplaceLeadAccessSelector(state),
        hasMarketplaceWithoutFeesAccess: hasMarketplaceWithoutFeesAccessSelector(state),
    }),
    {
        getProjects,
        logout,
        replace,
    }
)
@withTranslation('', { wait: true })
export default class PartnerPageHeader extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            mobileMenuOpen: false,
            width: 0,
            projectCount: 0,
        }
    }

    async componentDidMount() {
        const projectResponse = await this.props.getProjects({
            partnerId: this.props.partner.id,
            isLeadValidated: 1,
            limit: 0,
        })
        this.setState({
            projectCount: projectResponse?.pagination?.total ?? 0,
        })
    }

    updateWindowWidth = width => this.setState({ width: width })

    returnWindowWidthString = width => {
        if (width <= bkpInt.small) return 'small'
        if (width <= bkpInt.medium) return 'medium'
        return 'large'
    }

    render() {
        const {
            t,
            partner,
            authType,
            hasCommunicationAccess,
            showMenu,
            verification,
            logout,
            location,
            hasNewMessage,
            acceptMode,
            partnerContracts,
            partnerContractOfferGroups,
            hasMarketplaceSignupAccess,
            hasMarketplaceAccess,
            hasMarketplaceWithoutFeesAccess,
            marketplaceEnabledCountries,
        } = this.props

        const demoMode = partner.state === 'demo'

        const storageFilter = localStorage.getItem('projectsFilter')

        const projectFilter = (storageFilter === 'null' || !storageFilter)
            ? ''
            : localStorage.getItem('projectsFilter')

        const partnerHasContracts = Object.values(partnerContracts).length > 0

        const partnerHasContractOffers = Object.values(partnerContractOfferGroups).length > 0

        const partnerHasProjects = this.state.projectCount > 0

        const isEligibleForMarketplaceSignup = hasMarketplaceSignupAccess && marketplaceEnabledCountries.includes(partner.geo.code)

        const isMarketplaceTabHidden = partner => {
            if (hasMarketplaceWithoutFeesAccess) {
                return false
            }

            return !hasMarketplaceAccess || !marketplaceEnabledCountries.includes(partner.geo.code)
        }

        let menuItems = acceptMode ? [] : [
            { title: t('navigation.marketplace'), pathname: '/marketplace', hidden: isMarketplaceTabHidden(partner) },
            { title: t('navigation.projects'), pathname: '/projects', search: projectFilter, hidden: (demoMode || isEligibleForMarketplaceSignup) && !partnerHasProjects },
            { title: t('navigation.marketing'), pathname: '/marketing/profile', hidden: (demoMode || isEligibleForMarketplaceSignup) && !partnerHasProjects },
            { title: t('navigation.account'), pathname: '/account', hidden: (demoMode || isEligibleForMarketplaceSignup) && !partnerHasProjects },
            { title: t('navigation.partner_contract_offers'), pathname: '/partner-offers', hidden: !(demoMode && partnerHasContractOffers) || isEligibleForMarketplaceSignup },
            { title: demoMode ? t('navigation.partner_contracts_demo') : t('navigation.partner_contracts'), pathname: '/partner-contract', hidden: !partnerHasContracts || isEligibleForMarketplaceSignup },
        ]

        if (demoMode) {
            menuItems.unshift({ title: t('navigation.demo'), pathname: '/demo' })
        }

        if(partner.id === 8212) {
            menuItems.push({ title: 'CRM (beta)', url: 'http://crm.ageras.com/login?username=8212&password=2e9f7649295cf0c9ec403e050122ce84' })
        }

        if(!showMenu) {
            menuItems = []
        }

        return <header className="new">
            { authType === 'employee' && <InformationBar>You're currently logged in with your employee account. Your view is different form the partner's view.</InformationBar>}
            <TopBar>
                <Flex modifiers={[ 'justifySpaceBetween', 'alignCenter' ]}>
                    <Image modifiers={[ 'logo' ]}>
                        <Link to="/">
                            <img src="/images/ageras-logo.svg" alt="ageras_for_partners" width={'200px'}/>
                        </Link>
                    </Image>
                    <Flex>
                        {hasMarketplaceAccess && marketplaceEnabledCountries.includes(partner.geo.code) && Object.keys(partner.marketplace).length && <Flex modifiers={[ 'mR_2' ]}>
                            <BalanceWidget partner={partner} hasMarketplaceWithoutFeesAccess={this.props.hasMarketplaceWithoutFeesAccess} />
                        </Flex>}
                        <Navigation>
                            {hasCommunicationAccess ? <NotificationDropdown/> : ''}
                            <CurrentUser size={this.returnWindowWidthString(this.state.width)} acceptMode={acceptMode}/>
                        </Navigation>
                    </Flex>
                </Flex>
            </TopBar>
            {location.pathname !== '/demo' && <PageHeader
                closeMenu={() => this.setState({ mobileMenuOpen: false })}
                mobileMenuOpen={this.state.mobileMenuOpen}
                hasNewMessage={hasNewMessage}
                menuItems={menuItems}
                location={location}
                logout={logout}
                demoMode={demoMode}
                size={this.returnWindowWidthString(this.state.width)}
                t={t}
            />}

            <GlobalWarnings verification={verification} t={t}/>
            <WindowSizeListener onResize={size => this.updateWindowWidth(size.windowWidth)} />
        </header>
    }
}
