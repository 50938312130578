import React from 'react'
import { FormGroup } from 'react-bootstrap'
import { Error } from '../Error'
import { RadioBox } from '../../blocks/RadioBox'
import { Flex } from '../../elements/Flex'
import MetaLabel from '../../elements/text/MetaLabel'

export const RadioInputField = ({ option, input }) =>
    <RadioBox
        {...input}
        id={input.name + '_' + option.id}
        value={option.id}
        checked={input.value == option.id}
    />

const RadioGroup = ({
    className,
    hideLabel,
    options,
    label,
    input,
    meta,
}) =>
    <FormGroup bsClass={'form-group ' + className || ''}>
        {label && <MetaLabel>{label}</MetaLabel>}
        {options.map(option =>
            <Flex
                key={input.name + '_' + option.id}
                modifiers={[ 'alignCenter' ]}
                onClick={() => input.onChange(option.id)}
            >
                <RadioInputField
                    option={option}
                    input={input}
                />
                {hideLabel || <RadioBox.Label>{option.name}</RadioBox.Label> }
            </Flex>
        )}
        <Error meta={meta}/>
    </FormGroup>

export default RadioGroup
