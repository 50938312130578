import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FormattedDate from '../utils/FormattedDate'
import Nl2br from '../utils/Nl2br'
import RatingReplyItem from './RatingReplyItem'
import RatingReplyForm from './RatingReplyForm'
import RatingStarBox from '../utils/RatingStarBox'
import * as access from '../../selectors/access'

@connect(
    state => ({
        featureRatingAccess: access.hasFeatureRatingAccessSelector(state),
        replyToRatingAccess: access.hasReplyRatingAccessSelector(state),
        fullRatingDetailAccess: access.hasFullRatingDetailAccessSelector(state),
    }),
)
@withTranslation('', { wait: true })
export default class RatingItem extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showReplyForm: false,
        }
    }
    toggleReplyForm() {
        this.setState({ showReplyForm: !this.state.showReplyForm })
    }
    unFeatureRating() {
        this.props.unFeatureRating(this.props.rating.id)
    }

    featureRating() {
        this.props.featureRating(this.props.rating.id)
    }

    replyToRating(comment) {
        this.props.replyToRating(this.props.rating.id, comment)
        this.setState({ showReplyForm: false })
    }

    render() {
        const { rating, fullRatingDetailAccess, featureRatingAccess, t } = this.props
        const { showReplyForm } = this.state

        var buttonBox = (
            <div className="buttons">
                <div className="row">
                    {!this.state.showReplyForm &&
                    <div className="col-md-6">
                        <a disabled={featureRatingAccess ? '' : 'disabled'} className="btn btn-action btn-block btn-lg" onClick={this.toggleReplyForm.bind(this)}>{t(rating.reply && rating.reply.comment ? 'rating.reply_edit' : 'rating.reply')}</a>
                    </div>
                    }
                    <div className="col-md-6">
                        {this.props.rating.isFeatured ?
                            <a className="btn btn-secondary btn-block btn-lg" onClick={this.unFeatureRating.bind(this)}>{t('rating.remove_featured')}</a> :
                            <a className="btn btn-secondary btn-block btn-lg" onClick={this.featureRating.bind(this)}>{t('rating.make_featured')}</a>
                        }
                    </div>
                </div>
            </div>
        )

        return <div className="rating-item">

            <div className="rating-header">
                <div className="icon icon-round">
                    <span className="ap-star" />
                </div>
                <div className="info">
                    <h3>{showReplyForm} #{rating.id} {rating.title}</h3>
                    <ul className="meta-info">
                        <li>{rating.name}</li>
                        <li><FormattedDate date={rating.createdAt} format="date" /></li>
                    </ul>
                </div>
            </div>

            <div className="rating-body">
                <div className="rating-overview">
                    {rating.isFeatured && <div className="featured-header">{t('rating.featured')}</div>}
                    <div className="overall-rating">
                        <RatingStarBox stars={rating.score.overall} />
                    </div>
                    <ul>
                        <li><strong>{t('rating.score.overall')}:</strong> <span className="pull-right">{rating.score.overall}/5</span></li>
                        {fullRatingDetailAccess && <div>
                                {rating.score.quality ? <li><strong>{t('rating.score.quality')}:</strong> <span className="pull-right">{rating.score.quality}/5</span></li> : null}
                                {rating.score.expertise ? <li><strong>{t('rating.score.expertise')}:</strong> <span className="pull-right">{rating.score.expertise}/5</span></li> : null}
                                {rating.score.budget ? <li><strong>{t('rating.score.budget')}:</strong> <span className="pull-right">{rating.score.budget}/5</span></li> : null}
                                {rating.score.contact ? <li><strong>{t('rating.score.contact')}:</strong> <span className="pull-right">{rating.score.contact}/5</span></li> : null}
                                {rating.score.schedule ? <li><strong>{t('rating.score.schedule')}:</strong> <span className="pull-right">{rating.score.schedule}/5</span></li> : null}
                            </div>}
                    </ul>
                </div>
                <div className="rating-comment">
                    {Nl2br(rating.comment)}
                </div>
            </div>

            {this.state.showReplyForm &&
            <RatingReplyForm saveReply={this.replyToRating.bind(this)} cancelReply={this.toggleReplyForm.bind(this)} initialValues={{ comment: rating.reply ? rating.reply.comment : null }} />
                }
            {!this.state.showReplyForm && rating.reply && rating.reply.comment &&
            <RatingReplyItem partnerUser={this.props.partnerUser} reply={rating.reply} />
                }

            {!this.state.showReplyForm && buttonBox}
        </div>
    }
}
