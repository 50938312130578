import React from 'react'
import styled from 'styled-components'
import { actionColor, cardBorderColor } from '../../../../../theme/variables'
import { Card } from '../../../../../components/blocks/Card'
import { Flex } from '../../../../../components/elements/Flex'
import CheckMarkCircle from '../../../../../components/elements/CheckMarkCircle'
import { Button } from '../../../../../components/elements/Button'
import { media } from '../../../../../components/utils/styledMedia'

const ActionBar = styled.div`
  border-top: 1px solid ${cardBorderColor};
  background: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 20px;
  z-index: 1;
`
const CheckMarkLine = styled.div`
  border-top: 5px solid ${props => props.grey ? cardBorderColor : actionColor};
  width: 160px;
`

const CursorIcon = styled.i.attrs({ className: 'mdi mdi-cursor-default-outline' })`
  margin-left: 10px;
`

const Circle = styled.div`
  display: inline-block;
  border: 5px solid ${cardBorderColor};
  border-radius: 100%;
  padding: 6px;
`

export const ActionBarCard = ({
    partnerUserName,
    checkout,
    t,
}) =>
    <ActionBar>
        <Flex modifiers={[ 'justifyCenter' ]}>
            <div className="hidden-xs">
                <Flex modifiers={[ 'alignCenter', 'mX_3', 'mB_2' ]}>
                    <CheckMarkCircle modifiers={[ 'action' ]} size={3} />
                    <CheckMarkLine />
                    <CheckMarkCircle modifiers={[ 'action' ]} size={3} />
                    <CheckMarkLine grey />
                    <Circle />
                </Flex>
                <Flex modifiers={[ 'justifySpaceBetween' ]}>
                    <Card.Label>{t('quotes.quote.request_offer')}</Card.Label>
                    <Card.Label>{t('quotes.quote.receive_offer')}</Card.Label>
                    <Card.Label>{t('quotes.quote.choose_offer')}</Card.Label>
                </Flex>
            </div>
            <Button
                className="hidden-xs"
                modifiers={[ 'action', 'p_2', 'mL_6' ]}
                onClick={checkout}
            >
                {t('quotes.quote.action_bar_button', { name: partnerUserName })}
                <CursorIcon />
            </Button>
            <Button
                className="visible-xs"
                modifiers={[ 'action', 'p_2' ]}
                onClick={checkout}
            >
                {t('quotes.quote.action_bar_button', { name: partnerUserName })}
                <CursorIcon />
            </Button>

        </Flex>
    </ActionBar>
