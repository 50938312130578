import React, { useCallback, useEffect, useState } from 'react'
import { useAgerasApi, useAgerasApiMutation } from 'ageras-api-client/src'
import {
    IndustriesApiFetchParamCreator, IndustryGroupResult, IndustryResource, IndustryResult,
    LeadResource,
    LeadResult,
    LeadsApiFetchParamCreator, LeadTypeGroupResult, LeadTypeResource, LeadTypeResult,
    LocationGeoPointResource,
    PartnerResource,
    PartnersApiFetchParamCreator, PartnerUserResource,
} from 'ageras-api-client/src/api'
import { useAuth } from 'ageras-api-client/src/auth'
import Page from 'components/src/layout/Page'
import Flex, { Spacer } from 'components/src/layout/Flex'
import Grid from 'components/src/layout/Grid'
import Heading from 'components/src/texts/Heading'
import CheckboxToggle from 'components/src/inputs/CheckboxToggle'
import Text from 'components/src/texts/Text'
import { Spinner } from 'components/src/Spinner'
import Pagination from 'components/src/PaginationControl'
import { List, Filter, Frown } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'
import BalanceInfo from './BalanceInfo'
import LeadCard from './LeadCard'
import Dropdown from 'components/src/Dropdown'
import Button from 'components/src/Button'
import LeadListFilters from './LeadListFilters'
import LeadListSorts from './LeadListSorts'
import isIndividualType from '../../../utils/isIndividualType'
import Tooltip from 'components/src/Tooltip'

export interface FilterProps {
    distance?: string
    industries?: string[]
    types?: string[]
}

interface filtersRangeMaxValuesProps {
    [geoCode: string]: number
}

const filtersRangeMaxValues: filtersRangeMaxValuesProps = {
    us: 250,
}

function useLeads(partnerId: number, geoCode: string, sort: string, filters: {}|FilterProps, page: number, enabled: boolean, geoPoint?: LocationGeoPointResource) {
    let distance = undefined
    let industryIds = undefined
    let typeIds = undefined
    if ('distance' in filters && filters.distance && Number(filters.distance) < filtersRangeMaxValues[geoCode]) {
        distance = filters.distance
    }

    if ('industries' in filters && filters.industries && filters.industries.length) {
        industryIds = filters.industries
    }

    if ('types' in filters && filters.types && filters.types.length) {
        typeIds = filters.types
    }

    return useAgerasApi<LeadResult>(
        LeadsApiFetchParamCreator().leadsIndex(
            undefined,
            undefined,
            undefined,
            // @ts-ignore
            geoCode,
            // @ts-ignore
            sort,
            'validated',
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            partnerId,
            undefined,
            undefined,
            geoPoint ? `${geoPoint.lat},${geoPoint.lon}` : undefined,
            undefined,
            distance,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            industryIds ? String(industryIds) : undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            partnerId,
            undefined,
            undefined,
            undefined,
            typeIds ? String(typeIds) : undefined,
            12,
            page
        ),
        {
            refetchOnMount: true,
            enabled,
        }
    )
}

function useIndustryGroups(geoCode: string) {
    return useAgerasApi<IndustryGroupResult>(
        IndustriesApiFetchParamCreator().industriesGroupIndex(geoCode)
    )
}

function useIndustries(geoCode: string, parentIndustryId: string) {
    return useAgerasApi<IndustryResult>(
        IndustriesApiFetchParamCreator().industriesIndex(
            undefined,
            undefined,
            geoCode,
            true,
            50,
            parentIndustryId
        )
    )
}

function useTypeGroups(geoCode: string) {
    return useAgerasApi<LeadTypeGroupResult>(
        LeadsApiFetchParamCreator().leadsTypegroupsIndex(geoCode)
    )
}

function useTypes(geoCode: string) {
    return useAgerasApi<LeadTypeResult>(
        LeadsApiFetchParamCreator().leadsTypesIndex(
            undefined,
            undefined,
            geoCode,
            50,
            false,
            undefined
        )
    )
}

interface MarketplaceLeadListProps {
    partner: PartnerResource
    geoCode: string
    hasMarketplacePlusLeadAccess: boolean
    hasMarketplaceWithoutFeesAccess: boolean
}

function MarketplaceLeadList({ partner, geoCode, hasMarketplacePlusLeadAccess, hasMarketplaceWithoutFeesAccess }: MarketplaceLeadListProps) {
    const { partnerUser } = useAuth()
    const [ current, setCurrent ] = useState<number>(1)
    const [ sort, setSort ] = useState<string>('-created_at')
    const [ filters, setFilters ] = useState<FilterProps>({ industries: [], types: [] })
    const [ enabled, setEnabled ] = useState<boolean>(false)
    const [ showPreferencesNotification, setShowPreferencesNotification ] = useState<boolean>(false)
    const [ notificationsEnabled, setNotificationsEnabled ] = useState(false)
    const { data, isLoading } = useLeads(partner.id!, geoCode, sort, filters, current, enabled, partner.geo?.point)
    const { data: industries } = useIndustries(partner.geo?.code!, String(partner.industry?.id))
    const { data: types } = useTypes(partner.geo?.code!)
    const { data: industryGroups } = useIndustryGroups(geoCode)
    const { data: typeGroups } = useTypeGroups(geoCode)
    const queryClient = useQueryClient()
    const { t } = useTranslation('translation')

    useEffect(() => {
        const initialFilters: FilterProps = {}
        if (partner.industries?.length) {
            initialFilters.industries = partner.industries.map(industry => String(industry.id))
        }
        if (partner.leadTypes?.length) {
            initialFilters.types = partner.leadTypes.map(type => String(type.id))
        }

        setShowPreferencesNotification(!partner.industries?.length || !partner.leadTypes?.length)
        setFilters(initialFilters)
        setEnabled(true)
        setNotificationsEnabled(partnerUser?.wantsDailyEmail ?? false)
    }, [ partner, partnerUser ])

    const mutation = useAgerasApiMutation<PartnerUserResource>(
        partnerUser => PartnersApiFetchParamCreator().partnersUsersUpdate_24(String(partnerUser.partnerId), String(partnerUser.id), partnerUser),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([ '/auth/token' ])
            }
        }
    )

    const updateWantsDailyEmail = useCallback(() => {
        if (!partnerUser) {
            return
        }

        setNotificationsEnabled(!notificationsEnabled)
        mutation.mutate({ ...partnerUser, wantsDailyEmail: !partnerUser.wantsDailyEmail })
    }, [ mutation, partnerUser, notificationsEnabled ])

    const handleSortChange = useCallback((newSort) => {
        setSort(newSort)
    }, [])

    const handleFiltersChange = useCallback((filters) => {
        setFilters(filters)
    }, [])

    const handleGoUp = useCallback(() => {
        setCurrent(current + 1)
    }, [ current ])

    const handleGoDown = useCallback(() => {
        setCurrent(current - 1)
    }, [ current ])

    const handleGoTo = useCallback((page) => {
        setCurrent(page)
    }, [])

    return <>
        <Flex px={2} mb={2}>
            <Tooltip location="top">
                <Flex>
                    <CheckboxToggle disabled={mutation.isLoading} labelLeft={t('marketplace.controls.activate_notifications')} value={notificationsEnabled} onChange={updateWantsDailyEmail} />
                </Flex>
                <Flex p={2}>
                    <Text textAlign="center" color="impact" size="small">
                        {t('marketplace.controls.activate_notifications_popup')}
                    </Text>
                </Flex>
            </Tooltip>
            <Spacer />
            <Flex mr={2}>
                <Dropdown>
                    <Flex as={Button} variant="ternary" size="small" alignItems="center">
                        <Text size="medium">{t('marketplace.controls.filter')}</Text>
                        {' '}
                        <Flex ml={2}>
                            <Filter size={16} />
                        </Flex>
                    </Flex>
                    {types && industries && industryGroups && typeGroups && !isLoading && (
                        <LeadListFilters
                            onFiltersSave={handleFiltersChange}
                            rangeMax={filtersRangeMaxValues[geoCode]}
                            types={types.data?.map((type: LeadTypeResource) => ({ id: type.id!, title: type.title!, groupId: type.typeGroupId }))}
                            industries={industries.data?.map((industry: IndustryResource) => ({ id: industry.id!, title: industry.name!, groupId: industry.industryGroupId }))}
                            industryGroups={industryGroups.data?.map(group => ({ id: group.id!, title: group.name!, industries: group.industries! }))}
                            typeGroups={typeGroups.data?.map(group => ({ id: group.id!, title: group.name!, types: group.types! }))}
                            initialFilters={filters}
                            showPreferencesNotification={showPreferencesNotification}
                        />
                    )}
                </Dropdown>
            </Flex>
            <Dropdown>
                <Flex as={Button} variant="ternary" size="small" alignItems="center">
                    <Text size="medium">{t('marketplace.controls.sort')}</Text>
                    {' '}
                    <Flex ml={2}>
                        <List size={16} />
                    </Flex>
                </Flex>
                <LeadListSorts handleSortChange={handleSortChange} currentSort={sort} partner={partner} />
            </Dropdown>
        </Flex>
        <Grid gridTemplateColumns="repeat(auto-fit, minmax(360px, 1fr))" gridGap={2} mb={3}>
            {isLoading && <Flex justifyContent="center">
                <Spinner size={34} />
            </Flex>}
            {!isLoading && (!data || !data.data?.length) && <Flex flexDirection="column" justifyContent="center" alignItems="center" p={3}>
                <Frown size={40} />
                <Text mt={2}>{t('marketplace.no_leads')}</Text>
            </Flex>}
            {!isLoading && data && data.data!.map((lead: LeadResource) => (
                <LeadCard
                    key={lead.id}
                    lead={lead}
                    partner={partner}
                    isIndividualType={isIndividualType(lead.customerInfo?.customerType!, geoCode)}
                    hasMarketplacePlusLeadAccess={hasMarketplacePlusLeadAccess}
                    hasMarketplaceWithoutFeesAccess={hasMarketplaceWithoutFeesAccess}
                />
            ))}
        </Grid>
        <Flex flexDirection="column">
            <Pagination
                current={current}
                total={data?.pages ?? 0}
                onDown={handleGoDown}
                onUp={handleGoUp}
                onGoTo={handleGoTo}
            />
        </Flex>
    </>
}

function MarketplacePage(props: {
    marketplaceEnabledCountries: string[],
    hasMarketplaceLeadAccess: boolean
    hasMarketplacePlusLeadAccess: boolean
    hasMarketplaceWithoutFeesAccess: boolean
}) {
    const auth = useAuth()
    const { data: partner, isLoading } = useAgerasApi<PartnerResource>(
        PartnersApiFetchParamCreator().partnersGet(auth.partnerUser?.partnerId! as unknown as string)
    )
    const { t } = useTranslation('translation')

    if ((partner?.geo?.code && !props.marketplaceEnabledCountries.includes(partner?.geo?.code)) || !props.hasMarketplaceLeadAccess) {
        return null
    }

    return <Page variant="grey">
        <Flex px={6} pt={6} maxWidth={1300} mx="auto" flexDirection="column">
            <Heading size="large" textAlign="center">{t('marketplace.title')}</Heading>
        </Flex>
        { partner && !isLoading && <Flex px={6} pb={5} pt={5} maxWidth={1300} mx="auto" flexDirection="column" alignItems="flex-end">
            <BalanceInfo partner={partner} hasMarketplaceWithoutFeesAccess={props.hasMarketplaceWithoutFeesAccess} />
        </Flex>}
        {isLoading && <Flex px={6} maxWidth={1300} mx="auto" justifyContent="center">
            <Spinner size={34} />
        </Flex>}
        {!isLoading && !partner && <Flex px={6} maxWidth={1300} mx="auto" flexDirection="column">
            <Text textAlign="center">Something went wrong</Text>
        </Flex>}
        {partner && !isLoading && <Flex px={6} maxWidth={1300} mx="auto" flexDirection="column">
            <MarketplaceLeadList
                partner={partner}
                geoCode={partner.geo?.code!}
                hasMarketplacePlusLeadAccess={props.hasMarketplacePlusLeadAccess}
                hasMarketplaceWithoutFeesAccess={props.hasMarketplaceWithoutFeesAccess}
            />
        </Flex>}
    </Page>
}

export default MarketplacePage
