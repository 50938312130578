import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'
import { decamelizeKeys } from 'humps'

export const GET_REFUNDS = 'refunds/GET_REFUNDS'
export const GET_REFUNDS_SUCCESS = 'refunds/GET_REFUNDS_SUCCESS'
export const GET_REFUNDS_FAIL = 'refunds/GET_REFUNDS_FAIL'

export const GET_REFUND = 'refunds/GET_REFUND'
export const GET_REFUND_SUCCESS = 'refunds/GET_REFUND_SUCCESS'
export const GET_REFUND_FAIL = 'refunds/GET_REFUND_FAIL'

export const CREATE_REFUND = 'refunds/CREATE_REFUND'
export const CREATE_REFUND_SUCCESS = 'refunds/CREATE_REFUND_SUCCESS'
export const CREATE_REFUND_FAIL = 'refunds/CREATE_REFUND_FAIL'

export const getRefunds = (partnerId, page = 1) => dispatch => dispatch({
    [CALL_API]: {
        types: [ GET_REFUNDS, GET_REFUNDS_SUCCESS, GET_REFUNDS_FAIL ],
        endpoint: '/refunds',
        schema: schemas.refunds,
        options: {
            body: {
                limit: 10,
                partner_id: partnerId,
                page,
            },
        },
    },
})

export const getRefund = refundId => dispatch => dispatch({
    [CALL_API]: {
        types: [ GET_REFUND, GET_REFUND_SUCCESS, GET_REFUND_FAIL ],
        endpoint: `/refunds/${refundId}`,
        schema: schemas.refund,
    },
})

export const createRefund = data => dispatch => dispatch({
    [CALL_API]: {
        types: [ CREATE_REFUND, CREATE_REFUND_SUCCESS, CREATE_REFUND_FAIL ],
        endpoint: '/refunds',
        schema: schemas.refund,
        options: {
            method: 'post',
            body: decamelizeKeys(data),
        },
    },
})
