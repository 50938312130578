import React from 'react'
import { OverflowText } from '../cards'
import OverlayTrigger from 'react-bootstrap/es/OverlayTrigger'
import { Popover } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'

@withTranslation('', { wait: true })
export default class EditCell extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            editing: false,
            loader: false,
            text: '',
        }
    }

    onFocus(e, id, editable) {
        e.preventDefault()
        if (editable) {
            this['overlayTrigger' + id].show()
        }
    }

    onSave(id, overlay) {
        if (this.props.value !== this.state.text) {
            this.setState({ loader: true })
            this.props.onBlur(this.state.text, this.props.item)
                .then(res => {
                    this.setState({ loader: false })
                    overlay.hide()
                })
                .catch(err => {
                    overlay.hide()
                })
        }
    }

    render() {
        const { id, value, style, editable = true, tooltipPlaceholder, t } = this.props
        return <td style={style} className={this.state.loader ? 'text-center' : ''} onClick={e => {
            e.stopPropagation()
            this.onFocus(e, id, editable)
        }}>
            <OverlayTrigger
                trigger={editable && 'click'}
                onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                }}
                rootClose
                ref={ref => {
                    this['overlayTrigger' + id] = ref
                }}
                placement="top"
                overlay={
                    <Popover
                        id={'Cell' + id}
                        style={{
                            maxWidth: '400px',
                            minHeight: '100px',
                        }}
                    >
                        <textarea
                            onChange={e => this.setState({ text: e.target.value })}
                            style={{ width: '100%', minHeight: '100px' }}
                            defaultValue={value}
                            placeholder={tooltipPlaceholder}
                        />
                        <br />
                        <button
                            className="btn btn-action"
                            onClick={() => this.onSave(id, this['overlayTrigger' + id])}
                        >
                            {t('actions.save')}
                        </button>
                    </Popover>
                }
            >
                <OverflowText>{value}</OverflowText>
            </OverlayTrigger>
        </td>
    }
}
