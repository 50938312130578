import {
    GET_DELIVERABLES, GET_DELIVERABLES_SUCCESS, GET_DELIVERABLES_FAIL,
} from '../../actions/deliverables'

const initialState = {
    deliverables: [],
    isLoadingDeliverables: false,
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    case GET_DELIVERABLES:
        return {
            ...state,
            isLoadingDeliverables: true,
        }
    case GET_DELIVERABLES_SUCCESS:
        return {
            ...state,
            deliverables: action.response.result,
            isLoadingDeliverables: false,
        }
    case GET_DELIVERABLES_FAIL:
        return {
            ...state,
            isLoadingDeliverables: false,
        }
    default:
        return state
    }
}
