import React from 'react'
import CouponItem from './CouponItem'
import { withTranslation } from 'react-i18next'

@withTranslation('', { wait: true })
export default class CouponList extends React.Component {
    render() {
        const {
            coupons, t, partner,
        } = this.props

        return (
            <table className="datatable responsive">
                <thead>
                    <tr>
                        <th className="table-mobile-cell" />
                        <th>{ t('coupons.id') }</th>
                        <th>{ t('coupons.expires_at') }</th>
                        <th>{ t('coupons.punches_total') }</th>
                        <th>{ t('coupons.punches_left') }</th>
                    </tr>
                </thead>
                <tbody>
                    {coupons.map(coupon =>
                        <CouponItem key={coupon.id} coupon={coupon} currency={partner.preferences?.currency?.code} />
                )}
                </tbody>
            </table>
        )
    }
}
