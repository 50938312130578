import React from 'react'
import {
    InputGroup,
} from 'react-bootstrap'
import { NumberInput } from './NumberInputGroup'

const AmountInput = ({
    input,
    placeholder,
    disabled,
    currency,
}) => (
    <InputGroup className="currency">
        <NumberInput
            input={input}
            disabled={disabled}
            placeholder={placeholder}
        />
        <InputGroup.Addon>
            {currency.symbol}
        </InputGroup.Addon>
    </InputGroup>
)

export default AmountInput
