import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import * as auth from '../../actions/auth'
import { queryParametersSelector } from '../../selectors/routing'

const selector = formValueSelector('passwordReset')

@connect(
    state => ({
        queryParameters: queryParametersSelector(state),
        pathname: state.router.location.pathname,
        email: selector(state, 'email'),
        password: selector(state, 'password'),
        passwordConfirmation: selector(state, 'passwordConfirmation'),
        token: selector(state, 'token'),
    }),
    dispatch => ({
        resetPassword: bindActionCreators(auth.resetPassword, dispatch),
    })
)
@reduxForm({
    form: 'passwordReset',
    // validate: values => values.password == values.passwordConfirmation,
})
@withTranslation('', { wait: true })
export default class PasswordResetForm extends React.Component {

    onSubmit(values) {
        console.log(values)
        return this.props.resetPassword(values.email, values.password, values.token)
    }

    render() {
        const {
            t,
            pristine,
            submitting,
            handleSubmit,
            submitSucceeded,
            pathname,
        } = this.props

        return (
            <div>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <div style={{'display': 'flex', 'justifyContent': 'center'}}>
                        <img src="/images/ageras-logo-blue.svg"/>
                    </div>
                    { submitSucceeded && <div className="alert alert-success">
                        {t('login.password_reset')}
                        <div><Link to={{ pathname, query: { } }}>{t('login.go_to_login_page')}</Link></div>
                    </div> }
                    { submitSucceeded || <div>
                        <div className="form-group">
                            <label>{t('login.email')}</label>
                            <Field name="email" className="form-control" component="input" disabled placeholder={t('login.email')} />
                        </div>
                        <div className="form-group">
                            <label>{t('login.password')}</label>
                            <Field name="password" className="form-control" component="input" type="password" disabled={submitting} placeholder={t('login.password')} />
                        </div>
                        <div className="form-group">
                            <label>{t('login.password_confirmation')}</label>
                            <Field name="passwordConfirmation" className="form-control" component="input" type="password" disabled={submitting} placeholder={t('login.password_confirmation')} />
                        </div>
                        <button className="btn btn-action btn-primary btn-lg btn-block" disabled={pristine || submitting}>{t('login.reset_password')}</button>
                        <div className="center-block">
                            <br />
                            <Link to={{ pathname, query: { } }}>{t('login.go_to_login_page')}</Link>
                        </div>
                    </div> }
                </form>
            </div>
        )
    }
}
