import React from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { hideModal } from '../../actions/modal'
import { withTranslation } from 'react-i18next'

@connect(null, { hideModal })
@withTranslation('', { wait: true })
export class AgerasGreetingModal extends React.PureComponent {
    render() {
        const {
            hideModal,
            t,
        } = this.props

        return <Modal
            show={true}
            onHide={hideModal}
        >
            <Modal.Header>
                <Modal.Title>{t('ageras_greeting_modal.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {t('ageras_greeting_modal.text_1')} <br /><br />
                {t('ageras_greeting_modal.text_2')} <br />
                {t('ageras_greeting_modal.text_3')} <br/>
                {t('ageras_greeting_modal.text_4')}
            </Modal.Body>
        </Modal>
    }
}


