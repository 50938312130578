import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Field, reduxForm } from 'redux-form'
import LoadingAnimation from '../utils/LoadingAnimation'
import Select from 'react-select'
import monthOptions from '../utils/monthOptions'
import { dangerColor } from '../../theme/variables'

const FIELD_COURSE = 'courseName'
const FIELD_FACILITY = 'facilityName'
const FIELD_FROM_YEAR = 'fromDateYear'
const FIELD_FROM_MONTH = 'fromDateMonth'
const FIELD_CONTENT = 'content'

@withTranslation('', { wait: true })
@reduxForm({
    form: 'educationCv',
})

export default class CourseForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: {
                [FIELD_COURSE]: this.props.initialValues.courseName || '',
                [FIELD_FACILITY]: this.props.initialValues.facilityName || '',
                [FIELD_FROM_MONTH]: this.props.initialValues.fromDateMonth || '01',
                [FIELD_FROM_YEAR]: this.props.initialValues.fromDateYear || '',
                [FIELD_CONTENT]: this.props.initialValues.content || '',
            },
            errors: [],
        }
    }

    handleInput = evt => {
        const value = evt.target.value
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [evt.target.name]: value,
            },
        }))
    }

    handleSelect = (evt, name) => {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [name]: evt,
            },
        }))
    }

    validateForm = event => {
        event.preventDefault()
        this.setState({
            errors: [],
        }, () => {
            const { data } = this.state
            const errors = []

            if (data[FIELD_COURSE] === '') {
                errors.push(FIELD_COURSE)
            }

            if (data[FIELD_FACILITY] === '') {
                errors.push(FIELD_FACILITY)
            }

            if (data[FIELD_FROM_YEAR] === '' || isNaN(data[FIELD_FROM_YEAR])) {
                errors.push(FIELD_FROM_YEAR)
            }

            this.setState({
                errors: [
                    ...this.state.errors,
                    ...errors,
                ],
            }, () => {
                if (this.state.errors.length === 0) this.props.handleSubmit(data)
            })
        })
    }

    render() {
        const {
            t,
            submitting,
        } = this.props

        const { courseName, fromDateMonth, fromDateYear, facilityName, content } = this.state.data
        const { errors } = this.state

        if (submitting) {
            return <LoadingAnimation />
        }

        return (
            <form onSubmit={this.validateForm}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <Field
                                onChange={evt => this.handleInput(evt)}
                                className="form-control"
                                name="courseName"
                                component="input"
                                placeholder={t('cv.course.placeholders.courseName')}
                                {...courseName}
                                style={{ borderColor: errors.includes(FIELD_COURSE) ? dangerColor : '' }}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <Field
                                onChange={evt => this.handleInput(evt)}
                                className="form-control"
                                name="facilityName"
                                component="input"
                                placeholder={t('cv.course.placeholders.facilityName')}
                                {...facilityName}
                                style={{ borderColor: errors.includes(FIELD_FACILITY) ? dangerColor : '' }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <Field name="fromDateMonth" component={props => {
                                return <Select
                                    value={fromDateMonth}
                                    onChange={evt => this.handleSelect(evt, FIELD_FROM_MONTH)}
                                    options={monthOptions(t)}
                                    clearable={false}
                                    simpleValue
                                />
                            }} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <Field
                                onChange={evt => this.handleInput(evt)}
                                className="form-control"
                                name="fromDateYear"
                                component="input"
                                type="text"
                                placeholder={t('cv.course.placeholders.fromDate.fromYear')}
                                {...fromDateYear}
                                style={{ borderColor: errors.includes(FIELD_FROM_YEAR) ? dangerColor : '' }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <Field className="form-control"
                                   onChange={evt => this.handleInput(evt)}
                                   rows="5"
                                   component="textarea"
                                   placeholder={t('cv.course.placeholders.experienceDescription')}
                                   name="content"
                                   {...content}
                            />
                        </div>
                    </div>
                </div>
                <button className="btn btn-action btn-lg btn-block" type="submit">
                    {t('cv.course.save')}
                </button>
            </form>
        )
    }
}

