import React from 'react'
import ReactMarkdown from 'react-markdown'
import { withTranslation } from 'react-i18next'

const defaultIcon = '../../../images/icons/empty-state-flag.svg'
const defaultDescription = 'empty_card.default_empty_state'

@withTranslation('', { wait: true })
export default class EmptyCard extends React.Component {
    render() {
        const { isEmpty, children, icon, description, t, component } = this.props
        if(isEmpty) return children
        return <div className="empty-card">
            <div>
                <img src={icon || defaultIcon} />
                <ReactMarkdown>
                    {description || t(defaultDescription)}
                </ReactMarkdown>
                {component}
            </div>
        </div>
    }
}
