import {
    GET_CONVERSION,
    GET_CONVERSION_SUCCESS,
    GET_CONVERSION_FAIL,
} from '../../actions/conversions'

const initialState = {
    isLoadingConversionInfo: false,
    conversionInfo: null,
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    case GET_CONVERSION:
        return {
            ...state,
            isLoadingConversionInfo: true,
        }
    case GET_CONVERSION_SUCCESS:
        return {
            ...state,
            conversionInfo: action.response,
            isLoadingConversionInfo: false,
        }
    case GET_CONVERSION_FAIL:
        return {
            ...state,
            isLoadingConversionInfo: false,
        }
    default:
        return state
    }
}
