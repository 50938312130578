import styled from 'styled-components'
import { applyStyleModifiers, applyResponsiveStyleModifiers } from 'styled-components-modifiers'
import { MODIFIER_MARGIN_CONFIG, MODIFIER_PADDING_CONFIG } from '../../components/elements/modifiers/spaceModifiers'

const MODIFIER_ICON_CONFIG = {
    menuArrow: () => `
        height: 16px;
    `,
    tiny: () => `
        height: 24px;
    `,
    small: () => `
        height: 48px;
    `,
    medium: () => `
        height: 64px;
        max-width: 64px;
    `,
    large: () => `
        height: 74px;
        max-width: 74px;
    `,
    center: () => `
        margin-left: auto;
        margin-right: auto;
    `,
}

export const Icon = styled.div`
    position: relative;
    & > * {
        width: auto;
        height: 100%;
    };
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)};
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)};
    ${applyStyleModifiers(MODIFIER_ICON_CONFIG)};
    ${applyResponsiveStyleModifiers(MODIFIER_MARGIN_CONFIG)};
    ${applyResponsiveStyleModifiers(MODIFIER_PADDING_CONFIG)};
    ${applyResponsiveStyleModifiers(MODIFIER_ICON_CONFIG)}
`

const MODIFIER_IMAGE_CONFIG = {
    small: () => `
        width: 60%;
    `,
    tiny: () => `
        width: 120px;
    `,
    center: () => `
        margin-left: auto;
        margin-right: auto;
    `,
    logo: () => `
        max-width: 112px;
    `,
    logoBig: () => `
        max-width: 180px;
    `,
    circle: () => `
        overflow: hidden;
        border-radius: 50%;
    `,
    shaped: () => `
        & img {
            mask-image: url(images/masks/mask_shape.svg);
            mask-repeat: no-repeat;
            mask-position: center;
            mask-size: contain;
        }
    `,
}

export const Image = styled.div`
    position: relative;
    & img {
        width: 100%;
        height: auto;
    };
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)};
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)};
    ${applyStyleModifiers(MODIFIER_IMAGE_CONFIG)};
    ${applyResponsiveStyleModifiers(MODIFIER_MARGIN_CONFIG)};
    ${applyResponsiveStyleModifiers(MODIFIER_PADDING_CONFIG)};
    ${applyResponsiveStyleModifiers(MODIFIER_IMAGE_CONFIG)};
`
