import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { currentPartnerSelector, currentPartnerUserSelector } from '../../selectors/auth'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import LoadingAnimation from '../utils/LoadingAnimation'
import ActionCheckBox from '../utils/ActionCheckBox'
import PageTitle from '../utils/PageTitle'
import { Card } from '../blocks/Card'
import { Col, ListGroup, ListGroupItem, Panel, Row } from 'react-bootstrap'
import LanguageItem from '../marketing/LanguageItem'
import { getCv } from '../../actions/cv'
import { getCertifications, getPartnerCertifications } from '../../actions/certifications'
import { getPartnerContracts } from '../../actions/partnercontracts'
import { getPartnerBusinessUnits } from '../../actions/businessUnits'
import styled from 'styled-components'

const SEGMENTATION_CRITERIA = [
    {
        id: 'desiredRevenueRange',
        range: 1,
        field1: 'minimumRevenue',
        field2: 'maximumRevenue',
        title: 'Revenue Range',
    },
    {
        id: 'disableDigitalLeads',
        title: 'Digital Work Preference',
    },
    {
        id: 'desiredSectors',
        field: 'displayName',
        title: 'Sectors',
    },
    {
        id: 'desiredIndustries',
        field: 'name',
        title: 'Industries',
    },
    {
        id: 'desiredTypes',
        field: 'title',
        title: 'Lead Types',
    },
]

const StyledPanel = styled(Panel)`
   padding-left: 15px;
`
const StyledPanelTitle = styled(Panel.Title)`
   font-weight: 600;
   padding-bottom: 10px;
   margin-bottom: 20px;
`
const StyledCardLine = styled(Card.Line)`
   margin: 0;
   margin-bottom: 15px;
   border-top: 1px solid #21b468;
`
const StyledListGroupItem = styled(ListGroupItem)`
   border: none!important;
   padding-top: 0!important;
   padding-left: 0!important;
`

function handleCriteria(criteria, businessUnit) {
    if (criteria.hasOwnProperty('field')) {
        return businessUnit[criteria.id].map((el, key) =>
            <StyledListGroupItem key={criteria.id + key}>
                <div>{el[criteria.field]}</div>
            </StyledListGroupItem>
        )
    } else if(criteria.hasOwnProperty('range')) {
        return <StyledListGroupItem key={criteria.id + businessUnit.id}>
            <div>{businessUnit[criteria.id][criteria.field1] + ' - ' + businessUnit[criteria.id][criteria.field2]}</div>
        </StyledListGroupItem>
    }
    return <StyledListGroupItem key={criteria.id + businessUnit.id}>
        <div>
            {businessUnit[criteria.id] ?
                'Not Digital' : 'Digital'}
        </div>
    </StyledListGroupItem>
}

@connect(
    state => ({
        partner: currentPartnerSelector(state),
        partnerUser: currentPartnerUserSelector(state),
        certifications: Object.values(state.entities.certifications),
        partnerCertificationIds: state.pages.segmentation.partnerCertificationIds,
        partnerCv: state.entities.cvs[currentPartnerUserSelector(state).id],
        businessUnits: Object.values(state.entities.businessUnits),
        partnerContracts: Object.values(state.entities.partnerContracts),
    }),
    {
        getCertifications,
        getPartnerCertifications,
        getCv,
        getPartnerBusinessUnits,
        getPartnerContracts,
    }
)
@withTranslation('', { wait: true })
@reduxForm({
    form: 'partner',
})
export default class SegmentationPage extends React.Component {
    componentDidMount() {
        const partner = this.props.partner
        this.refreshPartnerCv()
        this.props.getPartnerCertifications(partner.id)
        this.props.getPartnerBusinessUnits(partner.id)
        this.props.getPartnerContracts(partner.id)
        this.props.getCertifications({
            geoCode: partner.geo.code,
            industryId: partner.industry.id,
            limit: 1000,
        })
    }

    refreshPartnerCv() {
        this.props.getCv(this.props.partner.id, this.props.partnerUser.id)
    }

    render() {
        const {
            partnerCertificationIds,
            certifications,
            isLoadingLeadTypes,
            isLoadingIndustries,
            partnerCv,
            businessUnits,
            partnerContracts,
            t,
        } = this.props

        if (isLoadingLeadTypes || isLoadingIndustries) {
            return <LoadingAnimation />
        }

        const activeContract = partnerContracts.filter(contract => contract.currentPartnerContractPeriod)
        const activeBusinessUnits = activeContract.length > 0 ? activeContract[0].businessUnits.map(bu => bu) : []

        const disabled = true

        return <Card>
            <Card.Content>
                <PageTitle identifier="segmentation.header" />
                <h2>{t('segmentation.header')}</h2>
                <Card.Intro>{t('segmentation.intro')}</Card.Intro>

                <Card.Line modifiers={[ 'mY_3', 'cardWidth' ]} />
                <Card.Title>{t('company_profile.certification.title')}</Card.Title>
                <Card.Intro>{t('company_profile.certification.intro')}</Card.Intro>
                <Row>
                    {certifications.map(sector => {
                        const checked = partnerCertificationIds.has(sector.id)
                        return <Col sm={6} key={sector.id}>
                            <ActionCheckBox
                                value={sector.id}
                                checked={checked}
                                text={sector.title}
                                disabled={disabled}
                            />
                        </Col>
                    })}
                </Row>
                <Card.Line modifiers={[ 'mY_3', 'cardWidth' ]} />
                <Row>
                    <Col md={12}>
                        <div className="language">
                            <Card.Title>{t('cv.language.title')}</Card.Title>
                            <Card.Intro>{t('cv.language.intro')}</Card.Intro>
                            <Row>
                                {partnerCv && partnerCv.languages.map(exp =>
                                    <Col md={6} key={'language_' + exp.id}>
                                        <LanguageItem
                                            key={exp.id}
                                            lang={exp}
                                        />
                                    </Col>
                                )}
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Card.Content>
        </Card>
    }
}
