import React, { ReactNode, useCallback, useState, createContext } from 'react'
import styled, { css } from 'styled-components'
import Flex from '../layout/Flex'
import { useComponentVisible } from '../inputs/Select'

const DropdownRef = styled(Flex)`
    position: relative;
    flex-direction: column;
`

interface DropdownBodyProps {
    open?: boolean
}

const DropdownBody = styled(Flex)<DropdownBodyProps>`
    display: none;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 9;

    ${({ open }) => open && css`
        display: block;
    `}
`

export interface DropdownProps {
    children: ReactNode[]
    disabled?: boolean
}

interface DropdownContextProps {
    isDropdownOpen?: boolean
    toggleDropdown?: () => void
    disabled?: boolean
}

export const DropdownContext = createContext<DropdownContextProps>({})

function Dropdown({ children, disabled }: DropdownProps) {
    const [ isOpen, setOpen ] = useState<boolean>(false)
    const ref = useComponentVisible<HTMLDivElement>(setOpen)

    const toggleDropdown = useCallback(() => {
        !disabled && setOpen(!isOpen)
    }, [ disabled, isOpen ])

    return (
        <DropdownContext.Provider value={{ isDropdownOpen: isOpen, toggleDropdown, disabled }}>
            <DropdownRef ref={ref}>
                <Flex flexDirection="column" onClick={toggleDropdown}>
                    {children[0]}
                </Flex>
                <DropdownBody open={isOpen}>
                    {children[1]}
                </DropdownBody>
            </DropdownRef>
        </DropdownContext.Provider>
    )
}

export default Dropdown
