import React from 'react'
import { withTranslation } from 'react-i18next'
import { Flex } from '../../elements/Flex'
import SwitchCheckboxInput from '../../blocks/SwitchCheckboxInput'
import styled from 'styled-components'

const WidgetWrapper = styled(Flex)`
    border-radius: 5px;
    padding: 10px;
    transition: background-color .1s ease-out;

    ${({ darkBackground }) => darkBackground && `
        background-color: #484848;

        span {
            color: #fff;
        }
    `}
`

@withTranslation('', { wait: true })
export default class WidgetItem extends React.Component {
    state = {
        darkBackground: false,
    }

    handleSwitchDarkBackground = () => {
        this.setState({
            darkBackground: !this.state.darkBackground,
        })
    }

    render() {
        const {
            reviewCount,
            partnerId,
            width,
            height,
            t,
        } = this.props

        const url = `https://cdn.ageras.com/api/rating-widget?partner_id=${partnerId}&review_count=${reviewCount}&width=${width}&height=${height}&light=${this.state.darkBackground ? '1' : '0'}`

        return (
            <div>
                <WidgetWrapper modifiers={[ 'alignCenter' ]} darkBackground={this.state.darkBackground}>
                    <iframe src={url} width={width} height={height} border="0" frameBorder="0" style={{ border: 0 }} allowTransparency="true" />

                    <Flex modifiers={[ 'alignCenter' ]}>
                        <SwitchCheckboxInput
                            onClick={this.handleSwitchDarkBackground}
                            checked={this.state.darkBackground}
                        />
                        <span>{ this.state.darkBackground ? t('widgets.dark_background') : t('widgets.light_background') }</span>
                    </Flex>
                </WidgetWrapper>

                <br />

                {t('widgets.embed_explanation')}
                <code>
                    &lt;iframe src="{url}" width="{width}" height="{height}" border="0" frameborder="0" style="border: 0" allowtransparency="true"&gt;&lt;/iframe&gt;
                </code>
            </div>
        )
    }
}
