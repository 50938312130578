import styled from 'styled-components'

export default styled.div`
    background: #ffffff;
    background: -moz-linear-gradient(left,  #ffffff 0%, #e0ebef 100%);
    background: -webkit-linear-gradient(left,  #ffffff 0%,#e0ebef 100%);
    background: linear-gradient(to right,  #ffffff 0%,#e0ebef 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e0ebef',GradientType=1 );
    .page-separator {
        background-color: #fff;
    }
`
