import { createSelector } from 'reselect'

const routingState = state => state.router

export const queryParametersSelector = createSelector(
    routingState,
    routing => Object.assign({}, ...(routing.location.search || '?').slice(1).split('&').filter(pair => pair).map(pair => {
        const [ key, value ] = pair.split('=')
        return { [key]: value }
    })),
)

export const currentPageSelector = createSelector(
	queryParametersSelector,
	query => parseInt(query.page) || 1,
)
