import React from 'react'
import Box from '../blocks/Box'
import { Flex } from '../elements/Flex'
import { Headline, Text } from '../elements/text/Text'
import { Button } from '../elements/Button'
import { connect } from 'react-redux'
import { hideModal } from '../../actions/modal'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FormGroup, Modal } from 'react-bootstrap'
import MetaLabel from '../elements/text/MetaLabel'
import { cardBorderColor } from '../../theme/variables'
import { Error } from '../forms/Error'

const CenteredModal = styled(Modal)`
    .modal-dialog {
        width: 520px;
        margin-top: 300px !important;
    }
`

const Textarea = styled.textarea`
    height: 70px;
    font-size: 16px;
    line-height: 27px;
    border: 1px solid ${cardBorderColor};
    border-radius: 3px;
    resize: none;
    padding: 5px 11px;
    width: 100%;
`

const CounterLabel = styled.span`
    display: inline-block;
    float: right;
    font-size: 12px;
    font-weight: bold;
    color: #8F8F8F;
    text-transform: uppercase;
    text-align: right;
    margin-top: 5px;
`

@connect(
    null,
    {
        hideModal,
    }
)
@withTranslation('', { wait: true })
export default class QuoteRefusalModal extends React.Component {

    MIN_LENGTH = 10
    MAX_LENGTH = 100

    constructor(props) {
        super(props)
        this.state = {
            contentLength: 0,
            reason: '',
            textareaMeta: {
                touched: true,
                error: '',
            }
        }
    }

    close = () => this.props.hideModal()

    confirm = t => {
        const { reason, contentLength, textareaMeta } = this.state
        const { callback, callbackParams } = this.props.modalProps

        if (contentLength < this.MIN_LENGTH) {
            this.setState({
                textareaMeta: {
                    ...textareaMeta,
                    error: t('projects.refusal_modal.reason_required_error_message'),
                }
            })

            return
        }

        this.props.hideModal()
        callback(callbackParams, reason)
    }

    onTextareaUpdate = (e, t) => {
        const { textareaMeta } = this.state
        this.setState({
            contentLength: e.target.value.length,
            reason: e.target.value,
            textareaMeta: {
                ...textareaMeta,
                error: e.target.value.length >= this.MIN_LENGTH && textareaMeta.error.length > 0 ? '' : textareaMeta.error
            }
        })
    }

    renderContentBody = () => {
        const { t } = this.props.modalProps
        const { contentLength, textareaMeta } = this.state

        return <React.Fragment>
            <Box.Header modifiers={[ 'pX_2', 'pT_2', 'pB_1' ]}>
                <Headline modifiers={[ 'tiny' ]}>{t('projects.refusal_modal.title')}</Headline>
            </Box.Header>
            <Box.Line />
            <Box.Content modifiers={[ 'p_2' ]}>
                <Text>
                    {t('projects.refusal_modal.body')}
                </Text>
                <FormGroup style={{ marginTop: '20px' }}>
                    <MetaLabel>{t('projects.refusal_modal.reason_text')}</MetaLabel>
                    <CounterLabel>{t('utils.textbox_characters')} ({this.MAX_LENGTH - contentLength})</CounterLabel>
                    <Textarea onKeyUp={e => this.onTextareaUpdate(e, t)} maxLength={this.MAX_LENGTH} />
                    <Error meta={textareaMeta}/>
                </FormGroup>
            </Box.Content>
            <Box.Line />
            <Flex modifiers={[ 'justifyEnd', 'p_2' ]}>
                <Button onClick={this.close} modifiers={[ 'secondary', 'bottomCenter', 'regular', 'mX_2' ]}>{t('projects.refusal_modal.cancel_text')}</Button>
                <Button onClick={() => this.confirm(t)} modifiers={[ 'action', 'bottomCenter', 'regular' ]}>{t('projects.refusal_modal.confirm_text')}</Button>
            </Flex>
        </React.Fragment>
    }

    render = () => {
        return <CenteredModal
            show={true}
            onHide={this.props.hideModal}
            className="new"
        >
            <Box>
                {this.renderContentBody()}
            </Box>
        </CenteredModal>
    }
}
