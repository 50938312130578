import { createSelector } from 'reselect'
import {
    currentPartnerSelector,
    currentClientSelector,
} from './auth'
import { permissionsSelector } from './access'

export const isLoadingPartnersGlobalSelector = createSelector(
    currentPartnerSelector,
    permissionsSelector,
    (partner, permissions) => !partner || !permissions.length
)

export const isLoadingClientsGlobalSelector = createSelector(
    currentClientSelector,
    permissionsSelector,
    (client, permissions) => !client || !permissions.length
)
