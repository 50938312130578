import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { TableCellButton } from '../../../../components/layout/buttons'
import PageTitle from '../../../../components/utils/PageTitle'
import { Page } from '../../../../components/layout/Page'
import { getLeads } from '../../../../actions/leads'
import { replace } from 'connected-react-router'

@connect(
    state => ({
        leads: state.pages.leads.leads.leadIds.map(id => state.entities.leads[id]),
        isLoading: state.pages.leads.leads.isLoading,
        clientId: state.auth.clientId,
    }),
    {
        getLeads,
        replace,
    }
)
@withTranslation('', { wait: true })
export default class LeadListPage extends React.Component {
    componentDidMount() {
        this.props.getLeads({
            clientId: this.props.clientId,
        })
            .then(res => {
                if (this.props.leads.length === 1)
                    this.props.replace(`/cases/${this.props.leads[0].id}`)
            })
    }

    render() {
        const {
            isLoading,
            leads,
            t,
        } = this.props

        if (!leads.length || isLoading)
            return null

        return (
            <Page id="lead-list-page">
                <PageTitle identifier="pages.titles.cases" />
                <table className="datatable responsive">
                    <thead>
                        <tr>
                            <th>{ t('labels.case_number') }</th>
                            <th>{ t('labels.industry')}</th>
                            <th>{ t('labels.offers') }</th>
                            <th/>
                        </tr>
                    </thead>
                    <tbody>
                        {
                        leads.map((lead, i) => (
                            <tr key={i} style={{ backgroundColor: '#fff' }}>
                                { /* Mobile table : start */ }
                                <td className="table-mobile-cell">
                                    <p>
                                        #{ lead.id }
                                    </p>
                                    <TableCellButton to={
                                        lead.quotesInfo.acceptedQuote ?
                                            `/cases/${lead.id}/${lead.quotesInfo.acceptedQuote.partner.id}` :
                                            `/cases/${lead.id}`
                                    }>
                                        { t('actions.view_case') }
                                    </TableCellButton>
                                </td>
                                { /* Mobile table : end */ }
                                <td>#{ lead.id }</td>
                                <td>{ lead.industries.secondary && lead.industries.secondary.name }</td>
                                <td>{ `${lead.quotesInfo.count} / ${lead.quotesInfo.max}` }</td>
                                <td>
                                    <TableCellButton to={
                                        lead.quotesInfo.acceptedQuote ?
                                            `/cases/${lead.id}/${lead.quotesInfo.acceptedQuote.partner.id}` :
                                            `/cases/${lead.id}`
                                    }>
                                        { t('actions.view_case') }
                                    </TableCellButton>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </Page>
        )
    }
}
