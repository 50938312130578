import React from 'react'
import { withTranslation } from 'react-i18next'
import Nl2br from '../utils/Nl2br'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'

const selector = formValueSelector('rating_reply')

@connect(
    state => ({
        leadGeoDistance: selector(state, 'comment'),
    })
)
@withTranslation('', { wait: true })
@reduxForm({
    form: 'rating_reply',
})
export default class RatingReplyForm extends React.Component {
    onSubmit(event) {
        event.preventDefault()
        this.props.saveReply(this.props.leadGeoDistance)
    }

    cancelReply(event) {
        event.preventDefault()
        this.props.cancelReply()
    }

    render() {
        const { reply, cancelReply, t } = this.props

        return <form onSubmit={this.onSubmit.bind(this)}>
            <Field name="comment" component="textarea" type="text" placeholder={t('rating.reply_save')}/>

            <div className="buttons">
                <div className="row">
                    <div className="col-md-6">
                        <button className="btn btn-action btn-block btn-lg">{t('rating.reply_save')}</button>
                    </div>
                    <div className="col-md-6">
                        <button className="btn btn-secondary btn-block btn-lg" onClick={this.cancelReply.bind(this)}>{t('rating.reply_cancel')}</button>
                    </div>
                </div>
            </div>
        </form>
    }
}
