import { actionColor, dangerColor, primaryColor, secondaryColor } from '../../../theme/variables'

export const BACKGROUND_COLOR_MODIFIERS = {
    primary: () => `
        background-color: ${primaryColor} 
    `,
    secondary: () => `
        background-color: ${secondaryColor} 
    `,
    action: () => `
        background-color: ${actionColor} 
    `,
    danger: () => `
        background-color: ${dangerColor} 
    `,
}
