import * as actions from '../../actions/certifications'

const initialState = {
    partnerCertificationIds: new Set([]),
    isLoading: false,
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    case actions.GET_PARTNER_CERTIFICATIONS:
        return {
            ...state,
            isLoading: true,
        }
    case actions.GET_PARTNER_CERTIFICATIONS_SUCCESS:

        return {
            ...state,
            partnerCertificationIds: new Set(action.response.result),
            listOfPartnersCertificationsIds: {
                ...state.listOfPartnersCertificationsIds,
                [action.partnerId]: action.response.entities.certifications ? Object.values(action.response.entities.certifications) : null,
            },
            isLoading: false,
        }
    case actions.GET_PARTNER_CERTIFICATIONS_FAIL:
        return {
            ...state,
            isLoading: false,
        }
    case actions.ADD_PARTNER_CERTIFICATION_SUCCESS:
        return {
            ...state,
            partnerCertificationIds: new Set([
                ...state.partnerCertificationIds,
                action.response.result,
            ]),
        }
    case actions.REMOVE_PARTNER_CERTIFICATION_SUCCESS:
        let set = new Set(state.partnerCertificationIds)
        set.delete(action.certificationId)

        return {
            ...state,
            partnerCertificationIds: set,
        }
    default:
        return state
    }
}
