import React, { memo, cloneElement } from 'react'

const Rows = memo(({
    noDataText,
    children,
    items,
}) =>
    <tbody>
        {items.length
            ? items.map(rowData => cloneElement(children, { rowData }))
            : <tr>
                <td colSpan={100} className="text-center">{noDataText}</td>
            </tr>}
    </tbody>
)

Rows.displayName = 'Rows'

export default Rows
