import React from 'react'
import TransactionItem from './TransactionItem'
import { withTranslation } from 'react-i18next'

@withTranslation('', { wait: true })
export default class TransactionList extends React.Component {
    render() {
        const {
            transactions, t,
        } = this.props

        return (
            <table className="datatable responsive">
                <thead>
                    <tr>
                        <th className="table-mobile-cell" />
                        <th>{ t('transactions.date') }</th>
                        <th>{ t('transactions.coupon') }</th>
                        <th style={{ textAlign: 'right' }}>{ t('transactions.punches') }</th>
                        <th>{ t('transactions.type') }</th>
                    </tr>
                </thead>
                <tbody>
                    {transactions.map(transaction =>
                        <TransactionItem key={transaction.id} transaction={transaction} />
                )}
                </tbody>
            </table>
        )
    }
}
