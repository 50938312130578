import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import TransactionList from '../transactions/TransactionList'
import * as transactions from '../../actions/transactions'
import { currentPartnerSelector } from '../../selectors/auth'
import { currentPageSelector } from '../../selectors/routing'
import GlobalPagination from '../utils/GlobalPagination'
import LoadingAnimation from '../utils/LoadingAnimation'
import PageTitle from '../utils/PageTitle'

@connect(
    state => ({
        transactions: state.pages.transactions.items.map(id => state.entities.transactions[id]),
        pagination: state.pages.transactions.pagination,
        page: currentPageSelector(state),
        isLoading: state.pages.transactions.isLoading,
        partner: currentPartnerSelector(state),
    }),
    dispatch => ({
        getTransactions: bindActionCreators(transactions.getTransactions, dispatch),
    })
)
@withTranslation('', { wait: true })
export default class TransactionListPage extends React.Component {
    componentDidMount() {
        this.props.getTransactions(this.props.partner, this.props.page)
    }

    componentWillReceiveProps(newProps) {
        if(this.props.page !== newProps.page) {
            newProps.getTransactions(newProps.partner, newProps.page)
        }
    }

    render() {
        const {
            isLoading,
            pagination: { pages },
            transactions,
            t,
        } = this.props

        return (
            <div className="card">
                <PageTitle identifier="transactions.title" />
                <h2>{t('transactions.title')}</h2>

                <p className="intro-text">
                    {t('transactions.intro')}
                </p>

                { isLoading ? <LoadingAnimation /> : <div>
                    <TransactionList transactions={transactions} />
                    <hr />
                    <GlobalPagination pages={pages} />
                </div> }
            </div>
        )
    }
}
