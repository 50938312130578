import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const GET_PARTNER_PROFILE_DATA_FIELDS = 'datafield/GET_PARTNER_PROFILE_DATA_FIELDS'
export const GET_PARTNER_PROFILE_DATA_FIELDS_SUCCESS = 'datafield/GET_PARTNER_PROFILE_DATA_FIELDS_SUCCESS'
export const GET_PARTNER_PROFILE_DATA_FIELDS_FAIL = 'datafield/GET_PARTNER_PROFILE_DATA_FIELDS_FAIL'

export const GET_DATA_FIELDS = 'datafield/GET_DATA_FIELDS'
export const GET_DATA_FIELDS_SUCCESS = 'datafield/GET_DATA_FIELDS_SUCCESS'
export const GET_DATA_FIELDS_FAIL = 'datafield/GET_DATA_FIELDS_FAIL'

export const getDataFields = (body, types) => ({
    [CALL_API]: {
        types: types || [ GET_DATA_FIELDS, GET_DATA_FIELDS_SUCCESS, GET_DATA_FIELDS_FAIL ],
        endpoint: '/datafields',
        schema: schemas.dataFields,
        options: {
            body: {
                ...body,
                limit: 1000,
            },
        },
    },
})

export const getPartnerProfileDataFields = criteria => getDataFields(
    { ...criteria, type: 'partner' },
    [ GET_PARTNER_PROFILE_DATA_FIELDS, GET_PARTNER_PROFILE_DATA_FIELDS_SUCCESS, GET_PARTNER_PROFILE_DATA_FIELDS_FAIL ],
)
