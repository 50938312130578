import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'
import config from '../config'
import { decamelizeKeys } from 'humps'
import { getLead } from './leads'

export const CREATE_QUOTE = 'quotes/CREATE_QUOTE'
export const CREATE_QUOTE_SUCCESS = 'quotes/CREATE_QUOTE_SUCCESS'
export const CREATE_QUOTE_FAIL = 'quotes/CREATE_QUOTE_FAIL'

export const GET_QUOTES = 'quotes/GET_QUOTES'
export const GET_QUOTES_SUCCESS = 'quotes/GET_QUOTES_SUCCESS'
export const GET_QUOTES_FAIL = 'quotes/GET_QUOTES_FAIL'

export const GET_QUOTE = 'quotes/GET_QUOTE'
export const GET_QUOTE_SUCCESS = 'quotes/GET_QUOTE_SUCCESS'
export const GET_QUOTE_FAIL = 'quotes/GET_QUOTE_FAIL'

export const PUBLISH_QUOTE = 'quotes/PUBLISH_QUOTE'
export const PUBLISH_QUOTE_SUCCESS = 'quotes/PUBLISH_QUOTE_SUCCESS'
export const PUBLISH_QUOTE_FAIL = 'quotes/PUBLISH_QUOTE_FAIL'

export const ACCEPT_QUOTE = 'quotes/ACCEPT_QUOTE'
export const ACCEPT_QUOTE_SUCCESS = 'quotes/ACCEPT_QUOTE_SUCCESS'
export const ACCEPT_QUOTE_FAIL = 'quotes/ACCEPT_QUOTE_FAIL'

export const ACTION_QUOTE_REFUSE = 'leads/ACTION_QUOTE_REFUSE'
export const ACTION_QUOTE_REFUSE_SUCCESS = 'leads/ACTION_QUOTE_REFUSE_SUCCESS'
export const ACTION_QUOTE_REFUSE_FAIL = 'leads/ACTION_QUOTE_REFUSE_FAIL'

export const UPDATE_QUOTE = 'quotes/UPDATE_QUOTE'
export const UPDATE_QUOTE_SUCCESS = 'quotes/UPDATE_QUOTE_SUCCESS'
export const UPDATE_QUOTE_FAIL = 'quotes/UPDATE_QUOTE_FAIL'

export const DELETE_QUOTE_ITEM = 'quotes/DELETE_QUOTE_ITEM'
export const DELETE_QUOTE_ITEM_SUCCESS = 'quotes/DELETE_QUOTE_ITEM_SUCCESS'
export const DELETE_QUOTE_ITEM_FAIL = 'quotes/DELETE_QUOTE_ITEM_FAIL'

export const ADD_QUOTE_ITEM = 'quotes/ADD_QUOTE_ITEM'
export const ADD_QUOTE_ITEM_SUCCESS = 'quotes/ADD_QUOTE_ITEM_SUCCESS'
export const ADD_QUOTE_ITEM_FAIL = 'quotes/ADD_QUOTE_ITEM_FAIL'

export const UPDATE_QUOTE_ITEM = 'quotes/UPDATE_QUOTE_ITEM'
export const UPDATE_QUOTE_ITEM_SUCCESS = 'quotes/UPDATE_QUOTE_ITEM_SUCCESS'
export const UPDATE_QUOTE_ITEM_FAIL = 'quotes/UPDATE_QUOTE_ITEM_FAIL'

export const GET_QUOTE_PROGRESS_STEPS = 'quotes/GET_QUOTE_PROGRESS_STEPS'
export const GET_QUOTE_PROGRESS_STEPS_SUCCESS = 'quotes/GET_QUOTE_PROGRESS_STEPS_SUCCESS'
export const GET_QUOTE_PROGRESS_STEPS_FAIL = 'quotes/GET_QUOTE_PROGRESS_STEPS_FAIL'

export const CREATE_CUSTOMER_CALLED_ACTION = 'quotes/CREATE_LEAD_CALLED_ACTION'
export const CREATE_CUSTOMER_CALLED_ACTION_SUCCESS = 'quotes/CREATE_LEAD_CALLED_ACTION_SUCCESS'
export const CREATE_CUSTOMER_CALLED_ACTION_FAIL = 'quotes/CREATE_LEAD_CALLED_ACTION_FAIL'

export const GET_RESERVED_QUOTES = 'quotes/GET_RESERVED_QUOTES'
export const GET_RESERVED_QUOTES_SUCCESS = 'quotes/GET_RESERVED_QUOTES_SUCCESS'
export const GET_RESERVED_QUOTES_FAIL = 'quotes/GET_RESERVED_QUOTES_FAIL'

export const createQuote = (leadId, partnerId, data) => dispatch => dispatch({
    [CALL_API]: {
        types: [ CREATE_QUOTE, CREATE_QUOTE_SUCCESS, CREATE_QUOTE_FAIL ],
        endpoint: `/leads/${leadId}/quotes`,
        schema: schemas.quote,
        options: {
            method: 'post',
            body: {
                ...data,
                partner: {
                    id: partnerId,
                },
            },
        },
    },
}).then(response => {
    dispatch(getLead(leadId))
    return response
})

export const getQuotes = criteria => ({
    [CALL_API]: {
        types: [ GET_QUOTES, GET_QUOTES_SUCCESS, GET_QUOTES_FAIL ],
        endpoint: `/leads/${criteria.leadId}/quotes`,
        options: {
            body: criteria,
        },
        schema: schemas.quotes,
    },
})

export const getQuote = (leadId, quoteId) => ({
    [CALL_API]: {
        types: [ GET_QUOTE, GET_QUOTE_SUCCESS, GET_QUOTE_FAIL ],
        endpoint: `/leads/${leadId}/quotes/${quoteId}`,
        schema: schemas.quote,
    },
})

export const publishQuote = (leadId, quoteId) => dispatch => dispatch({
    [CALL_API]: {
        types: [ PUBLISH_QUOTE, PUBLISH_QUOTE_SUCCESS, PUBLISH_QUOTE_FAIL ],
        endpoint: `/leads/${leadId}/quotes/${quoteId}/actions`,
        schema: schemas.quote,
        options: {
            method: 'post',
            body: {
                action: 'publish',
            },
        },
    },
}).then(() => dispatch(getQuote(leadId, quoteId)))

export const markQuoteAccepted = (leadId, quoteId) => dispatch => dispatch({
    [CALL_API]: {
        types: [ ACCEPT_QUOTE, ACCEPT_QUOTE_SUCCESS, ACCEPT_QUOTE_FAIL ],
        endpoint: `/leads/${leadId}/quotes/${quoteId}/actions`,
        schema: schemas.quote,
        options: {
            method: 'post',
            body: {
                action: 'accept',
            },
        },
    },
}).then(() => dispatch(getQuote(leadId, quoteId)))

export const acceptQuote = (leadId, quoteId, stripeToken) => ({
    [CALL_API]: {
        types: [ ACCEPT_QUOTE, ACCEPT_QUOTE_SUCCESS, ACCEPT_QUOTE_FAIL ],
        endpoint: `${config.agerasServices}/stripe/accept-quote`,
        schema: schemas.acceptQuote,
        options: {
            method: 'post',
            body: {
                stripe_token: stripeToken,
                lead_id: leadId,
                quote_id: quoteId,
            },
        },
    },
})

export const refuseQuote = (leadId, quoteId, refusal_reason) => ({
    [CALL_API]: {
        types: [ ACTION_QUOTE_REFUSE, ACTION_QUOTE_REFUSE_SUCCESS, ACTION_QUOTE_REFUSE_FAIL ],
        endpoint: `/leads/${leadId}/quotes/${quoteId}/actions`,
        schema: schemas.lead,
        options: {
            method: 'post',
            body: {
                action: 'refuse',
                refusal_reason: refusal_reason
            },
        },
    },
    quoteId,
})

export const updateQuote = (leadId, quoteId, body) => dispatch => dispatch({
    [CALL_API]: {
        types: [ UPDATE_QUOTE, UPDATE_QUOTE_SUCCESS, UPDATE_QUOTE_FAIL ],
        endpoint: `/leads/${leadId}/quotes/${quoteId}`,
        schema: schemas.quote,
        options: {
            method: 'put',
            body: decamelizeKeys(body),
        },
    },
})

export const addQuoteItems = (leadId, quoteId, body) => ({
    [CALL_API]: {
        types: [ ADD_QUOTE_ITEM, ADD_QUOTE_ITEM_SUCCESS, ADD_QUOTE_ITEM_FAIL ],
        endpoint: `/leads/${leadId}/quotes/${quoteId}/items`,
        schema: schemas.quoteItem,
        options: {
            method: 'post',
            body: body,
        },
    },
    quoteId,
})

export const deleteQuoteItem = (leadId, quoteId, itemId) => ({
    [CALL_API]: {
        types: [ DELETE_QUOTE_ITEM, DELETE_QUOTE_ITEM_SUCCESS, DELETE_QUOTE_ITEM_FAIL ],
        endpoint: `/leads/${leadId}/quotes/${quoteId}/items/${itemId}`,
        options: {
            method: 'delete',
        },
    },
    quoteId,
    itemId,
})

export const updateQuoteItem = (leadId, quoteId, body) => ({
    [CALL_API]: {
        types: [ UPDATE_QUOTE_ITEM, UPDATE_QUOTE_ITEM_SUCCESS, UPDATE_QUOTE_ITEM_FAIL ],
        endpoint: `/leads/${leadId}/quotes/${quoteId}/items/${body.id}`,
        schema: schemas.quoteItem,
        options: {
            method: 'put',
            body: body,
        },
    },
})

export const getQuotesProgresses = () => ({
    [CALL_API]: {
        types: [ GET_QUOTE_PROGRESS_STEPS, GET_QUOTE_PROGRESS_STEPS_SUCCESS, GET_QUOTE_PROGRESS_STEPS_FAIL ],
        endpoint: '/leads/quotes/progresssteps',
    },
})

export const createCustomerCalledAction = (leadId, quoteId) => dispatch => dispatch({
    [CALL_API]: {
        types: [ CREATE_CUSTOMER_CALLED_ACTION, CREATE_CUSTOMER_CALLED_ACTION_SUCCESS, CREATE_CUSTOMER_CALLED_ACTION_FAIL ],
        endpoint: `/leads/${leadId}/quotes/${quoteId}/actions`,
        schema: schemas.quote,
        options: {
            method: 'post',
            body: {
                action: 'called',
                quote_id: quoteId,
            },
        },
    },
})

export const getListOfReservedQuotes = partnerId => ({
    [CALL_API]: {
        types: [ GET_RESERVED_QUOTES, GET_RESERVED_QUOTES_SUCCESS, GET_RESERVED_QUOTES_FAIL ],
        endpoint: '/leads/quotes',
        schema: schemas.quotes,
        options: {
            body: {
                partnerId,
                isPublished: '0',
                isRejected: '0',
                reservationIsExpired: '0',
            },
        },
    },
})
