import SubscriptionWidget from '../stripe-subscription/SubscriptionWidget'
import PaymentMethodWidget from '../stripe-subscription/PaymentMethodWidget'
import LoadingAnimation from '../utils/LoadingAnimation';
import useHandleStripePaymentMethodRedirect from '../stripe-subscription/useHandleStripePaymentMethodRedirect';

export default function StripeSubscriptionPage ({
    partner
}) {
    const { isLoading: isLoadingRedirect } = useHandleStripePaymentMethodRedirect(partner);

    return (
        <div>
            {isLoadingRedirect && (
                <LoadingAnimation />
            )}
            {!isLoadingRedirect && (
                <div>
                    <SubscriptionWidget partner={partner}/>

                    <hr />

                    <PaymentMethodWidget partner={partner}/>
                </div>
            )}
        </div>
    )
}
