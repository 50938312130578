import React, { ChangeEvent } from 'react'
import styled, { css } from 'styled-components'
import { darken } from 'polished'
import Flex from '../../layout/Flex'

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`

interface StyledCheckboxProps {
    disabled?: boolean
    checked: boolean
}

export const PrimitiveCheckbox = styled.input.attrs({ type: 'checkbox' })`
    appearance: none;
    margin-top: 0 !important;
    display: inline-block;
    height: 22px;
    width: 22px;
    border-radius: 4px;
    overflow: hidden;
    ${({ theme }) => theme.inputs.checkboxes.styles};

    &::after {
        content: "";
        display: block;
        left: 50%;
        top: 50%;
        width: 8px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg) translate(85%,-18%);
    }

    &:hover {
        background-color: ${({ theme }) => darken(.1, theme.colors.lightGrey)};
        cursor: pointer;
    }

    ${({ checked, theme }) => checked && css`
        background-color: ${theme.colors.baseBlue} !important;
    `}

    ${({ disabled }) => disabled && `
        opacity: .5;
        cursor: not-allowed !important;
    `}
`

const StyledCheckbox = styled(Flex) <StyledCheckboxProps>`
    display: inline-block;
    height: 22px;
    width: 22px;
    border-radius: 4px;
    overflow: hidden;
    ${({ theme }) => theme.inputs.checkboxes.styles};

    &::after {
        content: "";
        display: block;
        left: 50%;
        top: 50%;
        width: 4px;
        height: 8px;
        box-sizing: content-box;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg) translate(125%, -20%);
    }

    &:hover {
        background-color: ${({ theme }) => darken(.1, theme.colors.lightGrey)};
        cursor: pointer;
    }

    ${({ checked, theme }) => checked && css`
        background-color: ${theme.colors.baseBlue} !important;
    `}

    ${({ disabled }) => disabled && `
        opacity: .5;
        cursor: not-allowed !important;
    `}
`

export interface CheckboxProps {
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    name?: string
    required?: boolean
    disabled?: boolean
    value: boolean
}

export function Checkbox({
    name,
    onChange,
    required,
    disabled,
    value,
    ...props
}: CheckboxProps) {
    return (
        <StyledCheckbox
            aria-hidden="true"
            disabled={disabled}
            checked={value}
        >
            <HiddenCheckbox
                name={name}
                required={required}
                onChange={onChange}
                disabled={disabled}
                checked={value}
                {...props}
            />
        </StyledCheckbox>
    )
}
