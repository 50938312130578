import React from 'react'
import styled from 'styled-components'
import { MODIFIER_MARGIN_CONFIG } from './modifiers/spaceModifiers'
import { applyStyleModifiers } from 'styled-components-modifiers'
import ColorHash from 'color-hash'

const Image = styled.div`
    background: url(${props => props.image}) center ${props => props.hash};
    line-height: ${props => props.size || '40'}px;
    height: ${props => props.size || '40'}px;
    width: ${props => props.size || '40'}px;
    background-size: cover;
    letter-spacing: 2px;
    border-radius: ${props => props.square ? '9px' : '100%'};
    text-align: center;
    color: #fff;
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
`

const Portrait = ({
    name = '',
    className,
    ...props
}) => {
    const colorHash = new ColorHash()
    let initials = name.match(/\b\w/g) || []
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()

    return (
        <Image
            className={className}
            hash={colorHash.hex(name)}
            {...props}
        >
            {props.image ? '' : initials}
        </Image>
    )
}

export default styled(Portrait)``
