import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const GET_PARTNER_CONTRACTS = 'partner/GET_PARTNER_CONTRACTS'
export const GET_PARTNER_CONTRACTS_SUCCESS = 'partner/GET_PARTNER_CONTRACTS_SUCCESS'
export const GET_PARTNER_CONTRACTS_FAIL = 'partner/GET_PARTNER_CONTRACTS_FAIL'

export const GET_PARTNER_CONTRACT = 'partner/GET_PARTNER_CONTRACT'
export const GET_PARTNER_CONTRACT_SUCCESS = 'partner/GET_PARTNER_CONTRACT_SUCCESS'
export const GET_PARTNER_CONTRACT_FAIL = 'partner/GET_PARTNER_CONTRACT_FAIL'

export const CREATE_PARTNER_CONTRACT_PAUSE = 'partnerContracts/CREATE_PARTNER_CONTRACT_PAUSE'
export const CREATE_PARTNER_CONTRACT_PAUSE_SUCCESS = 'partnerContracts/CREATE_PARTNER_CONTRACT_PAUSE_SUCCESS'
export const CREATE_PARTNER_CONTRACT_PAUSE_FAIL = 'partnerContracts/CREATE_PARTNER_CONTRACT_PAUSE_FAIL'

export const DOWNLOAD_PARTNER_CONTRACTS = 'partner/DOWNLOAD_PARTNER_CONTRACTS'
export const DOWNLOAD_PARTNER_CONTRACTS_SUCCESS = 'partner/DOWNLOAD_PARTNER_CONTRACTS_SUCCESS'
export const DOWNLOAD_PARTNER_CONTRACTS_FAIL = 'partner/DOWNLOAD_PARTNER_CONTRACTS_FAIL'

export const DOWNLOAD_SIGNED_PARTNER_CONTRACTS = 'partner/DOWNLOAD_SIGNED_PARTNER_CONTRACTS'
export const DOWNLOAD_SIGNED_PARTNER_CONTRACTS_SUCCESS = 'partner/DOWNLOAD_SIGNED_PARTNER_CONTRACTS_SUCCESS'
export const DOWNLOAD_SIGNED_PARTNER_CONTRACTS_FAIL = 'partner/DOWNLOAD_SIGNED_PARTNER_CONTRACTS_FAIL'

export const GET_PARTNER_CONTRACT_SIGNING_URL = 'partner/GET_PARTNER_CONTRACT_SIGNING_URL'
export const GET_PARTNER_CONTRACT_SIGNING_URL_SUCCESS = 'partner/GET_PARTNER_CONTRACT_SIGNING_URL_SUCCESS'
export const GET_PARTNER_CONTRACT_SIGNING_URL_FAIL = 'partner/GET_PARTNER_CONTRACT_SIGNING_URL_FAIL'

export const ACCEPT_PARTNER_CONTRACT = 'partner/ACCEPT_PARTNER_CONTRACT'
export const ACCEPT_PARTNER_CONTRACT_SUCCESS = 'partner/ACCEPT_PARTNER_CONTRACT_SUCCESS'
export const ACCEPT_PARTNER_CONTRACT_FAIL = 'partner/ACCEPT_PARTNER_CONTRACT_FAIL'

export const getPartnerContracts = (partnerId, criteria) => ({
    [CALL_API]: {
        types: [ GET_PARTNER_CONTRACTS, GET_PARTNER_CONTRACTS_SUCCESS, GET_PARTNER_CONTRACTS_FAIL ],
        endpoint: `/partners/${partnerId}/contracts`,
        schema: schemas.partnerContracts,
        options: {
            body: criteria,
        },
    },
})

export const getContract = (partnerId, contractId) => ({
    [CALL_API]: {
        types: [ GET_PARTNER_CONTRACT, GET_PARTNER_CONTRACT_SUCCESS, GET_PARTNER_CONTRACT_FAIL ],
        endpoint: `/partners/${partnerId}/contracts/${contractId}`,
        schema: schemas.partnerContract,
    },
})

export const createPartnerContractPause = (partnerId, body) => ({
    [CALL_API]: {
        types: [
            CREATE_PARTNER_CONTRACT_PAUSE,
            CREATE_PARTNER_CONTRACT_PAUSE_SUCCESS,
            CREATE_PARTNER_CONTRACT_PAUSE_FAIL,
        ],
        endpoint: `/partners/${partnerId}/allocationpauses`,
        schema: schemas.partnerContractPause,
        options: {
            method: 'post',
            body,
        },
    },
})

export const updatePartnerContractPause = (partnerId, contractId, pauseId, body) => ({
    [CALL_API]: {
        types: [
            CREATE_PARTNER_CONTRACT_PAUSE,
            CREATE_PARTNER_CONTRACT_PAUSE_SUCCESS,
            CREATE_PARTNER_CONTRACT_PAUSE_FAIL,
        ],
        endpoint: `/partners/${partnerId}/contracts/${contractId}/allocationpauses/${pauseId}`,
        schema: schemas.partnerContractPause,
        options: {
            method: 'put',
            body,
        },
    },
})

export const deletePartnerContractPause = (partnerId, contractId, pauseId) => ({
    [CALL_API]: {
        types: [
            CREATE_PARTNER_CONTRACT_PAUSE,
            CREATE_PARTNER_CONTRACT_PAUSE_SUCCESS,
            CREATE_PARTNER_CONTRACT_PAUSE_FAIL,
        ],
        endpoint: `/partners/${partnerId}/contracts/${contractId}/allocationpauses/${pauseId}`,
        schema: schemas.partnerContractPause,
        options: {
            method: 'delete',
        },
    }
})

export const acceptPartnerContract = (partnerId, contractId) => dispatch => dispatch({
    [CALL_API]: {
        types: [ACCEPT_PARTNER_CONTRACT, ACCEPT_PARTNER_CONTRACT_SUCCESS, ACCEPT_PARTNER_CONTRACT_FAIL],
        endpoint: `/partners/${partnerId}/contracts/${contractId}/actions`,
        schema: schemas.partnerContract,
        options: {
            method: 'post',
            body: {
                action: 'accept',
            },
        },
    },
}).then(() => dispatch(getPartnerContracts(partnerId, {})))

export const downloadPartnerContract = (partnerId, contractId) => ({
    [CALL_API]: {
        types: [DOWNLOAD_PARTNER_CONTRACTS, DOWNLOAD_PARTNER_CONTRACTS_SUCCESS, DOWNLOAD_PARTNER_CONTRACTS_FAIL],
        endpoint: `/partners/${partnerId}/contracts/${contractId}/pdf`,
    },
})

export const downloadSignedPartnerContract = (partnerId, contractId) => ({
    [CALL_API]: {
        types: [DOWNLOAD_SIGNED_PARTNER_CONTRACTS, DOWNLOAD_SIGNED_PARTNER_CONTRACTS_SUCCESS, DOWNLOAD_SIGNED_PARTNER_CONTRACTS_FAIL],
        endpoint: `/partners/${partnerId}/contracts/${contractId}/signedpdf`,
    },
})

export const generatePartnerContractSigningUrl = (partnerId, contractId) => ({
    [CALL_API]: {
        types: [GET_PARTNER_CONTRACT_SIGNING_URL, GET_PARTNER_CONTRACT_SIGNING_URL_SUCCESS, GET_PARTNER_CONTRACT_SIGNING_URL_FAIL],
        endpoint: `/partners/${partnerId}/contracts/${contractId}/signingurl`,
    },
})
