import React from 'react'
import { MapPin } from 'react-feather'
import { toastr } from 'react-redux-toastr'
import Box from '../../blocks/Box'
import { Flex } from '../../elements/Flex'
import { CenteredModal } from './styles'
import { Headline, Span, Text } from '../../elements/text/Text'
import { Button, TextButton } from '../../elements/ButtonNew'
import config from '../../../config'
import { connect } from 'react-redux'
import { hideModal } from '../../../actions/modal'
import { leadActionRecordBuyFromMarketplace } from '../../../actions/leads'
import { replace } from 'connected-react-router'
import { getProjects } from '../../../actions/projects'
import { withTranslation, Trans } from 'react-i18next'

@connect(
    (state) => ({
        partner: state.entities.partners[state.modal.partnerId],
        lead: state.entities.leads[state.modal.leadId],
    }),
    {
        hideModal,
        getProjects,
        leadActionRecordBuyFromMarketplace,
        replace,
    }
)
@withTranslation('', { wait: true })
export default class UnlockConfirmModal extends React.Component {
    confirmUnlock = async () => {
        const { lead, partner } = this.props
        try {
            await this.props.leadActionRecordBuyFromMarketplace(lead.id, partner.id, lead.quotesInfo.quoteFee.quoteFee)
            const projectsResponse = await this.props.getProjects({ leadId: lead.id, partnerId: partner.id })
            this.props.replace({ pathname: '/projects/' + projectsResponse.result[0] })
        } catch (err) {
            toastr.error('Error!', err.error)
        } finally {
            this.props.hideModal()
        }
    }

    render() {
        const { lead, t } = this.props

        return <CenteredModal
            show={true}
            onHide={this.props.hideModal}
            className="new"
        >
            <Box>
                <Box.Header modifiers={[ 'pX_2', 'pT_2', 'pB_1' ]}>
                    <Headline modifiers={[ 'tiny' ]}>Confirm Purchase of Lead</Headline>
                </Box.Header>
                <Box.Line />
                <Box.Content modifiers={[ 'p_2' ]}>
                    <Text>
                        <Trans
                            i18nKey="marketplace.confirm_text"
                            price={<Span modifiers={[ 'bold', 'blue' ]}>{lead.quotesInfo.quoteFee.quoteFee.amount} {lead.quotesInfo.quoteFee.quoteFee.currency}</Span>}
                        />
                    </Text>
                </Box.Content>
                <Box.Line />
                <Flex modifiers={[ 'justifyEnd', 'p_2' ]}>
                    <Button onClick={this.confirmUnlock} modifiers={[ 'action', 'bottomLeft', 'regular', 'bold' ]}>{t('marketplace.controls.confirm')}</Button>
                </Flex>
            </Box>
        </CenteredModal>
    }
}
