import React from 'react'
import styled, { css } from 'styled-components'
import { ChevronLeft, ChevronRight } from 'react-feather'
import Flex, { FlexProps } from '../layout/Flex'

interface ChevronButtonProps {
    direction: 'left' | 'right'
    disabled?: boolean
}

export const CircleButton = styled(Flex)<FlexProps>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
`

const ChevronButton = styled(CircleButton)<ChevronButtonProps>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    ${({ theme }) => theme.chevron.styles.default};

    ${({ direction }) => direction === 'left' ? css`
        & > svg {
            margin-right: 2px;
        }
    ` : css`
        & > svg {
            margin-left: 2px;
        }
    `};

    ${({ disabled, theme }) => disabled && css`
        ${theme.chevron.variants.disabled};
        cursor: not-allowed;
        & > svg {
            pointer-events: none;
        }
    `}
`

export interface ChevronProps extends ChevronButtonProps {
    onClick: () => any
}

const Chevron = ({
    disabled,
    direction,
    onClick,
}: ChevronProps & FlexProps) => <ChevronButton disabled={disabled} direction={direction}>
    {direction === 'left' && <ChevronLeft onClick={onClick} />}
    {direction === 'right' && <ChevronRight onClick={onClick} />}
</ChevronButton>

export default Chevron
