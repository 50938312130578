import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as certifications from '../../actions/certifications'
import { currentPartnerSelector, currentPartnerUserSelector } from '../../selectors/auth'
import * as cvAction from '../../actions/cv'
import ActionCheckBox from '../utils/ActionCheckBox'
import LoadingAnimation from '../utils/LoadingAnimation'
import EducationItem from '../marketing/EducationItem'
import SocialMediaItem from '../marketing/SocialMediaItem'
import ExpertiseItem from '../marketing/ExpertiseItem'
import CourseItem from '../marketing/CourseItem'
import ExperienceItem from '../marketing/ExperienceItem'
import AwardItem from '../marketing/AwardItem'
import ExpandButton from '../utils/ExpandButton'
import DescriptionForm from '../cv/DescriptionForm'
import EducationForm from '../cv/EducationForm'
import ExperienceForm from '../cv/ExperienceForm'
import SocialMediaForm from '../cv/SocialMediaForm'
import CourseForm from '../cv/CourseForm'
import ExpertiseForm from '../cv/ExpertiseForm'
import AwardForm from '../cv/AwardForm'
import PageTitle from '../utils/PageTitle'
import { Card } from '../blocks/Card'

@withTranslation('', { wait: true })
@connect(
    state => ({
        partnerCv: state.entities.cvs[currentPartnerUserSelector(state).id],
        partner: currentPartnerSelector(state),
        partnerUser: currentPartnerUserSelector(state),
        partnerCertifications: state.pages.certifications.listOfPartnersCertificationsIds?.[currentPartnerSelector(state).id],
        certifications: Object.values(state.entities.certifications),
    }),
    dispatch => ({
        getCv: bindActionCreators(cvAction.getCv, dispatch),
        getCertifications: bindActionCreators(certifications.getCertifications, dispatch),

        saveCvDescription: bindActionCreators(cvAction.saveDescription, dispatch),

        saveSocialMedia: bindActionCreators(cvAction.saveSocialMedia, dispatch),

        addCertificationToPartner: bindActionCreators(certifications.addPartnerCertification, dispatch),
        removeCertificationFromPartner: bindActionCreators(certifications.removePartnerCertification, dispatch),
        getPartnerCertifications: bindActionCreators(certifications.getPartnerCertifications, dispatch),

        createExpertise: bindActionCreators(cvAction.createExpertise, dispatch),
        updateExpertise: bindActionCreators(cvAction.updateExpertise, dispatch),
        deleteExpertise: bindActionCreators(cvAction.deleteExpertise, dispatch),

        createExperience: bindActionCreators(cvAction.createExperience, dispatch),
        updateExperience: bindActionCreators(cvAction.updateExperience, dispatch),
        deleteExperience: bindActionCreators(cvAction.deleteExperience, dispatch),

        addEducationToPartnerUser: bindActionCreators(cvAction.addEducationToPartnerUser, dispatch),
        updateEducation: bindActionCreators(cvAction.updateEducation, dispatch),
        deleteEducation: bindActionCreators(cvAction.deleteEducation, dispatch),

        addCourseToPartnerUser: bindActionCreators(cvAction.addCourseToPartnerUser, dispatch),
        updateCourse: bindActionCreators(cvAction.updateCourse, dispatch),
        deleteCourse: bindActionCreators(cvAction.deleteCourse, dispatch),

        addAwardToPartnerUser: bindActionCreators(cvAction.addAwardToPartnerUser, dispatch),
        updateAward: bindActionCreators(cvAction.updateAward, dispatch),
        deleteAward: bindActionCreators(cvAction.deleteAward, dispatch),

        createLanguage: bindActionCreators(cvAction.createLanguage, dispatch),
        deleteLanguage: bindActionCreators(cvAction.deleteLanguage, dispatch),

    })
)
export default class CVPage extends React.Component {
    componentDidMount() {
        this.refreshPartnerCv(this.props)
        const partner = this.props.partner
        this.props.getCertifications({ geo_code: partner.geo.code })
        this.props.getPartnerCertifications(this.props.partner.id)
    }

    refreshPartnerCv() {
        this.props.getCv(this.props.partner.id, this.props.partnerUser.id)
    }

    onDescriptionSubmit = data => {
        this.props.saveCvDescription(this.props.partnerUser.id, data).then(response => {
            this.refreshPartnerCv()
            this.refs.descriptionExpandButton.toggleExpand()
        })
    }

    onExpertiseSubmit = data => {
        this.props.createExpertise(this.props.partnerUser.id, data).then(response => {
            this.refreshPartnerCv()
            this.refs.expertiseExpandButton.toggleExpand()
        })
    }

    onExpertiseUpdate = data => {
        this.props.updateExpertise(this.props.partnerUser.id, data).then(response => {
            this.refreshPartnerCv()
        })
    }

    onExpertiseDelete = id => {
        this.props.deleteExpertise(this.props.partnerUser.id, id).then(response => {
            this.refreshPartnerCv()
        })
    }

    onEducationSubmit = data => {
        data.fromDate = data.fromDateYear + '-' + data.fromDateMonth
        if(data.toDateYear) {
            data.toDate = data.toDateYear + '-' + data.toDateMonth
        }
        this.props.addEducationToPartnerUser(this.props.partnerUser.id, data).then(response => {
            this.refreshPartnerCv()
            this.refs.educationExpandButton.toggleExpand()
        })
    }

    onEducationUpdate = data => {
        data.fromDate = data.fromDateYear + '-' + data.fromDateMonth
        if(data.toDateYear) {
            data.toDate = data.toDateYear + '-' + data.toDateMonth
        }

        this.props.updateEducation(this.props.partnerUser.id, data).then(response => {
            this.refreshPartnerCv()
        })
    }

    onEducationDelete = id => {
        this.props.deleteEducation(this.props.partnerUser.id, id).then(response => {
            this.refreshPartnerCv()
        })
    }

    onExperienceSubmit = data => {
        data.fromDate = data.fromDateYear + '-' + data.fromDateMonth

        if(data.toDateYear) {
            data.toDate = data.toDateYear + '-' + data.toDateMonth
        }
        this.props.createExperience(this.props.partnerUser.id, data).then(response => {
            this.refreshPartnerCv()
            this.refs.experienceExpandButton.toggleExpand()
        })
    }

    onExperienceUpdate = data => {
        data.fromDate = data.fromDateYear + '-' + data.fromDateMonth

        if(data.toDateYear) {
            data.toDate = data.toDateYear + '-' + data.toDateMonth
        }
        this.props.updateExperience(this.props.partnerUser.id, data).then(response => {
            this.refreshPartnerCv()
        })
    }

    onExperienceDelete = id => {
        this.props.deleteExperience(this.props.partnerUser.id, id).then(response => {
            this.refreshPartnerCv()
        })
    }

    onCourseSubmit = data => {
        data.fromDate = data.fromDateYear + '-' + data.fromDateMonth
        this.props.addCourseToPartnerUser(this.props.partnerUser.id, data).then(response => {
            this.refreshPartnerCv()
            this.refs.courseExpandButton.toggleExpand()
        })
    }

    onCourseUpdate = data => {
        data.fromDate = data.fromDateYear + '-' + data.fromDateMonth
        this.props.updateCourse(this.props.partnerUser.id, data).then(response => {
            this.refreshPartnerCv()
        })
    }

    onCourseDelete = id => {
        this.props.deleteCourse(this.props.partnerUser.id, id).then(response => {
            this.refreshPartnerCv()
        })
    }

    onAwardSubmit = data => {
        data.rewardDate = data.fromDateYear + '-' + data.fromDateMonth
        this.props.addAwardToPartnerUser(this.props.partnerUser.id, data).then(response => {
            this.refreshPartnerCv()
            this.refs.awardExpandButton.toggleExpand()
        })
    }

    onAwardUpdate = data => {
        data.rewardDate = data.fromDateYear + '-' + data.fromDateMonth
        this.props.updateAward(this.props.partnerUser.id, data).then(response => {
            this.refreshPartnerCv()
        })
    }

    onAwardDelete = id => {
        this.props.deleteAward(this.props.partnerUser.id, id).then(response => {
            this.refreshPartnerCv()
        })
    }

    handleCertificationChange = (certificateId, checked) => {
        const partnerId = this.props.partner.id
        const fetchCertifications = () => {this.props.getPartnerCertifications(partnerId)}
        if(checked) {
            return this.props.addCertificationToPartner(partnerId, certificateId).then(fetchCertifications)
        }

        this.props.removeCertificationFromPartner(partnerId, certificateId).then(fetchCertifications)
    }

    onSocialMediaSubmit = data => {
        let social = {}
        social[data.type] = data.url
        this.props.saveSocialMedia(this.props.partnerUser.id, social).then(response => {
            this.refreshPartnerCv()
            this.refs.socialMediaExpandButton.toggleExpand()
        })
    }

    onSocialMediaUpdate = data => {
        const social = {}
        social[data.type] = data.url
        this.props.saveSocialMedia(this.props.partnerUser.id, social).then(response => {
            this.refreshPartnerCv()
        })
    }

    render() {
        const {
            t,
            partnerCv,
            partnerCertifications,
            certifications,
        } = this.props
        if (!partnerCv) {
            return <LoadingAnimation />
        }

        const descriptionFormDefaultValues = {
            id: partnerCv ? partnerCv.description.id : null,
            description: partnerCv ? partnerCv.description.description : null,
        }

        return (
            <Card>
                <Card.Content>
                    <div id="cv-page">
                        <PageTitle identifier="cv.title" />
                        <div className="row">
                            <div className="col-md-12">
                                <div className="introduction">
                                    <h2>{t('cv.introduction.title')}</h2>
                                    <p className="intro-text">{t('cv.introduction.description')}</p>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="certification">
                                    <h3>{t('cv.certification.title')}</h3>
                                    <p className="intro-text">{t('cv.certification.intro')}</p>
                                    <div className="list-item">
                                        {certifications?.map(certification => {
                                            let checked = false
                                            partnerCertifications?.forEach(function(partnerCertification) {
                                                if(certification.id === partnerCertification.id) {
                                                    checked = true
                                                }
                                            })
                                            return (
                                                <div key={certification.id} className="col-md-6">
                                                    <ActionCheckBox
                                                onClick={this.handleCertificationChange}
                                                value={certification.id} checked={checked}
                                                text={certification.title} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="social-media">
                                    <h3>{t('cv.social_media.title')}</h3>
                                    <p className="intro-text">{t('cv.social_media.intro')}</p>
                                    <div className="list-item">
                                        {partnerCv.socialMedia && Object.keys(partnerCv.socialMedia).map(exp =>
                                            <SocialMediaItem
                                                ob={partnerCv.socialMedia}
                                                obKey={exp}
                                                key={exp.id}
                                                onSubmitEdit={this.onSocialMediaUpdate}
                                            />
                                        )}
                                    </div>
                                    <ExpandButton
                                        ref="socialMediaExpandButton"
                                        className="success"
                                        content={
                                            <SocialMediaForm
                                                onSubmit={this.onSocialMediaSubmit}
                                                existingMedia={partnerCv.socialMedia ? Object.keys(partnerCv.socialMedia).filter(media => media !== 'id') : null}
                                            />
                                        }
                                        header="Add Social Media"
                                    />
                                </div>
                            </div>
                        </div>

                        <hr/>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="description">
                                    <h3>{t('cv.description.title')}</h3>
                                    <p className="intro-text">{t('cv.description.intro')}</p>
                                    <p>{partnerCv.description.description}</p>
                                    <ExpandButton
                                        ref="descriptionExpandButton"
                                        className="success"
                                        content={
                                            <DescriptionForm
                                                onSubmit={this.onDescriptionSubmit}
                                                initialValues={descriptionFormDefaultValues}/>
                                        }
                                        header={t('cv.description.edit')}
                                    />
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="expertise">
                                    <h3>{t('cv.expertise.title')}</h3>
                                    <p className="intro-text">{t('cv.expertise.intro')}</p>
                                    <div className="list-item">
                                        {partnerCv.expertises.map(exp =>
                                            <ExpertiseItem exp={exp} key={exp.id} onSubmitEdit={this.onExpertiseUpdate} onDeleteExpertise={this.onExpertiseDelete} />
                                        )}
                                    </div>
                                    <ExpandButton
                                        ref="expertiseExpandButton"
                                        className="success"
                                        content={
                                            <ExpertiseForm
                                                handleSubmit={this.onExpertiseSubmit} initialValues={{ expertiseName: '' }}/>
                                        }
                                        header={t('cv.expertise.add')}
                                    />
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="experience">
                                    <h3>{t('cv.experience.title')}</h3>
                                    <p className="intro-text">{t('cv.experience.intro')}</p>
                                    <div className="list-item">
                                        {partnerCv.experiences.map(exp =>
                                            <ExperienceItem exp={exp} key={exp.id} onSubmitEdit={this.onExperienceUpdate} onDeleteExperience={this.onExperienceDelete} />
                                        )}
                                    </div>
                                    <ExpandButton
                                        ref="experienceExpandButton"
                                        className="success"
                                        content={
                                            <ExperienceForm
                                                handleSubmit={this.onExperienceSubmit} initialValues={{ fromDateMonth: '01', toDateMonth: '01' }}/>
                                        }
                                        header={t('cv.experience.add')}
                                    />
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="education">
                                    <h3>{t('cv.education.title')}</h3>
                                    <p className="intro-text">{t('cv.education.intro')}</p>
                                    <div className="list-item">
                                        {partnerCv.educations.map(edu =>
                                            <EducationItem edu={edu} key={edu.id} onSubmitEdit={this.onEducationUpdate} onDeleteEducation={this.onEducationDelete}/>
                                        )}
                                    </div>
                                    <ExpandButton
                                        ref="educationExpandButton"
                                        className="success"
                                        content={
                                            <EducationForm
                                                handleSubmit={this.onEducationSubmit} initialValues={{ fromDateMonth: '01', toDateMonth: '01' }}/>
                                        }
                                        header={t('cv.education.add')}
                                    />
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="courses">
                                    <h3>{t('cv.course.title')}</h3>
                                    <p className="intro-text">{t('cv.course.intro')}</p>
                                    <div className="list-item">
                                        {partnerCv.courses.map(exp =>
                                            <CourseItem course={exp} key={exp.id} onSubmitEdit={this.onCourseUpdate} onDeleteCourse={this.onCourseDelete}/>
                                        )}
                                    </div>
                                    <ExpandButton
                                        ref="courseExpandButton"
                                        className="success"
                                        content={
                                            <CourseForm
                                                handleSubmit={this.onCourseSubmit} initialValues={{ fromDateMonth: '01' }} />
                                        }
                                        header={t('cv.course.add')}
                                    />
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="award">
                                    <h3>{t('cv.award.title')}</h3>
                                    <p className="intro-text">{t('cv.award.intro')}</p>
                                    <div className="list-item">
                                        {partnerCv.awards.map(exp =>
                                            <AwardItem award={exp} key={exp.id} onSubmitEdit={this.onAwardUpdate} onDeleteAward={this.onAwardDelete}/>
                                        )}
                                    </div>
                                    <ExpandButton
                                        ref="awardExpandButton"
                                        className="success"
                                        content={
                                            <AwardForm
                                                handleSubmit={this.onAwardSubmit} initialValues={{ fromDateMonth: '01' }}/>
                                        }
                                        header={t('cv.award.add')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Content>
            </Card>
        )
    }
}
