import React from 'react'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import ExpandButton from '../utils/ExpandButton'
import ExperienceForm from '../cv/ExperienceForm'
import { Button } from '../elements/Button'
import { Flex } from '../elements/Flex'

@withTranslation('', { wait: true })
export default class ExperienceItem extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            editing: false,
        }
    }

    clickEdit = () => {
        this.setState({
            editing: true,
        })
    }

    onCloseEdit = () => {
        this.setState({
            editing: false,
        })
    }

    onSubmitUpdate = data => {
        this.setState({
            editing: false,
        })
        data.id = this.props.exp.id
        this.props.onSubmitEdit(data)
    }

    deleteExperience = () => {
        this.setState({
            editing: false,
        })

        this.props.onDeleteExperience(this.props.exp.id)
    }

    render() {
        const {
            exp, t,
        } = this.props
        const { editing } = this.state

        const toDate = exp.toDate ? '- ' + moment(exp.toDate).format('MMMM YYYY') : ''

        return (
            <div className="item">
                <Flex modifiers={[ 'justifySpaceBetween', 'alignCenter' ]}>
                    <div>
                        <h3>{exp.title} &ndash; {exp.companyName}</h3>
                        <span>{exp.content}</span>

                        <p className="meta">
                            {moment(exp.fromDate).format('MMMM YYYY')} {toDate} <br/>
                        </p>
                    </div>
                    {!editing && <Button
                        modifiers={[ 'secondary' ]} onClick={this.clickEdit}>{t('cv.experience.edit')}</Button>
                    }
                </Flex>
                {editing && <ExpandButton
                    expanded={true}
                    onClose={this.onCloseEdit}
                    className="success"
                    content={
                        <div>
                            <ExperienceForm handleSubmit={this.onSubmitUpdate} initialValues={{
                                ...exp,
                                fromDateYear: moment(exp.fromDate).format('YYYY'),
                                fromDateMonth: moment(exp.fromDate).format('MM'),
                                toDateYear: exp.toDate ? moment(exp.toDate).format('YYYY') : '',
                                toDateMonth: exp.toDate ? moment(exp.toDate).format('MM') : '01',
                            }}/>
                            <button style={{ marginTop: 20 }} className="btn btn-lg btn-danger btn-block" onClick={this.deleteExperience}>{t('cv.experience.delete')}</button>
                        </div>
                    }
                    header={t('cv.experience.edit')}
                />
                }
            </div>
        )
    }
}

