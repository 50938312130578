import React from 'react'
import { withTranslation } from 'react-i18next'
import i18next from 'i18next'

@withTranslation('', { wait: true })
export default class FormattedAmount extends React.Component {
    render() {
        let { amount, currency, roundTo, className } = this.props

        let options = {}
        if(currency) {
            options = { style: 'currency', currency: currency }
        }

        if (roundTo) {
            amount = Number(amount.toFixed(roundTo))
            options = {
                minimumFractionDigits: roundTo,
                maximumFractionDigits: roundTo,
                ...options,
            }
        }

        let value = amount || amount === 0 ? amount.toLocaleString(i18next.language, options) : '-'

        return (
            <span className={className}>{value}</span>
        )
    }
}
