import React from 'react'
import { connect } from 'react-redux'
import { CreditCard } from 'react-feather'
import { replace } from 'connected-react-router'
import { Trans, withTranslation } from 'react-i18next'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { currentPartnerSelector, currentPartnerUserSelector } from '../../../../selectors/auth'
import { Flex } from '../../../elements/Flex'
import { Text, Impact } from '../../../elements/text/Text'
import Box from '../../../blocks/Box'
import { Container, Col, Row } from '../../../layout/layout'
import { bkpInt } from '../../../../theme/variables_new'
import WindowSizeListener from 'react-window-size-listener'
import { Button } from '../../../elements/ButtonNew'
import { addStripeCard, getCards } from '../../../../actions/billing'
import { Page } from '../styles'
import Tabs from '../../../elements/Tabs'
import { createStripeSetupIntent, getStripeApiKey, saveStripePaymentMethod } from '../../../../actions/stripe'
import LoadingAnimation from '../../../utils/LoadingAnimation'
import { LoaderContainer } from '../../../cards'
import { subscribeToMarketplace } from '../../../../actions/partners'
import { preferPaymentMethod } from '../../../../actions/paymentMethods'
import { toastr } from 'react-redux-toastr'
import { getTerms, acceptTerms } from '../../../../actions/terms'
import { getSubscriptionTypes } from '../../../../actions/subscriptions'
import { getAuthPermissions } from '../../../../actions/auth'
import PackageCard from 'components/src/Marketplace/PackageCard'
import { StripePaymentMethodSetup } from '../../../StripePaymentMethodSetup'
import { PARTNER_SUBSCRIPTION_STARTED, sendSegmentData } from '../../../../utils/segment'

const Views = {
    register: 'register',
    select: 'select',
}

@withTranslation('', { wait: true })
export class SubscriptionAcceptForm extends React.Component {
    state = {
        submitting: false,
        formValid: true,
        acceptedTerms: [],
    }

    handleSubmit = async () => {
        this.setState({ submitting: true })

        if (!await this.checkAcceptedTerms()) {
            this.setState({ submitting: false })
            return
        }

        await this.props.onSubmit()
        this.setState({ submitting: false })
    }

    checkAcceptedTerms = async () => {
        let valid = true

        if (!this.props.renderedTerms) {
            return valid
        }

        this.props.renderedTerms.forEach(t => {
            if (this.state.acceptedTerms.indexOf(t.id) === -1) {
                valid = false
            }
        })
        await this.setState({ formValid: valid })

        if (valid) {
            try {
                await Promise.all(this.props.renderedTerms.map(term => this.props.acceptTerms(term.id)))
            } catch (err) {
                valid = false
                this.setState({ formValid: valid })
            }
        }

        return valid
    }

    toggleAcceptTerms = e => {
        const acceptedTerms = [ ...this.state.acceptedTerms ]
        const id = e.target.value

        if (e.target.checked) {
            acceptedTerms.push(parseInt(id))
        } else {
            const index = acceptedTerms.indexOf(parseInt(id))
            if (index !== -1) {
                acceptedTerms.splice(index, 1)
            }
        }
        this.setState({ acceptedTerms })
    }

    renderTermsAccept = () => {
        const { renderedTerms } = this.props
        const { acceptedTerms } = this.state

        if (!renderedTerms) {
            return null
        }

        return <ul className={'terms-list ' + (this.state.formValid ? '' : 'alert alert-danger')}>
            {renderedTerms.map(term => {
                return (
                    <li key={'terms-list' + term.id}>
                        <label>
                            <input
                                type="checkbox"
                                checked={acceptedTerms.indexOf(term.id) !== -1}
                                value={term.id}
                                onChange={e => this.toggleAcceptTerms(e)}
                            />
                            <Trans
                                i18nKey="marketplace.terms"
                                terms={<a href={`/terms/${term.id}`} target="_blank" rel="noreferrer"> {term.headline}</a>}
                            />
                        </label>
                    </li>
                )
            })}
        </ul>
    }

    render() {
        const { t } = this.props

        return <Flex
            modifiers={[ 'directionColumn', 'mT_2' ]}
        >
            {this.renderTermsAccept()}
            <Flex.Item modifiers={[ 'mT_4' ]}>
                <Button onClick={this.handleSubmit} modifiers={[ 'action', 'large', 'bold', 'bottomLeft', 'fullWidth', this.state.submitting ? 'disabled' : '' ]}>
                    {t('marketplace.sign_up.action')}
                </Button>
            </Flex.Item>
        </Flex>
    }
}

@connect(
    state => ({
        cards: state.pages.billing.cardsIds.map(id => state.entities.paymentCards[id]),
        stripe: state.stripe,
        partner: currentPartnerSelector(state),
        partnerUser: currentPartnerUserSelector(state),
        renderedTerms: state.entities.termss,
        subscriptionTypes: state.entities.subscriptionTypes,
    }),
    {
        createStripeSetupIntent,
        getTerms,
        getCards,
        acceptTerms,
        addStripeCard,
        getStripeApiKey,
        getSubscriptionTypes,
        getAuthPermissions,
        saveStripePaymentMethod,
        subscribeToMarketplace,
        preferPaymentMethod,
        replace,
    }
)
@withTranslation('', { wait: true })
export default class MarketplaceSignupPage extends React.Component {
    stripePromise = null

    state = {
        viewName: Views.register,
        width: 0,
        cardId: 0,
        paymentMethod: {},
        loading: false,
        stripeSetupIntentClientSecret: null,
        subscriptionType: null,
    }

    componentWillMount() {
        const q = new URLSearchParams(window.location.search)
        this.setState({ subscriptionType: q.get('type') })
    }

    async componentDidMount() {
        const { partner } = this.props

        this.setState({ loading: true })
        const q = new URLSearchParams(window.location.search)
        if (q.get('setup_intent')) {
            try {
                await this.saveStripePaymentMethod(q.get('setup_intent'))
            } catch (err) {
                toastr.error('Error!', err.error)
            }
            this.props.history.push('/marketplace/subscription?type=' + this.state.subscriptionType)
        }
        await Promise.all([
            this.props.getStripeApiKey(partner.geo.code),
            this.props.getSubscriptionTypes(partner.geo.code),
            this.props.getCards(this.props.partner),
            this.props.getTerms({
                geoCode: this.props.partner.geo.code,
                type: 'partner',
                identifier: 'marketplace',
            }),
        ])
        const setupIntentResponse = await this.props.createStripeSetupIntent(partner)
        this.setState({ stripeSetupIntentClientSecret: setupIntentResponse.clientSecret })
        this.stripePromise = loadStripe(this.props.stripe.applicationKey)
        this.setState({ loading: false })

        const { cards } = this.props

        if (cards.length) {
            this.changeView(Views.select)
            this.selectCard(cards.find(card => card.isPreferred)?.id || cards[0].id)
        }
    }

    selectedPackageType() {

        if (!this.state.subscriptionType) { // For now default to marketplace as that is part of the test
            this.props.replace({ pathname: '/marketplace/packages' })
        }

        return Object.values(this.props.subscriptionTypes)
            .find(subscriptionType => subscriptionType.identifier === this.state.subscriptionType)
    }

    async saveStripePaymentMethod(setupIntent) {
        this.setState({ savingPaymentMethod: true })
        await this.props.saveStripePaymentMethod(this.props.partner, setupIntent)
        this.setState({ savingPaymentMethod: false })
    }

    createSubscription = async (e) => {
        if (e) {
            e.preventDefault()
        }
        const { partner } = this.props
        const selectedPackageType = this.selectedPackageType()
        await this.props.subscribeToMarketplace(partner.id, selectedPackageType.id).then(() => {
            sendSegmentData(partner, PARTNER_SUBSCRIPTION_STARTED, {
                subscription: selectedPackageType.name
            })
        })
        await this.props.getAuthPermissions()
        this.continue()
    }

    continue() {
        this.props.replace({ pathname: '/marketplace' })
    }

    selectPreferredCard = async () => {
        const { paymentMethod } = this.state
        try {
            await this.props.preferPaymentMethod(paymentMethod.id)
            await this.createSubscription()
        } catch (err) {
            toastr.error('Error!', err.error)
        }
    }

    updateWindowWidth = width => this.setState({ width: width })

    returnWindowWidthString = width => {
        if (width <= bkpInt.small) return 'small'
        if (width <= bkpInt.medium) return 'medium'
        if (width <= bkpInt.large) return 'desktopSmall'
        if (width <= bkpInt.extraLarge) return 'desktopMedium'
        return 'desktopLarge'
    }

    selectCard = cardId => {
        const { cards } = this.props
        const paymentMethod = cards.find(card => card.id === cardId).paymentMethod

        this.setState({ cardId, paymentMethod })
    }

    changeView = viewName => this.setState({ viewName })

    render() {
        const { cardId } = this.state
        const {
            cards,
            renderedTerms,
            t,
            history,
        } = this.props

        const selectedPackageType = this.selectedPackageType()
        const translationIdentifierSuffix = selectedPackageType?.identifier?.split(/[_]+/).pop()

        const tabs = [
            { tabName: t('marketplace.sign_up.register_card'), tabId: Views.register },
            { tabName: `${t('marketplace.sign_up.select_card')} (${cards.length})`, tabId: Views.select, disabled: !Boolean(cards.length) },
        ]

        return <Page responsiveModifiers={{
            medium: [ 'pB_6', 'mB_6' ],
            desktopSmall: [ 'pB_6', 'mB_6' ],
            desktopMedium: [ 'pB_6', 'mB_6' ],
            desktopLarge: [ 'pB_6', 'mB_6' ],
        }} size={this.returnWindowWidthString(this.state.width)} className="new">
            <Container modifiers={[ 'widthLg' ]}>
                <Row modifiers={[ 'mY_5' ]}>
                    <Col>
                        <Flex modifiers={[ 'justifyCenter' ]}>
                            <img width="300px" src="/images/ageras-logo.svg" alt="Ageras for partner"/>
                        </Flex>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Flex onClick={() => history.push('/marketplace/packages')} className="text-active mdi mdi-chevron-left cursor-pointer">
                            <Text>{t('marketplace.sign_up.go_back')}</Text>
                        </Flex>
                    </Col>
                </Row>
                <Row>
                    <Col spanMd={4} spanLg={6}>
                        {!this.state.loading && <Tabs
                            tabs={tabs}
                            selectedTab={this.state.viewName}
                            changeTab={tabId => this.changeView(tabId)}
                            maxWidth
                        />}
                        <Flex modifiers={[ 'justifyCenter', 'directionColumn', 'mT_3', this.state.loading ? 'alignCenter' : '' ]} style={{ position: 'relative', height: this.state.loading ? '70%' : 'auto' }}>
                            {this.state.loading && <LoaderContainer>
                                <LoadingAnimation />
                            </LoaderContainer>}
                            {!this.state.loading && this.state.viewName === Views.register && <div>
                                <Elements stripe={this.stripePromise} options={{
                                    clientSecret: this.state.stripeSetupIntentClientSecret,
                                }}>
                                    <StripePaymentMethodSetup
                                        returnUrl={window.location.toString()}
                                        t={t}
                                    />
                                </Elements>
                            </div>}
                            {!this.state.loading && this.state.viewName === Views.select && <div>
                                {cards.map(card => <React.Fragment
                                    key={card.id}
                                >
                                    <Flex
                                        modifiers={[ 'alignCenter', 'pX_2', 'pT_3', 'pB_2' ]}
                                    >
                                        <input
                                            type="radio"
                                            value={card.id}
                                            checked={cardId === card.id}
                                            onChange={() => this.selectCard(card.id)}
                                        />
                                        <Flex.Item modfiers={[ 'grow2' ]}>
                                            <Flex modifiers={[ 'directionColumn', 'mL_3' ]}>
                                                <Text modifiers={[ 'small', 'bold' ]}>{t(`payment.type.${card.cardType}.label`)}</Text>
                                                <Impact modifiers={[ 'large' ]}>•••• •••• •••• {card.cardMask.slice(-4)}</Impact>
                                                <Text modifiers={[ 'tiny' ]}>{t('marketplace.sign_up.form.expiry_text')}: {card.expiration.month}/{card.expiration.year.toString().slice(-2)}</Text>
                                            </Flex>
                                        </Flex.Item>
                                        <Text modifiers={[ 'regular' ]}>
                                            <CreditCard />
                                        </Text>
                                    </Flex>
                                    <Box.Line />
                                </React.Fragment>)}
                                <SubscriptionAcceptForm
                                    width={this.returnWindowWidthString(this.state.width)}
                                    onSubmit={this.selectPreferredCard}
                                    acceptTerms={this.props.acceptTerms}
                                    renderedTerms={Object.values(renderedTerms).filter(term => term.identifier)}
                                    t={t}
                                />
                            </div>}
                        </Flex>
                    </Col>
                    <Col spanMd={4} spanLg={6}>
                        {selectedPackageType && <Flex modifiers={['justifyCenter', 'directionColumn', 'alignCenter']}>
                            <PackageCard
                                imageSrc="/images/ageras-logo.svg"
                                title={t(`marketplace.sign_up.package_${translationIdentifierSuffix}.title`)}
                                packageName={selectedPackageType.name}
                                subscriptionText={t(`marketplace.sign_up.package_${translationIdentifierSuffix}.subscription_text`)}
                                subscriptionFee={selectedPackageType?.monthlyPrice?.amount}
                                currency={this.props.partner.preferences.currency.code}
                                description={t(`marketplace.sign_up.package_${translationIdentifierSuffix}.description`)}
                                helpText={t(`marketplace.sign_up.package_${translationIdentifierSuffix}.help_text`)}
                            />
                        </Flex>}
                    </Col>
                </Row>
            </Container>
            <WindowSizeListener onResize={size => this.updateWindowWidth(size.windowWidth)} />
        </Page>
    }
}
