import { PartnerResource } from 'ageras-api-client/src/api'
import React, { useCallback } from 'react'
import Card from 'components/src/Card'
import Text from 'components/src/texts/Text'
import Flex from 'components/src/layout/Flex'
import { PrimitiveCheckbox as Checkbox } from 'components/src/inputs/CheckboxSquared'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Label = styled.label`
    cursor: pointer;
    font-weight: initial;
`

interface LeadListSortsProps {
    handleSortChange: (newSort: string) => void
    currentSort: string
    partner: PartnerResource
}

function LeadListSorts({ handleSortChange, currentSort, partner }: LeadListSortsProps) {
    const { t } = useTranslation('translation')
    const sorts = [
        {
            name: '-created_at',
            displayName: t('marketplace.controls.sort_options.newest'),
        },
        {
            name: 'created_at',
            displayName: t('marketplace.controls.sort_options.oldest'),
        },
        {
            name: '-distance',
            displayName: t('marketplace.controls.sort_options.distance_closest'),
            hidden: !partner.geo?.point?.lat || !partner.geo.point.lon,
        },
        {
            name: 'distance',
            displayName: t('marketplace.controls.sort_options.distance_furthest'),
            hidden: !partner.geo?.point?.lat || !partner.geo.point.lon,
        },
    ]

    const handleChange = useCallback(e => {
        handleSortChange(e.target.name as string)
    }, [ handleSortChange ])

    return <Card width="max-content">
        <Card.Content px={3} pt={3}>
            <Text>Set sorting preferences</Text>
        </Card.Content>
        <Card.Content pt={2}>
            {sorts.filter(sort => !sort.hidden).map(sort => (
                <Flex as={Label} htmlFor={sort.name} px={3} py={2} alignItems="center" key={sort.name}>
                    <Checkbox id={sort.name} checked={currentSort === sort.name} name={sort.name} onChange={handleChange}/>
                    <Text size="small" ml={2}>
                        {t('marketplace.controls.label_sort_by')}
                        {': '}
                        <Text bold size="small">{sort.displayName}</Text>
                    </Text>
                </Flex>
            ))}
        </Card.Content>
    </Card>
}

export default LeadListSorts
