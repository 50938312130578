import React from 'react'
import { connect } from 'react-redux'
import { withTranslation, Trans } from 'react-i18next'
import { EventTypes } from 'redux-segment'
import { decamelizeKeys } from 'humps'
import { Link } from 'react-router-dom'
import { push } from 'connected-react-router'
import {
    Col,
    Row,
} from 'react-bootstrap'
import {
    Card,
    Header,
    Content,
} from '../cards'
import { saveStripeCard } from '../../actions/stripe'
import {
    getCheckout,
    completeCheckout,
} from '../../actions/checkouts'
import {
    getTerms,
    acceptTerms,
    getRenderedTerm,
} from '../../actions/terms'
import {
    renderedTermsByIdSelector,
} from '../../selectors/terms'
import FormattedAmount from '../../components/utils/FormattedAmount'
import LoadingAnimation from '../../components/utils/LoadingAnimation'
import { toastr } from 'react-redux-toastr'

@connect(
    (state, props) => ({
        checkout: state.entities.checkouts[props.checkoutId],
        isLoadingTerms: state.pages.checkout.isLoadingTerms,
        isCompletingCheckout: state.pages.checkout.isCompletingCheckout,
        renderedTerms: state.pages.checkout.terms.map(id => renderedTermsByIdSelector(state)(id)).filter(term => term !== undefined),
    }),
    {
        getCheckout,
        getTerms,
        acceptTerms,
        getRenderedTerm,
        saveStripeCard,
        completeCheckout,
        push,
    }
)
@withTranslation('', { wait: true })
export default class Checkout extends React.Component {
    steps = {
        PAYMENT: 1,
        PAYMENT_REDIRECT: 2,
    }

    freeSolution = 'free_ageras'

    constructor(props) {
        super(props)

        this.state = {
            error: null,
            selectedPaymentMethod: 'card_stripe',
        }
    }

    componentDidMount() {
        const {
            checkoutId,
            checkout,
            getCheckout,
            getTerms,
            getRenderedTerm,
            action,
            buyer,
        } = this.props

        getCheckout(checkoutId).then(a => {
            if(action === 'return') {
                // User came back from a redirect.
                // Determine what to do.
                this.validateTokenAndCompleteCheckout()
                return
            }
            const checkout = a.entities.checkouts[a.result]

            if(this.isFree(checkout.payments.events)) {
                this.setState({
                    selectedPaymentMethod: this.freeSolution,
                })
                this.completeCheckout(checkout, 'free_ageras')
                return
            }

            // Load the terms, if necessary
            let termTypes = []

            checkout.lines.map(line => {
                if(line.item.type === 'package') {
                    termTypes.push('subscription')
                }
                return true
            })
            termTypes.push('general')
            termTypes.push('payment')
            termTypes.push(`general.${buyer.industry.identifier}`)

            if(termTypes.length > 0) {
                getTerms({
                    geoCode: checkout.geoCode,
                    type: checkout.buyer.type,
                    identifier: termTypes,
                }).then(response => {
                    response.result.forEach(termId => {
                        getRenderedTerm(termId, {
                            checkoutIdentifier: checkout.id,
                        })
                    })
                })
            }

        })
    }

    isFree(paymentMethods) {
        return paymentMethods.some(m => m.identifier === this.freeSolution)
    }

    validateTokenAndCompleteCheckout() {
        const q = new URLSearchParams(window.location.search)
        const event = this.props.checkout.payments.events.find(e => this.props.paymentMethod)
        const stripe = window.Stripe(event.provider.values.applicationKey)
        stripe.retrieveSource({
            client_secret: q.get('client_secret'),
            id: q.get('source'),
        }).then(a => {
            if(a.source) {
                if(a.source.status === 'chargeable') {
                    this.props.saveStripeCard(a.source)
                    clearTimeout(this.timer)

                    this.completeCheckout(a.source, this.props.paymentMethod)
                } else if(a.source.status === 'failed') {
                    this.setState({ error: true })
                }
            }
        })
    }

    completeCheckout(token, selectedPaymentMethod) {
        const event = this.props.checkout.payments.events.find(e => e.identifier === selectedPaymentMethod)
        this.props.completeCheckout(event.callback, {
            stripe_token: token.id,
        })
        .then(res => {
            const scope = JSON.parse(this.props.checkout.scope)
            this.props.push(scope.redirect)
        })
        .catch(res => {
            toastr.error(res.error)
        })
    }

    createSourceAndRedirect(sourceId, paymentMethod, additionalData = {}) {
        const stripe = window.Stripe(paymentMethod.provider.values.applicationKey)
        const amount = parseInt(Math.round(paymentMethod.priceInclVat.amount * 100))
        const buyer = this.props.buyer

        const sourceData = {
            type: sourceId,
            amount: amount > 0 ? amount : null,
            currency: this.props.checkout.totalPriceInclVat.currency,
            redirect: {
                return_url: window.location + '/' + paymentMethod.identifier + '/return',
            },
            owner: {
                name: buyer.companyName ? buyer.companyName : this.props.buyer.firstName + ' ' + this.props.buyer.lastName,
            },
            ...additionalData,
        }

        const thisHolder = this

        stripe.createSource(sourceData).then(function(result) {
            if(result.source) {
                if(result.source.redirect) {
                    window.location = result.source.redirect.url
                } else {
                    thisHolder.completeCheckout(result.source, paymentMethod.identifier)
                }
            } else {
                thisHolder.setState({
                    error: result.error.code,
                })
            }
        })
    }

    render() {
        const {
            checkout,
            renderedTerms,
            acceptTerms,
            buyer,
            t,
        } = this.props

        const {
            error,
        } = this.state

        const loading = !checkout || !buyer

        if(loading) {
            return <LoadingAnimation />
        }

        const installments = checkout.payments.installments
        const paymentMethods = checkout.payments.events
        const isFree = this.isFree(paymentMethods)

        if(checkout.validationSummary.length) {
            return <Card>
                <Header>
                    {t('checkout.validation.error_title')}
                </Header>
                <Content>

                    <div className="alert alert-danger">
                        <p style={{ marginTop: 0 }}>
                            {t('checkout.validation.error_intro')}
                        </p>
                        <ul>
                            {checkout.validationSummary.map(e => (
                                <li key={'validationSummary' + e}>
                                    {t('checkout.validation.' + e)}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <Link to={'/'}>
                        <button className="btn btn-block btn-lg btn-default">{t('checkout.validation.go_back')}</button>
                    </Link>
                </Content>
            </Card>
        }

        return (
            <Card>
                <Content>
                    {error && <div className="alert alert-danger">
                        {error.length ? t('payment_page.payment_error.' + error) : t('payment_page.payment_error_message')}
                    </div>}
                    <Row>
                        <Col sm={6}>
                            <h2>{t('payment_page.enter_your_payment')}</h2>
                            {isFree &&
                            <p className="intro-text">
                                {t('payment_page.payment_schedule_intro_free')}
                            </p>
                            }
                            {isFree ||
                            <p className="intro-text">
                                {t('payment_page.security_disclaimer')}
                                &nbsp;
                                {t('payment_page.payment_schedule_intro')}
                            </p>
                            }

                            <table className="datatable">
                                <thead>
                                    <tr>
                                        <th>{t('payment_page.installments.due_date_column')}</th>
                                        <th>{t('payment_page.installments.amount_excl_vat_column')}</th>
                                        <th>{t('payment_page.installments.amount_incl_vat_column')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {installments
                                        .filter(installment => installment.type !== 'discount')
                                        .map((installment, i) => <tr key={installment.type + i}>
                                            <td>{t('payment_page.installments.' + installment.type)}</td>
                                            <td>
                                                <FormattedAmount
                                                    amount={installment.priceExclVat.amount}
                                                    currency={installment.priceExclVat.currency}
                                                />
                                            </td>
                                            <td>
                                                <FormattedAmount
                                                    amount={installment.priceInclVat.amount}
                                                    currency={installment.priceInclVat.currency}
                                                />
                                            </td>
                                        </tr>)}
                                </tbody>
                            </table>
                            <br />
                        </Col>
                        <Col sm={6}>
{/*                            {isFree &&
                            <Card>
                                <Header>
                                    <strong>{t('pages.titles.payment_free')}</strong>
                                </Header>
                                <Content>
                                    <p className="intro-text" style={{ marginTop: '0px' }}>
                                        {t('payment_page.intro_free')}
                                    </p>
                                    {this.renderTermsAccept()}
                                    <a className="btn btn-block btn-action btn-lg" onClick={() => {
                                        this.validateForm() && this.completeCheckout({})
                                    }}>
                                        {t('payment_page.payment_method.free.accept')}
                                    </a>
                                </Content>
                            </Card>
                            }*/}
                            {isFree ||
                            <Card>
                                <Header>
                                    <strong>{t('pages.titles.payment')}</strong>
                                </Header>
                                <Content>
                                    <p className="intro-text" style={{ marginTop: '0px' }}>
                                        {t('payment_page.intro')}
                                    </p>
                                </Content>
                            </Card>
                            }
                        </Col>
                    </Row>
                </Content>
            </Card>
        )
    }
}
