import React from 'react'
import PropTypes from 'prop-types'
import { GreyCircle, SuccessCircle } from '../cards/index'

export default class ActionCheckBox extends React.Component {

    static defaultProps = {
        checked: false,
        disabled: false,
    }

    static propTypes = {
        onClick: PropTypes.func,
        checked: PropTypes.bool,
        text: PropTypes.string,
        value: PropTypes.any.isRequired,
    }

    clicked = () => {
        if (typeof this.props.onClick === 'function' && !this.props.disabled) {
            this.props.onClick(this.props.value, !this.props.checked)
        }
    }

    render() {
        const { text, checked, disabled } = this.props

        // whether or not to display the not-checked (false) option
        // as a hard no (red cross) or as a soft no (not checked green box)
        // const falseClass = this.props.softFalse ? 'soft-false' : 'hard-false'

        return (
            <label className="switch">
                <input
                    type="checkbox"
                    className="source"
                    checked={checked}
                    disabled={disabled}
                    onClick={this.clicked}
                />
                <div className="bevel">
                    <div className="knob" />
                </div>
                {text}
            </label>
        )

/*        return (
            <div className="action-checkbox" onClick={this.clicked.bind(this)}>
                <div className={`checkbox ${falseClass} ${checked ? 'checked' : ''} ${disabled ? 'disabled' : ''}`} />
                <div className="info">{text}</div>
            </div>
        )*/
    }
}
