import {
    IndustriesApiFetchParamCreator,
    IndustryGroupResource,
    LeadTypeGroupResult,
    LeadsApiFetchParamCreator,
    LeadTypeGroupResource,
    IndustryGroupResult,
    PartnersApiFetchParamCreator,
    LeadTypeResource,
    PartnerResource,
    PartnerIndustriesResource, PartnerLeadTypesResource,
} from 'ageras-api-client/src/api'
import { useFormik } from 'formik'
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import Flex from 'components/src/layout/Flex'
import Page from 'components/src/layout/Page'
import Heading from 'components/src/texts/Heading'
import Text from 'components/src/texts/Text'
import { FormControl } from 'components/src/inputs/FormControl'
import { useAgerasApi, useAgerasApiMutation } from 'ageras-api-client/src'
import { Checkbox as CheckboxSquared } from 'components/src/inputs/CheckboxSquared'
import Button from 'components/src/Button'
import { toastr } from 'react-redux-toastr'
import { useHistory } from 'react-router'
import * as yup from 'yup'
import { SchemaOf } from 'yup'
import { useTranslation } from 'react-i18next'
import Loader from 'components/src/Loader'
import styled, { css } from 'styled-components'
import usePartner from '../../../../utils/usePartner'
import { getInitialIndustryGroups, getInitialTypeGroups } from '../../Marketplace/LeadListFilters'

interface MarketplacePreferencesPageProps {
    marketplaceEnabledCountries: string[]
}

interface PreferencesFormValues {
    typeGroup: { [k: string]: boolean }
    industryGroup: { [k: string]: boolean }
}

interface FormWrapperProps {
    isSubmitting: boolean
}

const LoaderWrapper = styled(Flex)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`
const FormWrapper = styled(Flex)<FormWrapperProps>`
  ${({ isSubmitting }) => isSubmitting && css`
    filter: blur(1px);
  `}
`

type MarketplacePreferencesFormProps = {
    industryGroups: IndustryGroupResource[]
    leadTypeGroups: LeadTypeGroupResource[]
    onSuccessSubmit: () => void
    partner: PartnerResource
}

const validationSchema: SchemaOf<PreferencesFormValues> = yup.object().shape({
    typeGroup: yup.object()
        .shape({})
        .required('marketplace.preferences.no_selected_items')
        .test(
            'validTypeGroup',
            'marketplace.preferences.no_selected_items',
            (v: object) => {
                return Object.values(v).filter(v => v === true).length !== 0
            },
        ),
    industryGroup: yup.object()
        .shape({})
        .required('marketplace.preferences.no_selected_items')
        .test(
            'validIndustryGroup',
            'marketplace.preferences.no_selected_items',
            (v: object) => {
                return Object.values(v).filter(v => v === true).length !== 0
            },
        )
})

export function MarketplacePreferencesForm({ industryGroups, leadTypeGroups, onSuccessSubmit, partner }: MarketplacePreferencesFormProps) {
    const { t } = useTranslation()
    const initialIndustries = partner.industries?.map(industry => String(industry.id))
    const initialTypes = partner.leadTypes?.map(type => String(type.id))
    const industries = industryGroups.map((group: IndustryGroupResource) => ({ id: group.id!, title: group.name!, industries: group.industries! }))
    const types = leadTypeGroups.map((group: LeadTypeGroupResource) => ({ id: group.id!, title: group.name!, types: group.types! }))
    const [ selectedIndustryGroups, ] = useState<number[]>(getInitialIndustryGroups(initialIndustries, industries))
    const [ selectedTypeGroups, ] = useState<number[]>(getInitialTypeGroups(initialTypes, types))

    const partnerIndustryMutation = useAgerasApiMutation<PartnerIndustriesResource>((industries: PartnerIndustriesResource) => PartnersApiFetchParamCreator().partnersIndustriesUpdate(
        partner.id as unknown as string,
        industries
    ))

    const partnerTypeMutation = useAgerasApiMutation<PartnerLeadTypesResource>((types: PartnerLeadTypesResource) => PartnersApiFetchParamCreator().partnersLeadtypesUpdate(
        partner.id as unknown as string,
        types
    ))


    const onSubmit = useCallback(async (values: PreferencesFormValues) => {
        try {
            const selectedIndustries: PartnerIndustriesResource = {
                industryIds: []
            }

            const selectedTypes: PartnerLeadTypesResource = {
                typeIds: []
            }

            Object.keys(values.industryGroup!).map(id => {
                if (!values.industryGroup[id]) {
                    return []
                }
                const group = industryGroups.find((item: IndustryGroupResource) => String(item.id) === id)
                group!.industries!.map(item => {
                    return selectedIndustries.industryIds?.push(item.id!)
                })
            })

            Object.keys(values.typeGroup!).map(id => {
                if (!values.typeGroup[id]) {
                    return []
                }
                const group = leadTypeGroups.find((item: LeadTypeResource) => String(item.id) === id)
                group!.types!.map(item => {
                    return selectedTypes.typeIds?.push(item.id!)
                })
            })

            await Promise.all([
                partnerIndustryMutation.mutateAsync(selectedIndustries),
                partnerTypeMutation.mutateAsync(selectedTypes),
            ])

            await onSuccessSubmit()
        } catch (e) {
            toastr.error(t('marketplace.preferences.error_title'), t('marketplace.preferences.error_message'))
        }

    }, [ partnerTypeMutation, partnerIndustryMutation, onSuccessSubmit, industryGroups, leadTypeGroups, t ])

    const formik = useFormik<PreferencesFormValues>({
        initialValues: {
            typeGroup: selectedTypeGroups.reduce((a, v) => ({ ...a, [v]: true }), {}),
            industryGroup: selectedIndustryGroups.reduce((a, v) => ({ ...a, [v]: true }), {})
        },
        onSubmit,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        validateOnMount: false,
    })

    const isSubmitting: boolean = partnerIndustryMutation.isLoading || partnerTypeMutation.isLoading

    const onCheckBoxChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const { checked, name } = e.target
        formik.setFieldValue(name, checked)
    }, [ formik ])

    return <Flex flexDirection="column">
        <Heading color="impact" size="large">{t('marketplace.preferences.heading')}</Heading>
        {isSubmitting && <LoaderWrapper>
            <Loader />
        </LoaderWrapper>}
        <Flex mt={2} width="100%" flexDirection="column" position="relative">
            <form onSubmit={formik.handleSubmit}>
                <FormWrapper isSubmitting={isSubmitting} flexDirection="row" gap="100px">
                    <Flex flexDirection="column">
                        <FormControl error={t(formik.errors?.typeGroup?.toString() ?? '')}>
                            <Flex flexDirection="column" mb={2}>
                                <Heading color="impact">{t('marketplace.preferences.types_title')}</Heading>
                                <Text size="small">{t('marketplace.preferences.types_subtitle')}</Text>
                            </Flex>
                        </FormControl>
                        {leadTypeGroups.map((group: LeadTypeGroupResource) => (
                            <Flex key={group.id} my={1}>
                                <FormControl label={group.name} flexDirection="row-reverse" width="auto" childrenWidth="auto" justifyContent="flex-end" gap="0.5rem" cursor="pointer" alignItems="center">
                                    <CheckboxSquared
                                        name={`typeGroup[${group.id}]`}
                                        value={Boolean(formik.values.typeGroup[group.id!])}
                                        onChange={onCheckBoxChange}
                                    />
                                </FormControl>
                            </Flex>
                        ))}
                    </Flex>
                    <Flex flexDirection="column">
                        <FormControl error={t(formik.errors?.industryGroup?.toString() ?? '')}>
                            <Flex flexDirection="column" mb={2}>
                                <Heading color="impact">{t('marketplace.preferences.industry_title')}</Heading>
                                <Text size="small">{t('marketplace.preferences.industry_subtitle')}</Text>
                            </Flex>
                        </FormControl>
                        {industryGroups.map((group: IndustryGroupResource) => (
                            <Flex key={group.id} my={1}>
                                <FormControl label={group.name} flexDirection="row-reverse" width="auto" childrenWidth="auto" justifyContent="flex-end" gap="0.5rem" cursor="pointer" alignItems="center">
                                    <CheckboxSquared
                                        name={`industryGroup[${group.id}]`}
                                        value={Boolean(formik.values.industryGroup[group.id!])}
                                        onChange={onCheckBoxChange}
                                    />
                                </FormControl>
                            </Flex>
                        ))}
                    </Flex>
                </FormWrapper>
                <Flex>
                    <Button disabled={isSubmitting} variant="primary" type="submit" grow mt={2}>{t('marketplace.preferences.submit')}</Button>
                </Flex>
            </form >
        </Flex >
    </Flex >
}

function MarketplacePreferencesPage({ marketplaceEnabledCountries }: MarketplacePreferencesPageProps) {
    const { partner } = usePartner()
    const history = useHistory()

    const onSuccessSubmit = useCallback(() => {
        history.push('/marketplace/packages')
    }, [ history ])

    const { data: industryGroups, isFetched: industryGroupsFetched } = useAgerasApi<IndustryGroupResult>(IndustriesApiFetchParamCreator().industriesGroupIndex(
        String(partner?.geo?.code),
        1000,
    ), { enabled: Boolean(partner?.geo?.code) })

    const { data: leadTypeGroups, isFetched: leadTypeGroupsFetched } = useAgerasApi<LeadTypeGroupResult>(LeadsApiFetchParamCreator().leadsTypegroupsIndex(
        String(partner?.geo?.code),
        1000,
    ), { enabled: Boolean(partner?.geo?.code) })

    if (partner?.geo?.code && !marketplaceEnabledCountries.includes(partner?.geo?.code)) {
        window.location.href = '/'
    }

    if (!industryGroupsFetched || !leadTypeGroupsFetched || !partner) {
        return (<LoaderWrapper>
            <Loader />
        </LoaderWrapper>)
    }

    if ((!industryGroups && !leadTypeGroups)) {
        history.push('/marketplace/packages')
        return null
    }

    return <Page variant="grey">
        <Flex px={6} pt={6} pb={5} maxWidth={1200} mx="auto" justifyContent="center">
            <MarketplacePreferencesForm partner={partner} onSuccessSubmit={onSuccessSubmit} industryGroups={industryGroups?.data ?? []} leadTypeGroups={leadTypeGroups?.data ?? []}/>
        </Flex>
    </Page>
}

export default MarketplacePreferencesPage
