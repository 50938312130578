import { schema } from 'normalizr'

const { Entity, Array } = schema

export const partner = new Entity('partners')
export const partners = new Array(partner)

export const partnerUser = new Entity('partnerUsers')
export const partnerUsers = new Array(partnerUser)

export const client = new Entity('clients')
export const clients = new Array(client)

export const prompt = new Entity('prompts')
export const prompts = new Array(prompt)

export const employee = new Entity('employees')
export const employees = new Array(employee)

export const industry = new Entity('industries')
export const industries = new Array(industry)

export const invoice = new Entity('invoices')
export const invoices = new Array(invoice)

export const subscription = new Entity('subscriptions')
export const subscriptions = new Array(subscription)

export const subscriptionType = new Entity('subscriptionTypes')
export const subscriptionTypes = new Array(subscriptionType)

export const cashierSubscription = new Entity('cashierSubscriptions')
export const cashierSubscriptions = new Array(cashierSubscription)

export const cashierPaymentMethod = new Entity('cashierPaymentMethods')
export const cashierPaymentMethods = new Array(cashierPaymentMethod)

export const cashierProduct = new Entity('cashierProducts')
export const cashierProducts = new Array(cashierProduct)

export const certification = new Entity('certifications')
export const certifications = new Array(certification)

export const lead = new Entity('leads')
export const leads = new Array(lead)

export const quote = new Entity('quotes')
export const quotes = new Array(quote)

export const leadType = new Entity('leadTypes')
export const leadTypes = new Array(leadType)

export const leadOffer = new Entity('leadOffers')
export const leadOffers = new Array(leadOffer)

export const rating = new Entity('ratings')
export const ratings = new Array(rating)

export const subscriptionPackage = new Entity('subscriptionPackages')
export const subscriptionPackages = new Array(subscriptionPackage)

export const transaction = new Entity('transactions')
export const transactions = new Array(transaction)

export const coupon = new Entity('coupons')
export const coupons = new Array(coupon)

export const paymentCard = new Entity('paymentCards')
export const paymentCards = new Array(paymentCard)

export const permission = new Entity('permissions')
export const permissions = new Array(permission)

export const cv = new Entity('cvs')
export const cvs = new Array(cv)

export const language = new Entity('languages', {}, { idAttribute: 'identifier' })
export const languages = new Array(language)

export const refund = new Entity('refunds')
export const refunds = new Array(refund)

export const project = new Entity('projects')
export const projects = new Array(project)

export const projectNote = new Entity('projectNotes')
export const projectNotes = new Array(projectNote)

export const projectStatus = new Entity('projectStatuses', {}, { idAttribute: 'name' })
export const projectStatuses = new Array(projectStatus)

export const projectEvent = new Entity('projectEvents')
export const projectEvents = new Array(projectEvent)

export const projectConversation = new Entity('projectConversations')
export const projectConversations = new Array(projectConversation)

export const conversationMessage = new Entity('conversationMessages')
export const conversationMessages = new Array(conversationMessage)

export const notification = new Entity('notifications')
export const notifications = new Array(notification)

export const location = new Entity('locations')
export const locations = new Array(location)

export const acceptQuote = new Entity('acceptQuotes')
export const acceptQuotes = new Array(acceptQuote)

export const checkout = new Entity('checkouts')
export const checkouts = new Array(checkout)

export const deliverable = new Entity('deliverables')
export const deliverables = new Array(deliverable)

export const terms = new Entity('termss')
export const termss = new Array(terms)

export const renderedTerms = new Entity('renderedTermss')
export const renderedTermss = new Array(renderedTerms)

export const verification = new Entity('verifications')
export const verifications = new Array(verification)

export const bankAccount = new Entity('bankAccounts')
export const bankAccounts = new Array(bankAccount)

export const quoteItem = new Entity('quoteItems')
export const quoteItems = new Array(quoteItem)

export const invoiceRefundRequest = new Entity('invoiceRefundRequests')
export const invoiceRefundRequests = new Array(invoiceRefundRequest)

export const dataField = new Entity('dataFields')
export const dataFields = new Array(dataField)

export const subscriptionAgreement = new Entity('subscriptionAgreements')
export const subscriptionAgreements = new Array(subscriptionAgreement)

export const sector = new Entity('sectors')
export const sectors = new Array(sector)

export const businessUnit = new Entity('businessUnits')
export const businessUnits = new Array(businessUnit)

export const geoRegion = new Entity('geoRegions')
export const geoRegions = new Array(geoRegion)

export const partnerContract = new Entity('partnerContracts')
export const partnerContracts = new Array(partnerContract)

export const partnerContractPause = new Entity('partnerContractPauses')
export const partnerContractPauses = new Array(partnerContractPause)

export const partnerContractOfferGroup = new Entity('partnerContractOfferGroups')
export const partnerContractOfferGroups = new Array(partnerContractOfferGroup)

export const partnerAssignee = new Entity('partnerAssignees')
export const partnerAssignees = new Array(partnerAssignee)

export const paymentMethod = new Entity('paymentMethods')
export const paymentMethods = new Array(paymentMethod)

export const product = new Entity('products')
export const products = new Array(product)

export const partnerQuoteProductPrice = new Entity('partnerQuoteProductPrices')
export const partnerQuoteProductPrices = new Array(partnerQuoteProductPrice)

export const partnerRevenueSegment = new Entity('partnerRevenueSegments')
export const partnerRevenueSegments = new Array(partnerRevenueSegment)

export const partnerQuoteProductComment = new Entity('partnerQuoteProductComments')
export const partnerQuoteProductComments = new Array(partnerQuoteProductComment)

partner.define({
    industries,
})

client.define({
    assignedEmployee: employee,
    leads,
})

projectConversation.define({
    messages: conversationMessages,
})

project.define({
    quote,
})

project.define({
    notes: projectNotes,
})
