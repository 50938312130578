import React from 'react'
import InvoiceListPage from './InvoiceListPage'
import CouponListPage from './CouponListPage'
import CompanyDetailsPage from './CompanyDetailsPage'
import TransactionListPage from './TransactionListPage'
import RefundsListPage from './RefundsListPage'
import RefundsDetailPage from './RefundsDetailPage'
import RefundsCreateRequestPage from './RefundsCreateRequestPage'
import UsersPage from './UsersPage'
import KYCPage from './KYCPage'
import PayoutInfoPage from './PayoutInfoPage'
import BillingPage from './BillingPage'
import { ContainerFluid } from '../cards'
import SidebarPageTemplate from '../../containers/layout/SidebarPageTemplate'
import { currentPartnerSelector, currentPartnerUserSelector } from '../../selectors/auth'
import { hasListPartnerCouponsAccessSelector, hasMarketplaceLeadAccessSelector, hasListPartnerActionFeesAccessSelector } from '../../selectors/access'
import { getCoupons } from '../../actions/coupons'
import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import ImpressumPage from './ImpressumPage'
import ActionFees from './ActionFees'
import Preferences from './Preferences'
import StripeSubscriptionPage from './StripeSubscriptionPage'

const noRefundsCountries = [ 'se', 'dk', 'us', 'de', 'nl' ]

@connect(
    state => ({
        routePath: state.router.location.pathname,
        currentPartner: currentPartnerSelector(state),
        currentPartnerUser: currentPartnerUserSelector(state),
        hasListPartnerCouponsAccess: hasListPartnerCouponsAccessSelector(state),
        hasMarketplaceLeadAccess: hasMarketplaceLeadAccessSelector(state),
        hasListPartnerActionFeesAccess: hasListPartnerActionFeesAccessSelector(state),
        partner: currentPartnerSelector(state),
        coupons: Object.values(state.entities.coupons),
        partnerContracts: state.entities.partnerContracts,
    }),
    {
        getCoupons,
        replace,
    }
)
@withTranslation('', { wait: true })
export default class AccountPage extends React.Component {
    componentDidMount() {
        const {
            hasListPartnerCouponsAccess,
            hasMarketplaceLeadAccess,
            hasListPartnerActionFeesAccess,
            currentPartnerUser,
            coupons,
            partner,
            match,
            t,
        } = this.props

        if([ '/account', '/account/coupons' ].includes(this.props.routePath)) {
            this.props.getCoupons(this.props.partner.id)
                .then(res => {
                    if (this.props.coupons.length) {
                        this.props.replace('/account/coupons')
                    } else {
                        this.props.replace('/account/invoices')
                    }
                })
        }
        this.navLinks = [
            {
                pathname: `${match.url}/invoices`,
                relatedComponent: <InvoiceListPage />,
                mainText: t('navigation.invoices'),
                secondaryText: t('navigation.invoices_description'),
            },
            {
                pathname: `${match.url}/coupons`,
                relatedComponent: <CouponListPage />,
                mainText: t('navigation.coupons'),
                secondaryText: t('navigation.coupons_description'),
                hidden: !(coupons.length > 0 && hasListPartnerCouponsAccess) && !hasMarketplaceLeadAccess,
            },
            {
                pathname: `${match.url}/partner-fees`,
                relatedComponent: <ActionFees />,
                mainText: t('navigation.action_fees'),
                secondaryText: t('navigation.action_fees_description'),
                hidden: !(hasListPartnerActionFeesAccess && hasMarketplaceLeadAccess),
            },
            {
                pathname: `${match.url}/partner-preferences`,
                relatedComponent: <Preferences />,
                mainText: t('navigation.preferences'),
                secondaryText: t('navigation.preferences_description'),
                hidden: !hasMarketplaceLeadAccess
            },
            {
                pathname: `${match.url}/billing`,
                relatedComponent: <BillingPage />,
                mainText: t('navigation.subscription_billing'),
                secondaryText: t('navigation.subscription_billing_description'),
            } ]

        if(partner.isStripeCustomer){
            this.navLinks.push({
                pathname: `${match.url}/subscription`,
                relatedComponent: <StripeSubscriptionPage partner={partner} />,
                mainText: t('navigation.stripe_subscription'),
                secondaryText: t('navigation.stripe_subscription_description'),
            })
        }

        if (!noRefundsCountries.includes(this.props.partner.geo.code)) {
            this.navLinks.push({
                pathname: `${match.url}/refunds`,
                relatedComponent: <RefundsListPage />,
                mainText: t('navigation.refunds'),
                secondaryText: t('navigation.refunds_description'),
            })
        }
        let moreLinks = [
            {
                pathname: `${match.url}/company-details`,
                relatedComponent: <CompanyDetailsPage />,
                mainText: t('navigation.company_details'),
                secondaryText: t('navigation.company_details_description'),
            },
            {
                pathname: `${match.url}/users`,
                relatedComponent: <UsersPage />,
                mainText: t('navigation.users'),
                secondaryText: t('navigation.users_description'),
                hidden: currentPartnerUser.accessType !== 'master',
            },
            {
                pathname: `${match.url}/transactions`,
                relatedComponent: <TransactionListPage />,
                mainText: t('navigation.transactions'),
                secondaryText: t('navigation.transactions_description'),
                hidden: true,
            },
            {
                pathname: `${match.url}/impressum`,
                relatedComponent: <ImpressumPage />,
                mainText: t('navigation.impressum'),
                secondaryText: t('navigation.impressum_description'),
                hidden: partner.geo.code !== 'de',
            },
        ]
        this.navLinks = this.navLinks.concat(moreLinks)

        this.additionalRouteComponent = [
            {
                pathname: `${match.url}/refunds/:refundId`,
                relatedComponent: <RefundsDetailPage />,
            },
            {
                pathname: `${match.url}/kyc`,
                relatedComponent: <KYCPage />,
            },
            {
                pathname: `${match.url}/payout-info`,
                relatedComponent: <PayoutInfoPage />,
            },
            {
                pathname: `${match.url}/refunds-create`,
                relatedComponent: <RefundsCreateRequestPage />,
            },
        ]
    }

    render() {
        const { location: { hash } } = this.props

        return <ContainerFluid>
            {/* Sidebar and pages component*/}
            <SidebarPageTemplate
                additionalRouteComponent={this.additionalRouteComponent}
                navLinks={this.navLinks}
                hash={hash}
            />
        </ContainerFluid>
    }
}
