import React from 'react'
import { FormGroup } from 'react-bootstrap'
import styled from 'styled-components'
import { Error } from '../Error'

const InputBox = styled.div`
    border: 1px solid #ccc;
    padding: 2px 10px;
    min-width: 190px;
    height: 30px;
    float: left;
    margin-right: 10px;
    border-radius: 3px;
`

const adaptFileEventToValue = delegate => e => delegate(e.target.files[0])

const FileInput = ({
    showFileName,
    children,
    accept,
    touch = el => el,
    input: {
        value,
        onChange,
        onBlur,
        name,
        ...inputProps
    },
    meta,
}) => {
    if(value instanceof File) {
        const reader = new FileReader()
        reader.onload = upload => {
            const idxDot = value.name.lastIndexOf('.')

            const extFile = value.name.substr(idxDot, value.name.length).toLowerCase()

            if(!accept || accept.split(', ').includes(extFile)) {
                onChange({
                    originalName: value.name,
                    name: value.name,
                    content: upload.target.result,
                })
            } else {
                touch(name)
                onChange(null)
            }
        }
        reader.readAsDataURL(value)
    }

    return (
        <FormGroup bsClass="" className={meta.touched && meta.error && 'has-error'}>
            <input
                onChange={adaptFileEventToValue(onChange)}
                onBlur={adaptFileEventToValue(onBlur)}
                type="file"
                accept={accept}
                name={name}
                id={name}
                style={{ display: 'none' }}
                {...inputProps}
            />
            {showFileName && <InputBox>{value.name}</InputBox>}
            {children}
            <Error meta={meta}/>
        </FormGroup>
    )
}

export default FileInput
