import { useTranslation } from 'react-i18next';
import { Card } from '../../blocks/Card'
import { Flex } from '../../elements/Flex';
import { Col } from 'react-bootstrap';
import isArray from 'lodash/isArray';

export default function CustomerChoiceCaseInfoCard({
    lead,
}) {
    const { t } = useTranslation();

    const attributes = lead?.attributes?.length && isArray(lead.attributes) ? lead.attributes.map(attribute => attribute) : Object.values(lead.attributes).flatMap(attributeArray => Object.values(attributeArray));

    const caseInfo = [
        lead.primarySector?.displayName && {
            label: t('leads.lead_info.sector'),
            value: lead.primarySector.displayName
        },
        lead.industries?.subIndustries && {
            label: t('leads.lead_info.service_needed'),
            value: lead.industries?.subIndustries.map(industry => industry.name).join('; ')
        }
    ].filter(Boolean).concat(attributes.map(attribute => ({
        label: attribute.label,
        value: attribute.selected.map(value => value.title).join('; ')
    })));

    return (
        <Card>
            <Card.Header modifiers={[ 'pY_2' ]}>
                <strong>{t('quotes.creation.description.case_highlight')}</strong>
            </Card.Header>
            <Card.Content>
                <div>
                    {caseInfo.map((info) =>
                        <Flex key={'case-' + info.label} modifiers={['pY_1']}>
                            <Col sm={6}>
                                {info.label}
                            </Col>
                            <Col sm={6}>
                                {info.value}
                            </Col>
                        </Flex>
                    )}
                </div>
            </Card.Content>
        </Card>
    );
}
