import i18next from 'i18next'

export function parseRegExFloat(string, language) {
    let parsedValue = String(string).replace(/[^0-9&&^.&&^,]/g, '').replace(/,/g, '.')
    if(parsedValue.search(',') !== -1 || parsedValue.indexOf('.') !== -1) {
        const [ a, ...rest ] = parsedValue.split('.')
        parsedValue = [ a, '.', ...rest ].join('')
    }
    if (language && i18next.language === 'de')
        parsedValue = parsedValue.replace('.', ',')
    return parsedValue
}
