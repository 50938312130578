import { useQueryClient } from '@tanstack/react-query'
import { useAgerasApi } from 'ageras-api-client/src'
import {
    IndustriesApiFetchParamCreator,
    IndustryGroupResult,
    LeadsApiFetchParamCreator,
    LeadTypeGroupResult,
} from 'ageras-api-client/src/api'
import { KEY_PARTNER } from 'ageras-api-client/src/queryKeys'
import Flex from 'components/src/layout/Flex'
import Loader from 'components/src/Loader'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import usePartner from '../../utils/usePartner'
import { MarketplacePreferencesForm } from './MarketplacePage/PreferencesPage'
import Card from 'components/src/Card'
import { toastr } from 'react-redux-toastr'

const LoaderWrapper = styled(Flex)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

const Preferences = () => {
    const { partner } = usePartner()
    const queryClient = useQueryClient()
    const { t } = useTranslation()

    const { data: industryGroups, isFetched: industryGroupsFetched } = useAgerasApi<IndustryGroupResult>(IndustriesApiFetchParamCreator().industriesGroupIndex(
        String(partner?.geo?.code),
        1000,
    ), { enabled: Boolean(partner?.geo?.code) })

    const { data: leadTypeGroups, isFetched: leadTypeGroupsFetched } = useAgerasApi<LeadTypeGroupResult>(LeadsApiFetchParamCreator().leadsTypegroupsIndex(
        String(partner?.geo?.code),
        1000,
    ), { enabled: Boolean(partner?.geo?.code) })

    const onSuccessSubmit = useCallback( async () => {
        await queryClient.invalidateQueries([ KEY_PARTNER + '/' + partner?.id ])
        toastr.success(t('marketplace.preferences.success_title'), t('marketplace.preferences.success_description'))
    }, [ queryClient, partner ])

    if (!industryGroupsFetched || !leadTypeGroupsFetched || !partner) {
        return (<Flex minHeight={400} minWidth={400}>
            <LoaderWrapper>
                <Loader />
            </LoaderWrapper>
        </Flex>)
    }

    return <Card>
        <Card.Content>
            {industryGroups && leadTypeGroups && <Flex p={6}>
                <MarketplacePreferencesForm partner={partner} industryGroups={industryGroups?.data ?? []} leadTypeGroups={leadTypeGroups?.data ?? []} onSuccessSubmit={onSuccessSubmit}/>
            </Flex>}
        </Card.Content>
    </Card>
}

export default Preferences
