import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Field, reduxForm } from 'redux-form'
import LoadingAnimation from '../utils/LoadingAnimation'
import Select from 'react-select'
import monthOptions from '../utils/monthOptions'
import { dangerColor } from '../../theme/variables'

const FIELD_EDUCATION = 'educationName'
const FIELD_FACILITY = 'facilityName'
const FIELD_FROM_YEAR = 'fromDateYear'
const FIELD_FROM_MONTH = 'fromDateMonth'
const FIELD_TO_YEAR = 'toDateYear'
const FIELD_TO_MONTH = 'toDateMonth'
const FIELD_CONTENT = 'content'
const FIELD_DEGREE_LEVEL = 'degreeLevel'

@withTranslation('', { wait: true })
@reduxForm({
    form: 'educationCv',
})

export default class EducationForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                [FIELD_EDUCATION]: this.props.initialValues.educationName || '',
                [FIELD_FACILITY]: this.props.initialValues.facilityName || '',
                [FIELD_FROM_MONTH]: this.props.initialValues.fromDateMonth || '01',
                [FIELD_FROM_YEAR]: this.props.initialValues.fromDateYear || '',
                [FIELD_TO_MONTH]: this.props.initialValues.toDateMonth || '01',
                [FIELD_TO_YEAR]: this.props.initialValues.toDateYear || '',
                [FIELD_CONTENT]: this.props.initialValues.content || '',
                [FIELD_DEGREE_LEVEL]: this.props.initialValues.degreeLevel ? this.props.initialValues.degreeLevel.toString() : '',
            },
            errors: [],
        }
    }

    handleInput = evt => {
        const value = evt.target.value
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [evt.target.name]: value,
            },
        }))
    }

    handleSelect = (evt, name) => {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [name]: evt,
            },
        }))
    }

    validateForm = event => {
        event.preventDefault()
        this.setState({
            errors: [],
        }, () => {
            const { data } = this.state
            const errors = []

            if (data[FIELD_EDUCATION] === '') {
                errors.push(FIELD_EDUCATION)
            }

            if (data[FIELD_FACILITY] === '') {
                errors.push(FIELD_FACILITY)
            }

            if (data[FIELD_DEGREE_LEVEL] === '') {
                errors.push(FIELD_DEGREE_LEVEL)
            }

            if (data[FIELD_FROM_YEAR] === '' || isNaN(data[FIELD_FROM_YEAR])) {
                errors.push(FIELD_FROM_YEAR)
            }

            if (isNaN(data[FIELD_TO_YEAR])) {
                errors.push(FIELD_TO_YEAR)
            }

            this.setState({
                errors: [
                    ...this.state.errors,
                    ...errors,
                ],
            }, () => {
                if (this.state.errors.length === 0) this.props.handleSubmit(data)
            })
        })
    }

    render() {
        const {
            t,
            submitting,
        } = this.props

        const { educationName, facilityName, fromDateYear, fromDateMonth, toDateYear, toDateMonth, degreeLevel, content } = this.state.data
        const { errors } = this.state

        if (submitting) {
            return <LoadingAnimation />
        }

        return (
            <form onSubmit={this.validateForm}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <Field
                                onChange={evt => this.handleInput(evt)}
                                className="form-control"
                                name="educationName"
                                component="input"
                                placeholder={t('cv.education.placeholders.educationName')}
                                {...educationName}
                                style={{ borderColor: errors.includes(FIELD_EDUCATION) ? dangerColor : '' }}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <Field
                                onChange={evt => this.handleInput(evt)}
                                className="form-control"
                                name="facilityName"
                                component="input"
                                placeholder={t('cv.education.placeholders.facilityName')}
                                {...facilityName}
                                style={{ borderColor: errors.includes(FIELD_FACILITY) ? dangerColor : '' }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <Field
                                className="form-control"
                                onChange={evt => this.handleInput(evt)}
                                name="degreeLevel"
                                component="select"
                                {...degreeLevel}
                                style={{ borderColor: errors.includes(FIELD_DEGREE_LEVEL) ? dangerColor : '' }}
                            >
                                <option value="">{t('cv.education.placeholders.selectEducationLevel')}</option>
                                <option value="0">{t('cv.education.placeholders.educationLevels.highSchool')}</option>
                                <option value="1">{t('cv.education.placeholders.educationLevels.bachelor')}</option>
                                <option value="2">{t('cv.education.placeholders.educationLevels.master')}</option>
                                <option value="3">{t('cv.education.placeholders.educationLevels.doctoral')}</option>
                                <option value="4">{t('cv.education.placeholders.educationLevels.mba')}</option>
                                <option value="5">{t('cv.education.placeholders.educationLevels.course')}</option>
                            </Field>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="form-group">
                            <Field name="fromDateMonth" component={props => {
                                return <Select
                                    value={fromDateMonth}
                                    onChange={evt => this.handleSelect(evt, FIELD_FROM_MONTH)}
                                    options={monthOptions(t)}
                                    clearable={false}
                                    simpleValue
                                />
                            }} />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <Field
                                onChange={evt => this.handleInput(evt)}
                                className="form-control"
                                name="fromDateYear"
                                component="input"
                                type="text"
                                placeholder={t('cv.education.placeholders.fromDate.fromYear')}
                                {...fromDateYear}
                                style={{ borderColor: errors.includes(FIELD_FROM_YEAR) ? dangerColor : '' }}
                            />
                        </div>
                        <div className="year-hyphen">-</div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <Field name="toDateMonth" component={props => {
                                return <Select
                                    value={toDateMonth}
                                    onChange={evt => this.handleSelect(evt, FIELD_TO_MONTH)}
                                    options={monthOptions(t)}
                                    clearable={false}
                                    simpleValue
                                />
                            }} />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <Field
                                onChange={evt => this.handleInput(evt)}
                                className="form-control"
                                name="toDateYear"
                                component="input"
                                type="text"
                                placeholder={t('cv.education.placeholders.toDate.toYear')}
                                {...toDateYear}
                                style={{ borderColor: errors.includes(FIELD_TO_YEAR) ? dangerColor : '' }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <Field className="form-control"
                                   onChange={evt => this.handleInput(evt)}
                                   rows="5"
                                   component="textarea"
                                   placeholder={t('cv.education.placeholders.description')}
                                   name="content"
                                   {...content}
                            />
                        </div>
                    </div>
                </div>
                <button className="btn btn-action btn-lg btn-block" type="submit">
                    {t('cv.education.save')}
                </button>
            </form>
        )
    }
}

