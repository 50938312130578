import { createSelector } from 'reselect'

export const partnerDataFieldsSelctor = createSelector(
    state => state.pages.partnerProfile.dataFieldsIds,
    state => state.entities.dataFields,
    (dataFieldsIds, dataFields) =>
        dataFieldsIds.map(id => dataFields[id])
)

export const partnerCertificationsSelector = createSelector(
    state => state.pages.certifications.partnerCertificationIds,
    state => state.entities.certifications,
    (partnerCertificationIds, certifications) =>
        [ ...partnerCertificationIds ].map(id => certifications[id])
)

export const partnerRatingsSelector = createSelector(
    state => state.pages.ratings.ratingIds,
    state => state.entities.ratings,
    (ratingIds, ratings) =>
        [ ...ratingIds ]
            .map(id => ratings[id])
)

export const selectQuotePartner = createSelector(
    (state, leadId) => state.entities.leads[leadId],
    state => state.entities.partners,
    (lead, partners) => lead && lead.quotesInfo
        ? lead.quotesInfo.quotes.map(quote => partners[quote.partner.id]).filter(el => el)
        : []
    )
