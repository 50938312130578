import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as users from '../../actions/users'
import { currentPartnerSelector, currentPartnerUserSelector } from '../../selectors/auth'
import LoadingAnimation from '../utils/LoadingAnimation'
import PageTitle from '../utils/PageTitle'
import PartnerUserForm from '../partners/PartnerUserForm'
import ExpandButton from '../utils/ExpandButton'
import { Image } from 'react-bootstrap'
import Confirm from 'react-confirm-bootstrap'
import { Card, Content } from '../cards'

@connect(
    state => ({
        users: state.pages.partnerUsers.partnerUserIds.map(id => state.entities.partnerUsers[id]),
        isLoading: state.pages.partnerUsers.isLoading,
        partner: currentPartnerSelector(state),
        currentUser: currentPartnerUserSelector(state),
    }),
    dispatch => ({
        getUsers: bindActionCreators(users.getUsers, dispatch),
        createPartnerUser: bindActionCreators(users.createPartnerUser, dispatch),
        deletePartnerUser: bindActionCreators(users.deletePartnerUser, dispatch),
    })
)
@withTranslation('', { wait: true })
export default class UsersPage extends React.Component {
    componentDidMount() {
        this.refreshUsers(this.props)
    }

    handleDeleteUserClick() {
        this.props.deletePartnerUser(this.props.partner.id, this.props.user.id).then(response => {
            this.refreshUsers(this.props)
        })
    }

    refreshUsers(props) {
        props.partner && props.getUsers(props.partner)
    }

    handleSubmit(data) {
        this.props.createPartnerUser(this.props.partner.id, data).then(response => {
            this.refreshUsers(this.props)
        })
    }

    render() {
        const {
            users,
            isLoading,
            currentUser,
            t,
        } = this.props

        if (isLoading) {
            return <Card>
                <Content>
                    <LoadingAnimation />
                </Content>
            </Card>
        }

        return (
            <div className="card">
                <PageTitle identifier="navigation.users" />
                <h2>{t('navigation.users')}</h2>
                <p className="intro-text">{t('partner_user.introduction')}</p>
                <hr/>
                <table className="datatable responsive">
                    <thead>
                        <tr>
                            <th />
                            <th>{t('partner_user.name')}</th>
                            <th>{t('partner_user.contact_info')}</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user =>
                            <tr className="user" key={user.id}>
                                <td>
                                    <Image src={user.profilePicture ? user.profilePicture : '/images/icons/partner-users.svg'} />
                                </td>
                                <td>
                                    {user.firstName} {user.lastName}<br />
                                    <span>{user.accessType == 'master' ? t('partner_user.administrator') : t('partner_user.user')}</span>
                                </td>
                                <td>
                                    {user.email}<br />
                                    {user.mobile}
                                </td>
                                <td>
                                    {currentUser.accessType == 'master' && user.id != currentUser.id &&
                                    <Confirm
                                        onConfirm={this.handleDeleteUserClick.bind(this)}
                                        body={t('partner_user.delete_message')}
                                        confirmText={t('partner_user.confirm_text')}
                                        title={t('partner_user.delete')}>
                                        <a className="btn btn-danger btn-sm">{t('partner_user.delete')}</a>
                                    </Confirm>}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <hr />
                <ExpandButton
                    className="success"
                    content={
                        <PartnerUserForm onSubmit={this.handleSubmit.bind(this)}/>
                    }
                    header={t('partner_user.create')}
                />
            </div>
        )
    }
}
