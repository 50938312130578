
/* Colors */
export const lightGrey = '#E1E7E9'
export const lightBlue = '#00A4EB'
export const darkBlue = '#333C45'
export const darkGrey = '#8197AD'
export const ctaColor = '#25CA74'
export const cyanColor = '#45C4D7'
export const inputBackgroundColor = '#F9FBFC'
export const activeColor = lightBlue
export const primaryColorTwo = '#0072BC'
export const successColor = '#00c561'
export const backgroundColor = '#EEF3F6'
export const fadedTextColor = '#7E7E7E'
export const theadBackgroundColor = '#F8FAFC'
export const statusNewColor = '#888888'
export const statusPaidColor = '#1ec483'
export const progressDraftColor = 'rgb(157, 174, 182)'
export const secondaryColorTwo = '#9DAEB6'
export const dangerColorTwo = '#f96152'
export const wellBackgroundColor = '#F2F6F8'
export const tableBorderColor = '#E1E7E9'
export const secondaryActiveColor = '#7ED321'
export const progressProvidedColor = '#FFCE50'
export const selectedListItemColor = '#EBF1F5'
export const cardBorderColor = '#C7D7DD'
export const cardSeparatorColor = '#E3EAEC'
export const linkColor = primaryColorTwo
export const sidebarBorderColor = lightGrey
export const highlightBackgroundColor = theadBackgroundColor
export const metaTextColor = '#8F8F8F'
export const cardLabelColor = '#65768c'
export const menetoCyanColor = '#45C4D7'

/* Buttons color */
export const primaryColor = lightBlue
export const primaryLightColor = '#00affa'
export const primaryDarkColor = '#0099dc'

export const secondaryColor = darkGrey
export const secondaryLightColor = '#91aac3'
export const secondaryDarkColor = '#798da0'

export const actionColor = ctaColor
export const actionLightColor = '#27d77b'
export const actionDarkColor = '#23bd6d'

export const dangerColor = '#e63637'
export const dangerLightColor = '#ec4d4e'
export const dangerDarkColor = '#e62527'

export const warningColor = '#f0861c'
export const warningLightColor = '#f3983c'
export const warningDarkColor = '#e4770c'

/* Screen width */
export const landscapeMobileBreakpoint = 670
export const smallDevicesBreakpoint = 767
export const mobileBreakpoint = 855

/* Borders */
export const selectedBorder = '3px'

export const cardPadding = '30px'
