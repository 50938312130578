import {
    CREATE_QUOTE,
    UPDATE_QUOTE,
    CREATE_QUOTE_SUCCESS,
    UPDATE_QUOTE_SUCCESS,
    CREATE_QUOTE_FAIL,
    UPDATE_QUOTE_FAIL,
} from '../../actions/quotes'
import {
    GET_LEAD,
    GET_LEAD_SUCCESS,
    GET_LEAD_FAIL,
} from '../../actions/leads'
import {
    GET_LEAD_CERTIFICATIONS,
    GET_LEAD_CERTIFICATIONS_FAIL,
    GET_LEAD_CERTIFICATIONS_SUCCESS,
} from '../../actions/certifications'

const initialState = {
    isSaving: false,
    isLoading: false,
    leadCertificationsId: [],
    isLoadingCertification: false,
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CREATE_QUOTE:
        case UPDATE_QUOTE:
            return {
                ...state,
                isSaving: true,
            }
        case CREATE_QUOTE_SUCCESS:
        case UPDATE_QUOTE_SUCCESS:
            return {
                ...state,
                isSaving: false,
            }
        case CREATE_QUOTE_FAIL:
        case UPDATE_QUOTE_FAIL:
            return {
                ...state,
                isSaving: false,
            }
        case GET_LEAD:
            return {
                ...state,
                isLoading: true,
            }
        case GET_LEAD_SUCCESS:
        case GET_LEAD_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        case GET_LEAD_CERTIFICATIONS:
            return {
                ...state,
                isLoadingCertification: true,
            }
        case GET_LEAD_CERTIFICATIONS_SUCCESS:
            return {
                ...state,
                leadCertificationsId: action.response.result,
                isLoadingCertification: false,
            }
        case GET_LEAD_CERTIFICATIONS_FAIL:
            return {
                ...state,
                isLoadingCertification: false,
            }
        default:
            return state
    }
}
