import { applyResponsiveStyleModifiers, applyStyleModifiers } from 'styled-components-modifiers'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const MODIFIER_TAB_CONFIG = {
    active: () => `
        border-bottom: 3px solid #fff;
        padding: 17px 40px;
    `,
}

export default styled(Link)`
    display: inline-block;
    cursor: pointer;
    padding: 20px 40px;
    ${applyStyleModifiers(MODIFIER_TAB_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_TAB_CONFIG)}
`
