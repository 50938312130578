import { createSelector } from 'reselect'

const notificationsSelector = state => state.entities.notifications

export const numberOfUnseenNotificationsSelector = createSelector(
    notificationsSelector,
    notifications => {
        return Object.values(notifications).filter(notification => {
            return !notification.seenAt
        }).length
    }
)
