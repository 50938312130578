import styled from 'styled-components'
import { colors } from '../../../theme/variables_new'
import { applyResponsiveStyleModifiers, applyStyleModifiers } from 'styled-components-modifiers'
import { MODIFIER_MARGIN_CONFIG, MODIFIER_PADDING_CONFIG } from '../../elements/modifiers/spaceModifiers'
import { Flex } from '../../elements/Flex'
import { Modal } from 'react-bootstrap'

export const CircleNumber = styled.div`
    display: inline-block;
    position: relative;
    width: ${({ small }) => small ? 30 : 40}px;
    height: ${({ small }) => small ? 30 : 40}px;
    border-radius: 50%;
    background-color: ${({ active }) => active ? colors.blue : colors.grey};
    ${({ actionable }) => actionable && 'cursor: pointer;'}

    &::before {
        content: '${({ number }) => number}';
        font-weight: bold;
        font-size: ${({ small }) => small ? 16 : 20}px;
        position: absolute;
        top: 53%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: ${colors.white};
    }

    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)}
`

export const ChevronButton = styled.span`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
    color: ${({ disabled }) => disabled ? colors.darkBlue + '40' : colors.darkBlue};
    ${({ disabled }) => disabled && '\n' +
    '   cursor: not-allowed;\n' +
    '   & > svg {\n' +
    '       pointer-events: none;\n' +
    '}'}
`
