import { Modal } from 'react-bootstrap'
import { Button } from '../elements/ButtonNew'
import { Elements } from '@stripe/react-stripe-js'
import { Flex } from '../elements/Flex'
import { Text } from '../elements/text/Text'
import { Col, Row } from 'react-bootstrap'
import LoadingAnimation from '../utils/LoadingAnimation';
import PaymentMethodForm from './PaymentMethodForm';
import usePrepareStripeElements from './usePrepareStripeElements';
import { useEffect, useState } from 'react';
import { Card, Content } from '../cards';
import { StripeRedirectCreateSubscription, StripeRedirectProductKey, StripeRedirectPromotionCode } from './constants';
import { useDispatch } from 'react-redux'
import { createSubscription } from '../../actions/cashier'
import { useTranslation } from 'react-i18next'
import { toastr } from 'react-redux-toastr'
import styled from 'styled-components'
import { FormControl } from 'components/src/inputs/FormControl'
import { Input } from 'components/src/inputs/Input'

const ExternalLink = styled.a`
    text-decoration: underline;
`

export default function CreateSubscriptionModal ({
    partner,
    product,
    requirePaymentMethod,
    show,
    toggle,
}){
    const { isLoading, stripePromise, stripeClientSecret, options, prepareCardElements } = usePrepareStripeElements(partner);
    const [loading, setLoading] = useState(false);
    const [promotionCode, setPromotionCode] = useState('');
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if(show && !stripeClientSecret && !isLoading){
            prepareCardElements();
            sessionStorage.setItem(StripeRedirectProductKey, product.id);
            sessionStorage.removeItem(StripeRedirectPromotionCode);
        }
    }, [show, product, stripeClientSecret, isLoading, prepareCardElements]);

    const subscribeToProduct = async (e) => {
        try {
            setLoading(true);
            await dispatch(createSubscription(partner, product.id, promotionCode));
        } catch(err) {
            toastr.error(t('stripe_subscription_page.subscription.create_error.title'), t('stripe_subscription_page.subscription.create_error.description'));
        } finally {
            toggle(false);
            setLoading(false);
            toastr.confirm(t('stripe_subscription_page.subscription.create_success'));
        }
    };

    const onPromotionCodeUpdate = (value) => {
        setPromotionCode(value)
        sessionStorage.setItem(StripeRedirectPromotionCode, value);
    }

    const paymentLines = [
        {
            description: product.name,
            price: product.priceExclVat + ' ' + product.currency.toUpperCase(),
        }
    ];

    if(product.vatRate > 0){
        paymentLines.push({
            description: product.vatRate + '% ' + t('stripe_subscription_page.subscription.vat'),
            price: product.vat + ' ' + product.currency.toUpperCase(),
        });
    }

    return (
        <Modal show={show}
            animation={true}
            onHide={() => toggle(false)}>
            <Modal.Body>
                {isLoading && (
                    <LoadingAnimation />
                )}
                {stripeClientSecret && (
                    <Content>
                        <Card>
                            <Content>
                                <Flex modifiers={['justifySpaceBetween']}>
                                    <Text modifiers={[ 'bold' ]}>
                                        {t('stripe_subscription_page.subscription.create_modal.title')}
                                    </Text>
                                </Flex>
                                <br />
                                <Row>
                                    <Col sm={8}>
                                        {paymentLines.map((line) =>
                                            <Text modifiers={['small']}>
                                                {line.description}
                                            </Text>
                                        )}
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'right' }}>
                                        {paymentLines.map((line) =>
                                            <Text>
                                                {line.price}
                                            </Text>
                                        )}
                                    </Col>
                                </Row>

                                <hr />
                                <Flex modifiers={['justifySpaceBetween']}>
                                    <Text modifiers={[ 'bold' ]}>{t('stripe_subscription_page.subscription.create_modal.total')}</Text>
                                    <Text modifiers={[ 'bold' ]}>{product.priceInclVat} {product.currency.toUpperCase()}</Text>
                                </Flex>
                            </Content>
                        </Card>
                        <FormControl label={t('stripe_subscription_page.subscription.create_modal.promotion_code')}>
                            <Input name="promotion_code" value={promotionCode} style={{ backgroundColor: 'white' }}  onChange={(e) => onPromotionCodeUpdate(e.target.value)}/>
                        </FormControl>
                        {requirePaymentMethod ?
                            <Elements options={options} stripe={stripePromise}>
                                <PaymentMethodForm stripeClientSecret={stripeClientSecret} buttonText={t('stripe_subscription_page.subscribe')} redirectId={StripeRedirectCreateSubscription}/>
                            </Elements>
                        :
                            <Button modifiers={[ 'action', 'bold', 'small' ]} disabled={loading} onClick={ () => subscribeToProduct() }>
                                <span>
                                    {t('stripe_subscription_page.subscribe')}
                                </span>
                            </Button>
                        }
                        <Text modifiers={['tiny']}>
                            <p>
                                {t('stripe_subscription_page.subscription.terms_of_service_agree')} <ExternalLink href={t('stripe_subscription_page.subscription.terms_of_service_link')} target={'_blank'} rel="noreferrer">{t('stripe_subscription_page.subscription.terms_of_service_clickable')}</ExternalLink>
                            </p>
                        </Text>
                    </Content>
                )}
            </Modal.Body>
        </Modal>
    );
}
