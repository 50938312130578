import { LeadCustomerTypeResource } from 'ageras-api-client/src/api'

function isIndividualType(leadType: LeadCustomerTypeResource, geoCode: string) {
    if (geoCode === 'us') {
        return leadType.id === 77
    }
    return false
}

export default isIndividualType
