import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as ratings from '../../actions/ratings'
import LoadingAnimation from '../utils/LoadingAnimation'
import RatingItem from '../marketing/RatingItem'
import { currentPartnerSelector, currentPartnerUserSelector } from '../../selectors/auth'
import PageTitle from '../utils/PageTitle'
import { Card } from '../blocks/Card'
import { Button } from '../elements/Button'

@connect(
    state => ({
        ratings: Object.values(state.entities.ratings),
        currentPage: state.pages.ratings.currentPage,
        isLoading: state.pages.ratings.isLoading,
        isLoadingMore: state.pages.ratings.isLoadingMore,
        partner: currentPartnerSelector(state),
        partnerUser: currentPartnerUserSelector(state),
    }),
    dispatch => ({
        getRatings: bindActionCreators(ratings.getRatings, dispatch),
        updateRating: bindActionCreators(ratings.updateRating, dispatch),
    })
)
@withTranslation('', { wait: true })
export default class RatingsPage extends React.Component {
    componentDidMount() {
        this.refreshRatings(this.props)
    }

    featureRating(ratingId) {
        this.props.updateRating(ratingId, {
            is_featured: true,
        }).then(response => {
            this.props.getRatings(this.props.partner.id, 1)
        })
    }

    unFeatureRating(ratingId) {
        this.props.updateRating(ratingId, {
            is_featured: false,
        })
    }

    replyToRating(ratingId, comment) {
        this.props.updateRating(ratingId, {
            reply: {
                comment: comment,
            },
        })
    }

    loadMore() {
        this.props.getRatings(this.props.partner.id, this.props.currentPage + 1, true)
    }

    refreshRatings(props) {
        const { partner } = props
        if (partner) {
            this.props.getRatings(partner.id)
        }
    }

    render() {
        const {
            ratings, isLoading, isLoadingMore, t,
        } = this.props

        const hasRatings = Boolean(ratings.length)

        return (
            <Card>
                <Card.Content>
                    <PageTitle identifier="navigation.reviews" />
                    <Card.Title>{t('navigation.reviews')}</Card.Title>
                    <Card.Intro>{t('rating.intro')}</Card.Intro>

                    {isLoading ?
                        <LoadingAnimation /> :
                        <div>
                            {ratings.map(rating =>
                                <RatingItem
                                    key={rating.id}
                                    partnerUser={this.props.partnerUser}
                                    rating={rating}
                                    featureRating={this.featureRating.bind(this)}
                                    unFeatureRating={this.unFeatureRating.bind(this)}
                                    replyToRating={this.replyToRating.bind(this)}
                                />
                            )}

                            {isLoadingMore && <LoadingAnimation /> }

                            {hasRatings ?
                                <Button
                                    modifiers={[ 'primary, p_2', 'btnBlock' ]}
                                    onClick={this.loadMore.bind(this)}
                                >
                                    {t('rating.load_more')}
                                </Button> :
                                <div>
                                    <Card.Line modifiers={[ 'cardWidth' ]}/>
                                    <p>{t('rating.no_ratings')}</p>
                                </div>
                            }
                        </div>
                    }
                </Card.Content>
            </Card>
        )
    }
}
