import styled from 'styled-components'
import { Card } from '../blocks/Card'

export default styled(Card.Content)`
height: 100px;
text-align: center;
img {
  max-height:40px;
  max-width: 100%;
  borderRadius: 3px;
}
`
