import React from 'react'
import {
    Field,
    FieldArray,
    reduxForm,
} from 'redux-form'
import { connect } from 'react-redux'
import { ActionBarLineCard } from './create-quote-form-cards/ActionBarLineCard'
import { PartnerTermsLineCard } from './create-quote-form-cards/PartnerTermsLineCard'
import { QuoteItemsLineCard } from './create-quote-form-cards/QuoteItemsLineCard'
import { QuoteDescriptionLineCard } from './create-quote-form-cards/QuoteDescriptionLineCard'

@connect(
    (state, props) => ({
        initialValues: {
            items: (props.lead.products || []).map(product => ({
                title: product.product.name + (Boolean(product.description) && (' - ' + product.description) || ''),
                type: product.type,
                leadProductId: product.id,
            })),
            ...props.quote,
        },
    })
)
@reduxForm({
    form: 'quote',
    destroyOnUnmount: true,
    enableReinitialize: true,
})
export default class CreateQuoteForm extends React.PureComponent {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        const {
            handleSubmit,
            publishQuote,
            refuseQuote,
            onSubmit,
            currency,
            disabled,
            quote,
            lead,
            t,
            hasMarketplaceAccess,
            setProductLinesRequired,
            productLinesRequired
        } = this.props

        return (
            <form onSubmit={handleSubmit(onSubmit)} className={'quote-edit-form' + (disabled && ' disabled' || '')}>
                <Field
                    name="description"
                    component={QuoteDescriptionLineCard}
                    disabled={disabled}
                    lead={lead}
                />

                <FieldArray
                    name="items"
                    component={QuoteItemsLineCard}
                    disabled={disabled}
                    currency={currency}
                    setProductLinesRequired={setProductLinesRequired}
                    productLinesRequired={productLinesRequired}
                    lead={lead}
                    quote={quote}
                />

                <Field
                    name="partnerTerms"
                    component={PartnerTermsLineCard}
                    disabled={disabled}
                />

                <ActionBarLineCard
                    handleSubmit={handleSubmit(publishQuote)}
                    refuseQuote={refuseQuote}
                    disabled={disabled}
                    quote={quote}
                    lead={lead}
                    t={t}
                    hasMarketplaceAccess={hasMarketplaceAccess}
                />
            </form>
        )
    }
}
