import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { primaryColor, successColor } from '../../theme/variables'

const buttonShadow = '0px 2px 4px 0px rgba(200,207,211,0.5)'

export const BlockButton = styled(Link)`
    border-radius: 3px;
    background-color: ${successColor};
    color: #fff;
    padding: 20px 30px;
    box-shadow: ${buttonShadow};
    text-decoration: none;
    width: 80%;
    display: block;
    margin: 30px auto;
`

export const TableCellButton = styled(Link)`
    border-radius: 3px;
    background-color: #00A4EB;
    color: #fff;
    padding: 10px 15px;
    box-shadow: ${buttonShadow};
    text-decoration: none;
`
