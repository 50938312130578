import React from 'react'
import styled from 'styled-components'
import FormattedAmount from '../../components/utils/FormattedAmount'
import { Link } from 'react-router-dom'
import { Flex } from '../../components/elements/Flex'
import Portrait from '../../components/elements/Portrait'
import { BtnLink } from '../../components/elements/Links'
import { media } from '../../components/utils/styledMedia'
import { Card } from '../../components/blocks/Card'
import { menetoCyanColor } from '../../theme/variables'
import PartnerLogoContent from '../../components/elements/PartnerLogoContent'
import { StarRatingInput } from '../../components/utils/StarRatingInput'

const POINTS = [
    { text: 'Gratis app voor scannen bonnen', icon: 'cellphone-android' },
    { text: 'Automatisch kwartaal (BTW) aangifte doen', icon: 'timer' },
    { text: 'BTW en IB aangiften door onze boekhouder', icon: 'briefcase-check' },
    { text: 'Overzichtelijk al je financiën in een dashboard', icon: 'finance' },
]

const TellowButLink = styled(BtnLink)`
  background-color: ${menetoCyanColor};
  border-color: ${menetoCyanColor};
  &:active, &:focus, &:hover, &:disabled {
    background-color: #4ad4e8;
  }
`
const PointRow = styled(Card.Label)`
    font-size: 14px;
    line-height: 28px;
    display: flex;
    align-items: center;
    i {
      color: #45C4D7;
      font-size: 22px;
      margin-right: 10px;
    }
`

const PartnerActionContent = styled(Card.Content)`
      text-align: left;
      ${Card.Label} {
        font-size: 14px;
      }
`

const PartnerInfoContent = styled(Card.Content)`
    display: flex;
    align-items: center;
    ${media.max.landscapeMobile`
          padding-right: 15px;
    `}
    .mdi-chevron-right {
      font-size: 28px;
    }
    ${media.min.landscapeMobile`
      height: 190px;
      .mdi-chevron-right {
        display: none;
      }
      ${Card.Label} {
        font-size: 14px;
      }
  `}
`

const StrategicContent = styled(Card.Content)`
  text-align: center;
  border-bottom: 5px solid ${menetoCyanColor};
  ${Card.Label} {
    font-size: 14px;
    margin-bottom: 0;
  }
`

const CompanyName = styled(Card.Label)`
  word-break: break-all;
  font-size: 14px;
`

const StarRating = styled(StarRatingInput)`
  position: absolute;
  right: 30px;
`

export const TellowQuote = ({
    partner,
    quote,
    lead,
}) => {
    const partnerCityLength = quote.partner.city.length

    const companyName = (quote.partner.companyName.length + partnerCityLength) > 65
        ? quote.partner.companyName.slice(0, 65 - partnerCityLength) + '...'
        : quote.partner.companyName

    const partnerUserName = partner.partnerUser.name.length > 65 ? partner.partnerUser.name.slice(0, 60) + '...' : partner.partnerUser.name

    return <Link to={`/cases/${lead.id}/${partner.id}`} onClick={e => !partner.id && e.preventDefault()}>
        <Card modifiers={[ 'mB_1' ]}>
            <PartnerInfoContent>
                <Flex modifiers={[ 'justifySpaceBetween' ]}>
                    <div>
                        <Portrait
                            square
                            modifiers={[ 'mR_2' ]}
                            name={partner.partnerUser && partner.partnerUser.name}
                            image={partner.partnerUser && partner.partnerUser.profilePicture}
                            size={60}
                        />
                    </div>
                    <div>
                        <CompanyName>{companyName}</CompanyName>
                        <Card.Title><b>{partnerUserName}</b></Card.Title>
                        <Card.Label>{quote.partner.city}</Card.Label>
                        {partner.rating && Boolean(partner.rating.stars) && <StarRating filled={partner.rating.stars} total={5} />}
                    </div>
                </Flex>
                <b className="mdi mdi-chevron-right" />
            </PartnerInfoContent>
            <Card.Line />
            <PartnerActionContent>
                <Card.Title>
                    <b>
                        <FormattedAmount
                            currency={quote.totalAmountExclVat.currency}
                            amount={quote.totalAmountExclVat.amount}
                            decimals={2}
                        />/per jaar
                    </b>
                </Card.Title>
                <Card.Label>Alles inclusief - je betaalt per maand <FormattedAmount
                    currency={quote.totalAmountExclVat.currency}
                    amount={quote.totalAmountExclVat.amount / 12}
                    decimals={2}
                /></Card.Label>
                {partner.id && <TellowButLink
                    to={`/cases/${lead.id}/${partner.id}`}
                    className="hidden-xs"
                    modifiers={[ 'action', 'p_2', 'fullWidth', 'mT_2', 'btnBlock' ]}
                >
                    Zie aanbieding
                </TellowButLink>}
            </PartnerActionContent>
            <Card.Line />
            <Card.Content>
                {POINTS.map(el =>
                    <PointRow key={el.icon}><i className={`mdi mdi-${el.icon}`} /> {el.text}</PointRow>
                )}
            </Card.Content>
            <Card.Line />
            <StrategicContent modifiers={[ 'pY_2' ]}>
                <Card.Label> - STRATEGISCHE PARTNER MET AGERAS -</Card.Label>
            </StrategicContent>
        </Card>
        {partner.logo && <Card modifiers={[ 'mB_1' ]}>
            <PartnerLogoContent>
                <img src={partner.logo} />
            </PartnerLogoContent>
        </Card>}
        {partner.attributes && Object.values(partner.attributes).map(el => el && el.identifier === 'interview_quote' &&
            <Card>
                <Card.Content>
                    <Card.Intro modifiers={[ 'm_0' ]}>
                        <i>&quot;{el.selected[0].title}&quot;</i>
                    </Card.Intro>
                </Card.Content>
            </Card>
        )}
    </Link>
}
