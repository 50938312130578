import {
    GET_DELIVERABLES, GET_DELIVERABLES_SUCCESS, GET_DELIVERABLES_FAIL,
} from '../../actions/deliverables'

const initialState = {
    items: [],
    isLoading: false,
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    case GET_DELIVERABLES:
        return {
            ...state,
            isLoading: true,
        }
    case GET_DELIVERABLES_SUCCESS:
        return {
            ...state,
            items: action.response.result,
            isLoading: false,
        }
    case GET_DELIVERABLES_FAIL:
        return {
            ...state,
            isLoading: false,
        }
    default:
        return state
    }
}
