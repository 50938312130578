import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import * as auth from '../../actions/auth'
import { queryParametersSelector } from '../../selectors/routing'

const selector = formValueSelector('passwordReset')

@connect(
    (state, props) => ({
        queryParameters: queryParametersSelector(state),
        pathname: state.router.location.pathname,
        email: selector(state, 'email'),
    }),
    dispatch => ({
        sendPasswordReminder: bindActionCreators(auth.sendPasswordReminder, dispatch),
    })
)
@reduxForm({
    form: 'passwordReset',
    // validate: values => values.password == values.passwordConfirmation,
})
@withTranslation('', { wait: true })
export default class PasswordResetForm extends React.Component {

    onSubmit(values) {
        console.log(values)
        return this.props.sendPasswordReminder(values.email)
    }

    render() {
        const {
            t,
            pristine,
            submitting,
            handleSubmit,
            submitSucceeded,
            pathname,
            queryParameters,
        } = this.props

        return (
            <div>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <div style={{'display': 'flex', 'justifyContent': 'center'}}>
                        <img src="/images/ageras-logo-blue.svg"/>
                    </div>
                    { submitSucceeded && <div className="alert alert-success">
                        {t('login.reminder_sent')}
                    </div> }
                    { submitSucceeded || <div>
                        <div className="form-group">
                            <label>{t('login.email')}</label>
                            <Field name="email" className="form-control" component="input" disabled={submitting} placeholder={t('login.email')} />
                        </div>
                        <button className="btn btn-action btn-primary btn-lg btn-block" disabled={pristine || submitting}>{t('login.send_reminder')}</button>
                        <div className="center-block">
                            <br />
                            <Link to={{ pathname, query: { ...queryParameters, page: undefined } }}>{t('login.go_to_login_page')}</Link>
                        </div>
                    </div> }
                </form>
            </div>
        )
    }
}
