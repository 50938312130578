import React from 'react'
import { get } from 'lodash'
import { LineCard } from '../../cards'
import {
    dangerColor,
    secondaryColor,
    successColor,
} from '../../../theme/variables'
import { Card } from '../../blocks/Card'
import { Alert, FormControl, InputGroup, Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Field } from 'redux-form'
import AmountInput from '../../forms/inputs/AmountInput'
import styled from 'styled-components'
import { media } from '../../utils/styledMedia'
import { Button } from '../../elements/Button'
import { withTranslation } from 'react-i18next'
import { CheckboxBox } from '../../forms/inputs_new/CheckboxInput'
import { Flex } from '../../elements/Flex'
import config from '../../../config'

const CustomControl = ({ input, meta, ...props }) => (
    <FormControl {...input} {...props}/>
)

const DangerAlert = styled(Alert)`
  display: inline-block;
`

const MobileTBody = styled.tbody`
    ${media.min.landscapeMobile`display: none`}
    .currency {
        width: 100%;
    }
    td > * {
        margin: 5px 0;
    }
`

const DesktopTBody = styled.tbody`
    ${media.max.landscapeMobile`display: none`}
`

const MobileItemTable = ({
    addRowAndFocus,
    products,
    disabled,
    currency,
    fields,
    items,
    t,
}) => (
    <MobileTBody>
        { fields.map((member, index) => {
            if (items[index] && items[index].quantity == 0) {
                items[index].quantity = 1
            }
            return <tr key={index}>
                <td colSpan="3">
                    <Field
                        name={`${member}.id`}
                        component="input"
                        type="hidden"
                    />
                    <Field
                        name={`${member}.leadProductId`}
                        component="input"
                        type="hidden"
                    />
                    <Field
                        name={`${member}.title`}
                        component={CustomControl}
                        disabled={index < products.length || disabled}
                    />
                    {/*next col*/}
                    <Field
                        name={`${member}.type`}
                        component={CustomControl}
                        componentClass="select"
                        disabled={index < products.length || disabled}
                    >
                        <option value="yearly">{t('quotes.creation.products.product_types.yearly')}</option>
                        <option value="biannually">{t('quotes.creation.products.product_types.biannually')}</option>
                        <option value="quarterly">{t('quotes.creation.products.product_types.quarterly')}</option>
                        <option value="every_second_month">{t('quotes.creation.products.product_types.every_second_month')}</option>
                        <option value="monthly">{t('quotes.creation.products.product_types.monthly')}</option>
                        <option value="weekly">{t('quotes.creation.products.product_types.weekly')}</option>
                        <option value="unit">{t('quotes.creation.products.product_types.unit')}</option>
                    </Field>
                    {/*next col*/}
                    <Field
                        name={`${member}.quantity`}
                        disabled={products[index]?.leadProduct?.quantity || disabled}
                        component={CustomControl}
                    />
                    <Field
                        name={`${member}.amountExclVat.amount`}
                        component={AmountInput}
                        disabled={disabled}
                        currency={currency}
                    />
                </td>
                <td>
                    <Button
                        onClick={() => {
                            fields.remove(index)
                            if(fields.length < 2) {
                                fields.push({})
                            }
                        }}
                        modifiers={[ 'danger', 'btnIcon' ]}
                        className="mdi mdi-close"
                    />
                </td>
            </tr>
        })}
        <tr>
            <td colSpan={3} />
            <td>
                <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip">{t('quotes.creation.products.add_item_tooltip')}</Tooltip>}>
                    <Button
                        onClick={addRowAndFocus}
                        modifiers={[ 'action', 'btnIcon', 'mRsm' ]}
                        className="mdi mdi-plus"
                    />
                </OverlayTrigger>
            </td>
        </tr>
    </MobileTBody>
)

@withTranslation('', { wait: true })
export class QuoteItemsLineCard extends React.PureComponent {
    componentDidMount() {
        if (!this.props.fields.length) {
            this.addRow()
        }
    }

    addRow = () => {
        this.props.fields.push({
            type: 'yearly',
            quantity: 1,
        })
    }

    addRowAndFocus = () => {
        this.addRow()
        setTimeout(() => {
            this.title.focus()
        }, 1)
    }

    onKeyPress = event => {
        if (event.which === 13 /* Enter */) {
            event.preventDefault()
            this.addRowAndFocus()
        }
    }

    returnItemYearlyValueExclVat = index => {
        const fields = this.props.fields.getAll()

        const amountExclVat = get(fields[index], 'amountExclVat.amount', 0) * get(fields[index], 'quantity', 0)
        const frequency = get(fields[index], 'type', 'unit')

        switch (frequency) {
            case 'weekly':
                return amountExclVat * 52
            case 'every_second_month':
                return amountExclVat * 6
            case 'quarterly':
                return amountExclVat * 4
            case 'biannually':
                return amountExclVat * 2
            case 'monthly':
                return amountExclVat * 12
            case 'unit':
            case 'yearly':
            default:
                return amountExclVat
        }
    }

    returnQuoteYearlyValueExclVat = () => {
        const values = this.props.fields.map((field, index) => this.returnItemYearlyValueExclVat(index))

        return Math.round(values.reduce((acc, cur) => acc + cur, 0) * 100) / 100
    }

    render() {
        const {
            disabled,
            currency,
            fields,
            lead,
            setProductLinesRequired,
            productLinesRequired,
            quote,
            meta: {
                error,
            },
            t,
        } = this.props

        const items = fields.getAll()

        const existValues = items.filter(i => i.title).length

        const modifier = error ? 'danger' : existValues ? 'action' : 'secondary'

        const colorIndicator = error ? dangerColor : existValues ? successColor : secondaryColor

        const products = items.filter(i => i.leadProductId)

        const productContentModifiers = productLinesRequired ? {} : {
            filter: 'blur(2px)',
            cursor: 'not-allowed',
        }

        return (
            <LineCard borderColor={colorIndicator}>
                <Card.Header>
                    <Flex>
                        {config.marketsQuoteWithoutPrice.includes(lead.geo.code) && <CheckboxBox
                            checked={productLinesRequired}
                            onChange={setProductLinesRequired}
                        />}
                        <Card.MarkCircle size="40" modifiers={[ modifier, 'mR_2' ]}>2</Card.MarkCircle>
                        <strong>{t('quotes.creation.products.label')}</strong>
                    </Flex>
                </Card.Header>
                <Card.Content style={productContentModifiers}>
                    {error && <DangerAlert bsStyle="danger">{error}</DangerAlert>}
                    <Card.Intro>{t('quotes.creation.products.intro')}</Card.Intro>
                    {Boolean((lead.products || []).length) &&
                    <Alert bsStyle="info">{t('quotes.creation.products.required_items_intro')}</Alert>
                    }
                    <form onKeyPress={this.onKeyPress}>
                        <table className="datatable table-form">
                            <colgroup>
                                <col width="45%" />
                                <col width="15%" />
                                <col width="10%" />
                                <col width="15%" />
                                <col width="10%" />
                                <col width="5%" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>{t('quotes.creation.products.product_title_header')}</th>
                                    <th>{t('quotes.creation.products.product_type_header')}</th>
                                    <th>{t('quotes.creation.products.product_quantity_header')}</th>
                                    <th className="text-right">{t('quotes.creation.products.product_price_header')}</th>
                                    <th className="text-right">{t('quotes.creation.products.product_annual_price_header')}</th>
                                    <th />
                                </tr>
                            </thead>
                            <MobileItemTable
                                fields={fields}
                                products={products}
                                disabled={disabled}
                                t={t}
                                items={items}
                                currency={currency}
                                addRowAndFocus={this.addRowAndFocus}
                            />
                            <DesktopTBody>
                                {fields.map((member, index) => {
                                    if (items[index] && items[index].quantity == 0) {
                                        items[index].quantity = 1
                                    }
                                    return <tr key={index}>
                                        <td
                                            onMouseOver={this.toggleOverlay}
                                        >
                                            <Field
                                                name={`${member}.id`}
                                                component="input"
                                                type="hidden"
                                            />
                                            <Field
                                                name={`${member}.leadProductId`}
                                                component="input"
                                                type="hidden"
                                            />
                                            {items[index].title && <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={<Tooltip
                                                    style={{ fontSize: '16px' }}>{items[index].title}</Tooltip>}
                                            >
                                                <Field
                                                    name={`${member}.title`}
                                                    component={CustomControl}
                                                    inputRef={input => {
                                                        this.title = input
                                                    }}
                                                    disabled={index < products.length || disabled}
                                                    style={{ cursor: 'auto' }}
                                                />
                                            </OverlayTrigger>}
                                            {!items[index].title && <Field
                                                name={`${member}.title`}
                                                component={CustomControl}
                                                inputRef={input => {
                                                    this.title = input
                                                }}
                                                disabled={index < products.length || disabled}
                                                style={{ cursor: 'auto' }}
                                            />}
                                        </td>
                                        <td>
                                            <Field
                                                name={`${member}.type`}
                                                component={CustomControl}
                                                componentClass="select"
                                                disabled={index < products.length || disabled}
                                            >
                                                <option
                                                    value="yearly">{t('quotes.creation.products.product_types.yearly')}</option>
                                                <option
                                                    value="biannually">{t('quotes.creation.products.product_types.biannually')}</option>
                                                <option
                                                    value="quarterly">{t('quotes.creation.products.product_types.quarterly')}</option>
                                                <option
                                                    value="every_second_month">{t('quotes.creation.products.product_types.every_second_month')}</option>
                                                <option
                                                    value="monthly">{t('quotes.creation.products.product_types.monthly')}</option>
                                                <option
                                                    value="weekly">{t('quotes.creation.products.product_types.weekly')}</option>
                                                <option
                                                    value="unit">{t('quotes.creation.products.product_types.unit')}</option>
                                            </Field>
                                        </td>
                                        <td>
                                            <Field
                                                name={`${member}.quantity`}
                                                disabled={products[index]?.leadProduct?.quantity || disabled}
                                                component={CustomControl}
                                            />
                                        </td>
                                        <td className="text-right">
                                            <Field
                                                name={`${member}.amountExclVat.amount`}
                                                component={AmountInput}
                                                disabled={disabled}
                                                currency={currency}
                                            />
                                        </td>
                                        <td className="text-right">
                                            <span>{this.returnItemYearlyValueExclVat(index)}</span>
                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>{currency.symbol}</span>
                                        </td>
                                        <td>
                                            {index < products.length || disabled || <Button
                                                onClick={() => {
                                                    fields.remove(index)
                                                    if (fields.length < 2) {
                                                        fields.push({})
                                                    }
                                                }}
                                                modifiers={[ 'danger', 'btnIcon' ]}
                                                className="mdi mdi-close"
                                            />}
                                        </td>
                                    </tr>
                                })}
                                <tr>
                                    <td colSpan={5} />
                                    <td>
                                        <OverlayTrigger
                                            placement="left"
                                            overlay={
                                                <Tooltip id="tooltip">{t('quotes.creation.products.add_item_tooltip')}</Tooltip>
                                            }
                                        >
                                            <Button
                                                onClick={this.addRowAndFocus}
                                                modifiers={[ 'action', 'btnIcon', 'mRsm' ]}
                                                className="mdi mdi-plus"
                                            />
                                        </OverlayTrigger>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4} className="text-right">
                                        {t('quotes.creation.products.product_total_price_header')}
                                    </td>
                                    <td className="text-right">
                                        <span>{this.returnQuoteYearlyValueExclVat()}</span>
                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>{currency.symbol}</span>
                                    </td>
                                    <td />
                                </tr>
                            </DesktopTBody>
                        </table>
                    </form>
                </Card.Content>
            </LineCard>
        )
    }
}
