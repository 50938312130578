import styled from 'styled-components'

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 300px;
  width: 100%;
  background-size: contain;
  background-position: center;
  background-image: url(/images/cta-bg.png);
  background-repeat: no-repeat;
`
