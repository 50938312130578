import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Field, reduxForm } from 'redux-form'
import LoadingAnimation from '../utils/LoadingAnimation'

const options = [
    'facebook',
    'twitter',
    'linkedin',
    'website',
]

@withTranslation('', { wait: true })
@reduxForm({
    form: 'socialMediaForm',
    fields: [
        'url',
        'type',
    ],
})
export default class SocialMediaForm extends Component {

    render() {
        const {
            t,
            handleSubmit,
            submitting,
            initialValues,
            existingMedia,
            fields: {
                url,
                type,
            },
        } = this.props

        if(submitting) {
            return <LoadingAnimation />
        }

        return (
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <Field
                                className="form-control"
                                name="url"
                                component="input"
                                placeholder={t('cv.social_media.placeholder')}
                                {...url}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            {initialValues && initialValues.type && <Field
                                className="form-control"
                                name="type"
                                component="select"
                                {...type}
                            >
                                <option value={initialValues.type}>{t(`cv.social_media.type.${initialValues.type}`)}</option>
                            </Field>
                            }
                            {!initialValues && <Field
                                className="form-control"
                                name="type"
                                component="select"
                                {...type}
                            >
                                <option value="">{t('cv.social_media.type.social_media')}</option>
                                {existingMedia
                                    ? options.filter(option => !existingMedia.includes(option)).map(opt => <option key={'opt_' + opt} value={opt}>{t(`cv.social_media.type.${opt}`)}</option>)
                                    : options.map(opt => <option key={'opt_' + opt} value={opt}>{t(`cv.social_media.type.${opt}`)}</option>)
                                }
                            </Field>}
                        </div>
                    </div>
                </div>
                <button className="btn btn-action btn-lg btn-block" type="submit">
                    {t('cv.social_media.save')}
                </button>
            </form>
        )
    }
}
