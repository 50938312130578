import React from 'react'
import enhanceWithClickOutside from 'react-click-outside'

class PageHeaderDropdown extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            open: false,
        }
    }

    componentDidMount() {

    }

    // Called by enhanceWithClickOutside, not unused like IDE might say
    handleClickOutside() {
        this.setState({ open: false })
    }

    toggle() {
        this.setState({
            open: !this.state.open,
        })
    }

    handleButtonClick() {
        this.toggle()

        if (this.props.onButtonClick) {
            this.props.onButtonClick()
        }
    }

    render() {
        var { illustration } = this.props

        const illustrationStyle = {}

        if(this.props.profilePicture) {
            illustrationStyle.backgroundImage = 'url(' + this.props.profilePicture + ')'
        }

        return (
            <div id={this.props.id} className={'page-header-dropdown' + (this.state.open ? ' open' : '')}>
                {(this.props.notifications && this.props.notifications > 0) ?
                    <div className="notifications">
                        {this.props.notifications < 10 ? this.props.notifications : '9+'}
                    </div> : ''
                }
                <div className="button" onClick={this.handleButtonClick.bind(this)}>
                    {illustration &&
                    <div className="illustration" style={illustrationStyle}>
                        {illustration}
                    </div>
                    }
                    {this.props.button &&
                    <div className="content">{this.props.button}</div>
                    }
                </div>
                <div className="dropdown">
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default enhanceWithClickOutside(PageHeaderDropdown)
