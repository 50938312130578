import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const GET_CERTIFICATIONS = 'actions/GET_CERTIFICATIONS'
export const GET_CERTIFICATIONS_SUCCESS = 'actions/GET_CERTIFICATIONS_SUCCESS'
export const GET_CERTIFICATIONS_FAIL = 'actions/GET_CERTIFICATIONS_FAIL'

export const GET_PARTNER_CERTIFICATIONS = 'partners/GET_CERTIFICATIONS'
export const GET_PARTNER_CERTIFICATIONS_SUCCESS = 'partners/GET_CERTIFICATIONS_SUCCESS'
export const GET_PARTNER_CERTIFICATIONS_FAIL = 'partners/GET_CERTIFICATIONS_FAIL'

export const ADD_PARTNER_CERTIFICATION = 'partners/ADD_CERTIFICATION'
export const ADD_PARTNER_CERTIFICATION_SUCCESS = 'partners/ADD_CERTIFICATION_SUCCESS'
export const ADD_PARTNER_CERTIFICATION_FAIL = 'partners/ADD_CERTIFICATION_FAIL'

export const REMOVE_PARTNER_CERTIFICATION = 'partners/REMOVE_CERTIFICATION'
export const REMOVE_PARTNER_CERTIFICATION_SUCCESS = 'partners/REMOVE_CERTIFICATION_SUCCESS'
export const REMOVE_PARTNER_CERTIFICATION_FAIL = 'partners/REMOVE_CERTIFICATION_FAIL'

export const UPDATE_PARTNER_CERTIFICATION = 'partners/UPDATE_PARTNER_CERTIFICATION'
export const UPDATE_PARTNER_CERTIFICATION_SUCCESS = 'partners/UPDATE_PARTNER_CERTIFICATION_SUCCESS'
export const UPDATE_PARTNER_CERTIFICATION_FAIL = 'partners/UPDATE_PARTNER_CERTIFICATION_FAIL'

export const GET_LEAD_CERTIFICATIONS = 'leads/GET_CERTIFICATIONS'
export const GET_LEAD_CERTIFICATIONS_SUCCESS = 'leads/GET_CERTIFICATIONS_SUCCESS'
export const GET_LEAD_CERTIFICATIONS_FAIL = 'leads/GET_CERTIFICATIONS_FAIL'

export const getCertifications = criteria => dispatch => dispatch({
    [CALL_API]: {
        types: [ GET_CERTIFICATIONS, GET_CERTIFICATIONS_SUCCESS, GET_CERTIFICATIONS_FAIL ],
        endpoint: '/certifications',
        schema: schemas.certifications,
        options: {
            body: criteria,
        },
    },
})

export const getPartnerCertifications = partnerId => dispatch => dispatch({
    [CALL_API]: {
        types: [ GET_PARTNER_CERTIFICATIONS, GET_PARTNER_CERTIFICATIONS_SUCCESS, GET_PARTNER_CERTIFICATIONS_FAIL ],
        endpoint: `/partners/${partnerId}/certifications`,
        schema: schemas.certifications,
        options: {
            body: {
                limit: 1000,
            },
        },
    },
    partnerId,
})

export const addPartnerCertification = (partnerId, certificationId) => dispatch => dispatch({
    [CALL_API]: {
        types: [ ADD_PARTNER_CERTIFICATION, ADD_PARTNER_CERTIFICATION_SUCCESS, ADD_PARTNER_CERTIFICATION_FAIL ],
        endpoint: `/partners/${partnerId}/certifications`,
        schema: schemas.certification,
        options: {
            method: 'post',
            body: {
                id: certificationId,
            },
        },
    },
})

export const updatePartnerCertification = (partnerId, certificationIds) => ({
    [CALL_API]: {
        types: [ UPDATE_PARTNER_CERTIFICATION, UPDATE_PARTNER_CERTIFICATION_SUCCESS, UPDATE_PARTNER_CERTIFICATION_FAIL ],
        endpoint: `/partners/${partnerId}/certifications`,
        options: {
            method: 'put',
            body: {
                certificationIds,
            },
        },
    },
})

export const removePartnerCertification = (partnerId, certificationId) => dispatch => dispatch({
    [CALL_API]: {
        types: [ REMOVE_PARTNER_CERTIFICATION, REMOVE_PARTNER_CERTIFICATION_SUCCESS, REMOVE_PARTNER_CERTIFICATION_FAIL ],
        endpoint: `/partners/${partnerId}/certifications/${certificationId}`,
        options: {
            method: 'delete',
        },
    },
})

export const getLeadCertifications = leadId => ({
    [CALL_API]: {
        types: [ GET_LEAD_CERTIFICATIONS, GET_LEAD_CERTIFICATIONS_SUCCESS, GET_LEAD_CERTIFICATIONS_FAIL ],
        endpoint: `/leads/${leadId}/certifications`,
        schema: schemas.certifications,
        options: {
            body: {
                limit: 1000,
                isSegmentable: true,
            },
        },
    },
})
