import React from 'react'
import RefundItem from './RefundItem'
import { Link } from 'react-router-dom'
import { Table } from '../../blocks/Table'
import styled from 'styled-components'
import LoadingAnimation from '../../utils/LoadingAnimation'
import InfiniteScroll from 'react-infinite-scroller'

const Tr = styled.tr`
   th:not(:last-child) {
      word-break: break-word;
    }
`

export const RefundList = ({
    loadMore,
    refunds,
    hasMore,
    t,
}) =>
    <InfiniteScroll
        pageStart={1}
        loadMore={loadMore}
        hasMore={hasMore}
        loader={<LoadingAnimation size={25} />}
        useWindow={true}
    >
        <Table modifiers={[ 'mobile' ]}>
            <thead>
                <Tr>
                    <th className="table-mobile-cell" />
                    <th>{t('refunds.invoice')}</th>
                    <th>{t('refunds.request_date')}</th>
                    <th>{t('refunds.request_amount')}</th>
                    <th>{t('refunds.status')}</th>
                    <th>{t('refunds.accept_amount')}</th>
                    <th>{t('refunds.reason')}</th>
                </Tr>
            </thead>
            <tbody>
                {refunds.map(refund =>
                    <RefundItem key={refund.id} refund={refund} />
            )}
            </tbody>
        </Table>

        <hr />
        <Link to={'/account/refunds-create'} className="btn btn-action btn-lg btn-block">
            {t('refunds.create')}
        </Link>
    </InfiniteScroll>
