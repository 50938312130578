import {
    InvoiceResult,
    InvoicesApiFetchParamCreator
} from 'ageras-api-client/src/api'
import { useAuth } from 'ageras-api-client/src/auth'
import useModal from 'components/src/modals/useModal'
import React, { useCallback, useEffect } from 'react'
import { ChevronsRight } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useAgerasApi } from 'ageras-api-client/src'
import Modal from 'components/src/modals/Modal'
import { ModalProps } from 'components/src/modals'
import Text from 'components/src/texts/Text'
import Flex from 'components/src/layout/Flex'
import Button from 'components/src/Button'
import storage from 'local-storage-fallback'

const DEBTOR_MODAL_SHOWN = 'debtor_modal_shown';

const wasDebtorModalShown = () => {
    return Boolean(storage.getItem(DEBTOR_MODAL_SHOWN));
}
const setDebtorModalShown = () => {
    storage.setItem(DEBTOR_MODAL_SHOWN, 'true');
}

const Template = (args: ModalProps) => {
    const { t } = useTranslation()

    const handleRedirect = useCallback(() => {
        window.location.href = '/account/invoices'
    }, [])

    return <Modal {...args} size="small">
        <Flex p={5} maxWidth="100%" alignContent="center" alignItems="center" justifyContent="center" flexDirection="column">
            <Text textAlign="center">{t('debtor_modal.body')}</Text>
            <Flex mt={3} as={Button} maxWidth="300px" width="100%" alignItems="center" variant="primary" onClick={handleRedirect}>
                {t('debtor_modal.button')}
                <Flex as={ChevronsRight} ml={2}/>
            </Flex>
        </Flex>
    </Modal>
}

function DebtorModal() {
    const auth = useAuth();
    const { show } = useModal();

    const { data: unpaidInvoices, isLoading } = useAgerasApi<InvoiceResult>(InvoicesApiFetchParamCreator().invoicesIndex(
        undefined,
        String(auth.partnerUser?.partnerId),
        undefined,
        false,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        1,
    ), {
        enabled: Boolean(auth.partnerUser?.partnerId) && !wasDebtorModalShown()
    })

    useEffect(() => {

        if (!isLoading && unpaidInvoices?.data?.length) {
            setDebtorModalShown();
            show(args => <Template {...args}/>)();
        }

    }, [ isLoading, unpaidInvoices, show ])

    return <></>
}

export default DebtorModal
