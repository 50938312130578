import { applyStyleModifiers } from 'styled-components-modifiers'
import styled from 'styled-components'
import {
    mobileBreakpoint,
    actionColor,
    actionDarkColor,
    actionLightColor,
    dangerColor,
    dangerDarkColor,
    dangerLightColor,
    primaryColor,
    primaryDarkColor,
    primaryLightColor,
    secondaryColor,
    secondaryDarkColor,
    secondaryLightColor,
    warningColor,
    warningDarkColor,
    warningLightColor,
} from '../../theme/variables'
import { MODIFIER_MARGIN_CONFIG, MODIFIER_PADDING_CONFIG } from './modifiers/spaceModifiers'
import { colors } from '../../theme/variables_new'

const MODIFIER_BUTTON_CONFIG = {
    secondary: ({ outline }) => `
        background-color: ${outline ? 'transparent' : secondaryColor};
        border-color: ${secondaryColor};
        color: ${outline && secondaryColor};

        &:hover {
                background-color: ${secondaryLightColor};
            }
        &:active, &:focus {
                background-color: ${secondaryDarkColor};
                box-shadow: none;
            }
        }
        &:disabled {
            background-color: ${secondaryLightColor};
        }
    `,
    primary: ({ outline }) => `
        background-color: ${outline ? 'transparent' : primaryColor};
        border-color: ${primaryColor};
        color: ${outline && primaryColor};

        &:hover {
            background-color: ${primaryLightColor};
        }
        &:active, &:focus {
            background-color: ${primaryDarkColor};
            box-shadow: none;
        }
        &:disabled {
            background-color: ${colors.grey};
            border-color: ${colors.grey};
        }
        .icon {
            color: ${primaryColor};
            background-color: #fff;
        }
    `,
    action: ({ outline }) => `
        background-color: ${outline ? 'transparent' : actionColor};
        border-color: ${actionColor};
        color: ${outline && actionColor};

        &:hover {
            background-color: ${actionLightColor};
        }
        &:active, &:focus {
            background-color: ${actionDarkColor};
            box-shadow: none;
        }
        &:disabled {
            background-color: ${actionLightColor};
        }
        .icon {
            color: ${actionColor};
            background-color: #fff;
        }
    `,
    danger: ({ outline }) => `
        background-color: ${outline ? 'transparent' : dangerColor};
        border-color: ${dangerColor};
        color: ${outline && dangerColor};

        &:hover {
            background-color: ${dangerLightColor};
        }
        &:active, &:focus {
            background-color: ${dangerDarkColor};
            box-shadow: none;
        }
        &:disabled {
            background-color: ${dangerLightColor};
        }
        .icon {
            color: ${dangerColor};
            background-color: #fff;
        }
    `,
    warning: ({ outline }) => `
        background-color: ${outline ? 'transparent' : warningColor};
        border-color: ${warningColor};
        color: ${outline && warningColor};

        &:hover {
            background-color: ${warningLightColor};
        }
        &:active, &:focus {
            background-color: ${warningDarkColor};
            box-shadow: none;
        }
        &:disabled {
            background-color: ${warningLightColor};
        }
        .icon {
            color: ${warningColor};
            background-color: #fff;
        }
    }
    `,
    nowrap: () => `
        white-space: nowrap;
    `,
    btnBlock: () => `
        text-align: center;
        display: block;
    `,
    fullWidth: () => `
        width: 100%;
    `,
    mobileHiddenUp: () => `
        @media (min-width: ${mobileBreakpoint}px) {
            display: none;
        }
    `,
    btnIcon: () => `
        height: 35px;
        line-height: 35px;
        display: inline-block;
        width: 35px;
        padding: 0;
    `,
    mdWidth: () => `
        width: 140px
    `,
    uppercase: () => `
        text-transform: uppercase;
    `,
    nextOfType: () => `
        margin-top: 24px;
    `,
}

export const Button = styled.button.attrs(props => ({ type: props.type || 'button' }))`
    transition: background 200ms ease;
    border-radius: 3px;
    padding: 6px 12px;
    border: 2px solid;;
    color: #fff;
    text-align: center;
    &:hover, &:active, &:focus {
        color: #fff;
    }
    &:disabled {
        cursor: not-allowed;
    }
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)}
    ${applyStyleModifiers(MODIFIER_BUTTON_CONFIG)}
`
