import { Field, reduxForm } from 'redux-form'
import * as React from 'react'
import LoadingAnimation from '../../components/utils/LoadingAnimation'
import { Flex } from '../../components/cards'
import { Button } from '../../components/elements/Button'

@reduxForm({
    form: 'noteQuoteForm',
})
export default class NoteSubmitForm extends React.Component {
    static defaultProps = {
        supportFiles: true,
    }
    constructor() {
        super()
        this.state = {
            noteText: '',
        }
    }

    submit(data) {
        this.props.onSubmit(data, this.props.reset)
        this.setState({ noteText: '' })
    }

    render() {
        const {
            handleSubmit,
            submitting,
        } = this.props

        if (submitting) {
            return <LoadingAnimation />
        }

        let modifiers = [ 'primary', 'p_2', 'nowrap' ]
        if (this.state.noteText.length) {
            modifiers = [ 'action', 'p_2', 'nowrap' ]
        }

        return (
            <form className="message-form" onSubmit={handleSubmit(this.submit.bind(this))}>
                <div className="textarea-wrapper">
                    <Flex>
                        <Field rows="2"
                               component="textarea"
                               placeholder={this.props.placeholder}
                               onChange={e => this.setState({ noteText: e.target.value })}
                               name="content"
                               style={{ marginRight: '8px' }}
                        />
                        <Button type="submit" modifiers={modifiers} disabled={!this.state.noteText.length}>{this.props.buttonText}</Button>
                    </Flex>
                </div>
            </form>
        )
    }
}
