import styled from 'styled-components'
import { MODIFIER_MARGIN_CONFIG, MODIFIER_PADDING_CONFIG } from '../../elements/modifiers/spaceModifiers'
import { applyStyleModifiers } from 'styled-components-modifiers'

export default styled.div`
  display: ${({ block }) => block ? 'block' : 'inline-block'};
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 2px 3px 1px rgba(231, 231, 231, 0.5);
  ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
  ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)}
`
