import React from 'react'
import { withTranslation } from 'react-i18next'
import PageTitle from '../utils/PageTitle'

@withTranslation('', { wait: true })
export default class InfoPage extends React.Component {

    render() {
        const {
            titleText,
            subtitleText,
            icon,
            className,
        } = this.props

        return <div>
            <PageTitle title={titleText} />
            <div className={className}>
                <div className="page-splash-wrapper">
                    <div className="page-splash">
                        {icon && <div className="icon-container"><img src={icon} alt="" /></div>}
                        {titleText && <h1 className="intro-heading"><strong>{titleText}</strong></h1>}
                        {subtitleText && <span className="sub-header">{subtitleText}</span>}
                    </div>
                </div>
                <div className="info-page-content">
                    {this.props.children}
                </div>
            </div>
        </div>
    }
}
