import React  from 'react'
import { X } from 'react-feather'
import Button from '../../Button'
import Card from '../../Card'

export interface PromptProps {
    hide?: () => void
}

function Prompt({
    hide,
    children,
}: React.PropsWithChildren<PromptProps>) {
    return (
        <>
            {Boolean(hide) && <Card.Content p={1}>
                <Button ml="auto" mr={0} variant="neutral" onClick={hide} size="small">
                    <X size={18}/>
                </Button>
            </Card.Content>}
            {children}
        </>
    )
}

export default Prompt
