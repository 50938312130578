import React, { Component } from 'react'
import ColorHash from 'color-hash'

export default class Portrait extends Component {

    render() {
        const {
            name = '. .',
            image,
            hash = '',
            size = 32,
            style,
        } = this.props

        const defaultStyle = {
            width: size + 'px',
            height: size + 'px',
            lineHeight: size + 'px',
            fontSize: size / 3 + 'px',
            borderRadius: '100%',
            color: '#fff',
            textAlign: 'center',
            letterSpacing: '1px',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            ...style,
        }

        const colorHash = new ColorHash()
        if (image) {
            return (
                <div style={{ ...defaultStyle, backgroundImage: 'url(' + image + ')' }} />
            )
        }

        var initials = name.match(/\b\w/g) || []
        initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()

        return (
            <div style={{ ...defaultStyle, backgroundColor: colorHash.hex(hash) }}>{initials}</div>
        )
    }
}
