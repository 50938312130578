import { LineCard } from '../../cards'
import { dangerColor, secondaryColor, successColor } from '../../../theme/variables'
import { Card } from '../../blocks/Card'
import { TextareaGroup } from '../../forms/inputs/TextareaGroup'
import React from 'react'
import { Alert } from 'react-bootstrap'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

const DangerAlert = styled(Alert)`
  display: inline-block;
`

@withTranslation('', { wait: true })
export class PartnerTermsLineCard extends React.PureComponent {
    render() {
        const {
            disabled,
            input,
            meta: { error },
            t,
        } = this.props
        return <LineCard borderColor={error ? dangerColor : input.value ? successColor : secondaryColor}>
            <Card.Header>
                <Card.MarkCircle size="40" modifiers={[ 'mR_2', error ? 'danger' : input.value ? 'action' : 'secondary' ]}>3</Card.MarkCircle>
                <strong>{t('quotes.creation.terms.label')}</strong>
            </Card.Header>
            <Card.Content>
                {error && <DangerAlert bsStyle="danger"><strong>{error.errorText}</strong></DangerAlert>}
                <Card.Intro>{t('quotes.creation.terms.intro')}</Card.Intro>
                <TextareaGroup
                    placeholder={t('quotes.creation.terms.placeholder')}
                    disabled={disabled}
                    height={200}
                    input={input}
                    meta={{}}
                />
            </Card.Content>
        </LineCard>
    }
}
