import React from 'react'
import { Card } from '../../../../../components/blocks/Card'
import { Col, Row } from 'react-bootstrap'
import { Button } from '../../../../../components/elements/ButtonNew'
import Select from 'components/src/inputs/Select'
import { FormControl } from 'components/src/inputs/FormControl'
import Grid from 'components/src/layout/Grid'
import styled from 'styled-components'
import { Plus, X } from 'react-feather'
import { IconItemBtnSmall } from '../../../../../components/pages/QuotePriceSettingPage/styles'

const AVAILABLE_MEETING_HOURS = [
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
]

const MeetingFormControl = styled(FormControl)`
    width: 120px;
`

const RemoveButton = styled(Button)`
    width: 52px;
    height: 52px;
    margin-top: 17px;
`

const AddButton = styled(IconItemBtnSmall)`
    width: 100%;
    margin-bottom: 15px;
`

const DateSelect = ({ date, handleSelectMeetingDate, index, t }) => {
    const today = new Date();
    const items = []
    for (let i = 1; i < 8; i++) {
        items.push(new Date(today.getFullYear(), today.getMonth(), today.getDate() + i).toISOString().split('T')[0])
    }

    return <MeetingFormControl label={t('partner.meeting_day')}>
        <Select
            items={items}
            selected={date}
            onChange={event => handleSelectMeetingDate(index, event)}
        />
    </MeetingFormControl>
}

const HourSelect = ({ hour, date, handleSelectMeetingHour, index, t }) => {
    const today = new Date()
    let items = AVAILABLE_MEETING_HOURS
    if (today.getDate() === new Date(date).getDate()) {
        items = AVAILABLE_MEETING_HOURS.filter(hour => hour > today.getHours() + ':00')
    }

    return <MeetingFormControl label={t('partner.meeting_hour')}>
        <Select
            items={items}
            selected={hour}
            onChange={event => handleSelectMeetingHour(index, event)}
        />
    </MeetingFormControl>
}

export class MeetingsCard extends React.Component {
    state = {
        meetings: [],
    }

    componentDidMount(prevProps, prevState) {
        this.addMeeting()
    }

    addMeeting = async () => {
        const meetings  = [ ...this.state.meetings ]
        meetings.push({
            date: false,
            hour: false
        })
        await this.setState({ meetings })
    }

    updateMeeting = async (index, meeting) => {
        const meetings = [ ...this.state.meetings ]
        meetings.splice(index, 1, meeting)
        await this.setState({ meetings })
    }

    removeMeeting = async index => {
        const meetings = [ ...this.state.meetings ]
        meetings.splice(index, 1)
        await this.setState({ meetings })
        if (!this.state.meetings.length) {
            this.addMeeting()
        }
    }

    handleSelectMeetingDate = async (index, date) => {
        const meeting = this.state.meetings[index]
        meeting.date = date
        await this.updateMeeting(index, meeting)
    }

    handleSelectMeetingHour = async (index, hour) => {
        const meeting = this.state.meetings[index]
        meeting.hour = hour
        await this.updateMeeting(index, meeting)
    }

    haveMeetingDataFilled = () => {
        for (const meeting of [ ...this.state.meetings ]) {
            if (meeting.date && meeting.hour) {
                return true
            }
        }

        return false
    }

    sandMeetingsRequest = handleRequestMeetingClick => {
        handleRequestMeetingClick(this.state.meetings);
        this.setState({ meetings: [] })
    }

    render()
    {
        const {
            t,
            handleRequestMeetingClick,
            isRequestMeetingSent,
            creditSystemIsLoading,
            meetingsIsLoading,
        } = this.props

        const haveMeetingDataFilled = this.haveMeetingDataFilled()
        const disabledModifiers = !haveMeetingDataFilled || creditSystemIsLoading || meetingsIsLoading || isRequestMeetingSent
        const isDisabled = !haveMeetingDataFilled || creditSystemIsLoading || meetingsIsLoading

        return <Card id="meeting-card">
            <Card.Content modifiers={[ 'pY_5', 'pX_6' ]}>
                <Card.Heading modifiers={[ 'primaryColor', 'mB_1' ]}>{t('partner.meetings')}</Card.Heading>
                {!isRequestMeetingSent && <Row>
                    <Col md={10}>
                        <div style={{ padding: '15px 0 30px' }}>{t('partner.meetings_description')}</div>
                    </Col>
                </Row>}
                {!isRequestMeetingSent && <Row>
                    {this.state.meetings.map((meeting, index) => <Grid gridTemplateColumns="5fr 5fr 1fr" gridColumnGap="30px" style={{ padding: '0 15px' }}
                        key={`request_meeting_${index}`}
                        modifiers={[ 'mR_1' ]}
                    >
                        <DateSelect
                            index={index}
                            date={meeting.date}
                            handleSelectMeetingDate={this.handleSelectMeetingDate}
                            t={t}
                        />
                        <HourSelect
                            index={index}
                            hour={meeting.hour}
                            date={meeting.date}
                            handleSelectMeetingHour={this.handleSelectMeetingHour}
                            t={t}
                        />
                        <RemoveButton className="btn btn-sm btn-danger" onClick={() => this.removeMeeting(index)}>
                            <X size={20} />
                        </RemoveButton>
                    </Grid>)}
                </Row>}
                {!isRequestMeetingSent && <Row>
                    <Col style={{ padding: '0 15px' }}>
                        <AddButton onClick={this.addMeeting}>
                            <Plus size={18} />
                        </AddButton>
                    </Col>
                </Row>}
                <Row>
                    <Col md={6}>
                        <Button
                            className="hidden-xs"
                            modifiers={[ disabledModifiers ? 'disabled' : 'action', 'mB_3', 'p_2', 'btnBlock', 'maxWidth' ]}
                            disabled={isDisabled}
                            onClick={() => this.sandMeetingsRequest(handleRequestMeetingClick)}
                            >
                                {!isRequestMeetingSent ? t('partner.send_meeting_request') : t('partner.meeting_request_sent')}
                        </Button>
                    </Col>
                </Row>
            </Card.Content>
        </Card>
    }
}
