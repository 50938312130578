import styled from 'styled-components'
import { Panel, Badge } from 'react-bootstrap'
import React from 'react'
import config from '../../config'
import FormattedAmount from '../utils/FormattedAmount'
import FormattedDate from '../utils/FormattedDate'
import Box from '../blocks/Box'
import { Flex } from './Flex'
import { Impact, Span, Text } from './text/Text'
import Tabs from './Tabs'
import moment from 'moment'
import { Col } from '../layout/layout'
import BusinessUnitDetail from './BusinessUnitDetail'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'
import { acceptPartnerContract, getPartnerContracts, downloadPartnerContract, downloadSignedPartnerContract, generatePartnerContractSigningUrl } from '../../actions/partnercontracts'
import { getRenderedTerm, getTerms } from '../../actions/terms'
import { currentPartnerSelector } from '../../selectors/auth'
import { getPartner } from '../../actions/partners'
import { ContractAcceptForm } from '../pages/PartnerContractsPage'
import { Button, ButtonGroup } from './ButtonNew'
import { showModal } from '../../actions/modal'
import { colors } from '../../theme/variables_new'
import { Trans } from 'react-i18next'
import { downloadFile } from '../../utils/download';
import { hasContractSigningAccessSelector } from '../../selectors/access'

const PanelToggle = styled(Panel.Title)`
    a {
        display: block !important;
    }
`

const ActionBar = styled(Box)`
    padding: 18px 32px;
    margin-bottom: 30px;
`

const ContractStatusBadge = styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.lightGrey};
    color: ${colors.darkBlue};
    border-radius: 4px;
    min-width: 10px;
    padding: 3px 7px 1px;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    vertical-align: middle;
    white-space: nowrap;
    text-align: center;
    margin-right: 8px;
`

const DATE_OF_CONTRACT_DEPRECATION = '2024-03-20'

export const OverviewItem = ({
    title,
    value,
    additional,
    size,
    t,
}) => {
    return <Flex.Item responsiveModifiers={{ small: [ 'mB_2' ] }} size={size}>
        <Text responsiveModifiers={{ small: [ 'center' ] }} size={size} modifiers={[ 'smallHigh', 'grey' ]}>{title}</Text>
        <Impact responsiveModifiers={{ small: [ 'center' ] }} size={size} modifiers={[ 'large' ]}>{value}</Impact>
        {additional && <Text responsiveModifiers={{ small: [ 'center' ] }} size={size} modifiers={[ 'small', 'bold' ]}>{additional}</Text>}
    </Flex.Item>
}

export const ContractOverview = ({
    width,
    contract,
    t,
    calculateTotalLeads,
    calculateQuotaPrice,
    returnDeliveryPeriodString,
    returnBillingFrequencyString,
    replacesRunningCanceledContract,
}) => <Box.Content modifiers={[ 'overview' ]}>
    {replacesRunningCanceledContract &&
    <div className="alert alert-warning text-center">
        {t('partner_contracts.overview.churn_inheritance_text')}
    </div>}
    <Flex responsiveModifiers={{
        small: [ 'directionColumn' ],
    }}
          size={width}>
        <OverviewItem
            size={width}
            title={t('partner_contracts.overview.total_leads')}
            value={calculateTotalLeads(contract).total}
            additional={`${Object.keys(calculateTotalLeads(contract)).filter(key => Boolean(calculateTotalLeads(contract)[key]) && key !== 'total').map(key => `${key.toUpperCase()}: ${calculateTotalLeads(contract)[key]}`).join(', ')}`}
            t={t}
        />
        <OverviewItem
            size={width}
            title={t('partner_contracts.overview.price')}
            value={calculateQuotaPrice(contract)}
            additional={calculateTotalLeads(contract).pps ? '+ PPS price' : ''}
            t={t}/>
        <OverviewItem size={width} title={t('partner_contracts.overview.delivery_period')} value={returnDeliveryPeriodString(contract.bindingPeriodMonths)} t={t}/>
        <OverviewItem size={width} title={t('partner_contracts.overview.payment_terms')} value={returnBillingFrequencyString(contract.billingFrequencyMonths, contract.bindingPeriodMonths)} t={t}/>
    </Flex>
</Box.Content>

export const BusinessUnitsHeader = ({
    contract,
    activeBusinessUnitTab,
    setActiveBusinessUnitTab,
}) => <Box.Header modifiers={[ 'tabs' ]}>
    <Tabs
        selectedTab={activeBusinessUnitTab}
        changeTab={tabId => setActiveBusinessUnitTab(tabId)}
        tabs={contract.businessUnits.map((businessUnit, index) => {
            const isLB = Boolean(businessUnit.allocationQuota)
            const isPPS = Boolean(businessUnit.allocationLimit)
            let tabDetail = `(${(isLB && `LB: ${businessUnit.allocationQuota.count}`) ||
                (isPPS && `PPS: ${businessUnit.allocationLimit.count}`) || '-'})`

            if (contract.currentPartnerContractPeriod) {
                tabDetail = `(${(isLB && `LB: ${businessUnit.allocationQuota.allocated}/${businessUnit.allocationQuota.count}`) ||
                    (isPPS && `PPS: ${businessUnit.allocationLimit.allocated}/${businessUnit.allocationLimit.count}`) || '-'})`
            }

            return {
                tabName: businessUnit.displayName,
                tabId: index,
                tabDetail: tabDetail,
                tabExtra: isPPS ? `${businessUnit.allocationLimit.priceExclVat.amount} ${businessUnit.allocationLimit.priceExclVat.currency}/spot` : '',
            }
        })}
    />
</Box.Header>

const returnFormattedTimeString = (time, format, months = null) => months ? moment(time).add(months, 'M').format(format) : moment(time).format(format)

@connect(
    state => ({
        partner: currentPartnerSelector(state),
        signingEnabled: hasContractSigningAccessSelector(state),
    }),
    {
        getPartner,
        getPartnerContracts,
        acceptPartnerContract,
        downloadPartnerContract,
        downloadSignedPartnerContract,
        generatePartnerContractSigningUrl,
        getTerms,
        getRenderedTerm,
        showModal,
    }
)
export default class PartnerContractPanel extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            termContent: '',
            showAcceptButton: false,
            activeBusinessUnitTab: 0,
            accepted: false,
            toggled: false,
        }
    }

    componentDidMount() {
        if (this.props.awaitsAcceptance) this.setState({
            showAcceptButton: true,
        })
    }

    downloadPartnerContract = async () => {
        try {
            const result = await this.props.downloadPartnerContract(this.props.partner.id, this.props.contract.id)
            downloadFile(result.url)
        } catch (e) {
            toastr.error(this.props.t('partner_contracts.requests.error'), this.props.t('partner_contracts.could_not_download_pdf'))
        }
    }

    downloadSignedPartnerContract = async () => {
        try {
            const result = await this.props.downloadSignedPartnerContract(this.props.partner.id, this.props.contract.id)
            downloadFile(result.url)
        } catch (e) {
            toastr.error(this.props.t('partner_contracts.requests.error'), this.props.t('partner_contracts.could_not_download_signed_pdf'))
        }
    }

    submitContractForm = async () => {
        if (this.props.signingEnabled) {
            try {
                const result = await this.props.generatePartnerContractSigningUrl(this.props.partner.id, this.props.contract.id)
                window.location.href = result.url
            } catch (e) {
                toastr.error(this.props.t('partner_contracts.requests.error'), this.props.t('partner_contracts.could_not_generate_signing_url'))
            }
            return
        }

        try {
            await this.props.acceptPartnerContract(this.props.partner.id, this.props.contract.id)
            await this.setState({
                showAcceptButton: false,
                accepted: true,
            })
            await this.props.getPartnerContracts(this.props.partner.id, { isArchived: '0' })
        } catch (e) {
            toastr.error(this.props.t('partner_contracts.requests.error'), e.error)
        }
    }

    returnDeliveryPeriodString = period => {
        const num = parseInt(period)
        if (num === 12) {
            return '1 ' + this.props.t('partner_contracts.periods.year')
        }
        if (num > 1) {
            return period + ' ' + this.props.t('partner_contracts.periods.multiple_months')
        }
        return period + ' ' + this.props.t('partner_contracts.periods.single_month')
    }

    returnBillingFrequencyString = (frequency, period) => {
        const rates = Number(period) / frequency
        const unit = this.props.t(`partner_contracts.rates.${rates === 1 ? 'singular' : 'plural'}`)
        return `${rates} ${unit}`
    }

    setActiveBusinessUnitTab = tabId => {
        this.setState({ activeBusinessUnitTab: tabId })
    }

    selectBusinessUnit = () => {
        const { activeBusinessUnitTab } = this.state
        return this.props.contract.businessUnits[activeBusinessUnitTab]
    }

    calculateQuotaPrice = contract => {
        const contractLength = contract.bindingPeriodMonths
        const billingFrequency = contract.billingFrequencyMonths

        const quotaPrices = contract.businessUnits ? contract.businessUnits.map(el => {
            return el.allocationQuota && el.allocationQuota.priceExclVat.amount || 0
        }) : [ 0 ]
        const price = quotaPrices.reduce((accumulator, currentValue) => accumulator + currentValue, 0)

        const total = price * contractLength / billingFrequency

        const currency = config.currency[this.props.partner.geo.code]

        return <FormattedAmount
            amount={total}
            currency={currency}
            roundTo="0"
        />
    }

    calculateTotalLeads = contract => {
        const defaultTotal = {
            lb: 0,
            pps: 0,
        }

        if (!contract.businessUnits || !contract.businessUnits.length) {
            return defaultTotal
        }

        const leads = contract.businessUnits.map(el => {
            return {
                lb: el.allocationQuota && el.allocationQuota.count || 0,
                pps: el.allocationLimit && el.allocationLimit.count || 0,
            }
        })

        return leads.reduce((acc, cur) => {
            acc.lb += cur.lb
            acc.pps += cur.pps
            acc.total += cur.lb + cur.pps
            return acc
        }, { ...defaultTotal, total: 0 })
    }

    togglePanel = () => {
        this.setState(prevState => ({
            toggled: !prevState.toggled,
        }))
    }

    returnHeaderModifier = contract => {
        if (!contract.acceptedAt) {
            return 'accent'
        }
        if (contract.currentPartnerContractPeriod || (contract.acceptedAt && !contract.churnsAt)) {
            return 'success'
        }
        if (contract.acceptedAt && !contract.currentPartnerContractPeriod && contract.churnsAt) {
            return 'faded'
        }
    }

    onPauseContract = () => {
        const partnerId = this.props.partner.id
        const contractId = this.props.contract.id

        this.props.showModal({
            partnerId,
            contractId,
            name: 'pauseContract',
        })
    }

    getRenewalDate = contract => {
        if (contract.churnsAt) {
            return returnFormattedTimeString(contract.churnsAt, 'MMMM DD YYYY')
        }
        if (contract.currentPartnerContractPeriod) {
            return returnFormattedTimeString(contract.currentPartnerContractPeriod.endsAt, 'MMMM DD YYYY')
        }
        return returnFormattedTimeString(contract.startsAt, 'MMMM DD YYYY', contract.bindingPeriodMonths)
    }


    hideContractAction = date => {
        return moment(date).isAfter(DATE_OF_CONTRACT_DEPRECATION)
    }

    render() {
        const { contract, width, t, clientTypes, industries, sectors, geoRegions, pixelWidth, isPanel, replacedContract } = this.props

        const replacedContractIsRunning = replacedContract && replacedContract.currentPartnerContractPeriod

        const replacesRunningCanceledContract = replacedContractIsRunning &&
            replacedContract.canceledAt
            // and the replaced contract was canceled before this contract was created

        const activeContractPause = contract.allocationPauses && contract.allocationPauses.find(pause => moment(pause.startsAt).isBefore(moment()) && moment(pause.endsAt).isAfter(moment()))

        if (isPanel) {
            return <Panel onToggle={this.togglePanel}>
                <Panel.Heading>
                    <Box
                        responsiveModifiers={{ active: [ 'bottomLeft', 'bottomRight' ] }}
                        size={this.state.toggled ? 'active' : ''}
                    >
                        <PanelToggle toggle>
                            <Box.Header modifiers={[ this.returnHeaderModifier(contract) ]}>
                                <Flex responsiveModifiers={{
                                    small: [ 'directionColumn', 'alignSelfEnd' ],
                                    medium: [ 'justifySpaceBetween', 'alignCenter' ],
                                    desktopSmall: [ 'justifySpaceBetween', 'alignCenter' ],
                                    desktopMedium: [ 'justifySpaceBetween', 'alignCenter' ],
                                    desktopLarge: [ 'justifySpaceBetween', 'alignCenter' ],
                                }}
                                      size={width}>
                                    <Flex.Item>
                                        <Flex>
                                            {activeContractPause && <ContractStatusBadge>{t('partner_contracts.pauses.paused_label_text')}</ContractStatusBadge>}
                                            {contract.canceledAt && <ContractStatusBadge>{t('partner_contracts.overview.canceled_label')}</ContractStatusBadge>}
                                            <Impact modifiers={[ 'white', 'tiny' ]}>{t('partner_contracts.overview.contract')}: #{contract.id}</Impact>
                                        </Flex>
                                    </Flex.Item>
                                    <Flex.Item>
                                        <Text modifiers={[ 'white', 'smallHigh', 'center' ]}>{t('partner_contracts.overview.start')} <Span modifiers={[ 'bold' ]}>
                                            {returnFormattedTimeString(replacedContractIsRunning ? replacedContract.startsAt : contract.startsAt, 'MMMM DD YYYY')}
                                        </Span></Text>
                                    </Flex.Item>
                                    <Flex.Item>
                                        <Text modifiers={[ 'white', 'smallHigh', 'right' ]}>{contract.churnsAt || replacesRunningCanceledContract ? t('partner_contracts.overview.end') : t('partner_contracts.overview.renews')} <Span modifiers={[ 'bold' ]}>
                                            {this.getRenewalDate(replacedContractIsRunning && !contract.currentPartnerContractPeriod ? replacedContract : contract)}
                                        </Span></Text>
                                    </Flex.Item>
                                </Flex>
                            </Box.Header>
                        </PanelToggle>
                    </Box>
                </Panel.Heading>
                <Panel.Collapse>
                    <Panel.Body>
                        <Box modifiers={[ 'topLeftSharp', 'topRightSharp', 'mB_3' ]}>
                            <ContractOverview
                                width={width}
                                contract={contract}
                                replacesRunningCanceledContract={replacesRunningCanceledContract && !contract.currentPartnerContractPeriod}
                                calculateTotalLeads={this.calculateTotalLeads}
                                calculateQuotaPrice={this.calculateQuotaPrice}
                                returnBillingFrequencyString={this.returnBillingFrequencyString}
                                returnDeliveryPeriodString={this.returnDeliveryPeriodString}
                                t={t}
                            />
                        </Box>
                        {contract.businessUnits && contract.businessUnits.length && <Box modifiers={[ 'mB_3' ]}>
                            <BusinessUnitsHeader
                                contract={contract}
                                activeBusinessUnitTab={this.state.activeBusinessUnitTab}
                                setActiveBusinessUnitTab={this.setActiveBusinessUnitTab}
                            />
                            <Box.Line/>
                            <BusinessUnitDetail
                                width={pixelWidth}
                                businessUnit={this.selectBusinessUnit()}
                                industries={industries}
                                sectors={sectors}
                                geoRegions={geoRegions}
                                leadTypes={clientTypes}
                                t={t}/>
                            <div className="text-center" style={{padding: '0 10px 20px 10px'}}>
                                {!this.hideContractAction(contract.createdAt) && <Button onClick={contract.signedAt ? this.downloadSignedPartnerContract : this.downloadPartnerContract} className="btn btn-secondary-two">
                                    {t('partner_contracts.download_contract')}
                                </Button>}
                            </div>
                        </Box>}
                        {(!contract.businessUnits || !contract.businessUnits.length) && <Box modifiers={[ 'mB_3', 'p_3' ]}>
                            <Text modifiers={[ 'medium', 'center' ]}>{t('partner_contracts.no_business_units')}</Text>
                        </Box>}
                        {!this.hideContractAction(contract.createdAt) && !contract.acceptedAt && <Box modifiers={[ 'mB_3', 'pX_2', 'pY_3' ]}>
                            {this.state.showAcceptButton &&
                            <ContractAcceptForm
                                width={width}
                                onSubmit={this.submitContractForm}
                                signingEnabled={this.props.signingEnabled} />}
                        </Box>}
                        {contract.acceptedAt && <ActionBar>
                            <Flex modifiers={[ 'alignCenter' ]}>
                                <Flex.Item>
                                    <Text modifiers={[ 'medium' ]}>
                                        <Trans
                                            i18nKey="partner_contracts.contract_accepted"
                                        >
                                            <FormattedDate date={contract.acceptedAt} format="datetime"/>
                                        </Trans>
                                    </Text>
                                </Flex.Item>
                                <Flex.Item>
                                    <Flex modifiers={[ 'justifyEnd' ]}>
                                        <ButtonGroup>
                                            <Button className="pause-button" onClick={this.onPauseContract}
                                                    modifiers={[ 'warning', 'small', 'bold', 'bottomLeft', 'btnBlock' ]}>{t('partner_contracts.pauses.pause_button_text')}</Button>
                                        </ButtonGroup>
                                    </Flex>
                                </Flex.Item>
                            </Flex>
                        </ActionBar>}
                    </Panel.Body>
                </Panel.Collapse>
            </Panel>
        }

        return <Col>
            <Box modifiers={[ 'mB_3' ]}>
                <Box.Header modifiers={[ 'accent' ]}>
                    <Flex responsiveModifiers={{
                        small: [ 'directionColumn', 'alignSelfEnd' ],
                        medium: [ 'justifySpaceBetween', 'alignCenter' ],
                        desktopSmall: [ 'justifySpaceBetween', 'alignCenter' ],
                        desktopMedium: [ 'justifySpaceBetween', 'alignCenter' ],
                        desktopLarge: [ 'justifySpaceBetween', 'alignCenter' ],
                    }}
                          size={width}>
                        <Impact modifiers={[ 'white' ]}>{t('partner_contracts.overview.overview')}</Impact>
                        <Text modifiers={[ 'white', 'smallHigh' ]}>{t('partner_contracts.overview.start')} <Span modifiers={[ 'bold' ]}>{returnFormattedTimeString(contract.startsAt, 'MMMM DD YYYY')}</Span></Text>
                    </Flex>
                </Box.Header>
                <ContractOverview
                    width={width}
                    contract={contract}
                    calculateTotalLeads={this.calculateTotalLeads}
                    calculateQuotaPrice={this.calculateQuotaPrice}
                    returnBillingFrequencyString={this.returnBillingFrequencyString}
                    returnDeliveryPeriodString={this.returnDeliveryPeriodString}
                    t={t}
                />
            </Box>
            <Box>
                <BusinessUnitsHeader
                    contract={contract}
                    activeBusinessUnitTab={this.state.activeBusinessUnitTab}
                    setActiveBusinessUnitTab={this.setActiveBusinessUnitTab}
                />
                <Box.Line />
                <BusinessUnitDetail
                    width={pixelWidth}
                    businessUnit={this.selectBusinessUnit()}
                    industries={industries}
                    sectors={sectors}
                    geoRegions={geoRegions}
                    leadTypes={clientTypes}
                    t={t}/>
            </Box>
        </Col>
    }
}
