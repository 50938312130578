import React from 'react'
import { connect } from 'react-redux'
import { AgerasGreetingModal } from './AgerasGreetingModal'
import { PauseContractModal } from './PauseContractModal'
import UnlockConfirmModal from '../pages/MarketplacePage/UnlockConfirmModal'
import ForceCopyRevenueSegmentModal from '../pages/QuotePriceSettingPage/ForceCopyRevenueSegmentModal'
import CancelMarketplaceSubscriptionModal from './CancelMarketplaceSubscriptionModal'
import MarketplacePurchaseLimitReachedModal from './MarketplacePurchaseLimitReachedModal'
import ConfirmModal from './ConfirmModal'
import QuoteRefusalModal from './QuoteRefusalModal'

@connect(
    state => ({
        modalProps: state.modal,
    }),
)
export default class BootstrapModal extends React.PureComponent {
    render() {
        const {
            modalProps,
        } = this.props

        switch (modalProps.name) {
            case 'agerasGreetingModal':
                return <AgerasGreetingModal/>
            case 'pauseContract':
                return <PauseContractModal modalProps={modalProps} />
            case 'unlockMarketplaceLead':
                return <UnlockConfirmModal modalProps={modalProps} />
            case 'cancelMarketplaceSubscription':
                return <CancelMarketplaceSubscriptionModal modalProps={modalProps} />
            case 'showMarketplacePurchaseLimitReached':
                return <MarketplacePurchaseLimitReachedModal modalProps={modalProps} />
            case 'forceCopyRevenueSegment':
                return <ForceCopyRevenueSegmentModal modalProps={modalProps} />
            case 'showQuoteRefusalModal':
                return <QuoteRefusalModal modalProps={modalProps}/>
            case 'showConfirmModal':
                return <ConfirmModal modalProps={modalProps}/>
            default:
                return null
        }
    }
}
