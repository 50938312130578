import React from 'react'
import FormattedAmount from '../../../../components/utils/FormattedAmount'
import { Link } from 'react-router-dom'
import { QuotePricingOverlay } from '../../../../components/quotes/QuotePricingOverlay'
import { StarRatingInput } from '../../../../components/utils/StarRatingInput'
import { uniqBy } from 'lodash/array'
import flatten from 'lodash.flatten'
import { useTranslation } from 'react-i18next'


export default function LeadCompareSection({ lead, sortedQuotes }) {

    const { t } = useTranslation()

    const quotesItems = uniqBy(
        flatten(
            sortedQuotes
                .filter(el => el.quote)
                .map(el => el.quote.items)
        ),
        'productCustomId'
    )

    return <div className="comparison-list">
        <div className="heading-section">
            <h1 className="heading_large">
                {t('quotes.compare.copy_heading')}
            </h1>
            <h3 className="heading_sub">
                {t('quotes.compare.copy_subheading')}
            </h3>
        </div>

        <div className="comparison-list_row" type="company">
            <div className="container">
                <div className="comparison-list_row_inner">
                    <div className="comparison-list_column"/>
                    {sortedQuotes.map(qo => qo.partner && qo.quote &&
                        <div className="comparison-list_column" key={qo.id}>
                            <div className="heading">
                                <div className="heading_name">
                                    {qo.partner.companyName}
                                </div>
                                <div className="heading_price">
                                    {lead.customerInfo.showPricesIncludingVat ?
                                        <FormattedAmount
                                            amount={qo.quote.totalAmountInclVat.amount}
                                            currency={qo.quote.totalAmountInclVat.currency}
                                            decimals={2}
                                        /> :
                                        <FormattedAmount
                                            amount={qo.quote.totalAmountExclVat.amount}
                                            currency={qo.quote.totalAmountExclVat.currency}
                                            decimals={2}
                                        />
                                    }
                                </div>
                                {qo.partner.id &&
                                    <Link to={`/cases/${lead.id}/${qo.partner.id}#price-table`}>
                                        <button
                                            className="btn btn-action heading_button">{t('quotes.quote.see_quotes')}</button>
                                    </Link>}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>

        <div className="container">
            <div className="comparison-list_heading">
                {t('quotes.compare.company_info_title')}
            </div>
        </div>
        <div className="comparison-list_row">
            <div className="container">
                <div className="comparison-list_row_inner">
                    <div className="comparison-list_column">
                        {t('quotes.compare.city')}
                    </div>
                    {sortedQuotes.map((quote, i) =>
                        <div key={'partner_geo_cityName' + i} className="comparison-list_column">
                            {quote.partner && quote.partner.geo && quote.partner.geo.cityName}
                        </div>
                    )}
                </div>
            </div>
        </div>
        <div className="comparison-list_row">
            <div className="container">
                <div className="comparison-list_row_inner">
                    <div className="comparison-list_column">
                        {t('quotes.compare.rating')}
                    </div>
                    {sortedQuotes.map((quote, i) =>
                        <div className="comparison-list_column" key={i}>
                            {quote.partner && quote.partner.rating &&
                                <div>
                                    {(quote.partner.rating.score &&
                                            <div>
                                                <span className="rating-number">{quote.partner.rating.score}</span>
                                                <span className="rating-stars"><StarRatingInput
                                                    filled={quote.partner.rating.stars} total={5}/></span>
                                            </div>)
                                        || <span>{t('quotes.compare.no_reviews')}</span>
                                    }
                                </div>
                            }
                        </div>
                    )}
                </div>
            </div>
        </div>

        <div className="container">
            <div className="comparison-list_heading">
                {t('quotes.compare.pricing_title')}
            </div>
        </div>

        {quotesItems.length > 0 && quotesItems.map(itemFormList =>
            <div key={itemFormList.productCustomId + 'comparison-list_row'}
                 className="comparison-list_row">
                <div className="container">
                    <div className="comparison-list_row_inner">
                        <div className="comparison-list_column">
                            <span>{itemFormList.title} — <span className="quote-type">{t(`quotes.quote.item_type.${itemFormList.type}`)}</span></span>
                        </div>

                        {sortedQuotes
                            .map((qo, i) => {
                                const price = qo.quote && qo.quote.items.find(item => item.productCustomId === itemFormList.productCustomId)
                                return <div key={'comparison-list_column' + i} className="comparison-list_column">
                                    {price ? lead.customerInfo.showPricesIncludingVat ?
                                        <FormattedAmount
                                            amount={price.amountInclVat.amount}
                                            currency={price.amountInclVat.currency}
                                            decimals={2}
                                        /> :
                                        <FormattedAmount
                                            amount={price.amountExclVat.amount}
                                            currency={price.amountExclVat.currency}
                                            decimals={2}
                                        /> : '-'
                                    }
                                </div>
                            })
                        }

                    </div>
                </div>
            </div>
        )}
        <div className="comparison-list_row">
            <div className="container">
                <div className="comparison-list_row_inner">
                    <div className="comparison-list_column">
                        {lead.customerInfo.showPricesIncludingVat ? t('quotes.compare.total_price_inclvat') : t('quotes.compare.total_price_exclvat')}
                    </div>
                    {sortedQuotes.map(qo => qo.quote &&
                        <div className="comparison-list_column comparison-list_column_total" key={qo.id}>
                            <QuotePricingOverlay
                                t={t}
                                quote={qo.quote}
                                includeVat={lead.customerInfo.showPricesIncludingVat}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
        <div className="comparison-list_row">
            <div className="container">
                <div className="comparison-list_row_inner">
                    <div className="comparison-list_column"/>
                    {sortedQuotes.map(qo => qo.partner &&
                        <div className="comparison-list_column" key={qo.id}>
                            {qo.partner.id &&
                                <Link to={`/cases/${lead.id}/${qo.partner.id}#price-table`}>
                                    <button className="btn btn-action heading_button">{t('quotes.quote.see_quotes')}</button>
                                </Link>}
                        </div>
                    )}
                </div>
            </div>
        </div>
    </div>
}
