import { activeColor, cardBorderColor, darkBlue, mobileBreakpoint, sidebarBorderColor } from '../../../theme/variables'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export default styled(NavLink)`
    display: list-item;
    color: ${darkBlue};
    list-style-type: none;
    border-bottom: 1px solid ${sidebarBorderColor};
    padding: 20px 30px;
    text-decoration: none;
    &:last-of-type {
      border-bottom: none;
    }
    @media (min-width: ${mobileBreakpoint}px) {
        &.active {
            border-left: 5px solid ${activeColor};
            padding-left: 25px;
        }
    }
    &:hover:not(.active) {
        border-left: 5px solid ${cardBorderColor};
        padding-left: 25px;
    }
`
