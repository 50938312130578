import {
    GET_UNREAD_PARTICIPANT_MESSAGES,
    GET_UNREAD_PARTICIPANT_MESSAGES_FAIL,
    GET_UNREAD_PARTICIPANT_MESSAGES_SUCCESS,
} from '../../actions/conversations'

const initialState = {
    unreadMessagesIds: [],
    isLoading: false,
    pagination: {},
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_UNREAD_PARTICIPANT_MESSAGES:
            return {
                ...state,
                isLoading: true,
            }
        case GET_UNREAD_PARTICIPANT_MESSAGES_SUCCESS:
            return {
                ...state,
                unreadMessagesIds: [ ...action.response.result ],
                isLoading: false,
            }
        case GET_UNREAD_PARTICIPANT_MESSAGES_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state
    }
}
