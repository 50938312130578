import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const CREATE_DELIVERABLE = 'deliverables/CREATE_DELIVERABLE'
export const CREATE_DELIVERABLE_SUCCESS = 'deliverables/CREATE_DELIVERABLE_SUCCESS'
export const CREATE_DELIVERABLE_FAIL = 'deliverables/CREATE_DELIVERABLE_FAIL'

export const GET_DELIVERABLES = 'deliverables/GET_DELIVERABLES'
export const GET_DELIVERABLES_SUCCESS = 'deliverables/GET_DELIVERABLES_SUCCESS'
export const GET_DELIVERABLES_FAIL = 'deliverables/GET_DELIVERABLES_FAIL'

export const GET_DELIVERABLE = 'deliverables/GET_DELIVERABLE'
export const GET_DELIVERABLE_SUCCESS = 'deliverables/GET_DELIVERABLE_SUCCESS'
export const GET_DELIVERABLE_FAIL = 'deliverables/GET_DELIVERABLE_FAIL'

export const createDeliverable = (partnerId, leadId, data) => ({
    [CALL_API]: {
        types: [ CREATE_DELIVERABLE, CREATE_DELIVERABLE_SUCCESS, CREATE_DELIVERABLE_FAIL ],
        endpoint: '/partners/deliverables',
        schema: schemas.deliverable,
        options: {
            method: 'post',
            body: {
                partnerId,
                lead: {
                    id: leadId,
                },
                ...data,
            },
        },
    },
})

export const getDeliverables = criteria => ({
    [CALL_API]: {
        types: [ GET_DELIVERABLES, GET_DELIVERABLES_SUCCESS, GET_DELIVERABLES_FAIL ],
        endpoint: '/partners/deliverables',
        schema: schemas.deliverables,
        options: {
            body: {
                limit: 1000,
                ...criteria,
            },
        },
    },
})

export const getDeliverable = (partnerId, deliverableId) => ({
    [CALL_API]: {
        types: [ GET_DELIVERABLE, GET_DELIVERABLE_SUCCESS, GET_DELIVERABLE_FAIL ],
        endpoint: `/partners/${partnerId}/deliverables/${deliverableId}`,
        schema: schemas.deliverable,
    },
})
