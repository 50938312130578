import React from 'react'
import { withTranslation } from 'react-i18next'
import { change, FieldArray, initialize, submit, Field } from 'redux-form'
import LoadingAnimation from '../utils/LoadingAnimation'
import { getLocations } from '../../actions/locations'
import { connect } from 'react-redux'
import { currentPartnerSelector, currentPartnerUserSelector } from '../../selectors/auth'
import { CompanyDescriptionContent } from './profile-contents/CompanyDescriptionContent'
import { CompanyInformationContent } from './profile-contents/CompanyInformationContent'
import { CompanyServicesContent } from './profile-contents/CompanyServicesContent'
import { Card } from '../blocks/Card'
import { Button } from '../elements/Button'
import { CompanyLanguageContent } from './profile-contents/CompanyLanguageContent'
import { getPartnerProfileDataFields } from '../../actions/datafields'
import cloneDeep from 'lodash.clonedeep'
import { getCertifications, getPartnerCertifications } from '../../actions/certifications'
import { CompanySystemCertificationContent } from './profile-contents/CompanySystemCertificationContent'
import { createLanguage, deleteLanguage, getCv } from '../../actions/cv'
import ReduxForm from '../forms/ReduxForm'
import { partnerDataFieldsSelctor } from '../../selectors/partners'
import { savePartner } from '../../actions/partners'
import { CompanyInterviewQuestionsContent } from './profile-contents/CompanyInterviewQuestionsContent'
import { CompanyInterviewQuoteContent } from './profile-contents/CompanyInterviewQuoteContent'
import CompanyDigitalLead from './profile-contents/CompanyDigitalLead'

@withTranslation('', { wait: true })

@connect(
    state => ({
        partnerCv: state.entities.cvs[currentPartnerUserSelector(state).id],
        partnerGeoCode: currentPartnerSelector(state).geo.code,
        locationItems: state.entities.locations,
        partner: currentPartnerSelector(state),
        partnerUser: currentPartnerUserSelector(state),
        dataFields: partnerDataFieldsSelctor(state),
        isLoadingDataFields: state.pages.partnerProfile.isLoading,
        certifications: Object.values(state.entities.certifications),
    }),
    {
        getPartnerProfileDataFields,
        getPartnerCertifications,
        getCertifications,
        createLanguage,
        deleteLanguage,
        getLocations,
        savePartner,
        initialize,
        submit,
        change,
        getCv,
    }
)

export default class PartnerProfileForm extends React.Component {
    componentDidMount() {
        const { partner } = this.props

        this.props.initialize('partner', {
            id: partner.id,
            companyInformation: partner.companyInformation,
            website: partner.website,
            geo: {
                zipCode: partner.geo.zipCode,
                address: partner.geo.address,
                cityName: partner.geo.cityName,
            },
            phone: partner.phone,
            preferences: {
                digitalLeads: partner.preferences.digitalLeads,
            }
        })

        this.refreshPartnerCv()

        this.props.getPartnerProfileDataFields({ industryId: this.props.partner.industry.id })
            .then(res => {
                const partnerAttributes = this.props.partner.attributes

                const attributes = this.props.dataFields.map(field => ({
                    ...field,
                    selected: partnerAttributes[field.id] ? partnerAttributes[field.id].selected : [],
                }))
                this.props.change('partner', 'attributes', attributes)
            })
    }

    savePartnerInfo(data) {
        const body = cloneDeep(data)
        // trimFormData
        body.attributes = body.attributes.filter(el => el.selected && el.selected.length)

        this.props.savePartner(body)
    }

    getLocations = e => {
        this.props.change('partner', 'geo.cityName', '')
        if(e.target.value.length >= 3) {
            clearTimeout(this.delayTimer)
            this.delayTimer = setTimeout(() => {
                this.props.getLocations(e.target.value, this.props.partnerGeoCode).then(res => {
                    let found = Object.values(this.props.locationItems)
                        .find(el => el.zipCode === String(e.target.value))
                    let city = res.result.length && found ? this.props.locationItems[res.result[0]].cityName : ''
                    this.props.change('partner', 'geo.cityName', city)
                })
            }, 500)
        }
    }

    onLanguageSubmit(data) {
        this.props.createLanguage(this.props.partnerUser.id, data)
            .then(res => {
                this.refreshPartnerCv()
            })
    }

    handleRemoveLanguageClick(languageId) {
        this.props.deleteLanguage(this.props.partnerUser.id, languageId)
            .then(res => {
                this.refreshPartnerCv()
            })
    }

    refreshPartnerCv() {
        this.props.getCv(this.props.partner.id, this.props.partnerUser.id)
    }

    render() {
        const {
            submitting,
            partnerCv,
            submit,
            t,
        } = this.props

        if (submitting) {
            return <LoadingAnimation />
        }

        return (
            <div className="partner-profile-form">
                <ReduxForm onSubmit={this.savePartnerInfo.bind(this)} form="partner">
                    <CompanyInformationContent
                        zipCodeOnChange={this.getLocations}
                        t={t}
                    />
                    <Card.Line />    
                    <CompanyDigitalLead t={t} />

                    <Card.Line />        
                    <CompanyDescriptionContent t={t}/>

                    <FieldArray
                        name="attributes"
                        component={CompanyServicesContent}
                        t={t}
                    />

                    <FieldArray
                        name="attributes"
                        component={CompanySystemCertificationContent}
                        t={t}
                    />

                    <FieldArray
                        name="attributes"
                        component={CompanyInterviewQuestionsContent}
                        t={t}
                    />

                    <FieldArray
                        name="attributes"
                        component={CompanyInterviewQuoteContent}
                        t={t}
                    />
                </ReduxForm>

                <Card.Line />
                {partnerCv ?
                    <CompanyLanguageContent
                        handleRemoveLanguageClick={this.handleRemoveLanguageClick.bind(this)}
                        onLanguageSubmit={this.onLanguageSubmit.bind(this)}
                        languages={partnerCv.languages}
                        t={t}
                    /> :
                    <LoadingAnimation />
                }

                <Button
                    modifiers={[ 'action', 'p_2', 'fullWidth', 'btnBlock' ]}
                    onClick={() => submit('partner')}
                >
                    {t('company_profile.save')}
                </Button>
            </div>
        )
    }
}
