import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import * as auth from '../../actions/auth'
import { queryParametersSelector } from '../../selectors/routing'
import PasswordRecoveryCard from '../auth/PasswordRecoveryCard'
import PageTitle from '../utils/PageTitle'
import FBPixel from '../utils/FBPixel'
import styled from 'styled-components'

const selector = formValueSelector('login')

const LogoCentered = styled.div`
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
    position: relative;
    width: 250px;

    > svg {
        width: 100%;
        height: 80px;
    }
`

@connect(
    (state, props) => ({
        loggingIn: state.auth.loggingIn,
        loginFailed: state.auth.loginFailed,
        queryParameters: queryParametersSelector(state),
        leadGeoDistance: selector(state, 'email', 'password'),
    }),
    dispatch => ({
        login: bindActionCreators(auth.login, dispatch),
    })
)
@reduxForm({
    form: 'login',
})
@withTranslation('', { wait: true })
export default class LogIn extends React.Component {

    login(event) {
        event.preventDefault()
        const { email, password } = this.props.leadGeoDistance

        this.props.login(
            email,
            password
        )
    }

    render() {
        const {
            loggingIn, loginFailed, t,
            queryParameters: { page },
        } = this.props

        if(page) {
            return <PasswordRecoveryCard page={page} />
        }

        return (
            <div className="card login-card">
                <PageTitle identifier="login.title" />
                <form onSubmit={this.login.bind(this)}>
                    <div style={{'display': 'flex', 'justifyContent': 'center'}}>
                        <img src="/images/ageras-logo-blue.svg"/>
                    </div>
                    {loginFailed && <div className="alert alert-danger">{t('login.wrong_login_info')}</div>}
                    <div className="form-group">
                        <label>
                            {t('login.email')}
                        </label>
                        <Field name="email" component="input" type="email" placeholder={t('login.email')} className="form-control" />
                    </div>
                    <div className="form-group">
                        <label>
                            {t('login.password')}
                        </label>
                        <Field name="password" component="input" type="password" placeholder={t('login.password')} className="form-control" />
                    </div>
                    <button className="btn btn-action btn-primary btn-lg btn-block" disabled={loggingIn}>{t('login.button_label')}</button>
                </form>

                <div className="center-block">
                    <br />

                    <Link to={{ search: '?page=recover' }}>{t('login.forgot_password')}</Link>
                </div>
                <FBPixel/>
            </div>
        )
    }
}
