import styled from 'styled-components'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { BACKGROUND_COLOR_MODIFIERS } from '../../elements/modifiers/backgroundColorModifiers'
import { MODIFIER_MARGIN_CONFIG } from '../../elements/modifiers/spaceModifiers'

export default styled.div`
  line-height: ${props => props.size || '50'}px;
  height: ${props => props.size || '50'}px;
  width: ${props => props.size || '50'}px;
  float: ${props => props.float};
  display: inline-block;
  border-radius: 100%;
  text-align: center;
  font-weight: 500;
  color: #fff;
  ${applyStyleModifiers(BACKGROUND_COLOR_MODIFIERS)}
  ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
`
