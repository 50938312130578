import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import React from 'react'
import config from '../../config'
import { fetchJSON } from '../../fetch'
import { currentClientSelector, currentPartnerSelector } from '../../selectors/auth'
import { Button } from '../elements/Button'
import { createBrowserPushSubscription as createClientBrowserPushSubscription } from '../../actions/client'
import { createBrowserPushSubscription as createPartnerBrowserPushSubscription } from '../../actions/partners'

const LOCAL_STORAGE_KEY_DISMISSED_PUSH_NOTIFICATIONS = 'dismissed_push_notifications'

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4)
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/')

    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
}

function getEndpoint(pushSubscription) {
    let endpoint = pushSubscription.endpoint;
    const subscriptionId = pushSubscription.subscriptionId;

    // fix Chrome < 45
    if (subscriptionId && endpoint.indexOf(subscriptionId) === -1) {
        endpoint += '/' + subscriptionId;
    }

    return endpoint;
}

@connect(
    state => ({
        currentClient: currentClientSelector(state),
        currentPartner: currentPartnerSelector(state),
    }),
    {
        createClientBrowserPushSubscription,
        createPartnerBrowserPushSubscription,
    }
)
@withTranslation('', { wait: true })
export default class PushNotificationBar extends React.Component {
    state = {
        display: false,
    }

    componentDidMount = () => {
        if (! this.isPushNotificationSupported()) {
            return
        }

        if (this.hasDeniedPushNotifications()) {
            return
        }

        if (this.hasDismissedPushNotifications()) {
            return
        }

        this.checkForPushSubscription()
    }

    isPushNotificationSupported = () => {
        if (! ('ServiceWorkerRegistration' in window)) {
            return false
        }

        if (! ('showNotification' in ServiceWorkerRegistration.prototype)) {
            return false
        }

        if (! ('PushManager' in window)) {
            return false
        }

        return true
    }

    hasDeniedPushNotifications = () => {
        if (Notification.permission === 'denied') {
            return true
        }

        return false
    }

    hasDismissedPushNotifications = () => {
        return window.localStorage.getItem(LOCAL_STORAGE_KEY_DISMISSED_PUSH_NOTIFICATIONS)
    }

    checkForPushSubscription = () => {
        if (! ('serviceWorker' in navigator)) {
            return
        }

        navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
            serviceWorkerRegistration.pushManager.getSubscription()
                .then((subscription) => {
                    this.setState({ display: ! subscription })

                    if (subscription) {
                        this.savePushSubscription(subscription)
                    }
                })
            })
    }

    dismissPushNotifications = async () => {
        window.localStorage.setItem(LOCAL_STORAGE_KEY_DISMISSED_PUSH_NOTIFICATIONS, '1')
        await this.setState({ display: false })
    }

    enablePushNotifications = async () => {
        const jsonResponse = await fetchJSON(`${config.serviceUrl}/vapid`)
        const serviceWorkerRegistration = await navigator.serviceWorker.ready

        let subscription = null

        try {
            subscription = await serviceWorkerRegistration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(jsonResponse.public_key)
            })
        } catch (e) {
            // A DOMException is thrown if the user clicks the X in the 'enable push notification' modal. It only has an informal value.

            if (! e instanceof DOMException) {
                throw e
            }
        }

        if (subscription) {
            await this.savePushSubscription(subscription)
        }

        await this.setState({ display: false })
    }

    savePushSubscription = (subscription) => {
        const isPartner = !! this.props.currentPartner
        const endpoint = isPartner ? this.props.createPartnerBrowserPushSubscription : this.props.createClientBrowserPushSubscription
        const userId = isPartner ? this.props.currentPartner.id : this.props.currentClient.id

        const key = subscription.getKey('p256dh')
        const token = subscription.getKey('auth')

        return endpoint(userId, {
            publicKey: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
            authToken: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null,
            endpoint: getEndpoint(subscription),
        })
    }

    render() {
        if (! this.state.display) {
            return null
        }

        return (
            <div className="new" style={{ position: 'fixed', width: '100%', bottom: 0, left: 0, padding: '1em 1.8em', background: 'hsla(0,0%,100%,0.7)', fontSize: '14px', borderTop: 'solid 1px #c9c9c9', display: 'flex' }}>
                <div style={{ width: '80%', color: '#000' }}>{this.props.t('push_notifications.text')}</div>
                <div style={{ textAlign: 'right', width: '20%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                    <svg onClick={this.dismissPushNotifications} style={{ color: '#b0b0b0', cursor: 'pointer', display: 'inline-block', width: '33px', position: 'absolute', right: '0' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                    </svg>

                    <Button onClick={this.enablePushNotifications} modifiers={['action', 'large', 'bold', 'bottomLeft']} style={{ outline: 'none' }}>
                        <svg style={{ display: 'inline-block', width: '23px', verticalAlign: 'middle', marginRight: '10px' }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <b>{this.props.t('push_notifications.btn_text')}</b>
                    </Button>
                </div>
            </div>
        )
    }
}
