import { useTranslation } from 'react-i18next';
import { Card } from '../../blocks/Card'
import { Flex } from '../../elements/Flex';
import styled from 'styled-components';
import { darkGrey } from '../../../theme/variables';
import MeetingRequests from './MeetingRequests';

const ClientInfoRow = styled.div`
  margin: 5px 0;
  i {
      color: ${darkGrey};
      font-size: 22px;
      margin-right: 10px;
  }
`

export default function CustomerChoiceClientInfoCard({
    lead,
    partner,
}) {
    const { t } = useTranslation();

    const customer = lead?.customerInfo;

    const clientInfo = [
        customer.companyName && {
            icon: 'mdi-factory',
            content: customer.companyName,
        },
        customer.vatNumber && {
            icon: 'mdi-bank',
            content: customer.vatNumber,
        },
        customer.customerType?.identifier && {
            icon: 'mdi-domain',
            content: customer.customerType.identifier,
        },
        (customer.firstName || customer.lastName) && {
            icon: 'mdi-account',
            content: (customer.firstName + ' ' + customer.lastName).trim(),
        },
        customer.email && {
            icon: 'mdi-email',
            content: customer?.email,
        },
        customer.phone && {
            icon: 'mdi-phone',
            content: customer.phone,
        },
        lead.geo && {
            icon: 'mdi-map-marker',
            content: <Flex modifiers={[ 'directionColumn' ]}>{ lead.geo.address } { lead.geo.zipCode } { lead.geo.cityName }</Flex>,
        },
    ].filter(Boolean);

    return (
        <Card>
            <Card.Header modifiers={[ 'pY_2' ]}>
                <strong>{t('projects.project_detail.about_client_header')}</strong>
            </Card.Header>
            <Card.Content>
                {clientInfo.map((info) =>
                    <ClientInfoRow key={'client-' + info.icon}>
                        <Flex modifiers={[ 'alignCenter' ]} style={{gap: '5px'}}>
                            <i className={'mdi ' + info.icon} />{info.content}
                        </Flex>
                    </ClientInfoRow>
                )}

                {partner && lead &&
                    <ClientInfoRow style={{marginTop: '20px'}}>
                        <MeetingRequests partner={partner} leadId={lead.id} />
                    </ClientInfoRow>
                }
            </Card.Content>
        </Card>
    );
}
