import React from 'react'
import { Card } from '../../../../../components/blocks/Card'
import styled from 'styled-components'
import { Flex } from '../../../../../components/elements/Flex'
import { primaryColor } from '../../../../../theme/variables'

const Icon = styled.i`
  color: ${primaryColor};
  font-size: 32px;
  margin-right: 25px;
`

const SERVICES_ICONS = [
    { name: 'Bogføring', icon: 'book-multiple' },
    { name: 'Skatterådgivning', icon: 'file-document-box-outline' },
    { name: 'Revision', icon: 'calculator' },
    { name: 'Årsregnskab', icon: 'calendar-blank' },
    { name: 'Selskabsstiftelse', icon: 'domain' },
    { name: 'Selvangivelse', icon: 'account-card-details' },
    { name: 'Rådgivning', icon: 'account-multiple' },
    { name: 'Lønhåndtering', icon: 'cash' },
]

export const ServicesCard = ({
   partner,
   t,
}) =>
    Object.values(partner.attributes).map(attribute => attribute && attribute.identifier === 'partner_industry' &&
        <Card>
            <Card.Content modifiers={[ 'pY_5', 'pX_6' ]}>
                <Card.Heading modifiers={[ 'primaryColor', 'mB_5' ]}>{t('partner.data_fields.services')}</Card.Heading>
                <Card.Intro>{t('partner.data_fields.services_intro')}</Card.Intro>
                {attribute.selected.map(el => <div key={'system_certification' + el.id}>
                    <Card.ListItem modifiers={[ 'pX_3', 'pY_2', 'mT_1' ]}>
                        <Flex modifiers={[ 'alignCenter' ]}>
                            <Icon className={`mdi mdi-${(SERVICES_ICONS.find(service => service.name === el.title) || {}).icon || 'package-variant'}`} />
                            <span>{el.title}</span>
                        </Flex>
                    </Card.ListItem>
                </div>)}
            </Card.Content>
        </Card>
    )
