import React from 'react'
import { withTranslation } from 'react-i18next'
import FormattedDate from '../utils/FormattedDate'
import { COIN_DIVIDE_NUMBER } from '../../utils/usePartnerPrePaidBalance'
import Amount from 'components/src/Amount'

@withTranslation('', { wait: true })
export default class CouponItem extends React.Component {
    render() {
        const { coupon, t, currency } = this.props

        return (
            <tr>
                <td className="table-mobile-cell">
                    <table className="definition-list">
                        <tbody>
                            <tr>
                                <td>{ t('coupons.id') }</td>
                                <td>{coupon.id}</td>
                            </tr>
                            <tr>
                                <td>{ t('coupons.expires_at') }</td>
                                <td><FormattedDate date={coupon.expiresAt} format="date" /></td>
                            </tr>
                            <tr>
                                <td>{ t('coupons.punches_left') }</td>
                                <td>
                                    <Amount number={coupon.punchesLeft / COIN_DIVIDE_NUMBER} currency={currency} maximumFractionDigits={0} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                <td>{coupon.id}</td>
                <td><FormattedDate date={coupon.expiresAt} format="date" /></td>
                <td>
                    <Amount number={coupon.punchesTotal / COIN_DIVIDE_NUMBER} currency={currency} maximumFractionDigits={0} />
                </td>
                <td>
                    <Amount number={coupon.punchesLeft / COIN_DIVIDE_NUMBER} currency={currency} maximumFractionDigits={0} />
                </td>
            </tr>
        )
    }
}
