import React, { Component } from 'react'
import { Field, formValueSelector, reduxForm, SubmissionError } from 'redux-form'
import { withTranslation } from 'react-i18next'
import { NumberInput } from '../forms/inputs/NumberInputGroup'
import { TextInput } from '../forms/inputs/TextInput'
import { connect } from 'react-redux'
import RadioGroup from '../forms/inputs/RadioGroup'

@reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
})
@connect(
    (state, props) => ({
        formValues: formValueSelector('wizard')(state, 'request'),
        invoice: state.entities.invoices[props.invoiceId],
    }),
)
@withTranslation('', { wait: true })
export default class RefundRequestFormReason extends Component {
    validate(values) {
        if (!values.request_reason) {
            throw new SubmissionError({ request_reason: this.props.t('refunds.request.reason.validation') })
        }else if (!values.request) {
            throw new SubmissionError({ request: this.props.t('refunds.request.reason.validation') })
        }else if (!values.partner_request_description) {
            throw new SubmissionError({ partner_request_description: this.props.t('refunds.request.reason.validation') })
        } else {
            this.props.submit()
        }
    }

    setAmount() {
        this.props.change('requested_amount_excl_vat.amount', this.props.invoice.amounts.totalExcludingVat.amount)
        this.props.change('requested_amount_excl_vat.currency', this.props.invoice.amounts.totalExcludingVat.currency)
    }

    render() {
        const { handleSubmit, previousPage, t, formValues } = this.props
        const radios = [
            { id: 'no_contact', name: t('refunds.request.reason.message_reason.no_contact') },
            { id: 'company_closed', name: t('refunds.request.reason.message_reason.company_closed') },
            { id: 'size_changed', name: t('refunds.request.reason.message_reason.size_changed') },
            { id: 'task_changed', name: t('refunds.request.reason.message_reason.task_changed') },
            { id: 'client_reconsidered', name: t('refunds.request.reason.message_reason.client_reconsidered') },
        ]
        const requests = [
            { id: 'full_radio', name: t('refunds.request.reason.requesting_info.full_radio') },
            { id: 'partial_radio', name: t('refunds.request.reason.requesting_info.partial_radio') },
        ]
        return (
            <form onSubmit={handleSubmit(this.validate.bind(this))}>
                <Field
                    name="request_reason"
                    component={RadioGroup}
                    options={radios}
                    required
                    label={t('refunds.request.reason.message_reason.label')}
                />

                <p>
                    <Field
                        name="request"
                        component={RadioGroup}
                        options={requests}
                        required
                        onChange={this.setAmount.bind(this)}
                        label={t('refunds.request.reason.requesting_info.label')}
                    />
                    {formValues === 'partial_radio' &&
                        <Field name="requested_amount_excl_vat.amount" component={NumberInput} />
                    }
                </p>
                <p>
                    <label>{t('refunds.request.reason.additional_info')}</label>
                    <Field name="partner_request_description" component={TextInput} required/>
                </p>
                <div>
                    <br />
                    <button type="submit" className="next btn btn-action btn-lg btn-block">{t('refunds.request.reason.next')}</button>

                    <br />
                    <a onClick={previousPage} className="cursor-pointer">{t('refunds.request.reason.back')}</a>
                </div>
            </form>
        )
    }
}
