import React from 'react'
import {
    InputGroup,
} from 'react-bootstrap'
import { NumberInput } from '../forms/inputs/NumberInputGroup'

export const renderField = ({
    input,
    label,
    type,
    children,
    placeholder,
    currency,
    plainText,
    meta: { touched, error, warning },
  }) => <div className={touched && error ? ' has-error' : ''}>
      { label && <label className="control-label">{label}</label> }
      <div>
          {plainText ?
              <span>{input.value}</span> :
              currency ?
                  <InputGroup className="currency">
                      <NumberInput input={input} placeholder={placeholder ? placeholder : label} />
                      <InputGroup.Addon>{currency.symbol}</InputGroup.Addon>
                  </InputGroup> :
                  children ?
                      <select {...input} placeholder={placeholder ? placeholder : label} className={type === 'checkbox' ? '' : 'form-control'} type={type}>
                          {children}
                      </select> :
                      <input {...input} placeholder={placeholder ? placeholder : label} className={type === 'checkbox' ? '' : 'form-control'} type={type} />
          }
          {touched && (
              (error && <span className="help-block">{error}</span>) ||
              (warning && <span className="help-block">{warning}</span>)
          )}
      </div>
  </div>

export const renderTextarea = ({ input, height, placeholder, type, disabled, meta: { touched, error, warning } }) => (
    <div className="form-group">
        <textarea {...input}
              style={{ height: height ? height : '150px' }}
              type={type}
              placeholder={placeholder}
                  disabled={disabled}
              className="form-control" />
        {touched && (
            (error && <span className="help-block">{error}</span>) ||
            (warning && <span className="help-block">{warning}</span>)
        )}
    </div>
)

export class CheckboxGroup extends React.Component {
    render() {
        const {
            label,
            required,
            options,
            input,
            meta: { touched, error, warning },
        } = this.props

        if(!options.length) {
            return null
        }

        return (
            <div className={'form-group' + (touched && error ? ' has-error' : '')}>
                {options.map((option, index) => {
                    return (
                        <div className="checkbox" key={index}>
                            <label>
                                <input type="checkbox"
                                    name={`${input.name}[${index}]`}
                                    value={option.value}
                                    checked={input.value.indexOf(option.value) !== -1}
                                    onChange={event => {
                                        const newValue = [ ...input.value ]
                                        if (event.target.checked) {
                                            newValue.push(option.value)
                                        } else {
                                            newValue.splice(newValue.indexOf(option.value), 1)
                                        }

                                        return input.onChange(newValue)
                                    }}
                                />
                                {option.label}
                            </label>
                        </div>
                    )
                })}
                {touched &&
                    ((error &&
                        <span className="help-block">
                            {error}
                        </span>) ||
                        (warning &&
                        <span className="help-block">
                            {warning}
                        </span>))}
            </div>
        )
    }
}
