import React from 'react'
import { Card } from '../../../../../components/blocks/Card'
import styled from 'styled-components'
import { Flex } from '../../../../../components/elements/Flex'

const FormatText = styled.div`
  line-height: 1.53;
  color: #5f6974
`

const ImgContainer = styled.div`
  width: 800px;
  margin-${({ left }) => left ? 'left' : 'right'}: 50px;
`

export const MenetoWorkInfoCard = () =>
    <Card>
        <Card.Content modifiers={[ 'pY_5', 'pX_6' ]}>
            <Card.Heading modifiers={[ 'cyanColor', 'mB_5' ]}>
                Sådan virker Meneto
            </Card.Heading>
            <Flex modifiers={[ 'mB_3' ]}>
                <ImgContainer>
                    <img src="/images/meneto-partner/iphone-w-meneto-1.png" />
                </ImgContainer>
                <div>
                    <h2>
                        <strong>1. Snap dine bilag og vidaresend fakturaer</strong>
                    </h2>
                    <FormatText>
                        Hver gang du får en kvittering eller faktura skal du blot tage et billede af den eller videresende den til vores e-mail. Herefter bliver den automatisk bogført. Hvis vi har spørgsmål for at kunne kategorisere den korrekt, kan du give os denne information med ét klik i appen
                    </FormatText>
                </div>
            </Flex>
            <Flex modifiers={[ 'mB_3' ]}>
                <div>
                    <h2>
                        <strong>2. Meneto laver dit regnskab fra A-Z</strong>
                    </h2>
                    <FormatText>
                        Du skal aldrig bekymre dig om debet, kredit og regnskab igen. Meneto indberetter automatisk din moms og årsregnskab for dig. Slip for at bekymre dig om regnskabet op til deadline og læn dig i stedet tilbage. Med Meneto er du sikker på at alle frister overholdes, så du undgår bøder og fejl.
                    </FormatText>
                </div>
                <ImgContainer left>
                    <img src="/images/meneto-partner/iphone-w-meneto-2.png" />
                </ImgContainer>
            </Flex>
            <Flex>
                <ImgContainer>
                    <img src="/images/meneto-partner/iphone-w-meneto-3.png" />
                </ImgContainer>
                <div>
                    <h2>
                        <strong>3. Fuldt overblik over din virksomhed</strong>
                    </h2>
                    <FormatText>
                        Du skal aldrig bekymre dig om debet, kredit og regnskab igen. Meneto indberetter automatisk din moms og årsregnskab for dig. Slip for at bekymre dig om regnskabet op til deadline og læn dig i stedet tilbage. Med Meneto er du sikker på at alle frister overholdes, så du undgår bøder og fejl.
                    </FormatText>
                </div>
            </Flex>
        </Card.Content>
    </Card>
