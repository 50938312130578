import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { currentPartnerSelector, currentPartnerUserSelector } from '../../selectors/auth'
import LoadingAnimation from '../utils/LoadingAnimation'
import InfoPage from '../layout/infoPage'
import { Card, Content, LineCard, Separator, WarningIcon } from '../cards/index'
import { ModalLayout } from '../modals/ModalLayout'
import { Col, Row } from 'react-bootstrap'
import { getConversion } from '../../actions/conversions'
import { dangerColorTwo } from '../../theme/variables'
import { Link } from 'react-router-dom'
import { punchCountSelector } from '../../selectors/coupons'
import { showModal } from '../../actions/modal'

const youtubeIds = {
    'de-accounting': '7eSyf8jRo78',
    'dk-accounting': 'VIuuBgFk9Dw',
    'no-accounting': 'gK6nRVv3MyI',
    'se-accounting': 'hZM1QM9drUs',
    'nl-accounting': 'gGcZTCYDHmY',
    'de-law': '7eSyf8jRo78',
    'dk-law': 'uSAfk0a2jWs',
    'no-law': 'gK6nRVv3MyI',
    'se-law': 'hZM1QM9drUs',
    'nl-law': 'gGcZTCYDHmY',
}

const ConversionInfo = ({ t, industryKey }) => (<div>
    <div className="icon-container"><img src="/images/icons/blue-quality.svg" alt="" /></div>
    <h1 className="intro-heading"><strong>{t(`subscription.info_page.${industryKey}.step_box.step_header_1`)}</strong></h1>
    <span className="sub-header">{t(`subscription.info_page.${industryKey}.step_box.step_sub_header_1`)}</span>
    {t(`subscription.info_page.${industryKey}.step_box.step_sub_header_1_2`) === '.' ||
        t(`subscription.info_page.${industryKey}.step_box.step_sub_header_1_3`) === '.' ||
        <div className="subscription-preview">
            <Row>
                <Col xs={12}>
                    <strong>{t(`subscription.info_page.${industryKey}.step_box.step_sub_header_1_2`)}</strong>
                    <p>{t(`subscription.info_page.${industryKey}.step_box.step_sub_header_1_3`)}</p>
                </Col>
            </Row>
        </div>
    }
</div>)

@connect(
    state => ({
        isLoading: state.pages.subscriptionInfo.isLoading,
        conversion: state.pages.subscriptionInfo.conversionInfo,
        punchesLeft: punchCountSelector(state),
        partner: currentPartnerSelector(state),
        user: currentPartnerUserSelector(state),
    }),
    {
        getConversion,
        showModal,
    }
)

@withTranslation('', { wait: true })
export default class SubscriptionInfoPage extends React.Component {
    state = {
        showModal: false,
    }
    componentWillMount() {
        this.props.getConversion(this.props.partner.id)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    checkout = () => {
        this.props.showModal({ name: 'agerasGreetingModal' })
    }

    render() {
        const {
            isLoading,
            conversion,
            punchesLeft,
            t,
            partner,

        } = this.props

        const industryKey = partner.industry.identifier

        return (
            <div className="subscription-info"
                 style={{
                     backgroundColor: '#fff',
                     marginTop: '-30px',
                     paddingTop: '30px',
                     marginBottom: '-80px',
                     paddingBottom: '80px',
                 }}>
                <ModalLayout
                    showModal={this.state.showModal}
                    closeModal={() => this.setState({ showModal: !this.state.showModal })}
                >
                    <div style={{ backgroundColor: '#000' }}>
                    <iframe
                        width="100%"
                        height="428"
                        style={{ borderWidth: 0 }}
                        src={`https://www.youtube.com/embed/${youtubeIds[partner.geo.code + '-' + industryKey]}?rel=0&showinfo=0&autoplay=1`}
                    />
                    </div>
                </ModalLayout>
                <InfoPage
                    className="confetti"
                    icon="/images/icons/icon-ageras.svg"
                    titleText={t(`subscription.info_page.${industryKey}.page.title`)}
                    subtitleText={t(`subscription.info_page.${industryKey}.page.sub_title`)}
                >
                    <Separator margin="30px auto"/>

                    <div className="page-separator">
                        <img src="/images/separator-askew.svg" alt=""/>
                    </div>
                    <div className="separated-page-section">
                        {isLoading || !conversion ?
                            <LoadingAnimation /> :
                            <div className="page-splash">
                                {conversion.state === 'unknown' && ![
                                    'de',
                                    'nl',
                                    'se',
                                    'dk',
                                    'no',
                                ].includes(partner.geo.code) ?
                                    <ConversionInfo t={t} industryKey={industryKey} /> :
                                    <div>
                                        {conversion.state !== 'awaiting_conversion' &&
                                        <div>
                                            <div className="icon-container">
                                                <img src="/images/icons/subscription-quality.svg" alt="" />
                                            </div>
                                            <h1 className="intro-heading">
                                                <strong>{t(`subscription.info_page.${industryKey}.${conversion.state}.header`, {
                                                    converted: conversion.discountPrice,
                                                    months: conversion.discountMonths,
                                                    remaining: punchesLeft,
                                                })}</strong>
                                            </h1>
                                        </div>}
                                        <Separator margin="60px auto" />
                                        <Card>
                                            <Content className="text-left">
                                                {conversion.state === 'overdue_invoices' &&
                                                <LineCard borderColor={dangerColorTwo}>
                                                    <div className="warning-card">
                                                        <div className="text-center"><WarningIcon
                                                            backgroundColor={dangerColorTwo}>!</WarningIcon></div>
                                                        <div>
                                                            <p>
                                                                {t(`subscription.info_page.${conversion.state}.accept_invoices_warning`)}&nbsp;
                                                                <Link to="/account/invoices"
                                                                      style={{ textDecorationLine: 'underline' }}>
                                                                    {t(`subscription.info_page.${conversion.state}.link`)}
                                                                </Link>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </LineCard>}
                                                <a
                                                    onClick={this.checkout}
                                                    className={`btn btn-block btn-lg btn-action ${conversion.state === 'overdue_invoices' ? 'disabled' : ''}`}
                                                >
                                                    {t('subscription.info_page.button_text')}
                                                </a>
                                                {/*<p className="intro-text-color">{t('subscription.info_page.button_sub_info')}</p>*/}
                                                {conversion.state !== 'awaiting_conversion' && conversion.state !== 'unknown' &&
                                                <Row>
                                                    <br />
                                                    <Col md={1} className="text-center">
                                                        <img src="/images/icons/subscription-quality.svg" alt="" />
                                                        <br /><br />
                                                    </Col>
                                                    <Col md={11} className="grid-content">
                                                        <strong>{t('subscription.info_page.action_header')}</strong>
                                                        <p style={{ marginTop: '10px' }}>
                                                            {Boolean(conversion.discountMonths) &&
                                                            t(`subscription.info_page.${industryKey}.action_sub_header_1`, { months: conversion.discountMonths })
                                                            }
                                                        </p>
                                                    </Col>
                                                </Row>
                                                }
                                            </Content>
                                        </Card>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <div className="page-splash">
                        <h1 className="intro-heading"><strong>{t('subscription.info_page.preview_header')}</strong></h1>
                        <span className="sub-header">{t('subscription.info_page.preview_sub_header')}</span>
                    </div>
                    <div className="subscription-preview">
                        <Row>
                            <Col md={8}>
                                <img src={`/images/subscription-info/${partner.geo.code}1-partner-portal-${industryKey}.png`} alt="" />
                            </Col>
                            <Col md={4}>
                                <strong>{t('subscription.info_page.subscription_preview_info.header_1')}</strong>
                                <p>{t('subscription.info_page.subscription_preview_info.sub_header_1')}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <strong>{t('subscription.info_page.subscription_preview_info.header_2')}</strong>
                                <p>{t('subscription.info_page.subscription_preview_info.sub_header_2')}</p>
                            </Col>
                            <Col md={8}>
                                <img src={`/images/subscription-info/${partner.geo.code}2-create-quote-${industryKey}.png`} alt="" />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <img src="/images/subscription-info/3-quotes.png" />
                            </Col>
                            <Col md={4}>
                                <strong>{t('subscription.info_page.subscription_preview_info.header_3')}</strong>
                                <p>{t('subscription.info_page.subscription_preview_info.sub_header_3')}</p>
                            </Col>
                        </Row>
                    </div>
                </InfoPage>
            </div>
        )
    }
}
