import React from 'react'
import { Card } from '../../blocks/Card'
import ExpandButton from '../../utils/ExpandButton'
import LanguageForm from '../../cv/LanguageForm'
import LanguageItem from '../../marketing/LanguageItem'
import { Col, Row } from 'react-bootstrap'

export const CompanyLanguageContent = ({
    handleRemoveLanguageClick,
    onLanguageSubmit,
    languages,
    t,
}) =>
    <Card.Content>
        <Card.Title>{t('company_profile.language.title')}</Card.Title>
        <Card.Intro>{t('company_profile.language.intro')}</Card.Intro>

        <Row>
            {languages.map(exp =>
                <Col md={6}>
                    <LanguageItem
                        onDelete={handleRemoveLanguageClick}
                        key={exp.id}
                        lang={exp}
                    />
                </Col>
            )}
        </Row>
        <ExpandButton
            className="success"
            content={<LanguageForm onSubmit={onLanguageSubmit} />}
            header={t('company_profile.language.add_language')}
        />
    </Card.Content>
