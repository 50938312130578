import { PartnerResource } from 'ageras-api-client/src/api'
import { useAuth } from 'ageras-api-client/src/auth'
import Amount from 'components/src/Amount'
import useModal from 'components/src/modals/useModal'
import React from 'react'
import Flex from 'components/src/layout/Flex'
import Text from 'components/src/texts/Text'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Tooltip from 'components/src/Tooltip'
import usePartnerPrePaidBalance from '../../../utils/usePartnerPrePaidBalance'
import PrePaymentModal from '../../modals/PrePaymentModal'

interface MarketplaceBalanceProps {
    partner: PartnerResource,
    hasMarketplaceWithoutFeesAccess: boolean
}

const BalanceWrapper = styled(Flex)`
    background-color: #0003;
    border-radius: 56px;
    border: 1px solid #0000002A;

`
const CountBalanceWrapper = styled(Flex)`
    border-left: 1px solid #dbdfff18;
`

function BalanceWidget({ partner, hasMarketplaceWithoutFeesAccess }: MarketplaceBalanceProps) {
    const { t } = useTranslation()
    const auth = useAuth()
    const { show } = useModal()
    const { balance, isLoading } = usePartnerPrePaidBalance()

    if (isLoading || !partner.marketplace?.currentQuotesLimit || partner.marketplace?.currentQuotesCount === undefined) {
        return null
    }

    const quotesLeft = partner.marketplace?.currentQuotesLimit - partner.marketplace?.currentQuotesCount;

    return <Flex alignItems="center">
        <BalanceWrapper alignItems="center">
            <Tooltip location="bottom">
                <Flex pl={2} pr={2} py={1} cursor="default">
                    <Text>
                        {quotesLeft}
                        /
                        {partner.marketplace?.currentQuotesLimit}
                    </Text>
                </Flex>
                <Flex p={2}>
                    <Text textAlign="center" color="impact" size="small">
                        {t(`marketplace.balance.quotes_tooltip${quotesLeft === 1 ? '' : '_plural'}`, { count: quotesLeft })}
                    </Text>
                </Flex>
            </Tooltip>
            {!hasMarketplaceWithoutFeesAccess && <CountBalanceWrapper pl={2} pr={2} py={1} cursor="pointer" onClick={show(args => <PrePaymentModal {...args} token={auth.token!} partner={partner} geoCode={partner.geo?.code!} currency={partner.preferences?.currency!} partnerId={partner.id!} />)}>
                <Text>
                    {<Amount number={balance} currency={partner.preferences?.currency?.code} maximumFractionDigits={0} />}
                </Text>
            </CountBalanceWrapper>}
        </BalanceWrapper>
    </Flex>
}

export default BalanceWidget
