import React from 'react'
import { withTranslation } from 'react-i18next'
import Nl2br from '../utils/Nl2br'
import FormattedDate from '../utils/FormattedDate'

@withTranslation('', { wait: true })
export default class RatingReplyItem extends React.Component {
    render() {
        const { reply, t, partnerUser } = this.props

        return <div className="rating-reply bordered-container highlighted-container">
            <div className="header">
                {partnerUser.profilePicture &&
                    <div className="profile-picture" style={{ backgroundImage: `url(${partnerUser.profilePicture})` }} />
                }
                <div className="info">
                    <h3>{t('rating.reply_header')}</h3>
                    <ul className="meta-info">
                        <li><FormattedDate date={reply.created_at} format="date" /></li>
                    </ul>
                </div>
            </div>
            <blockquote>
                {reply.comment}
            </blockquote>
        </div>
    }
}
