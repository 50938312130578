import React from 'react'
import { Card } from '../../../../../components/blocks/Card'
import styled from 'styled-components'
import { Flex } from '../../../../../components/elements/Flex'
import { primaryColor } from '../../../../../theme/variables'

const Icon = styled.i`
  color: ${primaryColor};
  font-size: 32px;
  margin-right: 25px;
`

const Icons = [
    { name: 'Agricultural', icon: 'carrot' },
    { name: 'Arts, entertainment and crafts', icon: 'filmstrip' },
    { name: 'Association', icon: 'view-quilt' },
    { name: 'Construction', icon: 'shovel' },
    { name: 'Building', icon: 'shovel' },
    { name: 'Business services', icon: 'wallet-travel' },
    { name: 'Consulting', icon: 'wallet-travel' },
    { name: 'Education', icon: 'book-open-variant' },
    { name: 'Energy', icon: 'power-plug' },
    { name: 'Financial', icon: 'cash-multiple' },
    { name: 'Insurance services', icon: 'cash-multiple' },
    { name: 'Food', icon: 'food-fork-drink' },
    { name: 'Beverage', icon: 'food-fork-drink' },
    { name: 'Health', icon: 'food-apple' },
    { name: 'Beauty', icon: 'food-apple' },
    { name: 'Holding / Investment', icon: 'chart-areaspline' },
    { name: 'Industry/ Manufacturing', icon: 'office-building' },
    { name: 'Media', icon: 'television-classic' },
    { name: 'Medical / care', icon: 'hospital' },
    { name: 'Public Sector', icon: 'account-multiple' },
    { name: 'Real Estate', icon: 'home-variant' },
    { name: 'Retail / trade / e-commerce', icon: 'web' },
    { name: 'Services', icon: 'palette-swatch' },
    { name: 'Technology', icon: 'laptop' },
    { name: 'Transport', icon: 'car-hatchback' },
]

export const IndustrySpecialisationCard = ({
    partner,
    t,
}) =>
    Object.values(partner.attributes).map(attribute => attribute && attribute.identifier === 'client_industry' &&
        <Card>
            <Card.Content modifiers={[ 'pY_5', 'pX_6' ]}>
                <Card.Heading modifiers={[ 'primaryColor', 'mB_5' ]}>
                    {t('partner.data_fields.industry_specialisation')}
                </Card.Heading>
                <Card.Intro>
                    {t('partner.data_fields.industry_specialisation_intro')}
                </Card.Intro>
                {attribute.selected.map(el => <div key={'system_certification' + el.id}>
                    <Card.ListItem modifiers={[ 'pX_3', 'pY_2', 'mT_1' ]}>
                        <Flex modifiers={[ 'alignCenter' ]}>
                            <Icon className="mdi mdi-factory"/> <span>{el.title}</span>
                        </Flex>
                    </Card.ListItem>
                </div>)}
            </Card.Content>
        </Card>
    )
