import React from 'react'
import { Flex, OverflowText, OverlayToolTip, ProgressLabel } from '../cards'
import EditCell from '../layout/EditCell'
import { ProjectStatusChooser } from '../layout/ProjectStatusChooser'
import { Button } from '../elements/Button'
import moment from 'moment'
import { TriangleBadge } from '../blocks/triangleBadge'
import { withTranslation } from 'react-i18next'
import connect from 'react-redux/es/connect/connect'
import { projectsSelector, projectStatusesSelector } from '../../selectors/projects'
import { hasQuoteCreationAccessAccessSelector } from '../../selectors/access'
import { addNoteToProject, updateProject, getProjects, getProjectStatuses } from '../../actions/projects'
import { markQuoteAccepted, createCustomerCalledAction, refuseQuote } from '../../actions/quotes'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { highlightBackgroundColor } from '../../theme/variables'
import CallButton from '../elements/CallButton'
import { getQuotesProgresses, getQuotes } from '../../actions/quotes'
import { currentPartnerSelector } from '../../selectors/auth'
import { showModal } from '../../actions/modal'
import { toastr } from 'react-redux-toastr'
import { PARTNER_REJECTED_QUOTE, sendSegmentData } from '../../utils/segment'


const LinkRow = styled(Link)`
    display: table-row;
    &:hover {
        background-color: ${highlightBackgroundColor};
    }
`

@withTranslation('', { wait: true })
export class ProjectRow extends React.PureComponent {
    state = {
        refusalActive: this.props.quote && this.props.quote.canBeRefused ? new Date(this.props.quote.refusalExpiresAt) > new Date() : false
    }
    status = (project, quote) => {
        if (quote) {
            if (quote.status === 'rejected') {
                return <div>
                    <ProgressLabel progress={'lost'} icon={true} />
                    <span className="hidden-xs">&nbsp;{this.props.t('quotes.progress.lost')}</span>
                </div>
            }
            if (quote.status === 'closed') {
                return <div>
                    <ProgressLabel progress={'lost'} icon={true} />
                    <span className="hidden-xs">&nbsp;{this.props.t('quotes.progress.closed')}</span>
                </div>
            }
            return <div>
                <ProgressLabel progress={quote.progress} icon={true} />
                <span className="hidden-xs">&nbsp;{this.props.t('quotes.progress.' + (quote.progress || 'unknown'))}</span>
            </div>
        }
        if (project.status) {
            return <div>
                <div className="project-status" style={{ backgroundColor: project.status.color }} />
                <span className="hidden-xs">&nbsp;{this.props.t('projects.statuses.' + project.status.name)}</span>
            </div>
        }
        return '-'
    }

    handleRefusalTimeOut = () => {
        this.setState({
            refusalActive: false
        })
    }

    render() {
        const {
            hasQuoteCreationAccess,
            existAwaitingClients,
            handleChangeNoteCell,
            changeProjectStatus,
            markQuoteAccepted,
            projectStatuses,
            projectNote,
            customer,
            project,
            onCall,
            quote,
            t,
        } = this.props

        return (
            <LinkRow
                to={
                    quote && quote.progress === 'draft' ?
                        `quote/${project.lead.id}` :
                        `/projects/${project.id}`
                }
            >
                <td>{project.lead ? project.lead.id : '-'}</td>
                <td key="td_1">{customer.companyName || (customer.firstName && customer.lastName ?
                        customer.firstName + ' ' + customer.lastName : customer.firstName ?
                            customer.firstName : customer.lastName ?
                                customer.lastName : '-'
                )}</td>
                <td
                    key="td_2"
                    style={{ cursor: 'text', userSelect: 'text' }}
                    onClick={e => e.preventDefault()}
                >
                    <CallButton
                        phoneNumber={customer.phone || customer.mobile}
                        geoCode={customer.geo.code}
                        onCall={onCall}
                        item={project}
                        icon={<i className="mdi mdi-phone-classic" />}
                        modifiers={[ 'primary', 'fullWidth', 'btnBlock' ]}
                        disabled={quote ? [ 'lost', 'draft' ].includes(quote.progress) : true}
                        label={t('projects.project_detail.btn_call_client')}
                    />
                </td>
                <td key="td_3">{Object.keys(project.geo).length > 0 ? `${project.geo.zipCode} ${project.geo.cityName}` : '-'}</td>
                <EditCell
                    value={projectNote ? projectNote.content : ''}
                    id={projectNote ? projectNote.id : ''}
                    item={project.id}
                    onBlur={handleChangeNoteCell}
                    tooltipPlaceholder={t('projects.create_note')}
                />
                {hasQuoteCreationAccess &&
                <td onClick={e => e.preventDefault()}>
                    {project.status ?
                        <ProjectStatusChooser
                            changeProjectStatus={changeProjectStatus}
                            project={project}
                            projectStatuses={projectStatuses}
                            selectedStatus={(quote && quote.status === 'rejected') ? 'lost' : project.status.name}
                            t={t}
                        /> :
                        ' - '
                    }
                </td>}
                { existAwaitingClients &&
                <td>
                    {Boolean(quote) && ![ 'accepted', 'rejected' ].includes(quote.status) && quote.progress !== 'draft' ?
                        <Button
                            modifiers={[ 'action' ]}
                            onClick={e => {
                                e.stopPropagation()
                                markQuoteAccepted(quote.leadId, quote.id)
                            }}
                        >
                            {t('projects.quote_info.mark_as_won')}
                        </Button> : '-'
                    }
                </td>
                }
                <td>
                    {moment(project.createdAt).format('L')}
                </td>

                <td className="visible-xs">
                    <div>
                        <Flex modifiers={[ 'alignCenter' ]}>
                            {this.status(project, quote) !== '-' && this.status(project, quote)}&nbsp;&nbsp;
                            {Boolean(quote) && [ 'lost', 'draft' ].includes(quote.progress) ?
                                <OverflowText><strong>{project.title}</strong></OverflowText> :
                                <span>
                                    <strong>
                                        {(customer.firstName && customer.lastName ?
                                            customer.firstName + ' ' + customer.lastName : customer.firstName ?
                                                customer.firstName : customer.lastName ?
                                                    customer.lastName :
                                                    <OverlayToolTip text={project.title} id={project.id + '_project'}/>)
                                        }
                                    </strong>
                                    <br />
                                    <span className="project-address">
                                        {project.geo.zipCode}&nbsp;
                                        {project.geo.cityName} - {moment(project.createdAt).format('L')}
                                    </span>
                                </span>
                            }
                        </Flex>
                        {hasQuoteCreationAccess && project.status &&
                        <TriangleBadge text={t('projects.statuses.' + project.status.name)}/>
                        }
                    </div>
                </td>
            </LinkRow>
        )
    }
}

@connect(
    state => ({
        projects: projectsSelector(state),
        partner: currentPartnerSelector(state),
        quotes: state.entities.quotes,
        projectStatuses: projectStatusesSelector(state),
        projectNotes: state.entities.projectNotes,
        hasQuoteCreationAccess: hasQuoteCreationAccessAccessSelector(state),
    }),
    {
        updateProject,
        addNoteToProject,
        markQuoteAccepted,
        createCustomerCalledAction,
        refuseQuote,
        getProjects,
        getProjectStatuses,
        getQuotesProgresses,
        getQuotes,
        showModal,
    }
)
@withTranslation('', { wait: true })
export class ProjectListTable extends React.PureComponent {

    changeProjectStatus = (project, name) => {
        const data = {
            status: {
                name,
            },
        }
        this.props.updateProject(project.id, data)
    }

    handleChangeNoteCell = (note, projectId) => {
        return this.props.addNoteToProject(projectId, note)
    }

    onCall = project => {
        this.props.createCustomerCalledAction(project.lead.id, project.quote)
    }

    refuseCallback = async (project, refusal_reason) => {
        try {
            await this.props.refuseQuote(project.lead.id, project.quote, refusal_reason)
            sendSegmentData(this.props.partner, PARTNER_REJECTED_QUOTE, {
                leadId: project.lead.id,
                partnerId: this.props.partner.id,
            })
        } catch (e) {
            toastr.error(this.props.t('projects.quote_info.refusal_expired'))
        }

        this.props.getProjects({
            partnerId: this.props.partner.id,
            isLeadValidated: 1,
            limit: 50,
        })
    }

    handleRefuseLead = project => {
        const { t } = this.props

        this.props.showModal({
            name: 'showQuoteRefusalModal',
            t: t,
            callback: this.refuseCallback,
            callbackParams: project,
        })
    }

    markQuoteAccepted = items => this.props.markQuoteAccepted(...items)

    render() {
        const {
            hasQuoteCreationAccess,
            markQuoteAccepted,
            projectStatuses,
            projectNotes,
            projects,
            quotes,
            t,
            partner,
        } = this.props

        const projectsAwaitingClients = projects
            .filter(p => p.quote && ![ 'accepted', 'rejected' ]
                .includes(quotes[p.quote] && quotes[p.quote].status))

        return projects.length !== 0 &&
            <table className="datatable responsive_mobile compact" >
                <thead>
                    <tr>
                        <th style={{ width: '110px' }}>{t('projects.lead_id')}</th>
                        <th>{t('projects.contact_info.full_name')}</th>
                        <th style={{ width: '210px' }}>{t('projects.contact_info.phone')}</th>
                        <th>{t('projects.contact_info.address')}</th>
                        <th>{t('projects.notes.title')}</th>
                        {hasQuoteCreationAccess && <th>{t('projects.quote_info.label')}</th>}
                        {projectsAwaitingClients.length > 0 && <th/>}
                        <th style={{ width: '110px' }}>{t('projects.created_at')}</th>
                    </tr>
                </thead>
                <tbody>
                    {projects.map((project, i) => {
                        const customer = project.customers[0] || {}
                        const quote = quotes[project.quote]

                        return <ProjectRow
                            key={'project_' + project.id}
                            handleChangeNoteCell={this.handleChangeNoteCell}
                            changeProjectStatus={this.changeProjectStatus}
                            handleRefuseLead={this.handleRefuseLead}
                            onCall={this.onCall}
                            markQuoteAccepted={markQuoteAccepted}
                            existAwaitingClients={projectsAwaitingClients.length > 0}
                            hasQuoteCreationAccess={hasQuoteCreationAccess}
                            projectNote={projectNotes[project.notes[0]]}
                            projectStatuses={projectStatuses}
                            project={project}
                            customer={customer}
                            quote={quote}
                            partner={partner}
                        />
                    })}
                </tbody>
            </table>
    }
}
