import React from 'react'
import styled from 'styled-components'
import { cardLabelColor } from '../../../theme/variables'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { TEXT_COLOR_MODIFIERS } from '../../elements/modifiers/textColorModifiers'

const LABEL_MODIFIERS = {
    strong: () => `
        font-weight: bold;
    `,
}

export default styled.label`
    text-transform: uppercase;
    color: ${cardLabelColor};
    font-weight: 500;
    margin-bottom: 6px;
    font-size: 12px;
    ${applyStyleModifiers(LABEL_MODIFIERS)}
    ${applyStyleModifiers(TEXT_COLOR_MODIFIERS)}
`
