import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Dropzone from 'react-dropzone'
import * as users from '../../actions/users'
import { currentPartnerUserSelector, currentPartnerSelector } from '../../selectors/auth'
import UserEditForm from '../users/UserEditForm'
import PageTitle from '../utils/PageTitle'
import { Card } from '../blocks/Card'
import { Button } from '../elements/Button'
import SwitchCheckboxInput from '../blocks/SwitchCheckboxInput'
import { Flex } from '../elements/Flex'
import { lightGrey } from '../../theme/variables'
import styled from 'styled-components'

const PictureBox = styled(Dropzone)`
    float: left;
    padding: 20px;
    background: #eee;
    border: 1px solid ${lightGrey};
    border-radius: 3px;
    margin-right: 20px;

    img {
        border: 1px solid ${lightGrey};
        border-radius: 100%;
        height: 105px;
        width: 105px;
        background-size: cover;
        background-position: center center;
    }
`

@connect(
    state => ({
        user: currentPartnerUserSelector(state),
        partner: currentPartnerSelector(state),
    }),
    {
        getUser: users.getUser,
        saveUserProfilePicture: users.saveUserProfilePicture,
        deleteUserProfilePicture: users.deleteUserProfilePicture,
        saveUserProfile: users.saveUserProfile,
    }
)
@withTranslation('', { wait: true })

export default class UserProfilePage extends React.Component {
    deleteProfilePicture() {
        this.props.deleteUserProfilePicture(this.props.partner.id, this.props.user.id)
    }

    openFileUpload() {
        this.dropzone.open()
    }

    onDrop(files, props) {
        var file = files[0]

        var reader = new FileReader()
        reader.onload = function(upload) {
            const result = upload.target.result
            props.saveUserProfilePicture(props.partner.id, props.user.id, result).then(() => {
                props.getUser(props.partner.id, props.user.id)
            })
        }
        reader.readAsDataURL(file)
    }

    toggleProfileVisibility(checked) {
        this.props.saveUserProfile(this.props.partner.id, this.props.user.id, { is_visible: checked })
    }

    render() {
        const {
            t,
            user,
        } = this.props

        return (
            <Card>
                <Card.Content>
                    <PageTitle identifier="user_profile.title" />
                    <Card.Title>{t('user_profile.title')} ({user.firstName} {user.lastName})</Card.Title>
                    <Card.Intro>{t('user_profile.intro')}</Card.Intro>

                    <Flex modifiers={[ 'alignCenter' ]}>
                        <SwitchCheckboxInput
                            onClick={this.toggleProfileVisibility.bind(this, !user.isVisible)}
                            checked={user.isVisible}
                        />
                        <span>{t('user_profile.show_my_profile')}</span>
                    </Flex>

                    <Card.Line modifiers={[ 'mY_3', 'cardWidth' ]}/>
                    <Card.Title>{t('user_profile.picture.title')}</Card.Title>
                    <Card.Intro>{t('user_profile.picture.intro')}</Card.Intro>
                    <PictureBox
                        innerRef={node => {
                            this.dropzone = node
                        }}
                        onDrop={files => this.onDrop(files, this.props)}
                        activeClassName="dropzone-active"
                    >
                        <img src={user.profilePicture} />
                    </PictureBox>
                    <Button
                        onClick={this.openFileUpload.bind(this)}
                        modifiers={[ 'action', 'p_2', 'btnBlock' ]}
                    >
                        {t('user_profile.picture.upload')}
                    </Button>
                    <br />
                    <Button
                        onClick={this.deleteProfilePicture.bind(this)}
                        modifiers={[ 'secondary', 'p_2', 'btnBlock' ]}
                    >
                        {t('user_profile.picture.delete')}
                    </Button>

                    <Card.Line modifiers={[ 'mY_3', 'cardWidth' ]}/>
                    <UserEditForm />
                </Card.Content>
            </Card>
        )
    }
}
