import React from 'react'
import { Card } from '../../../../../components/blocks/Card'
import styled from 'styled-components'
import { Flex } from '../../../../../components/elements/Flex'
import { primaryColor } from '../../../../../theme/variables'

const SHOW_DATA_FIELDS = [
    'interview_question1',
    'interview_question2',
    'interview_question3',
]

const FormatQuote = styled.i`
  color: ${primaryColor};
  font-size: 32px;
  margin-right: 17px;
`

const FormatText = styled.div`
  line-height: 1.53;
  color: #5f6974
`

const Articale = styled.div`
  margin-top: 35px
`

export const InterviewQuestionsCard = ({
    partnerUserName,
    partner,
    t,
}) =>
    Boolean(Object.values(partner.attributes).find(attribute => attribute && SHOW_DATA_FIELDS.includes(attribute.identifier))) &&
    <Card>
        <Card.Content modifiers={[ 'pY_5', 'pX_6' ]}>
            <Card.Heading modifiers={[ 'primaryColor', 'mB_5' ]}>
                {t('quotes.quote.interview_with', { name: partnerUserName })}
            </Card.Heading>
            {Object.values(partner.attributes).map(attribute =>
                attribute && SHOW_DATA_FIELDS.includes(attribute.identifier) &&
                <Articale>
                    <Card.Label modifiers={[ 'strong', 'regularColor' ]}>{attribute.label}</Card.Label>
                    {attribute.selected.map(el =>
                        <Flex modifiers={[ 'mT_1' ]}>
                            <FormatQuote className="mdi mdi-format-quote-open"/>
                            <FormatText>{el.title}</FormatText>
                        </Flex>
                    )}
                </Articale>
            )}
        </Card.Content>
    </Card>
