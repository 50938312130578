import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import {
    markNotificationsAsRead,
    markAllNotificationsAsRead,
    markAllNotificationsAsSeen,
} from '../../actions/notifications'
import {
    currentPartnerUserSelector,
    currentPartnerSelector,
    currentClientSelector,
} from '../../selectors/auth'
import { numberOfUnseenNotificationsSelector } from '../../selectors/notifications'
import PageHeaderDropdown from './PageHeaderDropdown'
import styled from 'styled-components'
import { Icon } from './images'

const Notification = styled.li`
    padding: 15px;
    border-top: 1px solid $cardBorderColor;
    overflow: auto;
    cursor: pointer;
`

@connect(
    state => ({
        notifications: state.notifications.ids.map(id => state.entities.notifications[id]),
        partnerUser: currentPartnerUserSelector(state),
        client: currentClientSelector(state),
        numberOfUnseenNotifications: numberOfUnseenNotificationsSelector(state),
        lastFetch: state.notifications.lastFetch,
        partner: currentPartnerSelector(state),
    }),
    {
        markNotificationsAsRead,
        markAllNotificationsAsRead,
        markAllNotificationsAsSeen,
        push,
    }
)
@withTranslation('', { wait: true })
export default class NotificationDropdown extends React.Component {
    handleButtonClick() {
        if(this.props.numberOfUnseenNotifications) {
            this.props.markAllNotificationsAsSeen()
        }
    }

    renderMessageNotification(msg, index) {
        const { t } = this.props

        const time = moment(msg.createdAt)
        const customer = msg.trigger && msg.trigger.client
        if (!customer) {
            return null
        }

        return (
            <Notification key={'message_' + index + '_' + msg.id} className={`notification ${msg.readAt ? '' : 'unread'}`} onClick={() => this.handleMessageNotificationClick(msg)}>
                <div className="type">
                    <img src="/images/icon-notification-message.svg" alt=""/>
                </div>
                <div className="content">
                    <div className="heading">
                        {t('notifications.message_heading', { name: `${customer.firstName} ${customer.lastName}`, city: customer.geo.cityName })}
                    </div>
                    <div className="text">
                        {msg.subject?.message?.text?.substring(0, 100)}...
                    </div>
                    <div className="meta">
                        <div className="time">{time.fromNow()} ({time.format('HH:mm')})</div> &middot;{' '}
                        {msg.readAt ?
                            <div className="is-read">{t('notifications.is_read')}</div> :
                            <div className="mark-as-read" onClick={e => this.markAsRead(msg.id, e)}>{t('notifications.mark_one_read')}</div>
                        }
                    </div>
                </div>
            </Notification>
        )
    }

    handleMessageNotificationClick(msg) {
        // Set notification as read

        if(!msg.isRead) {
            this.props.markNotificationsAsRead([ msg.id ])
        }

        if(!msg.subject || !msg.subject.message) {
            return
        }

        if (msg.subject.message.projectId) {
            // Go to project communication
            this.props.push(`/projects/${msg.subject.message.projectId}/communication`)
        } else {
            // Go to project list
            this.props.push('/projects/')
        }

        // Close notification dropdown - Is there a better way?
        this.refs.dropdown.__wrappedComponent.toggle()
    }

    markAsRead(notificationId, e) {
        this.props.markNotificationsAsRead([ notificationId ])

        e.stopPropagation()
    }

    markAllAsRead() {
        const { markAllNotificationsAsRead, partnerUser } = this.props

        markAllNotificationsAsRead(partnerUser.id)
    }

    render() {
        const {
            notifications,
            numberOfUnseenNotifications,
            t,
        } = this.props

        return (
            <PageHeaderDropdown ref="dropdown" id="notifications-dropdown" illustration={<Icon modifiers={[ 'tiny' ]}>
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.998 21.5C13.3788 21.5 14.498 20.3807 14.498 19C14.498 18.6444 14.4238 18.3062 14.29 18H9.70607C9.57227 18.3062 9.49805 18.6444 9.49805 19C9.49805 20.3807 10.6173 21.5 11.998 21.5Z" stroke="#16BEFF" strokeWidth="1.8"/>
                    <path d="M12 3C8.41015 3 5.5 6.41015 5.5 10V13.3333C5.5 14.3825 5.00602 15.3705 4.16667 16C3.31243 16.6407 3.76554 18 4.83333 18H19.1667C20.2345 18 20.6876 16.6407 19.8333 16C18.994 15.3705 18.5 14.3825 18.5 13.3333V10C18.5 6.41015 15.5899 3 12 3Z" stroke="#16BEFF" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </Icon>} notifications={numberOfUnseenNotifications} onButtonClick={this.handleButtonClick.bind(this)}>
                <div className="header">
                    <div className="heading">
                        {t('notifications.heading')}
                    </div>
                    <div className="mark-all-read" onClick={this.markAllAsRead.bind(this)}>
                        {t('notifications.mark_all_read')}
                    </div>
                </div>
                <div className="main">
                    {notifications.length > 0 ?
                        <ul className="notification-list">
                            {notifications.map((msg, i) => this.renderMessageNotification(msg, i))}
                        </ul> :
                        <div style={{ padding: '15px' }}>{t('notifications.no_notifications')}</div>
                    }
                </div>
            </PageHeaderDropdown>
        )
    }
}
