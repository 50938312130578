import { normalize } from 'normalizr'
import { camelizeKeys, decamelizeKeys } from 'humps'
import * as loader from '../actions/loader'
import config from '../config'
import { simpleFetch } from '../fetch'

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
const callApi = (endpoint, options = {}, schema) => {
    const API_ROOT = config.serviceUrl

    var fullUrl = endpoint;

    if (
        !fullUrl.startsWith('http://')
        && !fullUrl.startsWith('https://')
        && !fullUrl.includes(API_ROOT)
    ) {
        fullUrl = API_ROOT + endpoint
    }

    return simpleFetch(fullUrl, {
        ...options,
        body: decamelizeKeys(options.body),
    })
        .then(response => {
            if (options && options.method === 'delete') {
                return {}
            }

            if (response.status === 204) {
                return {}
            }

            return response.json().then(json => {
                if (!response.ok) {
                    return Promise.reject(json)
                }
                if (!schema) {
                    return camelizeKeys(json)
                }
                const camelizedJson = json.data ?
                        camelizeKeys(json.data) :
                        camelizeKeys(json)

                return {
                    ...normalize(camelizedJson, schema),
                    pagination: {
                        page: json.page,
                        pages: json.pages,
                        total: json.total,
                        limit: json.limit,
                    },
                }
            })
        }
        )
}

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = 'Call API'

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default function apiMiddleware(store) {
    return next => action => {
        const callAPI = action ? action[CALL_API] : undefined
        if (callAPI === undefined) {
            return next(action)
        }

        let { endpoint } = callAPI
        const { schema, types, options, suppressLoader } = callAPI

        if (typeof endpoint === 'function') {
            endpoint = endpoint(store.getState())
        }

        if (typeof endpoint !== 'string') {
            throw new Error('Specify a string endpoint URL.')
        }

        if (!Array.isArray(types) || types.length !== 3) {
            throw new Error('Expected an array of three action types.')
        }
        if (!types.every(type => typeof type === 'string')) {
            throw new Error('Expected action types to be strings.')
        }

        const actionWith = data => {
            const finalAction = { ...action, ...data }
            delete finalAction[CALL_API]
            return finalAction
        }

        const [ requestType, successType, failureType ] = types
        next(actionWith({ type: requestType }))

        if(isNotGetRequest(options) && !suppressLoader) {
            next(loader.show())
        }

        return callApi(endpoint, options, schema).then(
            response => {
                next(actionWith({
                    response,
                    type: successType,
                }))

                if(isNotGetRequest(options) && !suppressLoader) {
                    next(loader.showSuccessful())
                }
                return Promise.resolve(response)
            },
            error => {
                if (isNotGetRequest(options) && !suppressLoader) {
                    next(loader.showFailed(error.message))
                }

                return Promise.reject(next(actionWith({
                    type: failureType,
                    error: error.message || 'Something bad happened',
                })))
            }
        )
    }
};

function isNotGetRequest(options) {
    return options && options.method && options.method !== 'get'
}
