import { CALL_API } from '../middleware/api'
import * as schemas from '../schemas'

export const GET_LOCATIONS = 'locations/GET_LOCATIONS'
export const GET_LOCATIONS_SUCCESS = 'locations/GET_LOCATIONS_SUCCESS'
export const GET_LOCATIONS_FAIL = 'locations/GET_LOCATIONS_FAIL'

/**
 * Get locations
 * @param location_id
 * @param geo_code
 * @param geo_point
 * @param slug
 * @param sort
 * @param is_primary
 * @param limit
 * @param page
 * @param query
 * @returns {any}
 */
export const getLocations = (query, geoCode) => ({
    [CALL_API]: {
        types: [ GET_LOCATIONS, GET_LOCATIONS_SUCCESS, GET_LOCATIONS_FAIL ],
        endpoint: '/locations',
        schema: schemas.locations,
        options: {
            body: {
                query,
                geo_code: geoCode,
            },
        },
    },
})
