import { css } from 'styled-components'
import { landscapeMobileBreakpoint, mobileBreakpoint } from '../../theme/variables'
const sizes = {
    desktop: 992,
    mobile: mobileBreakpoint,
    landscapeMobile: landscapeMobileBreakpoint,
    phone: 550,
}

export const mediaSize = limit => Object.keys(sizes).reduce((accumulator, label) => {
    const emSize = sizes[label] / 16
    accumulator[label] = (...args) => css`
        @media (${limit}-width: ${emSize}em) {
            ${css(...args)}
        }
    `
    return accumulator
}, {})

export const media = {
    max: mediaSize('max'),
    min: mediaSize('min'),
}
