import { fetchJSON, fetch } from '../fetch'
import config from '../config'
import moment from 'moment'
import queryString from 'query-string'
import i18n, { changeLanguage } from '../i18n'
import { handleError, logError } from './errors'
import { getPartner } from './partners'
import * as loader from './loader'
import { getCoupons } from './coupons'
import { replace } from 'connected-react-router'
import storage from 'local-storage-fallback'
import { CALL_API } from '../middleware/api'
import * as schemas from '../schemas'
import {
    currentPartnerUserSelector,
    currentPartnerSelector,
    currentClientSelector,
} from '../selectors/auth'
import {
    hasListPartnerCouponsAccessSelector,
} from '../selectors/access'
import { queryParametersSelector } from '../selectors/routing'
import { getProjectStatuses } from '../actions/projects'
import {
    getPartnerUserNotifications,
    getClientNotifications,
} from '../actions/notifications'
import { getAcceptedTerms } from './terms'
import { getDueNotPaidInvoices } from './invoices'
import { getPartnerContracts } from './partnercontracts'
import { getPartnerContractOfferGroups } from './partnercontractoffergroups'
import { redirectToBillingPage } from './billing'
import { CLIENT_LOG_IN, identifySegment, PARTNER_LOG_IN, sendSegmentData } from '../utils/segment'

export const LOGIN = 'auth/LOGIN'
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS'
export const LOGIN_FAIL = 'auth/LOGIN_FAIL'
export const LOGIN_USING_TOKEN_SUCCESS = 'auth/LOGIN_USING_TOKEN_SUCCESS'
export const LOGOUT = 'auth/LOGOUT'
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS'
export const REFRESH = 'auth/REFRESH'
export const REFRESH_SUCCESS = 'auth/REFRESH_SUCCESS'
export const REFRESH_FAIL = 'auth/REFRESH_FAIL'
export const START_REFRESH_INTERVAL_SUCCESS = 'auth/START_REFRESH_INTERVAL_SUCCESS'
export const STOP_REFRESH_INTERVAL_SUCCESS = 'auth/STOP_REFRESH_INTERVAL_SUCCESS'
export const GET_TOKEN_INFO = 'auth/GET_TOKEN_INFO'
export const GET_TOKEN_INFO_SUCCESS = 'auth/GET_TOKEN_INFO_SUCCESS'
export const GET_TOKEN_INFO_FAIL = 'auth/GET_TOKEN_INFO_FAIL'
export const GET_CURRENT_USER = 'auth/GET_CURRENT_USER'
export const GET_CURRENT_USER_SUCCESS = 'auth/GET_CURRENT_USER_SUCCESS'
export const GET_CURRENT_USER_FAIL = 'auth/GET_CURRENT_USER_FAIL'
export const GET_AUTH_PERMISSIONS = 'auth/GET_AUTH_PERMISSIONS'
export const GET_AUTH_PERMISSIONS_SUCCESS = 'auth/GET_AUTH_PERMISSIONS_SUCCESS'
export const GET_AUTH_PERMISSIONS_FAIL = 'auth/GET_AUTH_PERMISSIONS_FAIL'
export const CHANGE_PASSWORD = 'auth/CHANGE_PASSWORD'
export const CHANGE_PASSWORD_SUCCESS = 'auth/CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAIL = 'auth/CHANGE_PASSWORD_FAIL'
export const RESET_TIMEOUT = 'auth/RESET_TIMEOUT'
export const RESET_TIMEOUT_SUCCESS = 'auth/RESET_TIMEOUT_SUCCESS'
export const AUTHENTICATE_WITH_SIGNUP_CONFIRM_TOKEN = 'auth/AUTHENTICATE_WITH_SIGNUP_CONFIRM_TOKEN'
export const AUTHENTICATE_WITH_SIGNUP_CONFIRM_TOKEN_SUCCESS = 'auth/AUTHENTICATE_WITH_SIGNUP_CONFIRM_TOKEN_SUCCESS'
export const AUTHENTICATE_WITH_SIGNUP_CONFIRM_TOKEN_FAIL = 'auth/AUTHENTICATE_WITH_SIGNUP_CONFIRM_TOKEN_FAIL'
export const FINALIZE_SIGNUP = 'auth/FINALIZE_SIGNUP'
export const FINALIZE_SIGNUP_SUCCESS = 'auth/FINALIZE_SIGNUP_SUCCESS'
export const FINALIZE_SIGNUP_FAIL = 'auth/FINALIZE_SIGNUP_FAIL'

function isRefreshingTokenStorage() {
    return Boolean(storage.getItem('auth.refreshing_token'))
}

function lastTokenRefresh() {
    return storage.getItem('auth.last_token_refresh') ?
        moment(storage.getItem('auth.last_token_refresh')) :
        false
}

export const getCurrentPartnerUser = tokenInfo => (dispatch, getState) => dispatch({
    [CALL_API]: {
        types: [ GET_CURRENT_USER, GET_CURRENT_USER_SUCCESS, GET_CURRENT_USER_FAIL ],
        endpoint: `${config.serviceUrl}/partners/users/${getState().auth.partnerUserId || tokenInfo.user_id}`,
        schema: schemas.partnerUser,
    },
})

export const getCurrentClient = tokenInfo => (dispatch, getState) => dispatch({
    [CALL_API]: {
        types: [ GET_CURRENT_USER, GET_CURRENT_USER_SUCCESS, GET_CURRENT_USER_FAIL ],
        endpoint: `${config.serviceUrl}/clients/${getState().auth.clientId || tokenInfo.user_id}`,
        schema: schemas.client,
    },
})

function getTokenInfoSuccess(tokenInfo) {
    return dispatch => new Promise(resolve =>
        resolve(dispatch({ type: GET_TOKEN_INFO_SUCCESS, tokenInfo }))
    )
}

export function resetApp() {
    storage.clear()
    window.location.reload()
}

export function getTokenInfo() {
    return dispatch => {
        dispatch({ type: GET_TOKEN_INFO })
        return fetchJSON(`${config.serviceUrl}/auth/token`)
            .then(response => dispatch(getTokenInfoSuccess(response)))
            .catch(e => {
                dispatch({
                    type: GET_TOKEN_INFO_FAIL,
                })
                throw e
            })
    }
}

export const getAuthPermissions = () => dispatch => dispatch({
    [CALL_API]: {
        types: [ GET_AUTH_PERMISSIONS, GET_AUTH_PERMISSIONS_SUCCESS, GET_AUTH_PERMISSIONS_FAIL ],
        endpoint: '/auth/permissions',
        schema: schemas.permissions,
        options: {
            body: {
                limit: 10000,
            },
        },
    },
})

function startRefreshInterval(dispatch, getState) {
    const intervalId = setInterval(() => {
        if (getState().auth.refreshingToken) {
            return console.warn('Token already refreshing')
        }
        return dispatch(refreshTokenIfNeeded())
    }, 120 * 1000)
    dispatch({ type: START_REFRESH_INTERVAL_SUCCESS, intervalId })
}

const stopRefreshInterval = () => ({ type: STOP_REFRESH_INTERVAL_SUCCESS })

const loginUsingToken = (token, userId) => dispatch => {
    dispatch({
        type: LOGIN_USING_TOKEN_SUCCESS,
        token,
        userId,
    })
    dispatch(removeAuthParametersFromUrl())
}

const resetTimeout = () => dispatch => dispatch({
    type: RESET_TIMEOUT,
    timeoutId: setTimeout(() => dispatch({
        type: RESET_TIMEOUT_SUCCESS,
    }), 15000),
})

export const loginUsingClientToken = (token, userId) => dispatch =>
    new Promise(resolve => {
        dispatch({ type: LOGIN })
        if(userId) {
            resolve({ token, user_type: 'client' })
        } else {
            fetchJSON(`${config.serviceUrl}/auth`, {
                method: 'post',
                body: {
                    token,
                    user_type: 'client',
                },
            })
                .then(res => resolve(res))
        }
    })
        .then(response => dispatch(loginSuccess(response)))
        .then(response => dispatch(removeAuthParametersFromUrl()))
        .catch(e => {
            dispatch({ type: LOGIN_FAIL })
            throw e
        })

export const loadClientAuth = () => {
    return (dispatch, getState) => {
        dispatch(resetTimeout())
        let query = queryParametersSelector(getState())
        if (query['auth.token']) {
            return dispatch(loginUsingClientToken(query['auth.token'], query['auth.user_id']))
        }
        if (!getState().auth.token) {
            return Promise.reject(refreshFailLogout(dispatch))
        }

        return dispatch(getTokenInfo())
            .then(response => dispatch(getCurrentClient(response)))
            .then(response => dispatch(getAuthPermissions()))
            .then(() => {
                const client = currentClientSelector(getState())
                changeLanguage(client.geo.code)
                dispatch(getClientNotifications(client.id))
                identifySegment(client)
                sendSegmentData(client, CLIENT_LOG_IN)
            })
            .catch(e => {
                resetApp()
                throw e
            })
    }
}

function loginSuccess(response) {
    return dispatch => {
        dispatch(getTokenInfoSuccess(response))
        dispatch({ type: LOGIN_SUCCESS })
        if(response.user_type === 'client') {
           dispatch(loadClientAuth())
        }
        if(response.user_type === 'partner_user') {
            dispatch(loadPartnerAuth())
        }
    }
}

function removeAuthParametersFromUrl() {
    return (dispatch, getState) => {
        const query = queryParametersSelector(getState())
        return dispatch(replace({
            search: queryString.stringify(Object.assign({},
                ...Object.keys(query)
                    .filter(key => !key.startsWith('auth.'))
                    .map(key => ({
                        [key]: query[key],
                    }))
            )),
        }))
    }
}

export const login = (email, password) => dispatch => {
    dispatch({ type: LOGIN })
    return fetchJSON(`${config.serviceUrl}/auth`, {
        method: 'post',
        body: {
            email,
            password,
            user_type: 'partner',
        },
    })
        .then(response => dispatch(loginSuccess(response)))
        .catch(e => {
            dispatch({
                type: LOGIN_FAIL,
            })
            handleError(dispatch, e)
        })
}

export function logout() {
    return dispatch => {
        dispatch(stopRefreshInterval())
        return fetch(`${config.serviceUrl}/auth/token`, {
            method: 'delete',
        }).then(() => {
            dispatch({ type: LOGOUT_SUCCESS })
        }, () => {
            dispatch({ type: LOGOUT_SUCCESS })
        })
    }
}

function refreshSuccess(response) {
    return dispatch => {
        dispatch({ type: REFRESH_SUCCESS, token: response.token })
    }
}

function refreshFail(dispatch) {
    dispatch({ type: REFRESH_FAIL })
}

function refreshFailLogout(dispatch) {
    refreshFail(dispatch)
    dispatch(logout())
    // toastr.error('Refresh', 'You were signed out automatically due to an error')
}

export function loadPartnerAuth() {
    return (dispatch, getState) => {
        dispatch(resetTimeout())
        let query = queryParametersSelector(getState())
        if (query['auth.token'] && query['auth.user_id']) {
            dispatch(loginUsingToken(query['auth.token'], query['auth.user_id']))
        }
        if (!getState().auth.token) {
            return
        }
        startRefreshInterval(dispatch, getState)

        return dispatch(refreshTokenIfNeeded())
            .then(response => dispatch(getTokenInfo()))
            .then(response => dispatch(getCurrentPartnerUser(response)))
            .then(response => dispatch(getPartner(currentPartnerUserSelector(getState()).partnerId)))
            .then(response => dispatch(getAuthPermissions()))
            .then(response => {
                const partner = currentPartnerSelector(getState())
                const partnerUser = currentPartnerUserSelector(getState())
                if(hasListPartnerCouponsAccessSelector(getState())) {
                    dispatch(getCoupons(partner.id))
                }
                sendSegmentData(partner, PARTNER_LOG_IN)
                identifySegment(partner)
                changeLanguage(partner.geo.code)
                dispatch(getDueNotPaidInvoices(partner.id))
                dispatch(getAcceptedTerms(partner.id, config.showRequiredTermsPage[partner.geo.code].termId))
                dispatch(getProjectStatuses())
                dispatch(getPartnerUserNotifications(partnerUser.id))
                dispatch(getPartnerContracts(partner.id, { limit: 100, isArchived: '0' }))
                dispatch(getPartnerContractOfferGroups(partner.id, {}))
            })
    }
}

const refreshToken = () => dispatch => {
    dispatch({ type: REFRESH })
    return fetchJSON(`${config.serviceUrl}/auth/token`, {
        method: 'put',
    })
        .then(response => dispatch(refreshSuccess(response))).catch(e => {
            Promise.reject(refreshFailLogout(dispatch))
        })
}

function refreshTokenIfNeeded() {
    return dispatch => new Promise(resolve => {
        // Only refresh if the token was refreshed more than 5 minutes ago
        if (!lastTokenRefresh() || moment().diff(lastTokenRefresh(), 'minutes') > 5 && !isRefreshingTokenStorage()) {
            return resolve(dispatch(refreshToken()))
        }
        return resolve(this)
    })
}

export const changePassword = (currentPassword, newPassword) => dispatch => {
    dispatch({ type: CHANGE_PASSWORD })
    dispatch(loader.show())
    fetch(`${config.serviceUrl}/auth/password`, {
        method: 'put',
        body: {
            current_password: currentPassword,
            password: newPassword,
        },
    }).then(response => {
        if (!response.ok) {
            dispatch(loader.showFailed())
            dispatch({ type: CHANGE_PASSWORD_FAIL })
            return
        }
        dispatch(loader.showSuccessful())
        dispatch({ type: CHANGE_PASSWORD_SUCCESS })
    }).catch(e => {
        dispatch(loader.showFailed())
        dispatch({ type: CHANGE_PASSWORD_FAIL })
    })
}

export const sendPasswordReminder = email => dispatch => {
    return fetch(`${config.serviceUrl}/auth/passwordreminder`, {
        method: 'post',
        body: {
            email,
            consumer_type: 'partner_user',
        },
    })
}

export const resetPassword = (email, password, token) => dispatch => {
    return fetch(`${config.serviceUrl}/auth/password`, {
        method: 'post',
        body: {
            email,
            password,
            reset_token: token,
            consumer_type: 'partner_user',
        },
    })
}

export const authenticateUsingSignupConfirmToken = token => dispatch => dispatch({
    [CALL_API]: {
        types: [
            AUTHENTICATE_WITH_SIGNUP_CONFIRM_TOKEN,
            AUTHENTICATE_WITH_SIGNUP_CONFIRM_TOKEN_SUCCESS,
            AUTHENTICATE_WITH_SIGNUP_CONFIRM_TOKEN_FAIL,
        ],
        endpoint: '/auth',
        options: {
            method: 'post',
            body: {
                token,
                user_type: 'partner_sign_up',
            },
        },
    },
})

export const finalizeSignup = (email, userId, companyName, token, password, zipCode, phoneNumber) => dispatch => dispatch({
    [CALL_API]: {
        types: [
            FINALIZE_SIGNUP,
            FINALIZE_SIGNUP_SUCCESS,
            FINALIZE_SIGNUP_FAIL,
        ],
        endpoint: `${config.serviceUrl}/partnersignups/${userId}/actions`,
        options: {
            method: 'post',
            body: {
                action: 'convert_to_partner',
                company_name: companyName,
                signup_token: token,
                password,
                zip_code: zipCode,
                phone_number: phoneNumber,
            },
        },
    },
})

