import React from 'react'
import { FormGroup } from 'react-bootstrap'
import { Error } from '../Error'
import styled from 'styled-components'
import { cardBorderColor } from '../../../theme/variables'
import MetaLabel from '../../elements/text/MetaLabel'
import { withTranslation } from 'react-i18next'

const Textarea = styled.textarea`
    height: ${({ height }) => height ? height : 40}px;
    font-size: 16px;
    line-height: 27px;
    border: 1px solid ${cardBorderColor};
    border-radius: 3px;
    resize: none;
    transition: height 200ms ease;
    padding: 5px 11px;
    width: 100%;
    ${({ height }) => !height && `
        &:focus {
            height: 200px;
        }
    `}
`

const CounterLabel = styled.span`
    display: inline-block;
    float: right;
    font-size: 12px;
    font-weight: bold;
    color: #8F8F8F;
    text-transform: uppercase;
    text-align: right;
    margin-top: 5px;
`

@withTranslation('', { wait: true })
class TextareaFormGroup extends React.Component {
    state = {
        contentLength: 0,
        maxLength: 477,
    }

    componentDidMount() {
        this.setState({
            contentLength: this.props.input.value.length,
        })

        if (this.props.maxLength) {
            this.setState({
                maxLength: this.props.maxLength,
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.input.value &&
            (!prevProps.input.value || (prevProps.input.value.length != this.props.input.value.length))) {
            this.setState({
                contentLength: this.props.input.value.length,
            })
        }
    }

    countCharacters = e => {
        this.setState({
            contentLength: e.target.value.length,
        })
    }

    render() {
        const { className, input, label, meta, t, ...props } = this.props
        const { contentLength, maxLength } = this.state

        return <FormGroup controlId={input.name} bsClass={'form-group ' + className ? className : ''} style={{ marginBottom: '12px' }}>
            {label && <MetaLabel>{label}</MetaLabel>}
            {props.maxLength && <CounterLabel>{t('utils.textbox_characters')} ({maxLength - contentLength})</CounterLabel>}
            <Textarea {...input} {...props} onKeyUp={e => this.countCharacters(e)}/>
            <Error meta={meta}/>
        </FormGroup>
    }
}

export const TextareaGroup = styled(TextareaFormGroup)`
  width: 100%;
`
