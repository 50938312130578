import { ContainerFluid } from '../../../components/cards'
import { Flex } from '../../../components/elements/Flex'
import { Button } from '../../../components/elements/Button'
import { Card } from '../../../components/blocks/Card'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import CustomerChoiceClientInfoCard from '../../../components/pages/project/CustomerChoiceClientInfoCard'
import CustomerChoiceCaseInfoCard from '../../../components/pages/project/CustomerChoiceCaseInfoCard'

export default function CustomerChoiceQuotePage({
    lead,
    quote,
    partner,
    onAccept,
}) {
    const { t } = useTranslation();
    const history = useHistory();

    const disabled = !quote;

    const canAccept = quote && !disabled && !quote.publishedAt;

    return (
        <ContainerFluid>
            <form className={'quote-edit-form' + (disabled ? ' disabled' : '')}>
                <Row>
                    <Col md={12} lg={6}>
                        <CustomerChoiceClientInfoCard lead={lead} partner={partner} />
                    </Col>
                    <Col md={12} lg={6}>
                        <CustomerChoiceCaseInfoCard lead={lead} />
                    </Col>
                </Row>
                {(canAccept) && (
                    <Card>
                        <Card.Content>
                            <Card.Title>{t('quotes.creation.cc.confirm_title')}</Card.Title>

                            <Card.Intro>

                                <Row>
                                    <Col sm={3}>
                                    </Col>

                                    <Col sm={6}>
                                        {canAccept && (
                                            <Button
                                                modifiers={[
                                                    "action",
                                                    "p_2",
                                                    "fullWidth",
                                                ]}
                                                onClick={onAccept}
                                                disabled={disabled}
                                            >
                                                {t(
                                                    "quotes.creation.cc.accept_button"
                                                )}
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            </Card.Intro>
                        </Card.Content>
                    </Card>
                )}
            </form>
            <Flex modifiers={["justifyCenter", "mB_3"]}>
                <Button
                    onClick={() => history.goBack()}
                    modifiers={["primary", "p_1", "mR_2"]}
                >
                    <i className="mdi mdi-undo-variant" /> {t("quotes.go_back")}
                </Button>
            </Flex>
        </ContainerFluid>
    );
}
