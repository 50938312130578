import styled from 'styled-components'

const Column = styled.th`
    position: sticky;
    top: 0;
    z-index: 5;
    vertical-align: middle;
    height: 50px;
    padding: 0px 20px;
    line-height: 16px;
    background-color: #F8FAFC;
    color: #6F8289;
    text-transform: uppercase;
    font-size: 12px;
    &:after {
        content:'';
        position: absolute;
        pointer-events: none;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        border-top: 1px solid #E6EBF0;
        border-bottom: 1px solid #E6EBF0;
    }
    &:first-of-type:after {
        border-left: 1px solid #E6EBF0;
    }
    &:last-of-type:after {
        border-right: 1px solid #E6EBF0;
    }
    &:not(:first-of-type):before {
        content:'';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 16px;
        border-left: 1px solid #E6EBF0;
    }
    a {
        font-weight: bold;
        color: #6F8289;
        cursor: pointer;
    }
    &.number {
        text-align: right;
    }
`

export default Column
