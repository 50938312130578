import React from 'react'
import { connect } from 'react-redux'
import * as auth from '../../actions/auth'
import { bindActionCreators } from 'redux'
import { reduxForm, formValueSelector, change } from 'redux-form'
import { withTranslation } from 'react-i18next'
import PageTitle from '../utils/PageTitle'
import FBPixel from '../utils/FBPixel'
import styled from 'styled-components'
import { getLocations } from '../../actions/locations'
import PhoneNumber from 'components/src/inputs/PhoneNumber/index'
import { FormControl } from 'components/src/inputs/FormControl'
import countries from 'i18n-iso-countries'
import { isValidNumber } from 'libphonenumber-js'
import {Input} from 'components/src/inputs/Input'
import Button from 'components/src/Button'
import { Checkbox } from 'components/src/inputs/CheckboxSquared'
import Select from 'components/src/inputs/Select'
import { preferredLanguagesForIso6391 } from '../../i18n'
import Flex from 'components/src/layout/Flex'
import Heading from 'components/src/texts/Heading'
import { PARTNER_SIGN_UP, sendSegmentData } from '../../utils/segment'
import { currentPartnerSelector } from "../../selectors/auth";

const Separator = styled.div`
    width: 1px;
    height: 70px;
    background-color: #F1F5F7;
`

const FIELD_PASSWORD = 'password'
const FIELD_CONFIRM_PASSWORD = 'confirmPassword'
const FIELD_COMPANY_NAME = 'companyName'
const FIELD_TERMS_ACCEPTED = 'termsAccepted'
const ERROR_PASSWORDS_NOT_EQUAL = 'passwordsNotEqual'
const FIELD_SELECTED_LOCATION = 'selectedLocation'
const FIELD_PHONE_NUMBER = 'phoneNumber'

const selector = formValueSelector('signupConfirmForm')

@connect(
    (state, props) => ({
        email: state.auth.email,
        userId: state.auth.userId,
        geoCode: state.auth.geoCode,
        signupToken: props.match.params.signupToken,
        sessionToken: state.auth.token,
        authenticating: state.auth.authenticating,
        authenticatingFailed: state.auth.authenticatingFailed,
        finalizingSignup: state.auth.finalizingSignup,
        finalizingSignupSuccess: state.auth.finalizingSignupSuccess,
        finalizingSignupFailed: state.auth.finalizingSignupFailed,
        loggingIn: state.auth.loggingIn,
        loginFailed: state.auth.loginFailed,
        formValues: selector(state, 'password', 'confirmPassword', 'companyName', 'termsAccepted', 'selectedLocation', 'phoneNumber'),
        isLoadingLocations: state.pages.locations.isLoading,
        phoneCountries: countries.getNames(preferredLanguagesForIso6391[state.auth.geoCode], {select: 'alias'}),
        partner: currentPartnerSelector(state),
    }),
    dispatch => ({
        authenticateUsingSignupConfirmToken: bindActionCreators(auth.authenticateUsingSignupConfirmToken, dispatch),
        finalizeSignup: bindActionCreators(auth.finalizeSignup, dispatch),
        login: bindActionCreators(auth.login, dispatch),
        getLocations: bindActionCreators(getLocations, dispatch),
        change: bindActionCreators({ change }, dispatch),
    })
)
@reduxForm({
    form: 'signupConfirmForm',
    fields: [
        FIELD_PASSWORD,
        FIELD_CONFIRM_PASSWORD,
        FIELD_COMPANY_NAME,
        FIELD_TERMS_ACCEPTED,
        FIELD_SELECTED_LOCATION,
        FIELD_PHONE_NUMBER,
    ],
    initialValues: {
        [FIELD_PHONE_NUMBER]: '',
        [FIELD_COMPANY_NAME]: '',
        [FIELD_CONFIRM_PASSWORD]: '',
        [FIELD_PASSWORD]: '',
        [FIELD_TERMS_ACCEPTED]: false,
        [FIELD_SELECTED_LOCATION]: '',
    },
})
@withTranslation('', { wait: true })
export default class SignupConfirmPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            errors: [],
            availableLocations: [],
            locationSearchValue: '',
            phoneCountry: '',
        }
    }

    async componentDidMount() {
        await this.props.authenticateUsingSignupConfirmToken(
            this.props.signupToken
        )
        this.setState({phoneCountry: this.props.geoCode.toUpperCase()})
    }

    async submitForm() {
        const {
            password,
            companyName,
            selectedLocation: {
                zipCode,
            },
            phoneNumber,
        } = this.props.formValues

        const {
            email,
            userId,
            signupToken,
            finalizingSignupSuccess,
        } = this.props

        if (finalizingSignupSuccess === false) {
            await this.props.finalizeSignup(email, userId, companyName, signupToken, password, zipCode, phoneNumber)
        }

        await this.loginAndRedirect(email, password)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.partner === null && this.props.partner !== null) {
            sendSegmentData(this.props.partner, PARTNER_SIGN_UP)
            window.location.href = '/marketplace/preferences'
        }
    }

    async loginAndRedirect(email, password) {
        await this.props.login(email, password)

        const { loginFailed } = this.props

        if (loginFailed === true) {
            return
        }
    }

    getLocations = async query => {
        const { geoCode, getLocations } = this.props

        this.setState({ locationSearchValue: query.target.value })

        if (geoCode === '') {
            return
        }

        if (query.target.value.length < 3) {
            return
        }

        const response = await getLocations(query.target.value, geoCode)
        const locations = response.entities.locations

        if (locations == null) {
            this.setState({
                availableLocations: [],
            })

            return
        }

        const result = Object.values(locations)

        this.setState({
            availableLocations: result,
        })
    }

    resetLocation = async () => {
        await this.props.change(FIELD_SELECTED_LOCATION, null)
    }

    renderLocationItem = option => {
        return `${option.zipCode}, ${option.cityName}`
    }

    phoneItemToLabel = item => this.props.phoneCountries[item]

    phoneItemToCode = item => item

    onPhoneCountryChange = selected => {
        this.setState({ phoneCountry: selected })
    }

    onPhoneChange = phone => {
        this.props.change(FIELD_PHONE_NUMBER, phone)
    }

    onInputChange = current => {
        const {name, value} = current.target;
        this.props.change(name, value)
    }

    onAcceptedTermChange = current => {
        this.props.change(FIELD_TERMS_ACCEPTED, current.target.checked)
    }

    locationItemToLabel = item => {
        return item.zipCode + ', ' + item.cityName
    }

    onLocationChange = async item => {
        await this.props.change(FIELD_SELECTED_LOCATION, item)
    }

    validateForm = event => {
        event.preventDefault()

        this.setState({
            errors: [],
        }, () => {
            const errors = []

            const {
                password,
                confirmPassword,
                companyName,
                termsAccepted,
                selectedLocation,
                phoneNumber,
            } = this.props.formValues

            if (password === '') {
                errors.push(FIELD_PASSWORD)
            }

            if (confirmPassword === '') {
                errors.push(FIELD_CONFIRM_PASSWORD)
            }

            if (password !== confirmPassword) {
                errors.push(ERROR_PASSWORDS_NOT_EQUAL)
            }

            if (companyName === '') {
                errors.push(FIELD_COMPANY_NAME)
            }

            if (termsAccepted == null || termsAccepted === false) {
                errors.push(FIELD_TERMS_ACCEPTED)
            }

            if (selectedLocation === '') {
                errors.push(FIELD_SELECTED_LOCATION)
            }

            if (!phoneNumber || !isValidNumber(phoneNumber)) {
                errors.push(FIELD_PHONE_NUMBER)
            }

            this.setState({
                errors: [
                    ...this.state.errors,
                    ...errors,
                ],
            }, () => {
                if (this.state.errors.length === 0) this.submitForm()
            })
        })
    }

    render() {
        const {
            t,
            email,
            authenticating,
            authenticatingFailed,
            finalizingSignup,
            finalizingSignupSuccess,
            finalizingSignupFailed,
            loggingIn,
            loginFailed,
            formValues: {
                selectedLocation,
                phoneNumber,
                companyName,
                confirmPassword,
                password,
                termsAccepted
            },
        } = this.props


        const { errors, availableLocations, phoneCountry, locationSearchValue } = this.state

        const isSubmitButtonDisabled = () => {
            return finalizingSignup || authenticating || authenticatingFailed || finalizingSignup || loggingIn
        }

        const submitButtonText = () => {
            return finalizingSignupSuccess === true
                ? t('signup.retry_login')
                : t('signup.create_user')
        }

        return (
            <div className="card signup__card">
                <PageTitle identifier={t('signup.page_title')} />
                <form onSubmit={this.validateForm}>
                    <Flex mb={4} gap="2em" justifyContent="center" alignItems="center">
                        <Flex>
                            <img width="170px" src="/images/ageras-logo.svg"/>
                        </Flex>
                        <Separator />
                        <Heading size="medium" color="impact">{t('signup.create_marketplace_light_user')}</Heading>
                    </Flex>
                    {authenticatingFailed && <div className="alert alert-danger">{t('signup.token_authentication_failed')}</div>}
                    {finalizingSignupFailed && <div className="alert alert-danger">{t('signup.finalizing_signup_failed')}</div>}
                    {loginFailed && <div className="alert alert-danger">{t('signup.automatic_login_failed')}</div>}

                    <FormControl
                        label={t('signup.email')}
                    >
                        <Input
                            value={email}
                            disabled={true}
                            type="email"
                        />
                    </FormControl>
                    <FormControl label={t('signup.password')}>
                        <Input
                            placeholder={t('signup.password')}
                            value={password ?? ''}
                            disabled={finalizingSignupSuccess}
                            name="password"
                            type="password"
                            error={errors.includes(FIELD_PASSWORD)}
                            onChange={this.onInputChange}
                        />
                    </FormControl>
                    <FormControl
                        error={errors.includes(ERROR_PASSWORDS_NOT_EQUAL) ? t('signup.passwords_not_equal') : undefined}
                        label={t('signup.repeat_password')}
                    >
                        <Input
                            placeholder={t('signup.repeat_password')}
                            value={confirmPassword ?? ''}
                            disabled={finalizingSignupSuccess}
                            name="confirmPassword"
                            type="password"
                            error={errors.includes(ERROR_PASSWORDS_NOT_EQUAL)}
                            onChange={this.onInputChange}
                        />
                    </FormControl>
                    {phoneCountry && <FormControl
                        error={errors.includes(FIELD_PHONE_NUMBER) ? t('signup.phone_number_not_valid') : undefined}
                        label={t('signup.phone_number')}
                        className="phone-input">
                        <PhoneNumber
                            value={phoneNumber ?? ''}
                            name="phoneNumber"
                            placeholder="P"
                            selected={phoneCountry}
                            itemToCode={this.phoneItemToCode}
                            itemToLabel={this.phoneItemToLabel}
                            items={Object.keys(countries.getAlpha2Codes())}
                            onCountryChange={this.onPhoneCountryChange}
                            onChange={this.onPhoneChange}
                        />
                    </FormControl>}
                    <FormControl label={t('signup.company_name')}>
                        <Input
                            placeholder={t('signup.company_name')}
                            value={companyName ?? ''}
                            disabled={finalizingSignupSuccess}
                            name="companyName"
                            error={errors.includes(FIELD_COMPANY_NAME)}
                            onChange={this.onInputChange}
                        />
                    </FormControl>
                    <FormControl label={t('signup.search_locations_by_zip_code')} error={errors.includes(FIELD_SELECTED_LOCATION) ? t('signup.zip_code_not_selected') : undefined}>
                        <Select
                            placeholder={t('signup.search_locations_by_zip_code')}
                            items={availableLocations}
                            itemToLabel={this.renderLocationItem}
                            searchValue={locationSearchValue}
                            onSearchChange={this.getLocations}
                            onChange={this.onLocationChange}
                            selected={selectedLocation}
                            searchable
                        />
                    </FormControl>
                    <FormControl>
                        <Button variant="secondary" type="submit"
                                disabled={isSubmitButtonDisabled()}>{submitButtonText()}</Button>
                    </FormControl>
                    <FormControl
                        label={t('signup.accept_terms_text')}
                        error={errors.includes(FIELD_TERMS_ACCEPTED) ? t('signup.terms_not_accepted') : undefined}>
                        <Checkbox
                            name="termsAccepted"
                            value={termsAccepted ?? false}
                            width="350px"
                            onChange={this.onAcceptedTermChange}
                        />
                    </FormControl>
                </form>
                <FBPixel/>
            </div>
        )
    }
}
