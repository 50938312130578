import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

@connect(
    () => ({}),
    {
        changePageTitle: (title, pageId, backLinkName) =>
            ({ type: 'CHANGE_PAGE_TITLE', pageTitle: title, pageId, backLinkName }),
    }
)
@withTranslation('', { wait: true })
export default class PageTitle extends React.Component {
    static propTypes = {
        identifier: PropTypes.string,
        title: PropTypes.string,
    }

    componentDidMount() {
        this.updateTitle(this.props)
    }

    componentWillReceiveProps(newProps) {
        this.updateTitle(newProps)
    }

    updateTitle(props) {
        var {
            identifier,
            data = {},
            title,
            t,
            changePageTitle,
            backLinkName,
            pageId,
        } = props

        if (!title) {
            title = t(identifier, data)
        }

        if(document.title === title + t('base.page_title_suffix')) {
            return
        }

        document.title = title + t('base.page_title_suffix')

        changePageTitle(title, pageId, backLinkName)
    }

    render() {
        return null
    }
}
