import React, { Component } from 'react'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { withTranslation } from 'react-i18next'
import { FormGroup, HelpBlock, InputGroup } from 'react-bootstrap'
import { currentPartnerSelector } from '../../selectors/auth'
import { getInvoices } from '../../actions/invoices'
import { connect } from 'react-redux'

const renderField = ({ input, hidden, label, type, meta: { touched, error, warning } }) => (
    <div className={touched && error && 'has-error'} hidden={hidden}>
        <label>{label}</label>
        <InputGroup>
            <InputGroup.Addon>#</InputGroup.Addon>
            <input {...input} placeholder={label} type={type} className="form-control" />
        </InputGroup>
        {touched &&
        ((error &&
            <HelpBlock>
                {error}
            </HelpBlock>) ||
            (warning &&
                <HelpBlock>
                    {warning}
                </HelpBlock>))}
    </div>
)

@reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
})
@connect(
    state => ({
        partner: currentPartnerSelector(state),
        invoices: Object.values(state.entities.invoices),
    }),
    {
        getInvoices,
    }
)
@withTranslation('', { wait: true })
export default class RefundRequestFormInvoice extends Component {
    componentDidMount() {
        this.props.getInvoices({
            partnerId: this.props.partner.id,
        })
    }

    validate(values) {
        const invoice = this.props.invoices.find(invoice => invoice.invoiceNumber === Number(values.invoice.invoiceNumber))
        if (invoice) {
            Promise.resolve(this.props.change('invoice.id', invoice.id))
                .then(() => this.props.submit())
        } else {
            throw new SubmissionError({
                invoice: { invoiceNumber: this.props.t('refunds.request.invoice.intro') },
            })
        }
    }
    render() {
        const { handleSubmit, previousPage, t } = this.props
        return (
            <form onSubmit={handleSubmit(this.validate.bind(this))}>
                <p className="intro-text">
                    {t('refunds.request.invoice.intro')}
                </p>
                <FormGroup>
                    <Field name="invoice.invoiceNumber" type="number" component={renderField} label={t('refunds.request.invoice.input_label')} />
                    <div>
                        <br />
                        <button type="submit" className="next btn btn-action btn-lg btn-block">{t('refunds.request.lead.next')}</button>
                        <br />
                        <a onClick={previousPage} className="cursor-pointer">{t('refunds.request.lead.back')}</a>
                    </div>
                </FormGroup>
            </form>
        )
    }
}
