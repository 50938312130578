import React from 'react'
import { Card } from '../../blocks/Card'
import { Field } from 'redux-form'
import { TextareaGroup } from '../../forms/inputs/TextareaGroup'

export const CompanyInterviewQuestionsContent = ({ t, fields }) => {
    if(!fields) return null

    const dataFields = fields.getAll()

    return <div>
        <Card.Line />
        <Card.Content>
            <Card.Title>{t('company_profile.interview.title')}</Card.Title>
            <Card.Intro> {t('company_profile.interview.intro')}</Card.Intro>
            {fields.map((member, index) => {
                switch(dataFields[index].identifier) {
                    case 'interview_question1':
                    case 'interview_question2':
                    case 'interview_question3':
                        return (
                            <Field
                                label={dataFields[index].label}
                                name={`${member}.selected[0].title`}
                                component={TextareaGroup}
                                maxLength={450}
                            />
                        )
                    default:
                        return null
                }
            })}
        </Card.Content>
    </div>
}
