import React from 'react'
import { Card } from '../../../../../components/blocks/Card'
import styled from 'styled-components'
import { Flex } from '../../../../../components/elements/Flex'
import { primaryColor } from '../../../../../theme/variables'

const Icon = styled.i`
  color: ${primaryColor};
  font-size: 32px;
  margin-right: 25px;
`

export const SystemsCard = ({
   partner,
   t,
}) =>
    Object.values(partner.attributes).map(attribute => attribute && attribute.identifier === 'system_certification' &&
        <Card>
            <Card.Content modifiers={[ 'pY_5', 'pX_6' ]}>
                <Card.Heading modifiers={[ 'primaryColor', 'mB_5' ]}>
                    {t('partner.data_fields.accounting_systems_software')}
                </Card.Heading>
                <Card.Intro>{t('partner.data_fields.accounting_systems_software_intro')}</Card.Intro>
                {attribute.selected.map(el => <div key={'system_certification' + el.id}>
                    <Card.ListItem modifiers={[ 'pX_3', 'pY_2', 'mT_1' ]}>
                        <Flex modifiers={[ 'alignCenter' ]}>
                            <Icon className="mdi mdi-television"/> <span>{el.title}</span>
                        </Flex>
                    </Card.ListItem>
                </div>)}
            </Card.Content>
        </Card>
    )
