
import { CALL_API } from '../middleware/api'

export const CREATE_PUNCHABLE_ACTION = 'offers/CREATE_PUNCHABLE_ACTION'
export const CREATE_PUNCHABLE_ACTION_SUCCESS = 'offers/CREATE_PUNCHABLE_ACTION_SUCCESS'
export const CREATE_PUNCHABLE_ACTION_FAIL = 'offers/CREATE_PUNCHABLE_ACTION_FAIL'

export const createPunchableAction = (partnerId, leadId, action) => ({
    [CALL_API]: {
        types: [ CREATE_PUNCHABLE_ACTION, CREATE_PUNCHABLE_ACTION_SUCCESS, CREATE_PUNCHABLE_ACTION_FAIL ],
        endpoint: `/creditsystem/actions`,
        options: {
            method: 'post',
            body: {
                partner_id: partnerId,
                lead_id: leadId,
                action,
            },
        },
    },
})
