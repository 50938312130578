import React from 'react'
import moment from 'moment-timezone'

export default function FormattedDate({ date, format, timezone }) {
    date = moment(date)

    var isUs = false;
    if (timezone) {
        date.tz(timezone);
        if (timezone.includes('America')) {
            isUs = true
        }
    }

    switch (format) {
        case 'time':
            if (isUs) {
                date = date.format('LT')
            } else {
                date = date.format('HH:mm')
            }
            break
        case 'longtime':
            date = date.format('HH:mm:ss')
            break
        case 'longdatetime':
            date = date.format('L HH:mm:ss')
            break
        case 'relative':
            date = date.fromNow()
            break
        case 'calendar':
            date = date.calendar()
            break
        case 'datetime':
            date = date.format('L HH:mm')
            break
        case 'date':
        default:
            if (isUs) {
                date = date.format('L')
            } else {
                date = date.format('DD/MM/YYYY')
            }
    }

    return (
        <span>{ date }</span>
    )
}
