import { useCallback, useEffect, useState } from "react";
import useStripeSetupIntentRedirect from "./useStripeSetupIntentRedirect";
import { useDispatch } from "react-redux";
import { createSubscription, updatePaymentMethod } from "../../actions/cashier";
import {
    StripeRedirectCreateSubscription,
    StripeRedirectKey,
    StripeRedirectProductKey,
    StripeRedirectPromotionCode,
    StripeRedirectUpdatePaymentMethod
} from "./constants";
import { toastr } from 'react-redux-toastr'
import { useTranslation } from "react-i18next";

export default function useHandleStripePaymentMethodRedirect(partner){
    const [isLoading, setIsLoading] = useState(true);
    const [paymentMethodUpdated, setPaymentMethodUpdated] = useState(false);
    const [subscriptionCreated, setSubscriptionCreated] = useState(false);
    const [redirectChecked, setRedirectChecked] = useState(false);
    const { isLoading: isLoadingRedirect, isRedirect, setupIntentId } = useStripeSetupIntentRedirect();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const updatePayment = useCallback(async ()=>{
        try{
            await dispatch(updatePaymentMethod(partner, setupIntentId));
            setPaymentMethodUpdated(true);
            toastr.confirm(t('stripe_subscription_page.payment_method.update_success'));
        } catch {
            toastr.error(
                t('stripe_subscription_page.payment_method.update_error.title'),
                t('stripe_subscription_page.payment_method.update_error.description')
            );
        }
        setIsLoading(false);
    }, [dispatch, partner, setupIntentId, t]);

    const createStripeSubscription = useCallback(async(productId, promotionCode) => {
        if(productId){
            try{
                await dispatch(updatePaymentMethod(partner, setupIntentId));
                await dispatch(createSubscription(partner, productId, promotionCode));
                setSubscriptionCreated(true);
                toastr.confirm(t('stripe_subscription_page.subscription.create_success'));
            } catch {
                toastr.error(
                    t('stripe_subscription_page.subscription.create_error.title'),
                    t('stripe_subscription_page.subscription.create_error.description')
                );
             }
        }
        setIsLoading(false);
    }, [dispatch, partner, setupIntentId, t])

    useEffect(() => {
        if(!redirectChecked && !isLoadingRedirect){
            setRedirectChecked(true);
            if(isRedirect){
                const redirectKey = sessionStorage.getItem(StripeRedirectKey);
                if(redirectKey === StripeRedirectUpdatePaymentMethod){
                    updatePayment();
                }else if(redirectKey === StripeRedirectCreateSubscription){
                    createStripeSubscription(sessionStorage.getItem(StripeRedirectProductKey), sessionStorage.getItem(StripeRedirectPromotionCode));
                }else{
                    setIsLoading(false);
                }
                sessionStorage.removeItem(StripeRedirectKey);
                sessionStorage.removeItem(StripeRedirectProductKey);
                sessionStorage.removeItem(StripeRedirectPromotionCode);
            }else{
                setIsLoading(false);
            }
        }
    }, [isLoadingRedirect, redirectChecked, isRedirect, updatePayment, createStripeSubscription]);

    return { isLoading, paymentMethodUpdated, subscriptionCreated };
}
