import { RadioInput } from '../../elements/RadioInput'

export default RadioInput`
    position: absolute;
    opacity: 0;
    &:checked {
        + label:before {
            background-color: #3197EE;
            box-shadow: inset 0 0 0 4px #f4f4f4;
        }
    }
    
    &:focus {
       + label:before {
            outline: none;
            border-color: #3197EE;
        }
    }
    
    &:disabled {
       + label:before {
           box-shadow: inset 0 0 0 4px #f4f4f4;
           border-color: #ccc;
           background: #ccc;
       }
    }
`
