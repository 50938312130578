import styled from 'styled-components'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { MODIFIER_MARGIN_CONFIG, MODIFIER_PADDING_CONFIG } from '../modifiers/spaceModifiers'

const TabDropdown = styled.div`
    display: none;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.09);
    position: absolute;
    border-radius: 3px;
    color: #000;
    border: 1px solid #C7D7DD;
    z-index: 9999;
    width: max-content;

    ${({ open }) => open && 'display: flex;'}

    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)}
`

const Item = styled.div`
    padding: 5px 15px;
    cursor: pointer;

    &:hover {
        background-color: rgb(240, 240, 240);
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)}
`

TabDropdown.Item = Item

export default TabDropdown
