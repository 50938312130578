import React from 'react'
import { connect } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import PartnerProfileForm from '../partners/PartnerProfileForm'
import { currentPartnerSelector } from '../../selectors/auth'
import PageTitle from '../utils/PageTitle'
import * as actions from '../../actions/partners'
import Dropzone from 'react-dropzone'
import { toastr } from 'react-redux-toastr'
import { Card } from '../blocks/Card'
import { Button } from '../elements/Button'

@connect(
    state => ({
        partner: currentPartnerSelector(state),
    }),
    {
        savePartnerLogo: actions.savePartnerLogo,
        deletePartnerLogo: actions.deletePartnerLogo,
        getPartner: actions.getPartner,
    }
)
@withTranslation('', { wait: true })
export default class ProfilePage extends React.Component {
    openFileUpload() {
        this.dropzone.open()
    }

    deletePartnerLogo() {
        if (window.confirm(this.props.t('company_profile.logo_delete.prompt'))) {
            this.props.deletePartnerLogo(this.props.partner.id).then(() => {
                this.props.getPartner(this.props.partner.id).then(() => {
                    toastr.success(this.props.t('company_profile.logo_delete.header'), this.props.t('company_profile.logo_delete.description'))
                })
            })
        }
    }

    onDrop(files, props) {
        var file = files[0]

        var reader = new FileReader()
        reader.onload = function(upload) {
            const result = upload.target.result
            props.savePartnerLogo(props.partner.id, result).then(() => {
                props.getPartner(props.partner.id).then(() => {
                    toastr.success(props.t('company_profile.logo_updated.header'), props.t('company_profile.logo_updated.description'))
                })
            })
        }
        reader.readAsDataURL(file)
    }

    render() {
        const { partner, t } = this.props
        return (
            <Card>
                <PageTitle identifier="company_profile.title" />
                <Card.Content>
                    <h2>{t('company_profile.title')}</h2>
                    <Card.Intro>{t('company_profile.intro')}</Card.Intro>

                    <Card.Line modifiers={[ 'mY_3', 'cardWidth' ]} />
                    <Card.Title>{t('company_profile.logo.title')}</Card.Title>
                    <Card.Intro>{t('company_profile.logo.intro')}</Card.Intro>
                    <Dropzone
                        ref={node => this.dropzone = node }
                        onDrop={files => this.onDrop(files, this.props)}
                        className="dropzone"
                        activeClassName="dropzone-active"
                    >
                        {partner.logo ?
                            <img className="uploaded-logo" src={partner.logo} /> :
                            <Row>
                                <Col sm={6}>
                                    <Button
                                        modifiers={[ 'action', 'p_2', 'btnBlock', 'fullWidth' ]}
                                    >
                                        {t('company_profile.logo.upload')}
                                    </Button>
                                </Col>
                            </Row>
                        }
                    </Dropzone>
                    {partner.logo &&
                    <Row>
                        <Col sm={6}>
                            <Button
                                modifiers={[ 'secondary', 'p_2', 'fullWidth' ]}
                                onClick={this.deletePartnerLogo.bind(this)}
                            >
                                {t('company_profile.logo.delete')}
                            </Button>
                        </Col>
                        <Col sm={6}>
                            <Button
                                onClick={this.openFileUpload.bind(this)}
                                modifiers={[ 'action', 'p_2', 'fullWidth' ]}
                            >
                                {t('company_profile.logo.upload')}
                            </Button>
                        </Col>
                    </Row>}
                </Card.Content>

                <Card.Line />
                <PartnerProfileForm />
            </Card>
        )
    }
}
