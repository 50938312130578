import React from 'react'
import config from '../../config'

export default class ChatWidget extends React.Component {
    componentWillMount() {
        const {
            user,
            geoCode,
        } = this.props

        const dixaId = config.dixa[geoCode]

        // eslint-disable-next-line no-unused-expressions
        ! function(e, t, i, s, n, a, r, d) {
            e[n] = e[n] || function() {
                (e[n].q = e[n].q || []).push(arguments)
                null !== t.getElementById("dixa-widget-wrapper") && t.getElementById("dixa-widget-wrapper").contentWindow.postMessage(JSON.stringify(arguments), "*")
            }
            e[n].l = 1 * new Date()
            e[n].wid = dixaId
            r = t.createElement("script")
            d = t.getElementsByTagName("script")[0]
            r.async = 1
            r.setAttribute("charset", "utf-8")
            r.src = "https://widget.dixa.io/assets/scripts/javascript/loader.js"
            d.parentNode.insertBefore(r, d)
        }(window, document, 0, 0, "_dixa")

        window._dixa('api.setUser', user.firstName + ' ' + user.lastName, user.email)
    }

    render() {
        return null
    }
}
