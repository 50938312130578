import React from 'react'
import Dropdown from './styles'
import { isArray } from 'lodash'
import { Flex } from '../Flex'
import { CheckboxBox } from '../../forms/inputs_new/CheckboxInput'
import { Text } from '../text/Text'

export default class DropdownElement extends React.Component {
    isSelected = option => {
        const { selected, name } = this.props
        if (!isArray(selected[name])) {
            return selected[name] === (option.id || option.name)
        } else {
            return selected[name].includes(String(option.id))
        }
    }

    handleMultiValueChange = option => {
        const { selected, name, onSelect } = this.props
        if (this.isSelected(option)) {
            const values = [ ...selected[name] ]
            const filtered = values.filter(value => value !== String(option.id || option.name))
            onSelect({ [name]: filtered })
        } else {
            onSelect({ [name]: [ ...selected[name], option.id || option.name ] })
        }
    }

    render() {
        const { multi, options, selected, children, onSelect, name } = this.props

        return <Dropdown>
            {children}
            <Dropdown.Content>
                <Flex modifiers={[ 'directionColumn' ]}>
                    {Boolean(options.length) && options.filter(option => !option.hidden).map(option => <Dropdown.Option
                        onClick={() => multi ? this.handleMultiValueChange(option) : onSelect(option.id || option.name)}
                        modifiers={[ 'alignCenter', 'p_1' ]}
                        active={this.isSelected(option)}
                    >
                        {multi && <CheckboxBox
                            value={option.id}
                            checked={selected[name].includes(String(option.id))}
                            onChange={() => this.handleMultiValueChange(option)}
                        />}
                        {!multi && <input
                            type="radio"
                            value={option.id || option.name}
                            checked={selected[name] === option.name}
                            onChange={() => onSelect(option.id || option.name)}
                        />}
                        <Text modifiers={[ 'mL_3', 'small' ]}>{option.displayName || option.name || option.title || option.label}</Text>
                    </Dropdown.Option>)}
                </Flex>
            </Dropdown.Content>
        </Dropdown>
    }
}
