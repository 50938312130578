import { GET_QUOTES, GET_QUOTES_SUCCESS, GET_QUOTES_FAIL, } from '../../actions/quotes'
const initialState = {
    quotesId: [],
    isLoading: false,
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_QUOTES:
            return {
                ...state,
                isLoading: true,
            }
        case GET_QUOTES_SUCCESS:
            return {
                ...state,
                quotesId: action.response.result,
                isLoading: false,
            }
        case GET_QUOTES_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state
    }
}
