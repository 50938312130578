import { CALL_API } from '../middleware/api'

export const CREATE_BROWSER_PUSH_SUBSCRIPTION = 'partners/CREATE_BROWSER_PUSH_SUBSCRIPTION'
export const CREATE_BROWSER_PUSH_SUBSCRIPTION_SUCCESS = 'partners/CREATE_BROWSER_PUSH_SUBSCRIPTION_SUCCESS'
export const CREATE_BROWSER_PUSH_SUBSCRIPTION_FAIL = 'partners/CREATE_BROWSER_PUSH_SUBSCRIPTION_FAIL'

export const createBrowserPushSubscription = (clientId, body) => dispatch => dispatch({
    [CALL_API]: {
        types: [ CREATE_BROWSER_PUSH_SUBSCRIPTION, CREATE_BROWSER_PUSH_SUBSCRIPTION_SUCCESS, CREATE_BROWSER_PUSH_SUBSCRIPTION_FAIL ],
        endpoint: `/clients/${clientId}/browserpushsubscriptions`,
        options: {
            method: 'post',
            body,
        },
        suppressLoader: true,
    },
})
