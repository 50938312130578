import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import Portrait from '../Portrait'
import { currentPartnerUserSelector } from '../../../selectors/auth'
import nl2br from '../../utils/Nl2br'
import FileItem from './FileItem'

@connect(
    state => ({
        user: currentPartnerUserSelector(state),
    })
)
@withTranslation('', { wait: true })
export default class Message extends React.Component {

    render() {
        const {
            t,
            message: {
                type,
                createdAt,
                author: {
                    name,
                    picture,
                    email,
                },
                content,
                attachments,
            },
        } = this.props
        const date = moment(createdAt)

        return (
            <div className="project-message">
                <div className="header">
                    <Portrait
                        name={name}
                        hash={email}
                        image={picture}
                        size={32}
                        style={{
                            float: 'left',
                            marginLeft: '5px',
                            marginRight: '15px',
                        }}
                    />
                    <div className="meta">
                        { type == 'note' && t('projects.feed.types.note', { name })
                        }{ type == 'conversation' && t('projects.feed.types.message_from', { name })
                        }{ type != 'note' && type != 'conversation' && name
                        } &middot;&nbsp;
                        {date.format('HH.mm')} &middot; {date.format('L')}
                    </div>
                </div>
                <div className="body">
                    {nl2br(content)}
                    {attachments && <div className="attachments">
                        <hr />
                        {attachments.map(f => <FileItem file={f} />)}
                    </div>}
                </div>
            </div>
        )
    }
}
