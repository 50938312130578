import React from 'react'
import FormattedDate from '../../utils/FormattedDate'
import FormattedAmount from '../../utils/FormattedAmount'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { BtnLink } from '../../elements/Links'

@withTranslation('', { wait: true })
export default class UnpaidInvoiceItem extends React.Component {
    render() {
        const { invoice, t } = this.props

        const totalExcludingVat = invoice.amounts.totalExcludingVat

        return (
            <tr>
                <td className="table-mobile-cell">
                    <table className="definition-list">
                        <tbody>
                            <tr>
                                <td>{ t('invoices.number') }:</td>
                                <td><Link to={ '/invoices/' + invoice.id }>{t(`invoices.type.${invoice.type}`)} #{invoice.invoiceNumber}</Link></td>
                            </tr>
                            <tr>
                                <td>{ t('invoices.date') }:</td>
                                <td><FormattedDate date={invoice.createdAt} format="date" /></td>
                            </tr>
                            <tr>
                                <td>{ t('invoices.due_date') }:</td>
                                <td><FormattedDate date={invoice.dueAt} format="date" /></td>
                            </tr>
                            <tr>
                                <td>{ t('invoices.amount') }:</td>
                                <td><FormattedAmount amount={totalExcludingVat.amount} currency={totalExcludingVat.currency} /></td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                <td>
                    <BtnLink to={ '/invoices/' + invoice.id } modifiers={[ 'primary', 'nowrap' ]}>
                        {t('payment_page.go_to_payment_button')}
                    </BtnLink>
                </td>
                <td><Link to={ '/invoices/' + invoice.id }>{t(`invoices.type.${invoice.type}`)} #{invoice.invoiceNumber}</Link></td>
                <td><FormattedDate date={invoice.createdAt} format="date" /></td>
                <td><FormattedDate date={invoice.dueAt} format="date" /></td>
                <td>{invoice.invoiceLines[0].itemName}</td>
                <td className="amount"><FormattedAmount amount={totalExcludingVat.amount} currency={totalExcludingVat.currency} /></td>
            </tr>
        )
    }
}
