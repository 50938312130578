import { createSelector } from 'reselect'
import { getLeadQuoteByPartnerIdSelector } from './auth'
import { LeadClientExperimentResource } from 'ageras-api-client/src/api'

export const quotesSelector = state => state.entities.quotes
const projectsReservedQuotesId = state => state.pages.projects.reservedQuotesId

export const partnerLeadQuoteSelector = createSelector(
    quotesSelector,
    quotes => (partnerId, leadId) => Object.values(quotes).find(
        quote => parseInt(quote.partner.id) === parseInt(partnerId) && parseInt(quote.leadId) === parseInt(leadId)
    )
)

export const isQuoteClientCustomerChoiceSelector = createSelector(
    state => state.entities.quotes,
    () => true,
)

export const quoteByProject = createSelector(
    quotesSelector,
    quotes => project => project && quotes[project.quote]
)

export const reservedProjectsQuotesSelector = createSelector(
    quotesSelector, projectsReservedQuotesId,
    (quotes, reservedQuotesId) => reservedQuotesId.map(id => quotes[id]),
)
