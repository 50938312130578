import React, { Component } from 'react'
import styled from 'styled-components'
import { Flex } from '../elements/Flex'
import { Text } from '../elements/text/Text'
import { Button } from '../elements/ButtonNew'
import Box from '../blocks/Box'

const Popup = styled.div`
    position: fixed;
    top: 24px;
    right: 24px;
    max-width: 420px;
    z-index: 99;
`

export default class AccessibilityPrompt extends Component {
    state = {
        accessibility: false,
        accessibilityAsked: false,
    }

    componentDidMount() {
        const accessOptIn = localStorage.getItem('accessibilityHelp')
        const accessOptInAsked = localStorage.getItem('accessibilityHelpAsked')

        if (accessOptIn) {
            this.confirmOptIn()
        }

        if (accessOptInAsked) {
            this.confirmOptInAsked()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.accessibility !== this.state.accessibility && this.state.accessibility) {
            this.renderAccessibilityHelp()
        }
    }

    renderAccessibilityHelp = () => {
        const script = document.createElement('script')

        script.innerHTML = '(function(d){var s = d.createElement("script");s.setAttribute("data-account", "aOCckEvFCp");s.setAttribute("src", "https://cdn.userway.org/widget.js");(d.body || d.head).appendChild(s);})(document)'

        document.querySelector('body').appendChild(script)
    }

    confirmOptIn = () => this.setState({ accessibility: true })

    confirmOptInAsked = () => this.setState({ accessibilityAsked: true })

    setApprovalCookie = () => {
        localStorage.setItem('accessibilityHelp', true)
        this.confirmOptIn()
        this.setAskedCookie()
    }

    setAskedCookie = () => {
        localStorage.setItem('accessibilityHelpAsked', true)
        this.confirmOptInAsked()
    }

    render() {
        const { accessibility, accessibilityAsked } = this.state

        if (accessibility || accessibilityAsked) {
            return null
        }

        return <Popup>
            <Box modifiers={[ 'p_2' ]}>
                <Flex modifiers={[ 'alignCenter', 'justifySpaceBetween' ]}>
                    <Text modifiers={[ 'small' ]}>
                        Do you want to turn on accessibility help?
                    </Text>
                    <Button
                        modifiers={[ 'action', 'small', 'bottomLeft', 'inline', 'bold', 'nowrap' ]}
                        onClick={this.setApprovalCookie}
                    >
                        Turn on
                    </Button>
                    <Button
                        modifiers={[ 'primary', 'small', 'bottomLeft', 'inline', 'bold', 'mL_1', 'nowrap' ]}
                        onClick={this.setAskedCookie}
                    >
                        No, thank you
                    </Button>
                </Flex>
            </Box>
        </Popup>
    }
}
