import React from 'react'
import Message from './Message'

export default class ConversationMessage extends React.Component {

    render() {
        const {
            message: {
                createdAt,
                text,
                participant,
                attachments,
            },
        } = this.props

        return (
            <div className="conversation-message">
                <Message
                    message={{
                        type: 'conversation',
                        author: participant,
                        createdAt,
                        content: text,
                        attachments: attachments,
                    }}
                />
            </div>
        )
    }
}
