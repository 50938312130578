import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import * as billingActions from '../../actions/billing'
import { currentPartnerSelector } from '../../selectors/auth'

@connect(
    state => ({
        epay: state.pages.billing.epay,
        partner: currentPartnerSelector(state),
    }),
    dispatch => ({
        getConfig: bindActionCreators(billingActions.getWindowConfig, dispatch),
        unmountPayment: bindActionCreators(billingActions.unmountPaymentWindow, dispatch),
    })
)
@withTranslation('', { wait: true })
export default class EpayPaymentWindow extends React.Component {

    static propTypes = {
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        placeholderId: PropTypes.string.isRequired,
    }

    componentDidMount() {
        this.getConfig()
    }

    componentWillUnmount() {
        this.props.unmountPayment()
    }

    getConfig() {
        const { partner, getConfig } = this.props
        const { origin } = window.location
        const returnPath = this.props.returnPath ? this.props.returnPath : '/subscription/billing'
        const returnUrl = `${origin}${returnPath}`
        getConfig(partner, {
            subscription: true,
            windowstate: 4,
            iframeheight: this.props.height,
            iframewidth: this.props.width,
            accepturl: returnUrl,
            cancelurl: returnUrl,
            //cssurl: '',
        })
    }

    componentWillReceiveProps(newProps) {
        if(this.props.epay === null && newProps.epay) {
            this.openPaymentWindow(newProps.epay)
        }
    }

    openPaymentWindow(data) {
        var paymentwindow = new window.PaymentWindow({
            ...data,
            subscription: data.subscription ? 1 : 0,
            paymentcollection: data.paymentcollection ? 1 : 0,
            //currency: 'DKK',
        })

        paymentwindow.append(this.props.placeholderId)
        paymentwindow.open()
    }

    render() {
        const {
            placeholderId,
            width,
            height,
        } = this.props

        return (
            <div id={placeholderId} style={{ width, height }} />
        )
    }
}
