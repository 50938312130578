/**
 Where property is one of:
    *m - for classes that set margin
    *p - for classes that set padding
 ------------------------------------------------------
 Where sides is one of:
    *t - for classes that set margin-top or padding-top
    *b - for classes that set margin-bottom or padding-bottom
    *l - for classes that set margin-left or padding-left
    *r - for classes that set margin-right or padding-right
    *x - for classes that set both *-left and *-right
    *y - for classes that set both *-top and *-bottom
    *blank - for classes that set a margin or padding on all 4 sides of the element
**/

const spaceReducer = (property, att1, att2) =>
    [ 0, 1, 2, 3, 4, 5, 6 ].reduce((acc, cur) => ({
        ...acc,
        [property + cur]: () => `${att1}: ${cur * 10}px; ${att2 ? `${att2}: ${cur * 10}px;` : ''}`,
    }), {})

export const MODIFIER_MARGIN_CONFIG = {
    ...spaceReducer('mT_', 'margin-top'),
    ...spaceReducer('mB_', 'margin-bottom'),
    ...spaceReducer('mL_', 'margin-left'),
    ...spaceReducer('mR_', 'margin-right'),
    ...spaceReducer('m_', 'margin'),
    ...spaceReducer('mX_', 'margin-right', 'margin-left'),
    ...spaceReducer('mY_', 'margin-top', 'margin-bottom'),
    mAuto: () => 'margin: auto;',
}

export const MODIFIER_PADDING_CONFIG = {
    ...spaceReducer('pT_', 'padding-top'),
    ...spaceReducer('pB_', 'padding-bottom'),
    ...spaceReducer('pL_', 'padding-left'),
    ...spaceReducer('pR_', 'padding-right'),
    ...spaceReducer('p_', 'padding'),
    ...spaceReducer('pX_', 'padding-right', 'padding-left'),
    ...spaceReducer('pY_', 'padding-top', 'padding-bottom'),
}
