import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'
import { decamelizeKeys } from 'humps'

export const GET_USERS = 'users/GET_USERS'
export const GET_USERS_SUCCESS = 'users/GET_USERS_SUCCESS'
export const GET_USERS_FAIL = 'users/GET_USERS_FAIL'

export const GET_USER = 'users/GET_USER'
export const GET_USER_SUCCESS = 'users/GET_USER_SUCCESS'
export const GET_USER_FAIL = 'users/GET_USER_FAIL'

export const SAVE_USER_PROFILE_PICTURE = 'users/SAVE_USER_PROFILE_PICTURE'
export const SAVE_USER_PROFILE_PICTURE_SUCCESS = 'users/SAVE_USER_PROFILE_PICTURE_SUCCESS'
export const SAVE_USER_PROFILE_PICTURE_FAIL = 'users/SAVE_USER_PROFILE_PICTURE_FAIL'

export const DELETE_USER_PROFILE_PICTURE = 'users/DELETE_USER_PROFILE_PICTURE'
export const DELETE_USER_PROFILE_PICTURE_SUCCESS = 'users/DELETE_USER_PROFILE_PICTURE_SUCCESS'
export const DELETE_USER_PROFILE_PICTURE_FAIL = 'users/DELETE_USER_PROFILE_PICTURE_FAIL'

export const SAVE_USER_PROFILE = 'users/SAVE_USER_PROFILE'
export const SAVE_USER_PROFILE_SUCCESS = 'users/SAVE_USER_PROFILE_SUCCESS'
export const SAVE_USER_PROFILE_FAIL = 'users/SAVE_USER_PROFILE_FAIL'

export const SAVE_PARTNER_USER = 'users/SAVE_PARTNER_USER'
export const SAVE_PARTNER_USER_SUCCESS = 'users/SAVE_PARTNER_USER_SUCCESS'
export const SAVE_PARTNER_USER_FAIL = 'users/SAVE_PARTNER_USER_FAIL'

export const DELETE_PARTNER_USER = 'users/DELETE_PARTNER_USER'
export const DELETE_PARTNER_USER_SUCCESS = 'users/DELETE_PARTNER_USER_SUCCESS'
export const DELETE_PARTNER_USER_FAIL = 'users/DELETE_PARTNER_USER_FAIL'

export const getUsers = (partner, page = 1) => dispatch => dispatch({
    [CALL_API]: {
        types: [ GET_USERS, GET_USERS_SUCCESS, GET_USERS_FAIL ],
        endpoint: `/partners/${partner.id}/users`,
        schema: schemas.partnerUsers,
        options: {
            body: {
                page,
                per_page: 10000,
            },
        },
    },
})

export const getPartnersUsers = (partnerIds, page = 1) => ({
    [CALL_API]: {
        types: [ GET_USERS, GET_USERS_SUCCESS, GET_USERS_FAIL ],
        endpoint: '/partners/users',
        schema: schemas.partnerUsers,
        options: {
            body: {
                partnerId: partnerIds,
                page,
                per_page: 10000,
            },
        },
    },
})

export const getUser = (partnerId, userId) => dispatch => dispatch({
    [CALL_API]: {
        types: [ GET_USER, GET_USER_SUCCESS, GET_USER_FAIL ],
        endpoint: `/partners/${partnerId}/users/${userId}`,
        schema: schemas.partnerUser,
    },
})

export const deleteUserProfilePicture = (partnerId, partnerUserId) => dispatch => dispatch({
    [CALL_API]: {
        types: [ DELETE_USER_PROFILE_PICTURE, DELETE_USER_PROFILE_PICTURE_SUCCESS, DELETE_USER_PROFILE_PICTURE_FAIL ],
        endpoint: `/partners/${partnerId}/users/${partnerUserId}/contents/logo`,
        schema: schemas.partnerUser,
        options: {
            method: 'delete',
        },
    },
})

export const saveUserProfilePicture = (partnerId, partnerUserId, pictureData) => dispatch => dispatch({
    [CALL_API]: {
        types: [ SAVE_USER_PROFILE_PICTURE, SAVE_USER_PROFILE_PICTURE_SUCCESS, SAVE_USER_PROFILE_PICTURE_FAIL ],
        endpoint: `/partners/${partnerId}/users/${partnerUserId}/contents/logo`,
        schema: schemas.partnerUser,
        options: {
            method: 'put',
            body: {
                key: 'logo',
                value: pictureData,
            },
        },
    },
})

export const saveUserProfile = (partnerId, partnerUserId, data) => dispatch => dispatch({
    [CALL_API]: {
        types: [ SAVE_USER_PROFILE, SAVE_USER_PROFILE_SUCCESS, SAVE_USER_PROFILE_FAIL ],
        endpoint: `/partners/${partnerId}/users/${partnerUserId}`,
        schema: schemas.partnerUser,
        options: {
            method: 'put',
            body: decamelizeKeys(data),
        },
    },
})

export const createPartnerUser = (partnerId, data) => dispatch => dispatch({
    [CALL_API]: {
        types: [ SAVE_PARTNER_USER, SAVE_PARTNER_USER_SUCCESS, SAVE_PARTNER_USER_FAIL ],
        endpoint: `/partners/${partnerId}/users`,
        schema: schemas.partnerUser,
        options: {
            method: 'post',
            body: decamelizeKeys(data),
        },
    },
})

export const deletePartnerUser = (partnerId, partnerUserId) => dispatch => dispatch({
    [CALL_API]: {
        types: [ DELETE_PARTNER_USER, DELETE_PARTNER_USER_SUCCESS, DELETE_PARTNER_USER_FAIL ],
        endpoint: `/partners/${partnerId}/users/${partnerUserId}`,
        schema: schemas.partnerUser,
        options: {
            method: 'delete',
        },
    },
})
