import React from 'react'
import PropTypes from 'prop-types'

export default class RatingStarBox extends React.Component {

    static defaultProps = {
        stars: 0,
    }

    static propTypes = {
        stars: PropTypes.number,
    }

    _renderStar(number) {
        const className = 'ap-star ' + (number <= this.props.stars ? 'checked' : '')

        return <span className={className} />
    }

    render() {
        const { text, checked } = this.props

        const star = <span className="ap-star" />

        return (
            <div className="rating-star-box">
                {this._renderStar(1)}
                {this._renderStar(2)}
                {this._renderStar(3)}
                {this._renderStar(4)}
                {this._renderStar(5)}
            </div>
        )
    }
}
