import React from 'react'
import { FormGroup } from 'react-bootstrap'
import { Error } from '../Error'
import SwitchCheckboxInput from '../../blocks/SwitchCheckboxInput'
import MetaLabel from '../../elements/text/MetaLabel'
import { Flex } from '../../elements/Flex'
import styled from 'styled-components'
import { mobileBreakpoint } from '../../../theme/variables'

const CheckboxGrid = styled.div`
  display: grid;
  grid-gap: 10px 20px;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: ${mobileBreakpoint}px) {
    grid-gap: 10px 5px;
    grid-template-columns: 1fr 1fr;
  }
`

export const MultipleSwitchCheckboxField = ({
    option,
    input,
}) =>
    <SwitchCheckboxInput
        value={option.id}
        disabled={option.disabled}
        checked={(input.value || []).find(el => String(el.id) === String(option.id))}
        onChange={event => {
            let newValue = [ ...input.value ]
            if(event.target.checked) {
                newValue.push(option)
            } else {
                newValue = newValue.filter(el => String(el.id) !== String(option.id))
            }
            return input.onChange(newValue)
        }}
    />

export const SingleSwitchCheckboxField = ({ input }) =>
    <SwitchCheckboxInput {...input} checked={input.value} />

const SwitchCheckBoxGroup = ({
   className,
   options,
   label,
   input,
   meta,
   name,
}) =>
    <FormGroup controlId={name} bsClass={'form-group ' + className || ''}>
        {label && <MetaLabel>{label}</MetaLabel>}
        <CheckboxGrid>
            { options.map((option, index) =>
                <Flex modifiers={[ 'alignCenter' ]} key={input.name + '_' + index}>
                    <MultipleSwitchCheckboxField
                        option={option}
                        input={input}
                    />
                    {option.title}
                </Flex>
            )}
        </CheckboxGrid>
        <Error meta={meta}/>
    </FormGroup>

export default SwitchCheckBoxGroup
