import PaymentMethodCard from './PaymentMethodCard';
import LoadingAnimation from '../utils/LoadingAnimation';
import { PartnersApiFetchParamCreator } from 'ageras-api-client/src/api';
import { useAgerasApi } from 'ageras-api-client/src';
import isArray from 'lodash/isArray';

export default function PaymentMethodWidget ({
    partner
}) {
    const { data: paymentMethod, isLoading: loading } = useAgerasApi(PartnersApiFetchParamCreator().partnersCashierpaymentmethodsGet(partner.id));

    function hasPaymentMethod(){
        if(paymentMethod){
            if(isArray(paymentMethod)){
                return Boolean(paymentMethod.length);
            }
            return true;
        }
        return false;
    }

    return (
        <div>
            {loading && (
                <LoadingAnimation />
            )}

            {hasPaymentMethod() && (
                <PaymentMethodCard key={paymentMethod.pmLastFour} paymentMethod={paymentMethod} partner={partner} />
            )}
        </div>
    )
}
