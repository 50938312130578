import React from 'react'

export default class LoadingAnimation extends React.PureComponent {
    render() {
        const size = this.props.size ? this.props.size : 50
        const type = this.props.type ? this.props.type : 'circle'
        const circleClass = this.props.type === 'circle-light'
            ? 'path--light'
            : 'path'

        if (type === 'logo') {
            return <div className="logo-loader">
                <div className="square-4" />
                <div className="square-3" />
                <div className="square-2" />
                <div className="square-1" />
            </div>
        }

        return (
            <div className="showbox" style={this.props.style}>
                <div className="loader" style={{ width: size + 'px' }}>
                    <svg className="circular" viewBox="25 25 50 50">
                        <circle className={circleClass} cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
                    </svg>
                </div>
            </div>
        )
    }
}
