import React from 'react'
import { Route } from 'react-router-dom'
import { SidebarNav } from '../../components/blocks/sidebarNav'
import { Row } from 'react-bootstrap'
import { Col } from '../../components/blocks/bootstrap/index'
import { Flex } from '../../components/cards'

const SidebarPageTemplate = ({
    additionalRouteComponent = [],
    navigationInfo,
    navLinks = [],
    hash,
}) =>
    <Row>
        {/*Sidebar navigation*/}
        <Col
            md={3}
            lg={3}
            responsiveModifiers={{
                smHidden: [ 'smHiddenDown' ],
            }}
            size={hash ? 'smHidden' : ''}
        >
            <SidebarNav>
                {navigationInfo}
                {navLinks.map((el, i) => (
                    el.hidden ||
                    <SidebarNav.NavLink
                        exact
                        key={el.pathname + i}
                        activeClassName="active"
                        to={{ pathname: el.pathname, hash: '#navigation' }}
                    >
                        <Flex modifiers={[ 'alignCenter', 'justifySpaceBetween' ]}>
                            <div>
                                <SidebarNav.Text modifiers={[ 'main' ]}>
                                    {el.mainText}
                                </SidebarNav.Text>
                                <SidebarNav.Text modifiers={[ 'secondary' ]}>
                                    {el.secondaryText}
                                </SidebarNav.Text>
                            </div>
                            <SidebarNav.Chevron />
                        </Flex>
                    </SidebarNav.NavLink>
                ))}
            </SidebarNav>
        </Col>

        {/*Component content, depends of rout path*/}
        <Col
            md={9}
            lg={9}
            responsiveModifiers={{
                smHidden: [ 'smHiddenDown' ],
            }}
            size={hash ? '' : 'smHidden'}
        >
            {navLinks.map((el, i) => (
                el.hidden || el.relatedComponent &&
                <Route
                    exact
                    key={`component_${el.pathname + i}`}
                    path={el.pathname}
                    render={() => el.relatedComponent }
                />
            ))}
            {additionalRouteComponent.map((route, i) =>
                <Route
                    exact
                    key={`component_${route.pathname + i}`}
                    path={route.pathname}
                    render={() => route.relatedComponent }
                />
            )}
        </Col>
    </Row>

export default SidebarPageTemplate
