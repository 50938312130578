import React from 'react'
import { connect } from 'react-redux'
import { currentClientSelector } from '../../../selectors/auth'
import { withTranslation } from 'react-i18next'
import StaticNotification from '../../../components/offers/StaticNotification'

@connect(
    state => ({
        assignedEmployee: currentClientSelector(state).assignedEmployee ?
            state.entities.employees[currentClientSelector(state).assignedEmployee] :
            null,
    }),
)
@withTranslation('', { wait: true })
export class ClientPostponeMessagePage extends React.Component {
    render() {
        const {
            assignedEmployee,
            t,
        } = this.props

        if(!assignedEmployee) return null

        return <StaticNotification
            backgroundSize="cover"
            text={t('employee_messages.paused_case')}
            sender={assignedEmployee}
            signature={t('lead_status_message_from_employee.signature',
                    { name: assignedEmployee.name, email: assignedEmployee.email, phone: assignedEmployee.signature.phoneNumber }
                )
            }
        />
    }
}
