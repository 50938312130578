import React from 'react'
import { applyStyleModifiers } from 'styled-components-modifiers'
import styled from 'styled-components'
import { colors, breakpoints } from '../../theme/variables_new'
import { MODIFIER_MARGIN_CONFIG, MODIFIER_PADDING_CONFIG } from '../../components/elements/modifiers/spaceModifiers'

const MODIFIER_LIST_CONFIG = {
    responsive: () => `
        column-count: 1;

        @media (min-width: ${breakpoints.small}) {
            column-count: 2;
        }

        @media (min-width: ${breakpoints.medium}) {
            column-count: 3;
        }
    `,
}

const List = styled.ul`
    margin: 0;
    padding: 0;
    column-count: 4;
    column-fill: auto;
    height: ${({ rows }) => Number(rows) * 1.94 || 8}em;
    ${applyStyleModifiers(MODIFIER_LIST_CONFIG)};
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)};
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)};
`

const MODIFIER_LIST_ITEM_CONFIG = {
    secondary: () => `
        ::before {
            background-color: ${colors.orange};
        }
    `,
}

const Item = styled.li`
    position: relative;
    width: 100%
    list-style: none;
    position: relative;
    padding: 0 0 0 20px;
    line-height: 24px;
    margin-bottom: 8px;

    ::before {
        content: "";
        position: absolute;
        left: 0;
        top: 12px;
        transform: translateY(-3px);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: ${colors.blue};
    }
    ${applyStyleModifiers(MODIFIER_LIST_ITEM_CONFIG)};
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)};
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)};
`

List.Item = Item

export default List
