import { applyStyleModifiers, applyResponsiveStyleModifiers } from 'styled-components-modifiers'
import styled from 'styled-components'
import {
    warningColor,
    warningDarkColor,
    warningLightColor,
} from '../../theme/variables'
import { MODIFIER_MARGIN_CONFIG, MODIFIER_PADDING_CONFIG } from './modifiers/spaceModifiers'
import { breakpoints, colors, fonts } from '../../theme/variables_new'
import { Flex } from '../cards'

const MODIFIER_BUTTON_CONFIG = {
    secondary: ({ outline }) => `
        background-color: ${outline ? 'transparent' : colors.orange};
        border-color: ${colors.orange};
        color: ${outline && colors.orange};

        &:hover {
                background-color: ${colors.orange};
            }
        &:active, &:focus {
                background-color: ${colors.orange};
                box-shadow: none;
            }
        }
        &:disabled {
            background-color: ${colors.backgroundGrey};
        }
    `,
    primary: ({ outline }) => `
        background-color: ${outline ? 'transparent' : colors.blue};
        border-color: ${colors.blue};
        color: ${outline && colors.blue};

        &:hover {
            background-color: ${colors.blueHover};
            border-color: ${colors.blueHover};
        }
        &:active, &:focus {
            background-color: ${colors.blueHover};
            border-color: ${colors.blueHover};
            box-shadow: none;
        }
        &:disabled {
            background-color: ${colors.backgroundGrey};
        }
    `,
    action: ({ outline }) => `
        background-color: ${outline ? 'transparent' : colors.green};
        border-color: ${colors.green};
        color: ${outline && colors.green};

        &:hover {
            background-color: ${colors.greenHover};
            border-color: ${colors.greenHover};
        }
        &:active, &:focus {
            background-color: ${colors.greenHover};
            border-color: ${colors.greenHover};
            box-shadow: none;
        }
        &:disabled {
            background-color: ${colors.backgroundGrey};
        }
    `,
    danger: ({ outline }) => `
        background-color: ${outline ? 'transparent' : colors.red};
        border-color: ${colors.red};
        color: ${outline && colors.red};

        &:hover {
            background-color: ${colors.red};
        }
        &:active, &:focus {
            background-color: ${colors.red};
            box-shadow: none;
        }
        &:disabled {
            background-color: ${colors.backgroundGrey};
        }
    `,
    warning: ({ outline }) => `
        background-color: ${outline ? 'transparent' : warningColor};
        border-color: ${warningColor};
        color: ${outline && warningColor};

        &:hover {
            background-color: ${warningLightColor};
        }
        &:active, &:focus {
            background-color: ${warningDarkColor};
            box-shadow: none;
        }
        &:disabled {
            background-color: ${warningLightColor};
        }
    }
    `,
    large: () => `
        font-size: 16px;
        line-height: 20px;
        padding: 12px 32px;
        border-radius: 8px;
    `,
    regular: () => `
        font-size: 14px;
        line-height: 18px;
        padding: 11px 16px;
        border-radius: 8px;
    `,

    small: () => `
        font-size: 12px;
        line-height: 16px;
        padding: 8px 12px 5px;
        border-radius: 4px;
    `,

    bold: () => `
        font-weight: bold;
    `,

    bottomLeft: () => `
        border-bottom-left-radius: 0 !important;
    `,

    nowrap: () => `
        white-space: nowrap;
    `,
    btnBlock: () => `
        text-align: center;
        display: block;
        width: 100%;
    `,
    fullWidth: () => `
        width: 100%;
    `,
    halfWidth: () => `
        width: 50%;
    `,
    mobileHiddenUp: () => `
        @media (min-width: ${breakpoints.small}px) {
            display: none;
        }
    `,
    mdWidth: () => `
        width: 140px
    `,
    uppercase: () => `
        text-transform: uppercase;
    `,
    nextOfType: () => `
        margin-top: 16px;
    `,
    disabled: () => `
        cursor: not-allowed;
        pointer-events: none;
        border-color: ${colors.grey};
        background-color: ${colors.grey};
        opacity: 0.6;
    `,
    hidden: () => `
        display: none;
    `,
    inline: () => `
        display: inline-block;
    `,
    cellBtn: () => `
        font-size: 12px;
        font-weight: ${fonts.weight.bold}
        line-height: 14px;
        padding: 7px 10px 5px;
    `,
}

export const Icon = styled.div`
    display: inline-block;
    position: relative;
`

export const Button = styled.button.attrs(props => ({ type: props.type || 'button' }))`
    transition: background 200ms ease;
    border-radius: ${({ radius }) => radius || '4'}px;
    cursor: pointer;
    border: 2px solid;
    color: ${colors.white};
    text-align: center;
    outline: none;
    &:hover, &:active, &:focus {
        color: ${colors.white};
    }
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)}
    ${applyStyleModifiers(MODIFIER_BUTTON_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_BUTTON_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_PADDING_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_MARGIN_CONFIG)}
`

export const ButtonGroup = styled(Flex)`
    > ${Button} {
        width: fit-content;
        &:not(:only-child) {
            border-radius: 0;

            &:first-child {
                border-radius: 4px 0 0 4px;
            }
            &:last-child {
                border-radius: 0 4px 4px 0;
            }
            &:not(:last-child) {
                border-right: 1px solid #fff;
            }
        }
    }
`

const MODIFIER_TEXT_BUTTON_CONFIG = {
    secondary: () => `
        color: ${colors.orange}BB;

        &:hover {
            background-color: ${colors.backgroundGrey};
            color: ${colors.orange};
        }
        &:active, &:focus {
            background-color: ${colors.orange}25;
            color: ${colors.orange};
        }
        &:disabled {
            color: ${colors.backgroundGrey};
        }
    `,
    primary: () => `
        color: ${colors.blue}BB;

        &:hover {
            background-color: ${colors.backgroundGrey};
            color: ${colors.blue};
        }
        &:active, &:focus {
            background-color: ${colors.blue}25;
            color: ${colors.blue};
        }
        &:disabled {
            color: ${colors.backgroundGrey};
        }
    `
}

export const TextButton = styled.button`
    display: inline-block;
    border: none;
    border-radius: 4px;
    padding: 6px 8px 4px;
    background-color: transparent;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;

    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)}
    ${applyStyleModifiers(MODIFIER_TEXT_BUTTON_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_TEXT_BUTTON_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_PADDING_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_MARGIN_CONFIG)}
`
