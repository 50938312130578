import React from 'react'
import { withTranslation } from 'react-i18next'
import UserProfilePage from './UserProfilePage'
import { ContainerFluid } from '../cards'
import SidebarPageTemplate from '../../containers/layout/SidebarPageTemplate'
import ChangePasswordForm from '../users/ChangePasswordForm'

@withTranslation('', { wait: true })
export default class UserEditPage extends React.Component {
    render() {
        const {
            location: { hash },
            match,
            t,
        } = this.props

        const navLinks = [
            {
                pathname: `${match.url}/profile`,
                relatedComponent: <UserProfilePage />,
                mainText: t('navigation.user_profile'),
                secondaryText: t('navigation.user_profile_description'),
            },
            {
                pathname: `${match.url}/password`,
                relatedComponent: <ChangePasswordForm />,
                mainText: t('navigation.password'),
                secondaryText: t('navigation.password_description'),
            },
        ]

        return <ContainerFluid>
            <SidebarPageTemplate
                navLinks={navLinks}
                hash={hash}
            />
        </ContainerFluid>
    }
}
