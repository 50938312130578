import React from 'react'
import { withTranslation, Trans } from 'react-i18next'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { currentPartnerSelector, currentPartnerUserSelector } from '../../selectors/auth'
import { queryParametersSelector } from '../../selectors/routing'
import creditCardType, { getTypeInfo, types as CardType } from 'credit-card-type'
import LoadingAnimation from '../utils/LoadingAnimation'
import * as packages from '../../actions/packages'
import { startCheckout } from '../../actions/checkouts'
import * as subscriptions from '../../actions/subscriptions'
import FormattedAmount from '../utils/FormattedAmount'
import EpayPaymentWindow from '../subscription/EpayPaymentWindow'
import * as billingActions from '../../actions/billing'
import { toastr } from 'react-redux-toastr'

@connect(
    (state, props) => ({
        cards: Object.values(state.entities.paymentCards),
        subscriptionPackage: state.entities.subscriptionPackages[props.match.params.subscriptionPackageId],
        isLoading: state.pages.subscriptionPaymentOptions.isLoading,
        partner: currentPartnerSelector(state),
        epayResponse: queryParametersSelector(state),
        partnerUser: currentPartnerUserSelector(state),
    }),
    {
        getPackage: packages.getSubscriptionPackage,
        getPackages: packages.getSubscriptionPackages,
        createSubscription: subscriptions.createSubscription,
        getCards: billingActions.getCards,
        addCard: billingActions.addEpayCard,
        startPayment: billingActions.startPayment,
        redirectToBillingPage: billingActions.redirectToBillingPage,
        startCheckout,
        push,
    }
)
@withTranslation('', { wait: true })
export default class SubscriptionPaymentOptionsPage extends React.Component {
    componentDidMount() {
        const { getCards, partner } = this.props
        getCards(partner)
        this.props.getPackages(partner.geo.code, partner.id)
    }

    checkout() {
        this.props.startCheckout({
            type: 'partner',
            partner: {
                id: this.props.partner.id,
            },
        }, [ {
            quantity: 1,
            item: {
                type: 'package',
                package: {
                    id: this.props.subscriptionPackage.id,
                },
            },
        } ]).then(
            response => {
                this.props.push(`/checkout/${response.result}`)
            },
        )
    }

    render() {
        const {
            subscriptionPackage,
            isLoading,
            t,
        } = this.props

        const { subscriptionPackageId } = this.props.match.params

        if (!subscriptionPackage || isLoading) {
            return <LoadingAnimation />
        }

        return <div className="container-fluid" style={{ marginTop: '30px' }}>
            <div className="card">
                <h2>{t('subscription.payment.title')}</h2>

                <p className="intro">
                    <Trans
                        i18nKey="subscription.payment.intro"
                        component={<FormattedAmount
                            amount={subscriptionPackage.monthlyPrice.amount}
                            currency={subscriptionPackage.monthlyPrice.currency}
                        />}
                    />
                    <button
                        onClick={() => this.checkout()}
                        className="btn btn-action btn-block btn-lg"
                    >
                        {t('payment.go_to')}
                    </button>
                </p>
            </div>
        </div>
    }
}
