import { Field } from 'redux-form'
import { SingleSwitchCheckboxField } from '../../forms/inputs/SwitchCheckBoxGroup'
import { Card } from '../../blocks/Card'

export default function CompanyDigitalLead({ t }) {
  return (
    <Card.Content className="partner-company-description">
        <Card.Title>{t('company_profile.digital_lead.title')}</Card.Title>
        <Card.Intro>{t('company_profile.digital_lead.intro')}</Card.Intro>
        <Field name="preferences.digitalLeads" component={SingleSwitchCheckboxField}/>
    </Card.Content>
  )
}
