import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form'
import { reducer as toastrReducer } from 'react-redux-toastr'
import auth from './auth'
import entities from './entities'
import pages from './pages'
import loaderReducer from './loader'
import pageTitleReducer from './pageTitle'
import notifications from './notifications'
import { LOGOUT_SUCCESS } from '../actions/auth'
import stripe from './stripe'
import quoteProgresses from './quoteProgresses'
import modal from './modal'

const appReducer = (history) => combineReducers({
    auth,
    entities,
    pages,
    toastr: toastrReducer,
    form: formReducer,
    router: connectRouter(history),
    loader: loaderReducer,
    pageTitle: pageTitleReducer,
    quoteProgresses,
    notifications,
    stripe,
    modal,
})

const rootReducer = (history) => (state, action) => {
    if (action.type === LOGOUT_SUCCESS) {
        const { router } = state
        state = { router }
    }

    return appReducer(history)(state, action)
}

export default rootReducer
