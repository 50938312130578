import React, { Component } from 'react'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { withTranslation } from 'react-i18next'
import CheckboxInput from '../forms/inputs/CheckboxInput'

@reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
})
@withTranslation('', { wait: true })
export default class RefundRequestFormTerms extends Component {

    validate(values) {
        if (values.acceptTerms) {
            this.props.submit()
        } else {
            throw new SubmissionError({ acceptTerms: this.props.t('refunds.request.terms.accept_terms_validation_text') })
        }
    }
    render() {
        const { handleSubmit, t, previousPage } = this.props
        return (
            <form onSubmit={handleSubmit(this.validate.bind(this))}>
                <h3>{t('refunds.request.terms.header')}</h3>
                <p>{t('refunds.request.terms.external_use_policy.text')}</p>
                <ul>
                    <li>{t('refunds.request.terms.external_use_policy.item_1')}</li>
                    <li>{t('refunds.request.terms.external_use_policy.item_2')}</li>
                    <li>{t('refunds.request.terms.external_use_policy.item_3')}</li>
                    <li>{t('refunds.request.terms.external_use_policy.item_4')}</li>
                </ul>
                <p>{t('refunds.request.terms.external_use_policy.text_2')}</p>

                <Field name="acceptTerms" t={t} component={CheckboxInput} required>
                    {t('refunds.request.terms.accept_terms')}
                </Field>
                <button type="submit" className="next btn btn-action btn-lg btn-block">
                    {t('refunds.request.terms.next')}
                </button>
                <br />
                <a onClick={previousPage} className="cursor-pointer">{t('refunds.request.reason.back')}</a>
            </form>
        )
    }
}
