import React from 'react'
import styled, { css } from 'styled-components'
import { applyResponsiveStyleModifiers, applyStyleModifiers } from 'styled-components-modifiers'
import {
    dangerColor,
    fadedTextColor,
    mobileBreakpoint,
    primaryColor,
    progressDraftColor,
    progressProvidedColor,
    secondaryColorTwo,
    statusNewColor,
    successColor,
    warningColor,
} from '../../theme/variables'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const sizes = {
    desktop: 992,
    mobile: mobileBreakpoint,
    phone: 550,
}

export const media = Object.keys(sizes).reduce((accumulator, label) => {
    const emSize = sizes[label] / 16
    accumulator[label] = (...args) => css`
        @media (max-width: ${emSize}em) {
            ${css(...args)}
        }
    `
    return accumulator
}, {})

const borderColor = '#C7D7DD'
const cardPadding = '30px'
const textColor = '#65768C'

const MODIFIER_FLEX_CONFIG = {
    justifyCenter: () => `
        justify-content: center;
    `,
    justifyEnd: () => `
        justify-content: flex-end ;
    `,
    justifySpaceBetween: () => `
        justify-content: space-between;
    `,
    mobile: () => `
        @media (max-width: ${sizes.mobile}px) {
            flex-direction: column
            > *:not(:last-child) {
                margin-bottom: 15px;
            }
        }
    `,
    alignCenter: () => `
        align-items: center;
    `,
}

export const PlainCard = styled.div`
    background-color: #fff;
    box-shadow: 0 5px 10px 6px rgba(0, 0, 0, 0.02);
    border: 1px solid ${borderColor};
    ${props => props.useMobile && media.mobile`border: none`};
    ${props => props.useMobile && media.mobile`boxShadow: none`};

`

export const Card = styled(PlainCard)`
    border-radius: ${props => props.borderRadius || '3px'};
    margin: ${props => props.margin || '0 0 30px 0'};
    ${media.mobile`margin-bottom: 15px;`}
`

export const WarningIcon = styled.div`
    width: 40px;
    line-height: 40px;
    color: #fff;
    border-radius: 50%;
    font-size: 26px;
    text-align: center;
    height: 40px;
    background-color: ${props => props.backgroundColor || 'rgb(230, 54, 55)'};
    display: inline-block;
`

export const LineCard = styled(Card)`
    border-left: 5px solid ${props => props.borderColor || secondaryColorTwo}
    // border-radius: 0 3px 3px 0;
`

export const Header = styled.div`
    padding: ${props => props.padding || cardPadding};
    ${media.mobile`padding: ${props => props.padding || '15px'}`};
    ${media.mobile`text-align: center;`};
    border-bottom: 1px solid ${borderColor};
    background-color: ${props => props.backgroundColor};
    overflow: auto;
`

export const MarckCircle = styled.div`
    background-color: ${props => props.backgroundColor || secondaryColorTwo};
    line-height: ${props => props.size || '50px'};
    margin: ${props => props.margin || '0'};
    height: ${props => props.size || '50px'};
    width: ${props => props.size || '50px'};
    color: ${props => props.color || '#fff'};
    float: ${props => props.float};
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    font-weight: 500;
`

export const FeaturedHeader = styled(Header)`
    border: 1px solid ${borderColor};
    border-radius: ${props => props.borderRadius || '3px'};
    color: ${textColor};
    background-color: white;
    border-bottom: 0;
    border-radius: 3px 3px 0 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .3px;
`
export const Content = styled.div`
    padding: ${({ padding }) => padding || cardPadding};
    ${media.mobile`padding: ${props => props.padding || '20px'};`}

`

export const ScrollableContent = styled(Content)`
    overflow-y: auto;
    height: ${({ height }) => height};
    max-height: ${({ maxHeight }) => maxHeight};
    margin-bottom: ${({ marginBottom }) => marginBottom};
    ${media.mobile`height: ${({ mobileHeight }) => mobileHeight}`};
    ${media.mobile`margin-bottom: ${({ mobileMarginBottom }) => mobileMarginBottom}`};
`
export const ConversationScrollableList = styled(ScrollableContent)`
    border-right: 1px solid #C7D7DD;
    ${media.mobile`border-right-width: 0`};
`

export const Center = styled.div`
    text-align: center;
    width: 100%;
`

export const Star = styled.div`
    width: 18px;
    height: 18px;
    display: inline-block;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+DQo8c3ZnIHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KIDxnPg0KICA8dGl0bGU+TGF5ZXIgMTwvdGl0bGU+DQogIDxwYXRoIGZpbGw9IiMwMGE0ZWIiIGlkPSJzdmdfMSIgZD0ibTI2LjQzOSw5NS42MDFjLTUuNjA4LDIuOTQ5IC05LjI4NiwwLjI3NiAtOC4yMTYsLTUuOTY4bDQuNSwtMjYuMjM3bC0xOS4wNjEsLTE4LjU4Yy00LjUzNywtNC40MjMgLTMuMTMyLC04Ljc0NiAzLjEzNywtOS42NTdsMjYuMzQzLC0zLjgyOWwxMS43ODEsLTIzLjg3YzIuODA0LC01LjY4MiA3LjM1LC01LjY4MiAxMC4xNTQsMGwxMS43OCwyMy44N2wyNi4zNDMsMy44MjljNi4yNywwLjkxMSA3LjY3NCw1LjIzNCAzLjEzOCw5LjY1N2wtMTkuMDYxLDE4LjU4MWw0LjUwMSwyNi4yMzdjMS4wNyw2LjI0NCAtMi42MDgsOC45MTYgLTguMjE2LDUuOTY4bC0yMy41NjIsLTEyLjM4N2wtMjMuNTYxLDEyLjM4NnoiLz4NCiA8L2c+DQo8L3N2Zz4=);
    background-size: contain;
    margin-right: 4px;
`

export const EmptyStar = styled(Star)`
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+Cjxzdmcgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c3ZnPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiA8Zz4KICA8dGl0bGU+TGF5ZXIgMTwvdGl0bGU+CiAgPHBhdGggZmlsbD0iI2Q1ZDVkNSIgaWQ9InN2Z18xIiBkPSJtMjYuNDM5LDk1LjYwMWMtNS42MDgsMi45NDkgLTkuMjg2LDAuMjc2IC04LjIxNiwtNS45NjhsNC41LC0yNi4yMzdsLTE5LjA2MSwtMTguNThjLTQuNTM3LC00LjQyMyAtMy4xMzIsLTguNzQ2IDMuMTM3LC05LjY1N2wyNi4zNDMsLTMuODI5bDExLjc4MSwtMjMuODdjMi44MDQsLTUuNjgyIDcuMzUsLTUuNjgyIDEwLjE1NCwwbDExLjc4LDIzLjg3bDI2LjM0MywzLjgyOWM2LjI3LDAuOTExIDcuNjc0LDUuMjM0IDMuMTM4LDkuNjU3bC0xOS4wNjEsMTguNTgxbDQuNTAxLDI2LjIzN2MxLjA3LDYuMjQ0IC0yLjYwOCw4LjkxNiAtOC4yMTYsNS45NjhsLTIzLjU2MiwtMTIuMzg3bC0yMy41NjEsMTIuMzg2eiIvPgogPC9nPgo8L3N2Zz4=);
`

export const ProfileHeader = styled(Header)`
    padding:20px;
    font-weight: 500;
`

export const StarsContainer = styled.div`
  width: 100%;
  height: 80px;
  background-color: rgba(244, 247, 249, 0.42);
  border-top: solid 1px #cedce1;
  border-bottom: solid 1px #cedce1;
  padding: 17px 0;
`

export const Separator = styled.div`
    width: ${props => props.width || '100%'};
    margin: ${props => props.margin || '30px auto'}
`

export const SeparatorLine = styled(Separator)`
    border-top: 1px solid ${props => props.lineColor || '#E3EAEC'};
    margin: ${props => props.margin || '30px auto'};
`

export const VerticalTimeline = styled.div`
    border-radius: ${({ round }) => {
        switch(round) {
            case 'both':
                return '5px'
            case 'top':
                return '5px 5px 0 0'
            case 'bottom':
                return '0 0 5px 5px'
            default:
                return '0'
        }
    }};
    background-color: rgb(0, 165, 229);
    height: inherit;
    width: 10px;
    float: left;
    left: 52px;
    bottom: 0;
    top: 0;
    position: absolute;
`

export const TimelineDot = styled.div`
    border-radius: 20px;
    width: 6px;
    height: 6px;
    background-color: rgb(255, 255, 255);
    margin: 3px 2px;
`

export const GreyCircle = styled.div`
    border-radius: 50%;
    height: 32px;
    width: 32px;
    color: rgb(255, 255, 255);
    padding: 5px;
    background-color: rgb(181, 198, 203);
    text-align: center;
    float: left;
    margin-right: 20px;
`

export const SuccessCircle = styled(GreyCircle)`
    background-color: #25CA74;
    line-height: 20px !important;
    &:before {
        font-size: 12px;
    }
`
export const AcceptedQuote = styled(GreyCircle)`
    width: auto;
    background-color: #00a3ee;
    border: 3px solid #fff;
    padding: 7px;
    align-self: center;
    line-height: 9px !important;
    font-size: 15px;
    &:before {
        font-size: 12px;
    }
`

export const Stars = ({ filled = 0, total = 0 }) => {
    let result = []
    for (let i = 0; i < filled; i++) {
        result.push(<Star key={i} />)
    }
    for (let i = filled; i < total; i++) {
        result.push(<EmptyStar key={i} />)
    }

    return <div>{ result }</div>
}

const EmptyScore = styled.div`
    background-color: ${props => props.bgColor || '#d8d8d8'};
    display: inline-block;
    border-radius: 3px;
    margin-right: 6px;
    height: ${props => props.height};
    width: ${props => props.width};
`

export const Scores = ({ filled = 0, total = 0, size }) => {
    let params = {}
    switch(size) {
        case 'small':
            params.height = '35px'
            params.width = '10px'
            break
        default:
            params.height = '50px'
            params.width = '15px'
    }

    let result = []
    for (let i = 0; i < filled; i++) {
        result.push(<EmptyScore {...params} key={i} bgColor={successColor} />)
    }
    for (let i = filled; i < total; i++) {
        result.push(<EmptyScore {...params} key={i} />)
    }

    return <div style={{ lineHeight: 1 }}>{ result }</div>
}

export const Flex = styled.div`
    display: flex;
    ${applyStyleModifiers(MODIFIER_FLEX_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_FLEX_CONFIG)}
`

const DefaultTriangle = styled.div`
      width: 0;
      height: 0;
      border-style: solid;
      ${borderWidth => borderWidth || '10px 0 10px 10px;'}
      ${borderColor => borderColor || 'transparent transparent transparent #ccc;'}
    `

export const Triangle = ({ direction, color, style }) => {
    let borderWidth
    let borderColor
    switch (direction) {
        case 'left':
            borderWidth = '10px 10px 10px 0;'
            borderColor = `transparent ${color} transparent transparent;`
            break
        case 'down':
            borderWidth = '10px 10px 0 10px;'
            borderColor = `${color} transparent transparent transparent;`
            break
        case 'up':
            borderWidth = '0 10px 10px 10px;'
            borderColor = `transparent transparent ${color} transparent;`
            break
        case 'right':
            borderWidth = '10px 0 10px 10px;'
            borderColor = `transparent transparent transparent ${color}`
    }
    return <DefaultTriangle style={style} borderWidth={borderWidth} borderColor={borderColor}/>
}

export const Badge = styled.div`
      background-color: ${props => props.backgroundColor || secondaryColorTwo};
      float: ${props => props.float || 'none'};
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 500;
      border-radius: 20px;
      padding: 3px 8px;
      ${media.mobile`display: inline-block`};
      color: #fff;
      text-align: center;
`

export const SquareStar = styled.span`
    background: ${props => props.starColor || '#02c052'};
    border-radius: 3px;
    padding: 6px 4px 3px 4px;
    color: #fff;
    font-size: ${props => props.size || 12}px;
    margin-right: 4px;
    cursor: ${props => props.cursor || 'auto'};
`
export const SquareEmptyStar = styled(SquareStar)`
    background: #ccc;
    color: #fff;
`

export const IconContainer = styled.div`
    text-align: center;
    background: #333C45;
    border-radius: 3px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    color: white;
`

export const IconContainerLarge = styled.div`
    text-align: center;
    background: #333C45;
    border-radius: 100px;
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 28px;
    color: white;
`

function labelStatusColor(status) {
    switch (status) {
        case 'bid_round_closed':
        case 'quote_provided':
            return progressProvidedColor
        case 'pending_decision':
            return warningColor
        case 'follow_up':
            return primaryColor
        case 'won':
            return successColor
        case 'lost':
            return dangerColor
        case 'draft':
            return progressDraftColor
        default:
            return statusNewColor
    }
}

function labelStatusIcon(status) {
    switch (status) {
        case 'bid_round_closed':
        case 'quote_provided':
            return 'mdi mdi-arrow-top-right'
        case 'pending_decision':
            return 'mdi mdi-help'
        case 'follow_up':
            return ''
        case 'won':
            return 'mdi mdi-check'
        case 'lost':
            return 'mdi mdi-minus'
        case 'draft':
            return 'mdi mdi-pencil'
        default:
            return ''
    }
}

export const OverflowText = styled.div`
  max-width: ${props => props.width || '100%'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StatusLabel = styled.span`
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    border-radius: 2px;
    text-align: center;
    font-size: 14px;
    margin-right: 5px;
    color: #fff;
    display: inline-block;
    vertical-align: middle;
    background-color: ${props => labelStatusColor(props.progress)};
    &:before {
        line-height: ${props => props.size}px !important;
    }
`

export const ProgressLabel = ({ progress, icon, size }) =>
    <StatusLabel
        size={size === 'small' ? 12 : 20}
        progress={progress}
        className={icon ? labelStatusIcon(progress) : ''}
    />

export const Checkbox = ({ onChange, checked, value, id, inputProps, error }) => (
    <label htmlFor={id} className="label-cbx">
        <input name={id} type="checkbox" id={id} className="invisible" onChange={onChange} checked={checked} value={value} {...inputProps}/>
        <div className="checkbox" style={{ borderColor: error && dangerColor }}>
            <svg width="20px" height="20px" viewBox="0 0 20 20">
                <path d="M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z"/>
                <polyline points="4 11 8 15 16 6"/>
            </svg>
        </div>
    </label>
)

export const DotIndicator = styled.span`
    position: relative;
    display: inline-flex;
    display: -webkit-inline-flex;
    line-height: 25px;
    &:after {
        background-color: ${props => props.showBadge ? (props.backgroundColor || '#fa3e3e') : 'none'};
        padding: ${props => props.padding || '6px'};
        right: ${props => props.right || '-17px'};
        top: ${props => props.top || '0'};
        border-radius: 50%;
        font-size: 10px;
        position: absolute;
        content: ' ';
    }
`
export const MobileNoteSubmitWrapper = styled.div`
    ${media.mobile`
        padding: 15px;
        position: fixed;
        bottom: 70px;
        left: 0;
        background-color: #fff;
        borderTop: 1px solid #C7D7DD;
    `};
    width: 100%;
`
export const ChosenByClientWrapper = styled.div`
    position: absolute;
    bottom: -55px;
    ${media.mobile`top: -27px`};
    width: 100%;
    textAlign: center;
`

export const QuoteCheckOutModal = styled.div`
    .modal-header, .modal-body {
        padding: 30px
    }
    .modal-title {
        font-size: 16px;
    }
    ${Flex} {
        img {
            padding-right: 30px;
            max-width: 150px;
            ${media.mobile`padding-right: 15px;`};
            ${media.mobile`max-width: 130px`};
        }
        .quote-heading {
            margin-bottom: 10px
        }
        ${SquareStar} {
            ${media.mobile`font-size: 10px`};
        }
        ${SquareEmptyStar} {
            ${media.mobile`font-size: 10px`};
        }
    }
    .intro-text {
        color: ${fadedTextColor};
        ${media.mobile`margin: 10px 0 0`};
        &> div {
            margin: 10px 0;
        }
        a {
            color: ${primaryColor};
            text-decoration: underline;
        }
    }
    .label-cbx {
        margin-right: 10px;
    }
`
export const OverlayToolTip = ({ text, id }) =>
    <OverlayTrigger
        placement="top"
        overlay={ <Tooltip id={'tooltip_' + id}>{text}</Tooltip>}
    >
        <OverflowText>{text}</OverflowText>
    </OverlayTrigger>

export const ConversationParticipantContext = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    > div {
        &:first-child {
            margin-bottom: 10px;
        }
        margin: 2px auto;
    }
    a {
        margin-top: 15px;
    }
`
export const SquaredPlaceholder = styled.div`
    border-radius: 3px;
    background-color: #cad9df;
    opacity: 0.45;
    width: ${props => props.width || '40%'};
    height: 18px;
    margin-bottom: 15px;
`

const MODIFIER_CONTAINER_FLUID_CONFIG = {
    fullWidth: () => `
        padding: 0 15px;
        max-width: 100%;
    `,
}

export const ContainerFluid = styled.div`
    margin: 30px auto 0;
    max-width: 1370px;
    ${media.mobile`
        padding: 0;
        .row {
            margin: 0;
            > div {
                padding: 0;
            }
        }
        ${Card} {
            border-radius: 0;
            border-right: 0;
            border-left: 0;
        }
    `}

    @media (min-width: 1024px) {
        min-width: 1000px;
    }
  ${applyStyleModifiers(MODIFIER_CONTAINER_FLUID_CONFIG)}
  ${applyResponsiveStyleModifiers(MODIFIER_CONTAINER_FLUID_CONFIG)}
`

export const LoaderContainer = styled.div`
    position: absolute;
    top: 50%;
    width: 100%;
`
