import React from 'react'
import Card from '../../Card'
import Flex from '../../layout/Flex'
import Text from '../../texts/Text'
import Heading from '../../texts/Heading'
import Image from '../../Image'
import Amount from '../../Amount'
import Loader from '../../Loader'

export interface PackageCardProps {
    title: string,
    packageName: string,
    currency: string,
    subscriptionText: string,
    subscriptionFee: number,
    description: string,
    helpText: string,
    imageSrc: string,
    active?: boolean,
}

function PackageCard({
    title,
    packageName,
    currency,
    subscriptionText,
    subscriptionFee,
    description,
    helpText,
    imageSrc,
    active
}:PackageCardProps) {
    return <Card py={4} width="100%" maxWidth={450} active={active}>
        <Card.Content mb={2} px={4}>
            <Flex justifyContent="center" mb={3}>
                <Image src={imageSrc} variant="inherit"/>
            </Flex>
            <Flex flexDirection="column" alignItems="center" mb={3}>
                <Text color="impact" size="large" fontWeight={700}>{title}</Text>
                <Heading color="accent" size="medium" textAlign="center">{packageName}</Heading>
            </Flex>
            <Flex justifyContent="center" alignItems="center" mb={4} flexDirection="column">
                <Text size="small" mb={1}>{subscriptionText}</Text>
                <Heading size="large" color="impact">
                    <Amount number={subscriptionFee} currency={currency} maximumFractionDigits={0}/>
                </Heading>
            </Flex>
            <Flex mb={3}>
                <Text>{description}</Text>
            </Flex>
            <Flex>
                <Text>{helpText}</Text>
            </Flex>
        </Card.Content>
    </Card>
}

export default PackageCard
