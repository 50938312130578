import React from 'react'
import { Flex, OverflowText } from '../../cards'
import { secondaryColorTwo } from '../../../theme/variables'
import { BadgeLabel } from './BadgeLabel'
import { TriangleWithDot } from './TriangleWithDot'

export const TriangleBadge = ({ text, icon }) =>
    <Flex>
        <TriangleWithDot color={secondaryColorTwo}/>
        <BadgeLabel backgroundColor={secondaryColorTwo}>
            <Flex>
                <OverflowText>
                    {text}
                </OverflowText>
                {icon && <i className={icon}/>}
            </Flex>
        </BadgeLabel>
    </Flex>
