import React from 'react'
import StaticNotification from '../offers/StaticNotification'
import { useTranslation } from 'react-i18next'

export const EmployeeLeadStatusNotification = ({
    assignedEmployee,
    allQuotesRejected,
    lead,
}) => {
    const {
        count: quotesCurrent,
        max: quotesMax,
    } = lead.quotesInfo

    const { t } = useTranslation();

    let statusMessage = ''

    if(quotesMax > 0) {
        if (allQuotesRejected) {
            statusMessage = t('lead_status_message_from_employee.all_quote_rejected')
        } else if(!quotesCurrent) {
            statusMessage = t('lead_status_message_from_employee.no_quotes')
        } else if(quotesCurrent === 1 && quotesMax > 1) {
            statusMessage = t('lead_status_message_from_employee.receive_more_quotes')
        } else if(quotesCurrent === 2 && quotesMax > 2) {
            statusMessage = t('lead_status_message_from_employee.receive_third_quote')
        } else if(quotesCurrent === 3 && quotesCurrent === quotesMax) {
            statusMessage = t('lead_status_message_from_employee.not_expected_receive_more_quotes')
        } else if(quotesCurrent === quotesMax) {
            statusMessage = t('lead_status_message_from_employee.lead_is_complete')
        }
    }

    return (
        <StaticNotification
            sender={assignedEmployee}
            text={statusMessage}
            signature={
                <span>
                    <b>{assignedEmployee.name}</b><br/>
                    {assignedEmployee.email}{assignedEmployee.signature.phoneNumber ? `, ${assignedEmployee.signature?.phoneNumber}` : ''}
                </span>
            }
        />
    )
}
