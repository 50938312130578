import { CALL_API } from '../middleware/api'
import * as schemas from '../schemas'

export const GET_PARTNER_BUSINESS_UNITS = 'businessUnits/GET_PARTNER_BUSINESS_UNITS'
export const GET_PARTNER_BUSINESS_UNITS_SUCCESS = 'businessUnits/GET_PARTNER_BUSINESS_UNITS_SUCCESS'
export const GET_PARTNER_BUSINESS_UNITS_FAIL = 'businessUnits/GET_PARTNER_BUSINESS_UNITS_FAIL'

export const getPartnerBusinessUnits = partnerId => ({
    [CALL_API]: {
        types: [ GET_PARTNER_BUSINESS_UNITS, GET_PARTNER_BUSINESS_UNITS_SUCCESS, GET_PARTNER_BUSINESS_UNITS_FAIL ],
        endpoint: '/partners/businessunits',
        schema: schemas.businessUnits,
        options: {
            body: {
                partnerId,
            },
        },
    },
})

