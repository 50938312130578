import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { currentPartnerSelector } from '../../selectors/auth'
import { getInvoices } from '../../actions/invoices'
import LoadingAnimation from '../utils/LoadingAnimation'
import { Card } from '../blocks/Card'
import { ContainerFluid } from '../cards'
import { generateApiUrl } from '../../utils/urls'
import { BtnLink } from '../elements/Links'
import { Headline, Text } from '../elements/text/Text'
import Box from '../blocks/Box'

@connect(
    (state, props) => ({
        invoice: state.entities.invoices[props.match.params.invoiceId],
        partner: currentPartnerSelector(state),
    }),
    {
        getInvoices,
    }
)
@withTranslation('', { wait: true })
export default class InvoicePaymentConfirmationPage extends React.PureComponent {
    componentDidMount() {
        const { partner } = this.props
        this.props.getInvoices({ partnerId: partner.id, limit: 1, invoiceId: this.props.match.params.invoiceId })
    }

    render() {
        const {
            invoice,
            t,
        } = this.props

        if (!invoice) {
            return <LoadingAnimation />
        }

        const invoicePaymentStatus = invoice.status

        let downloadUrl = generateApiUrl('invoices/' + invoice.id + '.pdf')

        return <ContainerFluid>
            <div className="alert alert-success">
                <Text modifiers={[ 'center' ]}>{invoicePaymentStatus !== 'paid' ? t('payment_page.payment_confirmation.success_to_be_processed') : t('payment_page.payment_confirmation.success_and_paid')}</Text>
            </div>
            <Box modifiers={[ 'p_3' ]}>
                <Box.Content>
                    <Headline modifiers={[ 'small', 'mB_2' ]}>{t('payment_page.payment_confirmation.title')} {t(`invoices.type.${invoice.type}`)} #{invoice.invoiceNumber}</Headline>
                    <Text>{t('payment_page.payment_confirmation.description')}</Text>
                    {invoicePaymentStatus === 'paid' && <BtnLink
                        to={downloadUrl}
                        modifiers={[ 'btnBlock', 'primary', 'p_2', 'mT_3' ]}
                        target="_blank"
                    >
                        {t('invoices.download')}
                    </BtnLink>}
                </Box.Content>
            </Box>
        </ContainerFluid>
    }
}
