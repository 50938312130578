import styled from 'styled-components'

const Cell = styled.td`
    vertical-align: middle;
    position: relative;
    &:after {
      content:'';
      position: absolute;
      pointer-events: none;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      border-bottom: 1px solid #E6EBF0;
    }
    height: 50px;
    padding: 0px 20px;
    line-height: 16px;
    font-size: 14px;
    a {
      font-weight: bold;
    }
    &.number {
      text-align: right;
    }
`

export default Cell
