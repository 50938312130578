import { replace } from 'connected-react-router'
import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'
import { queryParametersSelector } from '../selectors/routing'
import { fetchJSON } from '../fetch'
import { handleError } from './errors'
import config from '../config'

export const GET_CARDS = 'billing/GET_CARDS'
export const GET_CARDS_SUCCESS = 'billing/GET_CARDS_SUCCESS'
export const GET_CARDS_FAIL = 'billing/GET_CARDS_FAIL'

export const ADD_EPAY_CARD = 'billing/ADD_CARD'
export const ADD_EPAY_CARD_SUCCESS = 'billing/ADD_EPAY_CARD_SUCCESS'
export const ADD_EPAY_CARD_FAIL = 'billing/ADD_CARD_FAIL'

export const GET_WINDOW_CONFIG = 'billing/GET_WINDOW_CONFIG'
export const GET_WINDOW_CONFIG_SUCCESS = 'billing/GET_WINDOW_CONFIG_SUCCESS'
export const GET_WINDOW_CONFIG_FAIL = 'billing/GET_WINDOW_CONFIG_FAIL'

export const START_PAYMENT_SUCCESS = 'billing/START_PAYMENT_SUCCESS'
export const UNMOUNT_PAYMENT_WINDOW_SUCCESS = 'billing/UNMOUNT_PAYMENT_WINDOW_SUCCESS'

export const ADD_STRIPE_CARD = 'projects/ADD_STRIPE_CARD '
export const ADD_STRIPE_CARD_SUCCESS = 'projects/ADD_STRIPE_CARD_SUCCESS '
export const ADD_STRIPE_CARD_FAIL = 'projects/ADD_STRIPE_CARD_FAIL '

export const DELETE_STRIPE_CARD = 'projects/DELETE_STRIPE_CARD '
export const DELETE_STRIPE_CARD_SUCCESS = 'projects/DELETE_STRIPE_CARD_SUCCESS '
export const DELETE_STRIPE_CARD_FAIL = 'projects/DELETE_STRIPE_CARD_FAIL '

export const CHARGE_STRIPE_SOURCE = 'billing/CHARGE_STRIPE_SOURCE '
export const CHARGE_STRIPE_SOURCE_SUCCESS = 'billing/CHARGE_STRIPE_SOURCE_SUCCESS '
export const CHARGE_STRIPE_SOURCE_FAIL = 'billing/CHARGE_STRIPE_SOURCE_FAIL '

export const COMPLETE_INVOICE_PAYMENT = 'billing/COMPLETE_INVOICE_PAYMENT'
export const COMPLETE_INVOICE_PAYMENT_SUCCESS = 'billing/COMPLETE_INVOICE_PAYMENT_SUCCESS'
export const COMPLETE_INVOICE_PAYMENT_FAIL = 'billing/COMPLETE_INVOICE_PAYMENT_FAIL'

export const CREATE_PAYMENT_INTENT = 'billing/CREATE_PAYMENT_INTENT'
export const CREATE_PAYMENT_INTENT_SUCCESS = 'billing/CREATE_PAYMENT_INTENT_SUCCESS'
export const CREATE_PAYMENT_INTENT_FAIL = 'billing/CREATE_PAYMENT_INTENT_FAIL'

export const CONFIRM_PAYMENT_INTENT = 'billing/CONFIRM_PAYMENT_INTENT'
export const CONFIRM_PAYMENT_INTENT_SUCCESS = 'billing/CONFIRM_PAYMENT_INTENT_SUCCESS'
export const CONFIRM_PAYMENT_INTENT_FAIL = 'billing/CONFIRM_PAYMENT_INTENT_FAIL'

export const VALIDATE_PAYMENT_INTENT = 'billing/VALIDATE_PAYMENT_INTENT'
export const VALIDATE_PAYMENT_INTENT_SUCCESS = 'billing/VALIDATE_PAYMENT_INTENT_SUCCESS'
export const VALIDATE_PAYMENT_INTENT_FAIL = 'billing/VALIDATE_PAYMENT_INTENT_FAIL'

export const getCards = partner => ({
    [CALL_API]: {
        types: [ GET_CARDS, GET_CARDS_SUCCESS, GET_CARDS_FAIL ],
        endpoint: '/payments/cards',
        schema: schemas.paymentCards,
        options: {
            body: {
                partner_id: partner.id,
                limit: 100,
            },
        },
    },
})

export const getWindowConfig = (partner, data) => dispatch => {
    dispatch({ type: GET_WINDOW_CONFIG })
    return fetchJSON(`${config.serviceUrl}/payments/epay/window`, {
        method: 'post',
        body: {
            geo_code: partner.geo.code,
            data,
        },
    }).then(response => {
        dispatch({
            type: GET_WINDOW_CONFIG_SUCCESS,
            epay: response.data,
        })
    }).catch(e => {
        dispatch({ type: GET_WINDOW_CONFIG_FAIL })
        handleError(dispatch, e)
    })
}

export const addEpayCard = (epayResponse, partnerUser) => ({
    [CALL_API]: {
        types: [ ADD_EPAY_CARD, ADD_EPAY_CARD_SUCCESS, ADD_EPAY_CARD_FAIL ],
        endpoint: '/payments/epay/cards',
        schema: schemas.paymentCard,
        options: {
            method: 'post',
            body: {
                data: epayResponse,
                partner_user_id: partnerUser.id,
            },
        },
    },
})

export const startPayment = () => ({
    type: START_PAYMENT_SUCCESS,
})

export const redirectToBillingPage = () => (dispatch, getState) => {
    const query = queryParametersSelector(getState())
    const pathName = '/subscription/billing'
    dispatch(replace(pathName))
    return dispatch(replace({
        query: Object.assign({},
            ...Object.keys(query)
                .filter(key => !key.startsWith('auth.'))
                .map(key => ({
                    [key]: query[key],
                }))
        ) }))
}

export const unmountPaymentWindow = () => ({
    type: UNMOUNT_PAYMENT_WINDOW_SUCCESS,
})

export const addStripeCard = (partnerId, type, stripeToken) => ({
    [CALL_API]: {
        types: [ ADD_STRIPE_CARD, ADD_STRIPE_CARD_SUCCESS, ADD_STRIPE_CARD_FAIL ],
        endpoint: `${config.agerasServices}/payments/stripe/add-card`,
        options: {
            method: 'post',
            body: {
                paymentSolutionIdentifier: type,
                partnerId,
                stripeToken,
            },
        },
    },
})

export const deleteStripeCard = cardId => ({
    [CALL_API]: {
        types: [ DELETE_STRIPE_CARD, DELETE_STRIPE_CARD_SUCCESS, DELETE_STRIPE_CARD_FAIL ],
        endpoint: `/payments/cards/${cardId}`,
        options: {
            method: 'delete',
        },
    },
    cardId,
})

export const createPaymentIntent = (paymentMethodId, invoiceId) => ({
    [CALL_API]: {
        types: [ CREATE_PAYMENT_INTENT, CREATE_PAYMENT_INTENT_SUCCESS, CREATE_PAYMENT_INTENT_FAIL ],
        endpoint: `${config.agerasServices}/payments/invoice/create-payment-intent`,
        options: {
            method: 'post',
            body: {
                paymentMethodId: paymentMethodId,
                invoiceId: invoiceId,
            },
        },
        suppressLoader: true,
    },
})

export const confirmPaymentIntent = (paymentIntentId, invoiceId) => ({
    [CALL_API]: {
        types: [ CONFIRM_PAYMENT_INTENT, CONFIRM_PAYMENT_INTENT_SUCCESS, CONFIRM_PAYMENT_INTENT_FAIL ],
        endpoint: `${config.agerasServices}/payments/invoice/confirm-payment-intent`,
        options: {
            method: 'post',
            body: {
                paymentIntentId: paymentIntentId,
                invoiceId: invoiceId,
            },
        },
        suppressLoader: true,
    },
})

export const validatePaymentIntent = (paymentIntentId, invoiceId) => ({
    [CALL_API]: {
        types: [ VALIDATE_PAYMENT_INTENT, VALIDATE_PAYMENT_INTENT_SUCCESS, VALIDATE_PAYMENT_INTENT_FAIL ],
        endpoint: `${config.agerasServices}/payments/invoice/validate-payment-intent`,
        options: {
            method: 'post',
            body: {
                paymentIntentId: paymentIntentId,
                invoiceId: invoiceId,
            },
        },
        suppressLoader: true,
    },
})
