import styled from 'styled-components'
import { metaTextColor } from '../../../theme/variables'
import { MODIFIER_MARGIN_CONFIG } from '../modifiers/spaceModifiers'
import { applyStyleModifiers } from 'styled-components-modifiers'

export default styled.p`
    color: ${metaTextColor};
    font-size: 14px;
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
`
