import { LeadClientExperimentResource } from 'ageras-api-client/src/api'
import { createSelector } from 'reselect'
import { quotesSelector } from './quotes'

const projectSelector = (state, id) => state.entities.projects[id]

export const projectsSelector = createSelector(
    state => state.pages.projects.projectIds,
    state => state.entities.projects,
    (projectIds, projects) => projectIds.map(id => projects[id]),
)

export const projectStatusesSelector = createSelector(
    state => state.entities.projectStatuses,
    projectStatuses => Object.values(projectStatuses),
)

export const isProjectClientCustomerChoiceSelector = createSelector(
    projectSelector,
    () => true,
)
