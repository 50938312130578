import React from 'react'
import {
    Trans,
    withTranslation,
} from 'react-i18next'
import PageTitle from '../../../components/utils/PageTitle'
import { currentPartnerSelector, getLeadQuoteByPartnerIdSelector } from '../../../selectors/auth'
import {
    hasQuoteCreationAccessAccessSelector,
    hasMarketplaceWithoutFeesAccessSelector,
    hasMarketplacePlusLeadAccessSelector,
    hasMarketplaceBasicLeadAccessSelector
} from '../../../selectors/access'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'
import { push, replace } from 'connected-react-router'
import {
    publishQuote,
    createQuote,
    updateQuote,
    getQuote,
    addQuoteItems,
    updateQuoteItem,
    deleteQuoteItem,
    refuseQuote,
} from '../../../actions/quotes'
import { getProjects } from '../../../actions/projects'
import { getLead } from '../../../actions/leads'
import LoadingAnimation from '../../../components/utils/LoadingAnimation'
import CreateQuoteForm from '../../../components/quotes/CreateQuoteForm'
import { SubmissionError, submit } from 'redux-form'
import {
    Alert,
    Clearfix,
} from 'react-bootstrap'
import FormattedIntervalTimer from '../../../components/utils/FormattedIntervalTimer'
import { dangerColor } from '../../../theme/variables'
import {
    LineCard,
    ContainerFluid,
} from '../../../components/cards'
import moment from 'moment'
import { Card } from '../../../components/blocks/Card'
import { Button } from '../../../components/elements/Button'
import { getDataFields } from '../../../actions/datafields'
import { leadDataFieldsMatchSelector } from '../../../selectors/lead'
import { hideModal, showModal } from '../../../actions/modal'
import { Flex } from '../../../components/elements/Flex'
import { getConversations } from '../../../actions/conversations'
import { partnerConversationSelector } from '../../../selectors/conversations'
import { PARTNER_PUBLISH_QUOTE_WITHOUT_PRICE, PARTNER_PUBLISH_QUOTE_WITH_PRICE, PARTNER_QUOTE_CREATED, PARTNER_ACCEPTED_MEETING, sendSegmentData } from '../../../utils/segment'
import config from "../../../config";
import CustomerChoiceQuotePage from './CustomerChoiceQuotePage'
import { isQuoteClientCustomerChoiceSelector } from '../../../selectors/quotes'

@withTranslation('', { wait: true })
@connect(
    (state, props) => ({
        lead: leadDataFieldsMatchSelector(state, props.match.params.leadId),
        partner: currentPartnerSelector(state),
        quoteCreationAccess: hasQuoteCreationAccessAccessSelector(state),
        quoteId: getLeadQuoteByPartnerIdSelector(state, props.match.params.leadId).id,
        quote: state.entities.quotes[getLeadQuoteByPartnerIdSelector(state, props.match.params.leadId).id],
        isQuoteClientCustomerChoice: isQuoteClientCustomerChoiceSelector(state, props.match.params.leadId),
        quoteIsSaving: state.pages.quote.isSaving,
        leadIsLoading: state.pages.quote.isLoading,
        conversation: partnerConversationSelector(state)[0],
        hasMarketplaceWithoutFeesAccess: hasMarketplaceWithoutFeesAccessSelector(state),
        hasMarketplacePlusLeadAccess: hasMarketplacePlusLeadAccessSelector(state),
        hasMarketplaceBasicLeadAccess: hasMarketplaceBasicLeadAccessSelector(state),
    }),
    {
        push,
        replace,
        getLead,
        publishQuote,
        createQuote,
        getProjects,
        updateQuote,
        getQuote,
        addQuoteItems,
        updateQuoteItem,
        deleteQuoteItem,
        getDataFields,
        getConversations,
        showModal,
        hideModal,
        submit,
        refuseQuote
    }
)

export default class QuotePage extends React.Component {
    state = {
        redirecting: false,
        productLinesRequired: true
    }

    componentDidMount() {
        if (this.props?.partner?.geo?.code.includes(config.marketsQuoteWithoutPrice)) {
            this.setState({
                productLinesRequired: false
            })
        }

        const leadId = this.props.match.params.leadId
        this.props.getConversations({
            leadId,
            partnerId: this.props.partner.id,
        })
        this.props.getDataFields({ geoCode: this.props.partner.geo.code })
        this.props.getLead(leadId)
            .then(res => {
                const quote = res.entities.leads[res.result].quotesInfo.quotes
                    .find(q => q.partner.id === this.props.partner.id)
                if(quote) {
                    this.props.getQuote(this.props.lead.id, quote.id)
                }
            })
    }

    componentDidUpdate(prevProps) {
        const {
            lead,
            partner,
            match,
            quote,
        } = this.props

        // Redirect if quote can no longer be edited
        if(lead && quote && quote.status !== 'new' && !this.state.redirecting) {
            this.setState({ redirecting: true })
            this.props.getProjects({
                leadId: match.params.leadId,
                partnerId: partner.id,
            }).then(pRes => {
                this.props.replace(`/projects/${pRes.result[0]}`)
            })
        }
    }

    expirationExcludedCountries = [ 'dk', 'se', 'no', 'de' ]

    saveQuote = async data => {
        const {
            partner,
            getQuote,
            createQuote,
            updateQuote,
            addQuoteItems,
            updateQuoteItem,
            deleteQuoteItem,
            match,
            t,
            quote,
        } = this.props
        const { productLinesRequired } = this.state

        // Ignore empty quote items
        data.items = (data.items || []).filter(item => item.title || item.amountExclVat && item.amountExclVat.amount)

        data.description = data.description || '' // Temporary until the API has changed // NIST

        if(quote && quote.publishedAt) {
            this.checkValidation(data)
        }

        if(quote) {
            let body = { ...data }
            delete body.items

            // Delete quote items that were removed in the form
            const p1 = quote.items
                .filter(item1 => !data.items.map(item2 => item2.id).includes(item1.id))
                .map(item => deleteQuoteItem(match.params.leadId, quote.id, item.id))

            // Create new quote items
            const p2 = data.items
                .filter(item => !item.id)
                .map(item => addQuoteItems(match.params.leadId, quote.id, item))

            // Update existing items
            const p3 = data.items
                .filter(item => item.id)
                .map(el => {
                    if (!productLinesRequired) {
                        el.amountExclVat.amount = 0
                    }
                    return updateQuoteItem(match.params.leadId, quote.id, el)
                })

            return updateQuote(match.params.leadId, quote.id, body)
                .then(() => Promise.all([ ...p1, ...p2, ...p3 ]))
                .then(() => getQuote(match.params.leadId, quote.id))
        }

        return await createQuote(match.params.leadId, partner.id, data)
            .then(response => {
                const revenueAmount = this.props.lead.attributes ? Object.values(this.props.lead.attributes.primary).find(attr => attr.identifier === 'annual_revenue')?.selected?.[0]?.title : 0
                sendSegmentData(this.props.partner, PARTNER_QUOTE_CREATED, {
                    revenueSize: revenueAmount,
                    leadId: match.params.leadId,
                    quoteAmount: response.entities?.quotes[response.result]?.totalAmountExclVat?.amount
                })

                toastr.success(t('quotes.toastr.saved_success'), t('quotes.toastr.saved_success_description'))
                return response
            }, error => {
                toastr.error(this.props.t('quotes.toastr.error_header'), error.error)
            })
    }

    checkValidation(data) {
        let error = {}
        const { t } = this.props

        const { productLinesRequired } = this.state

        if (this.props.isQuoteClientCustomerChoice) {
            return true
        }

        if (!productLinesRequired) {
            return error
        }

        if (!data.items.length) {
            error.items = {
                title: t('quotes.creation.products.label'),
                errorText: t('quotes.creation.validation.items_missing'),
                _error: t('quotes.creation.validation.items_missing'),
            }
        }

        if (data.items.filter(item => !item.title || !item.amountExclVat || item.amountExclVat.amount <= 0).length) {
            error.items = {
                title: t('quotes.creation.products.label'),
                errorText: t('quotes.creation.validation.item_missing_amount'),
                _error: t('quotes.creation.validation.item_missing_amount'),
            }
        }

        if (data.items.filter(item => item.type === 'unit' && (item.quantity == 0 || !item.quantity)).length) {
            error.items = {
                title: t('quotes.creation.products.label'),
                errorText: t('quotes.creation.validation.item_unit_amount'),
                _error: t('quotes.creation.validation.item_unit_amount'),
            }
        }

        if (Object.keys(error).length) {
            throw new SubmissionError(error)
        }
    }

    publishQuote = async data => {
        const {
            match,
            quote,
            getQuote,
        } = this.props

        this.checkValidation(data)

        await this.saveQuote(data)
            .then(quoteResponse => {
                // editing reserved quote
                if (quote && quote.id) {
                    return getQuote(match.params.leadId, quote.id)
                }
                // marketplace quote was just created
                return getQuote(match.params.leadId, Object.keys(quoteResponse.entities.quotes)[0])
            })
            .then(res => this.publishAnyway(res.entities.quotes[res.result] || {}))
    }

    publishAnyway(quote) {
        const quoteId = quote.id

        const partnerId = this.props.partner.id

        const leadId = this.props.lead.id

        this.props.publishQuote(leadId, quoteId)
            .then(() => {
                let eventName = ''
                if (this.props.isQuoteClientCustomerChoice) {
                    eventName = PARTNER_ACCEPTED_MEETING
                } else if (quote?.totalAmountExclVat?.amount === 0) {
                    eventName = PARTNER_PUBLISH_QUOTE_WITHOUT_PRICE
                } else {
                    eventName = PARTNER_PUBLISH_QUOTE_WITH_PRICE
                }

                sendSegmentData(this.props.partner, eventName, {
                    leadId: leadId,
                    quoteId: quoteId,
                })
            })
            .then(() => this.props.getProjects({ leadId, partnerId }))
            .then(response => this.props.push(`/projects/${response.result[0]}`))
    }

    refuseCallback = async (quote, refusal_reason) => {
        try {
            await this.props.refuseQuote(quote.lead.id, quote.id, refusal_reason)
            this.props.history.push('/projects')
        } catch (e) {
            this.props.history.go(0)
            toastr.error(this.props.t('projects.quote_info.refusal_expired'))
        }
    }

    handleRefuseQuote = () => {
        const { quote, t } = this.props

        this.props.showModal({
            name: 'showQuoteRefusalModal',
            t: t,
            callback: this.refuseCallback,
            callbackParams: quote,
        })
    }

    setProductLinesRequired = () => {
        this.setState(prevState => ({
            productLinesRequired: !prevState.productLinesRequired
        }));
    }

    render() {
        const {
            quoteCreationAccess,
            leadIsLoading,
            conversation,
            partner,
            quote,
            lead,
            t,
            hasMarketplaceWithoutFeesAccess,
            hasMarketplacePlusLeadAccess,
            hasMarketplaceBasicLeadAccess,
        } = this.props

        const hasMarketplaceAccess = hasMarketplacePlusLeadAccess || hasMarketplaceBasicLeadAccess || hasMarketplaceWithoutFeesAccess

        const lastConversationId = conversation ? conversation.id : null

        if (!quoteCreationAccess) {
            this.props.push('/subscription-info')
        }

        if (leadIsLoading || !lead || this.state.redirecting) {
            return <LoadingAnimation />
        }

        const partnerIsFromExcludedCountry = this.expirationExcludedCountries.includes(partner.geo.code)

        if (
            (!lead.quotesInfo.quoteFee && !lead.quotesInfo.openForReservations && !quote) ||
            (!partnerIsFromExcludedCountry && quote && !quote.publishedAt && moment().isAfter(quote.reservationExpiresAt))
        ) {
            return (
                <ContainerFluid>
                    <LineCard borderColor={dangerColor}>
                        <Card.Content>{t('quotes.reservation_closed')}</Card.Content>
                    </LineCard>
                </ContainerFluid>
            )
        }

        if(!lead.quotesInfo.openForQuotes && !quote.publishedAt) {
            return (
                <ContainerFluid>
                    <LineCard borderColor={dangerColor}>
                        <Card.Content>{t('quotes.lead_closed_for_quotes')}</Card.Content>
                    </LineCard>
                </ContainerFluid>
            )
        }

        if (this.props.isQuoteClientCustomerChoice) {
            return <CustomerChoiceQuotePage lead={lead} quote={quote} partner={partner} onAccept={() => this.publishAnyway(quote)}/>
        }

        return (
            <ContainerFluid>
                <PageTitle identifier="quotes.crate_quote_title" />
                {Boolean(quote) && !quote.publishedAt &&
                <Alert bsStyle="info">
                    <Trans
                        i18nKey="quotes.lead_created"
                    >
                        <FormattedIntervalTimer
                            from={lead.validatedAt}
                            interval={1000}
                            format="longtime"
                        />
                    </Trans>
                    <Clearfix/>
                </Alert>}

                <CreateQuoteForm
                    onSubmit={this.saveQuote}
                    publishQuote={this.publishQuote}
                    refuseQuote={this.handleRefuseQuote}
                    quote={quote}
                    disabled={!hasMarketplaceAccess && !quote}
                    lead={lead}
                    currency={partner.preferences.currency}
                    hasMarketplaceAccess={hasMarketplaceAccess}
                    setProductLinesRequired={this.setProductLinesRequired}
                    productLinesRequired={this.state.productLinesRequired}
                />
                <Flex modifiers={[ 'justifyCenter', 'mB_3' ]} >
                    <Button
                        onClick={this.props.history.goBack}
                        modifiers={[ 'primary', 'p_1', 'mR_2' ]}
                    >
                        <i className="mdi mdi-undo-variant"/> {t('quotes.go_back')}
                    </Button>
                </Flex>
            </ContainerFluid>
        )
    }
}
