import React from 'react'
import { withTranslation } from 'react-i18next'
import { Card } from '../blocks/Card'
import { Flex } from '../elements/Flex'
import Portrait from '../elements/Portrait'

@withTranslation('', { wait: true })
export default class StaticNotification extends React.Component {
    render() {
        const {
            signature,
            sender,
            text,
        } = this.props

        return (
            <Card modifiers={[ 'm_0' ]}>
                <Card.Content>
                    <Flex modifiers={[ 'alignCenter' ]}>
                        <div>
                            <Portrait
                                modifiers={[ 'mR_2' ]}
                                name={sender.name}
                                image={sender.image}
                                size={65}
                            />
                        </div>
                        <div>
                            <Card.Intro>{text}</Card.Intro>
                            {signature && <Card.Intro>{signature}</Card.Intro>}
                        </div>
                    </Flex>
                </Card.Content>
            </Card>
        )
    }
}
