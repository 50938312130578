import { GET_CV, GET_CV_SUCCESS, GET_CV_FAIL } from '../../actions/cv'

const initialState = {
    isLoading: false,
    partnerCertificationIds: new Set([]),
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    case GET_CV:
        return {
            ...state,
        }
    case GET_CV_SUCCESS:
        return {
            ...state,
        }

    case GET_CV_FAIL:
        return {
            ...state,
        }
    default:
        return state
    }
}
