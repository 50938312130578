import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import WidgetItem from '../marketing/widgets/WidgetItem'
import { currentPartnerSelector } from '../../selectors/auth'
import LoadingAnimation from '../utils/LoadingAnimation'
import PageTitle from '../utils/PageTitle'
import { Card } from '../blocks/Card'

@connect(
    state => ({
        partner: currentPartnerSelector(state),
    })
)
@withTranslation('', { wait: true })
export default class WidgetsPage extends React.Component {

    render() {
        const { partner, isLoading, t } = this.props

        if (isLoading || !partner) {
            return <LoadingAnimation />
        }

        return <Card>
            <Card.Content>
                <PageTitle identifier="widgets.title" />
                <h2>{t('widgets.title')}</h2>

                <p className="intro-text">
                    {t('widgets.intro')}
                </p>

                <hr />

                <h3>{t('widgets.simple_widget.header')}</h3>
                <p className="intro-text">
                    {t('widgets.simple_widget.intro')}
                </p>
                <WidgetItem partnerId={partner.id} width="250" height="190" />

                <hr />

                <h3>{t('widgets.widget_with_reviews.header')}</h3>
                <p className="intro-text">
                    {t('widgets.widget_with_reviews.intro')}
                </p>
                <WidgetItem partnerId={partner.id} reviewCount="3" width="250" height="190" />
            </Card.Content>
        </Card>
    }
}
