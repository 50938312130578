import React from 'react'
import { Card, Content, Header } from '../../cards/index'
import MessageFeed from '../../projects/MessageFeed'
import NoteSubmitForm from '../../../containers/layout/NoteSubmitForm'

export const ProjectNotesPage = ({ t, notes, customer, handleNoteSubmit }) =>
    <Card>
        <Header>
            <strong>{t('projects.notes.title')}</strong>
        </Header>
        <Content>
            <NoteSubmitForm
                placeholder={t('projects.notes.form.placeholder')}
                buttonText={t('projects.notes.form.save')}
                onSubmit={handleNoteSubmit}
                supportFiles={false}
            />
            <br />
            {notes.length > 0 && <MessageFeed messages={notes} customer={customer}/>}
        </Content>
    </Card>
