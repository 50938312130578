import styled from 'styled-components'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { mobileBreakpoint } from '../../../theme/variables'

const MODIFIER_COL_CONFIG = {
    mobile: () => `
        .table-mobile-cell {
            display: none;
        }
        @media (max-width: ${mobileBreakpoint}px) {
            .table-mobile-cell {
                display: table-cell;
            }
            th:not(.table-mobile), tbody > tr > td:not(.table-mobile-cell) {
                display: none;
            }
        }
    `,
}

export const Table = styled.table`
  th, td {
    vertical-align: middle;
    border: 1px solid #E6EBF0;
    height: 60px;
    padding: 0px 15px;
  }
  th {
    font-weight: 500;
    background-color: #F8FAFC;
    color: #6F8289;
    text-transform: uppercase;
    font-size: 12px;
  }
  ${applyStyleModifiers(MODIFIER_COL_CONFIG)}
`
