import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from '../../theme/variables_new'

const Item = styled.div`
    border-left: 7px solid #E1E7E9;
    padding: 0 20px;
    margin-bottom: 40px;
`

const Edit = styled.a`
    color: ${colors.red};
    font-size: 16px;
    cursor: pointer;

`

@withTranslation('', { wait: true })
export default class LanguageItem extends Component {

    render() {
        const {
            lang, t, onDelete,
        } = this.props

        return (
            <Item>
                <h4>{lang.name}</h4>
                <span>{t('cv.language.level.' + lang.proficiency)}</span>
                <br/>
                {onDelete && <Edit onClick={() => onDelete(lang.id)}>
                    {t('cv.language.remove')}
                </Edit>}
            </Item>
        )
    }
}

