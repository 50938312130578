import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'
import { currentAuthenticatedUserSelector } from '../selectors/auth'

export const GET_NOTIFICATIONS = 'actions/GET_NOTIFICATIONS'
export const GET_NOTIFICATIONS_SUCCESS = 'actions/GET_NOTIFICATIONS_SUCCESS'
export const GET_NOTIFICATIONS_FAIL = 'actions/GET_NOTIFICATIONS_FAIL'

export const MARK_ALL_NOTIFICATIONS_AS_SEEN = 'actions/MARK_ALL_NOTIFICATIONS_AS_SEEN'
export const MARK_ALL_NOTIFICATIONS_AS_SEEN_SUCCESS = 'actions/MARK_ALL_NOTIFICATIONS_AS_SEEN_SUCCESS'
export const MARK_ALL_NOTIFICATIONS_AS_SEEN_FAIL = 'actions/MARK_ALL_NOTIFICATIONS_AS_SEEN_FAIL'

export const MARK_NOTIFICATION_AS_READ = 'actions/MARK_NOTIFICATION_AS_READ'
export const MARK_NOTIFICATION_AS_READ_SUCCESS = 'actions/MARK_NOTIFICATION_AS_READ_SUCCESS'
export const MARK_NOTIFICATION_AS_READ_FAIL = 'actions/MARK_NOTIFICATION_AS_READ_FAIL'

export const getPartnerUserNotifications = (partnerUserId, createdAfter) => ({
    [CALL_API]: {
        types: [ GET_NOTIFICATIONS, GET_NOTIFICATIONS_SUCCESS, GET_NOTIFICATIONS_FAIL ],
        endpoint: '/notifications',
        schema: schemas.notifications,
        options: {
            body: {
                partnerUserId,
                createdAfter,
            },
        },
    },
})

export const getClientNotifications = (clientId, createdAfter) => ({
    [CALL_API]: {
        types: [ GET_NOTIFICATIONS, GET_NOTIFICATIONS_SUCCESS, GET_NOTIFICATIONS_FAIL ],
        endpoint: '/notifications',
        schema: schemas.notifications,
        options: {
            body: {
                clientId,
                createdAfter,
            },
        },
    },
})

export const markAllNotificationsAsSeen = () => ({
    [CALL_API]: {
        types: [ MARK_ALL_NOTIFICATIONS_AS_SEEN, MARK_ALL_NOTIFICATIONS_AS_SEEN_SUCCESS, MARK_ALL_NOTIFICATIONS_AS_SEEN_FAIL ],
        endpoint: '/notifications/actions',
        suppressLoader: true,
        options: {
            method: 'post',
            body: {
                action: 'mark_seen',
            },
        },
    },
})

export const markNotificationsAsRead = notificationIds => (dispatch, getState) => dispatch({
    [CALL_API]: {
        types: [ MARK_NOTIFICATION_AS_READ, MARK_NOTIFICATION_AS_READ_SUCCESS, MARK_NOTIFICATION_AS_READ_FAIL ],
        endpoint: '/notifications/actions',
        suppressLoader: true,
        options: {
            method: 'post',
            body: {
                action: 'mark_read',
                notificationIds,
            },
        },
    },
}).then(() => {
    const user = currentAuthenticatedUserSelector(getState())
    switch (user.type) {
    case 'partner_user':
        dispatch(getPartnerUserNotifications(user.id))
        break
    case 'client':
        dispatch(getClientNotifications(user.id))
        break
    }
})

export const markAllNotificationsAsRead = () => markNotificationsAsRead([])
