import {
    GET_SUBSCRIPTION_PACKAGES, GET_SUBSCRIPTION_PACKAGES_SUCCESS, GET_SUBSCRIPTION_PACKAGES_FAIL,
} from '../../actions/packages'

const initialState = {
    isLoading: false,
    subscriptionPackageIds: [],
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    case GET_SUBSCRIPTION_PACKAGES:
        return {
            ...state,
            isLoading: true,
        }
    case GET_SUBSCRIPTION_PACKAGES_SUCCESS:
        return {
            ...state,
            subscriptionPackageIds: action.response.result,
            isLoading: false,
        }
    case GET_SUBSCRIPTION_PACKAGES_FAIL:
        return {
            ...state,
            isLoading: false,
        }
    default:
        return state
    }
}
