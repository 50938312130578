import React from 'react'
import { withTranslation } from 'react-i18next'
import ProfilePage from './ProfilePage'
import CVPage from './CVPage'
import WidgetsPage from './WidgetsPage'
import RatingsPage from './RatingsPage'
import { ContainerFluid } from '../cards'
import SidebarPageTemplate from '../../containers/layout/SidebarPageTemplate'
import { hasSubscriptionFeaturesAccessSelector } from '../../selectors/access'
import { connect } from 'react-redux'
import { currentPartnerSelector } from '../../selectors/auth'

@connect(
    state => ({
        showSubscriptionMenuItems: hasSubscriptionFeaturesAccessSelector(state),
        partner: currentPartnerSelector(state),
    }),
)
@withTranslation('', { wait: true })
export default class MarketingPage extends React.Component {
    render() {
        const {
            showSubscriptionMenuItems,
            location: { hash },
            partner,
            match,
            t,
        } = this.props

        const navLinks = [
            {
                pathname: `${match.url}/profile`,
                relatedComponent: <ProfilePage />,
                mainText: t('navigation.profile'),
                secondaryText: t('navigation.profile_description'),
            },
            {
                pathname: `${match.url}/cv`,
                relatedComponent: <CVPage />,
                mainText: t('navigation.cv'),
                secondaryText: t('navigation.cv_description'),
                hidden: !showSubscriptionMenuItems,

            },
            {
                pathname: `${match.url}/widgets`,
                relatedComponent: <WidgetsPage />,
                mainText: t('navigation.widgets'),
                secondaryText: t('navigation.widgets_description'),
                hidden: !showSubscriptionMenuItems,
            },
            {
                pathname: `${match.url}/reviews`,
                relatedComponent: <RatingsPage />,
                mainText: t('navigation.reviews'),
                secondaryText: t('navigation.reviews_description'),
            },
        ]

        return <ContainerFluid>
            <SidebarPageTemplate
                navLinks={navLinks}
                hash={hash}
            />
        </ContainerFluid>
    }
}
