import React from 'react'
import moment from 'moment'
import FormattedDate from './FormattedDate'

export default class FormattedIntervalTimer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            intervalId: setInterval(this.forceUpdate.bind(this), props.interval),
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId)
    }

    dateDependingOnFormat() {
        const {
            from,
            until,
        } = this.props

        const diffInSeconds = moment(until).utcOffset(0).diff(moment(from))

        return moment(Math.abs(diffInSeconds) > 0 ? diffInSeconds : 0).utcOffset(0)
    }

    render() {
        return <FormattedDate date={this.dateDependingOnFormat()} format={this.props.format}/>
    }
}
