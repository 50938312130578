import React from 'react'
import { connect } from 'react-redux'
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from '@stripe/react-stripe-js'
import { withTranslation } from 'react-i18next'
import { currentPartnerSelector, currentPartnerUserSelector } from '../../selectors/auth'
import PageTitle from '../utils/PageTitle'
import { Col, Media, Modal, Row } from 'react-bootstrap'
import { Card, Content, Header } from '../cards/index'
import { addStripeCard, deleteStripeCard, getCards } from '../../actions/billing'
import { createStripeSetupIntent, getStripeApiKey, saveStripePaymentMethod } from '../../actions/stripe'
import { migratePartner } from '../../actions/cashier'
import moment from 'moment'
import { Star, X } from 'react-feather'
import { Flex } from '../elements/Flex'
import { Button } from '../elements/ButtonNew'
import { Text } from '../elements/text/Text'
import { getPartnerSubscriptions } from '../../actions/subscriptions'
import { showModal } from '../../actions/modal'
import { StripePaymentMethodSetup } from '../StripePaymentMethodSetup'

@connect(
    (state) => ({
        cards: state.pages.billing.cardsIds.map(id => state.entities.paymentCards[id]),
        partner: currentPartnerSelector(state),
        partnerUser: currentPartnerUserSelector(state),
        subscriptions: state.entities.subscriptions,
    }),
    {
        createStripeSetupIntent,
        saveStripePaymentMethod,
        getCards,
        showModal,
        addStripeCard,
        getStripeApiKey,
        deleteStripeCard,
        getPartnerSubscriptions,
        migratePartner,
    }
)
@withTranslation('', { wait: true })
export default class BillingPage extends React.Component {
    stripePromise = null
    stripe = null
    elements = null

    constructor(props) {
        super(props)

        this.state = {
            error: null,
            showModal: false,
            cardId: null,
            stripeSetupIntentClientSecret: null,
            savingPaymentMethod: false,
        }
    }

    async componentDidMount() {
        const { partner } = this.props
        const response = await this.props.getStripeApiKey(partner.geo.code)
        const q = new URLSearchParams(window.location.search)
        if (q.get('setup_intent')) {
            try {
                await this.saveStripePaymentMethod(q.get('setup_intent'))
                window.location = '/account/billing'
            } catch (e) {
                window.location = '/account/billing?error=true'
            }
            return
        }
        const setupIntentResponse = await this.props.createStripeSetupIntent(partner)
        this.setState({ stripeSetupIntentClientSecret: setupIntentResponse.clientSecret })
        this.stripePromise = loadStripe(response.applicationKey)
        this.props.getCards(partner)
        this.props.getPartnerSubscriptions(partner.id)
    }

    confirm() {
        this.props.deleteStripeCard(this.state.cardId)
        this.setState({ showModal: false, cardId: null })
    }

    async saveStripePaymentMethod(setupIntent) {
        this.setState({ savingPaymentMethod: true })
        await this.props.saveStripePaymentMethod(this.props.partner, setupIntent)
        this.setState({ savingPaymentMethod: false })
    }

    cancelMarketplaceSubscription = async subscriptionId => {
        this.props.showModal({
            subscriptionId,
            partnerId: this.props.partner.id,
            name: 'cancelMarketplaceSubscription',
        })
    }

    migratePartnerToCashierSolution = async () => {
        try{
            await this.props.migratePartner(this.props.partner)
        }catch (e){}
        window.location = '/account/subscription'
    }

    render() {
        const {
            t,
            cards,
            subscriptions,
            partner,
        } = this.props

        const marketplaceSubscription = subscriptions && Object.values(subscriptions).find(subscription => subscription.packageType === 'Ageras Marketplace Package')

        return (
            <div className="card billing-card">
                <PageTitle identifier="subscription.billing.header" />
                <h2>{ t('billing_page.page_header') }</h2>
                <p className="intro-text">{ t('billing_page.info_text') }</p>
                <hr />
                {cards.length ?
                    <Row className="account-billing">
                        {cards.sort(a => a.isPreferred === true ? -1 : 1).map(card =>
                            <Col key={'payment-card' + card.id } sm={6}>
                                <Card>
                                    <Media>
                                        <Media.Body className="credit-info">
                                            <Content padding="20px 20px 20px 10px">
                                                {cards.length > 1 && !card.isPreferred &&
                                                <X
                                                    onClick={() => this.setState({ showModal: true, cardId: card.id })}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                }
                                                {card.isPreferred &&
                                                <Star color="#fd0"/>
                                                }
                                                <i className={`mdi ${card.cardType === 'sepa_debit' ? 'mdi-bank' : 'mdi-credit-card'}`}
                                                   style={{ fontSize: '32px' }} />
                                                <br />
                                                <span style={{ fontWeight: 500 }}>{t(`payment.type.${card.cardType}.label`)}</span>
                                                <br />
                                                <span style={{ fontSize: '30px' }}>•••• •••• •••• {card.cardMask.slice(-4)}</span>
                                                <br />
                                                {card.expiration.month ?
                                                    <span>
                                                        {t('billing_page.expires_card')}:
                                                        {moment(card.expiration.month + '-' + card.expiration.year, 'MM-YYYY').format(' MMMM YYYY')}
                                                    </span>
                                                    :
                                                    <br />
                                                }
                                            </Content>
                                        </Media.Body>
                                    </Media>
                                </Card>
                            </Col>
                        )}
                        {!partner.isStripeCustomer &&
                            <Col>
                                <Button onClick={() => { this.migratePartnerToCashierSolution() }} modifiers={[ 'primary', 'bold', 'small' ]}>
                                    {'Migrate'}
                                </Button>
                            </Col>
                        }
                    </Row> :
                    <div>{t('subscription.no_cards')}</div>
                }
                <hr />
                {this.state.stripeSetupIntentClientSecret &&
                <Card>
                    <Header>
                        { t('billing_page.payment_card_header') }
                    </Header>
                    {this.state.error && <div className="alert alert-danger">{this.state.error}</div>}
                    <Content>
                        <Elements stripe={this.stripePromise} options={{
                            clientSecret: this.state.stripeSetupIntentClientSecret,
                        }}>
                            <StripePaymentMethodSetup
                                returnUrl={window.location.protocol + '//' + window.location.host + window.location.pathname}
                                t={t}
                            />
                        </Elements>
                    </Content>
                </Card>
                }

                {marketplaceSubscription &&
                <React.Fragment>
                    <hr/>
                    <Flex modifiers={[ 'justifySpaceBetween', 'mT_2', 'mX_2' ]}>
                        <Flex modifiers={[ 'directionColumn' ]}>
                            <Text>{t('marketplace.cancellation.package_title')}</Text>
                            <Text modifiers={[ 'small' ]}>{t('marketplace.cancellation.active_from')}: {moment(marketplaceSubscription.startsAt).format('Y/M/D')}</Text>
                        </Flex>
                        <div>
                            {marketplaceSubscription.canceledAt &&
                            <Text modifiers={[ 'small' ]}>{t('marketplace.cancellation.expires_at')}: {moment(marketplaceSubscription.churnedAt).format('Y/M/D')}</Text>}
                            {!marketplaceSubscription.canceledAt &&
                            <Button onClick={() => this.cancelMarketplaceSubscription(marketplaceSubscription.id)}
                                     modifiers={[ 'danger', 'bold', 'small' ]}>{t('marketplace.cancellation.open_modal_btn_text')}</Button>}
                        </div>
                    </Flex>
                </React.Fragment>}

                <Modal show={this.state.showModal}
                       animation={true}
                       onHide={() => this.setState({ showModal: false, cardId: null })}>
                    <Modal.Header closeButton />
                    <Modal.Body>
                        { t('billing_page.confirm_modal_text') }

                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.setState({ showModal: false })}>{ t('billing_page.cancel') }</Button>
                        <Button onClick={() => this.confirm()} className="btn btn-action btn-primary" >{ t('billing_page.proceed') }</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
