
export const colors = {
    darkBlue: '#042D52',
    blue: '#0A7AE0',
    blueHover: '#095FAD',
    lightBlue: '#16BEFF',
    lightGreen: '#E1F9ED',
    green: '#29D380',
    greenHover: '#08B05E',
    orange: '#f9AE1D',
    red: '#EA3152',
    grey: '#93A7B9',
    lightGrey: '#EFF9FF',
    fontLight: '#8196A8',
    backgroundGrey: '#F9FAFC',
    white: '#FFFFFF',
    opacity: {
        light: '0.15',
        medium: '0.5',
        high: '0.7',
        full: '1',
    },
}

export const fonts = {
    weight: {
        normal: '400',
        semibold: '500',
        bold: 'bold',
    },
    size: {
        xxs: '11px',
        xs: '14px',
        s: '16px',
        m: '20px',
        l: '22px',
        xl: '30px',
        xxl: '42px',
    },
    lineHeight: {
        18: '18px',
        16: '16px',
        20: '20px',
        24: '24px',
        28: '28px',
        40: '40px',
        50: '50px',
    },
}

export const spacing = {
    10: '10px',
    12: '12px',
    20: '20px',
    24: '24px',
    32: '32px',
    34: '34px',
    38: '38px',
    42: '42px',
    56: '56px',
    64: '64px',
    72: '72px',
    94: '94px',
}

export const border = {
    radius: {
        small: '4px',
        medium: '8px',
    },
}

export const bkpInt = {
    small: 640,
    medium: 960,
    large: 1180,
    mediumLarge: 1200,
    extraLarge: 1600,
}

export const breakpoints = {
    small: `${bkpInt.small}px`,
    medium: `${bkpInt.medium}px`,
    desktopSmall: `${bkpInt.large}px`,
    desktopMedium: `${bkpInt.mediumLarge}px`,
    desktopLarge: `${bkpInt.extraLarge}px`,
}
