import {
    GET_CARDS,
    GET_CARDS_SUCCESS,
    GET_CARDS_FAIL,
    START_PAYMENT_SUCCESS,
    GET_WINDOW_CONFIG_SUCCESS,
    UNMOUNT_PAYMENT_WINDOW_SUCCESS,
    DELETE_STRIPE_CARD_SUCCESS,
    COMPLETE_INVOICE_PAYMENT,
    COMPLETE_INVOICE_PAYMENT_SUCCESS,
    COMPLETE_INVOICE_PAYMENT_FAIL,
    CREATE_PAYMENT_INTENT,
    CREATE_PAYMENT_INTENT_SUCCESS,
    CREATE_PAYMENT_INTENT_FAIL,
    CONFIRM_PAYMENT_INTENT,
    CONFIRM_PAYMENT_INTENT_SUCCESS,
    CONFIRM_PAYMENT_INTENT_FAIL,
    VALIDATE_PAYMENT_INTENT,
    VALIDATE_PAYMENT_INTENT_SUCCESS,
    VALIDATE_PAYMENT_INTENT_FAIL,
} from '../../actions/billing'

const initialState = {
    isLoading: false,
    paymentStarted: false,
    epay: null,
    cardsIds: [],
    isCompletingPayment: false,
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_CARDS:
            return {
                ...state,
                isLoading: true,
            }
        case GET_CARDS_SUCCESS:
            return {
                ...state,
                cardsIds: action.response.result,
                isLoading: false,
            }
        case GET_CARDS_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        case START_PAYMENT_SUCCESS:
            return {
                ...state,
                paymentStarted: true,
            }
        case GET_WINDOW_CONFIG_SUCCESS:
            return {
                ...state,
                epay: action.epay,
            }
        case UNMOUNT_PAYMENT_WINDOW_SUCCESS:
            return {
                ...state,
                epay: null,
                paymentStarted: false,
            }
        case DELETE_STRIPE_CARD_SUCCESS:
            const cardsIds = new Set(state.cardsIds)
            cardsIds.delete(action.cardId)
            return {
                ...state,
                cardsIds: [ ...cardsIds ],
            }
        case COMPLETE_INVOICE_PAYMENT:
        case CREATE_PAYMENT_INTENT:
        case CONFIRM_PAYMENT_INTENT:
        case VALIDATE_PAYMENT_INTENT:
            return {
                ...state,
                isCompletingPayment: true,
            }
        case COMPLETE_INVOICE_PAYMENT_SUCCESS:
        case CREATE_PAYMENT_INTENT_SUCCESS:
        case CONFIRM_PAYMENT_INTENT_SUCCESS:
        case VALIDATE_PAYMENT_INTENT_SUCCESS:
            return {
                ...state,
                isCompletingPayment: false,
            }
        case COMPLETE_INVOICE_PAYMENT_FAIL:
        case CREATE_PAYMENT_INTENT_FAIL:
        case CONFIRM_PAYMENT_INTENT_FAIL:
        case VALIDATE_PAYMENT_INTENT_FAIL:
            return {
                ...state,
                isCompletingPayment: false,
            }
        default:
            return state
    }
}
