import styled from 'styled-components'
import { breakpoints, colors } from '../../../theme/variables_new'
import { Label } from '../../forms/inputs_new/FormControl'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { MODIFIER_MARGIN_CONFIG, MODIFIER_PADDING_CONFIG } from '../../elements/modifiers/spaceModifiers'
import { Text } from '../../elements/text/Text'
import { Modal } from 'react-bootstrap'

export const ProductPriceBlockGrid = styled.div`
    display: grid;
    grid-template-columns: 180px auto;
    grid-gap: 12px;

    @media (max-width: ${breakpoints.desktopMedium}) {
        grid-template-columns: 1fr;
    }
`

export const ProductRevenueSegmentBlockGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 0.18fr;
    grid-gap: 12px;

    @media (max-width: ${breakpoints.desktopMedium}) {
        grid-template-columns: 1fr;
    }
`

export const InfiniteMaximumRevenueSwitcher = styled.div`
    align-content: center;
    margin: auto;
`

export const ProductRevenueSegmentCopyBlockGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 0.7fr 0.7fr 0.18fr;
    grid-gap: 12px;

    @media (max-width: ${breakpoints.desktopMedium}) {
        grid-template-columns: 1fr;
    }
`

export const ProductRevenueSegmentCopy = styled.div`
    align-content: center;
    margin: auto;
`

export const ProductRevenueSegmentCopyLabel = styled(Label)`
    justify-self: center;
    margin-right: 20px;

    @media (max-width: ${breakpoints.desktopMedium}) {
        margin-right: 0;
        justify-self: end;
    }
`

export const CenteredModal = styled(Modal)`
    .modal-dialog {
        width: 520px;
        margin-top: 300px !important;
    }
`

export const ProductRevenueSegmentComment = styled.div`
    display: inline-flex;
    justify-content: flex-start;
    width: 70%;
    @media (max-width: ${breakpoints.desktopMedium}) {
        width: 60%;
    }
`

export const ProductRevenueSegmentActionButtonsContainer = styled.div`
    display: inline-flex;
    justify-content: flex-end;
    width: 30%;
    @media (max-width: ${breakpoints.desktopMedium}) {
        width: 40%;
    }
`

export const ProductPriceFixedPriceSwitcher = styled.div`
    margin-top: 10px;
    width: 300px;
`

export const ProductPriceSubGrid = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 12px;
`

export const ProductPriceBreakpointsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 8px;
`

export const IconRowBtn = styled.button`
    border: none;
    background: none;
    display: inline-block;
    transition: color .2s ease;

    ${({ save }) => save && `
        &:hover {
            color: ${colors.green}
        }
    `}

    ${({ remove }) => remove && `
        &:hover {
            color: ${colors.red}
        }
    `}
`

export const IconItemBtn = styled.button`
    display: flex;
    grid-column: span 3;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: transparent;
    padding: 10px;
    margin: 16px 0;
    border-radius: 6px;
    color: ${colors.grey};
    border: 2px dashed ${colors.grey};
`

export const IconItemBtnSmall = styled.button`
    display: flex;
    width: 70px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    background: transparent;
    padding: 10px;
    border-radius: 6px;
    color: ${colors.grey};
    border: 2px dashed ${colors.grey};
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)}
`
