import {
    GET_REFUNDS, GET_REFUNDS_SUCCESS, GET_REFUNDS_FAIL,
} from '../../actions/refunds'

const initialState = {
    items: [],
    isLoading: false,
    pagination: {},
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    case GET_REFUNDS:
        return {
            ...state,
            isLoading: true,
        }
    case GET_REFUNDS_SUCCESS:
        return {
            ...state,
            items: action.response.result,
            pagination: action.response.pagination,
            isLoading: false,
        }
    case GET_REFUNDS_FAIL:
        return {
            ...state,
            isLoading: false,
        }
    default:
        return state
    }
}
