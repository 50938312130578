import React from 'react'
import { Button } from './Button'
import { CopyToClipboard } from './CopyToClipboard'

export default class ActionButton extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            actionCompleted: false,
        }
    }

    onClick() {
        const {
            loading,
            actionCompleted,
        } = this.state
        if(loading || actionCompleted && !this.props.allowReClick) {
            return
        }
        this.setState({ loading: true })
        this.props.action()
            .then(() => {
                this.setState({
                    loading: false,
                    actionCompleted: true,
                })
            }, () => {
                this.setState({
                    loading: false,
                    actionCompleted: false,
                })
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    actionCompleted: false,
                })
            })
    }

    render() {
        const {
            hiddenData,
            label,
            icon,
            modifiers,
            loadingText,
            disabled,
        } = this.props

        const {
            loading,
            actionCompleted,
        } = this.state

        return (
            <Button
                modifiers={modifiers}
                onClick={this.onClick.bind(this)}
                disabled={disabled}
            >
                { icon && <i className={icon} /> }&nbsp;
                { loading ?
                    <span>{loadingText} <i className="fa fa-spin fa-spinner" /></span> :
                    (actionCompleted && <CopyToClipboard label={hiddenData}/>) || label
                }
            </Button>
        )
    }
}
