import { useEffect, useState } from "react";

export default function useStripeSetupIntentRedirect(){
    const [isLoading, setIsLoading] = useState(true);
    const [isRedirect, setIsRedirect] = useState(null);
    const [setupIntentId, setSetupIntentId] = useState(null);

    useEffect(() => {
        // Check to see if this is a redirect from creating a stripe setup intent
        const query = new URLSearchParams(window.location.search);

        const setupIntentId = query.get('setup_intent');
        if(setupIntentId){
            setIsRedirect(true);
            setSetupIntentId(setupIntentId);
        }else{
            setIsRedirect(false);
        }

        setIsLoading(false);
    }, []);

    return { isLoading, isRedirect, setupIntentId };
}
