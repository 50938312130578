import React, { ChangeEvent } from 'react'
import styled from 'styled-components'
import Text from '../../texts/Text'

export interface CheckboxToggleProps {
    labelLeft?: string
    labelRight?: string
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void
    name?: string
    required?: boolean
    disabled?: boolean
    value?: boolean
}

const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
`

const CheckboxBox = styled.span<CheckboxToggleProps>`
    cursor: pointer;
    text-indent: -9999px;
    width: 40px;
    height: 20px;
    display: block;
    border-radius: 100px;
    position: relative;
    ${({ theme }) => theme.inputs.checkboxes.styles};

    &::after {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        width: 10px;
        height: 10px;
        background: #fff;
        border-radius: 90px;
        transition: 0.3s;
    }
`

const CheckboxWrapper = styled.label<CheckboxToggleProps>`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    color: ${({ theme }) => theme.inputs.colors.label};
    cursor: pointer;
    user-select: none;

    & ${CheckboxInput}:checked ~ ${CheckboxBox} {
        background-color: ${({ theme }) => theme.colors.baseBlue};
        &::after {
            left: calc(100% - 5px);
            transform: translateX(-100%);
        }
    }
`

const CheckboxToggle = ({
    name,
    onChange,
    labelLeft,
    labelRight,
    required,
    disabled,
    value,
    ...props
}:CheckboxToggleProps) => {
    return (
        <CheckboxWrapper htmlFor={name} {...props}>
            {labelLeft && <Text>{labelLeft}</Text>}
            <CheckboxInput
                {...props}
                name={name}
                required={required}
                onChange={onChange}
                id={name}
                disabled={disabled}
                checked={value}
            />
            <CheckboxBox />
            {labelRight && <Text>{labelRight}</Text>}
        </CheckboxWrapper>
    )
}

export default CheckboxToggle
