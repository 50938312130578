import { Col } from 'react-bootstrap'
import styled from 'styled-components'
import { applyResponsiveStyleModifiers, applyStyleModifiers } from 'styled-components-modifiers'
import { mobileBreakpoint } from '../../../theme/variables'

const MODIFIER_COL_CONFIG = {
    smHiddenDown: () => `
        @media (max-width: ${mobileBreakpoint}px) {
            display: none;
        }
    `,
}

export default styled(Col)`
  ${applyStyleModifiers(MODIFIER_COL_CONFIG)}
  ${applyResponsiveStyleModifiers(MODIFIER_COL_CONFIG)}
`
