import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const GET_CONVERSATIONS = 'projects/GET_CONVERSATIONS'
export const GET_CONVERSATIONS_SUCCESS = 'projects/GET_CONVERSATIONS_SUCCESS'
export const GET_CONVERSATIONS_FAIL = 'projects/GET_CONVERSATIONS_FAIL'
export const CLEAN_UP_STORE_CONVERSATION = 'projects/CLEAN_UP_STORE_CONVERSATION'

export const GET_CONVERSATION = 'projects/GET_CONVERSATION'
export const GET_CONVERSATION_SUCCESS = 'projects/GET_CONVERSATION_SUCCESS'
export const GET_CONVERSATION_FAIL = 'projects/GET_CONVERSATION_FAIL'

export const GET_CONVERSATION_MESSAGES = 'projects/GET_CONVERSATION_MESSAGES'
export const GET_CONVERSATION_MESSAGES_SUCCESS = 'projects/GET_CONVERSATION_MESSAGES_SUCCESS'
export const GET_CONVERSATION_MESSAGES_FAIL = 'projects/GET_CONVERSATION_MESSAGES_FAIL'

export const CREATE_CONVERSATION = 'projects/CREATE_CONVERSATION'
export const CREATE_CONVERSATION_SUCCESS = 'projects/CREATE_CONVERSATION_SUCCESS'
export const CREATE_CONVERSATION_FAIL = 'projects/CREATE_CONVERSATION_FAIL'

export const UPDATE_CONVERSATIONS_FAIL = 'projects/UPDATE_CONVERSATIONS_FAIL'
export const UPDATE_CONVERSATIONS_SUCCESS = 'projects/UPDATE_CONVERSATIONS_SUCCESS'
export const UPDATE_CONVERSATIONS = 'projects/UPDATE_CONVERSATIONS'

export const GET_UNREAD_PARTICIPANT_MESSAGES = 'projects/GET_UNREAD_PARTICIPANT_MESSAGES'
export const GET_UNREAD_PARTICIPANT_MESSAGES_SUCCESS = 'projects/GET_UNREAD_PARTICIPANT_MESSAGES_SUCCESS'
export const GET_UNREAD_PARTICIPANT_MESSAGES_FAIL = 'projects/GET_UNREAD_PARTICIPANT_MESSAGES_FAIL'

export const GET_PARTICIPANT_MESSAGES = 'projects/GET_PARTICIPANT_MESSAGES'
export const GET_PARTICIPANT_MESSAGES_SUCCESS = 'projects/GET_PARTICIPANT_MESSAGES_SUCCESS'
export const GET_PARTICIPANT_MESSAGES_FAIL = 'projects/GET_PARTICIPANT_MESSAGES_FAIL'

export const getConversations = (criteria, append = false) => ({
    [CALL_API]: {
        types: [ GET_CONVERSATIONS, GET_CONVERSATIONS_SUCCESS, GET_CONVERSATIONS_FAIL ],
        endpoint: '/conversations',
        schema: schemas.projectConversations,
        options: {
            body: criteria,
        },
    },
    append,
})

export const readConversationMessages = conversationId => ({
    [CALL_API]: {
        types: [ UPDATE_CONVERSATIONS, UPDATE_CONVERSATIONS_SUCCESS, UPDATE_CONVERSATIONS_FAIL ],
        endpoint: `/conversations/${conversationId}/actions`,
        schema: schemas.projectConversation,
        options: {
            method: 'post',
            body: {
                action: 'mark_all_as_read',
            },
        },
        suppressLoader: true,
    },
})

export const getConversation = (conversationId, criteria) => ({
    [CALL_API]: {
        types: [ GET_CONVERSATION, GET_CONVERSATION_SUCCESS, GET_CONVERSATION_FAIL ],
        endpoint: `/conversations/${conversationId}`,
        schema: schemas.projectConversation,
        options: {
            body: criteria,
        },
    },
})

export const getMessages = (conversationId, criteria) => ({
    [CALL_API]: {
        types: [ GET_CONVERSATION_MESSAGES, GET_CONVERSATION_MESSAGES_SUCCESS, GET_CONVERSATION_MESSAGES_FAIL ],
        endpoint: `/conversations/${conversationId}/messages`,
        schema: schemas.conversationMessages,
        options: {
            body: criteria,
        },
    },
})

export const createConversation = criteria => ({
    [CALL_API]: {
        types: [ CREATE_CONVERSATION, CREATE_CONVERSATION_SUCCESS, CREATE_CONVERSATION_FAIL ],
        endpoint: '/conversations',
        schema: schemas.projectConversation,
        options: {
            method: 'post',
            body: criteria,
        },
    },
})

export const createConversationWithParticipants = (participants, criteria) => createConversation({
    participants,
    ...criteria,
})

export const getParticipantMessages = (criteria, type) => ({
    [CALL_API]: {
        types: type || [ GET_PARTICIPANT_MESSAGES, GET_PARTICIPANT_MESSAGES_SUCCESS, GET_PARTICIPANT_MESSAGES_FAIL ],
        endpoint: '/conversations/messages',
        schema: schemas.conversationMessages,
        options: {
            body: criteria,
        },
    },
})

export const getUnreadParticipantMessages = criteria =>
    getParticipantMessages(
        criteria,
        [ GET_UNREAD_PARTICIPANT_MESSAGES, GET_UNREAD_PARTICIPANT_MESSAGES_SUCCESS, GET_UNREAD_PARTICIPANT_MESSAGES_FAIL ]
    )
