import React from 'react'
import { withTranslation } from 'react-i18next'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { FieldGroup } from '../forms/inputs/FieldGroup'
import { Button } from '../elements/Button'
import { changePassword } from '../../actions/auth'
import { Card } from '../blocks/Card'
import PageTitle from '../utils/PageTitle'

const form = 'passwordChange'

const selector = formValueSelector(form)

@connect(
    state => ({
        currentPassword: selector(state, 'currentPassword'),
        newPassword: selector(state, 'newPassword'),
        newPasswordConfirmation: selector(state, 'newPasswordConfirmation'),
    }),
    {
        changePassword,
    }
)
@reduxForm({ form })
@withTranslation('', { wait: true })
export default class ChangePasswordForm extends React.Component {

    onSubmit = async ({ currentPassword, newPassword }) => {
        await this.props.changePassword(currentPassword, newPassword)
        this.props.reset()
    }

    render() {
        const {
            handleSubmit,
            submitting,
            pristine,
            t,
        } = this.props

        return (
            <Card>
                <PageTitle identifier="user_password.title" />
                <Card.Content>
                    <Card.Title>{t('user_password.title')}</Card.Title>
                    <Card.Intro>{t('user_password.intro')}</Card.Intro>
                    <form onSubmit={handleSubmit(this.onSubmit)}>
                        <Field
                            component={FieldGroup}
                            label={t('user_password.password_current')}
                            name="currentPassword"
                            type="password"
                        />
                        <Field
                            component={FieldGroup}
                            label={t('user_password.password_new')}
                            name="newPassword"
                            type="password"
                        />
                        <Field
                            component={FieldGroup}
                            label={t('user_password.password_new_repeat')}
                            name="newPasswordConfirmation"
                            type="password"
                        />
                        <br />
                        <Button
                            disabled={pristine || submitting}
                            type="submit"
                            modifiers={[ 'action', 'fullWidth', 'p_2' ]}
                        >
                            {t('user_password.update_password')}
                        </Button>
                    </form>
                </Card.Content>
            </Card>
        )
    }
}
