import styled from 'styled-components'
import { mobileBreakpoint } from '../../../theme/variables'

export default styled.div.attrs({ className: 'mdi mdi-chevron-right' })`
  margin-left: 20px;
  font-size: 22px;
  @media (min-width: ${mobileBreakpoint}px) {
      display: none;
  }
`
