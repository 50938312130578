import i18n from 'i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import config from './config.js'
import portalPicker from './utils/portalPicker'
import moment from 'moment'
import countries from 'i18n-iso-countries';

const portal = portalPicker()

countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
countries.registerLocale(require('i18n-iso-countries/langs/da.json'))
countries.registerLocale(require('i18n-iso-countries/langs/sv.json'))
countries.registerLocale(require('i18n-iso-countries/langs/no.json'))
countries.registerLocale(require('i18n-iso-countries/langs/de.json'))
countries.registerLocale(require('i18n-iso-countries/langs/nl.json'))

export const preferredLanguagesForIso6391 = {
    dk: 'da',
    nl: 'nl',
    se: 'sv',
    us: 'en',
    de: 'de',
    no: 'no',
    '': 'en',
}

i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .init({
        fallbackLng: 'en',
        debug: config.debug,
        load: 'currentOnly',
        lowerCaseLng: true,
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: `/locales/${portal}/{{lng}}/{{ns}}.json?_t=${Date.now() / 1000 | 0}`,
            allowMultiLoading: false,
        },
    })

export default i18n

export const changeLanguage = language => {
    const languageMapping = {
        nl: 'nl',
        dk: 'da',
        se: 'sv',
        no: 'nb',
        de: 'de',
        at: 'de',
        ch: 'de',
        uk: 'en-GB',
        us: 'en-US',
    }
    language = languageMapping[language]
    i18n.changeLanguage(language)
    moment.locale(language)
}
