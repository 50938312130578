import React from 'react'
import { Provider } from 'react-redux'
import { Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { I18nextProvider } from 'react-i18next'
import ReduxToastr, { actions as toastr } from 'react-redux-toastr'
import i18n from './i18n'
import portalPicker from './utils/portalPicker'
import configureStore from './configureStore'
import PartnerApp from './components/PartnerApp'
import ClientApp from './components/ClientApp'
import 'react-select/scss/default.scss'
import './theme/style.scss'
import { ThemeProvider } from 'styled-components'
import light from 'components/src/themes/light'
import LineBreaks from 'components/src/themes/globals/LineBreaks'
import Mont from 'components/src/themes/fonts/Mont'
import ModalHandler from 'components/src/modals/ModalHandler'
import Segment from './components/analytics/Segment'
import Flex from 'components/src/layout/Flex'
import Loader from 'components/src/Loader'

/* IE9, IE10 and IE11 requires all of the following polyfills. IE 11 has end of life on August 17, 2021 */
import 'core-js/stable/symbol'
import 'core-js/stable/object'
import 'core-js/stable/function'
import 'core-js/stable/parse-int'
import 'core-js/stable/parse-float'
import 'core-js/stable/number'
import 'core-js/stable/math'
import 'core-js/stable/string'
import 'core-js/stable/date'
import 'core-js/stable/array'
import 'core-js/stable/regexp'
import 'core-js/stable/map'
import 'core-js/stable/set'
/**/

const history = createBrowserHistory()

const store = configureStore(undefined, history)

const rootEl = document.getElementById('root')

const component = () => {
    const portal = portalPicker()
    switch(portal) {
    case 'partners':
        return PartnerApp
    case 'clients':
        return ClientApp
    }
    throw new Error(`Unable to find app component for portal: ${portal}`)
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
})

function GlobalLoadingComponent(){
    return (
        <ThemeProvider theme={light}>
            <Flex height="100%" width="100%" justifyContent="center" alignItems="center">
                <Loader/>
            </Flex>
        </ThemeProvider>
    )
}

const render = Component =>
    ReactDOM.render(
        <React.Suspense fallback={<GlobalLoadingComponent/>}>
            <QueryClientProvider client={queryClient}>
                <Provider store={store}>
                    <I18nextProvider i18n={i18n}>
                        <ThemeProvider theme={light}>
                            <ModalHandler zIndex={1000}>
                                <ConnectedRouter history={history}>
                                    <Route path="/" component={Component} />
                                </ConnectedRouter>
                            </ModalHandler>
                            <ReduxToastr />
                            <Mont apply={true}/>
                            <LineBreaks/>
                        </ThemeProvider>
                    </I18nextProvider>
                </Provider>
            </QueryClientProvider>
            <Segment />
        </React.Suspense>,
        rootEl,
    )

window.onunhandledrejection = event => {
    let reason = event &&
        event.reason ?
            event.reason.error ?
                    event.reason.error :
                    event.reason :
            event
    // store.dispatch(toastr.error(`Unhandled promise rejection. Reason: ${reason}`))
}

try {
    render(component())
    if (module.hot) module.hot.accept('./components/ClientApp', () => render(component()))
    if (module.hot) module.hot.accept('./components/PartnerApp', () => render(component()))
} catch (e) {
    store.dispatch(toastr.error(`Application error: ${e.message}`))
    throw e
}
