import { useAuth } from 'ageras-api-client/src/auth'
import { useAgerasApi } from 'ageras-api-client/src'
import { PartnerResource, PartnersApiFetchParamCreator } from 'ageras-api-client/src/api'


function usePartner() {
    const auth = useAuth()
    const { data, isLoading, error } = useAgerasApi<PartnerResource>(PartnersApiFetchParamCreator().partnersGet(
        String(auth.partnerUser?.partnerId)
    ))

    return { partner: data, isLoading, error }
}

export default usePartner
