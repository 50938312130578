import React from 'react'
import { Field, FieldArray } from 'redux-form'
import { TextareaGroup } from '../../forms/inputs/TextareaGroup'
import { Card } from '../../blocks/Card'

const CompanyIntroductionField = ({ fields }) => {
    if(!fields) return null

    const dataFields = fields.getAll()

    return <div>
        {fields.map((member, index) => dataFields[index].identifier === 'company_introduction' &&
            <Field
                label={dataFields[index].label}
                name={`${member}.selected[0].title`}
                component={TextareaGroup}
            />
        )}
    </div>
}

export const CompanyDescriptionContent = ({ t }) =>
    <Card.Content className="partner-company-description">
        <Card.Title>{t('company_profile.description.title')}</Card.Title>
        <Card.Intro>{t('company_profile.description.intro')}</Card.Intro>
        <Field
            label={t('company_profile.description.company_description_website')}
            name="companyInformation"
            component={TextareaGroup}
        />
        <br/>
        <Card.Intro>{t('company_profile.introduction_message')}</Card.Intro>
        <FieldArray name="attributes" component={CompanyIntroductionField} t={t} />
    </Card.Content>
