import React from 'react'
import { Card } from '../../../../../components/blocks/Card/index'
import { Flex } from '../../../../../components/elements/Flex'
import styled from 'styled-components'
import FormattedAmount from '../../../../../components/utils/FormattedAmount'
import { cardSeparatorColor, primaryColor } from '../../../../../theme/variables'
import { Trans, withTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import nl2br from '../../../../../components/utils/Nl2br'

const ItemInfo = styled.div`
  width: 300px;
`
const ItemLine = styled(Flex)`
  border-bottom: 1px solid ${cardSeparatorColor};
  color: #5f6974;
  > span {
    font-weight: 500;
  }
`
const TotalPrice = styled(Flex)`
  tr {
    td:first-child {
      padding-right: 80px;
    }
    td:last-child {
      font-weight: 500;
      text-align: right;
    }
    &:nth-child(2) td {
      border-bottom: 1px solid ${cardSeparatorColor};
      padding-top: 15px;
      padding-bottom: 20px;
    }
    &:last-child td {
      padding-top: 20px;
      span {
        &:last-child {
          color: ${primaryColor};
          font-weight: 500;
          font-size: 22px;
        }
      }
    }
  }
`

const TermsModalTrigger = styled.i`
  cursor: pointer;
  color: ${primaryColor}
`
@withTranslation('', { wait: true })
export class QuoteItemsInfoCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
        }
    }

    returnFrequency = type => {
        switch (type) {
            case 'weekly':
                return 52
            case 'every_second_month':
                return 6
            case 'quarterly':
                return 4
            case 'biannually':
                return 2
            case 'monthly':
                return 12
            case 'unit':
            case 'yearly':
            default:
                return 1
        }
    }

    render() {
        const {
            quote,
            lead,
            t,
        } = this.props

        return <Card>
            <Card.Content modifiers={[ 'pY_5', 'pX_6' ]}>
                <Card.Heading modifiers={[ 'primaryColor', 'mB_2' ]}>{t('quotes.quote.quote_title')}</Card.Heading>
                {quote.items.map(item =>
                    <ItemLine modifiers={[ 'justifySpaceBetween', 'alignCenter', 'mY_3', 'pB_2' ]}>
                        <ItemInfo>
                            {item.title} —&nbsp;
                            <span>{t(`quotes.quote.item_type.${item.type}`)}</span>
                            <span> {`(${this.returnFrequency(item.type)}x)`}</span>
                        </ItemInfo>
                        <span>
                            {lead.customerInfo.showPricesIncludingVat ? <FormattedAmount
                                amount={item.amountInclVat.amount}
                                currency={item.amountInclVat.currency}
                                decimals={2}
                            /> : <FormattedAmount
                                amount={item.amountExclVat.amount}
                                currency={item.amountExclVat.currency}
                                decimals={2}
                            />}
                        </span>
                        <span>
                            &nbsp; x {item.quantity}
                        </span>
                        <span>
                            {lead.customerInfo.showPricesIncludingVat ? <FormattedAmount
                                amount={item.totalAmountInclVat.amount}
                                currency={item.totalAmountInclVat.currency}
                                decimals={2}
                            /> : <FormattedAmount
                                amount={item.totalAmountExclVat.amount}
                                currency={item.totalAmountExclVat.currency}
                                decimals={2}
                            />}
                        </span>
                    </ItemLine>
                )}
                <TotalPrice modifiers={[ 'justifyEnd', 'alignCenter', 'mY_2' ]}>
                    <table>
                        {lead.customerInfo.showPricesIncludingVat && [
                            <tr>
                                <td>{t('quotes.quote.table_info.subtotal')}</td>
                                <td>
                                    <FormattedAmount
                                        amount={quote.totalAmountExclVat.amount}
                                        currency={quote.totalAmountExclVat.currency}
                                        decimals={2}
                                    />
                                </td>
                            </tr>,
                            <tr>
                                <td>{t('quotes.quote.table_info.vat_amount', { percentage: '25%' })}</td>
                                <td>
                                    <FormattedAmount
                                        amount={quote.totalVatAmount.amount}
                                        currency={quote.totalVatAmount.currency}
                                        decimals={2}
                                    />
                                </td>
                            </tr>]}
                        <tr>
                            <td>{lead.customerInfo.showPricesIncludingVat
                                ? t('quotes.quote.table_info.total_price_incl_vat')
                                : t('quotes.quote.table_info.total_price_excl_vat')}
                            </td>
                            <td>
                                {lead.customerInfo.showPricesIncludingVat ? <FormattedAmount
                                    amount={quote.totalAmountInclVat.amount}
                                    currency={quote.totalAmountInclVat.currency}
                                    decimals={2}
                                /> : <FormattedAmount
                                    amount={quote.totalAmountExclVat.amount}
                                    currency={quote.totalAmountExclVat.currency}
                                    decimals={2}
                                />
                                }
                            </td>
                        </tr>
                    </table>
                </TotalPrice>
                <Card.Line />
                <Card.Meta>{t('quotes.quote.table_info.quote_meta_text')}</Card.Meta>
                {quote.partnerTerms && <Card.Meta>
                    <Trans
                        i18nKey="quotes.quote.table_info.quote_meta_terms_text"
                        here={
                            <TermsModalTrigger
                                onClick={() => this.setState({ showModal: true })}
                            >
                                {t('quotes.quote.table_info.here')}
                            </TermsModalTrigger>
                        }
                    />
                </Card.Meta>}
            </Card.Content>

            <Modal
                onHide={() => this.setState({ showModal: false })}
                show={this.state.showModal}
                backdrop="false"
                bsSize="large"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-lg">
                        <strong>
                            {t('quotes.quote.partner_terms.header', {
                                partnerName: quote.partner.companyName,
                                leadId: lead.id,
                            })}
                        </strong>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {nl2br(quote.partnerTerms)}
                </Modal.Body>
            </Modal>
        </Card>
    }
}
