import styled from 'styled-components'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { cardPadding, cardSeparatorColor } from '../../../theme/variables'
import { MODIFIER_MARGIN_CONFIG, MODIFIER_PADDING_CONFIG } from '../../elements/modifiers/spaceModifiers'

const MODIFIER_SEPARATORS_CONFIG = {
    cardWidth: () => `
        margin-right: -${cardPadding}
        margin-left: -${cardPadding} 
    `,
}

export default styled.div`
    border-top: 1px solid ${cardSeparatorColor};
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyStyleModifiers(MODIFIER_SEPARATORS_CONFIG)}
`
