import { LineCard } from '../../cards'
import { primaryColor, secondaryColor } from '../../../theme/variables'
import { Card } from '../../blocks/Card'
import { Alert, Col, Row } from 'react-bootstrap'
import { Trans, withTranslation } from 'react-i18next'
import FormattedAmount from '../../utils/FormattedAmount'
import config from '../../../config'
import React from 'react'
import { Button } from '../../elements/Button'
import styled from 'styled-components'
import connect from 'react-redux/es/connect/connect'
import { showPayToQuoteVsPayToMatch } from '../../utils/showPayToQuoteVsPayToMatch'
import { currentPartnerSelector } from '../../../selectors/auth'

const DangerAlert = styled(Alert)`
  display: inline-block;
`
@connect(
    state => ({
        formError: state.form.quote && state.form.quote.submitErrors || {},
        partner: currentPartnerSelector(state),
    }),
)
@withTranslation('', { wait: true })
export class ActionBarLineCard extends React.PureComponent {
    render() {
        const {
            handleSubmit,
            refuseQuote,
            formError,
            disabled,
            partner,
            quote,
            lead,
            t,
            hasMarketplaceAccess,
        } = this.props

        const errors = Object.values(formError)

        const yearFeeExclVat = lead.serviceFeeSubsequentYearsAmountExclVat

        const { displayPayToQuote, removeFee } = showPayToQuoteVsPayToMatch(lead, partner)

        const quoteFee = displayPayToQuote && quote && (quote.publishedAt ? quote.quoteFeeAmountExclVat : lead.quotesInfo.quoteFee.quoteFee)

        const exclusiveLead = lead.category && lead.category.identifier === 'exclusive'

        return <LineCard borderColor={errors.length ? primaryColor : secondaryColor}>
            <Card.Header>
                <Card.MarkCircle size="40"
                                 modifiers={[ 'mR_2', errors.length ? 'primary' : 'secondary' ]}>4</Card.MarkCircle>
                <strong>{t('quotes.creation.send.label')}</strong>
            </Card.Header>
            <Card.Content>
                {Boolean(errors.length) &&
                <DangerAlert bsStyle="danger">
                    {errors.map(el => <div key={el.title}>
                        <strong>{t('quotes.creation.send.please_fill_out')} '{el.title}'</strong></div>)}
                </DangerAlert>}

                <Row>
                    <Col sm={12}>
                        <Card.Intro>
                            {hasMarketplaceAccess ? t('quotes.creation.intro_marketplace') : t('quotes.creation.intro')}
                            <br />
                            {exclusiveLead || removeFee || (quoteFee
                                ? <Trans
                                    i18nKey="quotes.quote_fee_text"
                                    fee={<FormattedAmount
                                        amount={quoteFee.amount}
                                        currency={quoteFee.currency}
                                    />}
                                />
                                || <Trans
                                    i18nKey="quotes.quote_fee_text"
                                    fee={<FormattedAmount
                                        amount={lead.quotesInfo.quoteFee.quoteFee.amount}
                                        currency={lead.quotesInfo.quoteFee.quoteFee.currency}
                                    />}
                                />
                                : lead.serviceFee && (Boolean(lead.serviceFee.amount) || Boolean(yearFeeExclVat && yearFeeExclVat.amount)) &&
                                    <Trans
                                        i18nKey="quotes.service_fee_text"
                                        fee={<FormattedAmount
                                            amount={lead.serviceFee.amount}
                                            currency={lead.serviceFee.currency}
                                        />}
                                        subsequentFee={<FormattedAmount
                                            amount={yearFeeExclVat ? yearFeeExclVat.amount : 0}
                                            currency={yearFeeExclVat ? yearFeeExclVat.currency : config.currency[ lead.geo.code ]}
                                        />}
                                    />
                            )}
                        </Card.Intro>
                    </Col>
                    {(!config.hiddenIndustriesForRefusal.includes(partner.industry.identifier)
                        && !disabled && quote && quote.canBeRefused)
                        && <Col sm={3}>
                        <Button
                            modifiers={[ 'secondary', 'p_2', 'fullWidth' ]}
                            onClick={refuseQuote}
                        >
                            {t('quotes.creation.refusal.refusal_button')}
                        </Button>
                    </Col>}
                    {(!quote || !quote.publishedAt) && <Col sm={5}>
                        <Button
                            modifiers={[ 'action', 'p_2', 'fullWidth' ]}
                            onClick={handleSubmit}
                            disabled={disabled}
                        >
                            {t('quotes.creation.send.btn_send_quote')}
                        </Button>
                    </Col>}
                    {!hasMarketplaceAccess && <Col sm={3}>
                        <Button
                            type="submit"
                            disabled={disabled}
                            modifiers={[ 'primary', 'p_2', 'fullWidth' ]}
                        >
                            {t('quotes.creation.send.btn_save_changes')}
                        </Button>
                    </Col>}
                </Row>
            </Card.Content>
        </LineCard>
    }
}
