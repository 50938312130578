import {
    GET_USERS, GET_USERS_SUCCESS, GET_USERS_FAIL,
    GET_USER, GET_USER_SUCCESS, GET_USER_FAIL, DELETE_PARTNER_USER_SUCCESS,
} from '../../actions/users'

const initialState = {
    partnerUserIds: [],
    isLoading: false,
    userIsLoading: false,
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    case GET_USER:
        return {
            ...state,
            userIsLoading: true,
        }
    case GET_USER_SUCCESS:
        return {
            ...state,
            userIsLoading: false,
        }
    case GET_USER_FAIL:
        return {
            ...state,
            userIsLoading: false,
        }
    case GET_USERS:
        return {
            ...state,
            isLoading: true,
        }
    case GET_USERS_SUCCESS:
        return {
            ...state,
            partnerUserIds: action.response.result,
            isLoading: false,
        }
    case GET_USERS_FAIL:
        return {
            ...state,
            isLoading: false,
        }
    case DELETE_PARTNER_USER_SUCCESS:
        return {
            ...state,
            isLoading: false,
        }
    default:
        return state
    }
}
