import {
    GET_PROJECT_NOTES, GET_PROJECT_NOTES_SUCCESS, GET_PROJECT_NOTES_FAIL,
    GET_PROJECT_EVENTS, GET_PROJECT_EVENTS_SUCCESS, GET_PROJECT_EVENTS_FAIL,
    GET_PROJECT_CONVERSATIONS, GET_PROJECT_CONVERSATIONS_SUCCESS, GET_PROJECT_CONVERSATIONS_FAIL,
    ADD_NOTE_TO_PROJECT_SUCCESS,
} from '../../actions/projects'

import {
    GET_CONVERSATIONS,
    GET_CONVERSATIONS_SUCCESS,
    GET_CONVERSATIONS_FAIL,
} from '../../actions/conversations'
import {
    CREATE_DELIVERABLE_SUCCESS,
    GET_DELIVERABLES,
    GET_DELIVERABLES_FAIL,
    GET_DELIVERABLES_SUCCESS,
} from '../../actions/deliverables'

const initialState = {
    isLoading: false,
    isLoadingEvents: false,
    notes: [],
    events: [],
    deliverables: [],
    tabName: 'notes',
    isLoadingConversations: false,
    conversationIds: [],
    pagination: {},
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    case GET_PROJECT_NOTES:
        return {
            ...state,
            isLoading: true,
        }
    case GET_PROJECT_NOTES_SUCCESS:
        return {
            ...state,
            isLoading: false,
            notes: action.response.result,
        }
    case GET_PROJECT_NOTES_FAIL:
        return {
            ...state,
            isLoading: false,
        }
    case ADD_NOTE_TO_PROJECT_SUCCESS: {
        const notes = [ action.response.result ].concat(state.notes)

        return {
            ...state,
            notes,
        }
    }
    case GET_PROJECT_EVENTS: {
        return {
            ...state,
            isLoadingEvents: true,
        }
    }
    case GET_PROJECT_EVENTS_SUCCESS: {
        return {
            ...state,
            isLoadingEvents: false,
            events: action.response.result,
        }
    }
    case GET_PROJECT_EVENTS_FAIL: {
        return {
            ...state,
            isLoadingEvents: false,
        }
    }
    case GET_CONVERSATIONS:
    case GET_PROJECT_CONVERSATIONS:
        return {
            ...state,
            isLoadingConversations: true,
        }
    case GET_CONVERSATIONS_SUCCESS:
    case GET_PROJECT_CONVERSATIONS_SUCCESS:
        return {
            ...state,
            isLoadingConversations: false,
            conversationIds: action.append ?
                [ ...new Set([
                    ...state.conversationIds,
                    ...action.response.result,
                ]) ] :
                action.response.result,
            pagination: action.response.pagination,
            isLoading: false,
        }
    case GET_CONVERSATIONS_FAIL:
    case GET_PROJECT_CONVERSATIONS_FAIL:
        return {
            ...state,
            isLoadingConversations: false,
        }
    case GET_DELIVERABLES: {
        return {
            ...state,
            isLoadingEvents: true,
        }
    }
    case GET_DELIVERABLES_SUCCESS: {
        return {
            ...state,
            isLoadingEvents: false,
            deliverables: action.response.result,
        }
    }
    case GET_DELIVERABLES_FAIL: {
        return {
            ...state,
            isLoadingEvents: false,
        }
    }
    case CREATE_DELIVERABLE_SUCCESS: {
        return {
            ...state,
            isLoadingEvents: false,
            deliverables: [ action.response.result ].concat(state.deliverables),
        }
    }
    default:
        return state
    }
}
