import React from 'react'
import { Alert } from 'react-bootstrap'
import WindowSizeListener from 'react-window-size-listener'
import { Trans, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import styled from 'styled-components'
import { generatePartnerContractSigningUrl, acceptPartnerContract } from '../../actions/partnercontracts'
import { getPartner } from '../../actions/partners'
import { getRenderedTerm, getTerms } from '../../actions/terms'
import LoadingAnimation from '../../components/utils/LoadingAnimation'
import { currentPartnerSelector, currentPartnerUserSelector } from '../../selectors/auth'
import { Flex } from '../elements/Flex'
import { Button } from '../elements/ButtonNew'
import CheckboxInput from '../forms/inputs/CheckboxInput'
import FormattedDate from '../utils/FormattedDate'
import { applyResponsiveStyleModifiers } from 'styled-components-modifiers'
import { Text, Headline } from '../elements/text/Text'
import { Container, Col, Row, Section } from '../layout/layout'
import { bkpInt, colors } from '../../theme/variables_new'
import { Image } from '../layout/images'
import { MODIFIER_PADDING_CONFIG, MODIFIER_MARGIN_CONFIG } from '../elements/modifiers/spaceModifiers'
import { getPartnerIndustries, getLeadTypes } from '../../actions/segmentation'
import { getGeoRegions } from '../../actions/regions'
import { getSectors } from '../../actions/sectors'
import PartnerContractPanel from '../elements/ContractPanel'
import { hasContractSigningAccessSelector } from '../../selectors/access'
import queryString from 'query-string'

// define page
const Page = styled.div`
    width: 100%;
    color: ${colors.darkBlue}
    ${applyResponsiveStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_PADDING_CONFIG)}
`

const ExternalLink = styled.a`
    text-decoration: underline;
`


const returnProfileImage = geo => {
    if ([ 'no', 'se', 'dk' ].includes(geo)) {
        return <img src="/images/rico_final.jpeg" alt="Rico"/>
    }
    if (geo === 'nl') {
        return <img src="/images/nick.jpeg" alt="Nick"/>
    }
    if ([ 'de', 'us' ].includes(geo)) {
        return <img src="/images/mikkel.jpeg" alt="Mikkel"/>
    }
}

const Form = styled.form`
    .form-group,
    label {
        margin-bottom: 0;
    }

    label {
        margin-right: 12px;
    }

    .help-block {
        font-size: 14px;
        line-height: 24px;
    }
`

@reduxForm({
    form: 'contractAcceptForm',
})
@withTranslation('', { wait: true })
export class ContractAcceptForm extends React.Component {
    state = {
        submitting: false,
    }

    validate = async data => {
        if (data.acceptTerms || this.props.signingEnabled) {
            await this.setState({ submitting: true })
            await this.props.onSubmit()
            await this.setState({ submitting: false })
        } else {
            throw new SubmissionError({ acceptTerms: this.props.t('partner_contracts.terms.accept_terms_validation_text') })
        }
    }

    render() {
        const { handleSubmit, t, width, signingEnabled } = this.props

        if (this.state.submitting) {
            return <LoadingAnimation />
        }

        return <Form onSubmit={handleSubmit(this.validate)} style={{ textAlign: signingEnabled ? 'center' : 'left' }}>
            <Flex
                responsiveModifiers={{
                    small: [ 'directionColumn' ],
                    medium: [ 'directionColumn' ],
                }}
                size={width}>
                {!signingEnabled && <Flex.Item modifiers={[ 'grow2' ]} responsiveModifiers={{
                    small: [ 'pB_2' ],
                    medium: [ 'pB_3' ],
                    desktopSmall: [ 'pR_4' ],
                    desktopMedium: [ 'pR_6' ],
                    desktopLarge: [ 'pR_6' ],
                }} size={width}>
                    <Field name="acceptTerms" t={t} component={CheckboxInput} required>
                        <Text modifiers={[ 'smallHigh' ]}>
                            <Trans
                                i18nKey="partner_contracts.terms.confirm_general_terms"
                            >
                                <ExternalLink href={t('partner_contracts.terms.terms_and_conditions_link')} target="_blank"/>
                            </Trans>
                        </Text>
                    </Field>
                </Flex.Item>}
                <Flex.Item>
                    <Button type="submit" className={{ margin: '0 auto' }} modifiers={[ 'action', 'large', 'bold', 'bottomLeft', ! signingEnabled ? 'btnBlock' : '' ]} disabled={this.state.submitting}>
                        {signingEnabled && <svg style={{ display: 'inline-block', width: '23px', verticalAlign: 'middle', marginRight: '10px' }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"/>
                        </svg>}
                        {t(signingEnabled ? 'partner_contracts.signing.btn_text' : 'partner_contracts.terms.btn_text')}
                    </Button>
                </Flex.Item>
            </Flex>
        </Form>
    }
}

@connect(
    state => ({
        partner: currentPartnerSelector(state),
        partnerUser: currentPartnerUserSelector(state),
        partnerContracts: state.entities.partnerContracts,
        isLoading: state.pages.partnerContracts.isLoading,
        clientTypes: state.entities.leadTypes,
        geoRegions: state.entities.geoRegions,
        industries: state.entities.industries,
        sectors: state.entities.sectors,
        signingEnabled: hasContractSigningAccessSelector(state),
    }),
    {
        getPartner,
        generatePartnerContractSigningUrl,
        getTerms,
        getRenderedTerm,
        getPartnerIndustries,
        getLeadTypes,
        getGeoRegions,
        getSectors,
        acceptPartnerContract,
    }
)

@withTranslation('', { wait: true })
export default class PartnerContractsPage extends React.Component {

    constructor() {
        super()
        this.state = {
            termContent: '',
            contractId: null,
            showAcceptButton: false,
            activeBusinessUnitTab: 0,
            accepted: false,
            width: 0,
        }
    }

    componentDidMount() {
        const geoCode = this.props.partner.geo.code

        Promise.all([
            this.props.getPartnerIndustries(this.props.partner),
            this.props.getSectors({ geoCode, limit: 100 }),
            this.props.getGeoRegions({ geoCode }),
            this.props.getLeadTypes(geoCode),
        ])

        this.props.getTerms({
            geoCode: this.props.partner.geo.code,
            type: 'partner',
            identifier: 'partner_contract_terms',
        }).then(termsResponse => {
            const replacerProps = {
                partnerName: this.props.partner.companyName,
            }
            function replacer(match) {
                const bodyProps = match.replace(/[{}]/g, '')
                return replacerProps[bodyProps]
            }
            const term = termsResponse.entities && termsResponse.entities.termss[termsResponse.result[0]]
            const termContent = term.body.replace(/\{\w+\}/g, replacer)

            this.setState({
                termContent,
            })
        })
    }

    componentWillReceiveProps(nextProps) {
        /**
         * Contracts to be accepted should take precedence over already accepted contracts
         */
        if (this.props.partner.state === 'demo') {
            const contractsToAccept = Object.values(nextProps.partnerContracts).filter(contract => !contract.acceptedAt)
            let contractToAccept = contractsToAccept.slice(-1)[0]
            if (contractToAccept) {
                return this.setState({
                    contractId: contractToAccept.id,
                    showAcceptButton: true,
                })
            }
        }
    }

    updateWindowWidth = width => this.setState({ width: width })

    returnWindowWidthString = width => {
        if (width <= bkpInt.small) return 'small'
        if (width <= bkpInt.medium) return 'medium'
        if (width <= bkpInt.large) return 'desktopSmall'
        if (width <= bkpInt.extraLarge) return 'desktopMedium'
        return 'desktopLarge'
    }

    submitContractForm = async () => {
        if (this.props.signingEnabled) {
            try {
                const result = await this.props.generatePartnerContractSigningUrl(this.props.partner.id, this.state.contractId)
                window.location.href = result.url
            } catch (e) {
                toastr.error(this.props.t('partner_contracts.requests.error'), this.props.t('partner_contracts.could_not_generate_signing_url'))
            }
            return
        }

        try {
            await this.props.acceptPartnerContract(this.props.partner.id, this.state.contractId)

            await this.setState({
                showAcceptButton: false,
                accepted: true,
            })

            await this.props.getPartner(this.props.partner.id)
        } catch (e) {
            toastr.error(this.props.t('partner_contracts.requests.error'), e.error)
        }
    }

    render() {
        const { t, partnerContracts, partner, clientTypes, industries, sectors, geoRegions, location } = this.props
        const qs = JSON.parse(JSON.stringify(queryString.parse(location.search)))

        if (this.state.isLoading) {
            return <LoadingAnimation />
        }

        const unacceptedContracts = Object.values(partnerContracts).filter(contract => !contract.acceptedAt)
        const upcomingContracts = Object.values(partnerContracts).filter(contract => contract.acceptedAt && !contract.currentPartnerContractPeriod && !contract.churnsAt)
        const activeContracts = Object.values(partnerContracts).filter(contract => contract.currentPartnerContractPeriod)
        const outdatedContracts = Object.values(partnerContracts).filter(contract => contract.acceptedAt && !contract.currentPartnerContractPeriod && contract.churnsAt)

        return <Page className="new">
            <Container modifiers={[ 'widthLg' ]}>
                <Row>
                    <Col>
                        <Headline responsiveModifiers={{
                            small: [ 'spacingSmallest', 'small' ],
                            medium: [ 'spacingSmallest', 'small' ],
                            desktopSmall: [ 'spacingSmallest', 'medium' ],
                            desktopMedium: [ 'spacingMedium', 'medium' ],
                            desktopLarge: [ 'spacingMedium', 'mediumLarge' ],
                        }} size={this.returnWindowWidthString(this.state.width)} modifiers={[ 'center' ]}>{partner.state === 'demo' ? t('subscription_terms.title') : t('partner_contracts.title')}</Headline>
                    </Col>
                </Row>
                {qs.event && qs.event === 'signing_complete' && <Row modifiers={[ 'mB_3' ]}>
                    <Col>
                        <Alert bsStyle="success">{t('partner_contracts.signing.complete')}</Alert>
                    </Col>
                </Row>}
                {partner.state !== 'demo' && <Row modifiers={[ 'mB_3' ]}>
                    {[ unacceptedContracts, upcomingContracts, activeContracts, outdatedContracts ].map((contractArray, i) => contractArray.length > 0 && <Col>
                        <Headline responsiveModifiers={{
                            small: [ 'tiny', 'mT_3', 'mB_2' ],
                            medium: [ 'tiny', 'mT_3', 'mB_2' ],
                            desktopSmall: [ 'tiny', 'mT_3', 'mB_2' ],
                            desktopMedium: [ 'tiny', 'mT_3', 'mB_2' ],
                            desktopLarge: [ 'tiny', 'mT_3', 'mB_2' ],
                        }} size={this.returnWindowWidthString(this.state.width)}>
                            {[ t('partner_contracts.unaccepted'), t('partner_contracts.upcoming'), t('partner_contracts.active'), t('partner_contracts.archived') ][i]}
                        </Headline>
                        {contractArray.map(contract => <div className={[ 'unaccepted', 'upcoming', 'active', 'archived' ][i]} key={'contract_' + contract.id} style={{ marginBottom: '30px' }}>
                            <PartnerContractPanel
                                contract={contract}
                                replacedContract={contract.intendingToReplacePartnerContractId && partnerContracts[contract.intendingToReplacePartnerContractId]}
                                partner={partner}
                                industries={industries}
                                clientTypes={clientTypes}
                                sectors={sectors}
                                geoRegions={geoRegions}
                                t={t}
                                width={this.returnWindowWidthString(this.state.width)}
                                pixelWidth={this.state.width}
                                isPanel
                                awaitsAcceptance={!contract.acceptedAt}
                            />
                        </div>)}
                    </Col>)}
                </Row>}
                {partner.state === 'demo' && partnerContracts[this.state.contractId] && <Row>
                    <PartnerContractPanel
                        contract={partnerContracts[this.state.contractId]}
                        partner={partner}
                        industries={industries}
                        clientTypes={clientTypes}
                        sectors={sectors}
                        geoRegions={geoRegions}
                        t={t}
                        width={this.returnWindowWidthString(this.state.width)}
                        pixelWidth={this.state.width}
                    />
                </Row>}
                {partner.state === 'demo' && <Row style={{ margin: '72px 0' }}>
                    <Col spanMd={3} spanLg={3} centerLg>
                        <Image modifiers={[ 'shaped' ]} responsiveModifiers={{
                            small: [ 'small', 'center', 'mB_3' ],
                        }}
                        size={this.returnWindowWidthString(this.state.width)}>
                            {returnProfileImage(partner.geo.code)}
                        </Image>
                    </Col>
                    <Col spanMd={4} spanLg={8} startMd={5} startLg={5} centerMd>
                        <div>
                            <Text modifiers={[ 'medium', 'opacityHigh', 'mB_3' ]}>{t('partner_contracts.manager_quote.quote')}</Text>
                            <Text modifiers={[ 'small', 'bold' ]}>{t('partner_contracts.manager_quote.name')}</Text>
                            <Text modifiers={[ 'smallHigh', 'opacityHigh' ]}>{t('partner_contracts.manager_quote.role')}</Text>
                        </div>
                    </Col>
                </Row>}
            </Container>
            {partner.state === 'demo' && <Section modifiers={[ 'whiteBackground', 'pY_6' ]}>
                <Container modifiers={[ 'widthLg' ]}>
                    {this.state.showAcceptButton &&
                    <ContractAcceptForm
                        width={this.returnWindowWidthString(this.state.width)}
                        onSubmit={this.submitContractForm}
                        signingEnabled={this.props.signingEnabled} />}
                    {(this.state.accepted || partnerContracts[this.state.contractId] && partnerContracts[this.state.contractId].acceptedAt) &&
                    <Text modifiers={[ 'smallHigh' ]}>
                        <Trans
                            i18nKey="partner_contracts.contract_accepted"
                        >
                            <FormattedDate
                                date={partnerContracts[this.state.contractId].acceptedAt}
                                format="datetime"
                            />
                        </Trans>
                    </Text>}
                </Container>
            </Section>}
            <WindowSizeListener onResize={size => this.updateWindowWidth(size.windowWidth)} />
        </Page>
    }
}
