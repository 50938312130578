import get from 'lodash/get'

export const showPayToQuoteVsPayToMatch = (lead, partner) => {
    const displayPayToMatch = partner.isPayToMatch && (lead.isServiceFee ? 'service_fee' : 'minimum_quote_amount')

    const displayPayToQuote = partner.isPayToQuote && lead.quotesInfo.quoteFee

    const agerasPackage = partner.subscription.find(el => el.packageType === 'Ageras Marketing Package')

    const removeFee = Boolean(agerasPackage) || !(
        partner.isPayToMatch ||
        partner.isPayToQuote ||
        get(lead, 'quotesInfo.quoteFee.quoteFee.amount') ||
        get(lead, 'minimumQuoteAmountExclVat.amount') ||
        get(lead, 'serviceFee.amount')
    )

    return { displayPayToMatch, displayPayToQuote, removeFee }
}
