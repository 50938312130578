import React from 'react'
import { withTranslation } from 'react-i18next'

@withTranslation('', { wait: true })
export default class InvoiceInfoBox extends React.Component {
    render() {
        const {
            partner, t,
        } = this.props

        var address = partner.invoicing && partner.invoicing.address ? partner.invoicing.address : partner.geo.address
        var cityName = partner.invoicing && partner.invoicing.cityName ? partner.invoicing.cityName : partner.geo.cityName
        var zipCode = partner.invoicing && partner.invoicing.zipCode ? partner.invoicing.zipCode : partner.geo.zipCode
        var companyName = partner.invoicing && partner.invoicing.companyName ? partner.invoicing.companyName : partner.companyName
        var vatNumber = partner.invoicing && partner.invoicing.vatNumber ? partner.invoicing.vatNumber : partner.vatNumber
        var attention = partner.invoicing && partner.invoicing.attention ? partner.invoicing.attention : partner.attention
        var email = partner.invoicing && partner.invoicing.email ? partner.invoicing.email : partner.email

        return <div>
            <strong>{companyName}</strong><br />
            {vatNumber ? <span>{t('offers.detail.vat_number', { vatNumber: vatNumber })}<br /></span> : ''}
            {attention ? <span>{t('invoices.attention', { attention: attention })}<br /></span> : ''}
            {address}<br/>
            {zipCode}&nbsp;
            {cityName}<br />
            {email}
        </div>
    }
}
