export default function(t) {
    return [
        {
            value: '01',
            label: t('months.january'),
        },
        {
            value: '02',
            label: t('months.february'),
        },
        {
            value: '03',
            label: t('months.march'),
        },
        {
            value: '04',
            label: t('months.april'),
        },

        {
            value: '05',
            label: t('months.may'),
        },
        {
            value: '06',
            label: t('months.june'),
        },
        {
            value: '07',
            label: t('months.july'),
        },
        {
            value: '08',
            label: t('months.august'),
        },
        {
            value: '09',
            label: t('months.september'),
        },
        {
            value: '10',
            label: t('months.october'),
        },
        {
            value: '11',
            label: t('months.november'),
        },
        {
            value: '12',
            label: t('months.december'),
        },

    ]
}
