import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { QuotePriceDetail } from './QuotePriceDetail'
import FormattedAmount from '../utils/FormattedAmount'

export class QuotePricingOverlay extends React.Component {
    render() {
        const {
            includeVat,
            quote,
            t,
        } = this.props

        return (
            <OverlayTrigger
                trigger="click"
                rootClose
                ref={ref => this['overlayTrigger' + quote.id] = ref}
                placement="top"
                overlay={
                    <Popover
                        id={'quoteId' + quote.id}
                        style={{
                            maxWidth: '400px',
                        }}
                    >
                        <QuotePriceDetail
                            t={t}
                            quote={quote}
                            close={() => this['overlayTrigger' + quote.id].hide()}
                            includeVat={includeVat}
                        />
                    </Popover>
                }
            >
                <strong data-tip data-for="global" className="dot-underline-text">
                    {includeVat ?
                        <FormattedAmount
                            amount={quote.totalAmountInclVat.amount}
                            currency={quote.totalAmountInclVat.currency}
                            decimals={2}
                        /> :
                        <FormattedAmount
                            amount={quote.totalAmountExclVat.amount}
                            currency={quote.totalAmountExclVat.currency}
                            decimals={2}
                        />
                    }
                </strong>
            </OverlayTrigger>
        )
    }
}
