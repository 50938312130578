const DEFAULT_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
const DEFAULT_TIMEZONE_STRING = 'local time';

export function timezoneFromGeoCode(geoCode) {
    return {
        'no': 'Europe/Oslo',
        'dk': 'Europe/Copenhagen',
        'nl': 'Europe/Amsterdam',
        'se': 'Europe/Stockholm',
        'de': 'Europe/Berlin',
    }[geoCode] ?? DEFAULT_TIMEZONE
};

export function timezoneStringFromGeoCode(geoCode) {
    return {
        'no': 'norsk tid',
        'dk': 'dansk tid',
        'nl': 'Nederlandse tijd',
        'se': 'svensk tid',
        'de': 'deutsche Zeit',
    }[geoCode] ?? DEFAULT_TIMEZONE_STRING
};
