import {
    GET_COUPONS, GET_COUPONS_SUCCESS, GET_COUPONS_FAIL,
} from '../../actions/coupons'

const initialState = {
    couponIds: new Set([]),
    isLoading: false,
    pagination: {},
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    case GET_COUPONS:
        return {
            ...state,
            isLoading: true,
        }
    case GET_COUPONS_SUCCESS:
        return {
            ...state,
            couponIds: new Set(action.response.result),
            isLoading: false,
        }
    case GET_COUPONS_FAIL:
        return {
            ...state,
            isLoading: false,
        }
    default:
        return state
    }
}
