import styled from 'styled-components'

export default styled.div`
    position: absolute;
    height: 22px;
    left: 0;
    width: 22px;
    background: #fff;
    border-radius: 100%;
    box-shadow: 0 0 0 1px rgba(86, 109, 121, .2), 0 2px 3px rgba(0, 0, 0, .22);
    transition: left .25s ease;
`
