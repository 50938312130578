import {
    GET_PROJECTS, GET_PROJECTS_SUCCESS, GET_PROJECTS_FAIL,
} from '../../actions/projects'
import { GET_RESERVED_QUOTES, GET_RESERVED_QUOTES_FAIL, GET_RESERVED_QUOTES_SUCCESS } from '../../actions/quotes'

const initialState = {
    isLoading: false,
    projectIds: [],
    showFilters: false,
    pagination: {},
    isLoadingReservedQuotes: false,
    reservedQuotesId: [],
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    case GET_PROJECTS:
        return {
            ...state,
            isLoading: true,
        }
    case GET_PROJECTS_SUCCESS:
        return {
            ...state,
            isLoading: false,
            projectIds: action.response.pagination.page == 1 ?
                    action.response.result :
            [
                ...state.projectIds,
                ...action.response.result,
            ],
            pagination: action.response.pagination,
        }
        case GET_PROJECTS_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        case GET_RESERVED_QUOTES:
            return {
                ...state,
                isLoadingReservedQuotes: true,
            }
        case GET_RESERVED_QUOTES_SUCCESS:
            return {
                ...state,
                reservedQuotesId: action.response.result,
            }
        case GET_RESERVED_QUOTES_FAIL:
            return {
                ...state,
                isLoadingReservedQuotes: false,
            }
        default:
            return state
    }
}
