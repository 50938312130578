import React from 'react'
import { FormGroup } from 'react-bootstrap'
import { Error } from '../Error'
import MetaLabel from '../../elements/text/MetaLabel'
import FormControl from '../../elements/FormControl'

export const FieldGroup = ({
   className,
   input,
   label,
   meta,
   ...props
}) =>
    <FormGroup controlId={input.name} bsClass={'form-group ' + className || ''}>
        {label && <MetaLabel>{label}</MetaLabel>}
        <FormControl {...input} {...props}/>
        <Error meta={meta}/>
    </FormGroup>
