import React from 'react'
import i18n from 'i18next'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { reduxForm, Field } from 'redux-form'
import { getBankAccounts, saveBankAccount } from '../../actions/partners'
import { currentPartnerSelector } from '../../selectors/auth'
import { renderField } from '../utils/forms'
import countries from 'i18n-iso-countries'
import LoadingAnimation from '../utils/LoadingAnimation'

@withTranslation('', { wait: true })
@reduxForm({
    form: 'bank_account_form',
})
@connect(
    (state, props) => ({
        partner: currentPartnerSelector(state),
        isLoading: state.pages.payoutInfo.isLoading,
        bankAccounts: state.pages.payoutInfo.bankAccountIds.map(id => state.entities.bankAccounts[id]),
    }),
    {
        getBankAccounts,
        saveBankAccount,
    }
)
export default class PayoutInfoPage extends React.Component {
    componentWillMount() {
        this.props.getBankAccounts(this.props.partner.id)
    }
    submitForm(data) {
        const {
            partner,
            saveBankAccount,
            getBankAccounts,
        } = this.props

        saveBankAccount(partner.id, data).then(a => {
            getBankAccounts(partner.id)
        })
    }

    render() {
        const {
            handleSubmit,
            submitting,
            bankAccounts,
            isLoading,
            t,
        } = this.props
        const required = value => (value ? undefined : t('verifications.validation.required'))

        if(isLoading) {
            return <LoadingAnimation />
        }

        return (
            <div className="card">
                <h2>{t('payout_info.title')}</h2>

                {bankAccounts.length > 0 && <div>
                    <h3>{t('payout_info.bank_account_list.title')}</h3>
                    <p className="intro-text">
                        {t('payout_info.bank_account_list.intro')}
                    </p>
                    <table className="datatable">
                        <thead>
                            <tr>
                                <th>{t('payout_info.bank_account_list.iban_number')}</th>
                                <th>{t('payout_info.bank_account_list.bank_name')}</th>
                                <th>{t('payout_info.bank_account_list.currency')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bankAccounts.map(account => <tr key={account.id}>
                                <td>&bull;&bull;&bull;&bull; {account.last4}</td>
                                <td>{account.bankName}</td>
                                <td>{account.currency.toUpperCase()}</td>
                            </tr>)}
                        </tbody>
                    </table>
                    <hr />
                </div>}

                <h3>{t('payout_info.destination.title')}</h3>
                <p className="intro-text">
                    {t('payout_info.destination.intro')}
                </p>

                <form onSubmit={handleSubmit(this.submitForm.bind(this))}>
                    <Field
                        name="account_number"
                        component={renderField}
                        validate={[ required ]}
                        label={t('payout_info.destination.iban_label')}
                        placeholder={t('payout_info.destination.iban_placeholder')}
                    />

                    <Field
                        name="currency"
                        component={renderField}
                        validate={[ required ]}
                        label={t('payout_info.destination.currency_label')}
                    >
                        <option />
                        <option value="EUR">EUR</option>
                        <option value="DKK">DKK</option>
                        <option value="SEK">SEK</option>
                        <option value="NOK">NOK</option>
                        <option value="GBP">GBP</option>
                    </Field>

                    <Field
                        name={'country'}
                        component={renderField}
                        label={t('payout_info.destination.country_label')}
                    >
                        <option />
                        {Object.keys(countries.getNames(i18n.language)).map((value, index) => {
                            return <option key={index} value={value}>{countries.getNames(i18n.language)[value]}</option>
                        })}
                    </Field>

                    <button disabled={submitting} type="submit" className="btn btn-action btn-primary btn-lg btn-block" >
                        {t('payout_info.destination.submit_data')}
                    </button>
                </form>
            </div>
        )
    }
}
