import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const GET_SUBSCRIPTION_PACKAGES = 'packages/GET_SUBSCRIPTION_PACKAGES'
export const GET_SUBSCRIPTION_PACKAGES_SUCCESS = 'packages/GET_SUBSCRIPTION_PACKAGES_SUCCESS'
export const GET_SUBSCRIPTION_PACKAGES_FAIL = 'packages/GET_SUBSCRIPTION_PACKAGES_FAIL'

export const GET_SUBSCRIPTION_PACKAGE = 'packages/GET_SUBSCRIPTION_PACKAGE'
export const GET_SUBSCRIPTION_PACKAGE_SUCCESS = 'packages/GET_SUBSCRIPTION_PACKAGE_SUCCESS'
export const GET_SUBSCRIPTION_PACKAGE_FAIL = 'packages/GET_SUBSCRIPTION_PACKAGE_FAIL'

export const getSubscriptionPackage = id => dispatch => dispatch({
    [CALL_API]: {
        types: [ GET_SUBSCRIPTION_PACKAGE, GET_SUBSCRIPTION_PACKAGE_SUCCESS, GET_SUBSCRIPTION_PACKAGE_FAIL ],
        endpoint: `/packages/${id}`,
        schema: schemas.subscriptionPackage,
    },
})

export const getSubscriptionPackages = (geoCode, partnerId) => dispatch => dispatch({
    [CALL_API]: {
        types: [ GET_SUBSCRIPTION_PACKAGES, GET_SUBSCRIPTION_PACKAGES_SUCCESS, GET_SUBSCRIPTION_PACKAGES_FAIL ],
        endpoint: `/packages?geo_code=${geoCode}&partner_id=${partnerId}`,
        schema: schemas.subscriptionPackages,
    },
})
