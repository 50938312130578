import styled from 'styled-components'
import { colors } from '../../../theme/variables_new'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { MODIFIER_MARGIN_CONFIG, MODIFIER_PADDING_CONFIG } from '../modifiers/spaceModifiers'
import { Flex } from '../Flex'

const Dropdown = styled.div`
    position: relative;
    display: inline-block;

    &:hover > div[data-type=content] {
        display: block;
    }

    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)}
`

const Content = styled.div.attrs({ 'data-type': 'content' })`
    display: none;
    position: absolute;
    right: 0
    z-index: 5;
    background-color: ${colors.white};
    min-width: max-content;
    box-shadow: 0px 9.29px 40px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    border-radius: 4px;

    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)}
`

const Option = styled(Flex)`
    cursor: pointer;

    &:hover {
        background-color: ${colors.blue}25;
    }

    &:active {
        background-color: ${colors.blue}60;
    }

    ${({ active }) => active && `background-color: ${colors.blue}60;`}
`

Dropdown.Content = Content
Dropdown.Option = Option

export default Dropdown
