import React from 'react'
import { withTranslation } from 'react-i18next'
import { Field, reduxForm } from 'redux-form'
import { toastr } from 'react-redux-toastr'
import { currentPartnerSelector, currentPartnerUserSelector } from '../../selectors/auth'
import { connect } from 'react-redux'
import { saveUserProfile } from '../../actions/users'
import { Card } from '../blocks/Card'
import { FieldGroup } from '../forms/inputs/FieldGroup'
import { Flex } from '../elements/Flex'
import { SingleSwitchCheckboxField } from '../forms/inputs/SwitchCheckBoxGroup'
import { getPartnerProfileDataFields } from '../../actions/datafields'
import { partnerDataFieldsSelctor } from '../../selectors/partners'
import { Button } from '../elements/Button'

const validate = values => {
    const errors = {}

    if (!values.email) {
        errors.email = true
    }

    return errors
}

@reduxForm({
    form: 'user_profile',
    validate,
})

@connect(
    state => ({
        dataFields: partnerDataFieldsSelctor(state),
        user: currentPartnerUserSelector(state),
        partner: currentPartnerSelector(state),
    }),
    {
        getPartnerProfileDataFields,
        saveUserProfile,
    }
)

@withTranslation('', { wait: true })

export default class UserEditForm extends React.Component {
    componentDidMount() {
        this.props.initialize(this.props.user)
        this.props.getPartnerProfileDataFields({ industryId: this.props.partner.industry.id })
            .then(res => {
                const partnerAttributes = this.props.user.attributes || {}

                const attributes = this.props.dataFields.map(field =>
                    [ 'interview_question1', 'interview_question2', 'interview_question3', 'interview_quote' ].includes(field.identifier) &&
                    ({
                        ...field,
                        selected: partnerAttributes[field.id] ? partnerAttributes[field.id].selected : [],
                    }))
                this.props.change('attributes', attributes.filter(el => el))
            })
    }

    submitProfile(data) {
        const { user, t } = this.props
        data = {
            email: data.email,
            phone: data.phone,
            wants_emails: data.wantsEmails,
            wants_daily_email: data.wantsDailyEmail,
            wants_text_messages: data.wantsTextMessages,
        }

        this.props.saveUserProfile(this.props.partner.id, user.id, data).then(() => {
            toastr.success(t('user_profile.saved.header'), t('user_profile.saved.description'))
        })
    }

    render() {
        const {
            t,
            handleSubmit,
            partner,
        } = this.props

        return (
            <form onSubmit={handleSubmit(this.submitProfile.bind(this))}>
                <Card.Title>{t('user_profile.contact_info.title')}</Card.Title>
                <Card.Intro>{t('user_profile.contact_info.intro')}</Card.Intro>

                <Field
                    label={t('user_profile.email')}
                    name="email"
                    component={FieldGroup}
                />
                <Flex modifiers={[ 'alignCenter' ]}>
                    <Field
                        name="wantsEmails"
                        component={SingleSwitchCheckboxField}
                    />
                    {t('user_profile.contact_by_email')}
                </Flex>
                {partner.hasSubscription && <Flex modifiers={[ 'alignCenter' ]}>
                    <Field
                        name="wantsDailyEmail"
                        component={SingleSwitchCheckboxField}
                    />
                    {t('user_profile.contact_daily_email')}
                </Flex>}
                <Field
                    label={t('user_profile.phone')}
                    name="phone"
                    component={FieldGroup}
                />
                <br />
                <Button
                    type="submit"
                    modifiers={[ 'action', 'fullWidth', 'p_2' ]}
                >
                    {t('user_profile.save')}
                </Button>
            </form>
        )
    }
}
