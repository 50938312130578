import styled from 'styled-components'
import { media } from '../../cards'
import { fadedTextColor } from '../../../theme/variables'

export default styled.div`
    color: ${fadedTextColor};
    font-size: 26px;
    ${media.mobile`font-size: 16px`};
    text-align: center;
`
