import {
    PartnerActionFeesResource,
    PartnerActionFeesResult,
    PartnersApiFetchParamCreator,
} from 'ageras-api-client/src/api'
import { useAuth } from 'ageras-api-client/src/auth'
import Amount from 'components/src/Amount'
import Flex from 'components/src/layout/Flex'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import Card from 'components/src/Card'
import { useAgerasApi } from 'ageras-api-client/src'
import Button from 'components/src/Button'
import { useHistory } from 'react-router'
import usePartner from '../../utils/usePartner'
import { COIN_DIVIDE_NUMBER } from '../../utils/usePartnerPrePaidBalance'
import { useTranslation } from 'react-i18next'
import Loader from 'components/src/Loader'
import FormattedDate from 'components/src/Date'
import Text from 'components/src/texts/Text'
import PageTitle from '../utils/PageTitle'

const LoaderWrapper = styled(Flex)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`
const FIRST_PAGE = 1

function ActionFees() {
    const { partnerUser } = useAuth()
    const { partner } = usePartner()
    const [ fees, setFees ] = useState<Array<PartnerActionFeesResource>>([])
    const [ page, setPage ] = useState(FIRST_PAGE)
    const [ showLoadMore, setShowLoadMore ] = useState(false)
    const history = useHistory()
    const { t } = useTranslation()

    const { data, isLoading } = useAgerasApi<PartnerActionFeesResult>(PartnersApiFetchParamCreator().partnersActionfeesIndex(
        String(partnerUser?.partnerId),
        20,
        page,
    ))

    useEffect(() => {
        if (data?.data) {
            if (data.pages === page) {
                setShowLoadMore(false)
            } else {
                setShowLoadMore(true)
            }

            if (fees.length) {
                setFees([ ...fees, ...data.data ])
            } else {
                setFees([ ...data.data ])
            }
        }
    }, [ data?.data ])

    const handleClientNameClick = (fee: PartnerActionFeesResource) => {
        return () => history.push('/projects/' + fee.projectId)
    }

    const increaseCounter = useCallback(() => {
        setPage(page + 1)
    }, [ page ])

    return <Card>
        <PageTitle identifier="action_fees.title"/>
        <Card.Content p={4}>
            <table className="datatable responsive compact">
                <thead>
                    <tr>
                        <th className="table-mobile-cell"/>
                        <th><Text>{t('action_fees.action_type')}</Text></th>
                        <th>{t('action_fees.fee')}</th>
                        <th>{t('action_fees.date')}</th>
                        <th>{t('action_fees.client')}</th>
                    </tr>
                </thead>
                {<tbody>
                    {isLoading && <LoaderWrapper>
                        <Loader/>
                    </LoaderWrapper>}
                    {fees.map((fee: PartnerActionFeesResource, index) => <tr key={index}>
                        <td>{ t('action_fees.' + fee.action)}</td>
                        <td>
                            <Amount number={Math.abs(fee.actionSummary || 0) / COIN_DIVIDE_NUMBER} currency={partner?.preferences?.currency?.code} maximumFractionDigits={0} />
                        </td>
                        <td><FormattedDate datetime={String(fee.createdAt)} options={{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }} /></td>
                        <td onClick={handleClientNameClick(fee)}><Text color="accent" cursor="pointer">{fee.client?.fullName}</Text></td>
                    </tr>)}
                </tbody>}
                {!isLoading && fees.length === 0 && <tbody>
                    <td colSpan={10}>{t('action_fees.no_fees')}</td>
                </tbody>}
            </table>
            {showLoadMore && <Flex justifyContent="center" alignItems="center"><Button mt={4} variant="secondary" disabled={isLoading} onClick={increaseCounter}>{t('action_fees.load_more')}</Button></Flex>}
        </Card.Content>
    </Card>
}

export default ActionFees
