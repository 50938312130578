import React, { Component } from 'react'
import FormattedDate from '../../utils/FormattedDate'
import { withTranslation } from 'react-i18next'
import FormattedAmount from '../../utils/FormattedAmount'
import { Label } from 'react-bootstrap'
import { generateApiUrl } from '../../../utils/urls'

@withTranslation('', { wait: true })
export default class RefundItem extends Component {
    render() {
        const { refund, t } = this.props
        let downloadUrl = generateApiUrl('invoices/' + refund.invoice.id + '.pdf')

        return (
            <tr>
                <td className="table-mobile-cell">
                    <table className="definition-list">
                        <tr>
                            <td>{t('refunds.invoice')}</td>
                            <td><a href={downloadUrl} target="_blank">{t(`invoices.type.${refund.invoice.type}`)} #{refund.invoice.invoiceNumber}</a></td>
                        </tr>
                        <tr>
                            <td>{t('refunds.request_date')}</td>
                            <td><FormattedDate date={refund.createdAt} format="date" /></td>
                        </tr>
                        <tr>
                            <td>{t('refunds.request_amount')}</td>
                            <td>
                                {refund.requestedAmountExclVat && <FormattedAmount
                                    amount={refund.requestedAmountExclVat.amount}
                                    currency={refund.requestedAmountExclVat.currency}
                                />}
                            </td>
                        </tr>
                        <tr>
                            <td>{t('refunds.status')}</td>
                            <td>
                                <Label
                                    bsStyle={(refund.status === 'rejected' ? 'danger' : refund.status === 'approved' ? 'success' : 'new')}
                                >
                                    {t('refunds.statuses.' + refund.status)}
                                </Label>
                            </td>
                        </tr>
                        <tr>
                            <td>{t('refunds.accept_amount')}</td>
                            <td>{refund.acceptedAmountExclVat &&
                                <FormattedAmount
                                    amount={refund.acceptedAmountExclVat.amount}
                                    currency={refund.acceptedAmountExclVat.currency}
                                />}
                            </td>
                        </tr>
                        <tr>
                            <td>{t('refunds.reason')}</td>
                            <td>{refund.employeeDecisionReason}</td>
                        </tr>
                    </table>
                </td>
                <td><a href={downloadUrl} target="_blank">{t(`invoices.type.${refund.invoice.type}`)} #{refund.invoice.invoiceNumber}</a></td>
                <td><FormattedDate date={refund.createdAt} format="date" /></td>
                <td>
                    {refund.requestedAmountExclVat && <FormattedAmount
                        amount={refund.requestedAmountExclVat.amount}
                        currency={refund.requestedAmountExclVat.currency}
                    />}
                </td>
                <td>
                    <Label
                        bsStyle={(refund.status === 'rejected' ? 'danger' : refund.status === 'accepted' ? 'success' : 'new')}
                    >
                        {t('refunds.statuses.' + refund.status)}
                    </Label >
                </td>
                <td>
                    {refund.acceptedAmountExclVat &&
                    <FormattedAmount
                        amount={refund.acceptedAmountExclVat.amount}
                        currency={refund.acceptedAmountExclVat.currency}
                    />}
                </td>
                <td>{refund.employeeDecisionReason}</td>
            </tr>
        )
    }
}
