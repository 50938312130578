import React from 'react'
import { Card } from '../../../../../components/blocks/Card'
import moment from 'moment/moment'
import { connect } from 'react-redux'
import { getRatings } from '../../../../../actions/ratings'
import { withTranslation } from 'react-i18next'
import { StarRatingInput } from '../../../../../components/utils/StarRatingInput'
import { Flex } from '../../../../../components/elements/Flex'
import styled from 'styled-components'
import { cardLabelColor } from '../../../../../theme/variables'
import { partnerRatingsSelector } from '../../../../../selectors/partners'
import { Button } from '../../../../../components/elements/Button'
import LoadingAnimation from '../../../../../components/utils/LoadingAnimation'

const Comment = styled.div`
  margin-top: 35px;
  line-height: 1.53;
  font-style: italic;
  color: ${cardLabelColor};
  font-size: 17px;
`

const CommentTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  margin-right: 20px;
`

const CommentInfo = styled.div`
  color: ${cardLabelColor};
  margin-top: 15px;
  font-size: 17px;
`

@connect(
    state => ({
        ratings: partnerRatingsSelector(state),
        pagination: state.pages.ratings.pagination,
        isLoading: state.pages.ratings.isLoading,
    }),
    { getRatings }
)

@withTranslation('', { wait: true })

export class RatingOverviewCard extends React.Component {
    getRatings(partnerId, limit) {
        this.props.getRatings(partnerId, 1, limit || 3)
    }

    render() {
        const {
            pagination: {
                total,
                pages,
                page,
            },
            isLoading,
            partnerId,
            ratings,
            t,
        } = this.props

        if (isLoading) return <LoadingAnimation />

        if(!ratings.length) return null

        let hasMore = isLoading ? false : page < pages

        return <Card>
            <Card.Content modifiers={[ 'pY_5', 'pX_6' ]}>
                <Card.Heading modifiers={[ 'primaryColor', 'mB_5' ]}>
                    {t('quotes.quote.reviews')} ({total})
                </Card.Heading>
                {ratings.length ?
                    ratings.map((rating, i) =>
                        <div key={'rating' + i}>
                            <Flex modifiers={[ 'justifySpaceBetween' ]}>
                                <div>
                                    <CommentTitle>{rating.title}</CommentTitle>
                                    <CommentInfo>
                                        {t('ratings.rating_by', {
                                            date: moment(rating.updatedAt).format('DD/MM-YYYY'),
                                            name: rating.name,
                                        })}
                                    </CommentInfo>
                                </div>
                                <StarRatingInput
                                    filled={rating.stars}
                                    total={5}
                                />
                            </Flex>
                            <Comment>{rating.comment}</Comment>
                            {ratings.length - 1 !== i &&
                            <Card.Line modifiers={[ 'mY_5' ]} />
                            }
                        </div>
                    ) :
                    <Card.Intro>
                        {t('empty_list.no_review')}
                    </Card.Intro>
                }
                {hasMore &&
                <Button
                    modifiers={[ 'primary', 'btnBlock', 'p_2', 'mAuto', 'mT_6' ]}
                    onClick={() => this.getRatings(partnerId, total)}
                >
                    {t('quotes.quote.show_all_reviews', { total })}
                </Button>
                }
            </Card.Content>
        </Card>
    }
}
