import styled from 'styled-components'
import { cardPadding } from '../../../theme/variables'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { MODIFIER_MARGIN_CONFIG, MODIFIER_PADDING_CONFIG } from '../../elements/modifiers/spaceModifiers'

const MODIFIER_SCROLLABLE_CONTENT_CONFIG = {
    scroll: () => `
        overflow-y: auto;
    `,
    maxHeightLg: () => `
        max-height: 550px;
    `,
}

export default styled.div`
    padding: ${({ padding }) => padding || cardPadding};
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)}
    ${applyStyleModifiers(MODIFIER_SCROLLABLE_CONTENT_CONFIG)}
`
