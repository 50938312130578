import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const GET_TRANSACTIONS = 'transactions/GET_TRANSACTIONS'
export const GET_TRANSACTIONS_SUCCESS = 'transactions/GET_TRANSACTIONS_SUCCESS'
export const GET_TRANSACTIONS_FAIL = 'transactions/GET_TRANSACTIONS_FAIL'

export const getTransactions = (partner, page = 1) => dispatch => dispatch({
    [CALL_API]: {
        types: [ GET_TRANSACTIONS, GET_TRANSACTIONS_SUCCESS, GET_TRANSACTIONS_FAIL ],
        endpoint: `/partners/${partner.id}/transactions`,
        schema: schemas.transactions,
        options: {
            body: {
                limit: 100,
                page,
            },
        },
    },
})
