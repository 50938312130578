import {
    GET_QUOTE_PROGRESS_STEPS_SUCCESS,
} from '../actions/quotes'

const initialState = []

export default function reducer(state = initialState, action = {}) {
    if(action.type === GET_QUOTE_PROGRESS_STEPS_SUCCESS) {
        return action.response.titles
    }
    return state
}
