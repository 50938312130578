import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import * as refunds from '../../actions/refunds'
import LoadingAnimation from '../utils/LoadingAnimation'
import PageTitle from '../utils/PageTitle'
import RefundItem from '../finance/refunds/RefundItem'
import Nl2br from '../utils/Nl2br'

@connect(
    (state, props) => ({
        refund: state.entities.refunds[props.match.params.refundId],
        isLoading: state.pages.leadDetail.isLoading,
    }),
    dispatch => ({
        getRefund: bindActionCreators(refunds.getRefund, dispatch),
    })
)
@withTranslation('', { wait: true })
export default class RefundsDetailPage extends Component {
    componentDidMount() {
        this.props.getRefund(this.props.match.params.refundId)
    }

    render() {
        const {
            refund,
            isLoading,
            t,
        } = this.props

        if (isLoading || !refund) {
            return <LoadingAnimation />
        }

        return <div className="card">
            <PageTitle identifier="refunds.detail_title" />

            <span className={'pull-right label label-' + (refund.status === 'rejected' ? 'danger' : 'success')}>{t('refunds.statuses.' + refund.status)}</span>

            <h2>{t('refunds.detail.header')}</h2>

            <h4>{t('refunds.detail.argument')}</h4>
            <p>{refund.argument}</p>

            <h4>{t('refunds.detail.reason')}</h4>
            <p>{refund.reason}</p>

            <h4>{t('refunds.detail.contact')}</h4>
            <p>{refund.contact}</p>

            <h4>{t('refunds.detail.settlement')} <span className={'label label-' + (refund.status === 'rejected' ? 'danger' : 'success')}>{t('refunds.statuses.' + refund.status)}</span></h4>
            <p>{Nl2br(refund.settlement)}</p>
        </div>
    }
}
