import { createSelector } from 'reselect'
import moment from 'moment'

const invoiceItemsSelector = state => Object.keys(state.entities.invoices).map(id => state.entities.invoices[id])

export const overdueInvoicesSelector = createSelector(
    invoiceItemsSelector,
    items => items.filter(invoice => moment(invoice.dueAt).isBefore(new Date()))
)

export const unpaidInvoicesTillFirsOfJuneSelector = createSelector(
    state => state.pages.invoices.notPaidInvoicesId,
    state => state.entities.invoices,
    (invoiceIds, invoices) =>
        invoiceIds.map(id => invoices[id]).filter(el => moment(el.dueAt).isBefore('2018-06-01'))
)

export const isLoadingInvoicesSelector = createSelector(
    state => state.pages.invoices.isLoadingNotPaidInvoices,
    isLoading => isLoading
)
