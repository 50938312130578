import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'
import { fetchJSON } from '../fetch'

export const GET_CHECKOUT = 'checkouts/GET_CHECKOUT'
export const GET_CHECKOUT_SUCCESS = 'checkouts/GET_CHECKOUT_SUCCESS'
export const GET_CHECKOUT_FAIL = 'checkouts/GET_CHECKOUT_FAIL'

export const COMPLETE_CHECKOUT = 'checkouts/COMPLETE_CHECKOUT'
export const COMPLETE_CHECKOUT_SUCCESS = 'checkouts/COMPLETE_CHECKOUT_SUCCESS'
export const COMPLETE_CHECKOUT_FAIL = 'checkouts/COMPLETE_CHECKOUT_FAIL'

export const CREATE_CHECKOUT = 'checkouts/CREATE_CHECKOUT'
export const CREATE_CHECKOUT_SUCCESS = 'checkouts/CREATE_CHECKOUT_SUCCESS'
export const CREATE_CHECKOUT_FAIL = 'checkouts/CREATE_CHECKOUT_FAIL'

export const getCheckout = checkoutId => ({
    [CALL_API]: {
        types: [ GET_CHECKOUT, GET_CHECKOUT_SUCCESS, GET_CHECKOUT_FAIL ],
        endpoint: `/checkouts/${checkoutId}`,
        schema: schemas.checkout,
    },
})

export const createCheckout = checkout => ({
    [CALL_API]: {
        types: [ CREATE_CHECKOUT, CREATE_CHECKOUT_SUCCESS, CREATE_CHECKOUT_FAIL ],
        endpoint: '/checkouts',
        schema: schemas.checkout,
        options: {
            method: 'post',
            body: checkout,
        },
    },
})

export const startCheckout = (buyer, lines, scope = null) => createCheckout({
    buyer,
    lines,
    scope: scope ? JSON.stringify(scope) : null,
})

export const completeCheckout = (callbackUrl, body) => ({
    [CALL_API]: {
        types: [ COMPLETE_CHECKOUT, COMPLETE_CHECKOUT_SUCCESS, COMPLETE_CHECKOUT_FAIL ],
        endpoint: callbackUrl,
        schema: schemas.checkout,
        options: {
            method: 'post',
            body,
        },
    },
})
