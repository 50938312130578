import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import * as auth from '../../actions/auth'
import PageHeaderDropdown from '../../components/layout/PageHeaderDropdown'
import { currentPartnerUserSelector } from '../../selectors/auth'
import { Icon } from '../../components/layout/images'
import styled from 'styled-components'

const ProfileButton = styled.div`
    display: flex;
`

@connect(
    state => ({
        user: currentPartnerUserSelector(state),
    }),
    dispatch => ({
        logout: bindActionCreators(auth.logout, dispatch),
    })
)
@withTranslation('', { wait: true })
export default class CurrentUser extends React.Component {
    logout(event) {
        event.preventDefault()
        this.props.logout()
    }

    render() {
        const {
            acceptMode,
            user,
            t,
            size,
        } = this.props

        return (
            <PageHeaderDropdown id="user-menu" button={size === 'large' || size === 'medium' ? <ProfileButton>
                {user && (user.firstName + ' ' + user.lastName)}
                <Icon style={{ marginLeft: '8px', marginTop: '3px' }} modifiers={[ 'menuArrow' ]}>
                    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.666 6L7.99935 10.6667L3.33268 6" stroke="#E9F6FF" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </Icon>
            </ProfileButton> : <Icon modifiers={[ 'tiny' ]}>
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22 14L2 14" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M22 7L8 7" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M22 21L2 21" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </Icon>}>
                <div style={{ padding: '15px' }}>
                    {acceptMode || <Link to={{ pathname: '/me/profile' }} className="btn btn-info btn-block">
                        {t('user.profile_edit.header')}
                    </Link>
                    }
                    <a onClick={this.logout.bind(this)} className="btn btn-info btn-block">
                        {t('user.log_out')}
                    </a>
                </div>
            </PageHeaderDropdown>
        )
    }
}
