import { decamelizeKeys } from 'humps'
import { fetch } from '../fetch'
import config from '../config'
import { handleError } from './errors'
import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const GET_PARTNER = 'partners/GET_PARTNER'
export const GET_PARTNER_SUCCESS = 'partners/GET_PARTNER_SUCCESS'
export const GET_PARTNER_FAIL = 'partners/GET_PARTNER_FAIL'

export const GET_PARTNERS = 'partners/GET_PARTNERS'
export const GET_PARTNERS_SUCCESS = 'partners/GET_PARTNERS_SUCCESS'
export const GET_PARTNERS_FAIL = 'partners/GET_PARTNERS_FAIL'

export const SAVE_PARTNER = 'partners/SAVE_PARTNER'
export const SAVE_PARTNER_SUCCESS = 'partners/SAVE_PARTNER_SUCCESS'
export const SAVE_PARTNER_FAIL = 'partners/SAVE_PARTNER_FAIL'

export const SAVE_PARTNER_NOTE = 'partners/SAVE_PARTNER_NOTE'
export const SAVE_PARTNER_NOTE_SUCCESS = 'partners/SAVE_PARTNER_NOTE_SUCCESS'
export const SAVE_PARTNER_NOTE_FAIL = 'partners/SAVE_PARTNER_NOTE_FAIL'

export const SAVE_PARTNER_LOGO = 'partners/SAVE_PARTNER_LOGO'
export const SAVE_PARTNER_LOGO_SUCCESS = 'partners/SAVE_PARTNER_LOGO_SUCCESS'
export const SAVE_PARTNER_LOGO_FAIL = 'partners/SAVE_PARTNER_LOGO_FAIL'

export const DELETE_PARTNER_LOGO = 'partners/DELETE_PARTNER_LOGO'
export const DELETE_PARTNER_LOGO_SUCCESS = 'partners/DELETE_PARTNER_LOGO_SUCCESS'
export const DELETE_PARTNER_LOGO_FAIL = 'partners/DELETE_PARTNER_LOGO_FAIL'

export const ADD_INDUSTRY_TO_PARTNER = 'partners/ADD_INDUSTRY_TO_PARTNER'
export const ADD_INDUSTRY_TO_PARTNER_SUCCESS = 'partners/ADD_INDUSTRY_TO_PARTNER_SUCCESS'
export const ADD_INDUSTRY_TO_PARTNER_FAIL = 'partners/ADD_INDUSTRY_TO_PARTNER_FAIL'

export const REMOVE_INDUSTRY_FROM_PARTNER = 'partners/REMOVE_INDUSTRY_FROM_PARTNER'
export const REMOVE_INDUSTRY_FROM_PARTNER_SUCCESS = 'partners/REMOVE_INDUSTRY_FROM_PARTNER_SUCCESS'
export const REMOVE_INDUSTRY_FROM_PARTNER_FAIL = 'partners/REMOVE_INDUSTRY_FROM_PARTNER_FAIL'

export const GET_PARTNER_VERIFICATIONS = 'partners/GET_PARTNER_VERIFICATIONS'
export const GET_PARTNER_VERIFICATIONS_SUCCESS = 'partners/GET_PARTNER_VERIFICATIONS_SUCCESS'
export const GET_PARTNER_VERIFICATIONS_FAIL = 'partners/GET_PARTNER_VERIFICATIONS_FAIL'

export const SAVE_PARTNER_VERIFICATIONS = 'partners/SAVE_PARTNER_VERIFICATIONS'
export const SAVE_PARTNER_VERIFICATIONS_SUCCESS = 'partners/SAVE_PARTNER_VERIFICATIONS_SUCCESS'
export const SAVE_PARTNER_VERIFICATIONS_FAIL = 'partners/SAVE_PARTNER_VERIFICATIONS_FAIL'

export const SAVE_PARTNER_VERIFICATION_FILE = 'partners/SAVE_PARTNER_VERIFICATION_FILE'
export const SAVE_PARTNER_VERIFICATION_FILE_SUCCESS = 'partners/SAVE_PARTNER_VERIFICATION_FILE_SUCCESS'
export const SAVE_PARTNER_VERIFICATION_FILE_FAIL = 'partners/SAVE_PARTNER_VERIFICATION_FILE_FAIL'

export const GET_PARTNER_BANK_ACCOUNTS = 'partners/GET_PARTNER_BANK_ACCOUNTS'
export const GET_PARTNER_BANK_ACCOUNTS_SUCCESS = 'partners/GET_PARTNER_BANK_ACCOUNTS_SUCCESS'
export const GET_PARTNER_BANK_ACCOUNTS_FAIL = 'partners/GET_PARTNER_BANK_ACCOUNTS_FAIL'

export const SAVE_PARTNER_BANK_ACCOUNT = 'partners/SAVE_PARTNER_BANK_ACCOUNT'
export const SAVE_PARTNER_BANK_ACCOUNT_SUCCESS = 'partners/SAVE_PARTNER_BANK_ACCOUNT_SUCCESS'
export const SAVE_PARTNER_BANK_ACCOUNT_FAIL = 'partners/SAVE_PARTNER_BANK_ACCOUNT_FAIL'

export const GET_PARTNER_SUBSCRIPTION_AGREEMENTS = 'partner/GET_PARTNER_SUBSCRIPTION_AGREEMENTS'
export const GET_PARTNER_SUBSCRIPTION_AGREEMENTS_SUCCESS = 'partner/GET_PARTNER_SUBSCRIPTION_AGREEMENTS_SUCCESS'
export const GET_PARTNER_SUBSCRIPTION_AGREEMENTS_FAIL = 'partner/GET_PARTNER_SUBSCRIPTION_AGREEMENTS_FAIL'

export const ACCEPT_PARTNER_SUBSCRIPTION_AGREEMENTS = 'partner/ACCEPT_PARTNER_SUBSCRIPTION_AGREEMENTS'
export const ACCEPT_PARTNER_SUBSCRIPTION_AGREEMENTS_SUCCESS = 'partner/ACCEPT_PARTNER_SUBSCRIPTION_AGREEMENTS_SUCCESS'
export const ACCEPT_PARTNER_SUBSCRIPTION_AGREEMENTS_FAIL = 'partner/ACCEPT_PARTNER_SUBSCRIPTION_AGREEMENTS_FAIL'

export const CREATE_IMPRESSUMS = 'partners/CREATE_IMPRESSUMS'
export const CREATE_IMPRESSUMS_SUCCESS = 'partners/CREATE_IMPRESSUMS_SUCCESS'
export const CREATE_IMPRESSUMS_FAIL = 'partners/CREATE_IMPRESSUMS_FAIL'

export const CREATE_BROWSER_PUSH_SUBSCRIPTION = 'partners/CREATE_BROWSER_PUSH_SUBSCRIPTION'
export const CREATE_BROWSER_PUSH_SUBSCRIPTION_SUCCESS = 'partners/CREATE_BROWSER_PUSH_SUBSCRIPTION_SUCCESS'
export const CREATE_BROWSER_PUSH_SUBSCRIPTION_FAIL = 'partners/CREATE_BROWSER_PUSH_SUBSCRIPTION_FAIL'

export const SUBSCRIBE_TO_MARKETPLACE = 'partners/SUBSCRIBE_TO_MARKETPLACE'
export const SUBSCRIBE_TO_MARKETPLACE_SUCCESS = 'partners/SUBSCRIBE_TO_MARKETPLACE_SUCCESS'
export const SUBSCRIBE_TO_MARKETPLACE_FAIL = 'partners/SUBSCRIBE_TO_MARKETPLACE_FAIL'

export const SEND_PURCHASE_LIMIT_REACHED = 'partners/SEND_PURCHASE_LIMIT_REACHED'
export const SEND_PURCHASE_LIMIT_REACHED_SUCCESS = 'partners/SEND_PURCHASE_LIMIT_REACHED_SUCCESS'
export const SEND_PURCHASE_LIMIT_REACHED_FAIL = 'partners/SEND_PURCHASE_LIMIT_REACHED_FAIL'

export const getPartner = (partnerId, criteria) => ({
    [CALL_API]: {
        types: [ GET_PARTNER, GET_PARTNER_SUCCESS, GET_PARTNER_FAIL ],
        endpoint: `/partners/${partnerId}`,
        schema: schemas.partner,
        options: {
            body: criteria,
        },
    },
})

export const getPartners = criteria => ({
    [CALL_API]: {
        types: [ GET_PARTNERS, GET_PARTNERS_SUCCESS, GET_PARTNERS_FAIL ],
        endpoint: '/partners',
        schema: schemas.partners,
        options: {
            body: {
                state: 'active,inactive,canvas,demo,ex_partner',
                ...criteria,
            },
        },
    },
})

export const savePartnerNote = (partnerId, note, satisfactionLevelId) => dispatch => dispatch({
    [CALL_API]: {
        types: [ SAVE_PARTNER_NOTE, SAVE_PARTNER_NOTE_SUCCESS, SAVE_PARTNER_NOTE_FAIL ],
        endpoint: `/partners/${partnerId}/notes`,
        schema: schemas.partner,
        options: {
            method: 'post',
            body: {
                note,
                satisfaction: { satisfaction_level_id: satisfactionLevelId },
            },
        },
    },
})

export const savePartnerLogo = (partnerId, logoData) => dispatch => dispatch({
    [CALL_API]: {
        types: [ SAVE_PARTNER_LOGO, SAVE_PARTNER_LOGO_SUCCESS, SAVE_PARTNER_LOGO_FAIL ],
        endpoint: `/partners/${partnerId}/contents/logo`,
        schema: schemas.partner,
        options: {
            method: 'put',
            body: {
                key: 'logo',
                value: logoData,
            },
        },
    },
})

export const deletePartnerLogo = partnerId => dispatch => dispatch({
    [CALL_API]: {
        types: [ DELETE_PARTNER_LOGO, DELETE_PARTNER_LOGO_SUCCESS, DELETE_PARTNER_LOGO_FAIL ],
        endpoint: `/partners/${partnerId}/contents/logo`,
        schema: schemas.partner,
        options: {
            method: 'delete',
        },
    },
})

export const savePartner = partner => dispatch => dispatch({
    [CALL_API]: {
        types: [ SAVE_PARTNER, SAVE_PARTNER_SUCCESS, SAVE_PARTNER_FAIL ],
        endpoint: `/partners/${partner.id}`,
        schema: schemas.partner,
        options: {
            method: 'put',
            body: decamelizeKeys(partner),
        },
    },
})

export const addIndustryToPartner = (partner, industry) => dispatch => dispatch({
    [CALL_API]: {
        types: [ ADD_INDUSTRY_TO_PARTNER, ADD_INDUSTRY_TO_PARTNER_SUCCESS, ADD_INDUSTRY_TO_PARTNER_FAIL ],
        endpoint: `${config.serviceUrl}/partners/${partner.id}/industries`,
        schema: schemas.industry,
        options: {
            method: 'post',
            body: {
                id: industry.id,
            },
        },
    },
})

export const removeIndustryFromPartner = (partner, industry) => dispatch => {
    dispatch({ type: REMOVE_INDUSTRY_FROM_PARTNER })
    fetch(`${config.serviceUrl}/partners/${partner.id}/industries/${industry.id}`, {
        method: 'delete',
    })
    .then(() => dispatch({ type: REMOVE_INDUSTRY_FROM_PARTNER_SUCCESS, industry }))
    .catch(e => handleError(dispatch, e))
}

export const getVerifications = partnerId => ({
    [CALL_API]: {
        types: [ GET_PARTNER_VERIFICATIONS, GET_PARTNER_VERIFICATIONS_SUCCESS, GET_PARTNER_VERIFICATIONS_FAIL ],
        endpoint: `/partners/${partnerId}/verifications`,
        schema: schemas.verifications,
    },
})

export const saveVerifications = (partnerId, data) => dispatch => dispatch({
    [CALL_API]: {
        types: [ SAVE_PARTNER_VERIFICATIONS, SAVE_PARTNER_VERIFICATIONS_SUCCESS, SAVE_PARTNER_VERIFICATIONS_FAIL ],
        endpoint: `/partners/${partnerId}/verifications`,
        schema: schemas.verification,
        options: {
            method: 'put',
            body: data,
        },
    },
})

export const getBankAccounts = partnerId => ({
    [CALL_API]: {
        types: [ GET_PARTNER_BANK_ACCOUNTS, GET_PARTNER_BANK_ACCOUNTS_SUCCESS, GET_PARTNER_BANK_ACCOUNTS_FAIL ],
        endpoint: `/partners/${partnerId}/bankaccounts`,
        schema: schemas.bankAccounts,
    },
})

export const saveBankAccount = (partnerId, data) => dispatch => dispatch({
    [CALL_API]: {
        types: [ SAVE_PARTNER_BANK_ACCOUNT, SAVE_PARTNER_BANK_ACCOUNT_SUCCESS, SAVE_PARTNER_BANK_ACCOUNT_FAIL ],
        endpoint: `/partners/${partnerId}/bankaccounts`,
        schema: schemas.bankAccount,
        options: {
            method: 'post',
            body: data,
        },
    },
})

export const getPartnerSubscriptionAgreements = (partnerId, body) => ({
    [CALL_API]: {
        types: [
            GET_PARTNER_SUBSCRIPTION_AGREEMENTS,
            GET_PARTNER_SUBSCRIPTION_AGREEMENTS_SUCCESS,
            GET_PARTNER_SUBSCRIPTION_AGREEMENTS_FAIL,
        ],
        endpoint: `/partners/${partnerId}/subscriptionagreements`,
        schema: schemas.subscriptionAgreements,
        options: {
            body,
        },
    },
})

export const acceptPartnerSubscriptionAgreement = (partnerId, agreementId, body) => ({
    [CALL_API]: {
        types: [ ACCEPT_PARTNER_SUBSCRIPTION_AGREEMENTS, ACCEPT_PARTNER_SUBSCRIPTION_AGREEMENTS_SUCCESS, ACCEPT_PARTNER_SUBSCRIPTION_AGREEMENTS_FAIL ],
        endpoint: `/partners/${partnerId}/subscriptionagreements/${agreementId}/actions`,
        schema: schemas.terms,
        options: {
            method: 'post',
            body: body,
        },
    },
})

export const createImpressums = (partnerId, body) => ({
    [CALL_API]: {
        types: [ CREATE_IMPRESSUMS, CREATE_IMPRESSUMS_SUCCESS, CREATE_IMPRESSUMS_FAIL ],
        endpoint: `/partners/${partnerId}/impressums`,
        options: {
            method: 'post',
            body,
        },
    },
})

export const createBrowserPushSubscription = (partnerId, body) => dispatch => dispatch({
    [CALL_API]: {
        types: [ CREATE_BROWSER_PUSH_SUBSCRIPTION, CREATE_BROWSER_PUSH_SUBSCRIPTION_SUCCESS, CREATE_BROWSER_PUSH_SUBSCRIPTION_FAIL ],
        endpoint: `/partners/${partnerId}/browserpushsubscriptions`,
        options: {
            method: 'post',
            body,
        },
        suppressLoader: true,
    },
})

export const subscribeToMarketplace = (partnerId, partnerPackageTypeId) => ({
    [CALL_API]: {
        types: [ SUBSCRIBE_TO_MARKETPLACE, SUBSCRIBE_TO_MARKETPLACE_SUCCESS, SUBSCRIBE_TO_MARKETPLACE_FAIL ],
        endpoint: `/partners/${partnerId}/actions`,
        schema: schemas.partner,
        options: {
            method: 'post',
            body: {
                action: 'subscribe_to_lead_marketplace',
                partnerPackageTypeId,
            },
        },
    },
})

export const sendPurchaseLimitReached = (partnerId) => ({
    [CALL_API]: {
        types: [ SEND_PURCHASE_LIMIT_REACHED, SEND_PURCHASE_LIMIT_REACHED_SUCCESS, SEND_PURCHASE_LIMIT_REACHED_FAIL ],
        endpoint: `/partners/${partnerId}/actions`,
        options: {
            method: 'post',
            body: {
                action: 'send_purchase_limit_reached',
            },
        },
    },
})
