import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const PREFER_PAYMENT_METHOD = 'paymentMethods/PREFER_PAYMENT_METHOD'
export const PREFER_PAYMENT_METHOD_SUCCESS = 'paymentMethods/PREFER_PAYMENT_METHOD_SUCCESS'
export const PREFER_PAYMENT_METHOD_FAIL = 'paymentMethods/PREFER_PAYMENT_METHOD_FAIL'

export const preferPaymentMethod = (paymentMethodId) => ({
    [CALL_API]: {
        types: [ PREFER_PAYMENT_METHOD, PREFER_PAYMENT_METHOD_SUCCESS, PREFER_PAYMENT_METHOD_FAIL ],
        endpoint: `/payments/methods/${paymentMethodId}/actions`,
        schema: schemas.paymentMethod,
        options: {
            method: 'post',
            body: {
                action: 'prefer',
            },
        },
    },
})
