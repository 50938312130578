import union from 'lodash.union'
import {
    GET_RECENT_LEADS,
    GET_RECENT_LEADS_SUCCESS,
    GET_RECENT_LEADS_FAIL,
    GET_ALL_EXCLUSIVE_LEADS,
    GET_ALL_EXCLUSIVE_LEADS_SUCCESS,
    GET_ALL_EXCLUSIVE_LEADS_FAIL,
    GET_BOUGHT_LEADS,
    GET_BOUGHT_LEADS_SUCCESS,
    GET_BOUGHT_LEADS_FAIL,
    CLEAR_LIST,
    GO_TO_PAGE,
    GET_LEADS,
    GET_LEADS_SUCCESS,
    GET_LEADS_FAIL,
    GET_DEMO_LEADS,
    GET_DEMO_LEADS_SUCCESS,
    GET_DEMO_LEADS_FAIL,
    GET_LEAD_ALLOCATION_SUGGESTIONS,
    GET_LEAD_ALLOCATION_SUGGESTIONS_SUCCESS,
    GET_LEAD_ALLOCATION_SUGGESTIONS_FAIL,
    ACCEPT_LEAD_ALLOCATION_SUGGESTIONS,
    ACCEPT_LEAD_ALLOCATION_SUGGESTIONS_SUCCESS,
    ACCEPT_LEAD_ALLOCATION_SUGGESTIONS_FAIL,
} from '../../actions/leads'

import {
    GET_DUE_INVOICES, GET_DUE_INVOICES_SUCCESS, GET_DUE_INVOICES_FAIL,
} from '../../actions/invoices'

const initialPageDataState = {
    leadIds: [],
    isLoading: false,
    pagination: {},
}

const initialState = {
    recentLeads: initialPageDataState,
    allExclusiveLeads: initialPageDataState,
    leads: initialPageDataState,
    boughtLeads: initialPageDataState,
    demoLeads: initialPageDataState,
    invoiceIds: [],
    invoicesLoading: true,
    leadAllocationSuggestions: [],
}

function pageData(state = initialPageDataState, action = {}) {
    switch (action.type) {
        case GET_RECENT_LEADS:
        case GET_ALL_EXCLUSIVE_LEADS:
        case GET_BOUGHT_LEADS:
        case GET_LEADS:
        case GET_DEMO_LEADS:
            return {
                ...state,
                isLoading: true,
            }
        case GET_RECENT_LEADS_SUCCESS:
        case GET_ALL_EXCLUSIVE_LEADS_SUCCESS:
        case GET_BOUGHT_LEADS_SUCCESS:
        case GET_LEADS_SUCCESS:
        case GET_DEMO_LEADS_SUCCESS:
            return {
                ...state,
                leadIds: union(state.leadIds, action.response.result),
                pagination: action.response.pagination,
                isLoading: false,
            }
        case GET_RECENT_LEADS_FAIL:
        case GET_ALL_EXCLUSIVE_LEADS_FAIL:
        case GET_BOUGHT_LEADS_FAIL:
        case GET_LEADS_FAIL:
        case GET_DEMO_LEADS_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        case GO_TO_PAGE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    page: action.page,
                },
            }
        default:
            return state
    }
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_RECENT_LEADS:
        case GET_RECENT_LEADS_SUCCESS:
        case GET_RECENT_LEADS_FAIL:
            return {
                ...state,
                recentLeads: pageData(state.recentLeads, action),
            }
        case GET_ALL_EXCLUSIVE_LEADS:
        case GET_ALL_EXCLUSIVE_LEADS_SUCCESS:
        case GET_ALL_EXCLUSIVE_LEADS_FAIL:
            return {
                ...state,
                allExclusiveLeads: pageData(state.allExclusiveLeads, action),
            }
        case GET_LEADS:
        case GET_LEADS_SUCCESS:
        case GET_LEADS_FAIL:
            return {
                ...state,
                leads: pageData(state.leads, action),
            }
        case GET_DEMO_LEADS:
        case GET_DEMO_LEADS_SUCCESS:
        case GET_DEMO_LEADS_FAIL:
            return {
                ...state,
                demoLeads: pageData(state.demoLeads, action),
            }
        case GET_BOUGHT_LEADS:
        case GET_BOUGHT_LEADS_SUCCESS:
        case GET_BOUGHT_LEADS_FAIL:
            return {
                ...state,
                boughtLeads: pageData(state.boughtLeads, action),
            }
        case CLEAR_LIST:
        case GO_TO_PAGE:
            return {
                ...state,
                recentLeads: pageData(state.recentLeads, action),
                allExclusiveLeads: pageData(state.allExclusiveLeads, action),
                boughtLeads: pageData(state.boughtLeads, action),
                demoLeads: pageData(state.demoLeads, action),
            }
        case GET_DUE_INVOICES:
            return {
                ...state,
                invoicesLoading: true,
            }
        case GET_DUE_INVOICES_SUCCESS:
            return {
                ...state,
                invoiceIds: action.response.result,
                invoicesLoading: false,
            }
        case GET_DUE_INVOICES_FAIL:
            return {
                ...state,
                invoicesLoading: false,
            }
        case GET_LEAD_ALLOCATION_SUGGESTIONS:
            return {
                ...state,
                fetchingAllocationSuggestions: true,
                fetchingAllocationSuggestionsFail: false,
            }
        case GET_LEAD_ALLOCATION_SUGGESTIONS_SUCCESS:
            return {
                ...state,
                leadAllocationSuggestions: action.response?.data,
                fetchingAllocationSuggestions: false,
                fetchingAllocationSuggestionsFail: false,
            }
        case GET_LEAD_ALLOCATION_SUGGESTIONS_FAIL:
            return {
                ...state,
                fetchingAllocationSuggestions: false,
                fetchingAllocationSuggestionsFail: true,
            }
        case ACCEPT_LEAD_ALLOCATION_SUGGESTIONS:
            return {
                ...state,
                acceptingAllocationSuggestions: true,
                acceptingAllocationSuggestionsFail: false,
            }
        case ACCEPT_LEAD_ALLOCATION_SUGGESTIONS_SUCCESS:
            return {
                ...state,
                acceptingAllocationSuggestions: false,
                acceptingAllocationSuggestionsFail: false,
            }
        case ACCEPT_LEAD_ALLOCATION_SUGGESTIONS_FAIL:
            return {
                ...state,
                acceptingAllocationSuggestions: false,
                acceptingAllocationSuggestionsFail: true,
            }
        default:
            return state
    }
}
