import React from 'react'
import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import { currentPartnerSelector } from '../../selectors/auth'
import { getAllExclusiveLeads } from '../../actions/leads'
import { getPartnerBusinessUnits } from '../../actions/businessUnits'
import LoadingAnimation from '../utils/LoadingAnimation'
import { hasMarketplaceLeadAccessSelector, hasMarketplaceSignUpAccessSelector } from '../../selectors/access'
import { exclusiveLeadsListHiddenCountries } from '../../containers/layout/PartnerPageHeader'

@connect(
    state => ({
        partner: currentPartnerSelector(state),
        hasMarketplaceSignupAccess: hasMarketplaceSignUpAccessSelector(state),
        hasMarketplaceAccess: hasMarketplaceLeadAccessSelector(state),
    }),
    {
        getAllExclusiveLeads,
        getPartnerBusinessUnits,
        replace,
    }
)
export default class IndexPage extends React.Component {
    componentDidMount() {
        this.redirectIndexIfSignedIn(this.props)
    }

    componentWillReceiveProps(newProps) {
        this.redirectIndexIfSignedIn(newProps)
    }

    async redirectIndexIfSignedIn(props) {
        if(!props.partner || props.isLoading) {
            return
        }

        if(props.partner.state === 'demo') {
            props.replace({ pathname: '/demo' })
            return
        }

        if (this.props.hasMarketplaceSignupAccess && this.props.marketplaceEnabledCountries.includes(this.props.partner.geo.code)) {
            props.replace({ pathname: '/marketplace' })
            return
        }

        if (props.partner.state === 'active' && exclusiveLeadsListHiddenCountries.includes(props.partner.geo.code) && !props.hasMarketplaceAccess && (props.partner.hasMarketingPackage || props.partner.hasActiveContract || props.partner.hasSubscription)) {
            this.props.getAllExclusiveLeads()
                .then(props.replace({ pathname: '/projects' }))
            return
        }
        props.replace({ pathname: '/projects' })
    }

    render() {
        return <LoadingAnimation />
    }
}
