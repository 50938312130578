import * as React from 'react'
import { connect } from 'react-redux'
import { getTerms, acceptTerms } from '../../actions/terms'
import { markQuoteAccepted } from '../../actions/quotes'
import { push, replace } from 'connected-react-router'
import { Trans, withTranslation } from 'react-i18next'
import { Checkbox } from '../../components/cards/index'
import { Alert, Modal } from 'react-bootstrap'
import { currentClientSelector, visibleClientName } from '../../selectors/auth'
import { Link } from 'react-router-dom'
import { StarRatingInput } from '../../components/utils/StarRatingInput'
import config from '../../config'
import { Flex, QuoteCheckOutModal, Separator } from '../../components/cards'
import { ActionBarCard } from '../clients/pages/cases/quote-detail-cards/ActionBarCard'
import { Card } from '../../components/blocks/Card'
import { Button } from '../../components/elements/Button'
import { createPunchableAction } from '../../actions/creditsystem'
import { CLIENT_ACCEPTED_QUOTE, sendSegmentData } from '../../utils/segment'

const RatingsBlock = ({ rating, t }) => (
    <div>
        <StarRatingInput filled={rating.stars} total={5} />
        <p className="intro-text">
            <span>
                {t('case_page.rating_text', {
                    score: rating.score,
                    count: rating.count,
                })}
            </span>
        </p>
    </div>
)

@connect(
    state => ({
        clientId: state.auth.clientId,
        clientName: visibleClientName(state),
        terms: Object.values(state.entities.termss),
        client: currentClientSelector(state),
        assignedEmployee: currentClientSelector(state).assignedEmployee ?
            state.entities.employees[currentClientSelector(state).assignedEmployee] : null,
        creditSystemIsLoading: state.pages.creditSystem.isLoading,
    }),
    {
        getTerms,
        acceptTerms,
        markQuoteAccepted,
        push,
        replace,
        createPunchableAction,
    }
)
@withTranslation('', { wait: true })
export class CheckOutQuote extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            messageFormVisible: false,
            formValid: true,
            acceptedTerms: [],
            showModal: false,
        }
    }

    componentDidMount() {
        this.props.getTerms({
            geoCode: this.props.lead.geo.code,
            type: 'client',
            identifier: [ 'payment', 'general' ],
        })
    }

    async checkout() {
        if(this.validateForm()) {
            await this.props.createPunchableAction(this.props.quote.partner.id, this.props.lead.id, 'accept_quote')
            this.props.markQuoteAccepted(this.props.lead.id, this.props.quote.id).then(() => {
                sendSegmentData(this.props.client, CLIENT_ACCEPTED_QUOTE, {
                    partnerId: this.props.quote.partner.id,
                    leadId: this.props.lead.id,
                })
            })
                .then(() => {
                    this.props.replace(`/cases/${this.props.lead.id}/${this.props.quote.partner.id}#accepted`)
                    window.scrollTo(0, 0)
                })
        }
    }

    validateForm() {
        let valid = true
        this.props.terms.forEach(t => {
            if(!this.state.acceptedTerms.includes(t.id) &&
                this.props.lead.geo.code === 'de') {
                valid = false
            }
        })
        this.setState({ formValid: valid })

        if(valid) {
            this.props.terms.forEach(term => this.props.acceptTerms(term.id))
        }

        return valid
    }

    renderTermsAccept() {
        const {
            terms,
            lead,
            quote,
        } = this.props
        const { acceptedTerms } = this.state

        if(!terms) return null

        return <Alert bsStyle={this.state.formValid ? '' : 'alert alert-danger'}>
            {terms.map(term => config.hideGeneralTerms(quote.partner.geoCode) ||
                <Flex key={term.identifier + 'Terms'}>
                    {lead.geo.code === 'de' &&
                        [<Checkbox
                            onChange={this.toggleAcceptTerms.bind(this)}
                            checked={acceptedTerms.indexOf(term.id) !== -1}
                            id={term.identifier + 'Terms'}
                            value={term.id}
                        />, <span>&nbsp;</span>]
                    }
                    <Trans
                        i18nKey="checkout.terms.accept_label"
                        link={<Link to={`/terms/${term.id}`} target="_blank">{term.headline}</Link>}
                    />
                </Flex>
            )}
        </Alert>
    }

    toggleAcceptTerms(e) {
        const { acceptedTerms } = this.state
        this.setState({ formValid: true })
        const id = e.target.value
        // did we accept or not?
        if(e.target.checked) {
            // add the ID to the list of accepted terms
            acceptedTerms.push(parseInt(id))
        } else {
            // remove the ID from the list of accepted terms
            const index = acceptedTerms.indexOf(parseInt(id))
            if(index !== -1) {
                acceptedTerms.splice(index, 1)
            }
        }
        this.setState({ acceptedTerms })
    }

    showModal() {
        this.setState({ showModal: true })
    }

    render() {
        const {
            partnerUserName,
            partner,
            quote,
            t,
        } = this.props

        return <div>
            <ActionBarCard
                checkout={() => this.showModal()}
                partnerUserName={partnerUserName}
                t={t}
            />
            <Modal
                onHide={() => this.setState({ showModal: false })}
                show={this.state.showModal}
                backdrop="false"
                bsSize="large"
            >
                <QuoteCheckOutModal>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-lg">
                            <strong>{t('quotes.quote.modal.great_choice')}</strong>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Flex modifiers={[ 'alignCenter' ]}>
                            {partner && partner.logo && <img src={partner.logo} alt='partner logo' />}
                            <div>
                                <div><strong>{quote.partner.companyName}</strong></div>
                                {partner.rating.stars > 0 &&
                                    <RatingsBlock rating={partner.rating} t={t} />
                                }
                            </div>
                        </Flex>
                        <Card.Line modifiers={[ 'mY_2' ]}/>
                        <Card.Intro>{t('quotes.quote.modal.confirm_text', { name: partner.companyName })}</Card.Intro>
                        <Card.Intro>{t('quotes.quote.modal.confirm_text_2')}</Card.Intro>
                        <Separator/>
                        {this.renderTermsAccept()}
                        <div className="text-center">
                            <Button
                                onClick={() => this.checkout()}
                                modifiers={[ 'action', 'p_2' ]}
                            >
                                <span>{t('quotes.quote.modal.confirm_check_out_btn')}</span>
                            </Button>
                        </div>
                    </Modal.Body>
                </QuoteCheckOutModal>
            </Modal>
        </div>
    }
}
