import React from 'react'
import { ContainerFluid } from '../../../../components/cards'
import {
    createConversationWithParticipants,
    getUnreadParticipantMessages,
    readConversationMessages,
    getConversations,
    getConversation,
} from '../../../../actions/conversations'
import { getLead } from '../../../../actions/leads'
import { getPartners } from '../../../../actions/partners'
import {
    clientConversationMessagesSelector,
    clientConversationSelector,
    existNewMessageSelector,
} from '../../../../selectors/conversations'
import { getPartnerCertifications } from '../../../../actions/certifications'
import { addMessageToConversation, getProjects } from '../../../../actions/projects'
import { withTranslation } from 'react-i18next'
import { partnerLeadQuoteSelector } from '../../../../selectors/quotes'
import { getQuotes } from '../../../../actions/quotes'
import { currentClientSelector } from '../../../../selectors/auth'
import { connect } from 'react-redux'
import { hasQuotePaymentAccessSelector } from '../../../../selectors/access'
import { Col, Row } from 'react-bootstrap'
import LoadingAnimation from '../../../../components/utils/LoadingAnimation'
import union from 'lodash.union'
import styled from 'styled-components'
import { QuoteItemsInfoCard } from './quote-detail-cards/QuoteItemsInfoCard'
import { AboutPartnerCard } from './quote-detail-cards/AboutPartnerCard'
import { InterviewQuoteCard } from './quote-detail-cards/InterviewQuoteCard'
import { CertificationCard } from './quote-detail-cards/CertificationCard'
import { ChatCard } from './quote-detail-cards/ChatCard'
import { InterviewQuestionsCard } from './quote-detail-cards/InterviewQuestionsCard'
import { SystemsCard } from './quote-detail-cards/SystemsCard'
import { IndustrySpecialisationCard } from './quote-detail-cards/IndustrySpecialisationCard'
import { ServicesCard } from './quote-detail-cards/ServicesCard'
import { RatingOverviewCard } from './quote-detail-cards/RatingOverviewCard'
import { PartnersPrimaryCard } from './quote-detail-cards/PartnersPrimaryCard'
import { CheckOutQuote } from '../../../common/CheckOutQuote'
import { GoogleMap, withGoogleMap } from 'react-google-maps'
import { media } from '../../../../components/utils/styledMedia'
import { Card } from '../../../../components/blocks/Card'
import PageTitle from '../../../../components/utils/PageTitle'
import { getRatings } from '../../../../actions/ratings'
import { MenetoPriceInfoCard } from './quote-detail-cards/MenetoPriceInfoCard'
import { MenetoWorkInfoCard } from './quote-detail-cards/MenetoWorkInfoCard'
import config from '../../../../config'
import { TellowWorkInfoCard } from './quote-detail-cards/TellowWorkInfoCard'
import { TellowPriceInfoCard } from './quote-detail-cards/TellowPriceInfoCard'
import { createPunchableAction } from '../../../../actions/creditsystem'
import { createRequestMeetingAction } from '../../../../actions/meetings'
import { MeetingsCard } from './quote-detail-cards/MeetingsCard'
import { MeetingActionResource, CreditSystemActionResource } from 'ageras-api-client/src/api'
import {
    CLIENT_OPENED_QUOTE,
    CLIENT_REQUESTED_MEETING, CLIENT_SENT_PARTNER_MESSAGE,
    CLIENT_VIEWED_PARTNERS_PHONE_NUMBER,
    sendSegmentData
} from '../../../../utils/segment'

const BackgroundImage = styled.div`
  margin-top: -30px;
  position: absolute;
  background: url(https://i.imgur.com/M4V9IWQ.jpg) center;
  background-size: cover;
  height: 420px;
  width: 100%;
  z-index: -1;
`

const MyMapComponent = withGoogleMap(props =>
    <GoogleMap
        defaultCenter={props.point}
        center={props.point}
        defaultZoom={18}
        options={{
            disableDefaultUI: true,
            mapTypeId: 'satellite',
            heading: 90,
            tilt: 45,
        }}
    />
)

const QuotePage = styled.div`
  ${media.max.landscapeMobile`
    ${Card.Content} {
        padding: 25px 30px;
    }
    ${Card.Heading} {
        margin: 0;
        margin-bottom: 30px;
    }
  `}
`

@connect(
    (state, { match: { params: { leadId, partnerId } } }) => ({
        client: currentClientSelector(state),
        lead: state.entities.leads[leadId],
        partner: state.entities.partners[partnerId],
        leadId: leadId,
        partnerId: partnerId,
        conversation: clientConversationSelector(state, leadId, partnerId),
        conversationMessages: clientConversationMessagesSelector(state, leadId, partnerId),
        quote: partnerLeadQuoteSelector(state)(partnerId, leadId),
        quotesIsLoading: state.pages.quoteDetail.isLoading,
        certifications: [ ...state.pages.certifications.partnerCertificationIds ]
            .map(id => state.entities.certifications[id]),
        hasQuotePaymentPermission: hasQuotePaymentAccessSelector(state),
        hasNewMessage: existNewMessageSelector(state, leadId, partnerId),
        creditSystemIsLoading: state.pages.creditSystem.isLoading,
        meetingsIsLoading: state.pages.meetings.isLoading,
    }),
    {
        createConversationWithParticipants,
        getUnreadParticipantMessages,
        readConversationMessages,
        addMessageToConversation,
        getPartnerCertifications,
        getConversations,
        getConversation,
        getPartners,
        getProjects,
        getRatings,
        getQuotes,
        getLead,
        createPunchableAction,
        createRequestMeetingAction,
    }
)

@withTranslation('', { wait: true })
export class QuoteDetailPage extends React.Component {
    state = {
        isPhoneNumbersRevealed: false,
        isRequestMeetingSent: false,
    }

    componentDidMount() {
        const { leadId, partnerId } = this.props.match.params
        this.props.getQuotes({
            clientId: this.props.client.id,
            leadId,
        })

        this.props.getRatings(partnerId, 1, 3)

        this.props.getLead(leadId).then(res => {
            const partnerIds = union(this.props.lead.quotesInfo.quotes.map(quote => quote.partner.id))
            if(partnerIds.length)
                return this.props.getPartners({ partnerId: partnerIds })
        })

        this.props.getPartnerCertifications(partnerId)

        this.props.getConversations({
            // Both clientId and leadId are needed for the api.
            clientId: this.props.client.id,
            leadId: this.props.leadId,
            partnerId: this.props.partnerId,
        })

        this.props.createPunchableAction(
            this.props.partnerId,
            this.props.leadId,
            CreditSystemActionResource.ActionEnum.OpenQuote
        )

        sendSegmentData(this.props.client, CLIENT_OPENED_QUOTE, {
            partnerId: this.props.partnerId,
            leadId: this.props.leadId,
        })
    }

    getPartner(partnerId) {
        this.props.getRatings(partnerId, 1, 3)
    }

    readConversationMessages() {
        if(this.props.hasNewMessage) {
            this.props.readConversationMessages(this.props.conversation.id)
                .then(res => {
                    this.props.getUnreadParticipantMessages({
                        unreadByParticipantId: this.props.client.id,
                    })
                })
        }
    }

    handleNoteSubmit(data, reset) {
        const {
            addMessageToConversation,
            createConversationWithParticipants,
            conversation,
            getConversation,
            match: {
                params: {
                    leadId,
                    partnerId,
                },
            },
        } = this.props

        new Promise(resolve => {
            if(conversation) {
                resolve(conversation.id)
                return
            }
            return createConversationWithParticipants([
                { type: 'lead', id: leadId },
                { type: 'partner', id: partnerId },
            ], {
                subject: 'CP',
            }).then(response => {
                resolve(response.result)
            })
        }).then(conversationId => {
            const files = data.file ? [ data.file ] : undefined
            addMessageToConversation(conversationId, data.content, files)
                .then(() => {
                    // Reset form
                    reset()
                    getConversation(conversationId)
                })
        }).then(() => {
            sendSegmentData(this.props.client, CLIENT_SENT_PARTNER_MESSAGE, {
                partnerId: partnerId,
                leadId: leadId
            })
        })
    }

    handleRevealPhoneNumberClick = () => {
        if (this.props.creditSystemIsLoading) {
            return
        }
        this.props.createPunchableAction(
            this.props.partner.id,
            this.props.lead.id,
            CreditSystemActionResource.ActionEnum.RevealPhoneNumber
        )
        this.setState({
            isPhoneNumbersRevealed: true,
        })

        sendSegmentData(this.props.client, CLIENT_VIEWED_PARTNERS_PHONE_NUMBER, {
            partnerId: this.props.partner.id,
            leadId: this.props.lead.id,
        })
    }

    handleRequestMeetingClick = (meetings) => {
        if (this.props.meetingsIsLoading) {
            return
        }

        this.setState({
            isRequestMeetingSent: true,
        })

        const meetingDates = [];
        meetings.forEach((meeting) => {
            if (meeting.date && meeting.hour) {
                meetingDates.push({
                    date: `${meeting.date} ${meeting.hour}:00`
                })
            }
        });

        this.props.createPunchableAction(
            this.props.partner.id,
            this.props.lead.id,
            CreditSystemActionResource.ActionEnum.RequestMeeting
        )
        this.props.createRequestMeetingAction(
            this.props.partner.id,
            this.props.lead.id,
            meetingDates,
            MeetingActionResource.ActionEnum.RequestMeeting
        )

        sendSegmentData(this.props.client, CLIENT_REQUESTED_MEETING, {
            partnerId: this.props.partner.id,
            leadId: this.props.lead.id,
        })
    }

    render() {
        const {
            conversationMessages,
            quotesIsLoading,
            certifications,
            partner,
            lead,
            quote,
            t,
        } = this.props

        if(quotesIsLoading || !quote || !lead || !partner) {
            return <LoadingAnimation />
        }

        const leadQuote = lead.quotesInfo.quotes
            .reduce((obj, value) => Object.assign(obj, { [value.id]: value }), {})

        const partnerUser = leadQuote[quote.id].partner.partnerUser

        const partnerUserName = partnerUser && partnerUser.name

        const companyIntroduction = Object.values(partner.attributes)
            .find(attribute => attribute && attribute.identifier === 'company_introduction')

        const acceptedQuote = quote.status === 'accepted'

        if (quote.items.length) {
            quote.items.forEach(item => {
                if (item.partnerProductComment) {
                    const partnerProductComment = {
                        hideInfo: true,
                        text: item.partnerProductComment.comment,
                        participant: {
                            name: partnerUserName,
                            type: 'partner',
                            picture: partnerUser && partnerUser.profilePicture,
                        },
                    }
                    conversationMessages.unshift(partnerProductComment)
                }
            })
        }

        const quoteMessage = {
            hideInfo: true,
            text: quote.description ?
                quote.description : companyIntroduction ?
                    companyIntroduction.selected[0].title :
                    t('quotes.quote.quote_conversation_text', {
                        leadName: lead.client.firstName + ' ' + lead.client.lastName,
                        partnerUserName: partnerUserName,
                    }),
            participant: {
                name: partnerUserName,
                type: 'partner',
                picture: partnerUser && partnerUser.profilePicture,
            },
        }
        conversationMessages.unshift(quoteMessage)

        return <QuotePage>
            <PageTitle identifier="quotes.quote_detail" pageId="quoteDetailPage"/>
            <MyMapComponent
                point={{
                    lat: partner.geo.point.lat,
                    lng: partner.geo.point.lon,
                }}
                containerElement={<BackgroundImage className="hidden-xs"/>}
                mapElement={<div style={{ height: '100%' }}/>}
            />
            <ContainerFluid>
                <PartnersPrimaryCard
                    acceptedQuote={acceptedQuote}
                    getPartner={partnerId => this.getPartner(partnerId)}
                    partnerUser={partnerUser}
                    partner={partner}
                    quote={quote}
                    lead={lead}
                    t={t}
                    handleRevealPhoneNumberClick={this.handleRevealPhoneNumberClick}
                    isPhoneNumbersRevealed={this.state.isPhoneNumbersRevealed}
                    creditSystemIsLoading={this.props.creditSystemIsLoading}
                    createPunchableAction={this.props.createPunchableAction}
                />
               {/*
                    TODO: need find out how to resolve this issue
                    https://stackoverflow.com/questions/42483647/remove-whitespace-before-after-float-element
                */}
                <Row className="hidden-xs">
                    <Col md={6}>
                        {Object.values(config.exclusivePartnersId).includes(partner.id) || quote.totalAmountExclVat.amount === 0 || <QuoteItemsInfoCard
                            quote={quote}
                            lead={lead}
                        />}
                        {config.exclusivePartnersId.meneto === partner.id && <MenetoPriceInfoCard
                            quote={quote}
                            t={t}
                        />}
                        {config.exclusivePartnersId.tellow === partner.id && <TellowPriceInfoCard
                            quote={quote}
                            t={t}
                        />}
                        {config.exclusivePartnersId.meneto === partner.id && <MenetoWorkInfoCard/>}
                        {config.exclusivePartnersId.tellow === partner.id && <TellowWorkInfoCard/>}
                        {<MeetingsCard
                            handleRequestMeetingClick={this.handleRequestMeetingClick}
                            isRequestMeetingSent={this.state.isRequestMeetingSent}
                            creditSystemIsLoading={this.props.creditSystemIsLoading}
                            meetingsIsLoading={this.state.meetingsIsLoading}
                            t={t}
                        />}
                        <AboutPartnerCard
                            partnerUser={partnerUser}
                            partner={partner}
                            t={t}
                            handleRevealPhoneNumberClick={this.handleRevealPhoneNumberClick}
                            isPhoneNumbersRevealed={this.state.isPhoneNumbersRevealed}
                            creditSystemIsLoading={this.props.creditSystemIsLoading}
                        />
                        <CertificationCard
                            certifications={certifications}
                            t={t}
                        />
                        <SystemsCard
                            partner={partner}
                            t={t}
                        />
                        <IndustrySpecialisationCard
                            partner={partner}
                            t={t}
                        />
                        <ServicesCard
                            partner={partner}
                            t={t}
                        />
                    </Col>

                    <Col md={6}>
                        <ChatCard
                            readConversationMessages={this.readConversationMessages.bind(this)}
                            handleNoteSubmit={this.handleNoteSubmit.bind(this)}
                            conversationMessages={conversationMessages}
                            partnerUserName={partnerUserName}
                            companyName={partner.companyName}
                            clientId={this.props.client.id}
                            t={t}
                        />
                        <InterviewQuoteCard
                            partnerUser={partnerUser}
                            partner={partner}
                            t={t}
                        />
                        <InterviewQuestionsCard
                            partnerUserName={partnerUserName}
                            partner={partner}
                            t={t}
                        />
                        <RatingOverviewCard
                            partnerId={partner.id}
                        />
                    </Col>
                </Row>
                <div className="visible-xs">
                    <br />
                    {Object.values(config.exclusivePartnersId).includes(partner.id) || quote.totalAmountExclVat.amount === 0 || <QuoteItemsInfoCard
                        quote={quote}
                        lead={lead}
                    />}
                    {config.exclusivePartnersId.meneto === partner.id && <MenetoPriceInfoCard
                        quote={quote}
                        t={t}
                    />}
                    {config.exclusivePartnersId.tellow === partner.id && <TellowPriceInfoCard
                        quote={quote}
                        t={t}
                    />}
                    <ChatCard
                        readConversationMessages={this.readConversationMessages.bind(this)}
                        handleNoteSubmit={this.handleNoteSubmit.bind(this)}
                        conversationMessages={conversationMessages}
                        partnerUserName={partnerUserName}
                        companyName={partner.companyName}
                        clientId={this.props.client.id}
                        t={t}
                    />
                    {config.exclusivePartnersId.meneto === partner.id && <MenetoWorkInfoCard/>}
                    {config.exclusivePartnersId.tellow === partner.id && <TellowWorkInfoCard/>}
                    <InterviewQuoteCard
                        partnerUser={partnerUser}
                        partner={partner}
                        t={t}
                    />
                    <AboutPartnerCard
                        partnerUser={partnerUser}
                        partner={partner}
                        t={t}
                    />
                    <InterviewQuestionsCard
                        partnerUserName={partnerUserName}
                        partner={partner}
                        t={t}
                    />
                    <RatingOverviewCard
                        partnerId={partner.id}
                    />
                    <CertificationCard
                        certifications={certifications}
                        t={t}
                    />
                    <SystemsCard
                        partner={partner}
                        t={t}
                    />
                    <IndustrySpecialisationCard
                        partner={partner}
                        t={t}
                    />
                    <ServicesCard
                        partner={partner}
                        t={t}
                    />
                </div>
            </ContainerFluid>
            {!acceptedQuote &&
            <CheckOutQuote
                partnerUserName={partnerUserName}
                lead={lead}
                quote={quote}
                partner={partner}
            />
            }
        </QuotePage>
    }
}
