import React, { Fragment } from 'react'
import Datetime from 'react-datetime'
import * as yup from 'yup'
import moment from 'moment'
import { Trans } from 'react-i18next'
import { Flex } from '../../elements/Flex'
import { Button } from '../../elements/ButtonNew'
import { Text } from '../../elements/text/Text'

const maxPauseLengthDays = 31

const validationErrors = {
    datesNull: 'partner_contracts.pauses.errors.invalid_dates',
    startInPast: 'partner_contracts.pauses.errors.starts_in_past',
    endDateNotGreater: 'partner_contracts.pauses.errors.end_date_not_greater',
    pauseTooLong: 'partner_contracts.pauses.errors.pause_too_long',
}

export default class PauseForm extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            startsAt: props.startsAt || null,
            endsAt: props.endsAt || null,
            errors: [],
        }
    }

    setDate = (side, date) => {
        this.setState({
            [side]: moment(date).format(),
        })
    }

    handleSubmit = async () => {
        const { startsAt, endsAt } = this.state
        const now = moment()

        await this.setState({ errors: [] })

        if (!startsAt || !endsAt) {
            await this.setState((prevState) => ({
                errors: [ ...prevState.errors, validationErrors.datesNull ],
            }))
        }

        if (moment(startsAt).startOf('day').isBefore(now.startOf('day'))) {
            await this.setState((prevState) => ({
                errors: [ ...prevState.errors, validationErrors.startInPast ],
            }))
        }

        if (!moment(startsAt).isBefore(endsAt)) {
            await this.setState((prevState) => ({
                errors: [ ...prevState.errors, validationErrors.endDateNotGreater ],
            }))
        }

        if (moment(endsAt).diff(moment(startsAt), 'days') > maxPauseLengthDays) {
            await this.setState((prevState) => ({
                errors: [ ...prevState.errors, validationErrors.pauseTooLong ],
            }))
        }

        if (this.state.errors.length > 0) {
            return
        }

        this.props.submit(startsAt, endsAt)
    }

    render() {
        const { startsAt, endsAt, errors } = this.state
        const { t } = this.props

        return <Fragment>
            <div className="alert alert-info text-center">{t('partner_contracts.pauses.pause_info')}</div>
            <Flex modifiers={[ 'justifySpaceBetween', 'alignItemsEnd' ]}>
                <Flex.Item modifiers={[ 'mR_2' ]}>
                    <Text modifiers={[ 'small', 'lightGrey', 'bold' ]}>{t('partner_contracts.pauses.pause_start_date')}</Text>
                    <Datetime
                        value={startsAt ? moment(startsAt).format('YYYY-MM-DD'): null}
                        onChange={e => this.setDate('startsAt', e._d)}
                        dateFormat="YYYY-MM-DD"
                        timeFormat={false}
                        inputProps={{ disabled: !(!this.props.startsAt || moment(this.props.startsAt).isAfter(moment())) || false }}
                    />
                </Flex.Item>
                <Flex.Item modifiers={[ 'mR_2' ]}>
                    <Text modifiers={[ 'small', 'lightGrey', 'bold' ]}>{t('partner_contracts.pauses.pause_end_date')}</Text>
                    <Datetime
                        value={endsAt ? moment(endsAt).format('YYYY-MM-DD') : null}
                        onChange={e => this.setDate('endsAt', moment(e._d).endOf('day').toISOString())}
                        dateFormat="YYYY-MM-DD"
                        timeFormat={false}
                    />
                </Flex.Item>
                <Button onClick={this.handleSubmit} modifiers={[ 'regular', 'small', 'bold', 'bottomLeft', 'action' ]}>
                    {this.props.startsAt
                        ? t('partner_contracts.pauses.pause_update_button_text')
                        : t('partner_contracts.pauses.pause_create_button_text')}
                </Button>
            </Flex>
            {Boolean(errors.length) && <div className="alert alert-danger" style={{ marginBottom: 0, marginTop: '16px' }}>
                <ul style={{ marginTop: 0 }}>{errors.map(error => <li key={error}>{
                    error === validationErrors.pauseTooLong
                        ? <Trans i18nKey={error} days={maxPauseLengthDays} />
                        : t(error)
                }</li>)}</ul>
            </div>}
        </Fragment>
    }
}
