import React, { useCallback } from 'react'
import config from '../../../../../config'
import styled from 'styled-components'
import { primaryColor } from '../../../../../theme/variables'
import { Card } from '../../../../../components/blocks/Card'
import PartnerTab from '../../../../../components/elements/PartnerTab'
import { Flex } from '../../../../../components/elements/Flex'
import Portrait from '../../../../../components/elements/Portrait'
import { OverflowText, SquareStar } from '../../../../../components/cards'
import { StarRatingInput } from '../../../../../components/utils/StarRatingInput'
import { Col } from 'react-bootstrap'
import { media } from '../../../../../components/utils/styledMedia'
import { Button } from '../../../../../components/elements/ButtonNew'
import { CreditSystemActionResource } from 'ageras-api-client/src/api'

const PartnerTabsHeader = styled(Flex)`
  background-color: ${primaryColor};
  font-weight: 300;
  color: #fff;
  opacity: 0.9;
  border: 0;
  ${media.max.landscapeMobile`display: none`};
`

const PartnerContent = styled(Card.Content)`
  display: grid;
  grid-template-columns: 180px auto;
  background-color: ${primaryColor};
  padding: 60px;
  grid-gap: 60px;
  color: #fff;
  h1 {
    color: #fff;
  }
  ${media.max.landscapeMobile`
    grid-template-columns: 80px auto;
    grid-gap: 30px;
    padding: 30px;
    h1 {
        font-size: 24px;
        margin-bottom: 10px
    }
    ${Portrait} {
      height: 80px;
      width: 80px;
      line-height: 80px;
      margin-bottom: 15px;
    }
    ${SquareStar} {
      font-size: 5px;
    }
  `};
`

const FontWeightSm = styled.div`
    font-weight: 300;
    ${media.min.landscapeMobile`font-weight: initial;`};
`

const RatingBlock = styled.div`
  > div {
    display: inline-block;
  }
  text-align: right;
  ${media.max.landscapeMobile`display: none`};
`

const PartnerCard = styled.div`
  ${media.min.landscapeMobile`
      padding-top: 80px;
      margin-bottom: 20px;
  `};
`

const TrigerLink = styled.a`
    display: block;
    text-decoration: underline;
`

const TabInfo = ({ partner }) =>
    <Flex>
        <Portrait
            modifiers={[ 'mR_2' ]}
            name={partner.partnerUser && partner.partnerUser.name}
            image={partner.partnerUser && partner.partnerUser.profilePicture}
            size={40}
        />
        <div>
            <OverflowText width="155px">
                <strong>{partner.partnerUser && partner.partnerUser.name}</strong>
            </OverflowText>
            <OverflowText width="155px">{partner.companyName}</OverflowText>
        </div>
    </Flex>

const PartnerContactInfo = ({
    partnerUser,
    className,
    geoCode,
    t,
    handleRevealPhoneNumberClick,
    isPhoneNumbersRevealed,
    creditSystemIsLoading,
}) => {
    const scrollToBookAMeeting = useCallback(() => document.getElementById('meeting-card').scrollIntoView({behavior: 'smooth', block: 'center'}), [])

    return (
        <Flex className={className} modifiers={['gaps']}>
            {!isPhoneNumbersRevealed && <Col md='auto'>
                <Button
                    className="hidden-xs"
                    modifiers={[ creditSystemIsLoading ? 'disabled' : 'action', 'p_2', 'btnBlock' ]}
                    disabled={creditSystemIsLoading}
                    onClick={handleRevealPhoneNumberClick}
                >
                    {t('partner.reveal_phone_number')}
                </Button>
            </Col>}
            {isPhoneNumbersRevealed && partnerUser.phone &&
            <Col md='auto'>
                <div><strong>{t('partner.phone')}</strong></div>
                <FontWeightSm className="hidden-xs">{partnerUser.phone}</FontWeightSm>
                <TrigerLink className="visible-xs" href={'tel:' + partnerUser.phone}>{partnerUser.phone}</TrigerLink>
            </Col>}
            {isPhoneNumbersRevealed && (config.hideMobileNumber(geoCode) || partnerUser.mobile) &&
            <Col md='auto'>
                <div><strong>{t('partner.mobile_phone')}</strong></div>
                <FontWeightSm className="hidden-xs">{partnerUser.mobile}</FontWeightSm>
                <TrigerLink className="visible-xs" href={'tel:' + partnerUser.mobile}>{partnerUser.mobile}</TrigerLink>
            </Col>}
            <Col md='auto'>
                <Button
                    className="hidden-xs"
                    modifiers={[ 'action', 'p_2', 'btnBlock' ]}
                    onClick={scrollToBookAMeeting}
                >
                    {t('partner.book_a_meeting')}
                </Button>
            </Col>
        </Flex>
    )
}


const MobilePartnerContactInfo = styled(PartnerContactInfo)`
  box-shadow: inset 0 3px 10px 0 rgba(0, 0, 0, 0.09);
  background-color: ${primaryColor};
  justify-content: space-between;
  padding: 15px 30px;
  color: #fff;
  ${media.min.landscapeMobile`display: none;`};
`

export const PartnersPrimaryCard = ({
    acceptedQuote,
    partnerUser = {},
    getPartner,
    partner,
    quote,
    lead,
    t,
    handleRevealPhoneNumberClick,
    isPhoneNumbersRevealed,
    creditSystemIsLoading,
    createPunchableAction,
}) => {
    const handleOpenQuoteClick = async (partnerId, leadId) => {
        await createPunchableAction(partnerId, leadId, CreditSystemActionResource.ActionEnum.OpenQuote)
        getPartner(partnerId)
    }

    return <PartnerCard>
        {!acceptedQuote && <PartnerTabsHeader modifiers={[ 'p_0' ]}>
            {lead.quotesInfo.quotes.map((quote, i) =>
                <PartnerTab
                    key={'quote_tab' + i}
                    to={`/cases/${lead.id}/${quote.partner.id}`}
                    responsiveModifiers={{ active: [ 'active' ] }}
                    size={quote.partner.id === partner.id ? 'active' : ''}
                    onClick={() => handleOpenQuoteClick(quote.partner.id, lead.id)}
                >
                    <TabInfo partner={quote.partner} />
                </PartnerTab>
            )}
        </PartnerTabsHeader>}
        <PartnerContent>
            <div>
                <Portrait
                    name={partnerUser.name}
                    image={partnerUser.profilePicture}
                    size={180}
                    square
                />
                {Boolean(partner.rating.stars) &&
                <StarRatingInput
                    filled={partner.rating.stars}
                    className="visible-xs"
                    total={5}
                />}
            </div>

            <Flex modifiers={[ 'directionColumn', 'justifySpaceBetween' ]}>
                <Flex modifiers={[ 'justifySpaceBetween' ]}>
                    <Col md='auto'>
                        <div className="hidden-xs">
                            <strong>{quote.partner.companyName}</strong> — <span>{quote.partner.city}</span>
                        </div>
                        {partnerUser &&
                        <h1>{partnerUser.name}</h1>
                        }
                        <div className="visible-xs">
                            <strong>{quote.partner.companyName}</strong> — <span>{quote.partner.city}</span>
                        </div>
                    </Col>
                    <Col md='auto' className='hidden-xs'>
                        {Boolean(partner.rating.stars) &&
                        <RatingBlock>
                            <StarRatingInput filled={partner.rating.stars} total={5} />
                            <p>{t('case_page.rating_text', {
                                score: partner.rating.score,
                                count: partner.rating.count,
                            })}</p>
                        </RatingBlock>}
                    </Col>
                </Flex>

                {partnerUser && <PartnerContactInfo
                    partnerUser={partnerUser}
                    geoCode={partner.geo.code}
                    className="hidden-xs"
                    t={t}
                    handleRevealPhoneNumberClick={handleRevealPhoneNumberClick}
                    isPhoneNumbersRevealed={isPhoneNumbersRevealed}
                    creditSystemIsLoading={creditSystemIsLoading}
                />}
            </Flex>
        </PartnerContent>
        {partnerUser &&
        <MobilePartnerContactInfo
            partnerUser={partnerUser}
            geoCode={partner.geo.code}
            t={t}
        />}
    </PartnerCard>
}
