import React from 'react'
import { FormGroup } from 'react-bootstrap'
import { parseRegExFloat } from '../../utils/parseFloat'
import { Error } from '../Error'
import MetaLabel from '../../elements/text/MetaLabel'
import FormControl from '../../elements/FormControl'

import i18n from '../../../i18n'

export const NumberInput = ({
     input: {
         value,
         onChange,
         name,
         ...inputProps
     },
     disabled,
     placeholder,
 }) => {
    setTimeout(() => {
        if(value !== parseRegExFloat(value)) {
            onChange(parseRegExFloat(value))
        }
    }, 0)

    return (
        <FormControl
            onChange={onChange}
            type="input"
            name={name}
            value={parseRegExFloat(value, i18n.language)}
            disabled={disabled}
            placeholder={placeholder}
            {...inputProps}
        />
    )
}

const NumberInputGroup = ({
   className,
   input,
   label,
   meta,
   ...props
}) =>
    <FormGroup controlId={input.name} bsClass={'form-group ' + className || ''}>
        {label && <MetaLabel>{label}</MetaLabel>}
        <NumberInput {...input} {...props}/>
        <Error meta={meta}/>
    </FormGroup>

export default NumberInputGroup
