import styled from 'styled-components'
import { applyStyleModifiers } from 'styled-components-modifiers'
const MODIFIER_SPLASH_CONFIG = {
    widthLg: () => `
        max-width: 960px;    
    `,
    widthSm: () => `
        max-width: 530px;    
    `,
    widthMd: () => `
        max-width: 724px;    
    `,
}
export default styled.div`
    max-width: 830px;
    margin: auto;
    ${applyStyleModifiers(MODIFIER_SPLASH_CONFIG)}
`
