import React from 'react'
import { Card } from '../../../../../components/blocks/Card/index'
import { Flex } from '../../../../../components/elements/Flex'
import styled from 'styled-components'
import FormattedAmount from '../../../../../components/utils/FormattedAmount'
import { primaryColor } from '../../../../../theme/variables'
import { Col, Row } from 'react-bootstrap'

const POINTS = [
    { title: 'Financiën altijd en overal inzichtelijk', icon: 'cellphone-android' },
    { title: 'Bespaar tijd en geld', icon: 'timer' },
    { title: 'Inclusief boekhoudadvies', icon: 'briefcase-check' },
    { title: 'Minimaliseer fouten', icon: 'finance' },
    { title: 'Intuïtief boekhoudprogramma', icon: 'transfer' },
]

const Icon = styled.i`
  color: ${primaryColor};
  font-size: 28px;
  margin-right: 25px;
`

const FormattedAmountText = styled.div`
  font-weight: 500;
  font-size: 22px;
  color: ${primaryColor}
`

export const TellowPriceInfoCard = ({ quote, t }) =>
    <Card>
        <Card.Content modifiers={[ 'pY_5', 'pX_6' ]}>
            <Card.Heading modifiers={[ 'primaryColor', 'mB_2' ]}>{t('quotes.quote.quote_title')}</Card.Heading>
            <Row>
                <Col sm={6}>
                    <Card.Text>
                        Alles-in-een oplossing voor je boekhouding voor een vaste maandelijkse prijs.
                    </Card.Text>
                </Col>
                <Col sm={6} className="text-right">
                    <Card.Text modifiers={[ 'primaryColor' ]}>
                        <FormattedAmountText>
                            <FormattedAmount
                                amount={quote.totalAmountExclVat.amount / 12}
                                currency={quote.totalAmountExclVat.currency}
                                decimals={2}
                            />/maand
                        </FormattedAmountText>
                    </Card.Text>
                    <Card.Meta>
                        Jaarlijks af te sluiten <br />
                        Geen opstartkosten <br />
                        Gratis overstap service
                    </Card.Meta>
                </Col>
            </Row>
            {POINTS.map(el =>
                <Card.ListItem block modifiers={[ 'pX_3', 'pY_2', 'mT_1' ]}>
                    <Flex modifiers={[ 'alignCenter' ]}>
                        <Icon className={`mdi mdi-${el.icon}`} />
                        <span>{el.title}</span>
                    </Flex>
                </Card.ListItem>
            )}
        </Card.Content>
    </Card>
