import moment from 'moment'
import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const GET_PROJECTS = 'projects/GET_PROJECTS'
export const GET_PROJECTS_SUCCESS = 'projects/GET_PROJECTS_SUCCESS'
export const GET_PROJECTS_FAIL = 'projects/GET_PROJECTS_FAIL'

export const GET_PROJECT = 'projects/GET_PROJECT'
export const GET_PROJECT_SUCCESS = 'projects/GET_PROJECT_SUCCESS'
export const GET_PROJECT_FAIL = 'projects/GET_PROJECT_FAIL'

export const GET_PROJECT_NOTES = 'projects/GET_PROJECT_NOTES'
export const GET_PROJECT_NOTES_SUCCESS = 'projects/GET_PROJECT_NOTES_SUCCESS'
export const GET_PROJECT_NOTES_FAIL = 'projects/GET_PROJECT_NOTES_FAIL'

export const ADD_NOTE_TO_PROJECT = 'projects/ADD_NOTE_TO_PROJECT'
export const ADD_NOTE_TO_PROJECT_SUCCESS = 'projects/ADD_NOTE_TO_PROJECT_SUCCESS'
export const ADD_NOTE_TO_PROJECT_FAIL = 'projects/ADD_NOTE_TO_PROJECT_FAIL'

export const GET_PROJECT_STATUSES = 'projects/GET_PROJECT_STATUSES'
export const GET_PROJECT_STATUSES_SUCCESS = 'projects/GET_PROJECT_STATUSES_SUCCESS'
export const GET_PROJECT_STATUSES_FAIL = 'projects/GET_PROJECT_STATUSES_FAIL'

export const GET_PROJECT_EVENTS = 'projects/GET_PROJECT_EVENTS'
export const GET_PROJECT_EVENTS_SUCCESS = 'projects/GET_PROJECT_EVENTS_SUCCESS'
export const GET_PROJECT_EVENTS_FAIL = 'projects/GET_PROJECT_EVENTS_FAIL'

export const GET_PROJECT_CONVERSATIONS = 'projects/GET_PROJECT_CONVERSATIONS'
export const GET_PROJECT_CONVERSATIONS_SUCCESS = 'projects/GET_PROJECT_CONVERSATIONS_SUCCESS'
export const GET_PROJECT_CONVERSATIONS_FAIL = 'projects/GET_PROJECT_CONVERSATIONS_FAIL'

export const ADD_MESSAGE_TO_CONVERSATION = 'projects/ADD_MESSAGE_TO_CONVERSATION'
export const ADD_MESSAGE_TO_CONVERSATION_SUCCESS = 'projects/ADD_MESSAGE_TO_CONVERSATION_SUCCESS'
export const ADD_MESSAGE_TO_CONVERSATION_FAIL = 'projects/ADD_MESSAGE_TO_CONVERSATION_FAIL'

export const CREATE_CONVERSATION = 'projects/CREATE_CONVERSATION'
export const CREATE_CONVERSATION_SUCCESS = 'projects/CREATE_CONVERSATION_SUCCESS'
export const CREATE_CONVERSATION_FAIL = 'projects/CREATE_CONVERSATION_FAIL'

export const CHANGE_TAB = 'projects/CHANGE_TAB'

export const getProjects = criteria => ({
    [CALL_API]: {
        types: [ GET_PROJECTS, GET_PROJECTS_SUCCESS, GET_PROJECTS_FAIL ],
        endpoint: '/projects',
        schema: schemas.projects,
        options: {
            body: {
                limit: 50,
                ...criteria,
            },
        },
    },
})

export const getProject = id => ({
    [CALL_API]: {
        types: [ GET_PROJECT, GET_PROJECT_SUCCESS, GET_PROJECT_FAIL ],
        endpoint: `/projects/${id}`,
        schema: schemas.project,
    },
})

export const updateProject = (id, body) => ({
    [CALL_API]: {
        types: [ GET_PROJECT, GET_PROJECT_SUCCESS, GET_PROJECT_FAIL ],
        endpoint: `/projects/${id}`,
        schema: schemas.project,
        options: {
            method: 'put',
            body,
        },
    },
})

export const addNoteToProject = (projectId, note) => ({
    [CALL_API]: {
        types: [ ADD_NOTE_TO_PROJECT, ADD_NOTE_TO_PROJECT_SUCCESS, ADD_NOTE_TO_PROJECT_FAIL ],
        endpoint: `/projects/${projectId}/notes`,
        schema: schemas.projectNote,
        options: {
            method: 'post',
            body: {
                content: note,
            },
        },
    },
    projectId,
})

export const getProjectNotes = (projectId, criteria) => ({
    [CALL_API]: {
        types: [ GET_PROJECT_NOTES, GET_PROJECT_NOTES_SUCCESS, GET_PROJECT_NOTES_FAIL ],
        endpoint: `/projects/${projectId}/notes?sort=-created_at`,
        schema: schemas.projectNotes,
    },
})

export const getProjectStatuses = () => ({
    [CALL_API]: {
        types: [ GET_PROJECT_STATUSES, GET_PROJECT_STATUSES_SUCCESS, GET_PROJECT_STATUSES_FAIL ],
        endpoint: '/projects/statuses',
        schema: schemas.projectStatuses,
    },
})

export const getProjectEvents = projectId => ({
    [CALL_API]: {
        types: [ GET_PROJECT_EVENTS, GET_PROJECT_EVENTS_SUCCESS, GET_PROJECT_EVENTS_FAIL ],
        endpoint: `/projects/${projectId}/events`,
        schema: schemas.projectEvents,
    },
})

export const addMessageToConversation = (conversationId, text, attachments) => ({
    [CALL_API]: {
        types: [ ADD_MESSAGE_TO_CONVERSATION, ADD_MESSAGE_TO_CONVERSATION_SUCCESS, ADD_MESSAGE_TO_CONVERSATION_FAIL ],
        endpoint: `/conversations/${conversationId}/messages`,
        schema: schemas.conversationMessage,
        options: {
            method: 'post',
            body: {
                text,
                attachments,
            },
        },
    },
    conversationId,
})
