import React from 'react'
import InvoiceItem from './InvoiceItem'
import { withTranslation } from 'react-i18next'

@withTranslation('', { wait: true })
export default class InvoiceList extends React.Component {
    render() {
        const {
            invoices, t,
        } = this.props

        return <table className="datatable responsive compact">
            <thead>
                <tr>
                    <th className="table-mobile-cell" />
                    <th>{ t('invoices.number') }</th>
                    <th>{ t('invoices.date') }</th>
                    <th>{ t('invoices.amount') }</th>
                    <th>{ t('invoices.due_date') }</th>
                    <th>{ t('invoices.status') }</th>
                </tr>
            </thead>
            <tbody>
                {invoices.map(invoice =>
                    <InvoiceItem key={invoice.id} invoice={invoice} />
                )}
                {!invoices.length && <tr>
                    <td colSpan="10">
                        { t('invoices.no_invoices') }
                    </td>
                </tr>}
            </tbody>
        </table>
    }
}
