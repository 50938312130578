import React from 'react'
import { withTranslation } from 'react-i18next'
import PageTitle from '../utils/PageTitle'

@withTranslation('', { wait: true })
export default class SubscriptionPlanPage extends React.Component {
    render() {
        const {
            t,
        } = this.props

        return (
            <div className="card">
                <PageTitle identifier="subscription.plan.header" />
                <h2>{t('subscription.plan.header')}</h2>
                <p className="intro-text">
                    {t('subscription.plan.intro')}
                </p>
            </div>
        )
    }
}
