import styled from 'styled-components'
import { breakpoints, colors } from '../../../theme/variables_new'
import { applyResponsiveStyleModifiers, applyStyleModifiers } from 'styled-components-modifiers'
import { MODIFIER_MARGIN_CONFIG, MODIFIER_PADDING_CONFIG } from '../../elements/modifiers/spaceModifiers'

const MODIFIER_BOX_CONFIG = {
    bottomRight: () => `
        border-bottom-right-radius: 0;
    `,
    bottomLeft: () => `
        border-bottom-left-radius: 0;
    `,
    topLeftSharp: () => `
        border-top-left-radius: 0;
    `,
    topRightSharp: () => `
        border-top-right-radius: 0;
    `,
    inline: () => `
        display: inline-block;
    `,
    overflowShow: () => `
        overflow: visible;
    `,
}

const Box = styled.div`
    position: relative;
    background-color: ${colors.white};
    border-radius: ${props => props.borderRadius || '8px'};
    overflow: hidden;
    box-shadow: 0px 9.29px 40px rgba(0, 0, 0, 0.1);
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)}
    ${applyStyleModifiers(MODIFIER_BOX_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_PADDING_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_BOX_CONFIG)}
`

const MODIFIER_HEADER_CONFIG = {
    accent: () => `
        background-color: ${colors.blue};
        color: ${colors.white}
        padding: 16px;

        @media (min-width: ${breakpoints.small}) {
            padding: 10px 32px;
        }
    `,
    success: () => `
        background-color: ${colors.green};
        color: ${colors.white}
        padding: 16px;

        @media (min-width: ${breakpoints.small}) {
            padding: 10px 32px;
        }
    `,
    faded: () => `
        background-color: ${colors.grey};
        color: ${colors.white}
        padding: 16px;

        @media (min-width: ${breakpoints.small}) {
            padding: 10px 32px;
        }
    `,
    base: () => `
        padding: 16px;

        @media (min-width: ${breakpoints.small}) {
            padding: 10px 32px;
        }
    `,
    tabs: () => `
        padding: 0 20px;
    `,
}

const Header = styled.div`
    position: relative;
    ${applyStyleModifiers(MODIFIER_HEADER_CONFIG)}
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_HEADER_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_PADDING_CONFIG)}
`

const MODIFIER_CONTENT_CONFIG = {
    overview: () => `
        padding: 24px 16px 18px;

        @media (min-width: ${breakpoints.small}) {
            padding: 25px 32px 35px;
        }
    `,
    inline: () => `
        display: inline-block;
    `,
}

const Content = styled.div`
    position: relative;
    ${applyStyleModifiers(MODIFIER_CONTENT_CONFIG)}
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_CONTENT_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_PADDING_CONFIG)}
`

const MODIFIER_LINE_CONFIG = {
    primary: () => `
        background-color: ${colors.darkBlue};
    `,
    grey: () => `
        background-color: ${colors.grey};
    `,
}

const Line = styled.div`
    position: relative;
    width: 100%;
    height: 1px;
    background-color: ${colors.blue};
    opacity: ${colors.opacity.light};
    ${applyStyleModifiers(MODIFIER_LINE_CONFIG)}
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_PADDING_CONFIG)}
`

const MODIFIER_FLOAT_CONFIG = {
    topRight: () => `
        top: -27px;
        right: -22px;
    `,
    regular: () => `
        padding: 6px 14px;
    `,
    radiusBottomLeft: () => `
        border-bottom-left-radius: 0;
    `,
}

const Float = styled.div`
    display: inline-block;
    background-color: ${colors.blue};
    color: ${colors.lightBlue};
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    position: absolute;
    border-radius: ${props => props.borderRadius || '8px'};
    ${applyStyleModifiers(MODIFIER_FLOAT_CONFIG)}
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_PADDING_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_FLOAT_CONFIG)}
`

Box.Header = Header
Box.Content = Content
Box.Line = Line
Box.Float = Float

export default Box
