import { CALL_API } from '../middleware/api'
import config from '../config'

export const GET_STRIPE_PUBLIC_KEY = 'cashier/GET_STRIPE_PUBLIC_KEY'
export const GET_STRIPE_PUBLIC_KEY_SUCCESS = 'cashier/GET_STRIPE_PUBLIC_KEY_SUCCESS'
export const GET_STRIPE_PUBLIC_KEYFAIL = 'cashier/GET_STRIPE_PUBLIC_KEYFAIL'

export const CREATE_SETUP_INTENT = 'cashier/CREATE_SETUP_INTENT'
export const CREATE_SETUP_INTENT_SUCCESS = 'cashier/CREATE_SETUP_INTENT_SUCCESS'
export const CREATE_SETUP_INTENT_FAIL = 'cashier/CREATE_SETUP_INTENT_FAIL'

export const UPDATE_PAYMENT_METHOD = 'cashier/UPDATE_PAYMENT_METHOD'
export const UPDATE_PAYMENT_METHOD_SUCCESS = 'cashier/UPDATE_PAYMENT_METHOD_SUCCESS'
export const UPDATE_PAYMENT_METHOD_FAIL = 'cashier/UPDATE_PAYMENT_METHOD_FAIL'

export const CREATE_SUBSCRIPTION = 'cashier/CREATE_SUBSCRIPTION'
export const CREATE_SUBSCRIPTION_SUCCESS = 'cashier/CREATE_SUBSCRIPTION_SUCCESS'
export const CREATE_SUBSCRIPTION_FAIL = 'cashier/CREATE_SUBSCRIPTION_FAIL'

export const CANCEL_SUBSCRIPTION = 'cashier/CANCEL_SUBSCRIPTION'
export const CANCEL_SUBSCRIPTION_SUCCESS = 'cashier/CANCEL_SUBSCRIPTION_SUCCESS'
export const CANCEL_SUBSCRIPTION_FAIL = 'cashier/CANCEL_SUBSCRIPTION_FAIL'

export const RESUME_SUBSCRIPTION = 'cashier/RESUME_SUBSCRIPTION'
export const RESUME_SUBSCRIPTION_SUCCESS = 'cashier/RESUME_SUBSCRIPTION_SUCCESS'
export const RESUME_SUBSCRIPTION_FAIL = 'cashier/RESUME_SUBSCRIPTION_FAIL'

export const MIGRATE_PARTNER = 'cashier/MIGRATE_PARTNER'
export const MIGRATE_PARTNER_SUCCESS = 'cashier/MIGRATE_PARTNER_SUCCESS'
export const MIGRATE_PARTNER_FAIL = 'cashier/MIGRATE_PARTNER_FAIL'


export const getStripePublicKey = geoCode => ({
    [CALL_API]: {
        types: [ GET_STRIPE_PUBLIC_KEY, GET_STRIPE_PUBLIC_KEY_SUCCESS, GET_STRIPE_PUBLIC_KEYFAIL ],
        endpoint: `${config.agerasServices}/cashier/get-stripe-public-key`,
        options: {
            body: {
                geoCode,
            },
        },
    },
})

export const createStripeSetupIntent = (partner) => ({
    [CALL_API]: {
        types: [ CREATE_SETUP_INTENT, CREATE_SETUP_INTENT_SUCCESS, CREATE_SETUP_INTENT_FAIL ],
        endpoint: `${config.agerasServices}/cashier/create-setup-intent`,
        options: {
            method: 'post',
            body: {
                partnerId: partner.id,
            },
        },
    },
})

export const updatePaymentMethod = (partner, setupIntentId) => ({
    [CALL_API]: {
        types: [ UPDATE_PAYMENT_METHOD, UPDATE_PAYMENT_METHOD_SUCCESS, UPDATE_PAYMENT_METHOD_FAIL ],
        endpoint: `${config.agerasServices}/cashier/update-payment-method`,
        options: {
            method: 'post',
            body: {
                partnerId: partner.id,
                setupIntentId
            },
        },
    },
})

export const createSubscription = (partner, productId, promotionCode) => ({
    [CALL_API]: {
        types: [ CREATE_SUBSCRIPTION, CREATE_SUBSCRIPTION_SUCCESS, CREATE_SUBSCRIPTION_FAIL ],
        endpoint: `${config.agerasServices}/cashier/create-subscription`,
        options: {
            method: 'post',
            body: {
                partnerId: partner.id,
                productId,
                promotionCode,
            },
        },
    },
})

export const cancelSubscription = (subscription) => ({
    [CALL_API]: {
        types: [ CANCEL_SUBSCRIPTION, CANCEL_SUBSCRIPTION_SUCCESS, CANCEL_SUBSCRIPTION_FAIL ],
        endpoint: `${config.agerasServices}/cashier/cancel-subscription`,
        options: {
            method: 'post',
            body: {
                subscriptionId: subscription.id,
            },
        },
    },
})

export const resumeSubscription = (subscription) => ({
    [CALL_API]: {
        types: [ RESUME_SUBSCRIPTION, RESUME_SUBSCRIPTION_SUCCESS, RESUME_SUBSCRIPTION_FAIL ],
        endpoint: `${config.agerasServices}/cashier/resume-subscription`,
        options: {
            method: 'post',
            body: {
                subscriptionId: subscription.id,
            },
        },
    },
})

export const migratePartner = (partner) => ({
    [CALL_API]: {
        types: [ MIGRATE_PARTNER, MIGRATE_PARTNER_SUCCESS, MIGRATE_PARTNER_FAIL ],
        endpoint: `${config.agerasServices}/cashier/migrate-partner`,
        options: {
            method: 'post',
            body: {
                partnerId: partner.id,
            },
        },
    },
})
