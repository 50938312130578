import React from 'react'
import { Trans, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { currentPartnerSelector, currentPartnerUserSelector } from '../../selectors/auth'
import PageTitle from '../utils/PageTitle'
import { Card, Content, Separator } from '../cards/index'
import Portrait from '../projects/Portrait'
import ReactMarkdown from 'react-markdown'

@connect(
    state => ({
        partner: currentPartnerSelector(state),
        user: currentPartnerUserSelector(state),
    })
)
@withTranslation('', { wait: true })
export default class QuoteIntroductionPage extends React.Component {

    render() {
        const { t, partner, user } = this.props

        return <div id="demo-page">
            <PageTitle identifier="demo.title" />
            <div className="page-splash" style={{ maxWidth: '760px' }}>
                <h1 className="intro-heading">
                    {t('content.quote_intro.title.line_1')} <br />
                    {t('content.quote_intro.title.line_2')}
                </h1>
                <p className="intro-text" style={{ margin: '30px 90px' }}>
                    {t('content.quote_intro.sub_title.line_1')}<br/>
                    {t('content.quote_intro.sub_title.line_2')}
                </p>
            </div>
            <div className="separated-page-section">
                <div className="page-separator">
                    <img src="/images/separator-askew.svg" alt=""/>
                </div>
                <div className="container-s-fluid">
                    <iframe width="100%" height="428" style={{ borderWidth: 0 }}
                            src="https://www.youtube.com/embed/uJaPe0stcxk?rel=0&showinfo=0" />
                    <Separator/>
                    <Card>
                        <Content>
                            <div>
                                <Portrait
                                    style={{ float: 'left', marginRight: '20px' }}
                                    name={partner.employee.name}
                                    image={partner.employee.profilePicture}
                                    size={40}
                                />
                                <Trans i18nKey="content.quote_intro.connect_info_text"
                                             employeeName={partner.employee.name}
                                             employeePhone={partner.employee.phoneWork}
                                />
                            </div>
                        </Content>
                    </Card>
                    <Separator/>
                    <Card>
                        <Content>
                            <div className="react-markdown">
                                <ReactMarkdown>
                                    {t('content.quote_intro.info_text', {
                                        userFirstName: user.firstName,
                                        userLastName: user.lastName,
                                        employeeName: partner.employee.name,
                                        employeePhone: partner.employee.phoneWork,
                                        employeeEmail: partner.employee.email,
                                    })}
                                </ReactMarkdown>

                                <br />
                                <div style={{ width: '150px' }}>
                                    <img src="/images/rico_signature.png" alt="" />
                                </div>
                            </div>
                        </Content>
                    </Card>
                    <Separator/>
                    <Card className="hide">
                        <Content>
                            <div className="row">
                                <div className="col-md-10 col-md-offset-1">
                                    <Portrait
                                        style={{ float: 'left', marginRight: '20px' }}
                                        name={partner.employee.name}
                                        image={partner.employee.profilePicture}
                                        size={40}
                                    />
                                    <Trans i18nKey="content.quote_intro.connect_info_text"
                                                 employeeName={partner.employee.name}
                                                 employeePhone={partner.employee.phoneWork}
                                    />
                                </div>
                            </div>
                        </Content>
                    </Card>

                    <div className="partners-section">
                        {t('demo.testimonial_header')}
                    </div>
                </div>
            </div>
        </div>
    }
}
