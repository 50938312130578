import { Modal } from 'react-bootstrap'
import { Elements } from '@stripe/react-stripe-js'
import LoadingAnimation from '../utils/LoadingAnimation';
import PaymentMethodForm from './PaymentMethodForm';
import usePrepareStripeElements from './usePrepareStripeElements';
import { useEffect } from 'react';
import { StripeRedirectUpdatePaymentMethod } from './constants';
import { useTranslation } from 'react-i18next';

export default function PaymentMethodModal ({
    partner,
    show,
    toggle,
}){
    const { isLoading, stripePromise, stripeClientSecret, options, prepareCardElements } = usePrepareStripeElements(partner);
    const { t } = useTranslation();

    useEffect(() => {
        if(show && !stripeClientSecret && !isLoading){
            prepareCardElements();
        }
    }, [show, stripeClientSecret, isLoading, prepareCardElements]);

    return (
        <Modal show={show}
            animation={true}
            onHide={() => toggle(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {t('stripe_subscription_page.payment_method.title')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading && (
                    <LoadingAnimation />
                )}
                {stripeClientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                        <PaymentMethodForm stripeClientSecret={stripeClientSecret} buttonText={t('stripe_subscription_page.payment_method.update')} redirectId={StripeRedirectUpdatePaymentMethod}/>
                    </Elements>
                )}
            </Modal.Body>
        </Modal>
    );
}
