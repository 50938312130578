import styled from 'styled-components'
import { primaryColor, linkColor } from '../../theme/variables'
import { Link } from 'react-router-dom'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { Button } from './ButtonNew'

const MODIFIER_LINK_CONFIG = {
    underline: () => `
        text-decoration: underline;
    `,
    linkBlock: () => `
        display: block
    `,
}

export const PrimaryBlankLink = styled.a.attrs({ target: '_blank' })`
    display: block;
    margin-top: 10px;
    text-decoration: underline;
    color: ${primaryColor};
`

export const BtnHref = Button.withComponent(styled.a``)

export const PrimaryLink = styled(Link)`
    color: ${primaryColor};
    ${applyStyleModifiers(MODIFIER_LINK_CONFIG)}
`

export const BtnLink = Button.withComponent(Link)

export const RegularLink = styled(Link)`
    color: ${linkColor};
    text-decoration: underline;
`
