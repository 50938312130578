import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const GET_INVOICES = 'invoices/GET_INVOICES'
export const GET_INVOICES_SUCCESS = 'invoices/GET_INVOICES_SUCCESS'
export const GET_INVOICES_FAIL = 'invoices/GET_INVOICES_FAIL'
export const GET_DUE_INVOICES = 'invoices/GET_DUE_INVOICES'
export const GET_DUE_INVOICES_SUCCESS = 'invoices/GET_DUE_INVOICES_SUCCESS'
export const GET_DUE_INVOICES_FAIL = 'invoices/GET_DUE_INVOICES_FAIL'

export const CREATE_INVOICE_REFUND_REQUEST = 'invoices/CREATE_INVOICE_REFUND_REQUEST'
export const CREATE_INVOICE_REFUND_REQUEST_SUCCESS = 'invoices/CREATE_INVOICE_REFUND_REQUEST_SUCCESS'
export const CREATE_INVOICE_REFUND_REQUEST_FAIL = 'invoices/CREATE_INVOICE_REFUND_REQUEST_FAIL'

export const GET_INVOICE_REFUND_REQUESTS = 'invoices/GET_INVOICE_REFUND_REQUESTS'
export const GET_INVOICE_REFUND_REQUESTS_SUCCESS = 'invoices/GET_INVOICE_REFUND_REQUESTS_SUCCESS'
export const GET_INVOICE_REFUND_REQUESTS_FAIL = 'invoices/GET_INVOICE_REFUND_REQUESTS_FAIL'

export const GET_DUE_NOT_PAID_INVOICES = 'invoices/GET_DUE_NOT_PAID_INVOICES'
export const GET_DUE_NOT_PAID_INVOICES_SUCCESS = 'invoices/GET_DUE_NOT_PAID_INVOICES_SUCCESS'
export const GET_DUE_NOT_PAID_INVOICES_FAIL = 'invoices/GET_DUE_NOT_PAID_INVOICES_FAIL'

export const getInvoices = (criteria, page = 1) => ({
    [CALL_API]: {
        types: [ GET_INVOICES, GET_INVOICES_SUCCESS, GET_INVOICES_FAIL ],
        endpoint: '/invoices',
        schema: schemas.invoices,
        options: {
            body: {
                ...criteria,
                limit: 1000,
                sort: '-created_at',
                page,
            },
        },
    },
})

export const getDueNotPaidInvoices = partnerId => ({
    [CALL_API]: {
        types: [ GET_DUE_NOT_PAID_INVOICES, GET_DUE_NOT_PAID_INVOICES_SUCCESS, GET_DUE_NOT_PAID_INVOICES_FAIL ],
        endpoint: '/invoices',
        schema: schemas.invoices,
        options: {
            body: {
                isOverdue: 'true',
                isPaid: 'false',
                limit: 1000,
                sort: '-created_at',
                partnerId,
            },
        },
    },
})

export const getDueInvoices = partnerId => ({
    [CALL_API]: {
        types: [ GET_DUE_INVOICES, GET_DUE_INVOICES_SUCCESS, GET_DUE_INVOICES_FAIL ],
        endpoint: '/invoices',
        schema: schemas.invoices,
        options: {
            body: {
                per_page: 1000,
                limit: 1000,
                partner_id: partnerId,
                // sort: '-due_at',
                is_paid: 'false',
            },
        },
    },
})

export const getInvoicesRefundRequestList = (partnerId, page = 1, append) => ({
    [CALL_API]: {
        types: [ GET_INVOICE_REFUND_REQUESTS, GET_INVOICE_REFUND_REQUESTS_SUCCESS, GET_INVOICE_REFUND_REQUESTS_FAIL ],
        endpoint: '/invoices/refundrequests',
        schema: schemas.invoiceRefundRequests,
        options: {
            body: {
                partnerId,
                limit: 30,
                page,
            },
        },
    },
    append,
})

export const createInvoiceRefundRequest = body => ({
    [CALL_API]: {
        types: [ CREATE_INVOICE_REFUND_REQUEST, CREATE_INVOICE_REFUND_REQUEST_SUCCESS, CREATE_INVOICE_REFUND_REQUEST_FAIL ],
        endpoint: `/invoices/${body.invoice.id}/refundrequests`,
        options: {
            method: 'post',
            body,
        },
    },
})
