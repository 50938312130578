import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'
import config from '../config'

export const GET_CONVERSION = 'projects/GET_CONVERSION'
export const GET_CONVERSION_SUCCESS = 'projects/GET_CONVERSION_SUCCESS'
export const GET_CONVERSION_FAIL = 'projects/GET_CONVERSION_FAIL'

export const getConversion = partnerId => ({
    [CALL_API]: {
        types: [ GET_CONVERSION, GET_CONVERSION_SUCCESS, GET_CONVERSION_FAIL ],
        endpoint: `${config.agerasServices}/conversion/${partnerId}`,
    },
})
