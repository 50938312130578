import React from 'react'
import { Card } from '../../blocks/Card'
import SwitchCheckBoxGroup from '../../forms/inputs/SwitchCheckBoxGroup'
import { Field } from 'redux-form'

export const CompanySystemCertificationContent = ({ t, fields }) => {
    if(!fields) return null

    const dataFields = fields.getAll()

    return <div className="partner-company-certifications">
        {fields.map((member, index) => dataFields[index].identifier === 'system_certification' && [
            <Card.Line />,
            <Card.Content>
                <Card.Title>{t('company_profile.system_certification.title')}</Card.Title>
                <Card.Intro>{t('company_profile.system_certification.intro')}</Card.Intro>
                <Field
                    name={`${member}.selected`}
                    component={SwitchCheckBoxGroup}
                    options={dataFields[index].values}
                />
            </Card.Content>,
            ]
        )}
    </div>
}

