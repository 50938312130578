import { createSelector } from 'reselect'
import { camelize } from 'humps'

const leadSelector = (state, id) => state.entities.leads[id]

export const currentPartnerUserSelector = createSelector(
    state => state.auth.partnerUserId,
    state => state.entities.partnerUsers,
    (partnerUserId, partnerUsers) => {
        return partnerUsers[partnerUserId]
    }
)

export const currentPartnerSelector = createSelector(
    state => state.entities.partners,
    currentPartnerUserSelector,
    (partners, partnerUser) => {
        if(!partnerUser) {
            return null
        }
        return partners[partnerUser.partnerId]
    }
)

export const currentAuthenticatedUserSelector = createSelector(
    state => state.auth,
    auth => ({
        id: auth[`${camelize(auth.userType)}Id`],
        type: auth.userType,
    })
)

export const currentClientSelector = createSelector(
    state => state.auth.clientId,
    state => state.entities.clients,
    (clientId, clients) => clients[clientId]
)

export const getLeadQuoteByPartnerIdSelector = createSelector(
    leadSelector,
    currentPartnerSelector,
    (lead, partner) => (lead && partner &&
        lead.quotesInfo.quotes.find(q => q.partner.id === partner.id)) || {}
)

export const visibleClientName = createSelector(
    currentClientSelector,
    client => {
        switch (client.geo.code) {
        case 'dk':
        case 'se':
        case 'no':
            return client.firstName
        }
        return client.firstName + ' ' + client.lastName
    }
)

export const isFinalizingSignupSelector = createSelector(
    state => state.router,
    router => {
        const pathname = router.location.pathname
        const firstSection = pathname.split('/')[1]

        return firstSection === 'signup'
    }
)
