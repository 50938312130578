import { Link } from 'react-router'

import {
    GET_SUBSCRIPTION_PACKAGES, GET_SUBSCRIPTION_PACKAGES_SUCCESS, GET_SUBSCRIPTION_PACKAGES_FAIL,
} from '../../actions/packages'
import {
    ADD_EPAY_CARD_SUCCESS,
} from '../../actions/billing'

const initialState = {
    isLoading: false,
    selectedCard: null,
    subscriptionPackageIds: [],
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    case ADD_EPAY_CARD_SUCCESS:
        return {
            ...state,
            selectedCard: action.response.result,
        }
    default:
        return state
    }
}
