import React from 'react'
import { Link } from 'react-router-dom'
import { DotIndicator } from '../../components/cards'
import { cardBorderColor, metaTextColor } from '../../theme/variables'
import styled from 'styled-components'
import { Flex } from '../../components/elements/Flex'

const MobileBreadcrumb = styled.nav`
    padding: 12px 0;
    width: 100%;
    position: fixed;
    z-index: 999;
    background: #fff;
    border-top: 1px solid ${cardBorderColor};
    bottom: 0;
    left: 0;
    color: ${metaTextColor};
    font-size: 14px;
    i {
        font-size: 19px;
    }
`

export const StickyMobileNavigation = ({
    mobileNavigationTabs,
    hasNewMessage,
}) =>
    <MobileBreadcrumb className="visible-xs visible-sm">
        <Flex modifiers={[ 'justifySpaceAround' ]}>
            {mobileNavigationTabs.map((el, index) =>
                <Link
                    to={el.link}
                    onClick={() => window.scrollTo(0, 0)}
                    className={el.active ? 'text-active' : ''}
                    key={index}
                >
                    <div className="text-center">
                        <DotIndicator
                            right={'-3px'}
                            padding={'5px'}
                            showBadge={[ '/messages' ].includes(el.link) ? hasNewMessage : ''}
                        >
                            <i className={el.icon} />
                        </DotIndicator>
                        {el.name && <><br /> <span>{el.name}</span></>}
                    </div>
                </Link>
            )}
        </Flex>
    </MobileBreadcrumb>
