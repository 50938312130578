import { camelize } from 'humps'

import { SHOW_LOADING, SHOW_STATUS, HIDE_LOADING } from '../actions/loader'

const initialState = {
    state: 'hidden',
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    case SHOW_LOADING:
        return {
            ...state,
            state: 'loading',
        }
    case SHOW_STATUS:
        return {
            ...state,
            state: 'show_status',
            status: action.status,
            message: action.message,
        }
    case HIDE_LOADING:
        return {
            ...state,
            state: 'hidden',
            status: '',
        }
    default:
        return state
    }
}
