const initialState = {
    title: '',
    pageId: '',
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    case 'CHANGE_PAGE_TITLE':
        return {
            ...state,
            title: action.pageTitle,
            backLinkName: action.backLinkName,
            pageId: action.pageId,
        }
    default:
        return state
    }
}
