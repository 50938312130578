import { secondaryColorTwo } from '../../../theme/variables'
import styled from 'styled-components'

export const TriangleWithDot = styled.div`
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 11px 11px 11px 0;
    display: inline-block;
    border-color: transparent ${({ color }) => color || secondaryColorTwo} transparent transparent;
    position: relative;
    &:after {
        content: '•';
        color: #fff;
        position: absolute;
        bottom: -17px;
        right: -20px;
        font-size: 24px;
        line-height: 38px;
    }
`
