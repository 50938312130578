import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import * as refunds from './../../actions/refunds'
import { currentPartnerSelector, currentPartnerUserSelector } from '../../selectors/auth'
import RefundRequestForm from '../refunds/RefundRequestForm'
import { push } from 'connected-react-router'
import { toastr } from 'react-redux-toastr'
import { createInvoiceRefundRequest } from '../../actions/invoices'
import { destroy } from 'redux-form'

@connect(
    state => ({
        partner: currentPartnerSelector(state),
        partnerUser: currentPartnerUserSelector(state),
    }),
    {
        createInvoiceRefundRequest,
        push,
        destroy,
    }
)
@withTranslation('', { wait: true })
export default class RefundsCreateRequestPage extends Component {

    saveRefundRequest(data) {
        const { partner, partnerUser, t } = this.props

        const body = {
            ...data,
            partner: { id: partner.id },
            partner_user: { id: partnerUser.id },
            documentation: data.documentation ? data.documentation.content : null,
        }
        delete body.request
        delete body.acceptTerms
        delete body.noDocumentation

        this.props.createInvoiceRefundRequest(body)
            .then(success => {
                this.props.destroy('wizard')
                this.props.push('/account/refunds/')
            }, action => {
                toastr.error(t('refunds.requests.error'), action.error)
            })
    }

    render() {
        const {
            t,
        } = this.props
        return (
            <div className="card">
                <h2>{t('refunds.request.header')}</h2>
                <RefundRequestForm onSubmit={this.saveRefundRequest.bind(this)} />
            </div>
        )
    }
}
