import { applyResponsiveStyleModifiers, applyStyleModifiers } from 'styled-components-modifiers'
import styled from 'styled-components'
import { MODIFIER_MARGIN_CONFIG, MODIFIER_PADDING_CONFIG } from './modifiers/spaceModifiers'
import { breakpoints } from '../../theme/variables_new'

const MODIFIER_FLEX_CONFIG = {
    positionRelative: () => `
        position: relative;
    `,
    justifyCenter: () => `
        justify-content: center;
    `,
    justifyEnd: () => `
        justify-content: flex-end ;
    `,
    justifySpaceBetween: () => `
        justify-content: space-between;
    `,
    justifySpaceAround: () => `
        justify-content: space-around;
    `,
    alignCenter: () => `
        align-items: center;
    `,
    alignStretch: () => `
        align-items: stretch;
    `,
    alignSelfCenter: () => `
        align-self: center;
    `,
    alignSelfEnd: () => `
        align-self: end;
    `,
    alignItemsEnd: () => `
        align-items: flex-end;
    `,
    directionColumn: () => `
        flex-direction: column;
    `,
    fullWidth: () => `
        width: 100%;
    `,
    fullHeight: () => `
        height: 100%;
    `,
    overflowX: () => `
        flex-wrap: nowrap;
        overflow-x: auto;

        & > * {
            min-width: max-content;
        }
    `,
    flexWrap: () => `
        flex-wrap: wrap;
    `,
    hidden: () => `
        display: none;
    `,
    gaps: () => `
        gap: 2em;
    `,
}

const MODIFIER_FLEX_ITEM_CONFIG = {
    grow2: () => `
        flex-grow: 2;
    `,
    minimum: () => `
        flex: unset;
    `,
    growReviews: () => `
        flex-grow: 1.68;
    `,
    first: () => `
        order: -1
    `,
}

const Item = styled.div`
    flex: 1;
    position: relative;
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)}
    ${applyStyleModifiers(MODIFIER_FLEX_CONFIG)}
    ${applyStyleModifiers(MODIFIER_FLEX_ITEM_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_FLEX_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_FLEX_ITEM_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_PADDING_CONFIG)}
`

export const Flex = styled.div`
    display: flex;
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)}
    ${applyStyleModifiers(MODIFIER_FLEX_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_FLEX_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyResponsiveStyleModifiers(MODIFIER_PADDING_CONFIG)}
`

Flex.Item = Item
