import * as schemas from '../schemas'
import { CALL_API } from '../middleware/api'

export const CREATE_SUBSCRIPTION = 'packages/CREATE_SUBSCRIPTION'
export const CREATE_SUBSCRIPTION_SUCCESS = 'packages/CREATE_SUBSCRIPTION_SUCCESS'
export const CREATE_SUBSCRIPTION_FAIL = 'packages/CREATE_SUBSCRIPTION_FAIL'

export const UPDATE_SUBSCRIPTION = 'packages/UPDATE_SUBSCRIPTION'
export const UPDATE_SUBSCRIPTION_SUCCESS = 'packages/UPDATE_SUBSCRIPTION_SUCCESS'
export const UPDATE_SUBSCRIPTION_FAIL = 'packages/UPDATE_SUBSCRIPTION_FAIL'

export const GET_SUBSCRIPTION_TYPES = 'packages/GET_SUBSCRIPTION_TYPES'
export const GET_SUBSCRIPTION_TYPES_SUCCESS = 'packages/GET_SUBSCRIPTION_TYPES_SUCCESS'
export const GET_SUBSCRIPTION_TYPES_FAIL = 'packages/GET_SUBSCRIPTION_TYPES_FAIL'

export const GET_PARTNER_SUBSCRIPTIONS = 'packages/GET_PARTNER_SUBSCRIPTIONS'
export const GET_PARTNER_SUBSCRIPTIONS_SUCCESS = 'packages/GET_PARTNER_SUBSCRIPTIONS_SUCCESS'
export const GET_PARTNER_SUBSCRIPTIONS_FAIL = 'packages/GET_PARTNER_SUBSCRIPTIONS_FAIL'

export const CANCEL_SUBSCRIPTION = 'packages/CANCEL_SUBSCRIPTION'
export const CANCEL_SUBSCRIPTION_SUCCESS = 'packages/CANCEL_SUBSCRIPTION_SUCCESS'
export const CANCEL_SUBSCRIPTION_FAIL = 'packages/CANCEL_SUBSCRIPTION_FAIL'

export const createSubscription = (partnerId, subscriptionPackageId, cardId, price) => dispatch => dispatch({
    [CALL_API]: {
        types: [ CREATE_SUBSCRIPTION, CREATE_SUBSCRIPTION_SUCCESS, CREATE_SUBSCRIPTION_FAIL ],
        endpoint: `/partners/${partnerId}/subscriptions`,
        schema: schemas.subscription,
        options: {
            method: 'post',
            body: {
                partner_id: partnerId,
                package_id: subscriptionPackageId,
                monthly_price: {
                    amount: price,
                },
                payment_solution: {
                    name: 'card',
                    payment_card_id: cardId,
                },
            },
        },
    },
})

export const updatePartnerSubscriptions = (partnerId, cardId) => dispatch => dispatch({
    [CALL_API]: {
        types: [ UPDATE_SUBSCRIPTION, UPDATE_SUBSCRIPTION_SUCCESS, UPDATE_SUBSCRIPTION_FAIL ],
        endpoint: `/partners/${partnerId}/subscriptions`,
        schema: schemas.subscription,
        options: {
            method: 'put',
            body: {
                partner_id: partnerId,
                payment_solution: {
                    name: 'card',
                    payment_card_id: cardId,
                },
            },
        },
    },
})

export const getSubscriptionTypes = geoCode => ({
    [CALL_API]: {
        types: [ GET_SUBSCRIPTION_TYPES, GET_SUBSCRIPTION_TYPES_SUCCESS, GET_SUBSCRIPTION_TYPES_FAIL ],
        endpoint: '/partners/subscriptiontypes',
        schema: schemas.subscriptionTypes,
        options: {
            method: 'get',
            body: {
                geo_code: geoCode,
            },
        },
    },
})

export const getPartnerSubscriptions = partnerId => ({
    [CALL_API]: {
        types: [ GET_PARTNER_SUBSCRIPTIONS, GET_PARTNER_SUBSCRIPTIONS_SUCCESS, GET_PARTNER_SUBSCRIPTIONS_FAIL ],
        endpoint: `/partners/${partnerId}/subscriptions`,
        schema: schemas.subscriptions,
        options: {
            method: 'get',
        },
    },
})

export const cancelSubscription = (partnerId, subscriptionId) => ({
    [CALL_API]: {
        types: [ CANCEL_SUBSCRIPTION, CANCEL_SUBSCRIPTION_SUCCESS, CANCEL_SUBSCRIPTION_FAIL ],
        endpoint: `/partners/${partnerId}/subscriptions/${subscriptionId}/actions`,
        schema: schemas.subscription,
        options: {
            method: 'post',
            body: {
                action: 'cancel_with_cancellation_period',
            },
        },
    },
})
