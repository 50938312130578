import { GET_PARTNER_CONTRACT_OFFER_GROUPS, GET_PARTNER_CONTRACT_OFFER_GROUPS_FAIL, GET_PARTNER_CONTRACT_OFFER_GROUPS_SUCCESS } from '../../actions/partnercontractoffergroups'

const initialState = {
    isLoading: false,
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_PARTNER_CONTRACT_OFFER_GROUPS:
            return {
                ...state,
                isLoading: true,
            }
        case GET_PARTNER_CONTRACT_OFFER_GROUPS_SUCCESS:
        case GET_PARTNER_CONTRACT_OFFER_GROUPS_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state
    }
}
