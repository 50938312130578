import React, { Fragment } from 'react'
import moment from 'moment'
import { omitBy, isEmpty } from 'lodash'
import { Modal, Row, Col } from 'react-bootstrap'
import Datetime from 'react-datetime'
import { Tool } from 'react-feather'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { hideModal } from '../../../actions/modal'
import {
    getContract,
    createPartnerContractPause,
    updatePartnerContractPause,
    deletePartnerContractPause,
} from '../../../actions/partnercontracts'
import { Impact, Span, Text } from '../../elements/text/Text'
import { Flex } from '../../elements/Flex'
import { Button } from '../../elements/ButtonNew'
import Box from '../../blocks/Box'
import Tabs from '../../elements/Tabs'
import FormattedDate from '../../utils/FormattedDate'
import { fadedTextColor } from '../../../theme/variables'
import Label from 'react-bootstrap/lib/Label'
import PauseForm from './PauseForm'
import { Table, Header, Rows, Column, Cell } from '../../elements/Table'
import { toastr } from 'react-redux-toastr'


const CenteredModal = styled(Modal)`
    .modal-dialog {
        width: 820px;
        margin-top: 300px !important;
    }
`

const Views = {
    list: 'list',
    create: 'create',
}

const StyledPauseRow = styled.tr`
    ${props =>  props.fadeOut
        ? `pointer-events: none; color: ${fadedTextColor};`
        : ''}

    ${Button} {
        visibility: hidden;
    }

    &:hover ${Button} {
        visibility: visible;
    }
`

const StatusLabel = styled(Label)`
    padding: 8px 10px 6px !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    border-radius: 12px !important;
`

class PauseRow extends React.PureComponent {
    state = {
        toggled: false,
    }

    toggleDropdown = () => {
        this.setState({
            toggled: !this.state.toggled,
        })
    }

    onPauseUpdate = async (startsAt, endsAt) => {
        const pause = this.props.rowData

        await this.props.pauseUpdate(pause.id, {
            startsAt: pause.startsAt !== startsAt ? startsAt : '',
            endsAt: pause.endsAt !== endsAt ? endsAt : '',
        })

        this.toggleDropdown()
    }

    onPauseEnd = async () => {
        const pause = this.props.rowData

        await this.props.pauseEnd(pause.id)
    }

    onPauseDelete = async () => {
        const pause = this.props.rowData

        await this.props.pauseDelete(pause.id)
    }

    render() {
        const { t } = this.props
        const pause = this.props.rowData
        const now = moment()

        const isActive = moment(pause.startsAt).isBefore(now) && moment(pause.endsAt).isAfter(now)
        const isExpired = moment(pause.startsAt).isBefore(now) && moment(pause.endsAt).isBefore(now)
        const isUpcoming = moment(pause.startsAt).isAfter(now) && moment(pause.endsAt).isAfter(now)

        return <Fragment>
            <StyledPauseRow fadeOut={isExpired}>
                <Cell>
                    {isActive && <StatusLabel bsStyle="success">{t('partner_contracts.pauses.status.active')}</StatusLabel>}
                    {isExpired && <StatusLabel bsStyle="default">{t('partner_contracts.pauses.status.expired')}</StatusLabel>}
                    {isUpcoming && <StatusLabel bsStyle="info">{t('partner_contracts.pauses.status.scheduled')}</StatusLabel>}
                </Cell>
                <Cell><FormattedDate date={pause.startsAt} /></Cell>
                <Cell><FormattedDate date={pause.endsAt} /></Cell>
                <Cell>
                    {!isExpired && <Button modifiers={[ 'cellBtn', 'primary' ]} onClick={this.toggleDropdown}>{t('partner_contracts.pauses.actions.modify')}</Button>}
                    {isActive && <Button modifiers={[ 'cellBtn', 'warning', 'mL_1' ]} onClick={this.onPauseEnd}>{t('partner_contracts.pauses.actions.end')}</Button>}
                    {isUpcoming && <Button modifiers={[ 'cellBtn', 'danger', 'mL_1' ]} onClick={this.onPauseDelete}>{t('partner_contracts.pauses.actions.delete')}</Button>}
                </Cell>
            </StyledPauseRow>
            {this.state.toggled && <tr>
                <Cell colSpan={10}>
                    <Box.Content modifiers={[ 'pX_2', 'pT_2', 'pB_5' ]}>
                        <PauseForm
                            startsAt={pause.startsAt}
                            endsAt={pause.endsAt}
                            submit={this.onPauseUpdate}
                            t={t}
                        />
                    </Box.Content>
                </Cell>
            </tr>}
        </Fragment>
    }
}

@connect((state) => ({
    partnerContract: state.entities.partnerContracts[state.modal.contractId],
    partner: state.entities.partners[state.modal.partnerId],
}), {
    hideModal,
    createPartnerContractPause,
    updatePartnerContractPause,
    deletePartnerContractPause,
    getContract,
})
@withTranslation('', { wait: true })
export class PauseContractModal extends React.PureComponent {
    state = {
        viewName: Views.list,
        pauseIds: [],
    }

    async componentDidMount() {
        const pauses = this.props.partnerContract.allocationPauses

        if (!pauses || !pauses.length) {
            await this.changeView(Views.create)
        } else {
            const pauseIds = pauses.map(pause => pause.id)
            this.setState({
                pauseIds: [ ...pauseIds ],
            })
        }
    }

    updatePauses = async () => {
        const { partner, partnerContract } = this.props
        const contractResponse = await this.props.getContract(partner.id, partnerContract.id)

        const pauses = contractResponse.entities.partnerContracts[contractResponse.result].allocationPauses

        if (pauses) {
            const pauseIds = pauses.map(pause => pause.id)
            this.setState({
                pauseIds: [ ...pauseIds ],
            })
        }
    }

    changeView = viewName => this.setState({ viewName })

    onPauseCreate = async (startsAt, endsAt) => {
        const { partner, partnerContract } = this.props

        try {
            await this.props.createPartnerContractPause(
                partner.id,
                {
                    partnerContractId: [ partnerContract.id ],
                    startsAt,
                    endsAt,
                }
            )

            await this.updatePauses()

            this.changeView(Views.list)
        } catch (e) {
            toastr.error('Error!', e.error)
        }
    }

    onPauseDelete = async pauseId => {
        const { partnerContract, partner } = this.props

        try {
            await this.props.deletePartnerContractPause(partner.id, partnerContract.id, pauseId)

            await this.updatePauses()
        } catch (e) {
            toastr.error('Error!', e.error)
        }
    }

    onPauseEnd = async pauseId => {
        const { partnerContract, partner } = this.props

        try {
            await this.props.updatePartnerContractPause(partner.id, partnerContract.id, pauseId, {
                endsAt: moment().format(),
            })

            await this.updatePauses()
        } catch (e) {
            toastr.error('Error!', e.error)
        }
    }

    onPauseUpdate = async (pauseId, pauseData) => {
        const { partnerContract, partner } = this.props

        const filteredData = omitBy({ ...pauseData }, isEmpty)

        try {
            await this.props.updatePartnerContractPause(partner.id, partnerContract.id, pauseId, filteredData)

            await this.updatePauses()
        } catch (e) {
            toastr.error('Error!', e.error)
        }
    }

    render() {
        const {
            hideModal,
            partnerContract,
            t,
        } = this.props

        const tabs = [
            { tabName: t('partner_contracts.pauses.tabs.list'), tabId: Views.list },
            { tabName: t('partner_contracts.pauses.tabs.create'), tabId: Views.create },
        ]

        return <CenteredModal
            show={true}
            onHide={hideModal}
            className="new"
        >
            <Box.Header modifiers={[ 'tabs' ]}>
                <Tabs
                    tabs={tabs}
                    selectedTab={this.state.viewName}
                    changeTab={tabId => this.changeView(tabId)}
                />
            </Box.Header>
            <Box.Line />
            <Modal.Body>
                {this.state.viewName === Views.list && <Table>
                    <Header>
                        <Column>{t('partner_contracts.pauses.table.status')}</Column>
                        <Column>{t('partner_contracts.pauses.pause_start_date')}</Column>
                        <Column>{t('partner_contracts.pauses.pause_end_date')}</Column>
                        <Column>{t('partner_contracts.pauses.table.actions')}</Column>
                    </Header>
                    <Rows
                        items={partnerContract.allocationPauses || []}
                    >
                        <PauseRow
                            partnerContract={partnerContract}
                            pauseUpdate={this.onPauseUpdate}
                            pauseEnd={this.onPauseEnd}
                            pauseDelete={this.onPauseDelete}
                            t={t}
                        />
                    </Rows>
                </Table>}
                {this.state.viewName === Views.create && <Box.Content modifiers={[ 'pX_2', 'pT_2', 'pB_4' ]}>
                    <PauseForm
                        t={t}
                        submit={this.onPauseCreate}
                    />
                </Box.Content>}
            </Modal.Body>
        </CenteredModal>
    }
}


