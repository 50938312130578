import {
    GET_LOCATIONS, GET_LOCATIONS_SUCCESS, GET_LOCATIONS_FAIL,
} from '../../actions/locations'

const initialState = {
    locations: new Set([]),
    isLoading: false,
    pagination: {},
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
    case GET_LOCATIONS:
        return {
            ...state,
            isLoading: true,
        }
    case GET_LOCATIONS_SUCCESS:
        return {
            ...state,
            locations: new Set(action.response.result),
            isLoading: false,
        }
    case GET_LOCATIONS_FAIL:
        return {
            ...state,
            isLoading: false,
        }
    default:
        return state
    }
}
