import { GET_ACCEPTED_TERMS, GET_ACCEPTED_TERMS_FAIL, GET_ACCEPTED_TERMS_SUCCESS } from '../../actions/terms'

const initialState = {
    termsId: [],
    isLoadingTerms: true,
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_ACCEPTED_TERMS:
            return {
                ...state,
                isLoadingTerms: true,
            }
        case GET_ACCEPTED_TERMS_SUCCESS:
            return {
                ...state,
                termsId: action.response.result,
                isLoadingTerms: false,
            }
        case GET_ACCEPTED_TERMS_FAIL:
            return {
                ...state,
                isLoadingTerms: false,
            }
        default:
            return state
    }
}
