import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { currentPartnerSelector } from '../../selectors/auth'
import { primaryIndustrySelector } from '../../selectors/industries'
import PageTitle from '../utils/PageTitle'
import InvoiceInfoBox from '../account/InvoiceInfoBox'

@connect(
    state => ({
        partner: currentPartnerSelector(state),
    })
)
@withTranslation('', { wait: true })
export default class CompanyDetailsPage extends React.Component {
    render() {
        const {
            t,
            partner,
        } = this.props

        return (
            <div className="card">
                <PageTitle identifier="company_details.header" />
                <h2>{t('company_details.header')}</h2>
                <p className="intro-text">{t('company_details.intro')}</p>

                <div className="alert alert-warning">{t('company_details.change_label')}</div>

                <InvoiceInfoBox partner={partner} />
            </div>
        )
    }
}
