import { applyStyleModifiers } from 'styled-components-modifiers'
import { TEXT_COLOR_MODIFIERS } from '../../elements/modifiers/textColorModifiers'
import styled from 'styled-components'
import { MODIFIER_MARGIN_CONFIG } from '../../elements/modifiers/spaceModifiers'

export default styled.div`
    font-size: 15px;
    ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
    ${applyStyleModifiers(TEXT_COLOR_MODIFIERS)}
`
