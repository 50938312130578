import styled from 'styled-components'
import { ControlLabel } from 'react-bootstrap'
import { metaTextColor } from '../../../theme/variables'

export default styled(ControlLabel)`
  font-size: 12px;
  font-weight: bold;
  color: ${metaTextColor};
  text-transform: uppercase;
`
