import React from 'react'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import ExpandButton from '../utils/ExpandButton'
import EducationForm from '../cv/EducationForm'
import { Button } from '../elements/Button'
import { Flex } from '../elements/Flex'

@withTranslation('', { wait: true })
export default class EducationItem extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            editing: false,
        }
    }

    clickEdit = () => {
        this.setState({
            editing: true,
        })
    }

    onCloseEdit = () => {
        this.setState({
            editing: false,
        })
    }

    onSubmitUpdate = data => {
        this.setState({
            editing: false,
        })
        data.id = this.props.edu.id
        this.props.onSubmitEdit(data)
    }

    deleteEducation = () => {
        this.setState({
            editing: false,
        })

        this.props.onDeleteEducation(this.props.edu.id)
    }

    render() {
        const {
            edu, t,
        } = this.props
        const { editing } = this.state

        const toDate = edu.toDate ? '- ' + moment(edu.toDate).format('MMMM YYYY') : ''

        return (
            <div className="item">
                <Flex modifiers={[ 'justifySpaceBetween', 'alignCenter' ]}>
                    <div>
                        <h3>{edu.educationName}</h3>
                        <div>{edu.facilityName}</div>
                        <p className="meta">
                            {moment(edu.fromDate).format('MMMM YYYY')} {toDate}
                        </p>
                    </div>
                    {!editing && <Button
                        modifiers={[ 'secondary' ]} onClick={this.clickEdit}>{t('cv.education.edit')}</Button>
                    }
                </Flex>

                {editing && <ExpandButton
                   expanded={true}
                   onClose={this.onCloseEdit}
                   className="success"
                   content={
                       <div>
                           <EducationForm handleSubmit={this.onSubmitUpdate} initialValues={{
                               ...edu,
                               fromDateYear: moment(edu.fromDate).format('YYYY'),
                               fromDateMonth: moment(edu.fromDate).format('MM'),
                               toDateYear: edu.toDate ? moment(edu.toDate).format('YYYY') : '',
                               toDateMonth: edu.toDate ? moment(edu.toDate).format('MM') : '01',
                           }}/>
                           <button style={{ marginTop: 20 }} className="btn btn-lg btn-danger btn-block" onClick={this.deleteEducation}>{t('cv.experience.delete')}</button>
                       </div>
                   }
                   header={t('cv.education.edit')}
                   />
               }
            </div>
        )
    }
}
