import { GET_CV_SUCCESS } from '../actions/cv'
import {
    GET_PARTNER_VERIFICATIONS_SUCCESS,
} from '../actions/partners'
import { ADD_NOTE_TO_PROJECT_SUCCESS } from '../actions/projects'
import {
    ADD_QUOTE_ITEM_SUCCESS,
    DELETE_QUOTE_ITEM_SUCCESS,
} from '../actions/quotes'
import { GET_LEAD_SUCCESS } from '../actions/leads'
import {
    DELETE_PARTNER_QUOTE_PRODUCT_COMMENT_SUCCESS,
    DELETE_PARTNER_QUOTE_PRODUCT_PRICE_SUCCESS,
    DELETE_PARTNER_REVENUE_SEGMENT_SUCCESS,
    COPY_PARTNER_REVENUE_SEGMENT_SUCCESS,
} from '../actions/products'

const initialState = {
    clients: {},
    checkouts: {},
    partnerUsers: {},
    partners: {},
    leads: {},
    quotes: {},
    geoRegions: {},
    industries: {},
    leadTypes: {},
    ratings: {},
    coupons: {},
    invoices: {},
    transactions: {},
    subscriptionPackages: {},
    paymentCards: {},
    cvs: {},
    certifications: {},
    listOfPartnersCertifications: {},
    languages: {},
    refunds: {},
    projects: {},
    projectNotes: {},
    projectStatuses: {},
    projectEvents: {},
    projectConversations: {},
    conversationMessages: {},
    prompts: {},
    notifications: {},
    termss: {},
    renderedTermss: {},
    deliverables: {},
    verifications: {},
    bankAccounts: {},
    conversions: {},
    invoiceRefundRequests: {},
    dataFields: {},
    sectors: {},
    businessUnits: {},
    partnerContracts: {},
    partnerContractOfferGroups: {},
    partnerAssignees: {},
    products: {},
    partnerQuoteProductPrices: {},
    partnerQuoteProductComments: {},
    partnerRevenueSegments: {},
    subscriptionTypes: {},
}

const customMerge = (srcEntities, newEntities) => {
    const out = { ...srcEntities }

    Object.keys(newEntities).forEach(name => {
        out[name] = {}
        Object.assign(out[name], srcEntities[name], newEntities[name])
    })

    return out
}

export default function reducer(state = initialState, action = {}) {
    if (action.type === COPY_PARTNER_REVENUE_SEGMENT_SUCCESS && action.forceCopy) {
        state.partnerRevenueSegments = []
        return state
    }

    if(action.type === GET_CV_SUCCESS) {
        return customMerge(state, { cvs: action.response.entities.cvs })
    }

    // if (action.type === CREATE_QUOTE_SUCCESS) {
    //     state.leads[action.leadId].quotesInfo.quotes.push(Object.values(action.response.entities.quotes)[0])
    //     return {
    //         ...state,
    //     }
    // }

    if (action.type === GET_LEAD_SUCCESS && state.leads[action.response.result]) {
        state.leads[action.response.result] = action.response.entities.leads[action.response.result]
        return { ...state }
    }

    if (action.type === ADD_QUOTE_ITEM_SUCCESS) {
        state.quotes[action.quoteId].items.push(Object.values(action.response.entities.quoteItems)[0])
    }

    if (action.type === DELETE_QUOTE_ITEM_SUCCESS) {
        const items = state.quotes[action.quoteId].items.filter(item => item.id !== action.itemId)
        state.quotes[action.quoteId].items = items
        return { ...state }
    }

    if(action.type === GET_PARTNER_VERIFICATIONS_SUCCESS) {
        return customMerge(state, { verifications: action.response.entities.verifications || {} })
    }

    if(action.type === ADD_NOTE_TO_PROJECT_SUCCESS) {
        state.projects[action.projectId].notes.unshift(action.response.result)
    }

    if (action.response && action.response.entities) {
        return customMerge(state, action.response.entities)
    }

    if (action.type === DELETE_PARTNER_QUOTE_PRODUCT_PRICE_SUCCESS) {
        state.partnerQuoteProductPrices = Object.values(state.partnerQuoteProductPrices)
            .filter(i => i.id !== action.quotePriceId)
        return state
    }

    if (action.type === DELETE_PARTNER_QUOTE_PRODUCT_COMMENT_SUCCESS) {
        state.partnerQuoteProductComments = Object.values(state.partnerQuoteProductComments)
            .filter(i => i.id !== action.quoteCommentId)
        return state
    }

    if (action.type === DELETE_PARTNER_REVENUE_SEGMENT_SUCCESS) {
        state.partnerRevenueSegments = Object.values(state.partnerRevenueSegments)
            .filter(i => i.id !== action.segmentId)
        return state
    }

    return state
}
