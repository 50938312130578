import styled from 'styled-components'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { MODIFIER_MARGIN_CONFIG, MODIFIER_PADDING_CONFIG } from './modifiers/spaceModifiers'

export default styled.div`
  max-width: ${props => props.width || '100%'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
  ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)}
`
