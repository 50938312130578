import { useState } from 'react'
import { Card, Content } from '../cards/index'
import { Col, Row } from 'react-bootstrap'
import { Button } from '../elements/ButtonNew'
import { Text } from '../elements/text/Text'
import CreateSubscriptionModal from './CreateSubscriptionModal'
import { useTranslation } from 'react-i18next'

export default function CreateSubscriptionCard ({
    partner,
    product,
    fillPayment,
}){
    const [showModal, setShowModal] = useState(false);
    const { t } = useTranslation();
    const toggleModal = (show) => {
        setShowModal(show);
    }

    let priceText = product.priceExclVat + ' ' + product.currency.toUpperCase();
    if(product.vatRate > 0){
        priceText += ' ' + t('stripe_subscription_page.subscription.ex_vat');
    }
    priceText += '/' + t('stripe_subscription_page.subscription.period.' + product.paymentFrequency);


    return(
        <div>
            <Card>
                <Content>
                    <Row>
                        <Col sm={8}>
                            <Text>{product.name}</Text>
                            <br />
                            <Text modifiers={['small']}>{product.description}</Text>
                        </Col>
                        <Col sm={4} style={{ textAlign: 'right' }}>
                            <Text>{priceText}</Text>
                        </Col>
                    </Row>

                    <br />

                    <Button onClick={ () => setShowModal(true) } modifiers={[ 'action', 'bold', 'small' ]}>
                        <span>
                            {t('stripe_subscription_page.subscribe')}
                        </span>
                    </Button>
                </Content>
            </Card>

            <CreateSubscriptionModal partner={partner} product={product} requirePaymentMethod={fillPayment} show={showModal} toggle={toggleModal}/>
        </div>
    )
}
