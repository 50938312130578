import { useState } from 'react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button } from '../elements/ButtonNew'
import LoadingAnimation from '../utils/LoadingAnimation';
import { StripeRedirectKey } from './constants';

export default function PaymentMethodForm ({
    stripeClientSecret,
    buttonText,
    redirectId,
}) {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);

    const stripe = useStripe();
    const elements = useElements();

    const paymentElementOptions = {
        layout: 'tabs'
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (stripe == null || elements == null) {
            return;
        }

        setLoading(true);
        const {error: submitError} = await elements.submit();
        if (submitError) {
            setMessage(submitError);
            setLoading(false);
            return;
        }

        sessionStorage.setItem(StripeRedirectKey, redirectId);
        const { error } = await stripe.confirmSetup({
            elements,
            clientSecret: stripeClientSecret,
            confirmParams: {
                return_url: window.location.protocol + '//' + window.location.host + window.location.pathname,
            },
        });
        setMessage(error);
        setLoading(false);
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" options={paymentElementOptions} />
            <br />
            <Button id="submit" type="submit" modifiers={[ 'action', 'bold', 'small' ]} disabled={loading}>
                <span id="button-text">
                    {buttonText}
                </span>
            </Button>
            {loading && (
                <LoadingAnimation />
            )}
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
        </form>
    )
}
