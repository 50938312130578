import { createSelector } from 'reselect'
import { renderedTermsByIdSelector } from './terms'

export const getVerificationSelector = createSelector(
    state => state.entities.verifications,
    verifications => Object.keys(verifications).length > 0 ? Object.entries(verifications)[0][1] : null
)

export const getVerificationTermsSelector = createSelector(
    getVerificationSelector,
    state => state.entities.renderedTermss,
    (verifications, renderedTermss) => verifications  ?
        verifications.entries
            .filter(el => renderedTermsByIdSelector({
                entities: {
                    renderedTermss,
                },
            })(el.metadata.termsId) && el.identifier === 'ageras.tos.accept')
            .map(ent => renderedTermsByIdSelector({
                entities: {
                    renderedTermss,
                },
            })(ent.metadata.termsId)) :
        []
)
