import React from 'react'
import { Card } from '../../../../../components/blocks/Card'
import styled from 'styled-components'
import { Flex } from '../../../../../components/elements/Flex'
import CheckMarkCircle from '../../../../../components/elements/CheckMarkCircle'

const Certification = styled.div`
    box-shadow: 0 2px 3px 1px rgba(231, 231, 231, 0.5);
    display: inline-block;
    border-radius: 100px;
    margin-bottom: 20px;
    padding: 17px 20px;
`

export const CertificationCard = ({
    certifications,
    t,
}) => Boolean(certifications.length) &&
    <Card>
        <Card.Content modifiers={[ 'pY_5', 'pX_6' ]}>
            <Card.Heading modifiers={[ 'primaryColor', 'mB_5' ]}> {t('partner.certifications')}</Card.Heading>
            {certifications.map(el => <div key={'certification_' + el.id}>
                <Certification>
                    <Flex modifiers={[ 'alignCenter' ]}>
                        <CheckMarkCircle modifiers={[ 'primary', 'mR_2' ]} size={5} />
                        <span>{el.title}</span>
                    </Flex>
                </Certification>
            </div>)}
        </Card.Content>
    </Card>
