import {
    GET_INVOICE_REFUND_REQUESTS,
    GET_INVOICE_REFUND_REQUESTS_SUCCESS,
    GET_INVOICE_REFUND_REQUESTS_FAIL,

} from '../../actions/invoices'

const initialState = {
    refunds: [],
    isLoading: false,
    pagination: {},
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_INVOICE_REFUND_REQUESTS:
            return {
                ...state,
                isLoading: true,
            }
        case GET_INVOICE_REFUND_REQUESTS_SUCCESS:
            return {
                ...state,
                refunds: action.append ? state.refunds.concat(action.response.result) : action.response.result,
                pagination: action.response.pagination,
                isLoading: false,
            }
        case GET_INVOICE_REFUND_REQUESTS_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state
    }
}
