import React from 'react'
import { MapPin } from 'react-feather'
import Box from '../../blocks/Box'
import { Flex } from '../../elements/Flex'
import { CircleNumber, DetailsCard, LeadCardLogo, Tooltip } from './styles'
import { Headline, Span, Text } from '../../elements/text/Text'
import { TextButton } from '../../elements/ButtonNew'
import config from '../../../config'
import moment from 'moment'
import { colors } from '../../../theme/variables_new'
import { Icon } from '../../layout/images'
import { calculateDistance } from '../../utils'

export default class LeadCard extends React.Component {
    state = {
        showDetails: false,
    }

    formatIndustryTitle = industry => {
        if (industry.includes('(')) {
            return industry.substring(0, industry.indexOf('('))
        }
        if (industry.length > 40) {
            return industry.substring(0, 32) + '...'
        }
        return industry
    }

    formatFrontTitle = title => title.length > 72 ? title.substring(0, 72) + '...' : title

    formatPrice = price => {
        const { currency } = this.props.partner.preferences

        if (!price) {
            return null
        }
        if (currency.code === config.currency.us) {
            return currency.symbol + price
        }
        return price + currency.symbol
    }

    formatDaysLeft = validatedAt => {
        const { validationDeadlineDays } = this.props

        const daysLeft = moment(validatedAt).diff(moment().subtract(validationDeadlineDays, 'days'), 'days')
        if (daysLeft < 0) {
            return 0
        }
        return daysLeft
    }

    formatGeoString = () => {
        const { lead, partner } = this.props
        const geoCode = partner.geo.code

        const string = lead.geo.cityName
        if (lead.geo.zipCode) {
            return lead.geo.zipCode
                .concat(` ${string}, ${calculateDistance(lead.geo.point.lat, lead.geo.point.lon, partner.geo.point.lat, partner.geo.point.lon, geoCode).toFixed(0)}`)
                .concat(geoCode === 'us' ? 'mi' : 'km')
        }
        return string
    }

    buyLead = () => {
        const { lead } = this.props
        return this.props.buyLead(lead.id)
    }

    toggleDetails = () => this.setState({ showDetails: !this.state.showDetails })

    render() {
        const { lead, t } = this.props

        let marketplaceDescription
        if (lead.marketplaceDescription) {
            marketplaceDescription = <React.Fragment>
                <Text modifiers={[ 'medium', 'blue', 'bold' ]}>{t('marketplace.lead.description')}:</Text>
                <Text modifiers={[ 'mB_2', 'medium' ]}>{lead.marketplaceDescription}</Text>
            </React.Fragment>
        }

        return <Box modifiers={[ 'mY_2' ]}>
            <Box.Header modifiers={[ 'pT_2', 'pX_2', 'pB_1' ]}>
                <Flex>
                    <div style={{ flexShrink: 0 }}>
                        <Icon modifiers={[ 'tiny' ]}>
                            <svg viewBox="0 0 43 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M31.2439 18.5366H21V14.1463L11.2439 18.5366V14.1463L1 18.5366V39.0244H31.2439" stroke="#0A7AE0" strokeWidth="1.8"/>
                                <path d="M8.72812 30.2439C8.72812 29.6633 9.19877 29.1927 9.77934 29.1927H21.4867C22.0672 29.1927 22.5379 29.6633 22.5379 30.2439V39.1H8.72812V30.2439Z" stroke="#0A7AE0" strokeWidth="1.8"/>
                                <path d="M30.2379 39.1L32.1014 0.9H40.1431L42.0065 39.1H30.2379Z" stroke="#16BEFF" strokeWidth="1.8" strokeLinejoin="round"/>
                                <line x1="31.2441" y1="7.88049" x2="41.0002" y2="7.88049" stroke="#16BEFF" strokeWidth="1.8"/>
                            </svg>
                        </Icon>
                    </div>
                    <Box.Content modifiers={[ 'mL_2' ]}>
                        <Text modifiers={[ 'regular', 'bold' ]}>{lead.customerInfo.customerType.identifier}</Text>
                        <Text modifiers={[ 'small' ]}>{this.formatIndustryTitle(lead.industries.secondary.name)}</Text>
                        <Flex modifiers={[ 'alignCenter' ]}>
                            <Text modifiers={[ 'small', 'mR_1' ]}>{this.formatGeoString()}</Text>
                            <MapPin size={16} />
                        </Flex>
                        <Text modifiers={[ 'tiny', 'grey' ]}>Expires in {this.formatDaysLeft(lead.validatedAt)} days</Text>
                    </Box.Content>
                </Flex>
            </Box.Header>
            <Box.Line />
            <Box.Content style={{ minHeight: '380px' }}>
                <Flex
                    modifiers={[ 'directionColumn' ]}
                    style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
                >
                    <Flex modifiers={[ 'justifyCenter', 'alignCenter', 'fullHeight' ]} style={{ position: 'relative' }}>
                        <LeadCardLogo>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 152.73 175.72"><title>symbol_black</title><path d="M54.82,65.05h-14c-4.07,0-7.37,2.85-7.37,6.36V91.83c0,3.51,3.3,6.36,7.37,6.36H62.19V71.41C62.19,67.9,58.89,65.05,54.82,65.05Z" fill={colors.blue}/><path d="M84.34,33.28h22.94c6.65,0,12,4.65,12,10.39V77c0,5.74-5.39,10.39-12,10.39h-35V43.67C72.3,37.93,77.69,33.28,84.34,33.28Z" fill={colors.blue}/><path d="M93,98.19H62.19v37.89c0,3.52,3.3,6.36,7.37,6.36H93c4.07,0,7.37-2.84,7.37-6.36V104.55C100.4,101,97.1,98.19,93,98.19Z" fill={colors.blue}/></svg>
                        </LeadCardLogo>
                        <Headline modifiers={[ 'large' ]} style={{ zIndex: 1 }}>{this.formatPrice(lead.quotesInfo.quoteFee.quoteFee.amount)}</Headline>
                    </Flex>
                    <Box.Line />
                    <Text modifiers={[ 'p_2', 'medium', 'lightGrey' ]} style={{ minHeight: '6.5em' }}>
                        {this.formatFrontTitle(lead.title)}
                    </Text>
                    <Flex modifiers={[ 'pX_1', 'pB_1', 'justifySpaceBetween' ]}>
                        <TextButton modifiers={[ 'primary' ]} onClick={this.toggleDetails}>{t('marketplace.controls.read_more')}</TextButton>
                        <TextButton modifiers={[ 'primary' ]} onClick={this.buyLead}>{t('marketplace.controls.unlock')}</TextButton>
                    </Flex>
                </Flex>
                <DetailsCard
                    active={this.state.showDetails}
                    modifiers={[ 'directionColumn', 'justifySpaceBetween' ]}
                >
                    <Flex.Item modifiers={[ 'p_2' ]} style={{ overflowY: 'scroll' }}>
                        <Text modifiers={[ 'medium', 'blue', 'bold' ]}>{t('marketplace.lead.industry')}:</Text>
                        <Text modifiers={[ 'mB_2', 'medium' ]}>{lead.industries.secondary.name}</Text>
                        <Text modifiers={[ 'medium', 'blue', 'bold' ]}>{t('marketplace.lead.case')}:</Text>
                        <Text modifiers={[ 'mB_2', 'medium' ]}>{lead.title}</Text>
                        {marketplaceDescription}
                        <Text modifiers={[ 'medium', 'blue', 'bold' ]}>{t('marketplace.lead.products')}:</Text>
                        <ul>
                            {lead.products.map(product => <li>
                                <Text modifiers={[ 'small' ]}>{product.product.name} {product.quantity ? ` x ${product.quantity}` : ''}</Text>
                                {product.description && <Text modifiers={[ 'tiny', 'pL_1' ]}>{product.description}</Text>}
                            </li>)}
                        </ul>
                    </Flex.Item>
                    <Flex.Item modifiers={[ 'minimum' ]}>
                        <Box.Line />
                        <Flex modifiers={[ 'justifyEnd', 'fullHeight', 'p_1' ]}>
                            <TextButton modifiers={[ 'primary' ]} onClick={this.toggleDetails}>{t('marketplace.controls.hide')}</TextButton>
                        </Flex>
                    </Flex.Item>
                </DetailsCard>
            </Box.Content>
        </Box>
    }
}
