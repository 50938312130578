import {
    GET_PARTNER_BANK_ACCOUNTS,
    GET_PARTNER_BANK_ACCOUNTS_SUCCESS,
    GET_PARTNER_BANK_ACCOUNTS_FAIL,
} from '../../actions/partners'

const initialState = {
    bankAccountIds: [],
    isLoading: false,
}

export default function reducer(state = initialState,
    action = {}) {
    switch (action.type) {
    case GET_PARTNER_BANK_ACCOUNTS_SUCCESS:
        return {
            ...state,
            bankAccountIds: action.response.result,
            isLoading: false,
        }
    case GET_PARTNER_BANK_ACCOUNTS_FAIL:
        return {
            ...state,
            isLoading: false,
        }
    case GET_PARTNER_BANK_ACCOUNTS:
        return {
            ...state,
            isLoading: false,
        }
    default:
        return state
    }
}
