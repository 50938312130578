import isoFetch from 'isomorphic-fetch'
import storage from 'local-storage-fallback'

let simpleFetch = (url, { method = 'get', body } = {}) => {
    url = new URL(url);

    if (storage.getItem('refresh_token')) {
        url.searchParams.append('token', storage.getItem('refresh_token'))
    }

    let options = {
        method,
        mode: 'cors',
    }

    if (method === 'get' && body) {
        for (const [name, value] of Object.entries(body)) {
            if (value) url.searchParams.append(name, String(value))
        }
    } else if(method === 'post' || method === 'put' || method === 'patch') {
        options.body = JSON.stringify(body || {})
        options.headers = { 'Content-type': 'application/json' }
    }

    return isoFetch(url, options)
}

let fetch = (url, options) => simpleFetch(url, options)
    .then(response => {
        switch (response.status) {
            case 400:
                throw new Error('Bad Request')
            case 401:
                throw new Error('Unauthorized')
            case 403:
                throw new Error('Forbidden')
            case 404:
                throw new Error('Not Found')
            case 500:
                throw new Error('Internal Server Error')
            default:
        }

        return response
    })

let fetchJSON = (url, options) => fetch(url, options)
    .then(response => response.json())

export { fetch, simpleFetch, fetchJSON }
export default fetch
