import React from 'react'
import { currentClientSelector } from '../../../selectors/auth'
import { getPartnersUsers } from '../../../actions/users'
import { getLead, leadActionRecordRejection } from '../../../actions/leads'
import { connect } from 'react-redux'
import { push, replace } from 'connected-react-router'
import PageTitle from '../../../components/utils/PageTitle'
import StaticNotification from '../../../components/offers/StaticNotification'
import LoadingAnimation from '../../../components/utils/LoadingAnimation'
import i18n from '../../../i18n'
import { Col, Row } from 'react-bootstrap'
import { ContainerFluid } from '../../../components/cards'
import ReduxForm from '../../../components/forms/ReduxForm'
import { Field, FormSection } from 'redux-form'
import { Button } from '../../../components/elements/Button'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import RadioGroup from '../../../components/forms/inputs/RadioGroup'
import { Card } from '../../../components/blocks/Card'
import { Flex } from '../../../components/elements/Flex'
const en = i18n.getFixedT('en')

const RadioInputsForm = styled(RadioGroup)`
  label {
    text-transform: initial!important;
    font-size: 15px;
  }

`
@connect(
    (state, props) => ({
        lead: state.entities.leads[props.match.params.leadId],
        partnerUsers: Object.values(state.entities.partnerUsers),
        client: currentClientSelector(state),
        assignedEmployee: currentClientSelector(state).assignedEmployee ? state.entities.employees[currentClientSelector(state).assignedEmployee] : {},
    }),
    {
        getLead,
        leadActionRecordRejection,
        getPartnersUsers,
        push,
        replace,
    }
)
@withTranslation('', { wait: true })
export class RejectionFeedbackPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hideQuotes: false,
        }
    }

    componentDidMount() {
        this.props.getLead(this.props.match.params.leadId, { clientId: this.props.client.id })
            .then(res => {
                const { lead } = this.props
                const partnerIds = lead.quotesInfo ? lead.quotesInfo.quotes.map(quote => quote.partner.id) : []
                if(partnerIds.length) {
                    this.props.getPartnersUsers(partnerIds)
                }
            })
    }

    sendReason(quoteRejectedReasons) {
        const rejectionReasons = Object.keys(quoteRejectedReasons)
            .map(quoteId => ({ ...quoteRejectedReasons[quoteId], quoteId }))
        this.props.leadActionRecordRejection(this.props.lead.id, rejectionReasons)
            .then(res => {
                this.setState({ hideQuotes: true })
            })
    }

    render() {
        const {
              lead,
              t,
              assignedEmployee,
              partnerUsers,
          } = this.props

        if(!lead) return <LoadingAnimation />

        const quotes = lead.quotesInfo.quotes
        const allQuotesRejected = quotes.map(quote => quote.status).every(el => el === 'rejected')

        if(!allQuotesRejected) this.props.replace('/case')

        const quotesAndUsers = quotes.map(quote =>
            ({
                partnerUser: partnerUsers.find(el => el.partnerId === quote.partner.id),
                quote,
            })
        )

        const translationPath = 'quotes.reject_all_quotes.reasons.'
        const reasons = [ 'missing_expertise', 'missing_personal', 'no_contact_with_partner', 'unsatisfying_process',
            'wrong_geographic_location', 'price', 'choose_an_expert', 'no_need_for_service' ]
        const reasonsFields = reasons.map(reason => ({ id: en(translationPath + reason), name: t(translationPath + reason) }))

        return <ContainerFluid>
            <PageTitle identifier="pages.titles.case"/>
            <StaticNotification
                sender={assignedEmployee}
                text={t('employee_messages.' + (this.state.hideQuotes ? 'did_not_choose_any_case_partner' : 'rejected_case'))}
                signature={
                    <span>
                        <strong>{assignedEmployee.name}</strong> —&nbsp;
                        {assignedEmployee.email}, {assignedEmployee.signature.phoneNumber}
                    </span>
                }
            />

            {this.state.hideQuotes ||
                <Card modifiers={[ 'mT_3' ]}>
                    <Card.Content>
                        <ReduxForm
                            form="leadRejectReasonForm"
                            onSubmit={this.sendReason.bind(this)}
                        >
                            <Row className="row-float-block">
                                {quotesAndUsers.map((el, i) =>
                                    <Col xs={12} sm={6} md={4} key={'reason_box' + i}>
                                        {el.quote && el.partnerUser &&
                                        <Card>
                                            <div ref={card => {
                                                this['#reason' + el.quote.id] = card
                                            }}>
                                                <Card.Header><strong>{el.quote.partner.companyName}</strong></Card.Header>
                                                <Card.Content>
                                                    <h5>{i + 1}/{quotesAndUsers.length} {t('quotes.reject_all_quotes.card_header', {
                                                        partnerUser: (el.partnerUser.firstName || '') + ' ' + (el.partnerUser.lastName || ''),
                                                    })}</h5>
                                                    <FormSection name={el.quote.id}>
                                                        <Field
                                                            component={RadioInputsForm}
                                                            options={reasonsFields}
                                                            name="reason"
                                                        />
                                                    </FormSection>
                                                    {(quotesAndUsers.length - 1) === i ||
                                                    <Button
                                                        type="button"
                                                        modifiers={[ 'primary', 'mobileHiddenUp', 'btnBlock' ]}
                                                        onClick={() => this['#reason' + quotesAndUsers[i + 1].quote.id].scrollIntoView({ behavior: 'smooth' })}
                                                    >
                                                        {t('quotes.reject_all_quotes.next_btn')}
                                                    </Button>
                                                    }
                                                </Card.Content>
                                                {el.quote.partner && el.quote.partner.logo &&
                                                <div>
                                                    <Card.Line />
                                                    <Card.Content>
                                                        <img style={{
                                                            margin: '-10px',
                                                            maxHeight: '50px',
                                                            maxWidth: '100%',
                                                            borderRadius: '3px',
                                                        }}
                                                             src={el.quote.partner.logo} />
                                                    </Card.Content>
                                                </div>
                                                }
                                            </div>
                                        </Card>
                                        }
                                    </Col>
                                )
                                }
                            </Row>
                            <Flex modifiers={[ 'justifyEnd' ]}>
                                <Button
                                    modifiers={[ 'action', 'btnBlock', 'p_2' ]}
                                    type="submit"
                                >
                                    {t('quotes.reject_all_quotes.submit_btn')}
                                </Button>
                            </Flex>
                        </ReduxForm>
                    </Card.Content>
                </Card>
            }
        </ContainerFluid>
    }
}
