import { createSelector } from 'reselect'
import isEmpty from 'lodash.isempty'
import groupBy from 'lodash.groupby'

const routeToDataMap = {
    '/leads/recent': 'recentLeads',
    '/leads': 'allExclusiveLeads',
    '/leads/bought': 'boughtLeads',
    '/leads/demo': 'demoLeads',
}

const leadsSelector = state => state.entities.leads

const projectsSelector = state => state.entities.projects

const leadIdsPages = (state, pathname) => state.pages.leads[routeToDataMap[pathname] || 'leads'].leadIds

const leadSelector = (state, id) => state.entities.leads[id]

const dataFieldsSelector = state => state.entities.dataFields

const leadWithMatchFields = (lead, dataFields) => {
    const attributes = Object.keys(lead.attributes)
        .map(attributesId => ({
            ...lead.attributes[attributesId],
            tag: dataFields[attributesId].tag,
        }))

    return {
        ...lead,
        attributes: groupBy(attributes, 'tag'),
    }
}

export const partnerLeadByProjectSelector = createSelector(
    leadsSelector,
    projectsSelector,
    (leads, projects) =>
        projectId =>
            Object.keys(leads).length ? leads[projects[projectId].lead.id] : {}
)

export const leadsDataFieldsMatchSelector = createSelector(
    leadsSelector,
    leadIdsPages,
    dataFieldsSelector,
    (leads, leadIds, dataFields) => isEmpty(dataFields) ? [] :
        leadIds.map(id => leadWithMatchFields(leads[id], dataFields))
)

export const leadDataFieldsMatchSelector = createSelector(
    leadSelector,
    dataFieldsSelector,
    (lead, dataFields) => (isEmpty(dataFields) || isEmpty(lead))
        ? lead
        : leadWithMatchFields(lead, dataFields)
)

export const leadByProjectDatafieldsMatchSelector = (state, projectId) => createSelector(
    state => partnerLeadByProjectSelector(state)(projectId),
    state => state.entities.dataFields,
    (lead, dataFields) => (isEmpty(dataFields) || isEmpty(lead))
        ? lead
        : {
            ...lead,
            attributes: Object.keys(lead.attributes)
                .map(attributesId => ({
                    ...lead.attributes[attributesId],
                    tag: dataFields[attributesId].tag,
                })),
        }
)(state)

export const certificationSelector = createSelector(
    state => state.pages.quote.leadCertificationsId,
    state => state.entities.certifications,
    (ids, cer) => ids.map(id => cer[id])
)
