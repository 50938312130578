import { Col } from 'react-bootstrap';
import CustomerChoiceClientInfoCard from './CustomerChoiceClientInfoCard';
import CustomerChoiceCaseInfoCard from './CustomerChoiceCaseInfoCard';

export default function CustomerChoiceDetailPage({
    lead,
    partner,
}) {
    return (
        <div>
            <Col md={12} lg={6}>
                <CustomerChoiceClientInfoCard lead={lead} partner={partner} />
            </Col>
            <Col md={12} lg={6}>
                <CustomerChoiceCaseInfoCard lead={lead} />
            </Col>
        </div>
    );
}
