import React, { Component } from 'react'
import { Field, formValueSelector, reduxForm, SubmissionError } from 'redux-form'
import { withTranslation } from 'react-i18next'
import FileInput from '../forms/inputs/FileInput'
import CheckboxInput from '../forms/inputs/CheckboxInput'
import config from '../../config'
import * as refunds from '../../actions/refunds'
import { connect } from 'react-redux'

@reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
})
@connect(
    (state, props) => ({
        formValues: formValueSelector('wizard')(state, 'noDocumentation', 'documentation'),
    }),
)
@withTranslation('', { wait: true })
export default class RefundRequestFormBrowseFile extends Component {

    validate(values) {
        const { t } = this.props
        if (values.noDocumentation || values.documentation) {
            this.props.submit()
        } else {
            throw new SubmissionError({
                noDocumentation: t('refunds.browse_file_page.validation_error'),
            })
        }
    }

    removeDocument(e) {
        if (this.props.formValues.documentation) {
            e.preventDefault()
            this.props.resetSection('documentation')
        }
    }
    required = value => value ? undefined : this.props.t('refunds.browse_file_page.validation_error')

    render() {
        const { handleSubmit, previousPage, t } = this.props

        return (
            <form onSubmit={handleSubmit(this.validate.bind(this))}>
                <p>{t('refunds.browse_file_page.need_documentation')}</p>
                <p>{t('refunds.browse_file_page.submit_evidence')}</p>
                {this.props.formValues.noDocumentation ||
                <Field
                    touch={this.props.touch}
                    validate={[ this.required ]}
                    component={FileInput}
                    name="documentation"
                    showFileName={true}
                    accept=".pdf, .doc, .docx, .jpg, .png, .txt"
                >
                    <label
                        htmlFor="documentation"
                        className="btn btn-secondary-two"
                        onClick={e => this.removeDocument(e)}
                    >
                        {this.props.formValues.documentation ?
                            t('refunds.browse_file_page.remove_document_button') :
                            t('refunds.browse_file_page.browse_document_button')
                        }
                    </label>
                </Field>}
                <div>{t('refunds.browse_file_page.submit_label')}</div>
                <br />
                <button type="submit" className="next btn btn-action btn-lg btn-block">
                    {t('refunds.request.terms.next')}
                </button>
                <br />
                <a onClick={previousPage} className="cursor-pointer">{t('refunds.request.reason.back')}</a>
            </form>
        )
    }
}
