import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Field, reduxForm, FormElement } from 'redux-form'
import { connect } from 'react-redux'
import LoadingAnimation from '../utils/LoadingAnimation'
import Select from 'react-select'
import { getLanguages } from '../../actions/languages'
import { currentPartnerSelector } from '../../selectors/auth'

@withTranslation('', { wait: true })
@reduxForm({
    form: 'languageCv',
    fields: [
        'identifier',
        'proficiency',
    ],
})
@connect(
    state => ({
        languages: Object.values(state.entities.languages),
        partner: currentPartnerSelector(state),
    }),
    {
        getLanguages,
    }
)
export default class LanguageForm extends Component {

    componentDidMount() {
        this.props.getLanguages({ geoCode: this.props.partner.geo.code })
    }

    render() {
        const {
            t,
            handleSubmit,
            submitting,
            languages,
            fields: {
                proficiency,
            },
        } = this.props

        if (submitting) {
            return <LoadingAnimation />
        }

        let options = languages.map(lang => ({ value: lang.identifier, label: lang.name }))

        return (
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <Field name="identifier"
                               component={props =>
                                   <Select
                                       value={props.input.value}
                                       placeholder={t('cv.language.placeholders.language')}
                                       onChange={e => {
                                           props.input.onChange(e.value)
                                       }}
                                       options={options}
                                   />
                               }
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <Field
                                className="form-control"
                                name="proficiency"
                                component="select"
                                {...proficiency}
                            >
                                <option value="">{t('cv.language.level.proficiency')}</option>
                                <option value="noProficiency">{t('cv.language.level.noProficiency')}</option>
                                <option value="elementaryProficiency">{t('cv.language.level.elementaryProficiency')}</option>
                                <option value="limitedWorkingProficiency">{t('cv.language.level.limitedWorkingProficiency')}</option>
                                <option value="professionalProficiency">{t('cv.language.level.professionalProficiency')}</option>
                                <option value="fullProficiency">{t('cv.language.level.fullProficiency')}</option>
                                <option value="nativeProficiency">{t('cv.language.level.nativeProficiency')}</option>
                            </Field>
                        </div>
                    </div>
                </div>
                <button className="btn btn-action btn-lg btn-block" type="submit">
                    {t('cv.language.save')}
                </button>
            </form>
        )
    }
}

