import React from 'react'
import { FormControl, FormGroup, HelpBlock } from 'react-bootstrap'

export const TextInput = ({
  input: {
      value,
      onChange,
      name,
      ...inputProps
  },
  disabled,
  placeholder,
  height,
  meta: { touched, error, warning }
}) => (
    <FormGroup className={touched && error && 'has-error'}>
        <FormControl
            componentClass="textarea"
            style={{ height: height ? height : '150px' }}
            name={name}
            value={value}
            onChange={onChange}
            disabled={disabled}
            placeholder={placeholder}
            {...inputProps}
        />
        {touched &&
        ((error &&
            <HelpBlock>
                {error}
            </HelpBlock>) ||
            (warning &&
                <HelpBlock>
                    {warning}
                </HelpBlock>))}
    </FormGroup>
)
