import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { currentClientSelector } from '../../../../selectors/auth'
import { getLead } from '../../../../actions/leads'
import {
    Header,
    Content,
    VerticalTimeline,
    SquaredPlaceholder,
    SuccessCircle,
    GreyCircle,
    TimelineDot,
} from '../../../../components/cards/index'
import LoadingAnimation from '../../../../components/utils/LoadingAnimation'
import PageTitle from '../../../../components/utils/PageTitle'
import { QuoteBox } from '../../../../components/quotes/QuoteBox'
import { EmployeeLeadStatusNotification } from '../../../../components/leads/EmployeeLeadStatusNotification'
import { replace } from 'connected-react-router'
import { getQuotes } from '../../../../actions/quotes'
import { MenetoQuote } from '../../../layout/MenetoQuote'
import { Col, Row } from 'react-bootstrap'
import config from '../../../../config'
import { Card } from '../../../../components/blocks/Card'
import styled from 'styled-components'
import { ContainerFluid } from '../../../../components/cards'
import { media } from '../../../../components/utils/styledMedia'
import { TellowQuote } from '../../../layout/TellowQuote'
import LeadCompareSection from './LeadCompareSection'
import { createPunchableAction } from '../../../../actions/creditsystem'

const Timeline = ({ round }) => {
    return (
        <VerticalTimeline round={round}>
            <TimelineDot/>
        </VerticalTimeline>
    )
}

const PartnerInfoContent = styled(Card.Content)`
    ${media.min.landscapeMobile`
        height: 190px;
    `}
    ${SquaredPlaceholder}:last-child {
        margin-bottom: 0;
    }
`

const PendingQuotesHeader = styled.header`
    text-align: center;
    padding: 0 30px;
    margin-top: 30px;
`

const IFrame = ({ src }) => (
    <div style={{
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        paddingTop: '56.25%',
    }}>
        <iframe src={src} title="video" style={{
            border: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: '100%',
            height: '100%',
        }}/>
    </div>
)

const TimelineEvent = ({ title, children }) => (
    <div style={{
        float: 'left',
        width: 'calc(100% - 90px)',
        marginLeft: '20px',
        padding: '0 20px 20px 10px',
    }}>
        <h5><strong>{title}</strong></h5>
        {children}
    </div>
)

function LeadPage({
                      assignedEmployee,
                      quotes,
                      lead,
                      creditSystemIsLoading,
                      getLead,
                      match,
                      client,
                      getQuotes,
                      replace,
                      createPunchableAction
                  }) {

    const { t } = useTranslation();

    const [ isLoading, setIsLoading ] = useState(true)

    useEffect(() => {

        const loadLead = getLead(match.params.leadId, { clientId: client.id })

        const loadQuotes = getQuotes({
            leadId: match.params.leadId,
            clientId: client.id,
            is_published: true
        })

        Promise.all([ loadLead, loadQuotes ])
            .finally(() => {
                setIsLoading(false)
            })
    }, [ match.params.leadId, client.id ])

    useEffect(() => {
        if (lead?.quotesInfo?.acceptedQuote) {
            replace(`/cases/${match.params.leadId}/${lead.quotesInfo.acceptedQuote.partner.id}`)
        }
    }, [lead])

    const handleOpenQuoteClick = async (partnerId) => {
        if (creditSystemIsLoading) {
            return
        }
        window.location.href = `/cases/${lead.id}/${partnerId}`
    }

    const getQuoteCard = quote => {
        if (quote.partner.id === config.exclusivePartnersId.meneto) {
            return <MenetoQuote
                partner={quote.partner}
                quote={quote.quote}
                index={quote.index}
                lead={lead}
            />
        }

        if (quote.partner.id === config.exclusivePartnersId.tellow) {
            return <TellowQuote
                partner={quote.partner}
                quote={quote.quote}
                index={quote.index}
                lead={lead}
            />
        }

        return <QuoteBox
            partner={quote.partner}
            quote={quote.quote}
            index={quote.index}
            lead={lead}
            handleOpenQuoteClick={() => handleOpenQuoteClick(quote.partner.id)}
            creditSystemIsLoading={creditSystemIsLoading}
        />
    }

    const getSortedQuotes = (lead, quotes) => {

        const quotesWithPartner = quotes.map((quote, index) => {

            const quotesInfo = lead.quotesInfo.quotes.find(leadQuote => leadQuote.id === quote.id) || {}

            return {
                quote: quote,
                partner: {
                    ...quotesInfo.partner,
                    partnerUser: quotesInfo?.partner?.partnerUser || {},
                },
                index: index,
            }
        })

        return quotesWithPartner
            .sort((curr, next) => {
                if (next && next.partner && Object.values(config.exclusivePartnersId).includes(next.partner.id)) {
                    return 1
                } else if (curr.hasOwnProperty('quote') && next?.hasOwnProperty('quote')) {
                    return next.quote.totalAmountExclVat.amount - curr.quote.totalAmountExclVat.amount
                }
                return 0
            })

    }

    if (isLoading || !lead) {
        return <LoadingAnimation/>
    }

    const sortedQuotes = getSortedQuotes(lead, quotes)

    const menetoPartner = sortedQuotes.find(el => el.partner && el.partner.id === config.exclusivePartnersId.meneto)
    const tellowPartner = sortedQuotes.find(el => el.partner && el.partner.id === config.exclusivePartnersId.tellow)

    // number of max quotes - number of actual quotes
    const placeholdersNumber = new Array(Math.max(0, lead.quotesInfo.max - sortedQuotes.length)).fill(1)

    const allQuotesRejected = quotes.length ? quotes.every(quote => quote.status === 'rejected') : false

    return (
        <ContainerFluid>
            <PageTitle identifier="pages.titles.case" data={{ number: lead.id }}/>

            {Object.entries(assignedEmployee).length !== 0 && <EmployeeLeadStatusNotification
                assignedEmployee={assignedEmployee}
                allQuotesRejected={allQuotesRejected}
                lead={lead}
            />}


            {quotes.length === 0 && lead.quotesInfo.max !== 0 &&
                <PendingQuotesHeader>
                    <h1>{t('quotes.pending.copy_heading', { count: lead.quotesInfo.max})}</h1>
                    <h2>{t('quotes.pending.copy_subheading')}</h2>
                </PendingQuotesHeader>
            }


            {(allQuotesRejected || lead.quotesInfo.max === 0) || <Card.Content modifiers={[ 'pX_0' ]}>
                <Row className={`row-float-block${lead.quotesInfo.max === 1 ? ' text-center' : ''}`}>

                    {sortedQuotes.map((quoteInfo, i) => <Col
                        xs={12} sm={6} md={4}
                        key={'QuoteOfferBox_' + i}
                        className="text-left"
                    >
                        {getQuoteCard(quoteInfo)}
                    </Col>)}

                    {Object.keys(placeholdersNumber).map(i => (
                        <Col key={'placeholders' + i} xs={12} sm={6} md={4}>
                            <Card modifiers={[ 'mB_1' ]}>
                                <PartnerInfoContent>
                                    <h3>{t('quotes.pending.quote.copy_heading')}</h3>
                                    <p>{t('quotes.pending.quote.copy_subheading')}</p>
                                </PartnerInfoContent>
                                <Card.Line/>
                                <Card.Content>
                                    <SquaredPlaceholder width="50%"/>
                                    <SquaredPlaceholder width="100%" height="60px" className="hidden-xs" noMargin/>
                                </Card.Content>
                            </Card>
                            <Card className="hidden-xs">
                                <Card.Content>
                                    <SquaredPlaceholder width="100%"/>
                                    <SquaredPlaceholder width="100%" noMargin/>
                                </Card.Content>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Card.Content>}

            {allQuotesRejected || (!tellowPartner && !menetoPartner && sortedQuotes.length > 1 && lead.quotesInfo.max !== 0 &&
                <LeadCompareSection lead={lead} sortedQuotes={sortedQuotes}></LeadCompareSection>)}

            {lead.quotesInfo.max === 0 && (
                <div className="row row-float-block">
                    <div className="col-md-6">
                        <Card>
                            <Header>
                                <strong>{t('cards.questions_and_answers')}</strong>
                            </Header>
                            <Content>
                                {(new Array(10)).fill(1).map((_, i) => {
                                    const translationString = `case_page.questions_and_answers.${lead.industries.primary.identifier}`
                                    if (t(`${translationString}.question_${i + 1}`) === '.') {
                                        return null
                                    }
                                    return (
                                        <div key={'questions_' + i}>
                                            <strong>{t(`${translationString}.question_${i + 1}`)}</strong>
                                            <blockquote>
                                                {t(`${translationString}.answer_${i + 1}`)}
                                            </blockquote>
                                            <br/>
                                        </div>
                                    )
                                })}
                            </Content>
                        </Card>
                    </div>
                    <div className="col-md-6">
                        <Card>
                            <Header>
                                <strong>{t('cards.timeline')}</strong>
                            </Header>
                            <Content>
                                {quotes.map((quote, i) => {
                                    const partner = quote.partner

                                    if (!partner) {
                                        return <div key={'quote' + i}/>
                                    }

                                    return (
                                        <div key={'quote' + i} style={{ position: 'relative' }}>
                                            <GreyCircle>{quotes.length - i}</GreyCircle>
                                            <Timeline round={i === 0 ? 'top' : 'none'}/>
                                            <TimelineEvent
                                                title={t('case_page.timeline.interested_company_title', { companyName: partner.companyName })}>
                                                {t('case_page.timeline.interested_company_text', { companyName: partner.companyName })}
                                            </TimelineEvent>
                                            <div className="clearfix"/>
                                        </div>
                                    )
                                })}
                                <div style={{ position: 'relative' }}>
                                    <SuccessCircle className="ap-checkmark"/>
                                    <Timeline round="both"/>
                                    <TimelineEvent title={t('case_page.timeline.inquiry_received_title')}>
                                        {t('case_page.timeline.inquiry_received_text')}
                                    </TimelineEvent>
                                    <div className="clearfix"/>
                                </div>
                            </Content>
                        </Card>
                    </div>
                </div>
            )}

            {lead.geo.code === 'us' && <div className="container">
                <IFrame src="https://player.vimeo.com/video/695706077?h=ea3b9f9777"/>
            </div>}

        </ContainerFluid>
    )
}

export default connect(
    (state, props) => ({
        lead: state.entities.leads[props.match.params.leadId],
        quotes: state.pages.quoteDetail.quotesId.map(id => state.entities.quotes[id]),
        client: currentClientSelector(state),
        assignedEmployee: currentClientSelector(state).assignedEmployee ? state.entities.employees[currentClientSelector(state).assignedEmployee] : {},
        creditSystemIsLoading: state.pages.creditSystem.isLoading,
    }),
    {
        getQuotes,
        getLead,
        replace,
        createPunchableAction,
    }
)(LeadPage)
