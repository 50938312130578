import React, {
    ReactNode,
    useCallback,
    useState,
} from 'react'
import styled, { css } from 'styled-components'
import Flex from '../layout/Flex'

export type TooltipLocations = 'top'|'left'|'right'|'bottom'

interface TooltipBodyProps {
    open: boolean
    location: TooltipLocations
}

const TooltipBody = styled(Flex)<TooltipBodyProps>`
    box-shadow: ${({ theme }) => theme.tooltip.shadow};
    min-width: 200px;
    max-width: 300px;
    ${({ theme }) => theme.tooltip.colors}
    box-sizing: border-box;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease;
    z-index: 1;


    ${({ open }) => open && css`
        opacity: 1;
        visibility: visible;
    `}

    &::after {
      ${({ theme }) => theme.tooltip.colors}
      content: "";
      height: 10px;
      width: 10px;
      position: absolute;
      transform: rotate(45deg);
    }

      ${({ location }) => (location === 'bottom') && css`
          top: calc(100% + 1em);
          left: 50%;
          transform: translateX(-50%);

          &::after {
            top: -5px;
            left: 50%;
          }
      `}
      ${({ location }) => (location === 'left') && css`
          right: calc(100% + 1em);
          top: 50%;
          transform: translateY(-50%);
          &::after {
            right: -5px;
            margin-top: -5px;
            top: 50%;
          }
      `}
       ${({ location }) => (location === 'right') && css`
          left: calc(100% + 1em);
          top: 50%;
          transform: translateY(-50%);
          &::after {
            left: -5px;
            margin-top: -5px;
            top: 50%;
          }
      `}
       ${({ location }) => (location === 'top') && css`
          bottom: calc(100% + 1em);
          left: 50%;
          transform: translateX(-50%);
          &::after {
            bottom: -5px;
            left: 50%;
            margin-left: -5px;
          }
      `}
`

export interface TooltipProps {
    children: [ReactNode, ReactNode]
    location: TooltipLocations
}


function Tooltip({ children, location }: TooltipProps) {
    const [ isOpen, setOpen ] = useState<boolean>(false)

    const toggleTooltip = useCallback(() => {
        setOpen(!isOpen)
    }, [ isOpen ])

    return (
        <Flex position="relative">
            <Flex onMouseEnter={!isOpen ? toggleTooltip : undefined} onMouseLeave={isOpen ? toggleTooltip : undefined}>
                {children[0]}
            </Flex>
            <TooltipBody open={isOpen} location={location}>
                {children[1]}
            </TooltipBody>
        </Flex>
    )
}

export default Tooltip
