import React, { Component } from 'react'

const TrustBox = ({ trustBoxRef }) => <div
        ref={trustBoxRef}
        className="trustpilot-widget"
        data-locale="en-US"
        data-template-id="5406e65db0d04a09e042d5fc"
        data-businessunit-id="56bb2d6c0000ff000588bdd6"
        data-style-width="100%"
        data-theme="light"
>
    <a
        href="https://www.trustpilot.com/review/ageras.com"
        target="_blank"
        rel="noopener noreferrer"
    >
        Trustpilot
    </a>
</div>

class TrustBoxContainer extends Component {
    constructor(props) {
        super(props)
        this.trustBoxRef = React.createRef()
    }
    componentDidMount() {
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(this.trustBoxRef.current, true)
        }
    }
    render() {
        return <TrustBox trustBoxRef={this.trustBoxRef} />
    }
}
export default TrustBoxContainer
