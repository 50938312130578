import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import { ProgressLabel } from '../../cards/index'
import nl2br from '../../utils/Nl2br'
import { generateApiUrl } from '../../../utils/urls'
import { updateProject } from '../../../actions/projects'
import { markQuoteAccepted, createCustomerCalledAction } from '../../../actions/quotes'
import { ProjectStatusSelectBox } from '../../layout/ProjectStatusChooser'
import styled from 'styled-components'
import { darkGrey } from '../../../theme/variables'
import { Button } from '../../elements/Button'
import { Card } from '../../blocks/Card'
import { Flex } from '../../elements/Flex'
import groupBy from 'lodash.groupby'
import Tabs from '../../elements/Tabs'
import CallButton from '../../elements/CallButton'
import ActionButton from '../../elements/ActionButton'
import { getLeadCertifications } from '../../../actions/certifications'
import { certificationSelector } from '../../../selectors/lead'
import MeetingRequests from "./MeetingRequests";
import { currentPartnerSelector } from '../../../selectors/auth'

const ClientInfoRow = styled.p`
  margin: 5px 0;
  i {
      color: ${darkGrey};
      font-size: 22px;
      margin-right: 10px;
  }
`

const ProductLine = styled(Row)`
  margin-bottom: 10px;
`

const TagGroup = styled.div`
  margin-bottom: 30px;
`

const TAG_LIST = [ 'basics', 'business_value', 'bookkeeping', 'requirement', 'preference' ]

@connect(
    state => ({
        projectStatuses: Object.values(state.entities.projectStatuses),
        certifications: certificationSelector(state),
        partner: currentPartnerSelector(state),
    }),
    {
        updateProject,
        markQuoteAccepted,
        createCustomerCalledAction,
        getLeadCertifications,
    }
)
@withTranslation('', { wait: true })
export default class ProjectDetailPage extends React.Component {
    state = {
        activeDescriptionTab: 'case',
        activeLeadInfoTab: 'caseHighlight',
        showInfoCard: true,
    }

    componentDidMount() {
        this.props.getLeadCertifications(this.props.lead.id)
    }

    setActiveDescriptionTab(tabKey, tab) {
        this.setState({ [tabKey]: tab })
    }

    changeProjectStatus = (project, name) =>{
        const data = {
            status: {
                name,
            },
        }
        this.props.updateProject(project.id, data)
    }

    onCall = project => {
        this.props.createCustomerCalledAction(project.lead.id, project.quote)
    }

    status = (project, quote) => {
        if (quote) {
            if (quote.status === 'rejected') {
                return <Flex modifiers={[ 'alignCenter' ]}>
                    <ProgressLabel progress={'lost'} icon={true} />
                    <span className="hidden-xs">&nbsp;{this.props.t('quotes.progress.lost')}</span>
                </Flex>
            }
            return <Flex modifiers={[ 'alignCenter' ]}>
                <ProgressLabel progress={quote.progress} icon={true} />
                <span className="hidden-xs">&nbsp;{this.props.t('quotes.progress.' + (quote.progress || 'unknown'))}</span>
            </Flex>
        }
        return <Flex modifiers={[ 'alignCenter' ]}>
            <div className="project-status" style={{ backgroundColor: project.status.color }} />
            <span className="hidden-xs">&nbsp;{this.props.t('projects.statuses.' + project.status.name)}</span>
        </Flex>
    }

    render() {
        const {
            markQuoteAccepted,
            projectStatuses,
            certifications,
            partner,
            project,
            quote,
            lead,
            t,
          } = this.props

        const downloadUrl = generateApiUrl('leads/' + (lead?.id) + '.pdf')

        const customer = project.customers[0]

        const groupByAttributeTag = groupBy(lead.attributes, 'tag')

        const showCaseTab = Object.keys(groupByAttributeTag).find(tag => TAG_LIST.includes(tag))

        return (
            <Row>
                <Col md={12} lg={6}>
                    <Card>
                        <Card.Header modifiers={[ 'pY_2' ]}>
                            <strong>{t('projects.project_detail.about_client_header')}</strong>
                        </Card.Header>
                        <Card.Content>
                            {quote && this.status(project, quote)}
                            <br />
                            {project.status &&
                            <ProjectStatusSelectBox
                                changeProjectStatus={this.changeProjectStatus}
                                projectStatuses={projectStatuses}
                                selectedStatus={(quote && quote.status === 'rejected') ? 'lost' : project.status.name}
                                project={project}
                                t={t}
                            />}
                            <Card.Line modifiers={[ 'mY_3' ]}/>
                            <ClientInfoRow>
                                <i className="mdi mdi-account" /> &nbsp;
                                {customer.firstName && customer.lastName ?
                                customer.firstName + ' ' + customer.lastName : customer.firstName ?
                                customer.firstName : customer.lastName ?
                                customer.lastName : ''}
                            </ClientInfoRow>
                            {customer.companyName && <ClientInfoRow><i className="mdi mdi-factory" /> &nbsp; {customer.companyName}</ClientInfoRow>}
                            {customer.geo.address && <ClientInfoRow><i className="mdi mdi-home" /> { customer.geo.address }</ClientInfoRow>}
                            {customer.geo.zipCode && customer.geo.cityName && <ClientInfoRow><i className="mdi mdi-city" /> { customer.geo.zipCode } { customer.geo.cityName }</ClientInfoRow>}
                            {customer.vatNumber && <ClientInfoRow><i className="mdi mdi-bank" /> { customer.vatNumber }</ClientInfoRow>}

                            {partner && lead && <ClientInfoRow style={{marginTop: "20px"}}>
                                <MeetingRequests partner={partner} leadId={lead.id} />
                            </ClientInfoRow>}

                            <Card.Line modifiers={[ 'mY_3' ]}/>
                            <CallButton
                                phoneNumber={customer.phone || customer.mobile}
                                geoCode={customer.geo.code}
                                onCall={this.onCall}
                                item={project}
                                icon={<i className="mdi mdi-phone-classic" />}
                                modifiers={[ 'primary', 'fullWidth', 'btnBlock', 'mT_2', 'p_2' ]}
                                label={t('projects.project_detail.btn_call_client')}
                                disabled={quote ? (quote.status === 'rejected' || [ 'lost', 'draft' ].includes(quote.progress)) : true}
                            />
                            <ActionButton
                                label={t('projects.project_detail.btn_email_the_client')}
                                modifiers={[ 'primary', 'fullWidth', 'btnBlock', 'mY_2', 'p_2' ]}
                                action={e => Promise.resolve(e)}
                                hiddenData={customer.email}
                                icon="mdi mdi-email-outline"
                                disabled={quote ? (quote.status === 'rejected' || [ 'lost', 'draft' ].includes(quote.progress)) : true}
                            />
                        </Card.Content>
                    </Card>
                    <Card>
                        <Card.Header modifiers={[ 'pY_0' ]}>
                            <Tabs
                                selectedTab={this.state.activeLeadInfoTab}
                                changeTab={tabId => this.setActiveDescriptionTab('activeLeadInfoTab', tabId)}
                                tabs={[
                                    {
                                        tabName: t('projects.lead_details.case_highlights'),
                                        tabId: 'caseHighlight',
                                    }, {
                                        tabName: t('projects.lead_details.service_needed'),
                                        tabId: 'serviceNeeded',
                                    },
                                ]}
                            />
                        </Card.Header>
                        { this.state.activeLeadInfoTab === 'caseHighlight' &&
                        <Card.Content>
                            <div>{lead.title} <strong>#{lead.id}</strong></div>
                            {lead.industries && lead.industries.primary && <p>{lead.industries.primary.title}</p>}
                            {lead.customerInfo.customerType && <div>
                                <Card.Line modifiers={[ 'mY_2' ]} />
                                <div><strong>{lead.customerInfo.customerType.identifier}</strong></div>
                            </div>}
                            {groupByAttributeTag.primary && <div>
                                <Card.Line modifiers={[ 'mY_2' ]} />
                                {groupByAttributeTag.primary.map(attribute => <ProductLine key={attribute.label}>
                                    <Col sm={6}>{attribute.label}</Col>
                                    <Col sm={6}>{attribute.selected[0].title}</Col>
                                </ProductLine>)}
                            </div>}
                        </Card.Content>}
                        {this.state.activeLeadInfoTab === 'serviceNeeded' && lead.products && Boolean(lead.products.length) &&
                        <Card.Content>
                            <Card.Text modifiers={[ 'primaryColor' ]}>{t('leads.lead_info.service_needed')}</Card.Text>
                            <Card.Line modifiers={[ 'mB_2', 'mT_1' ]}/>
                            {lead.products.map(el => <ProductLine key={el.product.name}>
                                <Col sm={6}>{el.product.name}</Col>
                                <Col sm={6}>
                                    {t(`quotes.creation.products.product_types.${el.type}`)}
                                    {el.description && <span> — {el.description}</span>}
                                </Col>
                            </ProductLine>)}
                        </Card.Content>}
                    </Card>
                </Col>
                <Col md={12} lg={6}>
                    {Boolean(quote) && quote.status !== 'accepted' && quote.status !== 'rejected' &&
                    <Card>
                        <Card.Header modifiers={[ 'pY_2' ]}>
                            <strong>{t('projects.quote_info.mark_as_won_title')}</strong>
                        </Card.Header>
                        <Card.Content>
                            {t('projects.quote_info.mark_as_won_description')}
                            <br />
                            <br />
                            <Button
                                modifiers={[ 'action', 'btnBlock', 'p_2', 'fullWidth' ]}
                                onClick={() => markQuoteAccepted(quote.leadId, quote.id)}
                            >
                                {t('projects.quote_info.mark_as_won')}
                            </Button>
                        </Card.Content>
                    </Card>}
                    <Card>
                        <Card.Header modifiers={[ 'pY_0' ]}>
                            <Tabs
                                selectedTab={showCaseTab ? this.state.activeDescriptionTab : 'view'}
                                changeTab={tabId => this.setActiveDescriptionTab('activeDescriptionTab', tabId)}
                                tabs={[
                                    {
                                        tabName: t('projects.case_details'),
                                        tabId: 'case',
                                        hidden: !showCaseTab,
                                    }, {
                                        tabName: t('projects.project_description'),
                                        tabId: 'view',
                                        hidden: !project.description,
                                    },
                                ]}
                            />
                        </Card.Header>
                        <Card.Content>
                            { (!showCaseTab || this.state.activeDescriptionTab === 'view') &&
                            <div>
                                <div className="project-title"><strong>{ project.title }</strong> (#{lead && lead.id})</div>
                                <br />
                                <div className="project-description">{ nl2br(project.description) }</div>
                                <br /><br />
                                { lead && <a href={downloadUrl} target="_blank" rel="noreferrer">
                                    <Button
                                        modifiers={[ 'btnBlock', 'primary', 'p_2', 'fullWidth' ]}
                                    >
                                        {t('leads.download_pdf')}
                                    </Button>
                                </a>}
                            </div> }
                            {showCaseTab && this.state.activeDescriptionTab === 'case' && <div>
                                {Object.keys(groupByAttributeTag).map(tag => TAG_LIST.includes(tag) &&
                                    <TagGroup>
                                        <Card.Text modifiers={[ 'primaryColor' ]}>{t(`leads.lead_info.${tag}`)}</Card.Text>
                                        <Card.Line modifiers={[ 'mY_1' ]} />
                                        {groupByAttributeTag[tag].map(attribute => <ProductLine key={attribute.label}>
                                            <Col sm={6}>{attribute.label}</Col>
                                            <Col sm={6}>{attribute.selected[0].title}</Col>
                                        </ProductLine>)}
                                    </TagGroup>)
                                }
                                {Boolean(certifications.length) &&
                                <TagGroup>
                                    <Card.Text modifiers={[ 'primaryColor' ]}>{t('leads.lead_info.certifications')}</Card.Text>
                                    <Card.Line modifiers={[ 'mY_1' ]} />
                                    {certifications.map(el => <ProductLine key={el.id + 'certifications'}>
                                        <Col sm={6}><i className="mdi mdi-certificate"/> {el.title}</Col>
                                    </ProductLine>)}
                                </TagGroup>}
                                { lead && !project.description &&
                                <a href={downloadUrl} target="_blank" rel="noreferrer">
                                    <Button
                                        modifiers={[ 'btnBlock', 'primary', 'p_2', 'fullWidth' ]}
                                    >
                                        {t('leads.download_pdf')}
                                    </Button>
                                </a>}
                            </div>}
                        </Card.Content>
                    </Card>
                </Col>
            </Row>
        )
    }
}
