import styled from 'styled-components'
import { applyResponsiveStyleModifiers, applyStyleModifiers } from 'styled-components-modifiers'
import { TEXT_COLOR_MODIFIERS } from '../../elements/modifiers/textColorModifiers'
import { MODIFIER_MARGIN_CONFIG, MODIFIER_PADDING_CONFIG } from '../../elements/modifiers/spaceModifiers'

export default styled.div`
  font-weight: bold;
  font-size: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  ${applyStyleModifiers(MODIFIER_MARGIN_CONFIG)}
  ${applyStyleModifiers(MODIFIER_PADDING_CONFIG)}
  ${applyStyleModifiers(TEXT_COLOR_MODIFIERS)}
  ${applyResponsiveStyleModifiers(TEXT_COLOR_MODIFIERS)}
`
