import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { IconContainerLarge } from '../cards/index'
import { Flex } from '../elements/Flex'

export const PageFooter = ({
    t,
    user,
    userType,
}) => <div id="container-footer">
    {userType === 'client' &&
        <div>
            <div className="usp-section">
                <div className="container">
                    <Row>
                        <Col md={4}>
                            <img src="/images/icon-handpicked.svg" alt="" />
                            <h3>{t('client_usp.handpicked_quotes_headline')}</h3>
                            <p>{t('client_usp.handpicked_quotes_description')}</p>
                        </Col>
                        <Col md={4}>
                            <img src="/images/icon-star.svg" alt="" />
                            <h3>{t('client_usp.quality_advisors_headline')}</h3>
                            <p>{t('client_usp.quality_advisors_description')}</p>
                        </Col>
                        <Col md={4}>
                            <img src="/images/icon-shield.svg" alt="" />
                            <h3>{t('client_usp.insurance_headline')}</h3>
                            <p>{t('client_usp.insurance_description')}</p>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="contact-footer">
                <div className="container">
                    <h1>
                        <strong>{t('footer.available_hours_part1')}</strong><br />{t('footer.available_hours_part2')}
                    </h1>
                    <Flex modifiers={[ 'justifyCenter' ]}>
                        <Flex modifiers={[ 'alignCenter', 'directionColumn', 'mR_3' ]}>
                            <IconContainerLarge className="mdi mdi-phone-classic i-contact"/>
                            <p>{t('footer.contact_phone_description')} <a
                                href={'tel:' + t('base.country.phone')}>{t('base.country.phone')}</a>
                            </p>
                            <span className="bubble bubble-link">
                                <a href={'tel:' + t('base.country.phone')}>{t('base.country.phone')}</a>
                            </span>
                        </Flex>
                        <Flex modifiers={[ 'alignCenter', 'directionColumn' ]}>
                            <IconContainerLarge className="mdi mdi-email i-contact" />
                            <p>{t('footer.contact_mail_description')} <a
                                href={'mailto:' + t('base.country.mail')}>{t('base.country.mail')}</a>
                            </p>
                            <span className="bubble bubble-link">
                                <a href={'mailto:' + t('base.country.mail')}>{t('base.country.mail')}</a>
                            </span>
                        </Flex>
                    </Flex>
                </div>
            </div>
            <div className="primary-footer">
                <div className="container company">
                    <Row>
                        <Col md={6}>
                            <img className="logo" src="/images/ageras-logo.svg" alt="" />
                        </Col>
                    </Row>
                </div>
                <div className="container">
                    <Row>
                        <Col md={3} xs={6}>
                            <h3 className="heading">{t('footer.heading.the_company')}</h3>
                            <ul>
                                <li><a target="_blank" rel="noopener noreferrer"
                                       href={t('footer.link.about_ageras_link')}>{t('footer.link.about_ageras')}</a>
                                </li>
                                <li><a target="_blank" rel="noopener noreferrer"
                                       href={t('footer.link.contact_link')}>{t('footer.link.contact')}</a>
                                </li>
                                <li><a target="_blank" rel="noopener noreferrer"
                                       href={t('footer.link.jobs_link')}>{t('footer.link.jobs')}</a>
                                </li>
                                <li><a target="_blank" rel="noopener noreferrer"
                                       href={t('footer.link.conditions_link')}>{t('footer.link.conditions')}</a>
                                </li>
                            </ul>
                        </Col>
                        <Col md={3} xs={6}>
                            <h3 className="heading">{t('footer.heading.resources')}</h3>
                            <ul>
                                <li><a target="_blank" rel="noopener noreferrer"
                                       href={t('footer.link.dictionary_link')}>{t('footer.link.dictionary')}</a>
                                </li>
                                <li><a target="_blank" rel="noopener noreferrer"
                                       href={t('footer.link.blog_link')}>{t('footer.link.blog')}</a>
                                </li>
                                <li><a target="_blank" rel="noopener noreferrer"
                                       href={t('footer.link.companies_link')}>{t('footer.link.companies')}</a>
                                </li>
                            </ul>
                        </Col>
                        <Col md={3} xs={6}>
                        </Col>
                        <Col md={3} xs={6}>
                            <h3 className="heading">{t('partner.address')}</h3>
                            <ul>
                                <li>{t('base.country.address')}</li>
                                <li>{t('base.country.city')}</li>
                                <li>{t('base.country.phone')}</li>
                                <li>{t('base.country.mail')}</li>
                            </ul>
                        </Col>
                    </Row>
                </div>
                <div className="container disclaimer">
                    <p>{t('footer.footer_disclaimer')}</p>
                    <Row>
                        <Col md={6}>
                            <span>© {t('footer.ageras_copyright')}</span>
                            <span className="seperator">|</span>
                            <a target="_blank" rel="noopener noreferrer"
                               href={t('footer.link.conditions_link')}>{t('footer.link.conditions')}</a>
                            {[
                                // Footer active in:
                                'de',
                            ].includes(user.geo.code) && <span className="brake-words">
                                <span className="seperator">|</span>
                                <a href="https://www.ageras.com/impressum/de" target="_blank"
                                   rel="noopener noreferrer">Impressum</a>
                                <span className="seperator">|</span>
                                <a href="https://www.ageras.com/datenschutzerklaerung/de"
                                   target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a>
                            </span>}
                        </Col>
                        <Col md={6}>
                            <div className="pull-right">
                                <a target="_blank" rel="noopener noreferrer"
                                   href="https://www.instagram.com/agerascom/"
                                >
                                    <i className="mdi mdi-instagram"/>
                                </a>
                                <a target="_blank" rel="noopener noreferrer"
                                   href="https://www.linkedin.com/company/ageras"
                                >
                                    <i className="mdi mdi-linkedin"/>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    }
</div>
