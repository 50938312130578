import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'
import Chevron, { CircleButton } from '../Chevron'
import Flex from '../layout/Flex'
import Text from '../texts/Text'

export const PaginationButton = styled(CircleButton)<{ disabled?: boolean, active: boolean }>`
    ${({ theme }) => theme.chevron.styles.inactive};

    ${({ active, theme }) => active && css(theme.chevron.styles.default)};

    ${({ disabled }) => disabled && css`
        cursor: not-allowed;
        & > svg {
            pointer-events: none;
        }
    `}
`

export interface PaginationControlProps {
    current: number
    total: number
    onDown: () => void
    onUp: () => void
    onGoTo: (page: number) => void
    offset?: number
}

function PaginationControl({ current, total, onDown, onUp, onGoTo, offset = 2 }: PaginationControlProps) {

    const handleGoTo = useCallback((data) => () => onGoTo(data), [ onGoTo ])

    return <Flex alignItems="top" justifyContent="center">
        <Chevron
            direction="left"
            disabled={current === 1 || !current}
            onClick={onDown}
        />
        <Flex mx={1} maxWidth={200} overflowX="scroll">
            {[ ...Array(total).keys() ].map(number => {
                const pageIndex = number + 1
                return (
                    <PaginationButton
                        flexShrink={0}
                        mx={1}
                        key={pageIndex}
                        onClick={handleGoTo(pageIndex)}
                        active={pageIndex === current}
                    >
                        <Text bold>{pageIndex}</Text>
                    </PaginationButton>
                )
            })}
        </Flex>
        <Chevron
            direction="right"
            disabled={(current === total) || !total}
            onClick={onUp}
        />
    </Flex>
}

export default PaginationControl
